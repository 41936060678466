import {
  BagOutline,
  BookOutline,
  FlagOutline,
  HandshakeOutline,
  Home2Outline,
  Large1On1,
  LargeActivity2,
  LargeBook2,
  LargeCalendarWithHeart,
  LargeCommunity,
  LargeDiamond,
  LargeFeedback,
  LargeFlag,
  LargeMeeting,
  LargeMeter,
  LargeMonitor,
  LargeTasks,
  LargeThumbsUp,
  MessageCircleOutline,
  SmartphoneOutline,
  TvOutline,
} from '@lyearn/icons';
import { DEFAULT_FEEDBACK_TAB } from '@/modules/corporate/modules/feedback/views/FeedbackWall/utils';
import { PraisesTabEnum } from '@/modules/praises/views/PraiseWall/types';
import routes from '@/pages/corporate/routes';

// To a add new tab
// 1. Add it's Icon to NavBarIconsMap
// 2. Add it's entry in MenuItemPathsConfig

export const NavBarIconsMap: {
  [key: string]: (
    _props: React.SVGProps<SVGSVGElement> & {
      fillColorToken?: string;
    },
  ) => JSX.Element;
} = {
  LargeFlag: LargeFlag,
  LargeMeeting: LargeMeeting,
  LargeBook2: LargeBook2,
  LargeMonitor: LargeMonitor,
  LargeDiamond: LargeDiamond,
  LargeTasks: LargeTasks,
  LargeActivity2: LargeActivity2,
  LargeMeter: LargeMeter,
  Large1On1: Large1On1,
  LargeThumbsUp: LargeThumbsUp,
  LargeFeedback: LargeFeedback,
  LargeCommunity: LargeCommunity,
  LargeCalendarWithHeart: LargeCalendarWithHeart,
  Home2Outline: Home2Outline,
};

export const MobileNavBarIconsMap: {
  [key: string]: (
    _props: React.SVGProps<SVGSVGElement> & {
      fillColorToken?: string;
    },
  ) => JSX.Element;
} = {
  home: Home2Outline,
  library: BookOutline,
  sessions: TvOutline,
  okrs: FlagOutline,
  community: MessageCircleOutline,
  product: BagOutline,
  business: HandshakeOutline,
  digital: SmartphoneOutline,
};

// for pages that use props to create their url
// 1. keep the url as ''
// 2. handle their case in getMenuItem and getLinks
// packages/apps/academy/src/@/components/Header/components/NavigationTabs/utils.tsx
export const MenuItemPathsConfigs: {
  [key: string]: string;
} = {
  home: routes.Home(),
  okrs: routes.OKRs(),
  meetings: routes.teamMeetingsDashboard(),
  sessions: routes.DiscoverAllEvent(),
  tasks: routes.Tasks(),
  '1on1': routes.oneOnOnes(),
  praise: routes.Praises(PraisesTabEnum.All),
  feedback: routes.Feedback(DEFAULT_FEEDBACK_TAB),
  habits: routes.Habits(),
  'review-cycles': routes.ReviewsListMy(),
  survey: routes.LearnerSurveyManager(),
  community: '',
  library: '',
};

export const NAV_TO_ROUTES_MAP: Record<string, string[]> = {
  home: ['/home'],
  okrs: ['/okrs', '/okrs/[tab]', '/cycle/[cycleId]/objectives'],
  meetings: ['/team-meetings', '/team-meetings/[id]/details'],
  library: ['/discover/[widgetId]'],
  sessions: ['/session/all', '/session/my'],
  tasks: ['/tasks', '/tasks/[fileId]'],
  habits: ['/habits', '/habits/[tab]'],
  'review-cycles': ['/review-cycles/my', '/review-cycles/team', '/review-cycles/action-plan'],
  '1on1': ['/1on1s', '/1on1s/[id]'],
  praise: ['/praises/[tab]'],
  feedback: ['/feedback/[tab]'],
  community: ['/community/[communityId]', '/community/[communityId]/spaces/[channelId]'],
};

// make sure these map to the keys in MenuItemPathsConfigs
// these are the only tabs that are available on mobile
// Note: community is not available in native, it's filtering is done in the TabScreen component
export const MOBILE_DEFAULT_ENABLED_TABS = ['home'];

export const MOBILE_ENABLED_TABS = [
  ...MOBILE_DEFAULT_ENABLED_TABS,
  'okrs',
  'sessions',
  'library',
  'community',
];
