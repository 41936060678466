export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Map: { input: any; output: any; }
};

export type AbsoluteDateFilter = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type AbsoluteDateFilterType = {
  __typename?: 'AbsoluteDateFilterType';
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

/** Assignee Info regarding externally linked action items */
export type ActionItemAssigneeInfo = {
  __typename?: 'ActionItemAssigneeInfo';
  /** emailAddress of the assignee */
  emailAddress?: Maybe<Scalars['String']['output']>;
};

export type ActionItemAssigneeInfoInput = {
  /** Additional Data for actionItem */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export type ActionItemCountByCategoryAndStatus = {
  __typename?: 'ActionItemCountByCategoryAndStatus';
  category: StatusCategoryEnum;
  count: Scalars['Float']['output'];
  statusGroups: Array<ActionItemStatusCount>;
};

export type ActionItemCountsByCategoryAndStatusPayload = {
  __typename?: 'ActionItemCountsByCategoryAndStatusPayload';
  actionItemCounts: Array<ActionItemCountByCategoryAndStatus>;
  openActionItemTotalCount: Scalars['Float']['output'];
};

/** Edge */
export type ActionItemEdge = {
  __typename?: 'ActionItemEdge';
  node: ActionItemType;
};

export type ActionItemEntityFilter = {
  entityId: Scalars['String']['input'];
  entityType: RelatedEntityTypeEnum;
  /** Meeting Type if entityType is Meeting */
  meetingType?: InputMaybe<MeetingTypeEnum>;
};

export enum ActionItemExternalEntityTypeEnum {
  JiraIssue = 'JIRA_ISSUE'
}

export type ActionItemFilters = {
  actionItemId: Scalars['String']['input'];
};

/** Data related to linking action item to any external entity */
export type ActionItemIntegrationConfig = {
  __typename?: 'ActionItemIntegrationConfig';
  /** All the ids of configs which result to this external entity */
  creationConfigIds?: Maybe<Scalars['String']['output']>;
  /** Defines which type of entity it is linked to */
  externalEntityType?: Maybe<ActionItemExternalEntityTypeEnum>;
  /** External content to which action time is linked */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Is there any action item created via manual sync */
  manualSync?: Maybe<Scalars['Boolean']['output']>;
  /** provider Id, to which this actionItem is externally link */
  providerId: Scalars['String']['output'];
};

export type ActionItemIntegrationConfigFilter = {
  creationConfigIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Defines which type of entity it is linked to */
  externalEntityType?: InputMaybe<ActionItemExternalEntityTypeEnum>;
  /** External content to which action time is linked */
  externalId?: InputMaybe<Scalars['String']['input']>;
  manualSync?: InputMaybe<Scalars['Boolean']['input']>;
  /** provider Id, to which this actionItem is externally link */
  providerId?: InputMaybe<Scalars['String']['input']>;
};

export type ActionItemIntegrationConfigInput = {
  /** All the ids of configs which result to this external entity */
  creationConfigIds?: InputMaybe<Scalars['String']['input']>;
  /** Defines which type of entity it is linked to */
  externalEntityType?: InputMaybe<ActionItemExternalEntityTypeEnum>;
  /** External content to which action time is linked */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Is there any action item created via manual sync */
  manualSync?: InputMaybe<Scalars['Boolean']['input']>;
  /** provider Id, to which this actionItem is externally link */
  providerId: Scalars['String']['input'];
};

/** It will resolve different entities with actionItem linkedTo */
export type ActionItemLinkedToEntityPayloadUnion = KeyResultType | MeetingType | ObjectiveType | PeerFeedbackSchema;

/** Additional information related to the actionItem */
export type ActionItemMetadata = {
  __typename?: 'ActionItemMetadata';
  /** Assignee info from external site */
  assigneeInfo?: Maybe<ActionItemAssigneeInfo>;
  /** Name to be shown on UI */
  displayName?: Maybe<Scalars['String']['output']>;
  /** priority info from external site */
  priorityInfo?: Maybe<ActionItemPriorityInfo>;
  /** Status to be shown on UI */
  status?: Maybe<Scalars['String']['output']>;
  /** Status id from external site */
  statusId?: Maybe<Scalars['String']['output']>;
};

export type ActionItemMetadataInput = {
  /** Assignee info from external site */
  assigneeInfo?: InputMaybe<ActionItemAssigneeInfoInput>;
  /** Additional Data for actionItem */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** priority info from external site */
  priorityInfo?: InputMaybe<ActionItemPriorityInfoInput>;
  /** status from external site */
  status?: InputMaybe<Scalars['String']['input']>;
  /** status id from external site */
  statusId?: InputMaybe<Scalars['String']['input']>;
};

export type ActionItemPayload = {
  __typename?: 'ActionItemPayload';
  actionItem?: Maybe<ActionItemType>;
};

export enum ActionItemPriorityEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NoPriority = 'NO_PRIORITY',
  Urgent = 'URGENT'
}

/** priority Info regarding externally linked action items */
export type ActionItemPriorityInfo = {
  __typename?: 'ActionItemPriorityInfo';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ActionItemPriorityInfoInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ActionItemStatusCount = {
  __typename?: 'ActionItemStatusCount';
  count: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  statusInfo?: Maybe<StatusType>;
};

export enum ActionItemSubTypeEnum {
  Align = 'ALIGN',
  Discuss = 'DISCUSS',
  Inform = 'INFORM',
  Update = 'UPDATE'
}

export type ActionItemSubscriptionInput = {
  /** ids of action items */
  actionItemIds: Array<Scalars['String']['input']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<ActionItemSubscriptionOperationEnum>>;
};

export enum ActionItemSubscriptionOperationEnum {
  ActionItemDeleted = 'ACTION_ITEM_DELETED',
  ActionItemUpdated = 'ACTION_ITEM_UPDATED'
}

export type ActionItemSubscriptionPayload = {
  __typename?: 'ActionItemSubscriptionPayload';
  actionItem: ActionItemType;
  operation: ActionItemSubscriptionOperationEnum;
};

export type ActionItemType = {
  __typename?: 'ActionItemType';
  assignedTo?: Maybe<UserType>;
  assignedToId?: Maybe<Scalars['String']['output']>;
  assignedToProfile?: Maybe<UserProfile>;
  attachments?: Maybe<Array<EntityMediaType>>;
  category?: Maybe<StatusCategoryEnum>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** This resolver returns channel of a actionItem */
  discussionChannel?: Maybe<ChannelType>;
  dueDate?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  /** External Link of the actionItem */
  externalLink?: Maybe<Scalars['String']['output']>;
  file?: Maybe<FileSchema>;
  fileId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** ActionItem Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Details for external entity */
  integrationConfig?: Maybe<ActionItemIntegrationConfig>;
  /** Flag to check what is sync type, Before migration, value can remain undefined */
  isAutoSynced?: Maybe<Scalars['Boolean']['output']>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  linkedToEntities?: Maybe<Array<ActionItemLinkedToEntityPayloadUnion>>;
  /** Additional Details for actionItem */
  metadata?: Maybe<ActionItemMetadata>;
  parentActionItem?: Maybe<ActionItemType>;
  parentId?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<ActionItemPriorityEnum>;
  progress?: Maybe<Scalars['Float']['output']>;
  provider?: Maybe<ProviderType>;
  providerTags: Array<EntityTagType>;
  /** All the things which are related to task */
  relatedToEntities?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** status of actionItem */
  status: Scalars['String']['output'];
  statusId?: Maybe<Scalars['String']['output']>;
  statusInfo?: Maybe<StatusType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<Maybe<EntityTagType>>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type ActionItemTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum ActionItemTypeEnum {
  Agenda = 'AGENDA',
  Task = 'TASK'
}

export type ActionItemsFilters = {
  actionItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  assignedToIds?: InputMaybe<Array<Scalars['String']['input']>>;
  assigneeActionItemStatus?: InputMaybe<AssigneeActionItemFilterEnum>;
  categories?: InputMaybe<Array<StatusCategoryEnum>>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDate?: InputMaybe<DateFilter>;
  entities?: InputMaybe<Array<ActionItemEntityFilter>>;
  entityTypes?: InputMaybe<Array<RelatedEntityTypeEnum>>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Details for external entity */
  integrationConfig?: InputMaybe<ActionItemIntegrationConfigFilter>;
  isAutoSynced?: InputMaybe<Scalars['Boolean']['input']>;
  meetingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  notInTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  priorities?: InputMaybe<Array<ActionItemPriorityEnum>>;
  relatedToActionPlans?: InputMaybe<RelatedActionPlanEntityInput>;
  relatedToEntities?: InputMaybe<Array<ActionItemEntityFilter>>;
  reporteeFilterType?: InputMaybe<ReporteeFilterTypeInput>;
  rootActionItemStatus?: InputMaybe<RootActionItemFilter>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  statusIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Get actionItems based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** title of actionItem */
  title?: InputMaybe<Scalars['String']['input']>;
  unCategorized?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActionItemsPayload = {
  __typename?: 'ActionItemsPayload';
  actionItems?: Maybe<Array<ActionItemType>>;
};

/** Edge */
export type ActionPlanEdge = {
  __typename?: 'ActionPlanEdge';
  node: ActionPlanType;
};

export type ActionPlanFilters = {
  cycleExists?: InputMaybe<Scalars['Boolean']['input']>;
  cycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDate?: InputMaybe<DateFilter>;
  focusAreas?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owners?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEntities?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEntityType?: InputMaybe<ActionPlanParentEntityEnum>;
  status?: InputMaybe<Array<ActionPlanStatusEnum>>;
  subTypes?: InputMaybe<Array<InputMaybe<ActionPlanSubTypeEnum>>>;
};

export enum ActionPlanOwnerEnum {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Manager = 'MANAGER',
  User = 'USER'
}

export type ActionPlanOwnerInput = {
  ownerId: Scalars['String']['input'];
  ownerType: ActionPlanOwnerEnum;
};

export type ActionPlanOwnerType = {
  __typename?: 'ActionPlanOwnerType';
  ownerId: Scalars['String']['output'];
  ownerType: ActionPlanOwnerEnum;
};

/** Action Plan Owner Type Union */
export type ActionPlanOwnerTypeUnion = DepartmentType | UserType | WorkspaceType;

export enum ActionPlanParentEntityEnum {
  PerformanceReview = 'PERFORMANCE_REVIEW',
  Survey = 'SURVEY'
}

export enum ActionPlanStatusEnum {
  Cancelled = 'CANCELLED',
  Compelted = 'COMPELTED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Todo = 'TODO'
}

export enum ActionPlanSubTypeEnum {
  DefaultActionPlan = 'DEFAULT_ACTION_PLAN',
  UserLevelEntityActions = 'USER_LEVEL_ENTITY_ACTIONS'
}

export type ActionPlanType = {
  __typename?: 'ActionPlanType';
  actionableOKRs?: Maybe<Array<OkrUnion>>;
  actionableTasks?: Maybe<Array<ActionItemType>>;
  actionables?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  cycleId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  focusAreaEntities?: Maybe<Array<FlagSurveyResponseType>>;
  focusAreas?: Maybe<Array<Scalars['String']['output']>>;
  focusAreasFlaggedQuestions?: Maybe<Array<QuestionType>>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  owner: ActionPlanOwnerType;
  parentEntities: Array<Scalars['String']['output']>;
  parentPerformanceReview?: Maybe<ReviewCycleType>;
  parentSurvey?: Maybe<UserSurveyType>;
  progress: Scalars['Float']['output'];
  resolvedOwner?: Maybe<ActionPlanOwnerTypeUnion>;
  status: ActionPlanStatusEnum;
  subType?: Maybe<ActionPlanSubTypeEnum>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type ActionableReviewCycle = {
  __typename?: 'ActionableReviewCycle';
  actions: Array<ReviewCycleUserActionsEnum>;
  reviewCycle: ReviewCycleType;
  reviewCycleId: Scalars['String']['output'];
};

export type ActivityMetricConfig = {
  __typename?: 'ActivityMetricConfig';
  learnerProfileReportId: Scalars['String']['output'];
};

/** Edge */
export type ActivityMetricEdge = {
  __typename?: 'ActivityMetricEdge';
  node: ActivityMetricType;
};

export enum ActivityMetricEntityTypeEnum {
  OrgRole = 'ORG_ROLE'
}

export type ActivityMetricInput = {
  learnerProfileReportId: Scalars['String']['input'];
};

export type ActivityMetricReportConfig = {
  __typename?: 'ActivityMetricReportConfig';
  learnerProfileWidgetId: Scalars['String']['output'];
};

export type ActivityMetricType = {
  __typename?: 'ActivityMetricType';
  convertedTargetValue: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedById?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  learnerProfileActivitiesReportWidget?: Maybe<WidgetUnion>;
  metric?: Maybe<MetricSchema>;
  metricId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgRole?: Maybe<OrgRoleType>;
  orgRoleId: Scalars['String']['output'];
  reportConfig?: Maybe<ActivityMetricReportConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  targetFrequency: RelativeDateFilterUnitEnum;
  targetValue: Scalars['Float']['output'];
  trackId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};


export type ActivityMetricTypeConvertedTargetValueArgs = {
  filters: GetConvertedTargetActivityMetricValue;
};

export type ActivityMetricWeightConfigInput = {
  distributionType: ActivityMetricWeightDistributionTypeEnum;
};

export type ActivityMetricWeightDistributionConfig = {
  __typename?: 'ActivityMetricWeightDistributionConfig';
  distributionType: ActivityMetricWeightDistributionTypeEnum;
};

export type ActivityMetricWeightDistributionConfigInput = {
  distributionType: ActivityMetricWeightDistributionTypeEnum;
};

export enum ActivityMetricWeightDistributionTypeEnum {
  Custom = 'CUSTOM',
  Equal = 'EQUAL'
}

export type ActivityMetricsFilters = {
  activityMetricIds?: InputMaybe<Array<Scalars['String']['input']>>;
  metricIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ActivityMetricsInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metricId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  targetFrequency: RelativeDateFilterUnitEnum;
  targetValue: Scalars['Float']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

/** add exercise submission feedback input! */
export type AddExerciseSubmissionFeedbackInput = {
  entityId: Scalars['String']['input'];
  mediaResponse?: InputMaybe<CreateMediaInput>;
  responseType: FeedbackResponseTypeEnum;
  submissionId: Scalars['String']['input'];
  textResponse?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

/** Add exercise submission Feedback Response */
export type AddExerciseSubmissionFeedbackPayload = {
  __typename?: 'AddExerciseSubmissionFeedbackPayload';
  channelId?: Maybe<Scalars['String']['output']>;
  currentReviewingState?: Maybe<CurrentReviewingState>;
  errorCode?: Maybe<PerformanceErrorCode>;
  post?: Maybe<PostType>;
  postId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AddReactionInput = {
  /** ChannelId on which the post exists */
  channelId: Scalars['String']['input'];
  /** Post Id on which the reaction needs to be added */
  postId: Scalars['String']['input'];
  /** Actual reaction */
  reaction: Scalars['String']['input'];
};

export type AddRemoveQuizQuestionsInput = {
  questionConfigs: Array<QuizQuestionConfigInput>;
  /** Quiz */
  quizId: Scalars['String']['input'];
};

export type AddSkillInOrgRoleInput = {
  expectedScore: Scalars['Float']['input'];
  skillGroupId: Scalars['String']['input'];
  skillId: Scalars['String']['input'];
};

export type AddSkillsToSkillGroupInput = {
  /** skillGroupId to which given skillIds should be added */
  skillGroupId: Scalars['String']['input'];
  /** skillId to add */
  skillIds: Array<Scalars['String']['input']>;
};

/** Object for adding tokens for push notification for an user. */
export type AddUserPushNotificationTokenInput = {
  /** token object, which will contain information along with tokenId */
  tokens?: InputMaybe<Array<NotificationTokenInput>>;
  /** User id for which tokens are added. */
  userId: Scalars['String']['input'];
};

export type AddUserSearchActivityInput = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<SearchableContentTypeEnum>;
  searchConversionType?: InputMaybe<SearchConversionTypeEnum>;
  /** search text of the context user */
  searchText: Scalars['String']['input'];
};

export type AddUserSearchActivityPayload = {
  __typename?: 'AddUserSearchActivityPayload';
  /** new user search activity */
  userSearchActivity?: Maybe<UserSearchActivityType>;
};

export type AddUsersInEventInput = {
  /** When assignment is made from assignments section, this will be sent from some service */
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  assignmentType?: InputMaybe<AssignmentTypeEnum>;
  createAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  eventId: Scalars['String']['input'];
  groupIds: Array<Scalars['String']['input']>;
  selfEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type AddUsersToStaffRolesInput = {
  roleIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type AdvanceSettingsInput = {
  /** automatically add employees to the reviewCycle when group (in reviewees array) is updated */
  addLearnerToReviewCycleOnGroupUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** field to check for comments */
  allowFeedbackComments?: InputMaybe<Scalars['Boolean']['input']>;
  /** only above or below this level of manager can do this review cycle  */
  level?: InputMaybe<Scalars['Float']['input']>;
  /** userId of project lead */
  projectLead?: InputMaybe<Scalars['String']['input']>;
};

/** ReviewCycle Schema */
export type AdvanceSettingsType = {
  __typename?: 'AdvanceSettingsType';
  /** automatically add employees to the reviewCycle when group (in reviewees array) is updated */
  addLearnerToReviewCycleOnGroupUpdate?: Maybe<Scalars['Boolean']['output']>;
  /** field to check for comments */
  allowFeedbackComments?: Maybe<Scalars['Boolean']['output']>;
  /** only above or below this level of manager can do this review cycle  */
  level?: Maybe<Scalars['Float']['output']>;
  /** userId of project lead */
  projectLead?: Maybe<Scalars['String']['output']>;
};

export enum AffiliationsTypeEnum {
  Lyearn = 'LYEARN',
  Razorpay = 'RAZORPAY',
  Stripe = 'STRIPE'
}

export type AgendaActionItemsInput = {
  assignedToName?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AgendaActionItemsInputPropsType = {
  __typename?: 'AgendaActionItemsInputPropsType';
  assignedToName?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Agenda Database Input */
export type AgendaDatabaseInput = {
  /** Database Id related to agenda */
  dbId: Scalars['String']['input'];
  /** Database View for the agenda */
  viewId: Scalars['String']['input'];
};

/** Defines database schema in Agenda */
export type AgendaDatabaseType = {
  __typename?: 'AgendaDatabaseType';
  /** Used to resolve user response of a form. Only be used via checkin */
  data?: Maybe<DatabaseDataType>;
  dbId: Scalars['String']['output'];
  dbMetadata: DatabaseMetadataType;
  dbView: DatabaseViewType;
  viewId: Scalars['String']['output'];
};

/** Edge */
export type AgendaItemEdge = {
  __typename?: 'AgendaItemEdge';
  node: AgendaItemType;
};

export type AgendaItemEntityFilter = {
  entityId: Scalars['String']['input'];
  entityType: AgendaItemEntityTypeEnum;
};

export type AgendaItemEntityInput = {
  entityId: Scalars['String']['input'];
  entityType: AgendaItemEntityTypeEnum;
};

export enum AgendaItemEntityTypeEnum {
  AgendaItemTemplate = 'AGENDA_ITEM_TEMPLATE',
  MeetingInstance = 'MEETING_INSTANCE',
  Workspace = 'WORKSPACE'
}

export type AgendaItemFilters = {
  agendaItemId: Scalars['String']['input'];
};

export type AgendaItemPayload = {
  __typename?: 'AgendaItemPayload';
  agendaItem?: Maybe<AgendaItemType>;
};

export enum AgendaItemStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type AgendaItemSubscriptionInput = {
  /** ids of agenda items */
  agendaItemIds: Array<Scalars['String']['input']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<AgendaItemSubscriptionOperationEnum>>;
};

export enum AgendaItemSubscriptionOperationEnum {
  ActionItemAdded = 'ACTION_ITEM_ADDED',
  ActionItemRemoved = 'ACTION_ITEM_REMOVED',
  AgendaItemDeleted = 'AGENDA_ITEM_DELETED',
  AgendaItemUpdated = 'AGENDA_ITEM_UPDATED'
}

export type AgendaItemSubscriptionPayload = {
  __typename?: 'AgendaItemSubscriptionPayload';
  agendaItem: AgendaItemType;
  operation: AgendaItemSubscriptionOperationEnum;
};

/** Edge */
export type AgendaItemTemplateEdge = {
  __typename?: 'AgendaItemTemplateEdge';
  node: AgendaItemTemplateType;
};

export type AgendaItemTemplateFilters = {
  agendaItemTemplateId: Scalars['String']['input'];
};

export type AgendaItemTemplatePayload = {
  __typename?: 'AgendaItemTemplatePayload';
  agendaItemTemplate?: Maybe<AgendaItemTemplateType>;
};

export enum AgendaItemTemplateStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type AgendaItemTemplateType = {
  __typename?: 'AgendaItemTemplateType';
  agendaItemIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  meetingsCount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: AgendaItemTemplateStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type AgendaItemTemplatesFilters = {
  agendaItemTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AgendaItemType = {
  __typename?: 'AgendaItemType';
  actionItemsCount: Scalars['Float']['output'];
  activityMetricIds?: Maybe<Array<Scalars['String']['output']>>;
  attachments?: Maybe<Array<EntityMediaType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationCadenceConfig?: Maybe<CadenceConfig>;
  entity: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** ActionItem Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  isChecked: Scalars['Boolean']['output'];
  mindsetIds?: Maybe<Array<Scalars['String']['output']>>;
  okrsConfig?: Maybe<OkrSelectionConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  sourceType?: Maybe<AgendaItemEntityTypeEnum>;
  status: AgendaItemStatusEnum;
  subType: ActionItemSubTypeEnum;
  tasks: Array<ActionItemType>;
  title: Scalars['String']['output'];
  type: ActionItemTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type AgendaItemsFilters = {
  agendaItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entities?: InputMaybe<Array<AgendaItemEntityFilter>>;
  sourceTypes?: InputMaybe<Array<AgendaItemEntityTypeEnum>>;
};

export type AgendaItemsPayload = {
  __typename?: 'AgendaItemsPayload';
  agendaItems?: Maybe<Array<AgendaItemType>>;
};

export type AgendaOkRsInput = {
  dueDate?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownerNames?: InputMaybe<Array<Scalars['String']['input']>>;
  progress: Scalars['Float']['input'];
  status: Scalars['String']['input'];
};

export type AgendaOkRsInputPropsType = {
  __typename?: 'AgendaOKRsInputPropsType';
  dueDate?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownerNames?: Maybe<Array<Scalars['String']['output']>>;
  progress: Scalars['Float']['output'];
  status: Scalars['String']['output'];
};

/** Agenda Schema */
export type AgendaType = {
  __typename?: 'AgendaType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Database linked with the agenda */
  database?: Maybe<AgendaDatabaseType>;
  /** Description about agenda */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Media objects related to the agenda */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Agenda Name */
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Type of agenda */
  type: AgendaTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum AgendaTypeEnum {
  Decision = 'DECISION',
  Feedback = 'FEEDBACK',
  Review = 'REVIEW'
}

/** Defines aggregation operation on a particular field */
export type AggregationInput = {
  aggregator: AggregationTypeEnum;
  property: Scalars['String']['input'];
};

/** Defines array of aggregation operations */
export type AggregationSetInput = {
  set: Array<AggregationInput>;
};

/** Defines aggregation criteria for a particular field */
export type AggregationType = {
  __typename?: 'AggregationType';
  /** Query Aggregator */
  aggregator: AggregationTypeEnum;
  /** Field Id */
  property: Scalars['String']['output'];
};

export enum AggregationTypeEnum {
  Avg = 'AVG',
  Checked = 'CHECKED',
  CountAll = 'COUNT_ALL',
  CountEmpty = 'COUNT_EMPTY',
  CountNotEmpty = 'COUNT_NOT_EMPTY',
  CountUniqueValues = 'COUNT_UNIQUE_VALUES',
  CountValues = 'COUNT_VALUES',
  DateRange = 'DATE_RANGE',
  EarliestDate = 'EARLIEST_DATE',
  LatestDate = 'LATEST_DATE',
  Max = 'MAX',
  Median = 'MEDIAN',
  Min = 'MIN',
  PercentChecked = 'PERCENT_CHECKED',
  PercentEmpty = 'PERCENT_EMPTY',
  PercentNotEmpty = 'PERCENT_NOT_EMPTY',
  PercentUnchecked = 'PERCENT_UNCHECKED',
  Range = 'RANGE',
  Unchecked = 'UNCHECKED'
}

export type AllActivityMetricsPayload = {
  __typename?: 'AllActivityMetricsPayload';
  activityMetrics: Array<ActivityMetricType>;
};

export type AllConfigsPayload = {
  __typename?: 'AllConfigsPayload';
  configs: Array<ConfigType>;
};

export type AllSkillCategoriesInput = {
  generatedBy?: InputMaybe<GeneratedByEnum>;
};

/** All Distinct Skill Categories */
export type AllSkillCategoriesPayload = {
  __typename?: 'AllSkillCategoriesPayload';
  skillCategories: Array<SkillCategoryType>;
};

export type AllowedEntityPermissions = {
  __typename?: 'AllowedEntityPermissions';
  availableOptions?: Maybe<Array<AvailableOptions>>;
  dependentOn: Array<Scalars['String']['output']>;
  /** @deprecated move to entity */
  entity: AllowedPermissionsEntityType;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  permittedOn: Array<EntityPermittedOn>;
};

export enum AllowedPermissionActionsEnum {
  Approve = 'APPROVE',
  Archive = 'ARCHIVE',
  Attach = 'ATTACH',
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Edit = 'EDIT',
  Enroll = 'ENROLL',
  Hide = 'HIDE',
  Invite = 'INVITE',
  List = 'LIST',
  Manage = 'MANAGE',
  Masquerade = 'MASQUERADE',
  Pin = 'PIN',
  Publish = 'PUBLISH',
  Read = 'READ',
  Reject = 'REJECT',
  Remove = 'REMOVE',
  Send = 'SEND',
  ShareContent = 'SHARE_CONTENT'
}

export enum AllowedPermissionsEntityType {
  Academy = 'ACADEMY',
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  AuditLog = 'AUDIT_LOG',
  Authentication = 'AUTHENTICATION',
  Billing = 'BILLING',
  Certificate = 'CERTIFICATE',
  Community = 'COMMUNITY',
  CommunityDiscussionChannel = 'COMMUNITY_DISCUSSION_CHANNEL',
  Department = 'DEPARTMENT',
  EmailDigest = 'EMAIL_DIGEST',
  Event = 'EVENT',
  File = 'FILE',
  Group = 'GROUP',
  Homepage = 'HOMEPAGE',
  Integration = 'INTEGRATION',
  Journey = 'JOURNEY',
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
  OkrDiscussionChannel = 'OKR_DISCUSSION_CHANNEL',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  ReviewCycle = 'REVIEW_CYCLE',
  Role = 'ROLE',
  SharedConnection = 'SHARED_CONNECTION',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Subaccount = 'SUBACCOUNT',
  Tag = 'TAG',
  User = 'USER',
  UserSurvey = 'USER_SURVEY',
  Workspace = 'WORKSPACE'
}

export enum AllowedPermissionsSubjectType {
  Academy = 'ACADEMY',
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  AuditLog = 'AUDIT_LOG',
  Authentication = 'AUTHENTICATION',
  Billing = 'BILLING',
  Category = 'CATEGORY',
  Certificate = 'CERTIFICATE',
  Channel = 'CHANNEL',
  Community = 'COMMUNITY',
  CompanyObjective = 'COMPANY_OBJECTIVE',
  ContentCollection = 'CONTENT_COLLECTION',
  ContentSubworkspace = 'CONTENT_SUBWORKSPACE',
  Department = 'DEPARTMENT',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  EmailDigest = 'EMAIL_DIGEST',
  Event = 'EVENT',
  EventSubworkspace = 'EVENT_SUBWORKSPACE',
  File = 'FILE',
  Group = 'GROUP',
  Homepage = 'HOMEPAGE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  Integration = 'INTEGRATION',
  Journey = 'JOURNEY',
  Learner = 'LEARNER',
  Members = 'MEMBERS',
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
  Post = 'POST',
  Recordings = 'RECORDINGS',
  Reply = 'REPLY',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  ReviewCycle = 'REVIEW_CYCLE',
  Role = 'ROLE',
  SharedConnection = 'SHARED_CONNECTION',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Space = 'SPACE',
  Staff = 'STAFF',
  Subaccount = 'SUBACCOUNT',
  SubWorkspace = 'SUB_WORKSPACE',
  Tag = 'TAG',
  TagSubworkspace = 'TAG_SUBWORKSPACE',
  TeamObjective = 'TEAM_OBJECTIVE',
  User = 'USER',
  UserCollection = 'USER_COLLECTION',
  UserSurvey = 'USER_SURVEY'
}

export type AnnouncementAvailableTillInput = {
  type: AnnouncementAvailableTillTypeEnum;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum AnnouncementAvailableTillTypeEnum {
  Never = 'NEVER',
  Set = 'SET'
}

export enum AnnouncementBroadcastStatusesEnum {
  Completed = 'COMPLETED',
  Live = 'LIVE',
  Scheduled = 'SCHEDULED'
}

/** Edge */
export type AnnouncementEdge = {
  __typename?: 'AnnouncementEdge';
  node: AnnouncementType;
};

export type AnnouncementFilters = {
  broadcastStatus?: InputMaybe<Array<AnnouncementBroadcastStatusesEnum>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<AnnouncementStatusesEnum>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AnnouncementInput = {
  id: Scalars['String']['input'];
};

export enum AnnouncementStatusesEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED'
}

export type AnnouncementType = {
  __typename?: 'AnnouncementType';
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableFrom?: Maybe<Scalars['String']['output']>;
  availableTill?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  dismissedCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isOptional: Scalars['Boolean']['output'];
  isScheduled?: Maybe<Scalars['Boolean']['output']>;
  medias?: Maybe<Array<Maybe<EntityMediaType>>>;
  name: Scalars['String']['output'];
  shouldAllowReactions?: Maybe<Scalars['Boolean']['output']>;
  showOnlyToNewEmployees: Scalars['Boolean']['output'];
  status: AnnouncementStatusesEnum;
  subworkspaceIds?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userAnnouncement?: Maybe<UserAnnouncementType>;
  viewedCount?: Maybe<Scalars['Int']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type AnnouncementTypeDismissedCountArgs = {
  filters?: InputMaybe<UserAnnouncementFilters>;
};


export type AnnouncementTypeViewedCountArgs = {
  filters?: InputMaybe<UserAnnouncementFilters>;
};

export enum ApplicableOnEnum {
  Classroom = 'CLASSROOM',
  Resource = 'RESOURCE',
  Workspace = 'WORKSPACE'
}

export type ApplicationSortType = {
  __typename?: 'ApplicationSortType';
  /** Sort Order */
  order: Array<Scalars['String']['output']>;
  /** Field Id */
  property: Scalars['String']['output'];
};

export enum ArchivalReasonEnum {
  ContentArchived = 'CONTENT_ARCHIVED',
  LessonDeleted = 'LESSON_DELETED',
  SourceEventRevoked = 'SOURCE_EVENT_REVOKED',
  UserArchived = 'USER_ARCHIVED'
}

export type ArchiveGroupInput = {
  groupId: Scalars['String']['input'];
};

export type ArchiveJourneyInput = {
  id: Scalars['String']['input'];
};

export type ArchiveTagInput = {
  tagId: Scalars['String']['input'];
};

export type ArchiveUserSurveyInput = {
  surveyId: Scalars['String']['input'];
};

export type ArchiveUserSurveyPayload = {
  __typename?: 'ArchiveUserSurveyPayload';
  success: Scalars['Boolean']['output'];
  userSurvey?: Maybe<UserSurveyType>;
};

export enum ArrayOperationCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET'
}

export type ArticleAuthorInputType = {
  name: Scalars['String']['input'];
  picture?: InputMaybe<Scalars['String']['input']>;
};

/** Holds name and profilePicture of the author for the article */
export type ArticleAuthorType = {
  __typename?: 'ArticleAuthorType';
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentType = {
  __typename?: 'ArticleContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  /** Author of the article. Creator of the article is the default author */
  author: ArticleAuthorType;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  creatorCommentChannel?: Maybe<ChannelType>;
  /** comment channel for creators */
  creatorCommentChannelId?: Maybe<Scalars['String']['output']>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  duplicatedFrom?: Maybe<Scalars['String']['output']>;
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  /** Feedback Questions for the article */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  instructors: Array<InstructorType>;
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  /** duration of content */
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  performance?: Maybe<ArticlePerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  /** Root blockId of the article */
  rootBlockId?: Maybe<Scalars['String']['output']>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Ids of skill related to the content */
  skillIds: Array<Scalars['String']['output']>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionConfigs?: Maybe<Array<VersionConfig>>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeRootBlockIdArgs = {
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type ArticleNodeStubType = {
  __typename?: 'ArticleNodeStubType';
  content: Scalars['String']['output'];
};

/** Article Performance Type */
export type ArticlePerformanceType = {
  __typename?: 'ArticlePerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type ArticleResultType = {
  __typename?: 'ArticleResultType';
  nodes?: Maybe<Array<ArticleNodeStubType>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** This is the input for AssignContent Enrollment Settings */
export type AssignContentEnrollmentSettingsInput = {
  /** tells whether the user is assigned directly or not */
  direct?: InputMaybe<Scalars['Boolean']['input']>;
  /** tells whether the user is self enrolled or not */
  selfEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the input for assigning a content! */
export type AssignContentInput = {
  dueDate?: InputMaybe<Scalars['String']['input']>;
  enrollmentSettings?: InputMaybe<AssignContentEnrollmentSettingsInput>;
  entityIds: Array<Scalars['String']['input']>;
  eventSelections?: InputMaybe<Array<EventSelectionInput>>;
  notificationSettings?: InputMaybe<AssignContentNotificationSettings>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This is the input for AssignContent Notification Settings */
export type AssignContentNotificationSettings = {
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

/** payload for AssignContentPayload */
export type AssignContentPayload = {
  __typename?: 'AssignContentPayload';
  contents?: Maybe<Array<ContentEntity>>;
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  errorCode?: Maybe<PerformanceErrorCode>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum AssignableEngageEntityEnumType {
  PerformanceReview = 'PERFORMANCE_REVIEW',
  UserSurvey = 'USER_SURVEY'
}

export type AssignedToIdSetInput = {
  /** used to set assignee in action item */
  set?: InputMaybe<Scalars['String']['input']>;
};

/** Filters applicable on userSkillReview */
export type AssignedUsersFilters = {
  entityId: Scalars['String']['input'];
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  /** Returns only assigned users for the given entityId if selectall is passed true */
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
  usersFilters?: InputMaybe<UsersFilters>;
};

export enum AssigneeActionItemFilterEnum {
  AssignedItems = 'ASSIGNED_ITEMS',
  Both = 'BOTH',
  UnAssignedItems = 'UN_ASSIGNED_ITEMS'
}

/** This returns paginated Assignments */
export type AssignmentConnection = {
  __typename?: 'AssignmentConnection';
  edges?: Maybe<Array<AssignmentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export enum AssignmentDueDateEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  NoDueDate = 'NO_DUE_DATE',
  Relative = 'RELATIVE'
}

/** Edge */
export type AssignmentEdge = {
  __typename?: 'AssignmentEdge';
  node: AssignmentType;
};

export type AssignmentImportMetadataType = {
  __typename?: 'AssignmentImportMetadataType';
  createdAssignmentsCount: Scalars['Float']['output'];
};

export type AssignmentItemDueDateConfig = {
  __typename?: 'AssignmentItemDueDateConfig';
  /** Only present if dueDates are not set on nested sections/contents */
  dueDateConfig?: Maybe<DueDateAssignmentConfig>;
  /** The nesting level at which the dueDate is set on. Only present if dueDates are set on nested contents */
  dueDateNestingLevel?: Maybe<DueDateNestingLevelEnum>;
  itemId: Scalars['String']['output'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
  /** Only present if dueDates are set on nested sections/contents */
  nestedDueDateConfig?: Maybe<Array<NestedDueDateConfig>>;
};

export type AssignmentItemDueDateConfigInput = {
  /** Only present if dueDates are not set on nested sections/contents */
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  /** The nesting level at which the dueDate is set on. Only present if dueDates are set on nested sections/contents */
  dueDateNestingLevel?: InputMaybe<DueDateNestingLevelEnum>;
  itemId: Scalars['String']['input'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
  /** Only present if dueDates are set on nested sections/contents */
  nestedDueDateConfig?: InputMaybe<Array<NestedDueDateConfigInput>>;
};

export enum AssignmentItemTypeEnum {
  Collection = 'COLLECTION',
  Content = 'CONTENT',
  Section = 'SECTION'
}

export type AssignmentLearnerImportMetadataType = {
  __typename?: 'AssignmentLearnerImportMetadataType';
  userIds: Array<Scalars['String']['output']>;
};

export type AssignmentRestrictionInput = {
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: AssignmentRestrictionTypeEnum;
};

/** Assignment restrictions for content */
export type AssignmentRestrictionType = {
  __typename?: 'AssignmentRestrictionType';
  /** role ids which can assign permissions */
  roleIds?: Maybe<Array<Scalars['String']['output']>>;
  type: AssignmentRestrictionTypeEnum;
};

export enum AssignmentRestrictionTypeEnum {
  All = 'ALL',
  Restricted = 'RESTRICTED'
}

export enum AssignmentScheduleDateEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  NoScheduleDate = 'NO_SCHEDULE_DATE'
}

export type AssignmentStatsInput = {
  collectionIds: Array<Scalars['String']['input']>;
  contentIds: Array<Scalars['String']['input']>;
  eventSelections?: InputMaybe<Array<EventSelectionInput>>;
  userGroupIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type AssignmentStatsPayload = {
  __typename?: 'AssignmentStatsPayload';
  contentCount: Scalars['Float']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  notVisibleContentIds: Array<Scalars['String']['output']>;
  notVisibleContents: Array<ContentEntity>;
  userCount: Scalars['Float']['output'];
};

export enum AssignmentStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED'
}

export type AssignmentSubscriptionInput = {
  assignmentId: Scalars['String']['input'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<AssignmentSubscriptionOperationEnum>>;
};

export enum AssignmentSubscriptionOperationEnum {
  AssignmentUpdated = 'ASSIGNMENT_UPDATED'
}

export type AssignmentSubscriptionPayload = {
  __typename?: 'AssignmentSubscriptionPayload';
  assignment: AssignmentType;
  operation: AssignmentSubscriptionOperationEnum;
};

/** Information regarding assignment */
export type AssignmentType = {
  __typename?: 'AssignmentType';
  assignedAt?: Maybe<Scalars['String']['output']>;
  assigner?: Maybe<UserType>;
  assignerId: Scalars['String']['output'];
  assignmentItemsDueDateConfig?: Maybe<Array<AssignmentItemDueDateConfig>>;
  /** number associate with assignment */
  assignmentNumber: Scalars['Float']['output'];
  collectionContents: Array<CollectionContentType>;
  collectionIds: Array<Scalars['String']['output']>;
  contentCount: Scalars['Float']['output'];
  contentIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  dueDateConfig: DueDateAssignmentConfig;
  eventSelections?: Maybe<Array<EventSelection>>;
  id: Scalars['String']['output'];
  /** Title of assignment */
  name?: Maybe<Scalars['String']['output']>;
  notificationConfigurations?: Maybe<Array<NotificationConfigurationType>>;
  scheduleDateConfig: ScheduleDateAssignmentConfig;
  scheduledJobConfig?: Maybe<ScheduledJobAssignmentConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of the Assignment */
  status: AssignmentStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userCount: Scalars['Float']['output'];
  userGroupIds: Array<Scalars['String']['output']>;
  userGroupSyncConfigs: UserGroupSyncAssignmentConfigs;
  userIds: Array<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum AssignmentTypeEnum {
  All = 'ALL',
  Flexible = 'FLEXIBLE',
  Specific = 'SPECIFIC'
}

export type AssignmentsFilters = {
  assignedOn?: InputMaybe<DateFilter>;
  assignerId?: InputMaybe<Scalars['String']['input']>;
  collectionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<AssignmentStatusEnum>>;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssociationsSetInput = {
  /** To which course the question is associated */
  set: Array<QuestionAssociationInput>;
};

/** This is the input for assigning a content! */
export type AsyncAssignContentInput = {
  collectionId?: InputMaybe<Array<Scalars['String']['input']>>;
  entityIds: Array<Scalars['String']['input']>;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This is the input for assigning a engage entity! */
export type AsyncAssignEngageEntityInput = {
  assignToEveryone: Scalars['Boolean']['input'];
  entityId: Scalars['String']['input'];
  entityType: AssignableEngageEntityEnumType;
  recurrenceNumber: Scalars['Float']['input'];
  userGroupIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
  userSurveyType: Scalars['String']['input'];
};

/** This is the input for assigning a journey entity! */
export type AsyncAssignJourneyEntityInput = {
  entityId: Scalars['String']['input'];
  groupIds: Array<Scalars['String']['input']>;
};

export type AsyncAssignJourneyEntityInputPropsType = {
  __typename?: 'AsyncAssignJourneyEntityInputPropsType';
  entityId: Scalars['String']['output'];
  groupIds: Array<Scalars['String']['output']>;
};

/** This is the input for assigning a journey entity! */
export type AsyncAssignJourneyStepEntityInput = {
  addedActionEntities: Array<JourneyStepActionEntitiesInput>;
  entityId: Scalars['String']['input'];
  groupIds: Array<Scalars['String']['input']>;
  parentEntityId: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type AsyncAssignJourneyStepEntityInputPropsType = {
  __typename?: 'AsyncAssignJourneyStepEntityInputPropsType';
  addedActionEntities: Array<JourneyStepActionEntitiesType>;
  entityId: Scalars['String']['output'];
  groupIds: Array<Scalars['String']['output']>;
  parentEntityId: Scalars['String']['output'];
  tagIds: Array<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
};

export type AttachActivityMetricsInput = {
  activityMetrics?: InputMaybe<Array<ActivityMetricsInputType>>;
  entityId: Scalars['String']['input'];
  entityType?: InputMaybe<ActivityMetricEntityTypeEnum>;
  weightConfig?: InputMaybe<ActivityMetricWeightConfigInput>;
};

export type AttachActivityMetricsPayload = {
  __typename?: 'AttachActivityMetricsPayload';
  activityMetrics?: Maybe<ActivityMetricType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** It will resolve different entities which has attachments */
export type AttachmentEntityUnion = ActionItemType | AgendaItemType | KeyResultType | MeetingType | MetricSchema | ObjectiveType;

export type AttachmentsSetInput = {
  /** Medias not in this list would be deleted and new medias in this list will be added */
  set: Array<CreateMediaInput>;
};

export enum AttendanceStatusEnum {
  Attended = 'ATTENDED',
  NotAttended = 'NOT_ATTENDED',
  NotMarked = 'NOT_MARKED',
  Partial = 'PARTIAL'
}

/** This is the input for attributes related to sync progress input */
export type AttributesInput = {
  conferenceBridgeId?: InputMaybe<Scalars['String']['input']>;
  isMicOn?: InputMaybe<Scalars['Boolean']['input']>;
  isVideoOn?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  recordingId?: InputMaybe<Scalars['String']['input']>;
};

export type AuditMetadataFilters = {
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  workspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AuditMetadataMessageInfo = {
  __typename?: 'AuditMetadataMessageInfo';
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  /** Who created this audit metadata */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<InstructionType>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type AuditMetadataMessageInfoConnection = {
  __typename?: 'AuditMetadataMessageInfoConnection';
  edges?: Maybe<Array<AuditMetadataMessageInfoEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type AuditMetadataMessageInfoEdge = {
  __typename?: 'AuditMetadataMessageInfoEdge';
  node: AuditMetadataMessageInfo;
};

export type AuthBackground = {
  __typename?: 'AuthBackground';
  imageName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  layout: BackgroundLayoutTypeEnum;
};

export type AuthBackgroundInput = {
  imageName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<BackgroundLayoutTypeEnum>;
};

export type AuthBackgroundType = {
  __typename?: 'AuthBackgroundType';
  imageName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  layout: BackgroundLayoutTypeEnum;
};

export type AuthConfig = {
  __typename?: 'AuthConfig';
  authPageUrl?: Maybe<Scalars['String']['output']>;
  background?: Maybe<AuthBackgroundType>;
  restrictedSignUp?: Maybe<Scalars['Boolean']['output']>;
  signUpAllowed?: Maybe<Scalars['Boolean']['output']>;
  spCertificate: Scalars['String']['output'];
  strategyMethods: Array<StrategyMethod>;
  strategyMethodsListing: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** userPublicAPIClientId for given workspace */
  userPublicAPIClientId?: Maybe<Scalars['String']['output']>;
};

export type AuthConfigType = {
  __typename?: 'AuthConfigType';
  authPageUrl?: Maybe<Scalars['String']['output']>;
  background?: Maybe<AuthBackground>;
  restrictedSignUp?: Maybe<Scalars['Boolean']['output']>;
  signUpAllowed?: Maybe<Scalars['Boolean']['output']>;
  strategyMethods: Array<StrategyMethodType>;
  strategyMethodsListing: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum AutomaticMetricTypeEnum {
  CertificateGiven = 'CERTIFICATE_GIVEN',
  ContentCompletionRate = 'CONTENT_COMPLETION_RATE',
  OneOnOneParticipationRate = 'ONE_ON_ONE_PARTICIPATION_RATE',
  PeerFeedbackGiven = 'PEER_FEEDBACK_GIVEN',
  PraiseGiven = 'PRAISE_GIVEN',
  SessionCompletionRate = 'SESSION_COMPLETION_RATE',
  UserLearningTime = 'USER_LEARNING_TIME'
}

export type AutomationConfigInput = {
  filterGroup?: InputMaybe<FilterGroupV2Input>;
  isAutomated: Scalars['Boolean']['input'];
};

export type AutomationConfigType = {
  __typename?: 'AutomationConfigType';
  filterGroup?: Maybe<FilterGroupV2Type>;
  isAutomated: Scalars['Boolean']['output'];
};

/** Available Media Formats */
export type AvailableMediaFormatType = {
  __typename?: 'AvailableMediaFormatType';
  format: Scalars['String']['output'];
  medias: Array<FormattedResourceMediaType>;
};

export enum AvailableOptionTypeEnum {
  RadioGroup = 'RADIO_GROUP'
}

export type AvailableOptions = {
  __typename?: 'AvailableOptions';
  children: Array<ChildrenType>;
  default: Scalars['String']['output'];
  type: AvailableOptionTypeEnum;
};

export type AvailableSchemaFieldType = {
  __typename?: 'AvailableSchemaFieldType';
  iconUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum BackgroundLayoutTypeEnum {
  CentreAligned = 'CENTRE_ALIGNED',
  LeftAligned = 'LEFT_ALIGNED',
  NoBackground = 'NO_BACKGROUND',
  RightAligned = 'RIGHT_ALIGNED'
}

export type BannerCardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  props?: InputMaybe<Scalars['String']['input']>;
  status: PageComponentStatusEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: PageComponentTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  widgetProps: WidgetPropsInput;
};

export type BannerPageComponentPreviewPropsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageComponentType: PageComponentTypeEnum;
  props?: InputMaybe<Scalars['String']['input']>;
  widgetProps?: InputMaybe<WidgetPropsInput>;
  widgetType: WidgetTypeEnum;
};

export type BannerPropsInput = {
  set: Array<BannerCardInput>;
};

export type BannerPropsPreviewInput = {
  components: Array<BannerPageComponentPreviewPropsInput>;
};

export enum BannerStatusEnum {
  AboutToStart = 'ABOUT_TO_START',
  Live = 'LIVE',
  Upcoming = 'UPCOMING'
}

/** Building element of any enclosing entity */
export type BaseBlockType = {
  __typename?: 'BaseBlockType';
  /** Attributes of the block */
  attributes: Scalars['String']['output'];
  /** Type of the block */
  blockType: Scalars['String']['output'];
  /** Child block ids in order of their hierarchy */
  childrenIds: Array<Scalars['String']['output']>;
  /** Contribution to total duration of the block */
  contributeToProgress?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** text content that is in form of json string allong with some markup/decoration */
  decoratedChildBlocks?: Maybe<Scalars['String']['output']>;
  /** Stores info about all media in the block */
  downloadableMedias?: Maybe<Array<EntityMediaType>>;
  /** Draft version of this block */
  draftBlockId?: Maybe<Scalars['String']['output']>;
  /** Reading duration of the block */
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  /** Is the block content optional for the learner */
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  /** Markup details of styling and comments */
  markups?: Maybe<Scalars['String']['output']>;
  /** mediaId of media embedded in the block */
  mediaId?: Maybe<Scalars['String']['output']>;
  /** Parent entity's id */
  parentId: Scalars['String']['output'];
  /** Parent entity's type or table */
  parentType: Scalars['String']['output'];
  /** Published version of this block */
  publishedBlockId?: Maybe<Scalars['String']['output']>;
  /** Root block's id */
  rootId: Scalars['String']['output'];
  /** Parent entity's type or table */
  rootType: Scalars['String']['output'];
  /** Reading duration of the block */
  selfDuration?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** text content that might be present in the block */
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type BaseGroupType = {
  __typename?: 'BaseGroupType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filterGroup?: Maybe<FilterGroupV2Type>;
  id: Scalars['String']['output'];
  isAutoSyncGroup: Scalars['Boolean']['output'];
  metricSnapshot?: Maybe<MetricSnapshot>;
  name: Scalars['String']['output'];
  reSyncedAt?: Maybe<Scalars['String']['output']>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: GroupStatusEnum;
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Base Performance Type */
export type BasePerformanceSchema = {
  __typename?: 'BasePerformanceSchema';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchema = {
  __typename?: 'BaseResourceSchema';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  duration: Scalars['Float']['output'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaTagArgs = {
  tagId: Scalars['String']['input'];
};

export type BaseUserNotification = {
  __typename?: 'BaseUserNotification';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  quotedMessage?: Maybe<Scalars['String']['output']>;
  readAt: Scalars['String']['output'];
  redirectPath: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']['output']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userProfileIcon?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type BatchConfigInput = {
  filterGroup?: InputMaybe<FilterGroupV2Input>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  toExcludeUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BatchConfigType = {
  __typename?: 'BatchConfigType';
  group?: Maybe<BaseGroupType>;
  groupId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  toExcludeUserIds: Array<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
};

/** This returns paginated BlockSearchSchema */
export type BlockSearchConnections = {
  __typename?: 'BlockSearchConnections';
  edges?: Maybe<Array<BlockSearchEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type BlockSearchEdge = {
  __typename?: 'BlockSearchEdge';
  node: BlockSearchSchema;
};

/** This is the Block search result schema, used to return ES results */
export type BlockSearchSchema = {
  __typename?: 'BlockSearchSchema';
  /** Attributes of the block */
  attributes: Scalars['String']['output'];
  /** Type of the block */
  blockType: Scalars['String']['output'];
  /** Child block ids in order of their hierarchy */
  childrenIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** text content that is in form of json string allong with some markup/decoration */
  decoratedChildBlocks?: Maybe<Scalars['String']['output']>;
  /** Reading duration of the block */
  duration?: Maybe<Scalars['Float']['output']>;
  /** Highlight info to be fetched from ES */
  highlights?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['String']['output'];
  /** Markup details of styling and comments */
  markups?: Maybe<Scalars['String']['output']>;
  /** parent content info */
  parentDetails: ParentDetails;
  /** Parent entity's id */
  parentId: Scalars['String']['output'];
  /** Parent entity's type or table */
  parentType: Scalars['String']['output'];
  /** Root block's id */
  rootId: Scalars['String']['output'];
  /** Parent entity's type or table */
  rootType: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** parent entity's Status to be stored as block's status */
  status: Scalars['String']['output'];
  /** text content that might be present in the block */
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Edge */
export type BookmarkEdge = {
  __typename?: 'BookmarkEdge';
  node: BookmarkType;
};

/** Filters for Bookmark of the user */
export type BookmarkFilters = {
  /** Bookmark ids to be fetched */
  bookmarkIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** entityIds for which we need bookmarks */
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** status of content for which we need bookmarks */
  entityStatus?: InputMaybe<Array<ResourceStatusEnum>>;
  /** entityTypes for which we need bookmarks */
  entityTypes?: InputMaybe<Array<ResourceTypeEnum>>;
  /** performance status of content of user for which we need bookmarks */
  performanceStatus?: InputMaybe<Array<PerformanceStatusEnum>>;
  /** search text to get related content which is bookmarked */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** UserIds for whom we want bookmarks */
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Bookmark schema to persist bookmark info */
export type BookmarkType = {
  __typename?: 'BookmarkType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Content that was bookmarked by the user */
  entity?: Maybe<ContentEntity>;
  /** id of the content on which bookmark is created */
  entityId: Scalars['String']['output'];
  /** Type of content that is bookmarked */
  entityType: ResourceTypeEnum;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** userId for whom the bookmark is created */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** input for creating microskills using hierarchy */
export type BulkCreateMicroSkillsInput = {
  children?: InputMaybe<Array<BulkCreateMicroSkillsInput>>;
  name: Scalars['String']['input'];
};

export type BulkTagsUpdateMetadataType = {
  __typename?: 'BulkTagsUpdateMetadataType';
  createdTagsCount: Scalars['Float']['output'];
  updatedTagsCount: Scalars['Float']['output'];
};

export type BulkUnAssignContentPropsInput = {
  /** Id of the entity to be unassigned */
  entityId: Scalars['String']['input'];
  learnerGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** User config that will determine users to be unassigned from the given entity */
  unAssignUserConfig?: InputMaybe<UnAssignUserConfigInput>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BulkUnAssignContentPropsInputType = {
  __typename?: 'BulkUnAssignContentPropsInputType';
  entityId: Scalars['String']['output'];
  learnerGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  unAssignUserConfig?: Maybe<UnAssignUserConfigType>;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type BulkUnAssignLearnerMetadataType = {
  __typename?: 'BulkUnAssignLearnerMetadataType';
  totalLearnersCount: Scalars['Float']['output'];
  unAssignableLearnersCount: Scalars['Float']['output'];
};

/** Cadence config for creation of any entity */
export type CadenceConfig = {
  __typename?: 'CadenceConfig';
  frequency: Scalars['Float']['output'];
};

export type CadenceConfigInput = {
  frequency: Scalars['Float']['input'];
};

export enum CalculationMechanismEnum {
  NoOverallGrade = 'NO_OVERALL_GRADE',
  TotalPoints = 'TOTAL_POINTS',
  WeightedByCategory = 'WEIGHTED_BY_CATEGORY'
}

export type CancelGenerativeTaskInput = {
  generativeTaskId: Scalars['String']['input'];
};

export type CancelJobInput = {
  /** id of the job */
  jobScheduleId: Scalars['String']['input'];
};

export enum CategoryCalculationTypeEnum {
  Percentage = 'PERCENTAGE',
  Point = 'POINT'
}

export type CategoryIdsInput = {
  /** list of all categories attached to the content */
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum CategoryStatusTypeEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

/** Assignment Category Type */
export type CategoryType = {
  __typename?: 'CategoryType';
  calculationType: CategoryCalculationTypeEnum;
  classroomId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Returns the count of assignment */
  homeworkCount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CategoryStatusTypeEnum;
  systemGenerated: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  weight: Scalars['Float']['output'];
};

export type CertificateConnections = {
  __typename?: 'CertificateConnections';
  edges?: Maybe<Array<CertificateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type CertificateDimensions = {
  __typename?: 'CertificateDimensions';
  height: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

/** Edge */
export type CertificateEdge = {
  __typename?: 'CertificateEdge';
  node: CertificateType;
};

/** Settings for certificate on the entity */
export type CertificateSettings = {
  __typename?: 'CertificateSettings';
  /** allow learner to download certificate */
  allowDownload: Scalars['Boolean']['output'];
  /** allow learner to add certificate to linkedin profile */
  allowLinkedinProfile: Scalars['Boolean']['output'];
  /** allow learner to share certificate on social media posts */
  allowSocialSharing: Scalars['Boolean']['output'];
  certificateTemplateId: Scalars['String']['output'];
  minPercentage?: Maybe<Scalars['Float']['output']>;
};

export type CertificateSettingsInput = {
  /** allow learner to download certificate */
  allowDownload: Scalars['Boolean']['input'];
  /** allow learner to add certificate to linkedin profile */
  allowLinkedinProfile: Scalars['Boolean']['input'];
  /** allow learner to share certificate on social media posts */
  allowSocialSharing: Scalars['Boolean']['input'];
  certificateTemplateId: Scalars['String']['input'];
  minPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export enum CertificateStatusEnum {
  Completed = 'COMPLETED',
  MediaPending = 'MEDIA_PENDING'
}

export type CertificateTemplateConnections = {
  __typename?: 'CertificateTemplateConnections';
  edges?: Maybe<Array<CertificateTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type CertificateTemplateEdge = {
  __typename?: 'CertificateTemplateEdge';
  node: CertificateTemplateType;
};

export type CertificateTemplateModelRequirement = {
  __typename?: 'CertificateTemplateModelRequirement';
  /** fields of the content on which certificate is provided */
  content?: Maybe<Array<Scalars['String']['output']>>;
  /** fields of receiver */
  user?: Maybe<Array<Scalars['String']['output']>>;
};

export enum CertificateTemplateStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

/** Scorecard Type */
export type CertificateTemplateType = {
  __typename?: 'CertificateTemplateType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** date format to populate dates in certificate */
  dateFormat?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dimensions: CertificateDimensions;
  id: Scalars['String']['output'];
  modelRequirement: CertificateTemplateModelRequirement;
  name: Scalars['String']['output'];
  /** preview of the certificate */
  preview: EntityMediaType;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CertificateTemplateStatusEnum;
  template: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CertificateTemplatepayload = {
  __typename?: 'CertificateTemplatepayload';
  certificateTemplate?: Maybe<CertificateTemplateType>;
};

export type CertificateTemplatesFilters = {
  certificateTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CertificateTemplateStatusEnum>>;
};

export type CertificateType = {
  __typename?: 'CertificateType';
  content?: Maybe<ContentEntity>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  dimensions: CertificateDimensions;
  entityId: Scalars['String']['output'];
  entityType: PerformanceTypeEnum;
  event?: Maybe<EventUnion>;
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<EntityMediaType>;
  name: Scalars['String']['output'];
  pdf?: Maybe<EntityMediaType>;
  publicLink?: Maybe<Scalars['String']['output']>;
  sharedId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CertificateStatusEnum;
  template?: Maybe<CertificateTemplateType>;
  templateId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CertificatesFilters = {
  certificateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  templateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ChangeDueDateInput = {
  contentId: Scalars['String']['input'];
  /** If this parameter is not passed then due date will be changed to No_Due_Date */
  dueDate?: InputMaybe<Scalars['String']['input']>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export enum ChannelAccessTypeEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum ChannelActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Hide = 'HIDE',
  Manage = 'MANAGE',
  Pin = 'PIN',
  Read = 'READ',
  Remove = 'REMOVE'
}

export enum ChannelAllowedToPostEnum {
  All = 'ALL',
  Staff = 'STAFF'
}

/** This returns paginated channel users */
export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  edges?: Maybe<Array<ChannelEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  node: ChannelType;
};

/** Filters applicable on channel */
export type ChannelFilters = {
  /** Filter channel based on its id */
  channelId?: InputMaybe<Scalars['String']['input']>;
};

export enum ChannelParentEntityTypeEnum {
  Article = 'ARTICLE',
  Assignment = 'ASSIGNMENT',
  Checkin = 'CHECKIN',
  Classroom = 'CLASSROOM',
  Cohort = 'COHORT',
  Community = 'COMMUNITY',
  Course = 'COURSE',
  Event = 'EVENT',
  Exercise = 'EXERCISE',
  OkrCycle = 'OKR_CYCLE',
  PeerFeedback = 'PEER_FEEDBACK',
  Task = 'TASK',
  UserSurvey = 'USER_SURVEY',
  Workspace = 'WORKSPACE'
}

export type ChannelParticipant = {
  __typename?: 'ChannelParticipant';
  emailId?: Maybe<Scalars['String']['output']>;
  federationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** participant uniqueId */
  uniqueId: Scalars['String']['output'];
};

export type ChannelParticipantEdge = {
  __typename?: 'ChannelParticipantEdge';
  node: ChannelParticipant;
};

export type ChannelParticipantsFilters = {
  /** id of channel */
  channelId: Scalars['String']['input'];
  /** user roles in channel */
  userRoles: Array<RoleTypesEnum>;
};

export type ChannelPostsSubscriptionInput = {
  /** channelIds to subscribe posts on */
  channelIds: Array<Scalars['String']['input']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<ChannelPostsSubscriptionOperation>>;
  /** parentId to filter posts */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** filter posts on types */
  types?: InputMaybe<Array<PostTypeEnum>>;
};

export enum ChannelPostsSubscriptionOperation {
  PostCreated = 'POST_CREATED',
  PostDeleted = 'POST_DELETED',
  PostReactionUpdated = 'POST_REACTION_UPDATED',
  PostUpdated = 'POST_UPDATED'
}

export type ChannelPostsSubscriptionPayload = {
  __typename?: 'ChannelPostsSubscriptionPayload';
  /** operation which happened on the post */
  operation: ChannelPostsSubscriptionOperation;
  post: PostType;
};

export enum ChannelStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED'
}

export enum ChannelSubjectsEnum {
  Attachments = 'ATTACHMENTS',
  Channel = 'CHANNEL',
  Members = 'MEMBERS',
  Post = 'POST',
  Reactions = 'REACTIONS',
  Reply = 'REPLY'
}

/** Channel Type */
export type ChannelType = {
  __typename?: 'ChannelType';
  /** Channel access type */
  accessType: ChannelAccessTypeEnum;
  /** Who is allowed to post in channel */
  allowedToPost?: Maybe<ChannelAllowedToPostEnum>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** channelUser document of context user. */
  channelUser?: Maybe<ChannelUserType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** description of channel */
  description?: Maybe<Scalars['String']['output']>;
  /** icon corresponding to the channel */
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Name of the Channel */
  name: Scalars['String']['output'];
  /** Id of the parent entity of this channel */
  parentId: Scalars['String']['output'];
  /** Type of the parent entity of this channel */
  parentType: ChannelParentEntityTypeEnum;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of channel */
  status: ChannelStatusEnum;
  /** subscription topic */
  topic: Scalars['String']['output'];
  /** Type of channel */
  type: ChannelTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User permissions on the channel */
  userPermissions: Array<UserChannelPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** Channel visibility settings */
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export enum ChannelTypeEnum {
  ActionItem = 'ACTION_ITEM',
  Announcement = 'ANNOUNCEMENT',
  CommunityDiscussion = 'COMMUNITY_DISCUSSION',
  Discussion = 'DISCUSSION',
  OkrDiscussion = 'OKR_DISCUSSION',
  PeerFeedback = 'PEER_FEEDBACK',
  Praise = 'PRAISE',
  UserSurvey = 'USER_SURVEY'
}

/** Has information of users in a channel */
export type ChannelUserType = {
  __typename?: 'ChannelUserType';
  /** channel id */
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Stores the time when user last opened the corresponding channel */
  lastViewedAt: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  unreadCount?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user id */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Filters applicable on channels */
export type ChannelsFilters = {
  /** channelIds that needs to be fetched */
  channelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** EntityId whose channel one needs to fetch */
  parentId: Scalars['String']['input'];
  sharedChannelFilters?: InputMaybe<SharedEntityFilters>;
  /** channel status to be fetched */
  status?: InputMaybe<Array<ChannelStatusEnum>>;
  /** Type of channel one needs to fetch */
  types: Array<ChannelTypeEnum>;
};

export type ChartExportMetadataType = {
  __typename?: 'ChartExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  failedPagesMetadata?: Maybe<Array<FailedPageMetadata>>;
  totalCount: Scalars['Float']['output'];
};

export type ChartExportPropsInput = {
  chartFilterGroup?: InputMaybe<FilterGroupInput>;
  chartId: Scalars['String']['input'];
  projections?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type ChartExportPropsType = {
  __typename?: 'ChartExportPropsType';
  chartFilterGroup?: Maybe<FilterGroupType>;
  chartId: Scalars['String']['output'];
  projections: Array<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum ChartTypeEnum {
  Bar = 'BAR',
  Column = 'COLUMN',
  Counter = 'COUNTER',
  Distribution = 'DISTRIBUTION',
  Donut = 'DONUT',
  Line = 'LINE',
  LinearNormalized = 'LINEAR_NORMALIZED',
  MetricsList = 'METRICS_LIST',
  MetricsOverview = 'METRICS_OVERVIEW',
  MetricSummaryList = 'METRIC_SUMMARY_LIST',
  PercentileProgress = 'PERCENTILE_PROGRESS',
  PillsList = 'PILLS_LIST',
  Polar = 'POLAR',
  Progress = 'PROGRESS',
  ProgressList = 'PROGRESS_LIST',
  Scatter = 'SCATTER',
  ScatterWithBuckets = 'SCATTER_WITH_BUCKETS',
  Table = 'TABLE',
  TaggedSummaryList = 'TAGGED_SUMMARY_LIST',
  TeamProfileRedirect = 'TEAM_PROFILE_REDIRECT',
  Timeseries = 'TIMESERIES',
  UsersList = 'USERS_LIST'
}

export type ChartWidgetPropsInput = {
  chartType?: InputMaybe<ChartTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dimensionConfigs?: InputMaybe<Array<DimensionConfigTypeInput>>;
  dimensions?: InputMaybe<Array<Scalars['String']['input']>>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  measureConfigs?: InputMaybe<Array<MeasureConfigTypeInput>>;
  measures?: InputMaybe<Array<MeasureTypeInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<Array<SortType>>;
  sorts?: InputMaybe<Array<SortInput>>;
  timeDimensions?: InputMaybe<Array<TimeDimensionTypeInput>>;
};

/** Chart Widget */
export type ChartWidgetType = {
  __typename?: 'ChartWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  chartType: ChartTypeEnum;
  columnConfigs?: Maybe<Array<ColumnConfig>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  customWidget?: Maybe<ReportingWidgetType>;
  deletedAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dimensionConfigs?: Maybe<Array<DimensionConfigType>>;
  dimensions?: Maybe<Array<Scalars['String']['output']>>;
  entity?: Maybe<Scalars['String']['output']>;
  facetConfig?: Maybe<FacetConfig>;
  filterConfigs?: Maybe<Array<FilterConfigType>>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  measureConfigs?: Maybe<Array<MeasureConfigType>>;
  measures?: Maybe<Array<MeasureType>>;
  name: Scalars['String']['output'];
  properties?: Maybe<Array<Scalars['String']['output']>>;
  /** Redirection url for chart widget type */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  showGroupData?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Array<SortTypeSchema>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  timeDimensions?: Maybe<Array<TimeDimensionType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CheckContentPublishablePayload = {
  __typename?: 'CheckContentPublishablePayload';
  isPublishable: Scalars['Boolean']['output'];
  reason?: Maybe<ContentPublishFailureReasonEnum>;
};

export type CheckinAdvanceSettingsInput = {
  /** To Allow or disallow comments on checkin */
  allowComments: Scalars['Boolean']['input'];
};

/** Input to checkin Key Result */
export type CheckinKeyResultInput = {
  /** Current Value of the key result */
  actualValue?: InputMaybe<Scalars['Float']['input']>;
  /** Id of the key result cycle */
  cycleId: Scalars['String']['input'];
  /** Optional description related to key result checkin */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checkin details in text or media */
  details?: InputMaybe<InstructionInput>;
  /** Id of the key result */
  keyResultId: Scalars['String']['input'];
  /** Id of the parent objective of key result */
  objectiveId: Scalars['String']['input'];
  /** Key Result Status */
  status: OkrStatusEnum;
};

/** Input to checkin Objective */
export type CheckinObjectiveInput = {
  /** Id of the objective cycle */
  cycleId: Scalars['String']['input'];
  /** Optional description related to objective checkin */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checkin details in text or media */
  details?: InputMaybe<InstructionInput>;
  /** Id of the objective */
  objectiveId: Scalars['String']['input'];
  /** status of objective */
  status: OkrStatusEnum;
};

/** Checkin Performance Type */
export type CheckinPerformanceType = {
  __typename?: 'CheckinPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Checkin Type */
export type CheckinType = {
  __typename?: 'CheckinType';
  agendas?: Maybe<Array<AgendaType>>;
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Discussion Id */
  commentChannelId?: Maybe<Scalars['String']['output']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  /** Description of the checkin */
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** Duration of the checkin */
  duration: Scalars['Float']['output'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Media objects related to the checkin */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  performance?: Maybe<CheckinPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Checkin Type */
export type CheckinTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Checkin Type */
export type CheckinTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Checkin Type */
export type CheckinTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Checkin Type */
export type CheckinTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Checkin Type */
export type CheckinTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Checkin Type */
export type CheckinTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Checkin Type */
export type CheckinTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Checkin Type */
export type CheckinTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type ChildJobMetaInputType = {
  completedAt?: InputMaybe<Scalars['String']['input']>;
  jobScheduleId: Scalars['String']['input'];
  status: JobEntityStatusEnum;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

/** Child job meta information */
export type ChildJobMetaType = {
  __typename?: 'ChildJobMetaType';
  completedAt?: Maybe<Scalars['String']['output']>;
  jobScheduleId: Scalars['String']['output'];
  status: JobEntityStatusEnum;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChildKrStubType = {
  __typename?: 'ChildKRStubType';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ChildKRsFromOkrResultType = {
  __typename?: 'ChildKRsFromOKRResultType';
  records: Array<ChildKrStubType>;
};

export type ChildObjectiveStubType = {
  __typename?: 'ChildObjectiveStubType';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ChildObjectivesFromOkrResultType = {
  __typename?: 'ChildObjectivesFromOKRResultType';
  records: Array<ChildObjectiveStubType>;
};

export type ChildObjectivesSetInput = {
  /** input to update child objectives */
  set: Array<UpdateObjectiveInput>;
};

export type ChildrenType = {
  __typename?: 'ChildrenType';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export enum ClassroomActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Manage = 'MANAGE',
  Read = 'READ',
  Remove = 'REMOVE'
}

/** Counts regarding different type of assignment of a classroom */
export type ClassroomContentCounts = {
  __typename?: 'ClassroomContentCounts';
  openHomeworkCount?: Maybe<Scalars['Float']['output']>;
  toDoHomeworkCount?: Maybe<Scalars['Float']['output']>;
};

export enum ClassroomEventActionsEnum {
  Add = 'ADD',
  Allow = 'ALLOW',
  ControlOthers = 'CONTROL_OTHERS',
  Create = 'CREATE',
  Edit = 'EDIT',
  End = 'END',
  Join = 'JOIN',
  Launch = 'LAUNCH',
  Leave = 'LEAVE',
  Manage = 'MANAGE',
  Participate = 'PARTICIPATE',
  Priority = 'PRIORITY',
  Read = 'READ',
  Remove = 'REMOVE',
  Start = 'START',
  View = 'VIEW'
}

export enum ClassroomEventErrorCodeEnum {
  EventAggregationNotScheduled = 'EVENT_AGGREGATION_NOT_SCHEDULED',
  EventMetricsNotAggregated = 'EVENT_METRICS_NOT_AGGREGATED',
  EventNotFound = 'EVENT_NOT_FOUND',
  EventNotUpdated = 'EVENT_NOT_UPDATED',
  FailedToCreateEvent = 'FAILED_TO_CREATE_EVENT',
  FailedToDeleteEvent = 'FAILED_TO_DELETE_EVENT'
}

export enum ClassroomEventMetricsAggregationStatusEnum {
  NotProcessed = 'NOT_PROCESSED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING'
}

export enum ClassroomEventResourceAvailabilityEnum {
  InClass = 'IN_CLASS',
  PostClass = 'POST_CLASS',
  PreClass = 'PRE_CLASS'
}

/** Event resource relation object */
export type ClassroomEventResourceType = {
  __typename?: 'ClassroomEventResourceType';
  availability: ClassroomEventResourceAvailabilityEnum;
  resourceId: Scalars['String']['output'];
};

export enum ClassroomEventStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED',
  Skipped = 'SKIPPED',
  Started = 'STARTED'
}

export enum ClassroomEventSubjectsEnum {
  Activity = 'ACTIVITY',
  Audio = 'AUDIO',
  Chat = 'CHAT',
  ClassroomEvent = 'CLASSROOM_EVENT',
  MemberList = 'MEMBER_LIST',
  Notepad = 'NOTEPAD',
  One = 'ONE',
  Poll = 'POLL',
  Quiz = 'QUIZ',
  RaiseHand = 'RAISE_HAND',
  Recordings = 'RECORDINGS',
  ScreenShare = 'SCREEN_SHARE',
  StudyMaterial = 'STUDY_MATERIAL',
  VideoShare = 'VIDEO_SHARE',
  Whiteboard = 'WHITEBOARD'
}

/** Event Type. This can be used in classrooms as well as individually */
export type ClassroomEventType = {
  __typename?: 'ClassroomEventType';
  /** Classroom of which the event is part of */
  classroom?: Maybe<ClassroomType>;
  classroomId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Default discussion channel ID for the event */
  defaultDiscussionChannelId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Duration of event in minutes */
  duration: Scalars['Int']['output'];
  /** Completion timestamp of event in ISO-8601 format. It is set when the status of event is marked completed */
  eventCompletionTimestamp?: Maybe<Scalars['String']['output']>;
  /** Id of eventSchedule that created the event */
  eventScheduleId?: Maybe<Scalars['String']['output']>;
  /** Start timestamp of event in ISO-8601 format */
  eventStartTimestamp: Scalars['String']['output'];
  eventType?: Maybe<ClassroomEventTypeEnum>;
  /** All users of this group have access to this event */
  groupIds: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Start timestamp of metrics aggregation process */
  metricsAggregationStartTimestamp?: Maybe<Scalars['String']['output']>;
  /** Current status of aggregation of metrics */
  metricsAggregationStatus: ClassroomEventMetricsAggregationStatusEnum;
  name: Scalars['String']['output'];
  /** Id of postback schedule on which the postback will invoke aggregation */
  postbackScheduleId?: Maybe<Scalars['String']['output']>;
  /** Id of the recurrenceRule that scheduled the event */
  recurrenceRuleId?: Maybe<Scalars['String']['output']>;
  /** Holds resources that is available with this live session */
  resources?: Maybe<Array<ClassroomEventResourceType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Current status of the event */
  status: ClassroomEventStatusEnum;
  /** Type of the event */
  type: ClassroomEventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User Permissions on event */
  userPermissions: Array<UserClassroomEventPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Number of users part of the event */
  usersCount?: Maybe<Scalars['Int']['output']>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum ClassroomEventTypeEnum {
  Exam = 'EXAM',
  Lecture = 'LECTURE'
}

export enum ClassroomPointStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

/** Aggregated points of a user in classroom */
export type ClassroomPointType = {
  __typename?: 'ClassroomPointType';
  /** All user behavior in a classroom */
  behaviors: Array<UserBehaviorStatsType>;
  /** ClassroomId in which points has been rewarded */
  classroomId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of classroomUser */
  status: ClassroomPointStatusEnum;
  /** Total number of points rewarded in a classroom */
  totalPoints: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user to which points has been awarded */
  user?: Maybe<UserType>;
  /** User to which points has been rewarded */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Classroom Settings Type */
export type ClassroomSettingType = {
  __typename?: 'ClassroomSettingType';
  categories?: Maybe<Array<CategoryType>>;
  classroomId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** GradeBook setting type */
  gradeBookSettings: GradeBookSettingType;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum ClassroomSettingsGradeTypeEnum {
  LetterGrade = 'LETTER_GRADE',
  Percentage = 'PERCENTAGE'
}

export enum ClassroomStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum ClassroomSubjectsEnum {
  AllPointActivity = 'ALL_POINT_ACTIVITY',
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  Channel = 'CHANNEL',
  Classroom = 'CLASSROOM',
  Event = 'EVENT',
  Gradebook = 'GRADEBOOK',
  Invitation = 'INVITATION',
  MyPointActivity = 'MY_POINT_ACTIVITY',
  Participants = 'PARTICIPANTS',
  PointActivity = 'POINT_ACTIVITY',
  Resource = 'RESOURCE'
}

/** Classroom Type */
export type ClassroomType = {
  __typename?: 'ClassroomType';
  /** Counts regarding different type of assignment of a classroom */
  assignmentCounts?: Maybe<ClassroomContentCounts>;
  /** classroomuser document of context user. Only be used when single user classrooms are fetched */
  classroomUser?: Maybe<ClassroomUserType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultAnnouncementChannelId?: Maybe<Scalars['String']['output']>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  defaultRoleId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Tentative duration of classroom in days */
  duration?: Maybe<Scalars['Int']['output']>;
  hasHomeworks: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  inviteCode?: Maybe<InvitationCodeType>;
  /** All the media corresponding to classroom */
  media: Array<DeprecatedMediaType>;
  name: Scalars['String']['output'];
  roles?: Maybe<Array<RoleType>>;
  /** Classroom setting */
  settings?: Maybe<ClassroomSettingType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: ClassroomStatusEnum;
  /** Categorize the classroom into subjects */
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User permissions on the classroom */
  userPermissions: Array<UserClassroomPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount?: Maybe<Scalars['Int']['output']>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** custom watermark for classes */
  watermark?: Maybe<Scalars['String']['output']>;
};

export enum ClassroomUserStatusEnum {
  Active = 'ACTIVE',
  Archive = 'ARCHIVE'
}

/** Classroom users */
export type ClassroomUserType = {
  __typename?: 'ClassroomUserType';
  classroom: ClassroomType;
  /** Classroom Id */
  classroomId: Scalars['String']['output'];
  classroomPoint?: Maybe<ClassroomPointType>;
  classroomPointsRanking?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** User onboard status in classroom */
  onboardStatus: OnboardStatusEnum;
  /** RoleId for user's role for classroom */
  roleId?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of classroomUser */
  status: ClassroomUserStatusEnum;
  /** Classroom user's total points received */
  totalPoints?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User Data of classroomUser */
  user?: Maybe<UserType>;
  /** User Id of classroom */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CloseUserSurveyInput = {
  surveyId: Scalars['String']['input'];
};

export type CloseUserSurveyPayload = {
  __typename?: 'CloseUserSurveyPayload';
  success: Scalars['Boolean']['output'];
  userSurvey?: Maybe<UserSurveyType>;
};

export type CollectionContentType = {
  __typename?: 'CollectionContentType';
  collectionId: Scalars['String']['output'];
  contentIds: Array<Scalars['String']['output']>;
};

export type ColumnConfig = {
  __typename?: 'ColumnConfig';
  label: Scalars['String']['output'];
  uniqueId: Scalars['String']['output'];
};

export enum CommandEnum {
  AddTime = 'ADD_TIME',
  ContentAccessed = 'CONTENT_ACCESSED',
  ContentVisit = 'CONTENT_VISIT',
  HandRaise = 'HAND_RAISE',
  SetProgress = 'SET_PROGRESS',
  SetView = 'SET_VIEW',
  UpdateEventRecordingProgress = 'UPDATE_EVENT_RECORDING_PROGRESS',
  UpdateEventUserSession = 'UPDATE_EVENT_USER_SESSION'
}

/** Edge */
export type CommonProviderEdge = {
  __typename?: 'CommonProviderEdge';
  node: CommonProviderType;
};

/** Common provider model */
export type CommonProviderType = {
  __typename?: 'CommonProviderType';
  availableOn: ProviderAvailableOnEnum;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  formInputs?: Maybe<Array<FormComponentType>>;
  id: Scalars['String']['output'];
  logoUrl: Scalars['String']['output'];
  marketplaceContentCount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Type of the external content */
  verificationMethod?: Maybe<VerificationMethodEnum>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CommunityChannelInfoInput = {
  /** who is allowed to post in the channel */
  allowedToPost: ChannelAllowedToPostEnum;
  /** block id containing community channel */
  channelBlockId?: InputMaybe<Scalars['String']['input']>;
  /** description of channel */
  description?: InputMaybe<Scalars['String']['input']>;
  /** icon corresponding to the channel */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** name of channel */
  name: Scalars['String']['input'];
  /** status of channel */
  status: ChannelStatusEnum;
  /** visibility settings of channel */
  visibilitySettings: VisibilitySettingsInput;
};

/** Community defining block */
export type CommunityType = {
  __typename?: 'CommunityType';
  blockTree?: Maybe<Array<BaseBlockType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultSpaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** String Templates for joining */
  joiningTemplates?: Maybe<Array<Scalars['String']['output']>>;
  /** Root block Id */
  rootBlockId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CompleteReviewCycleForManagerReporteesInput = {
  /** Id of manager for reportees */
  managerId: Scalars['String']['input'];
  /** Id of the review cycle */
  reviewCycleId: Scalars['String']['input'];
};

export type CompletedLearningsWidgetPreviewType = {
  __typename?: 'CompletedLearningsWidgetPreviewType';
  /** completed contents */
  contents?: Maybe<Array<Scalars['JSON']['output']>>;
  type: WidgetTypeEnum;
};

export type CompletedLearningsWidgetType = {
  __typename?: 'CompletedLearningsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  contents: ContentConnection;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type CompletedLearningsWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type CompletionSettingsInput = {
  considerRecordingProgress?: InputMaybe<Scalars['Boolean']['input']>;
  recurringSessionCompletionRollupType?: InputMaybe<RecurringSessionCompletionRollupTypeEnum>;
  requirePreWorkPostWork: Scalars['Boolean']['input'];
};

/** Event metadata */
export type CompletionSettingsType = {
  __typename?: 'CompletionSettingsType';
  considerRecordingProgress?: Maybe<Scalars['Boolean']['output']>;
  recurringSessionCompletionRollupType?: Maybe<RecurringSessionCompletionRollupTypeEnum>;
  requirePreWorkPostWork: Scalars['Boolean']['output'];
};

export type ConditionActionPropertyInput = {
  type: FilterPropertyTypeEnum;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ConditionActionPropertyType = {
  __typename?: 'ConditionActionPropertyType';
  type: FilterPropertyTypeEnum;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type ConferenceSubscriptionInput = {
  conferenceBridgeId: Scalars['String']['input'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<ConferenceSubscriptionOperationEnum>>;
};

export enum ConferenceSubscriptionOperationEnum {
  ConferenceUpdated = 'CONFERENCE_UPDATED'
}

export type ConferenceSubscriptionPayload = {
  __typename?: 'ConferenceSubscriptionPayload';
  conferenceBridgeId: Scalars['String']['output'];
  event?: Maybe<EventUnion>;
  operation: ConferenceSubscriptionOperationEnum;
};

export type ConfigType = {
  __typename?: 'ConfigType';
  authConfig?: Maybe<AuthConfigType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  difficultyWeightConfig?: Maybe<DifficultyWeightConfigType>;
  globalSearchAndLibraryConfig?: Maybe<GlobalSearchAndLibraryConfigType>;
  id: Scalars['String']['output'];
  /** navConfig | authConfig | ... */
  key: Scalars['String']['output'];
  languageConfig?: Maybe<LanguageConfigType>;
  navConfig?: Maybe<NavConfigProperties>;
  publishContentConfigProperties?: Maybe<PublishContentConfigPropertiesType>;
  quiz?: Maybe<QuizConfigType>;
  quizConfig?: Maybe<QuizConfigType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillRubricConfigs?: Maybe<Array<SkillRubricConfigType>>;
  skillScoreWeightConfig?: Maybe<SkillScoreWeightConfigType>;
  timeZoneConfig?: Maybe<TimeZoneConfigType>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ConfigsFilters = {
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentCollectionGroupType = {
  __typename?: 'ContentCollectionGroupType';
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  contentVisibilitySettings?: Maybe<VisibilitySettingsType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filterGroup?: Maybe<FilterGroupV2Type>;
  id: Scalars['String']['output'];
  isAutoSyncGroup: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  memberCount: Scalars['Float']['output'];
  metricSnapshot?: Maybe<MetricSnapshot>;
  name: Scalars['String']['output'];
  reSyncedAt?: Maybe<Scalars['String']['output']>;
  sampleMembers: Array<ContentEntity>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: GroupStatusEnum;
  type: GroupTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type ContentCollectionGroupTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type ContentCollectionGroupTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

/** This returns paginated contents */
export type ContentConnection = {
  __typename?: 'ContentConnection';
  edges?: Maybe<Array<ContentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export enum ContentCreationStrategyEnum {
  Admin = 'ADMIN',
  SelfLearning = 'SELF_LEARNING',
  System = 'SYSTEM'
}

/** Edge */
export type ContentEdge = {
  __typename?: 'ContentEdge';
  node: ContentEntity;
};

/** It will resolve homework or resource */
export type ContentEntity = ArticleContentType | BaseResourceSchema | CheckinType | CourseContentType | ExerciseType | ExternalContentType | ExternalEventContentType | HomeworkType | LiveEventContentType | OfflineEventContentType | PathContentType | QuizContentType | RecurringExternalEventContentType | RecurringLiveEventContentType | RecurringOfflineEventContentType | ScormContentType | SurveyContentType | TaskContentType;

export type ContentFilter = {
  id: Scalars['String']['input'];
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<ResourceStatusEnum>>;
  versionStatus?: InputMaybe<Scalars['String']['input']>;
};

export enum ContentFilterPurposeEnum {
  ReportFilter = 'REPORT_FILTER'
}

export type ContentFilters = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Return all path-content whose childResources these childResourceIds */
  childResourceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  classroomId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateFilter>;
  /** Deprecated. Use createdAt instead. Deprecation Reason: This filter is of type TimestampRangeInput. Use createdAt field which has type DateFilter */
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  creationStrategies?: InputMaybe<Array<ContentCreationStrategyEnum>>;
  draftCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Return all content having the following dripTypes */
  dripTypes?: InputMaybe<Array<DripTypeEnum>>;
  /** Array of two dates between which the dueDateTimestampRange of assignment lies */
  dueDateRange?: InputMaybe<Array<Scalars['String']['input']>>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityTypes?: InputMaybe<Array<QuizEntityTypeEnum>>;
  eventRecurringCategories?: InputMaybe<Array<RecurringTypeEnum>>;
  eventStatus?: InputMaybe<Array<EventStatusEnum>>;
  excludeReceivedContents?: InputMaybe<Scalars['Boolean']['input']>;
  /** if true, will fetch event containers also */
  fetchEventContainers?: InputMaybe<Scalars['Boolean']['input']>;
  fileCategoriesFilter?: InputMaybe<FileCategoriesFilter>;
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  filterPurpose?: InputMaybe<ContentFilterPurposeEnum>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  /** get static or dynamic contents for given groupIds */
  groupFilter?: InputMaybe<EntityGroupFilter>;
  /** ContentIds */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  intent?: InputMaybe<ContentQueryIntentEnum>;
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  journeyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** if true, will fetch those contents which are linked with external resource */
  linkedExternalResource?: InputMaybe<Scalars['Boolean']['input']>;
  marketplaceContentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** title of content */
  name?: InputMaybe<Scalars['String']['input']>;
  /** parent content Ids for surveys */
  parentContentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Parent id of the content */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** parent survey Ids for surveys */
  parentSurveyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  performanceFilters?: InputMaybe<ContentPerformanceFilters>;
  reviewerConfig?: InputMaybe<ReviewerConfigFilters>;
  /** root blockId of content */
  rootBlockIds?: InputMaybe<Array<Scalars['String']['input']>>;
  scorecardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  sharedContentFilters?: InputMaybe<SharedEntityFilters>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<ResourceStatusEnum>>;
  /** Filter based on subtype of resource. */
  subTypes?: InputMaybe<Array<ResourceSubTypeEnum>>;
  /** get resources based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  /** tag id of content */
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tagOptionsOutputOrder?: InputMaybe<TagOptionsOutputOrderInput>;
  types?: InputMaybe<Array<ResourceTypeEnum>>;
  /** filter and return contents by recency of usage by current user */
  userRecents?: InputMaybe<Scalars['Boolean']['input']>;
  workspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentLockSettings = {
  __typename?: 'ContentLockSettings';
  availableFrom: Scalars['String']['output'];
  contentId: Scalars['String']['output'];
  contentType: DripByEnum;
  isAvailable: Scalars['Boolean']['output'];
};

/** This returns paginated Performances */
export type ContentPerformanceConnection = {
  __typename?: 'ContentPerformanceConnection';
  edges?: Maybe<Array<ContentPerformanceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type ContentPerformanceEdge = {
  __typename?: 'ContentPerformanceEdge';
  node: ContentPerformanceEntity;
};

/** It will resolve performances */
export type ContentPerformanceEntity = ArticlePerformanceType | BasePerformanceSchema | CoursePerformanceType | EventPerformanceType | ExercisePerformanceType | ExternalContentPerformanceType | HomeworkPerformanceType | PathPerformanceType | QuestionPerformanceType | QuizPerformanceType | ScormPerformanceType | SubTaskPerformanceType | SurveyPerformanceType | TaskPerformanceType | TaskSectionPerformanceType;

export type ContentPerformanceFilters = {
  status: Array<PerformanceStatusFilterEnum>;
  userId: Scalars['String']['input'];
};

/** Assignment Performance Metrics */
export type ContentPerformanceMetricsType = {
  __typename?: 'ContentPerformanceMetricsType';
  assignedCount?: Maybe<Scalars['Float']['output']>;
  averageScore?: Maybe<Scalars['Float']['output']>;
  awaitingCount?: Maybe<Scalars['Float']['output']>;
  contentSubmittedCount?: Maybe<Scalars['Float']['output']>;
  excusedCount?: Maybe<Scalars['Float']['output']>;
  gradedCount?: Maybe<Scalars['Float']['output']>;
  highestScore?: Maybe<Scalars['Float']['output']>;
  lowestScore?: Maybe<Scalars['Float']['output']>;
  missedCount?: Maybe<Scalars['Float']['output']>;
  notStartedCount?: Maybe<Scalars['Float']['output']>;
  returnedCount?: Maybe<Scalars['Float']['output']>;
  submittedCount?: Maybe<Scalars['Float']['output']>;
  toReviewCount?: Maybe<Scalars['Float']['output']>;
};

/** Content Properties */
export type ContentProfile = {
  __typename?: 'ContentProfile';
  duration: Scalars['Float']['output'];
  /** id of the Content */
  id: Scalars['String']['output'];
  /** type of the Content */
  type: ResourceTypeEnum;
};

export enum ContentPublishFailureReasonEnum {
  MandatoryTagsNotAttached = 'MANDATORY_TAGS_NOT_ATTACHED'
}

export enum ContentPublishedEventTypeEnum {
  AdditionOrRemovalOfEmbeddedContents = 'ADDITION_OR_REMOVAL_OF_EMBEDDED_CONTENTS',
  ArchiveLessonPerformances = 'ARCHIVE_LESSON_PERFORMANCES',
  ForceCompleteLessonsOnContentPublish = 'FORCE_COMPLETE_LESSONS_ON_CONTENT_PUBLISH',
  HandleCollectionVisibilityChanges = 'HANDLE_COLLECTION_VISIBILITY_CHANGES',
  HandleContentCategoriesUpdate = 'HANDLE_CONTENT_CATEGORIES_UPDATE',
  HandleContentSkillsUpdate = 'HANDLE_CONTENT_SKILLS_UPDATE',
  HandleContentTagsUpdate = 'HANDLE_CONTENT_TAGS_UPDATE',
  HandleReviewerUpdates = 'HANDLE_REVIEWER_UPDATES',
  InvalidateContentProfiles = 'INVALIDATE_CONTENT_PROFILES',
  MajorVersionUpdate = 'MAJOR_VERSION_UPDATE',
  PropogatePathContentVisibilityToChildContents = 'PROPOGATE_PATH_CONTENT_VISIBILITY_TO_CHILD_CONTENTS',
  RecalibrateLearnerProgress = 'RECALIBRATE_LEARNER_PROGRESS',
  SharedContentUpdate = 'SHARED_CONTENT_UPDATE',
  UdpateResourceVersionForContentPerformances = 'UDPATE_RESOURCE_VERSION_FOR_CONTENT_PERFORMANCES',
  UpdateCourseLessonAvailability = 'UPDATE_COURSE_LESSON_AVAILABILITY',
  UpdateResourceVersionForNotCompletedPerformances = 'UPDATE_RESOURCE_VERSION_FOR_NOT_COMPLETED_PERFORMANCES',
  UpdateSubworkspacesAndPermissions = 'UPDATE_SUBWORKSPACES_AND_PERMISSIONS'
}

export enum ContentQueryIntentEnum {
  Assign = 'ASSIGN',
  Read = 'READ'
}

export type ContentTodoType = {
  __typename?: 'ContentTodoType';
  content: ContentEntity;
  type: TodoTypeEnum;
};

/** Product Content Types */
export type ContentType = {
  __typename?: 'ContentType';
  /** id of content */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Description of content */
  description?: Maybe<Scalars['String']['output']>;
  /** downloadable Ids */
  downloadableIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Duration of content in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  /** Represents media in content  */
  media?: Maybe<Array<DeprecatedMediaType>>;
  /** Name or title of content */
  name: Scalars['String']['output'];
  /** previewable Ids */
  previewableIds?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Type of product content */
  type: ProductContentTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum ContentTypeEnum {
  SubTask = 'SUB_TASK',
  Task = 'TASK',
  TaskSection = 'TASK_SECTION'
}

export enum ContentWidgetPopulationTypeEnum {
  Collection = 'COLLECTION',
  FilterGroup = 'FILTER_GROUP',
  Static = 'STATIC'
}

export enum ContentWidgetSortTypeEnum {
  Alphabetically = 'ALPHABETICALLY',
  Newest = 'NEWEST',
  Oldest = 'OLDEST'
}

export type ContentsWidgetPreviewType = {
  __typename?: 'ContentsWidgetPreviewType';
  /** contents */
  dummyContents?: Maybe<Array<Scalars['JSON']['output']>>;
  /** contents */
  realContents?: Maybe<Array<ContentEntity>>;
  type: WidgetTypeEnum;
};

export type ContentsWidgetPropsInput = {
  collectionId?: InputMaybe<Scalars['String']['input']>;
  populationType: ContentWidgetPopulationTypeEnum;
  sortType?: InputMaybe<ContentWidgetSortTypeEnum>;
  staticIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentsWidgetType = {
  __typename?: 'ContentsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  /** collection id */
  collectionId?: Maybe<Scalars['String']['output']>;
  /** contents */
  contents: ContentConnection;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  populationType: ContentWidgetPopulationTypeEnum;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sortType: ContentWidgetSortTypeEnum;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** static content ids */
  staticIds?: Maybe<Array<Scalars['String']['output']>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type ContentsWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  facetValueInputs?: InputMaybe<Array<FacetValueInput>>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sorts?: InputMaybe<Array<SortType>>;
};

export type ContinueLearningWidgetPreviewType = {
  __typename?: 'ContinueLearningWidgetPreviewType';
  /** ongoing contents */
  contents?: Maybe<Array<Scalars['JSON']['output']>>;
  type: WidgetTypeEnum;
};

export type ContinueLearningWidgetType = {
  __typename?: 'ContinueLearningWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  contents: ContentConnection;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type ContinueLearningWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type ConvertMediaInput = {
  /** mimetype of the media to be converted from */
  from: Scalars['String']['input'];
  /** purpose of the job */
  purpose?: InputMaybe<JobPurposeEnum>;
  /** mimetype of the media to be converted to */
  to: Scalars['String']['input'];
  /** url of the media to be converted */
  url: Scalars['String']['input'];
};

/** Response received on convert media mutation */
export type ConvertMediaPayload = {
  __typename?: 'ConvertMediaPayload';
  /** job id of the convert media job */
  jobScheduleId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Filters applicable on correctOptions */
export type CorrectOptionsFilters = {
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentType = {
  __typename?: 'CourseContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  courseSettings?: Maybe<CourseSettings>;
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  creatorCommentChannel?: Maybe<ChannelType>;
  /** comment channel for creators */
  creatorCommentChannelId: Scalars['String']['output'];
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dripSettings: DripSettingsType;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  duplicatedFrom?: Maybe<Scalars['String']['output']>;
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  /** Feedback Questions for the course */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  instructors: Array<InstructorType>;
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  /** duration of content */
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  performance?: Maybe<CoursePerformanceType>;
  /** Ids of prerequisites related to the content */
  prerequisiteIds?: Maybe<Array<Scalars['String']['output']>>;
  prerequisites: Array<ContentEntity>;
  /** Storing media of course-preview */
  preview?: Maybe<EntityMediaType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  /** Root blockId of the course */
  rootBlockId?: Maybe<Scalars['String']['output']>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Ids of skill related to the content */
  skillIds: Array<Scalars['String']['output']>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  /** Tagline for course */
  tagline?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionConfigs?: Maybe<Array<VersionConfig>>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeRootBlockIdArgs = {
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type CourseInstructorInputType = {
  externalInstructor?: InputMaybe<ExternalContentInstructorInputType>;
  internalUserId?: InputMaybe<Scalars['String']['input']>;
};

export type CourseLessonResultType = {
  __typename?: 'CourseLessonResultType';
  text: Scalars['String']['output'];
};

export type CourseOutlineInput = {
  sections?: InputMaybe<Array<CourseOutlineSectionInput>>;
};

export type CourseOutlineLessonInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CourseOutlineLessonStubType = {
  __typename?: 'CourseOutlineLessonStubType';
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CourseOutlineResultType = {
  __typename?: 'CourseOutlineResultType';
  description?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<CourseOutlineSectionStubType>>;
  title: Scalars['String']['output'];
};

export type CourseOutlineSectionInput = {
  lessons?: InputMaybe<Array<CourseOutlineLessonInput>>;
  title: Scalars['String']['input'];
};

export type CourseOutlineSectionStubType = {
  __typename?: 'CourseOutlineSectionStubType';
  lessons?: Maybe<Array<CourseOutlineLessonStubType>>;
  title: Scalars['String']['output'];
};

/** Course Performance Type */
export type CoursePerformanceType = {
  __typename?: 'CoursePerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  lastScrollPosition?: Maybe<Scalars['Float']['output']>;
  learningType?: Maybe<LearningTypeEnum>;
  /** It stores the current lesson lessonPage progress */
  lessonPagePerformance?: Maybe<Scalars['Float']['output']>;
  lockSettings?: Maybe<Array<ContentLockSettings>>;
  masteryScore?: Maybe<Scalars['Float']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Percentage score: avg of all child quizzes */
  scorePercentage?: Maybe<Scalars['Float']['output']>;
  scrollPosition?: Maybe<Scalars['Float']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Will contain various course settings */
export type CourseSettings = {
  __typename?: 'CourseSettings';
  /**  flag to check if review is enabled or not */
  isReviewEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateActionItemInput = {
  assignedToId?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<StatusCategoryEnum>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<RelatedEntityInput>;
  /** External Link of the actionItem */
  externalLink?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  /** instructions for the learner */
  instruction?: InputMaybe<InstructionInput>;
  /** Details for external entity */
  integrationConfig?: InputMaybe<ActionItemIntegrationConfigInput>;
  /** Details for external entity */
  metadata?: InputMaybe<ActionItemMetadataInput>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<ActionItemPriorityEnum>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<EntityTagsInput>;
  title: Scalars['String']['input'];
  /** Attach Task to user level actions */
  userEntityActionsInput?: InputMaybe<UserEntityActionsInput>;
};

export type CreateActionItemsInput = {
  /** Multiple action items */
  actionItems?: InputMaybe<Array<CreateActionItemInput>>;
  /** Config for task creation */
  taskCreationConfig: TaskCreationConfig;
};

export type CreateActionPlanInput = {
  actionables?: InputMaybe<Array<Scalars['String']['input']>>;
  cycleId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  focusAreas: Array<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<ActionPlanOwnerInput>;
  parentEntities: Array<Scalars['String']['input']>;
  status?: InputMaybe<ActionPlanStatusEnum>;
  subType?: InputMaybe<ActionPlanSubTypeEnum>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateActionPlanPayload = {
  __typename?: 'CreateActionPlanPayload';
  actionPlan: ActionPlanType;
};

/** Agenda creation Input */
export type CreateAgendaInput = {
  /** Database related to the Agenda */
  database: AgendaDatabaseInput;
  /** Description of Agenda */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Agenda attachments */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Name of Agenda */
  name: Scalars['String']['input'];
  /** Agenda Type */
  type: AgendaTypeEnum;
};

export type CreateAgendaItemInput = {
  creationCadenceConfig?: InputMaybe<CadenceConfigInput>;
  entity: AgendaItemEntityInput;
  /** Agenda Instructions in text or media */
  instruction?: InputMaybe<InstructionInput>;
  sourceType?: InputMaybe<AgendaItemEntityTypeEnum>;
  subType: ActionItemSubTypeEnum;
  title: Scalars['String']['input'];
};

export type CreateAgendaItemTemplateInput = {
  name: Scalars['String']['input'];
};

export type CreateAnnouncementInput = {
  availableFrom: Scalars['String']['input'];
  availableTill: AnnouncementAvailableTillInput;
  description?: InputMaybe<Scalars['String']['input']>;
  isOptional: Scalars['Boolean']['input'];
  medias?: InputMaybe<Array<InputMaybe<CreateMediaInput>>>;
  name: Scalars['String']['input'];
  shouldAllowReactions?: InputMaybe<Scalars['Boolean']['input']>;
  showOnlyToNewEmployees: Scalars['Boolean']['input'];
  subWorkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateAnnouncementPayload = {
  __typename?: 'CreateAnnouncementPayload';
  announcement: AnnouncementType;
};

export type CreateArticleInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of article-cover */
  cover?: InputMaybe<CreateMediaInput>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Create Article Response */
export type CreateArticlePayload = {
  __typename?: 'CreateArticlePayload';
  article?: Maybe<ArticleContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateBookmarkInput = {
  /** Id of the content */
  entityId: Scalars['String']['input'];
  /** Type of content to bookmark */
  entityType: ResourceTypeEnum;
};

export type CreateBookmarkPayload = {
  __typename?: 'CreateBookmarkPayload';
  /** created bookmark */
  bookmark?: Maybe<BookmarkType>;
};

export enum CreateChannelErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR'
}

export type CreateChannelPayload = {
  __typename?: 'CreateChannelPayload';
  channel?: Maybe<ChannelType>;
  errorCode?: Maybe<CreateChannelErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateChartInput = {
  chartType: ChartTypeEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  dimensionConfigs?: InputMaybe<Array<DimensionConfigTypeInput>>;
  dimensions?: InputMaybe<Array<Scalars['String']['input']>>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  measureConfigs?: InputMaybe<Array<MeasureConfigTypeInput>>;
  measures?: InputMaybe<Array<MeasureTypeInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<Array<SortType>>;
  timeDimensions?: InputMaybe<Array<TimeDimensionTypeInput>>;
};

export type CreateChartPayload = {
  __typename?: 'CreateChartPayload';
  chart: ChartWidgetType;
};

/** Checkin creation Input */
export type CreateCheckinInput = {
  /** To Allow or disallow advanceSettings */
  advanceSettings?: InputMaybe<CheckinAdvanceSettingsInput>;
  /** Agendas related to the checkin */
  agendas: Array<CreateAgendaInput>;
  /** Description about Checkin */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of checkin in seconds */
  duration: Scalars['Float']['input'];
  /** Checkin attachments */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Name of checkin */
  name: Scalars['String']['input'];
  tags?: InputMaybe<EntityTagsInput>;
};

/** Create Checkin-Content Response */
export type CreateCheckinPayload = {
  __typename?: 'CreateCheckinPayload';
  checkin?: Maybe<CheckinType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Defines create input for database view */
export type CreateDatabaseViewInput = {
  dbId: Scalars['String']['input'];
  dbMetadataId?: InputMaybe<Scalars['String']['input']>;
  format: DatabaseViewFormat;
  name: Scalars['String']['input'];
  viewType: ViewTypeEnum;
};

/** Response to create database view operation */
export type CreateDatabaseViewPayload = {
  __typename?: 'CreateDatabaseViewPayload';
  errorCode?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  /** Returns created view */
  view?: Maybe<DatabaseViewType>;
};

/** input for creating a new department under a tenant */
export type CreateDepartmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Input props rquired for creating a department */
export type CreateDepartmentInputPropsType = {
  __typename?: 'CreateDepartmentInputPropsType';
  /** Description of the department */
  description?: Maybe<Scalars['String']['output']>;
  /** Name of the department */
  name: Scalars['String']['output'];
};

export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  /** Created Department */
  department: DepartmentType;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateEmailDigestInput = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<EmailDigestResourceTypeEnum>;
  exportMetadata?: InputMaybe<ExportMetadataInput>;
  fileFormat: EmailDigestFileFormatEnum;
  name: Scalars['String']['input'];
  recipients: Array<RecipientInfoInput>;
  reportId?: InputMaybe<Scalars['String']['input']>;
  schedule: ScheduleInfoInput;
};

export type CreateEventInput = {
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionSettings?: InputMaybe<CompletionSettingsInput>;
  /** Storing media of course-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['String']['input'];
  externalEventProps?: InputMaybe<ExternalEventProps>;
  /** Create an instance of parent */
  flexiblePermissionGroupId?: InputMaybe<Scalars['String']['input']>;
  instructorIds: Array<Scalars['String']['input']>;
  liveEventProps?: InputMaybe<LiveEventProps>;
  name: Scalars['String']['input'];
  objective: Scalars['String']['input'];
  offlineEventProps?: InputMaybe<OfflineEventProps>;
  /** Create an instance of parent */
  parentEventId?: InputMaybe<Scalars['String']['input']>;
  postWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  postwork?: InputMaybe<SetEventWorkConfigInput>;
  preWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  prework?: InputMaybe<SetEventWorkConfigInput>;
  recurrenceSchedule?: InputMaybe<ScheduleInfoInput>;
  /** depicts the subtype for session */
  recurringCategory?: InputMaybe<RecurringTypeEnum>;
  registrationSettings?: InputMaybe<RegistrationSettingsInput>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime: Scalars['String']['input'];
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type: EventTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateExerciseInput = {
  /** Exercise Comments/ and other advance level settings */
  advanceSettings?: InputMaybe<ExerciseAdvanceSettingsInput>;
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionReviewRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Storing media of exercise-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  exerciseQuestions?: InputMaybe<Array<ExerciseQuestionInput>>;
  /** instructions for the learner */
  instruction?: InputMaybe<ExerciseInstructionInput>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  reviewType?: InputMaybe<ResourceReviewTypeEnum>;
  reviewerConfig?: InputMaybe<ReviewerConfigInput>;
  /** instructions for the reviewer */
  reviewerInstruction?: InputMaybe<ExerciseInstructionInput>;
  /** scorecards */
  scorecardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateExercisePayload = {
  __typename?: 'CreateExercisePayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  /** Created Assignment */
  exercise?: Maybe<ExerciseType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateExternalContentInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  externalResourceInfo?: InputMaybe<ExternalResourceInfoInput>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  marketplaceContentId?: InputMaybe<Scalars['String']['input']>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Id of the external content provider */
  providerId?: InputMaybe<Scalars['String']['input']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  /** Method to verify completion of the external content */
  verificationMethod?: InputMaybe<VerificationMethodInputType>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Payload for create external content mutation */
export type CreateExternalContentPayload = {
  __typename?: 'CreateExternalContentPayload';
  externalContent?: Maybe<ExternalContentType>;
};

/** Feedback provided by the user */
export type CreateFeedbackInput = {
  /** Entity Id */
  entityId: Scalars['String']['input'];
  /** Type of Enum */
  entityType: EntityTypeEnum;
  metadata?: InputMaybe<FeedbackMetadataInput>;
  questionScope?: InputMaybe<FeedbackQuestionScopeEnum>;
  /** Feedback questions and responses */
  responses: Array<FeedbackResponseInput>;
  /** User who is giving the feedback */
  userId: Scalars['String']['input'];
};

export type CreateFileInput = {
  /** files or folder */
  category: FileCategoryEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** contains type of file */
  includes?: InputMaybe<Array<FileContentTypeEnum>>;
  name: Scalars['String']['input'];
  /** order in parent */
  orderInParent?: InputMaybe<Scalars['Float']['input']>;
  /** category or normal file */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** Location under which folders/file will be searched */
  path?: InputMaybe<Scalars['String']['input']>;
  /** category or normal file */
  purpose?: InputMaybe<FilePurposeEnum>;
  visibilitySettings: VisibilitySettingsInput;
};

export type CreateGenerativeTaskInput = {
  generativeTaskContextId?: InputMaybe<Scalars['String']['input']>;
  input: GenerativeTaskPropsInput;
  processSync?: InputMaybe<Scalars['Boolean']['input']>;
  type: GenerativeTaskTypeEnum;
};

export type CreateGroupInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  contentVisibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  name: Scalars['String']['input'];
  systemGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  type: GroupTypeEnum;
  userCollectionGroupProps?: InputMaybe<UserCollectionGroupPropsInput>;
};

export type CreateImportJobInput = {
  childJobs?: InputMaybe<Array<ChildJobMetaInputType>>;
  customJobProcessor?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<JobInputPropsInput>;
  /** url and format of the media associated with the job */
  mediaUrls?: InputMaybe<Array<MediaUrlInput>>;
  /** purpose of the job */
  purpose?: InputMaybe<JobPurposeEnum>;
  settings?: InputMaybe<JobSettingsInput>;
};

export type CreateJourneyInput = {
  iconSettings?: InputMaybe<IconSettingsInput>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateJourneyStepInput = {
  actionEntities: Array<InputMaybe<JourneyStepActionEntitiesInput>>;
  createQuestionsInput?: InputMaybe<Scalars['String']['input']>;
  createTaskInput?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isOptional: Scalars['Boolean']['input'];
  journeyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  phaseId: Scalars['String']['input'];
  settings: JourneyStepSettingsInput;
};

export type CreateJourneyStepPayload = {
  __typename?: 'CreateJourneyStepPayload';
  journeyStep: JourneyStepType;
};

export type CreateJourneyStepTraineeSessionInput = {
  attendeeAdditionType: JourneyStepSessionAttendeeAdditionType;
  attendeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  batchId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  excludeAttendeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  journeyStepId: Scalars['String']['input'];
  trainerId: Scalars['String']['input'];
};

export type CreateKeyResultInput = {
  /** Order of okr in the parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']['input']>;
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDateConfig: DueDateConfigInput;
  instruction?: InputMaybe<InstructionInput>;
  /** Details for linking externally */
  integrationConfig?: InputMaybe<OkrIntegrationConfigInput>;
  name: Scalars['String']['input'];
  /** outcome for the KeyResult */
  outcome: KeyResultOutcomeInput;
  /** owners of OKR */
  ownerIds: Array<Scalars['String']['input']>;
  /** ParentId for objective or keyResult */
  parentId?: InputMaybe<Scalars['String']['input']>;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Details for progress measurement */
  progressMeasurementConfig?: InputMaybe<OkrProgressMeasurementConfigInput>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateMediaInput = {
  /** access class of media */
  accessClass?: InputMaybe<MediaAccessClassEnum>;
  /** Information regarding Media category(Image, video, audio, document) */
  kind: MediaKindEnum;
  /** Location of media object */
  location?: InputMaybe<MediaLocationInput>;
  /** mediaID associated with media */
  mediaId?: InputMaybe<Scalars['String']['input']>;
  /** media type of uploaded resource */
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** name of the media attached */
  name?: InputMaybe<Scalars['String']['input']>;
  /** preview enabled for the media */
  previewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Media URL */
  resolutions?: InputMaybe<Array<ResolutionInputType>>;
  /** Size of the attachment in kbs */
  size?: InputMaybe<Scalars['Float']['input']>;
  /** enable maintaining relationship during bulk create */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  /** url of the attachment stored on our end */
  url?: InputMaybe<Scalars['String']['input']>;
  urlPreviewMetadata?: InputMaybe<UrlPreviewMetadataInput>;
};

export type CreateMeetingInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  participantIds: Array<Scalars['String']['input']>;
  /** All the things which are related to meetings */
  relatedToEntities?: InputMaybe<Array<RelatedEntityInput>>;
  schedule: ScheduleInfoInput;
  templateId?: InputMaybe<Scalars['String']['input']>;
  type: MeetingTypeEnum;
  /** Attach Meeting to user level actions */
  userEntityActionsInput?: InputMaybe<UserEntityActionsInput>;
};

export type CreateMeetingInstanceInput = {
  meetingId: Scalars['String']['input'];
  occurrenceType?: InputMaybe<MeetingInstanceOccurrenceTypeEnum>;
  participantIds: Array<Scalars['String']['input']>;
  startTime: Scalars['String']['input'];
  status: MeetingInstanceStatusEnum;
  type: MeetingInstanceTypeEnum;
};

export type CreateMetricConfigsInputPropsType = {
  __typename?: 'CreateMetricConfigsInputPropsType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultTargetFrequency?: Maybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: Maybe<Scalars['Float']['output']>;
  defaultTargetValueUnit?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  measurementUnit: KeyResultMetricEnum;
  /** Name of metric */
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  syncConfig: MetricSyncConfig;
  /** Type of metric */
  type: MetricTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CreateMetricInput = {
  /** Salesforce -> dataObject */
  dataObject?: InputMaybe<Scalars['String']['input']>;
  defaultTargetFrequency?: InputMaybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: InputMaybe<Scalars['Float']['input']>;
  defaultTargetValueUnit?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  manualMetricConfig?: InputMaybe<ManualMetricConfigInput>;
  /** Metric type */
  measurementUnit: KeyResultMetricEnum;
  /** Salesforce -> filters */
  metricType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reminders?: InputMaybe<Array<Scalars['String']['input']>>;
  repeatSchedule?: InputMaybe<ScheduleInfoInput>;
  syncConfig: MetricSyncConfigInputType;
  /** Type of metric. By default will be considered as activity metric. */
  type: MetricTypeEnum;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOkrCycleInput = {
  /** Due date of the cycle */
  dueDateConfig: DueDateConfigInput;
  /** display emoji or color */
  iconSetting: IconSettingsInput;
  /** Name of cycle */
  name: Scalars['String']['input'];
  objectivePermissions: Array<OkrPermissionInput>;
  scheduleConfig?: InputMaybe<ScheduleConfigInput>;
  /** start date of the cycle */
  startDate: Scalars['String']['input'];
  /** store who can view the cycle */
  visibilitySettings: VisibilitySettingsInput;
};

export type CreateOkrCyclePayload = {
  __typename?: 'CreateOKRCyclePayload';
  cycle?: Maybe<OkrCycle>;
};

export type CreateObjectiveInput = {
  /** category config input  */
  categoryConfig: ObjectiveCategoryConfigInput;
  childObjectives: Array<CreateObjectiveInput>;
  /** Order of okr in the parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']['input']>;
  contributeToProgress?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDateConfig: DueDateConfigInput;
  instruction?: InputMaybe<InstructionInput>;
  keyResults: Array<CreateKeyResultInput>;
  name: Scalars['String']['input'];
  /** owners of OKR */
  ownerIds: Array<Scalars['String']['input']>;
  /** ParentId for objective or keyResult */
  parentId?: InputMaybe<Scalars['String']['input']>;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  /** Attach OKR to user level actions */
  userEntityActionsInput?: InputMaybe<UserEntityActionsInput>;
  /** store who can view the objective */
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateObjectivePayload = {
  __typename?: 'CreateObjectivePayload';
  objective?: Maybe<ObjectiveType>;
};

export type CreateOrUpdateWidgetInput = {
  chartWidgetProps?: InputMaybe<ChartWidgetPropsInput>;
  contentsWidgetProps?: InputMaybe<ContentsWidgetPropsInput>;
  externalWidgetProps?: InputMaybe<ExternalWidgetPropsInput>;
  listItemsWidgetProps?: InputMaybe<ListItemsWidgetPropsInput>;
  type: WidgetTypeEnum;
  widgetId?: InputMaybe<Scalars['String']['input']>;
};

/** input for creating a new orgRole under a department */
export type CreateOrgRoleInput = {
  definition?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  tag?: InputMaybe<EntityTagInput>;
  trackId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrgRoleTypeEnum>;
};

/** Input props rquired for creating an orgROle */
export type CreateOrgRoleInputPropsType = {
  __typename?: 'CreateOrgRoleInputPropsType';
  /** DepartmentId in which the orgRole belongs */
  departmentId: Scalars['String']['output'];
  /** Description of the orgRole */
  description?: Maybe<Scalars['String']['output']>;
  /** Key of orgRole */
  key: Scalars['String']['output'];
  /** Level of orgRole */
  level: Scalars['Float']['output'];
  /** mindsets attached to orgRole */
  mindsetIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Name of the orgRole */
  name?: Maybe<Scalars['String']['output']>;
  /** skill matrix attached to org role and skill mastery settings */
  skillMatrixConfig: SkillMatrixConfig;
  /** TrackId in which the orgRole belongs */
  trackId?: Maybe<Scalars['String']['output']>;
};

export type CreateOrgRolePayload = {
  __typename?: 'CreateOrgRolePayload';
  /** Created OrgRole */
  orgRole?: Maybe<OrgRoleType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateOutlookCalendarEventFromMeetingInput = {
  meetingId: Scalars['String']['input'];
  relayState?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateOutlookCalendarEventFromMeetingPayload = {
  __typename?: 'CreateOutlookCalendarEventFromMeetingPayload';
  /** redirect url if user is not loginedin */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateOutlookCalendarEventInput = {
  entityId: Scalars['String']['input'];
  entityType: OutlookEventEntityTypeEnum;
  relayState?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateOutlookCalendarEventPayload = {
  __typename?: 'CreateOutlookCalendarEventPayload';
  /** redirect url if user is not loginedin */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreatePathInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the path content */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  dripSettings?: InputMaybe<DripSettingsInput>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  sections: Array<PathSectionInput>;
  /** skill ids */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreatePathPayload = {
  __typename?: 'CreatePathPayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  path?: Maybe<PathContentType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreatePeerFeedbackInput = {
  /** Attachments of this feedback */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  feedbackMessage?: InputMaybe<Scalars['String']['input']>;
  learningContentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Mentions like @user */
  mentions?: InputMaybe<Array<MentionInputType>>;
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  receiverUserId: Scalars['String']['input'];
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  status: PeerFeedbackStatusEnum;
  visibleToManager?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CreatePostErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR',
  PostNotCreated = 'POST_NOT_CREATED'
}

export type CreatePostInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  /** ChannelId on which post to be created */
  channelId: Scalars['String']['input'];
  /** Creator of post, if empty user from context will be taken */
  createdById?: InputMaybe<Scalars['String']['input']>;
  /** Mentions like @user */
  mentions?: InputMaybe<Array<MentionInputType>>;
  /** Actual message of the post */
  message: Scalars['String']['input'];
  /** ParentId of post which is of type reply */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** Title of the post, can be used for posts like announcements */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Type of the post */
  type: PostTypeEnum;
  /** [Deprecated]: Urls of the post */
  urls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Urls of the post */
  urlsV2?: InputMaybe<Array<PostUrlInput>>;
};

export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  errorCode?: Maybe<CreatePostErrorCodeEnum>;
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreatePraiseInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: InputMaybe<Array<MentionInputType>>;
  message: Scalars['String']['input'];
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  receiverUserIds: Array<Scalars['String']['input']>;
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  visibleToEveryone: Scalars['Boolean']['input'];
};

export type CreateQuestionsInput = {
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** Letting backend API know, that we need to publish question only */
  publishQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  /** Questions to be created */
  questions: Array<QuestionInput>;
  /** Letting backend API know, that we need to add these questions into the following quiz */
  quizId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateQuestionsPayload = {
  __typename?: 'CreateQuestionsPayload';
  /** Questions created */
  createdQuestions: Array<QuestionType>;
  /** QuizQuestions created */
  createdQuizQuestions: Array<QuizQuestionSchema>;
  /** Failed Questions */
  failedQuestions: Array<QuestionPayload>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateQuizInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the content */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration in seconds */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Entity on which this quiz has to attached */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** Entity type of the entity where this quiz will be attached */
  entityType?: InputMaybe<QuizEntityTypeEnum>;
  /** Flag to force generate new */
  generateNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** Instructions for the quiz */
  instruction?: InputMaybe<ResourceInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of the quiz */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Objective of the content */
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Passing Percentage of the quiz */
  passingPercentage?: InputMaybe<Scalars['Float']['input']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** Quiz Question ids along with settings */
  questions?: InputMaybe<Array<QuizQuestionInput>>;
  /** quiz level settings */
  settings?: InputMaybe<QuizSettingsInput>;
  /** Ids of skills suitable for the content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** SubType of resource, here quiz. Ex - Static, inLesson, review etc */
  subType: ResourceSubTypeEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateQuizPayload = {
  __typename?: 'CreateQuizPayload';
  /** Flag to denote if questions are available to create quiz */
  questionsAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Created Quiz */
  quiz?: Maybe<QuizContentType>;
};

export type CreateReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  iconSettings: IconSettingsInput;
  name: Scalars['String']['input'];
  tags?: InputMaybe<EntityTagsInput>;
  type?: InputMaybe<ReportTypeEnum>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  /** Contains info about chart widgets and their layout */
  widgetsLayout?: InputMaybe<Array<WidgetLayoutInput>>;
};

/** Create Report Payload */
export type CreateReportPayload = {
  __typename?: 'CreateReportPayload';
  report: ReportType;
};

export type CreateResourceInput = {
  autoRename?: InputMaybe<Scalars['Boolean']['input']>;
  category: ResourceCategoryEnum;
  classroomId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  kind: ResourceKindEnum;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['String']['input'];
  path: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Create Resource Response */
export type CreateResourcePayload = {
  __typename?: 'CreateResourcePayload';
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  resource?: Maybe<ResourceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** ReviewCycle Schema */
export type CreateReviewCycleInput = {
  /** Advance settings to control certain aspects of review */
  advanceSettings?: InputMaybe<AdvanceSettingsInput>;
  /** ReviewCycle description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Settings related to downward-review */
  downwardReviewSettings?: InputMaybe<DownwardReviewSettingsInput>;
  /** When will the review cycle end */
  endDate?: InputMaybe<Scalars['String']['input']>;
  /** Icon settings */
  iconSettings?: InputMaybe<IconSettingsInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Types of modules to be reviewed in this review cycle */
  modulesToReview?: InputMaybe<Array<ReviewModuleTypeEnum>>;
  /** Settings related to peer-review */
  peerReviewSettings?: InputMaybe<PeerReviewSettingsInput>;
  /** Review Cycle End Settings */
  reviewCycleEndSettings?: InputMaybe<ReviewCycleEndSettingsInput>;
  /** reviewee to whom this reviewCycle is attached */
  reviewees?: InputMaybe<Array<RevieweeInput>>;
  /** TagId of the Tag (userType) that is selected as a reviewer for the reviewees in the review cycle */
  reviewerTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes?: InputMaybe<Array<ReviewTypeEnum>>;
  sameItemsForAllReviewTypes?: InputMaybe<Scalars['Boolean']['input']>;
  /** manual/automatic schedule settings */
  scheduleSettings?: InputMaybe<ReviewCycleScheduleConfigInput>;
  /** Settings related to self-review */
  selfReviewSettings?: InputMaybe<ReviewCycleModuleSettingsInput>;
  /** When will the review cycle start */
  startDate?: InputMaybe<Scalars['String']['input']>;
  /** ReviewCycle Name or Title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Type of this review cycle */
  type: ReviewCycleTypeEnum;
  /** Settings related to upward-review */
  upwardReviewSettings?: InputMaybe<UpwardReviewSettingsInput>;
};

export type CreateScorecardInput = {
  questions: Array<ScorecardQuestionInput>;
  skillId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateScorecardPayload = {
  __typename?: 'CreateScorecardPayload';
  /** Error code */
  errorCode?: Maybe<Scalars['String']['output']>;
  scorecard?: Maybe<ScorecardType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateScormInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of article-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  scormProperties?: InputMaybe<ScormPropertiesInput>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateSelfLearningContentInput = {
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateSharedViewInput = {
  allowComments: Scalars['Boolean']['input'];
  filterGroup?: InputMaybe<FilterGroupInput>;
  name: Scalars['String']['input'];
  parentEntityId: Scalars['String']['input'];
  type: SharedViewTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateSharedViewPayload = {
  __typename?: 'CreateSharedViewPayload';
  sharedView: SharedViewType;
};

export type CreateSkillGroupInOrgRoleInput = {
  /** name of the skill group */
  skillGroupName: Scalars['String']['input'];
};

export type CreateSkillGroupInTrackInput = {
  /** name of the skill group */
  skillGroupName: Scalars['String']['input'];
};

export type CreateSkillInOrgRoleInput = {
  /** ids of the child skills to be attached under this skill */
  childrenIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  expectedScore: Scalars['Float']['input'];
  iconSettings?: InputMaybe<IconSettingsInput>;
  importedFromId?: InputMaybe<Scalars['String']['input']>;
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  /** complete hierearchy of microskills to be created under this skill */
  microSkills?: InputMaybe<Array<BulkCreateMicroSkillsInput>>;
  name: Scalars['String']['input'];
  /** ids of the skills that this skill is to be created as child of */
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rubrics: Array<RubricInput>;
  skillGroupId: Scalars['String']['input'];
  subWorkspaces?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<SkillTypeEnum>;
  visibleInListing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSkillInTrackInput = {
  /** ids of the child skills to be attached under this skill */
  childrenIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  importedFromId?: InputMaybe<Scalars['String']['input']>;
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  /** complete hierearchy of microskills to be created under this skill */
  microSkills?: InputMaybe<Array<BulkCreateMicroSkillsInput>>;
  name: Scalars['String']['input'];
  /** ids of the skills that this skill is to be created as child of */
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rubrics: Array<RubricInput>;
  /** skillGroupId to which given skill should be added */
  skillGroupId: Scalars['String']['input'];
  subWorkspaces?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<SkillTypeEnum>;
  visibleInListing?: InputMaybe<Scalars['Boolean']['input']>;
};

/** input for creating a new Skill */
export type CreateSkillInput = {
  /** ids of the child skills to be attached under this skill */
  childrenIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  importedFromId?: InputMaybe<Scalars['String']['input']>;
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  /** complete hierearchy of microskills to be created under this skill */
  microSkills?: InputMaybe<Array<BulkCreateMicroSkillsInput>>;
  name: Scalars['String']['input'];
  /** ids of the skills that this skill is to be created as child of */
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rubrics: Array<RubricInput>;
  subWorkspaces?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<SkillTypeEnum>;
  visibleInListing?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input props rquired for creating a skill */
export type CreateSkillInputPropsType = {
  __typename?: 'CreateSkillInputPropsType';
  /** Skill description */
  description?: Maybe<Scalars['String']['output']>;
  iconSettings?: Maybe<IconSettings>;
  /** Skill Name or Title */
  name: Scalars['String']['output'];
  /** Denotes the rules for scoring this skill */
  rubrics: Array<Rubric>;
  type: SkillTypeEnum;
};

export type CreateSkillMatrixInput = {
  iconSettings: IconSettingsInput;
  name: Scalars['String']['input'];
};

/** Input props rquired for creating a skillMatrix */
export type CreateSkillMatrixInputPropsType = {
  __typename?: 'CreateSkillMatrixInputPropsType';
  iconSettings?: Maybe<IconSettings>;
  name: Scalars['String']['output'];
  skillGroups: Array<SkillGroupType>;
};

/** Inputs required to create channel for a squad route */
export type CreateSquadChannelInput = {
  /** Name of the channel, use the squad url */
  name: Scalars['String']['input'];
};

export type CreateStaffRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entityPermissionKeys: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  restrictedPermittedOn?: InputMaybe<RestrictedPermittedOnInput>;
  tagColor?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSurveyInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Cover image for survey */
  cover?: InputMaybe<CreateMediaInput>;
  /** Cover offset */
  coverOffset?: InputMaybe<Scalars['Float']['input']>;
  /** Description of the survey */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration in seconds */
  duration: Scalars['Float']['input'];
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the survey */
  name: Scalars['String']['input'];
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  /** Skill Ids */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Publishing status of survey */
  status: ResourceStatusEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings: VisibilitySettingsInput;
};

/** Create Survey Payload */
export type CreateSurveyPayload = {
  __typename?: 'CreateSurveyPayload';
  survey?: Maybe<SurveyContentType>;
};

export type CreateTagInput = {
  availableOn: Array<TagAvailableOnEnum>;
  category?: InputMaybe<TagCategoryEnum>;
  dateTagProps?: InputMaybe<DateTagPropsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  multiSelectTagProps?: InputMaybe<MultiSelectTagPropsInput>;
  name: Scalars['String']['input'];
  numberTagProps?: InputMaybe<NumberTagPropsInput>;
  settings: TagSettingsInput;
  singleSelectTagProps?: InputMaybe<SingleSelectTagPropsInput>;
  source?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<TagSubTypeEnum>;
  subworkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  textTagProps?: InputMaybe<TextTagPropsInput>;
  type: TagTypeEnum;
};

export type CreateTaskCreationConfigInput = {
  /** Type of config, manual or auto */
  externalEntityType: ActionItemExternalEntityTypeEnum;
  /** provider with which this sync is created */
  providerId: Scalars['String']['input'];
  /** Searchtext, related query for the sync */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** Type of config, manual or auto */
  taskCreationType: TaskCreationConfigTypeEnum;
};

export type CreateTaskInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of task-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration: Scalars['Float']['input'];
  instruction?: InputMaybe<TaskInstructionInput>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  subTasks?: InputMaybe<Array<SubTaskInput>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
  task?: Maybe<TaskContentType>;
};

export type CreateThemeInput = {
  IconSettings?: InputMaybe<IconSettingsInput>;
  Status?: InputMaybe<ThemeStatusEnum>;
  name: Scalars['String']['input'];
};

export type CreateThemePayload = {
  __typename?: 'CreateThemePayload';
  theme: ThemeType;
};

export type CreateTrackInput = {
  /** departmentId in which the track belongs */
  departmentId: Scalars['String']['input'];
  /** description of the track */
  description?: InputMaybe<Scalars['String']['input']>;
  /** name of the track */
  name: Scalars['String']['input'];
  orgRoles?: InputMaybe<Array<CreateOrgRoleInput>>;
  /** tagId for creating roles for track */
  roleTagId?: InputMaybe<Scalars['String']['input']>;
  /** too add learners to track based on tag condition */
  syncConfig?: InputMaybe<FilterGroupV2InputType>;
};

/** Input props rquired for creating a track */
export type CreateTrackInputPropsType = {
  __typename?: 'CreateTrackInputPropsType';
  /** DepartmentId in which the track belongs */
  departmentId: Scalars['String']['output'];
  /** Description of the track */
  description?: Maybe<Scalars['String']['output']>;
  /** metric attached to track */
  metricIds?: Maybe<Array<Scalars['String']['output']>>;
  /** mindsets attached to track */
  mindsetIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Name of the track */
  name: Scalars['String']['output'];
  /** skillMatrix attached to track */
  skillMatrixId: Scalars['String']['output'];
};

export type CreateTrackPayload = {
  __typename?: 'CreateTrackPayload';
  track: TrackType;
};

export type CreateUserCollectionGroupInput = {
  classroomId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  memberIds?: InputMaybe<GroupMembersIds>;
  name: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<UserCollectionGroupTypeEnum>;
  systemGenerated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserPublicApiCredentialsInput = {
  /** ClientId mapped to the generated secret */
  clientId: Scalars['String']['input'];
  /** Description for the generated secret */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A unique name through which user can identify a generated secret. Unique across all secrets generated for a given user */
  name: Scalars['String']['input'];
  /** UserId for which credentials will be created. If not passed, actorId will be associated */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserPublicApiCredentialsPayload = {
  __typename?: 'CreateUserPublicAPICredentialsPayload';
  /** ClientId against which this secret can be mapped */
  clientId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Decrypted secret that the user should use while generating accessToken. Only available once while creating userPublicAPICredentials */
  decryptedSecret: Scalars['String']['output'];
  /** Description for the generated secret */
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Unique identifyer through which a user can identify a generated secret */
  name: Scalars['String']['output'];
  /** Encrypted version of secret through which user can generate public api access token */
  secret: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** UserId for which the secret is valid */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CreateUserSurveyInput = {
  name: Scalars['String']['input'];
  subWorkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  systemGenerated: Scalars['Boolean']['input'];
  type: UserSurveyTypeEnum;
};

export type CreateUserSurveyPayload = {
  __typename?: 'CreateUserSurveyPayload';
  userSurvey: UserSurveyType;
};

export enum CronFrequencyEnum {
  Custom = 'CUSTOM',
  Daily = 'DAILY',
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type CurrentEventsWidgetType = {
  __typename?: 'CurrentEventsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  events: Array<EventUnion>;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CurrentMeetingInstancesWidgetType = {
  __typename?: 'CurrentMeetingInstancesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  meetingInstances: Array<MeetingInstanceType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type CurrentMeetingsWidgetType = {
  __typename?: 'CurrentMeetingsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  meetings: Array<MeetingType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Current reviewing state of content */
export type CurrentReviewingState = {
  __typename?: 'CurrentReviewingState';
  isCurrentUserReviewing: Scalars['Boolean']['output'];
  reviewBy: UserType;
  reviewedById: Scalars['String']['output'];
  status: PerformanceStatusEnum;
};

export type CustomFrequency = {
  __typename?: 'CustomFrequency';
  dayOfMonth?: Maybe<Scalars['Float']['output']>;
  daysOfWeek?: Maybe<Array<DayOfWeekEnum>>;
  frequency: CustomFrequencyEnum;
  interval: Scalars['Float']['output'];
  monthOfYear?: Maybe<MonthOfYearEnum>;
  monthsOfYear?: Maybe<Array<MonthOfYearEnum>>;
  weekOfMonth?: Maybe<WeekOfMonthEnum>;
  weeksOfMonth?: Maybe<Array<WeekOfMonthEnum>>;
};

export enum CustomFrequencyEnum {
  Day = 'DAY',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type CustomFrequencyInput = {
  dayOfMonth?: InputMaybe<Scalars['Float']['input']>;
  daysOfWeek?: InputMaybe<Array<DayOfWeekEnum>>;
  frequency: CustomFrequencyEnum;
  interval: Scalars['Float']['input'];
  monthOfYear?: InputMaybe<MonthOfYearEnum>;
  monthsOfYear?: InputMaybe<Array<MonthOfYearEnum>>;
  weekOfMonth?: InputMaybe<WeekOfMonthEnum>;
  weeksOfMonth?: InputMaybe<Array<WeekOfMonthEnum>>;
};

/** Custom grading schema type */
export type CustomLetterGradingSchemaType = {
  __typename?: 'CustomLetterGradingSchemaType';
  relationalOperator: Scalars['String']['output'];
  symbolGrade: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

/** Custom Rating Question component */
export type CustomRatingComponent = {
  __typename?: 'CustomRatingComponent';
  ratingLabels: Array<RatingLabelComponent>;
};

/** Custom Rating Question component */
export type CustomRatingComponentInput = {
  ratingLabels: Array<RatingLabelComponentInput>;
};

export enum CustomStatusEntityTypeEnum {
  File = 'FILE'
}

/** OKR permission schema */
export type CycleOkrPermission = {
  __typename?: 'CycleOKRPermission';
  /** action to be performed */
  action: OkrActions;
  /** store who can act on subject */
  permission: VisibilitySettingsType;
  /** subject on which permission action is to be performed */
  subject: OkrPermissionSubjects;
};

/** Skill Group Schema */
export type CycleReviewSkillGroupType = {
  __typename?: 'CycleReviewSkillGroupType';
  name: Scalars['String']['output'];
  /** source responsibilityId of the skillGroup */
  orgRoleId?: Maybe<Scalars['String']['output']>;
  originalId: Scalars['String']['output'];
  skills: Array<CycleReviewSkillType>;
};

/** Skill Schema */
export type CycleReviewSkillType = {
  __typename?: 'CycleReviewSkillType';
  /** Skill description */
  description?: Maybe<Scalars['String']['output']>;
  expectedScore?: Maybe<Scalars['Float']['output']>;
  /** Skill Name or Title */
  name: Scalars['String']['output'];
  /** source responsibilityId of the skill/mindset */
  orgRoleId?: Maybe<Scalars['String']['output']>;
  originalId: Scalars['String']['output'];
  /** Denotes the rules for scoring this skill, empty array for microskill */
  rubrics: Array<Rubric>;
};

export enum CycleReviewStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotAReviewer = 'NOT_A_REVIEWER',
  NotStarted = 'NOT_STARTED'
}

/** CycleReviewType Schema */
export type CycleReviewType = {
  __typename?: 'CycleReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Mindsets of the user who is getting reviewed */
  mindsetsSnapshot?: Maybe<Array<CycleReviewSkillType>>;
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  /** review cycle instance */
  reviewCycleId: Scalars['String']['output'];
  /** reviewType */
  reviewType: Scalars['String']['output'];
  /** reviewee */
  reviewee?: Maybe<UserType>;
  /** reviewer */
  reviewer?: Maybe<UserType>;
  /** user who is reviewing */
  reviewerId: Scalars['String']['output'];
  /** overall score in a cycle */
  score?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Skill Groups of the user who is getting reviewed */
  skillGroupsSnapshot?: Maybe<Array<CycleReviewSkillGroupType>>;
  status: CycleReviewStatusEnum;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting reviewed */
  userId: Scalars['String']['output'];
  /** OrgRole Name or Title of user getting reviewed */
  userOrgRole?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type DbViewId = {
  __typename?: 'DBViewId';
  /** Database View Type */
  type: ViewTypeEnum;
  /** Database View Id */
  viewId: Scalars['String']['output'];
};

export enum DataCheckAttributeTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT'
}

export enum DataCheckDefaultAttributeEnum {
  Department = 'DEPARTMENT',
  Manager = 'MANAGER',
  Role = 'ROLE',
  Track = 'TRACK'
}

/** DatabaseData Edge */
export type DatabaseData = {
  __typename?: 'DatabaseData';
  node: DatabaseDataType;
};

/** This returns paginated DatabaseData */
export type DatabaseDataConnection = {
  __typename?: 'DatabaseDataConnection';
  edges?: Maybe<Array<DatabaseData>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Database Data subscription schema  */
export type DatabaseDataSubscriptionType = {
  __typename?: 'DatabaseDataSubscriptionType';
  /** Affected Database Data */
  data?: Maybe<DatabaseDataType>;
  /** Affected Document Id */
  documentId?: Maybe<Scalars['String']['output']>;
  /** Operation Type */
  operation: OperationEnum;
  /** Entity Type */
  type: DatabaseEntitiesEnum;
};

/** Database data */
export type DatabaseDataType = {
  __typename?: 'DatabaseDataType';
  createdBy?: Maybe<UserType>;
  /** Database data */
  data: Scalars['JSONObject']['output'];
  id: Scalars['String']['output'];
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Defines database data filters for fetching multiple documents */
export type DatabaseDatasFilters = {
  dbId: Scalars['String']['input'];
  dbMetadataId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<QueryInput>;
  viewId?: InputMaybe<Scalars['String']['input']>;
};

export enum DatabaseEntitiesEnum {
  Data = 'DATA',
  Metadata = 'METADATA',
  View = 'VIEW'
}

export enum DatabaseFieldTypeEnum {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Email = 'EMAIL',
  FilesAndMedias = 'FILES_AND_MEDIAS',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  Person = 'PERSON',
  PhoneNumber = 'PHONE_NUMBER',
  Rating = 'RATING',
  Relation = 'RELATION',
  Select = 'SELECT',
  Text = 'TEXT',
  Url = 'URL'
}

/** This returns paginated database metadata */
export type DatabaseMetadataConnection = {
  __typename?: 'DatabaseMetadataConnection';
  edges?: Maybe<Array<DatabaseMetadataEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type DatabaseMetadataEdge = {
  __typename?: 'DatabaseMetadataEdge';
  node: DatabaseMetadataType;
};

/** Database Metadata Fields Schema */
export type DatabaseMetadataFieldType = {
  __typename?: 'DatabaseMetadataFieldType';
  /** Defines if multiple selections are allowed */
  allowMultipleSelection?: Maybe<Scalars['Boolean']['output']>;
  /** Collection id */
  collectionId?: Maybe<Scalars['String']['output']>;
  /** Defines collection format */
  collectionType?: Maybe<DatabaseRelationCollectionTypeEnum>;
  /** Defines date format */
  dateFormat?: Maybe<Scalars['String']['output']>;
  /** Field id */
  fieldId: Scalars['String']['output'];
  /** Field Name */
  name: Scalars['String']['output'];
  /** Defines number format */
  numberFormat?: Maybe<DatabaseNumberFormatEnum>;
  /** Select/Multi-select options */
  options?: Maybe<Array<SelectFieldOptionsType>>;
  /** Defines rating type */
  ratingType?: Maybe<DatabaseRatingTypeEnum>;
  /** Type of field */
  type: DatabaseFieldTypeEnum;
  /** Validation required on the field */
  validation?: Maybe<ValidationType>;
};

/** Defines database metadata filters for fetching a single document */
export type DatabaseMetadataFilters = {
  dbId?: InputMaybe<Scalars['String']['input']>;
  dbMetadataId?: InputMaybe<Scalars['String']['input']>;
};

/** Database Metadata Schema */
export type DatabaseMetadataType = {
  __typename?: 'DatabaseMetadataType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Default viewId of the database */
  dbDefaultViewId: Scalars['String']['output'];
  /** Database Id */
  dbId: Scalars['String']['output'];
  /** Database Name */
  dbName: Scalars['String']['output'];
  /** Database Description */
  description: Scalars['String']['output'];
  /** Database fields */
  fields: Array<DatabaseMetadataFieldType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** This resolver returns id, name and viewType of all the views corresponding to the metadata */
  views?: Maybe<Array<DisplayDatabaseViewType>>;
};

/** Defines database metadata filters for fetching multiple documents */
export type DatabaseMetadatasFilters = {
  dbIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dbMetadataIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search text for searching database metadata */
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export enum DatabaseNumberFormatEnum {
  Dollar = 'DOLLAR',
  Euro = 'EURO',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Pound = 'POUND',
  Rupee = 'RUPEE',
  Yen = 'YEN'
}

export enum DatabaseRatingTypeEnum {
  Number = 'NUMBER',
  Star = 'STAR'
}

export enum DatabaseRelationCollectionTypeEnum {
  Agenda = 'AGENDA',
  Checkin = 'CHECKIN',
  Database = 'DATABASE',
  User = 'USER'
}

export enum DatabaseTextFormatEnum {
  MultiLine = 'MULTI_LINE',
  SingleLine = 'SINGLE_LINE'
}

/** Database Fields */
export type DatabaseViewFieldType = {
  __typename?: 'DatabaseViewFieldType';
  /** Defines if user is able to create custom tags */
  allowCustomOptions?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if character limit is enabled */
  charLimitEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the field */
  description?: Maybe<Scalars['String']['output']>;
  /** Defines if description is enabled */
  descriptionEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Short Id of the field */
  fieldId: Scalars['String']['output'];
  /** Defines if end date is included */
  includeEndDate?: Maybe<Scalars['Boolean']['output']>;
  /** Defines if time is included */
  includeTime?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the field */
  label?: Maybe<Scalars['String']['output']>;
  /** Max label for rating type input */
  maxLabel?: Maybe<Scalars['String']['output']>;
  /** Min label for rating type input */
  minLabel?: Maybe<Scalars['String']['output']>;
  /** Placeholder for text input */
  placeholder?: Maybe<Scalars['String']['output']>;
  /** Field Required in input */
  required: Scalars['Boolean']['output'];
  /** Defines if user is able to create custom tags */
  textType?: Maybe<DatabaseTextFormatEnum>;
  /** Field visibility */
  visible: Scalars['Boolean']['output'];
  /** Width of the column */
  width: Scalars['Float']['output'];
};

/** Defines database view filters for fetching a single view */
export type DatabaseViewFilters = {
  viewId: Scalars['String']['input'];
};

/** Defines database view fields */
export type DatabaseViewFormat = {
  fields: Array<DatabaseViewFormatFields>;
};

/** Defines database view format fields */
export type DatabaseViewFormatFields = {
  allowCustomOptions?: InputMaybe<Scalars['Boolean']['input']>;
  /** Defines if character limit is enabled */
  charLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Defines if description is enabled */
  descriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fieldId: Scalars['String']['input'];
  /** Defines if end date is included */
  includeEndDate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Defines if time is included */
  includeTime?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Max label for rating type input */
  maxLabel?: InputMaybe<Scalars['String']['input']>;
  /** Min label for rating type input */
  minLabel?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
  textType?: InputMaybe<DatabaseTextFormatEnum>;
  visible: Scalars['Boolean']['input'];
  width: Scalars['Float']['input'];
};

/** Defines what fields are present and how fields should be displayed in a Database View */
export type DatabaseViewFormatType = {
  __typename?: 'DatabaseViewFormatType';
  /** Fields of the database view */
  fields: Array<DatabaseViewFieldType>;
};

/** Database View Schema */
export type DatabaseViewType = {
  __typename?: 'DatabaseViewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Database Id */
  dbId: Scalars['String']['output'];
  /** Database Metadata Id */
  dbMetadataId: Scalars['String']['output'];
  /** Format of the database view */
  format: DatabaseViewFormatType;
  id: Scalars['String']['output'];
  /** Database View Name */
  name: Scalars['String']['output'];
  /** Query on  */
  query?: Maybe<QueryType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** Tells about the view type of the database */
  viewType: ViewTypeEnum;
};

export type DateFilter = {
  absolute?: InputMaybe<AbsoluteDateFilter>;
  relative?: InputMaybe<RelativeDateFilter>;
  timezone: Scalars['String']['input'];
};

export type DateFilterType = {
  __typename?: 'DateFilterType';
  absolute?: Maybe<AbsoluteDateFilterType>;
  relative?: Maybe<RelativeDateFilterType>;
  timezone: Scalars['String']['output'];
};

/** Metadata for date fields */
export type DateMetadataInput = {
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate: Scalars['DateTime']['input'];
  timeFormat?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type DateTagPropsInput = {
  includeEndDate: Scalars['Boolean']['input'];
  includeTime: Scalars['Boolean']['input'];
};

export type DateTagType = {
  __typename?: 'DateTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** number type tag specific fields */
  includeEndDate: Scalars['Boolean']['output'];
  includeTime: Scalars['Boolean']['output'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']['output']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** This denotes, whether we need to create a new dynamic group, when a new option is added inside single/multi select */
  syncGroup?: Maybe<Scalars['Boolean']['output']>;
  /** field resolvers */
  taggedContentCount: Scalars['Float']['output'];
  taggedEventCount: Scalars['Float']['output'];
  taggedOKRCount: Scalars['Float']['output'];
  taggedTaskCount: Scalars['Float']['output'];
  taggedUserCount: Scalars['Float']['output'];
  type: TagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type DateTagTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type DateTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

export type DateValueInput = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type DateValueType = {
  __typename?: 'DateValueType';
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export enum DayOfWeekEnum {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

/** Defines metadata field options */
export type DbMetadataFieldOptions = {
  color: Scalars['String']['input'];
  optionId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Default Enrolment Settings Type */
export type DefaultEnrolmentSettingsType = {
  __typename?: 'DefaultEnrolmentSettingsType';
  /** Number of days to complete the path */
  dueDays: Scalars['Float']['output'];
};

export enum DefaultTemplateEntityEnum {
  Department = 'DEPARTMENT',
  Metric = 'METRIC',
  OrgRole = 'ORG_ROLE',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Track = 'TRACK'
}

/** Documents containing data for creation of default entities */
export type DefaultTemplateType = {
  __typename?: 'DefaultTemplateType';
  /** Configs required to create a department */
  createDepartmentConfig?: Maybe<CreateDepartmentInputPropsType>;
  /** Configs required to create a metric */
  createMetricConfig?: Maybe<CreateMetricConfigsInputPropsType>;
  /** Configs required to create an orgRole */
  createOrgRoleConfig?: Maybe<CreateOrgRoleInputPropsType>;
  /** Configs required to create a skill/mindset */
  createSkillConfig?: Maybe<CreateSkillInputPropsType>;
  /** Configs required to create a skillMatrix */
  createSkillMatrixConfig?: Maybe<CreateSkillMatrixInputPropsType>;
  /** Configs required to create a track */
  createTrackConfig?: Maybe<CreateTrackInputPropsType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Flows for which this template can be used */
  supportedFlows: Array<SupportedFlowsEnum>;
  trackTemplates: Array<TrackTemplatesProfile>;
  /** Entity type of template */
  type: DefaultTemplateEntityEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type DeleteActionItemInput = {
  actionItemId: Scalars['String']['input'];
};

export type DeleteActionPlanInput = {
  actionPlanId: Scalars['String']['input'];
};

export type DeleteActionPlanPayload = {
  __typename?: 'DeleteActionPlanPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteAgendaItemInput = {
  agendaItemId: Scalars['String']['input'];
};

export type DeleteAgendaItemTemplateInput = {
  agendaItemTemplateId: Scalars['String']['input'];
};

export type DeleteBookmarkInput = {
  /** id of the Bookmark that needs to be deleted */
  bookmarkId: Scalars['String']['input'];
};

export type DeleteBookmarkPayload = {
  __typename?: 'DeleteBookmarkPayload';
  /** deleted bookmark */
  bookmark?: Maybe<BookmarkType>;
};

/** input for deleting a department */
export type DeleteDepartmentInput = {
  id: Scalars['String']['input'];
};

export type DeleteEmailDigestInput = {
  emailDigestId: Scalars['String']['input'];
};

export type DeleteEventPerformanceInput = {
  eventId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type DeleteFileInput = {
  fileId: Scalars['String']['input'];
};

export type DeleteJourneyInput = {
  id: Scalars['String']['input'];
};

export type DeleteJourneyStepInput = {
  id: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteMeetingInput = {
  meetingId: Scalars['String']['input'];
};

export type DeleteMeetingInstancesInput = {
  filters: MeetingInstancesFilters;
};

export type DeleteMetricInput = {
  metricId: Scalars['String']['input'];
};

/** Delete Cycle Mutation Input */
export type DeleteOkrCycleInput = {
  /** Id of the cycle which needs to be deleted */
  cycleId: Scalars['String']['input'];
};

/** Delete OKR Mutation Input */
export type DeleteOkrInput = {
  /** Id of the okr which needs to be deleted */
  okrId: Scalars['String']['input'];
  /** Type of OKR to be deleted */
  type: OkrTypeEnum;
};

export type DeleteOkrPayload = {
  __typename?: 'DeleteOKRPayload';
  parentOKR?: Maybe<OkrUnion>;
  parentOKRs?: Maybe<Array<OkrUnion>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteOrgRoleInput = {
  orgRoleId: Scalars['String']['input'];
};

export type DeletePeerFeedbackInput = {
  peerFeedbackId: Scalars['String']['input'];
};

/** Input for deleting a post */
export type DeletePostInput = {
  /** ChannelId of the post that needs to be deleted */
  channelId: Scalars['String']['input'];
  /** PostId to be deleted */
  postId: Scalars['String']['input'];
};

export type DeletePraiseInput = {
  praiseId: Scalars['String']['input'];
};

export type DeleteReportInput = {
  reportId: Scalars['String']['input'];
};

export type DeleteResourceInput = {
  kind: ResourceKindEnum;
  path?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['String']['input'];
};

export type DeleteReviewCycleInput = {
  reviewCycleId: Scalars['String']['input'];
};

export type DeleteReviewCyclePayload = {
  __typename?: 'DeleteReviewCyclePayload';
  reviewCycleId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteSharedViewInput = {
  id: Scalars['String']['input'];
};

export type DeleteSharedViewPayload = {
  __typename?: 'DeleteSharedViewPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteSkillInput = {
  skillId: Scalars['String']['input'];
};

export type DeleteSkillMatrixInput = {
  skillMatrixId: Scalars['String']['input'];
};

export type DeleteSkillMatrixPayload = {
  __typename?: 'DeleteSkillMatrixPayload';
  skillMatrixId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteSkillPayload = {
  __typename?: 'DeleteSkillPayload';
  skillId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteSkillReviewInput = {
  reviewCycleId: Scalars['String']['input'];
  reviewTypes: Array<Scalars['String']['input']>;
  reviewerId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type DeleteStaffRoleInput = {
  roleId: Scalars['String']['input'];
};

export type DeleteTracksInput = {
  filters: TracksFilters;
};

export type DeleteUserActivityMetricsInput = {
  userActivityMetricIds: Array<Scalars['String']['input']>;
};

export type DeleteUserSearchActivityInput = {
  /** id of the userSearchActivity that needs to be deleted */
  userSearchActivityId: Scalars['String']['input'];
};

export type DeleteWidgetsInput = {
  widgetIds: Array<Scalars['String']['input']>;
};

export enum DeletedFilterEnum {
  Both = 'BOTH',
  Deleted = 'DELETED',
  NonDeleted = 'NON_DELETED'
}

/** This returns paginated Department */
export type DepartmentConnections = {
  __typename?: 'DepartmentConnections';
  edges?: Maybe<Array<DepartmentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  node: DepartmentType;
};

export type DepartmentIdInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type DepartmentOrgRoleFilter = {
  departmentId?: InputMaybe<Scalars['String']['input']>;
  orgRoleId?: InputMaybe<Scalars['String']['input']>;
};

export type DepartmentOrgRoleFilterType = {
  __typename?: 'DepartmentOrgRoleFilterType';
  departmentId?: Maybe<Scalars['String']['output']>;
  orgRoleId?: Maybe<Scalars['String']['output']>;
};

export enum DepartmentStatusEnum {
  Active = 'ACTIVE',
  Archive = 'ARCHIVE'
}

/** Department in a corporate tenant */
export type DepartmentType = {
  __typename?: 'DepartmentType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  /** Description of the department */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Indicates if department is deleted */
  isDeleted: Scalars['Boolean']['output'];
  /** Name of the department */
  name: Scalars['String']['output'];
  orgRoleCount?: Maybe<Scalars['Float']['output']>;
  orgRoles?: Maybe<Array<OrgRoleType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of the department */
  status: DepartmentStatusEnum;
  trackCount?: Maybe<Scalars['Float']['output']>;
  tracks?: Maybe<Array<TrackType>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userCount?: Maybe<Scalars['Float']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type DepartmentsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name of the department */
  name?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<DepartmentStatusEnum>>;
};

/** Media Types */
export type DeprecatedMediaType = {
  __typename?: 'DeprecatedMediaType';
  /** Blured initial image */
  blurHash?: Maybe<Scalars['String']['output']>;
  resolutions: Array<DeprecatedResolutionType>;
  /** Media type */
  type: MediaTypeEnum;
  /** Media URL */
  url: Scalars['String']['output'];
};

export enum DeprecatedResolutionEnum {
  L = 'L',
  S = 'S',
  Thumb = 'THUMB'
}

/** Deprecated Multiple Resolution Type */
export type DeprecatedResolutionType = {
  __typename?: 'DeprecatedResolutionType';
  type: DeprecatedResolutionEnum;
  url: Scalars['String']['output'];
};

export enum DifficultyEnum {
  Easy = 'EASY',
  Hard = 'HARD',
  Medium = 'MEDIUM',
  Na = 'NA'
}

export type DifficultyWeightConfigType = {
  __typename?: 'DifficultyWeightConfigType';
  difficultyWeights: Array<DifficultyWeightType>;
};

export type DifficultyWeightType = {
  __typename?: 'DifficultyWeightType';
  difficulty: DifficultyEnum;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type DimensionConfigType = {
  __typename?: 'DimensionConfigType';
  dependentFilters?: Maybe<FilterGroupType>;
  dimension: Scalars['String']['output'];
  dimensionDateFilter?: Maybe<Scalars['String']['output']>;
  innerDimensionSize?: Maybe<Scalars['Float']['output']>;
  isHierarchyEnabled?: Maybe<Scalars['Boolean']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  sortOrder?: Maybe<SortTypeSchema>;
};

export type DimensionConfigTypeInput = {
  dependentFilters?: InputMaybe<FilterGroupInput>;
  dimension: Scalars['String']['input'];
  dimensionDateFilter?: InputMaybe<Scalars['String']['input']>;
  innerDimensionSize?: InputMaybe<Scalars['Float']['input']>;
  isHierarchyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  sortOrder?: InputMaybe<SortType>;
};

export type DiscardUnpublishedChangesInput = {
  contentId: Scalars['String']['input'];
};

/** Publish Course-Content Response */
export type DiscardUnpublishedChangesPayload = {
  __typename?: 'DiscardUnpublishedChangesPayload';
  content?: Maybe<ContentEntity>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DisconnectIntegrationDetailInput = {
  integrationProviderId: Scalars['String']['input'];
  scope?: InputMaybe<IntegrationScopeEnum>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Product discount parameters */
export type DiscountType = {
  __typename?: 'DiscountType';
  /** Represents discounted amount */
  amount: Scalars['Float']['output'];
  /** Discount Type. it can be percentage OR fixed. */
  type: DiscountTypeEnum;
  /** Represents validity of the discount */
  validity?: Maybe<Scalars['String']['output']>;
};

export enum DiscountTypeEnum {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export enum DisplayComponentTypeEnum {
  ImageGrid = 'IMAGE_GRID',
  ListSection = 'LIST_SECTION',
  TextSection = 'TEXT_SECTION'
}

export type DisplayComponentUnion = ImageGridDisplayComponentType | ListSectionDisplayComponentType | TextSectionDisplayComponentType;

/** Projected view fields used to display view */
export type DisplayDatabaseViewType = {
  __typename?: 'DisplayDatabaseViewType';
  id: Scalars['String']['output'];
  /** Database View name */
  name: Scalars['String']['output'];
  /** Tells about the view type of the database */
  viewType: ViewTypeEnum;
};

/** Represent pair data */
export type Distractor = {
  __typename?: 'Distractor';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Represent pair data */
export type DistractorInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type DistractorsSetInput = {
  /** Array of distractors */
  set: Array<DistractorInput>;
};

/** Input for fields and corresponding data */
export type DocumentFieldsInput = {
  data: DocumentValuesInput;
  fieldId: Scalars['String']['input'];
};

/** Data corresponding to a fieldId */
export type DocumentValuesInput = {
  metadata?: InputMaybe<DocumentValuesMetadataInput>;
  value: Scalars['String']['input'];
};

/** Metadata for field values which require special information */
export type DocumentValuesMetadataInput = {
  /** metadata for date type field */
  d?: InputMaybe<DateMetadataInput>;
  /** metadata for media type field */
  m?: InputMaybe<Array<CreateMediaInput>>;
  /** metadata for relation type field */
  r?: InputMaybe<RelationMetadataInput>;
  /** metadata for user type field */
  u?: InputMaybe<Array<UserMetadataInput>>;
};

export type DownardReviewResultDeliverySettings = {
  __typename?: 'DownardReviewResultDeliverySettings';
  /** share only reviews of the reviewCycle */
  shareOnlyReview?: Maybe<Scalars['Boolean']['output']>;
  /** share only summary of the reviewCycle */
  shareOnlySummary?: Maybe<Scalars['Boolean']['output']>;
};

export type DownardReviewResultDeliverySettingsInput = {
  /** share only reviews of the reviewCycle */
  shareOnlyReview?: InputMaybe<Scalars['Boolean']['input']>;
  /** share only summary of the reviewCycle */
  shareOnlySummary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** DownloadVideoInput */
export type DownloadVideoInput = {
  blockId: Scalars['String']['input'];
  videoUID: Scalars['String']['input'];
};

export type DownloadVideoType = {
  __typename?: 'DownloadVideoType';
  blockId: Scalars['String']['output'];
  videoUID: Scalars['String']['output'];
};

export type DownwardReviewSettingsInput = {
  endReviewCycleConfig?: InputMaybe<ReviewCycleEndConfigInput>;
  /** false if manager can see reviews only when everyone has submitted */
  managerReviewImmediateVisibility?: InputMaybe<Scalars['Boolean']['input']>;
  /** false if manager can submit reviews only after other reviewers */
  managerReviewSequenceWithOtherReviewers?: InputMaybe<Scalars['Boolean']['input']>;
  mindsetReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
  questions?: InputMaybe<Array<ReviewCycleQuestionInput>>;
  resultDeliverySettings?: InputMaybe<DownardReviewResultDeliverySettingsInput>;
  reviewInstruction?: InputMaybe<InstructionInput>;
  skillReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
};

export type DownwardReviewSettingsType = {
  __typename?: 'DownwardReviewSettingsType';
  endReviewCycleConfig?: Maybe<ReviewCycleEndConfig>;
  /** false if manager can see reviews only when everyone has submitted */
  managerReviewImmediateVisibility?: Maybe<Scalars['Boolean']['output']>;
  /** false if manager can submit reviews only after other reviewers */
  managerReviewSequenceWithOtherReviewers?: Maybe<Scalars['Boolean']['output']>;
  mindsetReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
  questions?: Maybe<Array<ReviewCycleQuestionType>>;
  resultDeliverySettings?: Maybe<DownardReviewResultDeliverySettings>;
  /** instructions for reviewers from admin */
  reviewInstruction?: Maybe<InstructionType>;
  skillReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
};

/** Draft Exercise Schema */
export type DraftExerciseType = {
  __typename?: 'DraftExerciseType';
  advanceSettings?: Maybe<HomeworkAdvanceSettingsType>;
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  completionReviewRequired?: Maybe<Scalars['Boolean']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** Questions for exercise. Learner has to submit answers for */
  exerciseQuestions: Array<ExerciseQuestionType>;
  /** Assignment Instructions in text or media */
  instruction?: Maybe<ExerciseInstructionType>;
  /** Draft name of the resource */
  name: Scalars['String']['output'];
  objective?: Maybe<Scalars['String']['output']>;
  reviewType: ResourceReviewTypeEnum;
  reviewerConfig?: Maybe<ReviewerConfig>;
  /** Reviewer Instructions in text or media */
  reviewerInstruction?: Maybe<ExerciseInstructionType>;
  /** Ids of Scorecard related to the exercise */
  scorecardIds?: Maybe<Array<Scalars['String']['output']>>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  tags?: Maybe<Array<EntityTagType>>;
};

/** draft external content type */
export type DraftExternalContentType = {
  __typename?: 'DraftExternalContentType';
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Draft External content URL */
  contentUrl?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<EntityMediaType>;
  /** Description of the draft external content */
  description?: Maybe<Scalars['String']['output']>;
  /** Duration in seconds */
  duration?: Maybe<Scalars['Float']['output']>;
  /** Type of the external content */
  externalContentType?: Maybe<ExternalContentTypeEnum>;
  marketplaceContentId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** Objective of the draft external content */
  objective?: Maybe<Scalars['String']['output']>;
  /** Id of the draft external content provider */
  providerId?: Maybe<Scalars['String']['output']>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Method to verify the completion of the draft external content */
  verificationMethod?: Maybe<VerificationMethodType>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

/** Draft Path Type */
export type DraftPathContentType = {
  __typename?: 'DraftPathContentType';
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  cover?: Maybe<EntityMediaType>;
  /** Stores the draft description of the path */
  description?: Maybe<Scalars['String']['output']>;
  dripSettings: DripSettingsType;
  /** Path Draft Medias */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Draft name of the resource */
  name: Scalars['String']['output'];
  /** Draft Objective of the path content */
  objective?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<PathSectionType>>;
  /** skill ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  tags?: Maybe<Array<EntityTagType>>;
  visibilitySettings: VisibilitySettingsType;
};

export type DraftScormContentType = {
  __typename?: 'DraftScormContentType';
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  cover?: Maybe<EntityMediaType>;
  /** Description of the external content */
  description?: Maybe<Scalars['String']['output']>;
  /** Duration in seconds */
  duration?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** Objective of the scorm content */
  objective?: Maybe<Scalars['String']['output']>;
  scormProperties: ScormProperties;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  tags?: Maybe<Array<EntityTagType>>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type DraftSurveyType = {
  __typename?: 'DraftSurveyType';
  /** Database Metadata Id */
  dbMetadataId: Scalars['String']['output'];
  /** Database View Id */
  dbViewIds: Array<DbViewId>;
};

/** Draft Task Type */
export type DraftTaskContentType = {
  __typename?: 'DraftTaskContentType';
  /** duration in seconds */
  duration: Scalars['Float']['output'];
  /** description of the task */
  instruction?: Maybe<TaskInstruction>;
  /** Draft name of the content */
  name: Scalars['String']['output'];
  sections?: Maybe<Array<TaskSectionType>>;
};

export enum DripByEnum {
  Content = 'CONTENT',
  Lesson = 'LESSON',
  Section = 'SECTION'
}

export type DripSettingsInput = {
  dripBy?: InputMaybe<DripByEnum>;
  interval?: InputMaybe<Scalars['Float']['input']>;
  subLessonDripEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type: DripTypeEnum;
};

/** Content drip settings */
export type DripSettingsType = {
  __typename?: 'DripSettingsType';
  dripBy?: Maybe<DripByEnum>;
  interval?: Maybe<Scalars['Float']['output']>;
  subLessonDripEnabled?: Maybe<Scalars['Boolean']['output']>;
  type: DripTypeEnum;
};

export type DripSettingsV2Input = {
  condition?: InputMaybe<FilterGroupV2InputType>;
  dripBy?: InputMaybe<DripByEnum>;
  interval?: InputMaybe<Scalars['Float']['input']>;
  type: DripTypeEnum;
};

/** Common content drip settings v2 */
export type DripSettingsV2Type = {
  __typename?: 'DripSettingsV2Type';
  condition?: Maybe<FilterGroupV2Type>;
  dripBy?: Maybe<DripByEnum>;
  interval?: Maybe<Scalars['Float']['output']>;
  subLessonDripEnabled?: Maybe<Scalars['Boolean']['output']>;
  type: DripTypeEnum;
};

export enum DripTypeEnum {
  Conditional = 'CONDITIONAL',
  Immediate = 'IMMEDIATE',
  Interval = 'INTERVAL',
  Sequential = 'SEQUENTIAL'
}

export type DueDateAssignmentConfig = {
  __typename?: 'DueDateAssignmentConfig';
  dueDate?: Maybe<Scalars['String']['output']>;
  dueDateType: AssignmentDueDateEnum;
  operator?: Maybe<RelativeDateFilterOperatorEnum>;
  tagId?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<RelativeDateFilterUnitEnum>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DueDateAssignmentConfigInput = {
  dueDate?: InputMaybe<Scalars['String']['input']>;
  dueDateType: AssignmentDueDateEnum;
  operator?: InputMaybe<RelativeDateFilterOperatorEnum>;
  tagId?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<RelativeDateFilterUnitEnum>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type DueDateConfig = {
  __typename?: 'DueDateConfig';
  dueDate?: Maybe<Scalars['String']['output']>;
  dueDateType: DueDateTypeEnum;
  operator?: Maybe<RelativeDateFilterOperatorEnum>;
  tagId?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<RelativeDateFilterUnitEnum>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DueDateConfigInput = {
  dueDate?: InputMaybe<Scalars['String']['input']>;
  dueDateType: DueDateTypeEnum;
  operator?: InputMaybe<RelativeDateFilterOperatorEnum>;
  tagId?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<RelativeDateFilterUnitEnum>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum DueDateNestingLevelEnum {
  Contents = 'CONTENTS',
  Sections = 'SECTIONS'
}

export type DueDateSetInput = {
  /** used to set /unset duedate in action item */
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum DueDateTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  NoDueDate = 'NO_DUE_DATE',
  Relative = 'RELATIVE'
}

export type DuplicateActionPlanInput = {
  actionPlanId: Scalars['String']['input'];
};

export type DuplicateActionPlanPayload = {
  __typename?: 'DuplicateActionPlanPayload';
  actionPlan: ActionPlanType;
};

export type DuplicateAgendaItemInput = {
  agendaItemId: Scalars['String']['input'];
};

export type DuplicateAgendaItemTemplateInput = {
  agendaItemTemplateId: Scalars['String']['input'];
};

export type DuplicateContentInput = {
  entityId: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type DuplicateContentPayload = {
  __typename?: 'DuplicateContentPayload';
  entity: ContentEntity;
};

export type DuplicateEventInput = {
  eventId: Scalars['String']['input'];
};

export type DuplicateGroupInput = {
  groupId: Scalars['String']['input'];
};

/** input for Duplicating OKRs */
export type DuplicateOkrInput = {
  /** new parent id of okr being duplicated, if not present will be duplicated to root */
  alignToParentId?: InputMaybe<Scalars['String']['input']>;
  /** whether to duplicate comments,attachments, note block in newly created OKRS */
  duplicateAuxiliaryData?: InputMaybe<Scalars['Boolean']['input']>;
  /** whether to duplicate whole okr tree or not */
  duplicateOKRSubTree?: InputMaybe<Scalars['Boolean']['input']>;
  /** id of cycle to which new okr will be duplicated to */
  duplicateToCycleId: Scalars['String']['input'];
  /** whether to keep the same progress in newly created OKRS */
  maintainProgress?: InputMaybe<Scalars['Boolean']['input']>;
  okrId: Scalars['String']['input'];
  /** new parent ids under which okr will be duplicated */
  updatedParentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DuplicateOkrPayload = {
  __typename?: 'DuplicateOKRPayload';
  /** New Duplicated(Created) okr */
  duplicatedOKR?: Maybe<OkrUnion>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DuplicateReportInput = {
  reportId: Scalars['String']['input'];
};

/** Duplicate Report Payload */
export type DuplicateReportPayload = {
  __typename?: 'DuplicateReportPayload';
  report: ReportType;
};

export type DuplicateSkillMatrixInput = {
  skillMatrixId: Scalars['String']['input'];
};

export type DuplicateSurveyInput = {
  /** Parent Content Id */
  parentContentId?: InputMaybe<Scalars['String']['input']>;
  /** Parent Survey Id */
  surveyId: Scalars['String']['input'];
};

/** Duplicate Survey Payload */
export type DuplicateSurveyPayload = {
  __typename?: 'DuplicateSurveyPayload';
  survey?: Maybe<SurveyContentType>;
};

export type DuplicateUserSurveyInput = {
  surveyId?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type DuplicateUserSurveyPayload = {
  __typename?: 'DuplicateUserSurveyPayload';
  success: Scalars['Boolean']['output'];
  userSurvey?: Maybe<UserSurveyType>;
};

export type DuplicationAllowedOnSharingInput = {
  sharedToWorkspaceId: Scalars['String']['input'];
};

export enum EditPostErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR',
  PostNotFound = 'POST_NOT_FOUND'
}

export type EditPostInput = {
  attachments?: InputMaybe<AttachmentsSetInput>;
  /** Post channel id */
  channelId: Scalars['String']['input'];
  /** Flag to hide the post on the channel */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to pin the post on the channel */
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<MentionsSetInput>;
  /** Actual message which needs to be edited */
  message?: InputMaybe<Scalars['String']['input']>;
  /** PostId which needs to be edited */
  postId: Scalars['String']['input'];
  /** Title of posts like announcement post */
  title?: InputMaybe<Scalars['String']['input']>;
  urls?: InputMaybe<UrlsSetInput>;
  /** Urls of the post */
  urlsV2?: InputMaybe<UrlsV2SetInput>;
};

export type EditPostPayload = {
  __typename?: 'EditPostPayload';
  errorCode?: Maybe<EditPostErrorCodeEnum>;
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type EditSkillGroupInOrgRoleInput = {
  /** name of the skill group */
  name: Scalars['String']['input'];
  /** id of the skill group */
  skillGroupId: Scalars['String']['input'];
};

export type EditSkillGroupInTrackInput = {
  /** name of the skill group */
  name: Scalars['String']['input'];
  /** id of the skill group */
  skillGroupId: Scalars['String']['input'];
};

/** Email Digest Edge */
export type EmailDigestEdge = {
  __typename?: 'EmailDigestEdge';
  node: EmailDigestType;
};

export enum EmailDigestFileFormatEnum {
  Csv = 'CSV',
  Pdf = 'PDF',
  Png = 'PNG'
}

export type EmailDigestPayload = {
  __typename?: 'EmailDigestPayload';
  emailDigest?: Maybe<EmailDigestType>;
};

export enum EmailDigestResourceTypeEnum {
  Report = 'REPORT',
  Survey = 'SURVEY'
}

export enum EmailDigestStatusEnum {
  Active = 'ACTIVE',
  Paused = 'PAUSED'
}

export type EmailDigestType = {
  __typename?: 'EmailDigestType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  entityType: EmailDigestResourceTypeEnum;
  exportMetadata?: Maybe<ExportMetadata>;
  fileFormat: EmailDigestFileFormatEnum;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  recipients: Array<RecipientInfo>;
  /** Report for digest */
  report?: Maybe<ReportType>;
  schedule: ScheduleInfo;
  scheduledJob: ScheduledJobConfigs;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: EmailDigestStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type EmailDigestsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  emailDigestIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityType?: InputMaybe<Array<EmailDigestResourceTypeEnum>>;
  frequency?: InputMaybe<Array<CronFrequencyEnum>>;
  reportIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EmailDigestStatusEnum>>;
};

export type EmbeddedEntitiesInfo = {
  __typename?: 'EmbeddedEntitiesInfo';
  /** Cover image of the content */
  cover?: Maybe<EntityMediaType>;
  embeddedEntitiesCount: Scalars['Float']['output'];
  entityId: Scalars['String']['output'];
  eventType?: Maybe<EventTypeEnum>;
  name: Scalars['String']['output'];
  previewMemberIds?: Maybe<Array<Scalars['String']['output']>>;
  resourceType?: Maybe<ResourceTypeEnum>;
  sampleMembers: Array<ContentEntity>;
  type: ParentEntityTypeEnum;
};

export type EmbeddedEntitiesInfoPayload = {
  __typename?: 'EmbeddedEntitiesInfoPayload';
  embeddedEntitiesInfo: Array<EmbeddedEntitiesInfo>;
};

export enum EmployeeJourneyReportTypeEnum {
  Content = 'CONTENT',
  Employees = 'EMPLOYEES',
  Forms = 'FORMS',
  Reviews = 'REVIEWS',
  Sessions = 'SESSIONS'
}

export type EmployeeJourneyReportsExportInputProps = {
  employeeJourneyId: Scalars['String']['input'];
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
  reportTypes?: InputMaybe<Array<EmployeeJourneyReportTypeEnum>>;
  reportsExportType: EmployeeJourneyReportsExportTypeEnum;
};

export type EmployeeJourneyReportsExportInputPropsType = {
  __typename?: 'EmployeeJourneyReportsExportInputPropsType';
  employeeJourneyId: Scalars['String']['output'];
  properties?: Maybe<Array<Scalars['String']['output']>>;
  reportTypes?: Maybe<Array<EmployeeJourneyReportTypeEnum>>;
  reportsExportType: EmployeeJourneyReportsExportTypeEnum;
};

export type EmployeeJourneyReportsExportMetadataType = {
  __typename?: 'EmployeeJourneyReportsExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export enum EmployeeJourneyReportsExportTypeEnum {
  All = 'ALL',
  Specific = 'SPECIFIC'
}

export type EndScheduleInfo = {
  __typename?: 'EndScheduleInfo';
  date?: Maybe<Scalars['String']['output']>;
  endScheduleType: EndScheduleTypeEnum;
  numberOfOccurrences?: Maybe<Scalars['Float']['output']>;
};

export type EndScheduleInfoInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  endScheduleType: EndScheduleTypeEnum;
  numberOfOccurrences?: InputMaybe<Scalars['Float']['input']>;
};

export enum EndScheduleTypeEnum {
  Date = 'DATE',
  Occurrences = 'OCCURRENCES'
}

export enum EnrollmentTypeEnum {
  Assigned = 'ASSIGNED',
  SelfEnrolled = 'SELF_ENROLLED'
}

/** Tells about the enrolment settings */
export type EnrolmentInfoType = {
  __typename?: 'EnrolmentInfoType';
  assignedAt?: Maybe<Scalars['String']['output']>;
  assignedBy?: Maybe<UserType>;
  assignedById?: Maybe<Scalars['String']['output']>;
  assignmentId?: Maybe<Scalars['String']['output']>;
  assignmentType?: Maybe<AssignmentTypeEnum>;
  availableFrom?: Maybe<Scalars['String']['output']>;
  direct?: Maybe<Scalars['Boolean']['output']>;
  dripSettings?: Maybe<DripSettingsType>;
  dueDate?: Maybe<Scalars['String']['output']>;
  selfEnrolled?: Maybe<Scalars['Boolean']['output']>;
};

/** Assignment due date settings */
export type EnrolmentSettingsType = {
  __typename?: 'EnrolmentSettingsType';
  /** Due Date for Assignment */
  dueDate: Scalars['String']['output'];
};

export type EntityAccessPayload = {
  __typename?: 'EntityAccessPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** This schema defines the association with an entity */
export type EntityAssociation = {
  __typename?: 'EntityAssociation';
  blockId?: Maybe<Scalars['String']['output']>;
  courseId: Scalars['String']['output'];
  lessonId: Scalars['String']['output'];
  lessonPageId?: Maybe<Scalars['String']['output']>;
};

/** This schema defines the association with an entity */
export type EntityAssociationInput = {
  blockId?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['String']['input'];
  lessonId: Scalars['String']['input'];
};

export type EntityCheck = {
  __typename?: 'EntityCheck';
  entity: EntityCheckEnum;
  exists: Scalars['Boolean']['output'];
};

export enum EntityCheckEnum {
  Okr = 'OKR',
  Task = 'TASK'
}

export type EntityCheckWidgetType = {
  __typename?: 'EntityCheckWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  entitiesToCheck: Array<EntityCheckEnum>;
  /** check if entity exists or not */
  entityExists: Array<EntityCheck>;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum EntityCollectionEnum {
  Block = 'BLOCK',
  Event = 'EVENT',
  Okr = 'OKR',
  Resource = 'RESOURCE',
  User = 'USER'
}

/** EntityCycleReviewType Schema */
export type EntityCycleReviewType = {
  __typename?: 'EntityCycleReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** skill score in denormalised form (out of 5) for skill/skillGroup entity */
  denormalisedScore?: Maybe<Scalars['Float']['output']>;
  entityId: Scalars['String']['output'];
  entityType: ReviewModuleTypeEnum;
  /** expected score in a cycle for skill/skillGroup entity */
  expectedScore?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  /** type of question */
  questionType?: Maybe<QuestionTypeEnum>;
  /** review cycle instance */
  reviewCycleId: Scalars['String']['output'];
  /** reviewType */
  reviewType: Scalars['String']['output'];
  /** overall score in a cycle for particular entity */
  score: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroupId?: Maybe<Scalars['String']['output']>;
  skillMatrixId?: Maybe<Scalars['String']['output']>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting reviewed */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type EntityGroupFilter = {
  conditionOperator?: InputMaybe<GroupConditionOperator>;
  groupAdditionMethod: GroupAdditionMethodEnum;
  groupIds: Array<Scalars['String']['input']>;
};

export type EntityGroupFilterType = {
  __typename?: 'EntityGroupFilterType';
  groupAdditionMethod: GroupAdditionMethodEnum;
  groupIds: Array<Scalars['String']['output']>;
};

export type EntityJiraIntegrationStatusConfig = {
  __typename?: 'EntityJiraIntegrationStatusConfig';
  jiraStatusId?: Maybe<Scalars['String']['output']>;
  lyearnStatusId?: Maybe<Scalars['String']['output']>;
};

export type EntityJiraProjectStatuesConfig = {
  __typename?: 'EntityJiraProjectStatuesConfig';
  projectId: Scalars['String']['output'];
  statuses?: Maybe<Array<EntityJiraIntegrationStatusConfig>>;
};

/** General Media Object, can be used with any entity */
export type EntityMediaType = {
  __typename?: 'EntityMediaType';
  accessClass: MediaAccessClassEnum;
  /** Tells whether it is a file or folder */
  kind: MediaKindEnum;
  location: MediaLocation;
  mediaId: Scalars['String']['output'];
  /** media type of uploaded entity */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** name of the media stored */
  name: Scalars['String']['output'];
  previewEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Media URL */
  resolutions?: Maybe<Array<ResolutionType>>;
  /** Size of the attachment in kbs */
  size?: Maybe<Scalars['Float']['output']>;
  uniqueId?: Maybe<Scalars['String']['output']>;
  /** url of the attachment stored on our end */
  url?: Maybe<Scalars['String']['output']>;
  /** preview of url */
  urlPreview?: Maybe<Scalars['Map']['output']>;
  urlPreviewMetadata?: Maybe<UrlPreviewMetadata>;
};

export type EntityPermissionsMap = {
  __typename?: 'EntityPermissionsMap';
  entityPermissions: Array<AllowedEntityPermissions>;
  icon?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type EntityPermittedOn = {
  __typename?: 'EntityPermittedOn';
  actions: Array<AllowedPermissionActionsEnum>;
  entities: Array<AllowedPermissionsEntityType>;
  subject: AllowedPermissionsSubjectType;
};

/** It will resolve groupRole or userRole */
export type EntitySharedSpace = SharedSpaceGroupRoleType | SharedSpaceUserRoleType;

export type EntitySubworkspacesInput = {
  set: Array<Scalars['String']['input']>;
};

export type EntityTagInput = {
  dateValue?: InputMaybe<DateValueInput>;
  numberValue?: InputMaybe<Scalars['Float']['input']>;
  numberValues?: InputMaybe<Array<Scalars['Float']['input']>>;
  optionIdValues?: InputMaybe<Array<Scalars['String']['input']>>;
  tagId: Scalars['String']['input'];
  textValue?: InputMaybe<Scalars['String']['input']>;
  textValues?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<TagTypeEnum>;
  userIdValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EntityTagType = {
  __typename?: 'EntityTagType';
  dateValue?: Maybe<DateValueType>;
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  numberValue?: Maybe<Scalars['Float']['output']>;
  optionIdValues?: Maybe<Array<Scalars['String']['output']>>;
  options?: Maybe<Array<SelectTagOptionType>>;
  tag?: Maybe<TagUnion>;
  tagId: Scalars['String']['output'];
  textValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TagTypeEnum>;
  userIdValues?: Maybe<Array<Scalars['String']['output']>>;
  userProfiles?: Maybe<Array<UserProfile>>;
  users?: Maybe<Array<UserType>>;
};

export type EntityTagsInput = {
  set: Array<EntityTagInput>;
};

export enum EntityTypeEnum {
  Article = 'ARTICLE',
  Assignment = 'ASSIGNMENT',
  AuthToken = 'AUTH_TOKEN',
  Channel = 'CHANNEL',
  Classroom = 'CLASSROOM',
  Course = 'COURSE',
  Department = 'DEPARTMENT',
  Event = 'EVENT',
  Exercise = 'EXERCISE',
  GenerativeTask = 'GENERATIVE_TASK',
  KeyResult = 'KEY_RESULT',
  Lesson = 'LESSON',
  Objective = 'OBJECTIVE',
  Okr = 'OKR',
  Path = 'PATH',
  Performance = 'PERFORMANCE',
  Quiz = 'QUIZ',
  Resource = 'RESOURCE',
  Task = 'TASK',
  User = 'USER',
  UserSurvey = 'USER_SURVEY',
  VerificationCode = 'VERIFICATION_CODE',
  Workspace = 'WORKSPACE'
}

export type EntityViewConfigInput = {
  fields: Array<UserViewFieldsInputSchema>;
};

/** This holds the information of the view of the entity */
export type EntityViewConfigSchema = {
  __typename?: 'EntityViewConfigSchema';
  fields: Array<UserViewFieldSchema>;
  updatedAt: Scalars['String']['output'];
};

export type EntityWorkspaceMapping = {
  __typename?: 'EntityWorkspaceMapping';
  entityId: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

/** input for approving or rejecting exercise submission */
export type EvaluateExerciseSubmissionInput = {
  entityId: Scalars['String']['input'];
  /** set to true to feature this submission */
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  status: ExerciseSubmissionStatusEnum;
  submissionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** payload for exerciseSubmissionStatus */
export type EvaluateExerciseSubmissionPayload = {
  __typename?: 'EvaluateExerciseSubmissionPayload';
  currentReviewingState?: Maybe<CurrentReviewingState>;
  errorCode?: Maybe<PerformanceErrorCode>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum EventActions {
  Add = 'ADD',
  Allow = 'ALLOW',
  Archive = 'ARCHIVE',
  ControlOthers = 'CONTROL_OTHERS',
  Create = 'CREATE',
  Edit = 'EDIT',
  End = 'END',
  Join = 'JOIN',
  Launch = 'LAUNCH',
  Leave = 'LEAVE',
  Manage = 'MANAGE',
  Participate = 'PARTICIPATE',
  Priority = 'PRIORITY',
  Read = 'READ',
  Remove = 'REMOVE',
  Start = 'START',
  View = 'VIEW'
}

export type EventAttendeesFilters = {
  performancesFilters: PerformancesFilters;
  userFilters?: InputMaybe<PerformanceUsersFilters>;
};

export type EventAttendeesFiltersType = {
  __typename?: 'EventAttendeesFiltersType';
  performancesFilters: PerformancesFiltersType;
  userFilters?: Maybe<PerformanceUsersFiltersType>;
};

export type EventAttendeesFiltersV2 = {
  attendanceStatus?: InputMaybe<Array<AttendanceStatusEnum>>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  isAttended?: InputMaybe<Scalars['Boolean']['input']>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  parentEventId?: InputMaybe<Scalars['String']['input']>;
  registeredOn?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<PerformanceStatusEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userSearchText?: InputMaybe<Scalars['String']['input']>;
  userTypes?: InputMaybe<Array<EventUserTypeEnum>>;
  workspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Edge */
export type EventEdge = {
  __typename?: 'EventEdge';
  node: EventUnion;
};

export type EventExternalPerformanceInfo = {
  __typename?: 'EventExternalPerformanceInfo';
  duration?: Maybe<Scalars['Float']['output']>;
};

export type EventFilters = {
  conferenceBridgeId?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
};

export type EventLocation = {
  __typename?: 'EventLocation';
  customLocation?: Maybe<Scalars['String']['output']>;
  geoLocation?: Maybe<GeoLocation>;
  type: EventLocationTypeEnum;
};

export type EventLocationInput = {
  customLocation?: InputMaybe<Scalars['String']['input']>;
  geoLocation?: InputMaybe<GeoLocationInput>;
  type: EventLocationTypeEnum;
};

export enum EventLocationTypeEnum {
  Custom = 'CUSTOM',
  GeoLocation = 'GEO_LOCATION'
}

/** Event metadata */
export type EventMetadataType = {
  __typename?: 'EventMetadataType';
  bookedSeatRatio: Scalars['Float']['output'];
};

export type EventPayload = {
  __typename?: 'EventPayload';
  event?: Maybe<EventUnion>;
};

export type EventPerformanceType = {
  __typename?: 'EventPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  attendanceStatus?: Maybe<AttendanceStatusEnum>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  externalEventRegistrationInfo?: Maybe<ExternalEventRegistrationInfo>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  /** indicates if the user was ever present in the event */
  isAttended: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** Parent Id of which recurring event this event is part of */
  parentEntityId?: Maybe<Scalars['String']['output']>;
  postWorkProgress: Scalars['Float']['output'];
  preWorkProgress: Scalars['Float']['output'];
  /** percentage of total event time the user was present */
  progress: Scalars['Float']['output'];
  progressInfo?: Maybe<ProgressInfoType>;
  progressSource?: Maybe<ProgressSourceEnum>;
  recordingWatchSummaries?: Maybe<Array<RecordingWatchSummary>>;
  /** date when the user registered on event */
  registeredOn?: Maybe<Scalars['String']['output']>;
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  userType: EventUserTypeEnum;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type EventRecordingInput = {
  duration: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  recordingMedia: CreateMediaInput;
};

export type EventRecordingType = {
  __typename?: 'EventRecordingType';
  _id: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  recordingMedia: EntityMediaType;
};

export enum EventSeatStatusEnum {
  Available = 'AVAILABLE',
  Booked = 'BOOKED',
  FillingFast = 'FILLING_FAST'
}

export type EventSelection = {
  __typename?: 'EventSelection';
  assignmentType?: Maybe<AssignmentTypeEnum>;
  eventId: Scalars['String']['output'];
};

export type EventSelectionInput = {
  /** The type of assignment for recurring sessions */
  assignmentType: AssignmentTypeEnum;
  /** event selected */
  eventId: Scalars['String']['input'];
};

/** self enroll course inside course micro app */
export type EventSharingSelfEnrollCourseInput = {
  courseId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
};

export enum EventStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Revoked = 'REVOKED',
  Scheduled = 'SCHEDULED',
  Started = 'STARTED',
  Waiting = 'WAITING'
}

export enum EventSubTypeEnum {
  GroupSession = 'GROUP_SESSION',
  Webinar = 'WEBINAR'
}

export enum EventSubjects {
  Activity = 'ACTIVITY',
  Attendance = 'ATTENDANCE',
  Audio = 'AUDIO',
  Chat = 'CHAT',
  Course = 'COURSE',
  Event = 'EVENT',
  MemberList = 'MEMBER_LIST',
  Notepad = 'NOTEPAD',
  One = 'ONE',
  Poll = 'POLL',
  Quiz = 'QUIZ',
  RaiseHand = 'RAISE_HAND',
  Recordings = 'RECORDINGS',
  ScreenShare = 'SCREEN_SHARE',
  StudyMaterial = 'STUDY_MATERIAL',
  SubWorkspace = 'SUB_WORKSPACE',
  VideoShare = 'VIDEO_SHARE',
  Whiteboard = 'WHITEBOARD'
}

export type EventSubscriptionInput = {
  eventId: Scalars['String']['input'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<EventSubscriptionOperationEnum>>;
};

export enum EventSubscriptionOperationEnum {
  EventUpdated = 'EVENT_UPDATED'
}

export type EventSubscriptionPayload = {
  __typename?: 'EventSubscriptionPayload';
  event: EventUnion;
  operation: EventSubscriptionOperationEnum;
};

export enum EventTypeEnum {
  External = 'EXTERNAL',
  Live = 'LIVE',
  Offline = 'OFFLINE',
  RecurringExternal = 'RECURRING_EXTERNAL',
  RecurringLive = 'RECURRING_LIVE',
  RecurringOffline = 'RECURRING_OFFLINE'
}

/** union of all events */
export type EventUnion = ExternalEventType | LiveEventType | OfflineEventType | RecurringExternalEventType | RecurringLiveEventType | RecurringOfflineEventType;

export enum EventUpdationSourceEnum {
  ExternalSync = 'EXTERNAL_SYNC',
  Scheduler = 'SCHEDULER',
  User = 'USER'
}

export enum EventUserTypeEnum {
  Instructor = 'INSTRUCTOR',
  Learner = 'LEARNER'
}

export type EventWorkConfig = {
  __typename?: 'EventWorkConfig';
  contents: Array<EventWorkContent>;
};

export type EventWorkConfigInput = {
  contents: Array<EventWorkContentInput>;
};

export type EventWorkContent = {
  __typename?: 'EventWorkContent';
  contentId: Scalars['String']['output'];
  isOptional: Scalars['Boolean']['output'];
};

export type EventWorkContentInput = {
  contentId: Scalars['String']['input'];
  isOptional: Scalars['Boolean']['input'];
};

export type EventWorkContentsInput = {
  set: Array<Scalars['String']['input']>;
};

export type EventsFilters = {
  associatedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endTime?: InputMaybe<DateFilter>;
  eventIds?: InputMaybe<Array<Scalars['String']['input']>>;
  eventSeatStatus?: InputMaybe<Array<EventSeatStatusEnum>>;
  excludeReceivedEvents?: InputMaybe<Scalars['Boolean']['input']>;
  /** filter by field and tag properties */
  filterGroupV2?: InputMaybe<FilterGroupV2InputType>;
  /** when RECURRING_INSTANCE in recurringCategories */
  includeOnlyStandaloneRecurringInstance?: InputMaybe<Scalars['Boolean']['input']>;
  instructorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  notInParentEventIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEventIds?: InputMaybe<Array<Scalars['String']['input']>>;
  preWorkORPostWorkContentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  recurringCategories?: InputMaybe<Array<RecurringTypeEnum>>;
  recurringFrequencies?: InputMaybe<Array<CronFrequencyEnum>>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  sharedEventFilters?: InputMaybe<SharedEntityFilters>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<EventStatusEnum>>;
  /** get resources based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<EventTypeEnum>>;
};

export type EventsWidgetType = {
  __typename?: 'EventsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  /** contents */
  events: PaginatedEventsPayload;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type EventsWidgetTypeEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type ExerciseAdvanceSettingsInput = {
  allowComments: Scalars['Boolean']['input'];
};

export type ExerciseHotspotInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type ExerciseHotspotResponseInput = {
  id: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

/** Hotspot response attributes */
export type ExerciseHotspotResponseType = {
  __typename?: 'ExerciseHotspotResponseType';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Hotspot attributes */
export type ExerciseHotspotType = {
  __typename?: 'ExerciseHotspotType';
  id: Scalars['String']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ExerciseImageHotspotInput = {
  hotspots: Array<ExerciseHotspotInput>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
};

export type ExerciseImageHotspotResponseInput = {
  hotspots: Array<ExerciseHotspotResponseInput>;
  mediaId: Scalars['String']['input'];
};

/** Image hotspot response attributes */
export type ExerciseImageHotspotResponseType = {
  __typename?: 'ExerciseImageHotspotResponseType';
  hotspots: Array<ExerciseHotspotResponseType>;
  mediaId: Scalars['String']['output'];
};

/** Image hotspot attributes */
export type ExerciseImageHotspotType = {
  __typename?: 'ExerciseImageHotspotType';
  hotspots: Array<ExerciseHotspotType>;
  mediaId: Scalars['String']['output'];
};

export type ExerciseInstructionInput = {
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Instruction Text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Instruction for Exercise */
export type ExerciseInstructionType = {
  __typename?: 'ExerciseInstructionType';
  /** media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']['output']>;
};

export type ExercisePerformanceType = {
  __typename?: 'ExercisePerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  currentReviewingState?: Maybe<CurrentReviewingState>;
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  featuredStatusUpdatedAt?: Maybe<Scalars['String']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  hasFeaturedSubmission?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isContextUserReviewer: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  isResubmissionAllowed?: Maybe<Scalars['Boolean']['output']>;
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  parentEntity?: Maybe<ContentEntity>;
  parentEntityId?: Maybe<Scalars['String']['output']>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** reviewer info */
  reviewInfo?: Maybe<ExerciseReviewInfo>;
  /** review result */
  reviewResults?: Maybe<Array<ExerciseReviewType>>;
  reviewStatus?: Maybe<ReviewStatusEnum>;
  reviewers: Array<UserType>;
  score?: Maybe<Scalars['Float']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  /** submission reviewer info */
  submissionReviewInfo?: Maybe<SubmissionReviewInfo>;
  /** Submissions */
  submissions?: Maybe<Array<ExerciseSubmissionType>>;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};


export type ExercisePerformanceTypeCurrentReviewingStateArgs = {
  submissionId: Scalars['String']['input'];
};


export type ExercisePerformanceTypeIsResubmissionAllowedArgs = {
  submissionId: Scalars['String']['input'];
};

export type ExerciseQuestionInput = {
  /** Question id, to update the same question */
  id?: InputMaybe<Scalars['String']['input']>;
  /** hotspots for image hotspot type question */
  imageHotspots?: InputMaybe<Array<ExerciseImageHotspotInput>>;
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Type for response, for learner to submit */
  responseType: ResourceResponseTypeEnum;
  /** Question Text */
  text: Scalars['String']['input'];
};

/** This is the input for submission of Exercise! */
export type ExerciseQuestionResponseInput = {
  /** User response for image hotspot type */
  imageHotspotResponses?: InputMaybe<Array<ExerciseImageHotspotResponseInput>>;
  /** User response with some attachments */
  mediaResponses?: InputMaybe<Array<CreateMediaInput>>;
  /** Id of the question for which submission is made */
  questionId: Scalars['String']['input'];
  responseType: ResourceResponseTypeEnum;
  /** If response type is text, HTML string */
  textResponse?: InputMaybe<Scalars['String']['input']>;
};

export type ExerciseQuestionResponseType = {
  __typename?: 'ExerciseQuestionResponseType';
  /** hotspots responses for image hotspot type question */
  imageHotspotResponses?: Maybe<Array<ExerciseImageHotspotResponseType>>;
  question?: Maybe<ExerciseQuestionType>;
  questionId: Scalars['String']['output'];
  response?: Maybe<Array<EntityMediaType>>;
  responseType: ResourceResponseTypeEnum;
  /** If response type is text, HTML string */
  textResponse?: Maybe<Scalars['String']['output']>;
};

/** Question for Exercise */
export type ExerciseQuestionType = {
  __typename?: 'ExerciseQuestionType';
  id: Scalars['String']['output'];
  /** hotspots for image hotspot type question */
  imageHotspots?: Maybe<Array<ExerciseImageHotspotType>>;
  /** media for question */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Type for response, for learner to submit */
  responseType: ResourceResponseTypeEnum;
  /** text for question */
  text: Scalars['String']['output'];
};

/** Data regarding the review */
export type ExerciseReviewInfo = {
  __typename?: 'ExerciseReviewInfo';
  peerReviewSubmitted?: Maybe<Scalars['Boolean']['output']>;
  peerReviewSubmittedAt?: Maybe<Scalars['String']['output']>;
  reviewers: Array<ReviewerInfo>;
  reviewersAssigned: Scalars['Float']['output'];
};

export type ExerciseReviewType = {
  __typename?: 'ExerciseReviewType';
  approved: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['String']['output']>;
  reviewer?: Maybe<UserType>;
  reviewerId: Scalars['String']['output'];
  /** score */
  score?: Maybe<Scalars['Float']['output']>;
};

export type ExerciseSubmissionScorecardScoreType = {
  __typename?: 'ExerciseSubmissionScorecardScoreType';
  score?: Maybe<Scalars['Float']['output']>;
  scorecardId: Scalars['String']['output'];
};

export enum ExerciseSubmissionStatusEnum {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type ExerciseSubmissionType = {
  __typename?: 'ExerciseSubmissionType';
  allReviewersScorecardScores?: Maybe<Array<ReviewerExerciseSubmissionScorecardScoreType>>;
  /** It will be created lazily when someone gives a feedback to a submission */
  feedbackChannelId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  /** when user does partial submission, this will be false.On final submission,will be set true */
  isSubmitted?: Maybe<Scalars['Boolean']['output']>;
  questionResponses: Array<ExerciseQuestionResponseType>;
  reviewedAt?: Maybe<Scalars['String']['output']>;
  reviewedBy?: Maybe<UserType>;
  /** Id of the reviewer who has reviewed the submission */
  reviewedById?: Maybe<Scalars['String']['output']>;
  reviewerScorecardScore?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scorecardScores?: Maybe<Array<ExerciseSubmissionScorecardScoreType>>;
  submittedAt: Scalars['String']['output'];
};


export type ExerciseSubmissionTypeReviewerScorecardScoreArgs = {
  reviewerId?: InputMaybe<Scalars['String']['input']>;
};


export type ExerciseSubmissionTypeScorecardScoresArgs = {
  reviewerId?: InputMaybe<Scalars['String']['input']>;
};

export type ExerciseSubmissionsForReviewerFilters = {
  reviewStatus?: InputMaybe<Array<ReviewStatusEnum>>;
  reviewedOn?: InputMaybe<DateFilter>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  submittedOn?: InputMaybe<DateFilter>;
  /** get submissions of reviewees based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
};

/** Exercise Schema */
export type ExerciseType = {
  __typename?: 'ExerciseType';
  advanceSettings?: Maybe<HomeworkAdvanceSettingsType>;
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** discussion channel */
  commentChannelId?: Maybe<Scalars['String']['output']>;
  completionReviewRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<DraftExerciseType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  duration: Scalars['Float']['output'];
  /** Homework Deadline and availability settings */
  enrolmentSettings?: Maybe<EnrolmentSettingsType>;
  /** Questions for exercise. Learner has to submit answers for */
  exerciseQuestions: Array<ExerciseQuestionType>;
  /** Feedback Questions for the exercise */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  gradeSettings: ResourceGradeSettingsType;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** Assignment Instructions in text or media */
  instruction?: Maybe<ExerciseInstructionType>;
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<ExercisePerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  reviewType: ResourceReviewTypeEnum;
  reviewerConfig?: Maybe<ReviewerConfig>;
  /** Reviewer Instructions in text or media */
  reviewerInstruction?: Maybe<ExerciseInstructionType>;
  reviewers: Array<UserType>;
  /** Ids of Scorecard related to the exercise */
  scorecardIds?: Maybe<Array<Scalars['String']['output']>>;
  scorecards: Array<ScorecardType>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Exercise Schema */
export type ExerciseTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Exercise Schema */
export type ExerciseTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Exercise Schema */
export type ExerciseTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Exercise Schema */
export type ExerciseTypeIsReviewerArgs = {
  learnerId?: InputMaybe<Scalars['String']['input']>;
};


/** Exercise Schema */
export type ExerciseTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Exercise Schema */
export type ExerciseTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Exercise Schema */
export type ExerciseTypePerformanceArgs = {
  filters?: InputMaybe<PerformancesFilters>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Exercise Schema */
export type ExerciseTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Exercise Schema */
export type ExerciseTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Exercise Schema */
export type ExerciseTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum ExpectedTeamSizeEnum {
  FiftyToHundered = 'FIFTY_TO_HUNDERED',
  HunderedToFiveHundered = 'HUNDERED_TO_FIVE_HUNDERED',
  OneToFifty = 'ONE_TO_FIFTY',
  OverFiveHundered = 'OVER_FIVE_HUNDERED'
}

export type ExportMetadata = {
  __typename?: 'ExportMetadata';
  filters?: Maybe<Scalars['String']['output']>;
  projections?: Maybe<Array<Scalars['String']['output']>>;
};

export type ExportMetadataInput = {
  filters?: InputMaybe<Scalars['String']['input']>;
  projections?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExternalContentInstructorInputType = {
  designation: Scalars['String']['input'];
  name: Scalars['String']['input'];
  picture: Scalars['String']['input'];
};

/** External Content Performance Type */
export type ExternalContentPerformanceType = {
  __typename?: 'ExternalContentPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** url type submission */
  externalCertificateUrl?: Maybe<Scalars['String']['output']>;
  /** performance done on external platform */
  externalPerformance?: Maybe<ExternalPerformance>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  /** media type submission */
  mediaSubmission?: Maybe<EntityMediaType>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  /** text submission */
  textSubmission?: Maybe<Scalars['String']['output']>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** External Content Type */
export type ExternalContentType = {
  __typename?: 'ExternalContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** External content URL */
  contentUrl?: Maybe<Scalars['String']['output']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  /** Description of the external content */
  description?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<DraftExternalContentType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** Duration in seconds */
  duration: Scalars['Float']['output'];
  /** Type of the external content */
  externalContentType?: Maybe<ExternalContentTypeEnum>;
  externalResourceInfo?: Maybe<ExternalResourceInfo>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  marketplaceContentId?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  /** Objective of the external content */
  objective?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<ExternalContentPerformanceType>;
  /** Provider of external content */
  provider?: Maybe<ProviderType>;
  /** Id of the external content provider */
  providerId?: Maybe<Scalars['String']['output']>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Skills suitable to the externalContent */
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Method to verify the completion of the external content */
  verificationMethod?: Maybe<VerificationMethodType>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** External Content Type */
export type ExternalContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** External Content Type */
export type ExternalContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** External Content Type */
export type ExternalContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** External Content Type */
export type ExternalContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** External Content Type */
export type ExternalContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** External Content Type */
export type ExternalContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** External Content Type */
export type ExternalContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** External Content Type */
export type ExternalContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** External Content Type */
export type ExternalContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum ExternalContentTypeEnum {
  Article = 'ARTICLE',
  Assessment = 'ASSESSMENT',
  Book = 'BOOK',
  Course = 'COURSE',
  Podcast = 'PODCAST',
  ResearchPaper = 'RESEARCH_PAPER',
  Video = 'VIDEO'
}

/** External event container content type. Refers to event entity */
export type ExternalEventContentType = {
  __typename?: 'ExternalEventContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  event?: Maybe<EventUnion>;
  eventRecurringCategory?: Maybe<RecurringTypeEnum>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEventId?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String']['output'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum ExternalEventCreationStrategyEnum {
  Form = 'FORM',
  Integration = 'INTEGRATION'
}

export type ExternalEventProps = {
  enableWaitingRoom?: InputMaybe<Scalars['Boolean']['input']>;
  externalEventProviderInfo: ExternalEventProviderInfoInput;
  recordingSettings: RecordingSettingsInput;
};

export type ExternalEventProviderInfoInput = {
  creationStrategy?: InputMaybe<ExternalEventCreationStrategyEnum>;
  meta: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type ExternalEventProviderInfoType = {
  __typename?: 'ExternalEventProviderInfoType';
  /** describes whether external session was created with a form fill, or an integration */
  creationStrategy?: Maybe<ExternalEventCreationStrategyEnum>;
  meta: Scalars['String']['output'];
  provider?: Maybe<ProviderType>;
  providerId: Scalars['String']['output'];
};

export type ExternalEventRegistrationInfo = {
  __typename?: 'ExternalEventRegistrationInfo';
  registrantId: Scalars['String']['output'];
};

export type ExternalEventType = {
  __typename?: 'ExternalEventType';
  /** actual ending time */
  actualEndTime?: Maybe<Scalars['String']['output']>;
  /** actual start time */
  actualStartTime?: Maybe<Scalars['String']['output']>;
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']['output']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String']['output'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** to enable waiting room */
  enableWaitingRoom?: Maybe<Scalars['Boolean']['output']>;
  /** user provided end time in UTC */
  endTime: Scalars['String']['output'];
  eventProgress?: Maybe<EventPerformanceType>;
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  externalEventProviderInfo: ExternalEventProviderInfoType;
  firstRegisteredSlotId?: Maybe<Scalars['String']['output']>;
  flexiblePermissionGroupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructorIds: Array<Scalars['String']['output']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isRevokedAfterCompletion: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean']['output'];
  isUserRegistered: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  latestUpcomingEventId?: Maybe<Scalars['String']['output']>;
  latestUpcomingEventStartTime?: Maybe<Scalars['String']['output']>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEvent?: Maybe<EventUnion>;
  /** stores the id of parent recurring session wrapper */
  parentEventId?: Maybe<Scalars['String']['output']>;
  postWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  recordingSettings: RecordingSettings;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']['output']>>;
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** depicts the subtype for session */
  recurringCategory?: Maybe<RecurringTypeEnum>;
  recurringEventSlotCount?: Maybe<Scalars['Float']['output']>;
  registeredEventsCount?: Maybe<Scalars['Float']['output']>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']['output']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float']['output'];
  remainingPreWorkContents: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String']['output'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String']['output'];
  toCompleteScheduledJobId: Scalars['String']['output'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float']['output'];
  type: EventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type ExternalEventTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type ExternalEventTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type ExternalEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type ExternalEventTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

/** External Performance Type to store performance done on other platforms */
export type ExternalPerformance = {
  __typename?: 'ExternalPerformance';
  accuracy: Scalars['Float']['output'];
  confidence: Scalars['Float']['output'];
  minutesSpent: Scalars['Float']['output'];
  progress: Scalars['Float']['output'];
  /** stores all the scores of user */
  scores: Array<ExternalPerformanceScore>;
  /** status on other platform */
  status: Scalars['String']['output'];
  /** Type of externalPerformance */
  type: ExternalPerformanceTypeEnum;
};

/** External Performance Type to store performance done on other platforms */
export type ExternalPerformanceInput = {
  accuracy: Scalars['Float']['input'];
  confidence: Scalars['Float']['input'];
  minutesSpent: Scalars['Float']['input'];
  progress: Scalars['Float']['input'];
  /** stores all the scores of user */
  scores: Array<ExternalPerformanceScoreInput>;
  /** status on other platform */
  status: Scalars['String']['input'];
  /** Type of externalPerformance */
  type: ExternalPerformanceTypeEnum;
};

/** External Performance Score to store score computed on other platforms */
export type ExternalPerformanceScore = {
  __typename?: 'ExternalPerformanceScore';
  /** accuracy by which score is calculated */
  accuracy?: Maybe<Scalars['Float']['output']>;
  /** confidence by which score is calculated */
  confidence?: Maybe<Scalars['Float']['output']>;
  /** Language in which this performance was done */
  language?: Maybe<Scalars['String']['output']>;
  /** minutes spent in doing this course in this language */
  minutesSpent?: Maybe<Scalars['Float']['output']>;
  /** progress in course */
  progress?: Maybe<Scalars['Float']['output']>;
  /** Language in which this performance was done */
  status?: Maybe<Scalars['String']['output']>;
  videosWatched?: Maybe<Scalars['Float']['output']>;
};

/** External Performance Score to store score computed on other platforms */
export type ExternalPerformanceScoreInput = {
  /** accuracy by which score is calculated */
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  /** confidence by which score is calculated */
  confidence?: InputMaybe<Scalars['Float']['input']>;
  /** Language in which this performance was done */
  language?: InputMaybe<Scalars['String']['input']>;
  /** minutes spent in doing this course in this language */
  minutesSpent?: InputMaybe<Scalars['Float']['input']>;
  /** progress in course */
  progress?: InputMaybe<Scalars['Float']['input']>;
  /** Language in which this performance was done */
  status?: InputMaybe<Scalars['String']['input']>;
  videosWatched?: InputMaybe<Scalars['Float']['input']>;
};

export enum ExternalPerformanceTypeEnum {
  SecureCodeWarrior = 'SECURE_CODE_WARRIOR'
}

/** If this resource is linked with some external resource */
export type ExternalResourceInfo = {
  __typename?: 'ExternalResourceInfo';
  /** Unique Id to identify resource  */
  resourceId: Scalars['String']['output'];
};

/** If this resource is linked with some external resource */
export type ExternalResourceInfoInput = {
  /** Unique Id to identify resource  */
  resourceId: Scalars['String']['input'];
};

/** Edge */
export type ExternalUserPerformanceInfoEdge = {
  __typename?: 'ExternalUserPerformanceInfoEdge';
  node: ExternalUserPerformanceInfoType;
};

export type ExternalUserPerformanceInfoType = {
  __typename?: 'ExternalUserPerformanceInfoType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  entityType: PerformanceTypeEnum;
  eventData?: Maybe<EventExternalPerformanceInfo>;
  id: Scalars['String']['output'];
  /** Returns user doc if user is already invited */
  invitedUser?: Maybe<UserType>;
  /** Returns true if the external user has interacted with any Lyearn content before */
  isReturningAttendee: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userAlreadyInvited: Scalars['Boolean']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ExternalUserPerformanceInfosFilters = {
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  externalUserPerformanceInfoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Fetches one external info doc for each external user to list external users across all entities */
  fetchUniqueExternalUsers?: InputMaybe<Scalars['Boolean']['input']>;
  uniqueIdText?: InputMaybe<Scalars['String']['input']>;
  uniqueIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExternalWidgetPreviewType = {
  __typename?: 'ExternalWidgetPreviewType';
  link?: Maybe<LinkType>;
  /** media */
  media?: Maybe<EntityMediaType>;
  type: WidgetTypeEnum;
};

export type ExternalWidgetPropsInput = {
  /** external link props for the widget */
  linkProps?: InputMaybe<LinkPropsInput>;
  /** media for the widget */
  media?: InputMaybe<CreateMediaInput>;
};

export type ExternalWidgetType = {
  __typename?: 'ExternalWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** external link props for the widget */
  link?: Maybe<LinkType>;
  /** media for the widget */
  media?: Maybe<EntityMediaType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** This is used for fill in the blanks */
export type FtbComponent = {
  __typename?: 'FTBComponent';
  distractors?: Maybe<Array<Distractor>>;
  id: Scalars['String']['output'];
  isBlank: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

/** This is used for fill in the blanks */
export type FtbComponentInput = {
  distractors?: InputMaybe<Array<DistractorInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  isBlank: Scalars['Boolean']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type FtbComponentsSetInput = {
  /** FTB component */
  set: Array<FtbComponentInput>;
};

/** Used when response type is Fill in the blank  */
export type FtbResponse = {
  __typename?: 'FTBResponse';
  blankId: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Used when response type is Fill in the blank  */
export type FtbResponseInput = {
  blankId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type FacetApplyOnType = {
  __typename?: 'FacetApplyOnType';
  field: Scalars['String']['output'];
  model: FacetModelEnum;
};

/** configurable facets defined on this widget */
export type FacetConfig = {
  __typename?: 'FacetConfig';
  /** filter facets */
  filterFacets?: Maybe<Array<FilterFacetUnion>>;
  /** search facet */
  searchFacet?: Maybe<SearchFacetType>;
  /** sort facet */
  sortFacet?: Maybe<SortFacetType>;
};

export enum FacetModelEnum {
  Content = 'CONTENT'
}

export enum FacetTypeEnum {
  MultiSelect = 'MULTI_SELECT',
  Search = 'SEARCH',
  SingleSelect = 'SINGLE_SELECT',
  Sort = 'SORT'
}

export type FacetValueInput = {
  id: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type FailedPageMetadata = {
  __typename?: 'FailedPageMetadata';
  chartId: Scalars['String']['output'];
  currentPartition?: Maybe<Scalars['Float']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  from: Scalars['Float']['output'];
  maxRecordLimitReached?: Maybe<Scalars['Boolean']['output']>;
  partitionBatchSize?: Maybe<Scalars['Float']['output']>;
  size: Scalars['Float']['output'];
  totalPartitions?: Maybe<Scalars['Float']['output']>;
};

/** Feature Flag Type */
export type FeatureFlagType = {
  __typename?: 'FeatureFlagType';
  name: Scalars['String']['output'];
  /** Type of FlagValue */
  type: FlagValueTypeEnum;
  value: Scalars['String']['output'];
};

/** FeatureFlagsInput */
export type FeatureFlagsInput = {
  names: Array<Scalars['String']['input']>;
};

/** FeatureFlagsPayload */
export type FeatureFlagsPayload = {
  __typename?: 'FeatureFlagsPayload';
  featureFlags: Array<FeatureFlagType>;
};

export type FeatureNudge = {
  __typename?: 'FeatureNudge';
  feature: FeatureNudgeEnum;
  nudge: Scalars['Boolean']['output'];
};

export enum FeatureNudgeEnum {
  Habit = 'HABIT',
  LearningContent = 'LEARNING_CONTENT',
  Meeting = 'MEETING',
  Okr = 'OKR',
  OneOnOne = 'ONE_ON_ONE',
  PeerFeedback = 'PEER_FEEDBACK',
  Praise = 'PRAISE',
  Session = 'SESSION',
  Task = 'TASK'
}

export type FeatureNudgeWidgetType = {
  __typename?: 'FeatureNudgeWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  featureNudgeFilters: Array<FeatureNudgeEnum>;
  /** feature nudges */
  featureNudges: Array<FeatureNudge>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type FeedbackMetadataInput = {
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type FeedbackMetadataType = {
  __typename?: 'FeedbackMetadataType';
  sessionId?: Maybe<Scalars['String']['output']>;
};

/** Response received on createFeedback/updateFeedback mutation */
export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  /** Feedback document */
  feedback?: Maybe<FeedbackType>;
};

/** Filters for Feedback Questions */
export type FeedbackQuestionFilter = {
  questionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  scope: FeedbackQuestionScopeEnum;
  /** Type of Feedback Question to fetch */
  types?: InputMaybe<Array<FeedbackQuestionTypeEnum>>;
};

export enum FeedbackQuestionScopeEnum {
  Global = 'GLOBAL',
  Workspace = 'WORKSPACE'
}

/** Feedback Question */
export type FeedbackQuestionType = {
  __typename?: 'FeedbackQuestionType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Description */
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Questions */
  questions: Array<QuestionType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Type of Question */
  type: FeedbackQuestionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum FeedbackQuestionTypeEnum {
  ArticleQuality = 'ARTICLE_QUALITY',
  AudioQuality = 'AUDIO_QUALITY',
  CourseQuality = 'COURSE_QUALITY',
  Custom = 'CUSTOM',
  ExerciseQuality = 'EXERCISE_QUALITY',
  GenerativeTaskQuality = 'GENERATIVE_TASK_QUALITY',
  LessonQuality = 'LESSON_QUALITY',
  PathQuality = 'PATH_QUALITY',
  QuizQuality = 'QUIZ_QUALITY',
  VideoQuality = 'VIDEO_QUALITY'
}

/** Feedback Response Input */
export type FeedbackResponseInput = {
  /** Id of corresponding feedback question */
  questionId: Scalars['String']['input'];
  /** Response to the question */
  response: Scalars['String']['input'];
};

/** Feedback Response Type */
export type FeedbackResponseType = {
  __typename?: 'FeedbackResponseType';
  /** Id of corresponding feedback question */
  questionId: Scalars['String']['output'];
  /** Response to the question */
  response: Scalars['String']['output'];
};

export enum FeedbackResponseTypeEnum {
  Sticker = 'STICKER',
  Text = 'TEXT'
}

/** Feedback */
export type FeedbackType = {
  __typename?: 'FeedbackType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Entity Id */
  entityId: Scalars['String']['output'];
  /** Type of Entity */
  entityType: EntityTypeEnum;
  id: Scalars['String']['output'];
  metadata?: Maybe<FeedbackMetadataType>;
  questionScope?: Maybe<FeedbackQuestionScopeEnum>;
  /** Response */
  responses: Array<FeedbackResponseType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User Id */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type FieldExistsFilter = {
  exists: Scalars['Boolean']['input'];
  field: Scalars['String']['input'];
};

export type FieldMappingInput = {
  /** if field is mandatory to map */
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** field name of lyearn schema */
  lyearnFieldName?: InputMaybe<Scalars['String']['input']>;
  /** if permission to modify the mapping */
  selectionAvailable: Scalars['Boolean']['input'];
  /** field name of source schema */
  sourceFieldName: Scalars['String']['input'];
  /** if source field is mapped with lyearn tag */
  tagId?: InputMaybe<Scalars['String']['input']>;
};

/** field mapping from source schema to lyearn schema */
export type FieldMappingType = {
  __typename?: 'FieldMappingType';
  /** if field is mandatory to map */
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  /** field name of lyearn schema */
  lyearnFieldName?: Maybe<Scalars['String']['output']>;
  /** if permission to modify the mapping */
  selectionAvailable: Scalars['Boolean']['output'];
  /** field name of source schema */
  sourceFieldName: Scalars['String']['output'];
  /** if source field is mapped with lyearn tag */
  tagId?: Maybe<Scalars['String']['output']>;
};

export type FileCategoriesFilter = {
  categoryIds: Array<Scalars['String']['input']>;
  /** if null undefined, it will be considered false */
  includeSubtree?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum FileCategoryEnum {
  File = 'FILE',
  Folder = 'FOLDER'
}

export enum FileContentTypeEnum {
  Okr = 'OKR',
  Task = 'TASK'
}

/** Edge */
export type FileEdge = {
  __typename?: 'FileEdge';
  node: FileSchema;
};

export type FileFilters = {
  fileId: Scalars['String']['input'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  file?: Maybe<FileSchema>;
};

export enum FilePurposeEnum {
  LibraryCategory = 'LIBRARY_CATEGORY',
  TaskCategory = 'TASK_CATEGORY'
}

export type FileSchema = {
  __typename?: 'FileSchema';
  /** files or folder */
  category: FileCategoryEnum;
  /** ordered list of children ids */
  childrenIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Count of content associated with content */
  contentCount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedById?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Filter query for system generated files */
  filterQuery?: Maybe<Scalars['String']['output']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  /** contains type of file */
  includes?: Maybe<Array<FileContentTypeEnum>>;
  jiraProjectStatusesMap?: Maybe<Array<JiraIntegrationProjectStatusesConfig>>;
  jiraTaskProjectStatusesMap?: Maybe<Array<EntityJiraProjectStatuesConfig>>;
  meta?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Location under which folders/file will be searched */
  path?: Maybe<Scalars['String']['output']>;
  /** contains usage of file as what category, if Category it defines that it is used as category file. */
  purpose?: Maybe<FilePurposeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated?: Maybe<Scalars['Boolean']['output']>;
  taskConfig?: Maybe<TaskConfigSchema>;
  /** Count of task inside file */
  taskCount?: Maybe<Scalars['Float']['output']>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  taskStatuses?: Maybe<Array<StatusType>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  visibilitySettings: VisibilitySettingsType;
};

export type FilesFilters = {
  /** fetches all files which have these ids as children */
  childrenIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  generatedByFilterType?: InputMaybe<GeneratedByEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** fetches all files which are children of given parentIds */
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** contains usage of file as what category, if Category it defines that it is used as category file. */
  purpose?: InputMaybe<Array<FilePurposeEnum>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  systemGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  taskCreationConfigExists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FilterConfigType = {
  __typename?: 'FilterConfigType';
  entity?: Maybe<Scalars['String']['output']>;
  filter: Scalars['String']['output'];
  replaceWithFilterProperty: Scalars['String']['output'];
};

export type FilterD1Input = {
  filters?: InputMaybe<Array<FilterD2Input>>;
  operator: FilterOperatorEnum;
  property?: InputMaybe<FilterPropertyInput>;
  typeCast?: InputMaybe<TypeCastEnum>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Defines filtering criteria for a particular field  */
export type FilterD1InputType = {
  /** filters sub group */
  filters?: InputMaybe<Array<FilterD2InputType>>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property?: InputMaybe<FilterPropertyInputType>;
  /** Datatype of the "value" field */
  typeCast?: InputMaybe<TypeCastEnum>;
  /** Value to be filtered with */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Defines filtering criteria for a particular field  */
export type FilterD1Type = {
  __typename?: 'FilterD1Type';
  /** filters sub group */
  filters: Array<FilterD2Type>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property?: Maybe<FilterPropertyType>;
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value?: Maybe<Scalars['String']['output']>;
};

export type FilterD2Input = {
  operator: FilterOperatorEnum;
  property: FilterPropertyInput;
  typeCast?: InputMaybe<TypeCastEnum>;
  value: Scalars['String']['input'];
};

/** Defines filtering over entity */
export type FilterD2InputType = {
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property: FilterPropertyInputType;
  /** Datatype of the "value" field */
  typeCast?: InputMaybe<TypeCastEnum>;
  /** Value to be filtered with */
  value: Scalars['String']['input'];
};

/** Defines filtering over entity */
export type FilterD2Type = {
  __typename?: 'FilterD2Type';
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property: FilterPropertyType;
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value: Scalars['String']['output'];
};

/** union of all filter facets */
export type FilterFacetUnion = MultiSelectFacetType | SingleSelectFacetType;

/** Defines complex  view filter */
export type FilterGroupInput = {
  filters: Array<FiltersInput>;
  operator: FilterOperatorEnum;
};

/** Defines filtering over entity */
export type FilterGroupType = {
  __typename?: 'FilterGroupType';
  /** Array of simple filter queries */
  filters: Array<FilterType>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};


/** Defines filtering over entity */
export type FilterGroupTypeFiltersArgs = {
  input?: InputMaybe<FilterInputType>;
};

export type FilterGroupV2Input = {
  filters?: InputMaybe<Array<FilterD1Input>>;
  operator: FilterOperatorEnum;
};

/** Defines filtering over entity */
export type FilterGroupV2InputType = {
  /** Array of simple filter queries */
  filters: Array<FilterD1InputType>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};

/** Defines filtering over entity */
export type FilterGroupV2Type = {
  __typename?: 'FilterGroupV2Type';
  /** Array of simple filter queries */
  filters: Array<FilterD1Type>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};

export type FilterInputType = {
  /** Will replace given meetingId in place of the string `meetingId` */
  meetingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Will replace given metricIds in place of the string `metricIds` */
  metricIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Will replace given reviewCycleIds in place of the string `reviewCycleIds` */
  reviewCycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Will replace given userIds in place of the string `userIds` */
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum FilterOperatorEnum {
  And = 'AND',
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  ContainsNot = 'CONTAINS_NOT',
  DateRange = 'DATE_RANGE',
  ElemMatch = 'ELEM_MATCH',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  In = 'IN',
  Is = 'IS',
  IsEmpty = 'IS_EMPTY',
  IsNot = 'IS_NOT',
  IsNotEmpty = 'IS_NOT_EMPTY',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  MatchAll = 'MATCH_ALL',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN',
  Or = 'OR',
  Range = 'RANGE',
  Regex = 'REGEX',
  StartsWith = 'STARTS_WITH'
}

export type FilterPropertyInput = {
  meta?: InputMaybe<Scalars['String']['input']>;
  type: FilterPropertyTypeEnum;
  value: Scalars['String']['input'];
};

export type FilterPropertyInputType = {
  /** property meta field */
  meta?: InputMaybe<Scalars['String']['input']>;
  /** type of property */
  type: FilterPropertyTypeEnum;
  /** property identifier */
  value: Scalars['String']['input'];
};

export type FilterPropertyType = {
  __typename?: 'FilterPropertyType';
  content?: Maybe<ContentEntity>;
  /** property meta field */
  meta?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<TagUnion>;
  /** type of property */
  type: FilterPropertyTypeEnum;
  /** property identifier */
  value: Scalars['String']['output'];
};

export enum FilterPropertyTypeEnum {
  Activity = 'ACTIVITY',
  Content = 'CONTENT',
  Field = 'FIELD',
  Page = 'PAGE',
  Question = 'QUESTION',
  Tag = 'TAG'
}

/** Defines filtering criteria for a particular field  */
export type FilterType = {
  __typename?: 'FilterType';
  /** filters sub group */
  filters: Array<SubFiltersType>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** Field Id */
  property?: Maybe<Scalars['String']['output']>;
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value?: Maybe<Scalars['String']['output']>;
  /** Value to be parsed and replaced with actual value */
  valueToParse?: Maybe<Scalars['String']['output']>;
};

/** Defines view filter for a particular field */
export type FiltersInput = {
  filters?: InputMaybe<Array<SubFiltersInput>>;
  operator: FilterOperatorEnum;
  property?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FlagAtLevelEnum {
  Custom = 'CUSTOM',
  Department = 'DEPARTMENT',
  Manager = 'MANAGER',
  Organisation = 'ORGANISATION',
  User = 'USER'
}

export enum FlagOnEntityEnum {
  Mindset = 'MINDSET',
  Question = 'QUESTION',
  Skill = 'SKILL',
  SkillGroup = 'SKILL_GROUP',
  Theme = 'THEME'
}

/** Edge */
export type FlagSurveyResponseEdge = {
  __typename?: 'FlagSurveyResponseEdge';
  node: FlagSurveyResponseType;
};

export enum FlagSurveyResponseStatusEnum {
  Active = 'ACTIVE',
  Archive = 'ARCHIVE'
}

export type FlagSurveyResponseType = {
  __typename?: 'FlagSurveyResponseType';
  actionPlan?: Maybe<ActionPlanType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  cycleId?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  filterGroup?: Maybe<FilterGroupType>;
  flagAtLevel: FlagAtLevelEnum;
  flagOn: FlagOnEntityEnum;
  flaggedMindset?: Maybe<SkillType>;
  flaggedQuestion?: Maybe<QuestionType>;
  flaggedSkill?: Maybe<SkillType>;
  flaggedSkillGroup?: Maybe<SkillGroupType>;
  flaggedTheme?: Maybe<ThemeType>;
  id: Scalars['String']['output'];
  parentEntities?: Maybe<Array<Scalars['String']['output']>>;
  performanceReviewMetadata?: Maybe<Array<FlaggedPerformanceReviewMetadataType>>;
  sanitizedFilter?: Maybe<Scalars['String']['output']>;
  status: FlagSurveyResponseStatusEnum;
  surveyId: Scalars['String']['output'];
  surveyResponseMetadata?: Maybe<Array<FlaggedSurveyResponseMetadataType>>;
  type: FlagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type FlagSurveyResponsesFilter = {
  cycleExists?: InputMaybe<Scalars['Boolean']['input']>;
  cycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  flagAtLevels?: InputMaybe<Array<FlagAtLevelEnum>>;
  flagOns?: InputMaybe<Array<FlagOnEntityEnum>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  managerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEntities?: InputMaybe<Array<Scalars['String']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sanitizedFilter?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<FlagSurveyResponseStatusEnum>>;
  surveyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<FlagTypeEnum>>;
};

export enum FlagTypeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export enum FlagValueTypeEnum {
  Bool = 'BOOL',
  Number = 'NUMBER',
  Object = 'OBJECT',
  String = 'STRING'
}

export type FlaggedPerformanceReviewMetadataInput = {
  cycleId?: InputMaybe<Scalars['String']['input']>;
  scoreConfig?: InputMaybe<FlaggedPerformanceReviewScoreConfigInput>;
};

export type FlaggedPerformanceReviewMetadataType = {
  __typename?: 'FlaggedPerformanceReviewMetadataType';
  cycleId?: Maybe<Scalars['String']['output']>;
  scoreConfig?: Maybe<FlaggedPerformanceReviewScoreConfig>;
};

export type FlaggedPerformanceReviewScoreConfig = {
  __typename?: 'FlaggedPerformanceReviewScoreConfig';
  score: Scalars['Float']['output'];
};

export type FlaggedPerformanceReviewScoreConfigInput = {
  score: Scalars['Float']['input'];
};

export type FlaggedSurveyResponseMcqConfig = {
  __typename?: 'FlaggedSurveyResponseMCQConfig';
  optionText: Scalars['String']['output'];
  responseCount?: Maybe<Scalars['Int']['output']>;
};

export type FlaggedSurveyResponseMcqConfigInput = {
  optionText: Scalars['String']['input'];
  responseCount?: InputMaybe<Scalars['Int']['input']>;
};

export type FlaggedSurveyResponseMetadataInput = {
  cycleId?: InputMaybe<Scalars['Int']['input']>;
  mcqConfig?: InputMaybe<Array<FlaggedSurveyResponseMcqConfigInput>>;
  participationCount?: InputMaybe<Scalars['Int']['input']>;
  responseCount?: InputMaybe<Scalars['Int']['input']>;
  scoreConfig?: InputMaybe<FlaggedSurveyResponseScoreConfigInput>;
};

export type FlaggedSurveyResponseMetadataType = {
  __typename?: 'FlaggedSurveyResponseMetadataType';
  cycleId?: Maybe<Scalars['Int']['output']>;
  mcqConfig?: Maybe<Array<FlaggedSurveyResponseMcqConfig>>;
  participationCount?: Maybe<Scalars['Int']['output']>;
  responseCount?: Maybe<Scalars['Int']['output']>;
  scoreConfig?: Maybe<FlaggedSurveyResponseScoreConfig>;
};

export type FlaggedSurveyResponseScoreConfig = {
  __typename?: 'FlaggedSurveyResponseScoreConfig';
  detractorsCount: Scalars['Int']['output'];
  passiveCount: Scalars['Int']['output'];
  promotersCount: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
};

export type FlaggedSurveyResponseScoreConfigInput = {
  detractorsCount: Scalars['Int']['input'];
  passiveCount: Scalars['Int']['input'];
  promotersCount: Scalars['Int']['input'];
  score: Scalars['Float']['input'];
};

export enum FocusAreaEnum {
  Activity = 'ACTIVITY',
  FlAggedQuestion = 'FlAGGED_QUESTION',
  Mindset = 'MINDSET',
  Skill = 'SKILL'
}

/** This is the input for force completing content */
export type ForceCompleteContentInput = {
  entityIds: Array<Scalars['String']['input']>;
  entityType?: InputMaybe<PerformanceEntityTypeEnum>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** ForceCompleteContentProgressInput! */
export type ForceCompleteContentProgressInput = {
  entityIds: Array<Scalars['String']['input']>;
  entityType?: InputMaybe<PerformanceEntityTypeEnum>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  usersFilters?: InputMaybe<PerformanceUsersFilters>;
};

/** Force complete content payload */
export type ForceCompleteContentProgressPayload = {
  __typename?: 'ForceCompleteContentProgressPayload';
  jobScheduleId: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ForceCompleteContentType = {
  __typename?: 'ForceCompleteContentType';
  entityIds: Array<Scalars['String']['output']>;
  entityType?: Maybe<PerformanceEntityTypeEnum>;
  parentEntityId: Scalars['String']['output'];
  userIds: Array<Scalars['String']['output']>;
};

export enum FormComponentFieldTypeEnum {
  Email = 'EMAIL',
  Multiline = 'MULTILINE',
  Password = 'PASSWORD',
  Text = 'TEXT',
  Url = 'URL'
}

export type FormComponentType = {
  __typename?: 'FormComponentType';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  type: FormComponentFieldTypeEnum;
};

/** Resource Media Formats */
export type FormattedResourceMediaType = {
  __typename?: 'FormattedResourceMediaType';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** General Setting Parameters */
export type GeneralSettingsType = {
  __typename?: 'GeneralSettingsType';
  /** Product categories */
  category: Array<Scalars['String']['output']>;
  /** Product has certificate rewarded on completion true or false */
  certificateOnCompletion: Scalars['Boolean']['output'];
  /** Product is best seller true or false */
  isBestSeller: Scalars['Boolean']['output'];
  /** Product Label */
  label?: Maybe<Scalars['String']['output']>;
};

export type GenerateArticleFromTopicInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  topicName: Scalars['String']['input'];
};

export type GenerateArticleFromTopicInputPropsType = {
  __typename?: 'GenerateArticleFromTopicInputPropsType';
  context?: Maybe<Scalars['String']['output']>;
  topicName: Scalars['String']['output'];
};

export type GenerateChildKRsFromOkrInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type GenerateChildKRsFromOkrInputPropsType = {
  __typename?: 'GenerateChildKRsFromOKRInputPropsType';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GenerateChildObjectivesFromOkrInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type GenerateChildObjectivesFromOkrInputPropsType = {
  __typename?: 'GenerateChildObjectivesFromOKRInputPropsType';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GenerateCourseLessonFromTopicInput = {
  lessonDescription?: InputMaybe<Scalars['String']['input']>;
  lessonName?: InputMaybe<Scalars['String']['input']>;
  topicName: Scalars['String']['input'];
};

export type GenerateCourseLessonFromTopicInputPropsType = {
  __typename?: 'GenerateCourseLessonFromTopicInputPropsType';
  lessonDescription?: Maybe<Scalars['String']['output']>;
  lessonName?: Maybe<Scalars['String']['output']>;
  topicName: Scalars['String']['output'];
};

export type GenerateCourseOutlineFromTopicInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  topicName: Scalars['String']['input'];
};

export type GenerateCourseOutlineFromTopicInputPropsType = {
  __typename?: 'GenerateCourseOutlineFromTopicInputPropsType';
  context?: Maybe<Scalars['String']['output']>;
  topicName: Scalars['String']['output'];
};

export type GenerateLearnerLinkInput = {
  action: GenerateLinkActionEnum;
  userId: Scalars['String']['input'];
};

export type GenerateLearnerLinkPayload = {
  __typename?: 'GenerateLearnerLinkPayload';
  link: Scalars['String']['output'];
};

export enum GenerateLinkActionEnum {
  ForgotPassword = 'FORGOT_PASSWORD'
}

export type GenerateMeetingInstanceSummaryInputPropsType = {
  __typename?: 'GenerateMeetingInstanceSummaryInputPropsType';
  agendaItems?: Maybe<Array<SummaryAgendaItemsInput>>;
};

export type GenerateMindsetNameAndDescriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  trackName: Scalars['String']['input'];
};

export type GenerateMindsetNameAndDescriptionInputPropsType = {
  __typename?: 'GenerateMindsetNameAndDescriptionInputPropsType';
  description?: Maybe<Scalars['String']['output']>;
  trackName: Scalars['String']['output'];
};

export type GenerateMindsetRubricsInput = {
  mindsetDescription: Scalars['String']['input'];
  mindsetName: Scalars['String']['input'];
  trackName: Scalars['String']['input'];
};

export type GenerateMindsetRubricsInputPropsType = {
  __typename?: 'GenerateMindsetRubricsInputPropsType';
  mindsetDescription: Scalars['String']['output'];
  mindsetName: Scalars['String']['output'];
  trackName: Scalars['String']['output'];
};

export type GenerateOkrTemplatesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type GenerateOkrTemplatesInputPropsType = {
  __typename?: 'GenerateOKRTemplatesInputPropsType';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GenerateOptionsFromQuizQuestionInput = {
  correctOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  incorrectOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  question: Scalars['String']['input'];
};

export type GenerateOptionsFromQuizQuestionInputPropsType = {
  __typename?: 'GenerateOptionsFromQuizQuestionInputPropsType';
  correctOptions?: Maybe<Array<Scalars['String']['output']>>;
  incorrectOptions?: Maybe<Array<Scalars['String']['output']>>;
  question: Scalars['String']['output'];
};

export type GenerateQuizFromTopicInput = {
  difficultyLevel?: InputMaybe<DifficultyEnum>;
  numberOfQuestions?: InputMaybe<QuizNumberOfQuestionsEnum>;
  snippet?: InputMaybe<Scalars['String']['input']>;
  topicName: Scalars['String']['input'];
};

export type GenerateQuizFromTopicInputPropsType = {
  __typename?: 'GenerateQuizFromTopicInputPropsType';
  difficultyLevel?: Maybe<DifficultyEnum>;
  numberOfQuestions?: Maybe<QuizNumberOfQuestionsEnum>;
  snippet?: Maybe<Scalars['String']['output']>;
  topicName: Scalars['String']['output'];
};

export type GenerateReportingQueriesInput = {
  text: Scalars['String']['input'];
};

export type GenerateReportingQueriesInputPropsType = {
  __typename?: 'GenerateReportingQueriesInputPropsType';
  text: Scalars['String']['output'];
};

export type GenerateSkillNameAndDescriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  skillGroup: Scalars['String']['input'];
  trackName: Scalars['String']['input'];
};

export type GenerateSkillNameAndDescriptionInputPropsType = {
  __typename?: 'GenerateSkillNameAndDescriptionInputPropsType';
  description?: Maybe<Scalars['String']['output']>;
  skillGroup: Scalars['String']['output'];
  trackName: Scalars['String']['output'];
};

export type GenerateSkillRubricsInput = {
  skillDescription: Scalars['String']['input'];
  skillGroup: Scalars['String']['input'];
  skillName: Scalars['String']['input'];
  trackName: Scalars['String']['input'];
};

export type GenerateSkillRubricsInputPropsType = {
  __typename?: 'GenerateSkillRubricsInputPropsType';
  skillDescription: Scalars['String']['output'];
  skillGroup: Scalars['String']['output'];
  skillName: Scalars['String']['output'];
  trackName: Scalars['String']['output'];
};

export type GenerateSummaryAgendaItemsInput = {
  actionItems?: InputMaybe<Array<AgendaActionItemsInput>>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  okrs?: InputMaybe<Array<AgendaOkRsInput>>;
  title: Scalars['String']['input'];
};

export type GenerateTasksFromKrInput = {
  text: Scalars['String']['input'];
};

export type GenerateTasksFromKrInputPropsType = {
  __typename?: 'GenerateTasksFromKRInputPropsType';
  text: Scalars['String']['output'];
};

export type GenerateTasksFromPeerFeedbackInput = {
  text: Scalars['String']['input'];
};

export type GenerateTasksFromPeerFeedbackInputPropsType = {
  __typename?: 'GenerateTasksFromPeerFeedbackInputPropsType';
  text: Scalars['String']['output'];
};

export enum GeneratedByEnum {
  All = 'ALL',
  SystemGenerated = 'SYSTEM_GENERATED',
  UserGenerated = 'USER_GENERATED'
}

export enum GenerativeTaskErrorCodeEnum {
  InternalError = 'INTERNAL_ERROR',
  TokenLimitExceeded = 'TOKEN_LIMIT_EXCEEDED'
}

export type GenerativeTaskErrorType = {
  __typename?: 'GenerativeTaskErrorType';
  errorCode: GenerativeTaskErrorCodeEnum;
  message?: Maybe<Scalars['String']['output']>;
};

export type GenerativeTaskFilters = {
  generativeTaskId: Scalars['String']['input'];
};

export type GenerativeTaskInputPropsType = {
  __typename?: 'GenerativeTaskInputPropsType';
  articleFromTopic?: Maybe<GenerateArticleFromTopicInputPropsType>;
  childKRsFromOKR?: Maybe<GenerateChildKRsFromOkrInputPropsType>;
  childObjectivesFromOKR?: Maybe<GenerateChildObjectivesFromOkrInputPropsType>;
  courseLessonFromTopic?: Maybe<GenerateCourseLessonFromTopicInputPropsType>;
  courseOutlineFromTopic?: Maybe<GenerateCourseOutlineFromTopicInputPropsType>;
  mindsetNameAndDescription?: Maybe<GenerateMindsetNameAndDescriptionInputPropsType>;
  mindsetRubricsFromMindsetNameAndDescription?: Maybe<GenerateMindsetRubricsInputPropsType>;
  okrTemplates?: Maybe<GenerateOkrTemplatesInputPropsType>;
  optionsFromQuizQuestion?: Maybe<GenerateOptionsFromQuizQuestionInputPropsType>;
  quizFromTopic?: Maybe<GenerateQuizFromTopicInputPropsType>;
  reportingQueries?: Maybe<GenerateReportingQueriesInputPropsType>;
  skillNameAndDescription?: Maybe<GenerateSkillNameAndDescriptionInputPropsType>;
  skillRubricsFromSkillNameAndDescription?: Maybe<GenerateSkillRubricsInputPropsType>;
  summaryFromMeetingInstance?: Maybe<GenerateMeetingInstanceSummaryInputPropsType>;
  tasksFromKR?: Maybe<GenerateTasksFromKrInputPropsType>;
  tasksFromPeerFeedback?: Maybe<GenerateTasksFromPeerFeedbackInputPropsType>;
  transformText?: Maybe<TransformTextInputPropsType>;
};

export type GenerativeTaskPayload = {
  __typename?: 'GenerativeTaskPayload';
  generativeTask?: Maybe<GenerativeTaskType>;
};

export type GenerativeTaskPropsInput = {
  articleFromTopic?: InputMaybe<GenerateArticleFromTopicInput>;
  childKRsFromOKR?: InputMaybe<GenerateChildKRsFromOkrInput>;
  childObjectivesFromOKR?: InputMaybe<GenerateChildObjectivesFromOkrInput>;
  courseLessonFromTopic?: InputMaybe<GenerateCourseLessonFromTopicInput>;
  courseOutlineFromTopic?: InputMaybe<GenerateCourseOutlineFromTopicInput>;
  mindsetNameAndDescription?: InputMaybe<GenerateMindsetNameAndDescriptionInput>;
  mindsetRubricsFromMindsetNameAndDescription?: InputMaybe<GenerateMindsetRubricsInput>;
  okrTemplates?: InputMaybe<GenerateOkrTemplatesInput>;
  optionsFromQuizQuestion?: InputMaybe<GenerateOptionsFromQuizQuestionInput>;
  quizFromTopic?: InputMaybe<GenerateQuizFromTopicInput>;
  reportingQueries?: InputMaybe<GenerateReportingQueriesInput>;
  skillNameAndDescription?: InputMaybe<GenerateSkillNameAndDescriptionInput>;
  skillRubricsFromSkillNameAndDescription?: InputMaybe<GenerateSkillRubricsInput>;
  summaryFromMeetingInstance?: InputMaybe<MeetingInstanceSummaryInput>;
  tasksFromKR?: InputMaybe<GenerateTasksFromKrInput>;
  tasksFromPeerFeedback?: InputMaybe<GenerateTasksFromPeerFeedbackInput>;
  transformText?: InputMaybe<TransformTextInput>;
};

export type GenerativeTaskResultType = {
  __typename?: 'GenerativeTaskResultType';
  articleFromTopic?: Maybe<ArticleResultType>;
  childKRsFromOKR?: Maybe<ChildKRsFromOkrResultType>;
  childObjectivesFromOKR?: Maybe<ChildObjectivesFromOkrResultType>;
  courseLessonFromTopic?: Maybe<CourseLessonResultType>;
  courseOutlineFromTopic?: Maybe<CourseOutlineResultType>;
  mindsetNameAndDescription?: Maybe<SkillResultType>;
  mindsetRubricsFromMindsetNameAndDescription?: Maybe<SkillRubricsResultType>;
  okrTemplates?: Maybe<OkrTemplatesResultType>;
  optionsFromQuizQuestion?: Maybe<QuizQuestionOptionsResultType>;
  quizFromTopic?: Maybe<QuizResultType>;
  reportingQueries?: Maybe<ReportingQueriesResultType>;
  skillNameAndDescription?: Maybe<SkillResultType>;
  skillRubricsFromSkillNameAndDescription?: Maybe<SkillRubricsResultType>;
  summaryFromMeetingInstance?: Maybe<MeetingInstanceSummaryResultType>;
  tasksFromKR?: Maybe<TasksFromKrResultType>;
  tasksFromPeerFeedback?: Maybe<TasksFromPeerFeedbackResultType>;
  transformText?: Maybe<TransformTextResultType>;
};

export enum GenerativeTaskStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  ToBeProcessed = 'TO_BE_PROCESSED'
}

export type GenerativeTaskSubscriptionInput = {
  /** generativeTaskId to subscribe on */
  generativeTaskId: Scalars['String']['input'];
};

export enum GenerativeTaskSubscriptionOperationEnum {
  GenerativeTaskUpdated = 'GENERATIVE_TASK_UPDATED'
}

export type GenerativeTaskSubscriptionPayload = {
  __typename?: 'GenerativeTaskSubscriptionPayload';
  generativeTask?: Maybe<GenerativeTaskType>;
  generativeTaskId: Scalars['String']['output'];
  generativeTaskStatus: GenerativeTaskStatusEnum;
  operation: GenerativeTaskSubscriptionOperationEnum;
};

export type GenerativeTaskType = {
  __typename?: 'GenerativeTaskType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  error?: Maybe<GenerativeTaskErrorType>;
  generativeTaskContextId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  input: GenerativeTaskInputPropsType;
  result: GenerativeTaskResultType;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: GenerativeTaskStatusEnum;
  type: GenerativeTaskTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum GenerativeTaskTypeEnum {
  ArticleFromTopic = 'ARTICLE_FROM_TOPIC',
  ChildKrsFromOkr = 'CHILD_KRS_FROM_OKR',
  ChildObjectivesFromOkr = 'CHILD_OBJECTIVES_FROM_OKR',
  CourseLessonFromTopic = 'COURSE_LESSON_FROM_TOPIC',
  CourseOutlineFromTopic = 'COURSE_OUTLINE_FROM_TOPIC',
  MindsetNameAndDescription = 'MINDSET_NAME_AND_DESCRIPTION',
  MindsetRubricsFromMindsetNameAndDescription = 'MINDSET_RUBRICS_FROM_MINDSET_NAME_AND_DESCRIPTION',
  OkrTemplates = 'OKR_TEMPLATES',
  OptionsFromQuizQuestion = 'OPTIONS_FROM_QUIZ_QUESTION',
  QuizFromTopic = 'QUIZ_FROM_TOPIC',
  ReportingQueries = 'REPORTING_QUERIES',
  SkillNameAndDescription = 'SKILL_NAME_AND_DESCRIPTION',
  SkillRubricsFromSkillNameAndDescription = 'SKILL_RUBRICS_FROM_SKILL_NAME_AND_DESCRIPTION',
  SummaryFromMeetingInstance = 'SUMMARY_FROM_MEETING_INSTANCE',
  TasksFromKr = 'TASKS_FROM_KR',
  TasksFromPeerFeedback = 'TASKS_FROM_PEER_FEEDBACK',
  TransformText = 'TRANSFORM_TEXT'
}

export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** address line 1 */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /**
   * custom label for longitude, latitude
   * @deprecated use addressLine 1
   */
  label?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type GeoLocationInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** custom label for longitude, latitude */
  label?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type GetByIdFilter = {
  id: Scalars['String']['input'];
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetChildOkRsFilters = {
  fetchAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** If no type given, then will fetch both Objective and key result which are direct children of given OKR */
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};

export type GetConvertedTargetActivityMetricValue = {
  requiredFrequency: RelativeDateFilterUnitEnum;
};

export type GetEmbeddedEntitiesInfoInput = {
  entities: Array<GetEmbeddedEntityInfo>;
};

export type GetEmbeddedEntityInfo = {
  entityId: Scalars['String']['input'];
  type: ParentEntityTypeEnum;
};

export type GetEntitiesAttachedToCountPayload = {
  __typename?: 'GetEntitiesAttachedToCountPayload';
  contentsCount: Scalars['Float']['output'];
  /** Count of contents that are attached to the microskills of current skill */
  microSkillsContentsCount: Scalars['Float']['output'];
  skillMatricesCount: Scalars['Float']['output'];
};

export type GetImportJobSampleCsvRowsInput = {
  purpose: JobPurposeEnum;
};

export type GetJourneyStepTraineeSessionInput = {
  sessionId: Scalars['String']['input'];
};

export type GetOkrFilters = {
  /** Returns OKRs which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  okrId?: InputMaybe<Scalars['String']['input']>;
};

export type GetOkRsFilters = {
  /** Returns OKRs whose ancestor is ancestorId in parent hierarchy */
  ancestorId?: InputMaybe<Scalars['String']['input']>;
  cycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns OKRs which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  dueDate?: InputMaybe<DateFilter>;
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  /** Returns OKRs with selected integration config */
  integrationConfig?: InputMaybe<OkrIntegrationConfigInput>;
  /** Get Team OKRs based on managers */
  managerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Returns OKRs which is not parent okr and does not have parent hierarchy */
  notInParentIdHierarchy?: InputMaybe<Scalars['String']['input']>;
  /** Get okrs based on objective type */
  objectiveTypes?: InputMaybe<Array<ObjectiveTypeEnum>>;
  okrIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ownerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns OKRs with selected progress measurement type */
  progressMeasurementTypes?: InputMaybe<Array<OkrProgressMeasurementTypeEnum>>;
  reporteeFilterType?: InputMaybe<ReporteeFilterTypeInput>;
  /** Returns Root/Top level OKRs */
  rootOKRs?: InputMaybe<Scalars['Boolean']['input']>;
  showKeyResults?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<OkrStatusEnum>>;
  /** Get okrs based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  /** Get okrs based on tag Ids */
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns OKRs with selected task creation config */
  taskCreationConfig?: InputMaybe<OkrTaskCreationConfigFilter>;
  /** Returns OKRs with if task creation config */
  taskCreationConfigExists?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Array<OkrTypeEnum>>;
  viewType?: InputMaybe<OkrViewTypeEnum>;
};

export type GetParentContentsInput = {
  id: Scalars['String']['input'];
};

export type GetParentContentsPayload = {
  __typename?: 'GetParentContentsPayload';
  contents: Array<ContentEntity>;
  totalCount: Scalars['Int']['output'];
};

export type GetProviderFilters = {
  availableOn?: InputMaybe<ProviderAvailableOnEnum>;
  id?: InputMaybe<Scalars['String']['input']>;
  integrationProviderId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};

export type GetProvidersFilters = {
  availableOn?: InputMaybe<Array<ProviderAvailableOnEnum>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  integrationProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  uniqueIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetRecommendedContentIdsForSkillIdsInput = {
  skillIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type GetResourceByIdFilter = {
  id: Scalars['String']['input'];
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

export type GetResourcesFilters = {
  /** Associate with classroom */
  classroomId?: InputMaybe<Scalars['String']['input']>;
  /** Array of two dates between which the resource was created, [startTimeStamp, endTimeStamp] */
  createdAtTimeStampRange?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tells whether it is a file or folder */
  kind?: InputMaybe<ResourceKindEnum>;
  /** name of the file/folder */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Associate with classroom */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** Location under which folders/file will be searched */
  path?: InputMaybe<Scalars['String']['input']>;
  /** Resource Types. Filter resource based on category */
  resourceCategories?: InputMaybe<Array<ResourceCategoryEnum>>;
  /** Filter resource based on resource ids */
  resourceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Resource Status. Filter resources based on resource status */
  status?: InputMaybe<Array<ResourceStatusEnum>>;
};

export type GetUrlPreviewsInput = {
  /** Urls for which one needs previews */
  urls: Array<Scalars['String']['input']>;
};

export type GetUrlPreviewsPayload = {
  __typename?: 'GetUrlPreviewsPayload';
  previews: Array<Scalars['JSONObject']['output']>;
};

export type GlobalSearchAndLibraryConfigType = {
  __typename?: 'GlobalSearchAndLibraryConfigType';
  globalSearch?: Maybe<GlobalSearchConfigType>;
  library?: Maybe<LibraryConfigType>;
};

export enum GlobalSearchAndLibraryFilterTypeEnum {
  Skill = 'SKILL'
}

export type GlobalSearchConfigType = {
  __typename?: 'GlobalSearchConfigType';
  allowedContentTypes?: Maybe<Array<SearchableContentTypeEnum>>;
};

export type GlobalSearchFilter = {
  contentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypes?: InputMaybe<Array<SearchableContentTypeEnum>>;
  fileCategoriesFilter?: InputMaybe<FileCategoriesFilter>;
  /** get static or dynamic contents for given groupIds */
  groupFilter?: InputMaybe<EntityGroupFilter>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sortFilters?: InputMaybe<GlobalSearchSortFilters>;
  status?: InputMaybe<Scalars['String']['input']>;
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  widgetTypes?: InputMaybe<Array<SearchWidgetTypeEnum>>;
};

/** Response for Global Search Query */
export type GlobalSearchPayload = {
  __typename?: 'GlobalSearchPayload';
  contents?: Maybe<ContentConnection>;
  events?: Maybe<PaginatedEventsPayload>;
  lessons?: Maybe<BlockSearchConnections>;
};


/** Response for Global Search Query */
export type GlobalSearchPayloadContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


/** Response for Global Search Query */
export type GlobalSearchPayloadEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


/** Response for Global Search Query */
export type GlobalSearchPayloadLessonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type GlobalSearchSortFilters = {
  accessCountTimeRange?: InputMaybe<DateFilter>;
};

export type GlobalSearchWidgetType = {
  __typename?: 'GlobalSearchWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  contents: ContentConnection;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type GlobalSearchWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GlobalSearchFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type GoogleIntegrationSettingsInput = {
  archiveTerminatedUsers: Scalars['Boolean']['input'];
  fieldMappings: Array<FieldMappingInput>;
  lastSyncedAt?: InputMaybe<Scalars['String']['input']>;
  orgUnitPaths: Array<Scalars['String']['input']>;
  syncJobId?: InputMaybe<Scalars['String']['input']>;
  syncOrgHierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  syncSchedule?: InputMaybe<ScheduleInfoInput>;
  tagMappings?: InputMaybe<Scalars['JSONObject']['input']>;
  userGroupIds: Array<Scalars['String']['input']>;
  usersImportType: UsersImportTypeEnum;
};

export type GoogleIntegrationSettingsType = {
  __typename?: 'GoogleIntegrationSettingsType';
  archiveTerminatedUsers: Scalars['Boolean']['output'];
  fieldMappings: Array<FieldMappingType>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  orgUnitPaths: Array<Scalars['String']['output']>;
  scheduledJob?: Maybe<ScheduledJobConfigs>;
  syncJobId?: Maybe<Scalars['String']['output']>;
  syncOrgHierarchy?: Maybe<Scalars['Boolean']['output']>;
  syncSchedule?: Maybe<ScheduleInfo>;
  /** sync users job status */
  syncStatus?: Maybe<JobEntityStatusEnum>;
  tagMappings?: Maybe<Scalars['JSONObject']['output']>;
  /** User selected group ids. Might contain stale ids. To get the latest count, use integrationGroupsCount field resolver */
  userGroupIds: Array<Scalars['String']['output']>;
  usersImportType: UsersImportTypeEnum;
};

/** GradeBook settings type */
export type GradeBookSettingType = {
  __typename?: 'GradeBookSettingType';
  calculationMechanism: CalculationMechanismEnum;
  gradeType?: Maybe<ClassroomSettingsGradeTypeEnum>;
  /** Grading schema type */
  gradingSchema?: Maybe<GradingSchemaType>;
};

export enum GradeSchemaTemplateEnum {
  Alphanumeric = 'ALPHANUMERIC',
  Custom = 'CUSTOM',
  FiveLetter = 'FIVE_LETTER',
  PlusMinus = 'PLUS_MINUS'
}

export enum GradedByEnum {
  Peer = 'PEER',
  Teacher = 'TEACHER'
}

/** Grading Schema Type */
export type GradingSchemaType = {
  __typename?: 'GradingSchemaType';
  /** Custom grading schema type */
  customSchema?: Maybe<Array<CustomLetterGradingSchemaType>>;
  templateType: GradeSchemaTemplateEnum;
};

export enum GroupAdditionMethodEnum {
  All = 'ALL',
  Dynamic = 'DYNAMIC',
  Manual = 'MANUAL'
}

export enum GroupConditionOperator {
  And = 'AND',
  Or = 'OR'
}

/** This returns paginated Groups */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  edges?: Maybe<Array<GroupEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  node: GroupUnion;
};

export enum GroupMemberExportFieldEnum {
  AutoSync = 'AUTO_SYNC',
  Emailid = 'EMAILID',
  GroupName = 'GROUP_NAME',
  UniqueId = 'UNIQUE_ID'
}

export type GroupMemberExportInputProps = {
  groupId: Scalars['String']['input'];
  requiredFields: GroupMemberExportRequiredFieldsInput;
};

export type GroupMemberExportInputPropsType = {
  __typename?: 'GroupMemberExportInputPropsType';
  groupId: Scalars['String']['output'];
  requiredFields: GroupMemberExportRequiredFieldsType;
};

export type GroupMemberExportMetadataType = {
  __typename?: 'GroupMemberExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type GroupMemberExportRequiredFieldsInput = {
  fields: Array<GroupMemberExportFieldEnum>;
};

export type GroupMemberExportRequiredFieldsType = {
  __typename?: 'GroupMemberExportRequiredFieldsType';
  fields: Array<GroupMemberExportFieldEnum>;
};

export type GroupMembersIds = {
  set: Array<Scalars['String']['input']>;
};

export enum GroupQueryIntentEnum {
  Assign = 'ASSIGN',
  Read = 'READ'
}

export enum GroupStatusEnum {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED',
  Revoked = 'REVOKED'
}

export enum GroupTypeEnum {
  ContentCollection = 'CONTENT_COLLECTION',
  UserCollection = 'USER_COLLECTION'
}

/** union of all groups */
export type GroupUnion = ContentCollectionGroupType | UserCollectionGroupType;

export type GroupedActionItemEdge = {
  __typename?: 'GroupedActionItemEdge';
  groupByKey: Scalars['String']['output'];
  groupedEntity: PaginatedActionItemsPayload;
};

export type GroupsFilters = {
  autoGroupTagValueIds?: InputMaybe<Array<Scalars['String']['input']>>;
  classroomIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeReceivedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  intent?: InputMaybe<GroupQueryIntentEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** filter groups by exact names, overrides `name` filter */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  sharedGroupFilters?: InputMaybe<SharedEntityFilters>;
  status?: InputMaybe<Array<GroupStatusEnum>>;
  subTypes?: InputMaybe<Array<UserCollectionGroupTypeEnum>>;
  systemGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Array<GroupTypeEnum>>;
};

/** Input for selecting userGroups */
export type GroupsSelectionConfigInput = {
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<GroupsFilters>;
  includeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  operation: ArrayOperationCommandEnum;
};

export type HabitsWidgetType = {
  __typename?: 'HabitsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  /** scheduled habits */
  habits: PaginatedMetricsPayload;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type HabitsWidgetTypeHabitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: DateFilter;
  first?: InputMaybe<Scalars['Float']['input']>;
};

/** homework advanced settings */
export type HomeworkAdvanceSettingsType = {
  __typename?: 'HomeworkAdvanceSettingsType';
  allowComments?: Maybe<Scalars['Boolean']['output']>;
  allowLateCompletion?: Maybe<Scalars['Boolean']['output']>;
  allowLateSubmission?: Maybe<Scalars['Boolean']['output']>;
  allowMultipleSubmissions?: Maybe<Scalars['Boolean']['output']>;
  allowRetake?: Maybe<Scalars['Boolean']['output']>;
  allowSubmissionNote?: Maybe<Scalars['Boolean']['output']>;
  /** Word limit of submission if response type is text */
  wordLimit?: Maybe<Scalars['Float']['output']>;
};

/** Instruction for Homework */
export type HomeworkInstructionType = {
  __typename?: 'HomeworkInstructionType';
  /** Instruction Media */
  medias: Array<EntityMediaType>;
  /** Instruction Text */
  text?: Maybe<Scalars['String']['output']>;
};

export type HomeworkPerformanceType = {
  __typename?: 'HomeworkPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** Submission Feedback Discussion Id */
  feedbackChannelId?: Maybe<Scalars['String']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  gradedAt?: Maybe<Scalars['String']['output']>;
  gradedById?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  parentEntityId?: Maybe<Scalars['String']['output']>;
  percentile?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  returnedAt?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  /** user submissions */
  submission?: Maybe<SubmissionType>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Homework Schema */
export type HomeworkType = {
  __typename?: 'HomeworkType';
  advanceSettings?: Maybe<HomeworkAdvanceSettingsType>;
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** category in which homework belongs */
  categoryId?: Maybe<Scalars['String']['output']>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  classroom: ClassroomType;
  /** This field is present if homework is created via classroom */
  classroomId: Scalars['String']['output'];
  /** Homework Discussion Id */
  commentChannelId?: Maybe<Scalars['String']['output']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** user groups for the homework */
  deprecatedGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  duration: Scalars['Float']['output'];
  /** Homework Deadline and availability settings */
  enrolmentSettings?: Maybe<EnrolmentSettingsType>;
  feedbackStickers: Array<SubmissionFeedbackStickers>;
  gradeSettings: ResourceGradeSettingsType;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** Homework Instructions in text and media */
  instruction?: Maybe<HomeworkInstructionType>;
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  performance?: Maybe<HomeworkPerformanceType>;
  performanceMetrics: ContentPerformanceMetricsType;
  /** Id of Scheduled postback call */
  postbackScheduleId?: Maybe<Scalars['String']['output']>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  submissionSettings: SubmissionSettingsType;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on resource */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Homework Schema */
export type HomeworkTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Homework Schema */
export type HomeworkTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Homework Schema */
export type HomeworkTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Homework Schema */
export type HomeworkTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Homework Schema */
export type HomeworkTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Homework Schema */
export type HomeworkTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Homework Schema */
export type HomeworkTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Homework Schema */
export type HomeworkTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

/** Token Verification Configurations */
export type IdpTokenVerificationConfig = {
  __typename?: 'IDPTokenVerificationConfig';
  tokenVerificationEndpoint?: Maybe<Scalars['String']['output']>;
  userVerificationEndpoint?: Maybe<Scalars['String']['output']>;
};

export type IconSettings = {
  __typename?: 'IconSettings';
  iconColor?: Maybe<Scalars['String']['output']>;
  type: IconTypeEnum;
  value: Scalars['String']['output'];
};

export type IconSettingsInput = {
  iconColor?: InputMaybe<Scalars['String']['input']>;
  type: IconTypeEnum;
  value: Scalars['String']['input'];
};

export type IconSettingsSetInput = {
  set?: InputMaybe<IconSettingsInput>;
};

export enum IconTypeEnum {
  Color = 'COLOR',
  Emoji = 'EMOJI',
  Icon = 'ICON'
}

export type ImageGridDisplayComponentType = {
  __typename?: 'ImageGridDisplayComponentType';
  /** heading for the section */
  heading?: Maybe<Scalars['String']['output']>;
  /** image urls */
  imageUrls: Array<Scalars['String']['output']>;
  type: DisplayComponentTypeEnum;
};

/** This is used for image hotspot */
export type ImageHotspotComponent = {
  __typename?: 'ImageHotspotComponent';
  distractors?: Maybe<Array<Distractor>>;
  id: Scalars['String']['output'];
  mediaId: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

/** This is used for image hotspot */
export type ImageHotspotComponentInput = {
  distractors?: InputMaybe<Array<DistractorInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type ImageHotspotComponentsSetInput = {
  /** Image hotspot component */
  set: Array<ImageHotspotComponentInput>;
};

/** Used when response type is image hotspot  */
export type ImageHotspotResponse = {
  __typename?: 'ImageHotspotResponse';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Used when response type is image hotspot  */
export type ImageHotspotResponseInput = {
  id: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type ImportContentFromMarketplaceInput = {
  marketplaceContentId: Scalars['String']['input'];
};

export type ImportContentFromMarketplacePayload = {
  __typename?: 'ImportContentFromMarketplacePayload';
  marketplaceContent?: Maybe<MarketplaceContentType>;
  marketplaceContentId: Scalars['String']['output'];
  /** @deprecated this is just for backward compatibility */
  success: Scalars['Boolean']['output'];
};

export type ImportJobSampleCsvRow = {
  __typename?: 'ImportJobSampleCsvRow';
  values: Array<Scalars['String']['output']>;
};

export type ImportJobSampleCsvRowsPayload = {
  __typename?: 'ImportJobSampleCsvRowsPayload';
  rows: Array<ImportJobSampleCsvRow>;
};

export type ImportSkillMatrixTemplateInput = {
  skillMatrixId: Scalars['String']['input'];
};

export type ImportSkillTemplatesInput = {
  skillIds: Array<Scalars['String']['input']>;
};

export enum IncludeChildObjectivesEnum {
  Both = 'BOTH',
  IncludeChildObjectives = 'INCLUDE_CHILD_OBJECTIVES',
  NotIncludeChildObjectives = 'NOT_INCLUDE_CHILD_OBJECTIVES'
}

export type InstructionInput = {
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Instruction Text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Instruction for entities */
export type InstructionType = {
  __typename?: 'InstructionType';
  /** media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']['output']>;
};

export type InstructorProviderAvailabilitiesInput = {
  instructorIds: Array<Scalars['String']['input']>;
  providerId: Scalars['String']['input'];
};

export type InstructorProviderAvailabilitiesPayload = {
  __typename?: 'InstructorProviderAvailabilitiesPayload';
  availabilities: Array<InstructorProviderAvailability>;
};

export type InstructorProviderAvailability = {
  __typename?: 'InstructorProviderAvailability';
  instructorId: Scalars['String']['output'];
  isAvailable: Scalars['Boolean']['output'];
};

/** Instructor Object */
export type InstructorType = {
  __typename?: 'InstructorType';
  designation: Scalars['String']['output'];
  name: Scalars['String']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type IntegrationConfigsType = {
  __typename?: 'IntegrationConfigsType';
  connectionStrategy: IntegrationConnectionStrategyType;
};

export enum IntegrationConnectionStrategyEnum {
  Form = 'FORM',
  FormAndRedirectUrl = 'FORM_AND_REDIRECT_URL',
  RedirectUrl = 'REDIRECT_URL'
}

export type IntegrationConnectionStrategyType = {
  __typename?: 'IntegrationConnectionStrategyType';
  connectionType: IntegrationConnectionStrategyEnum;
  /** Fields to be taken as input from user for completing integration with the selected platform */
  formFields?: Maybe<Array<FormComponentType>>;
};

export type IntegrationDetailPayload = {
  __typename?: 'IntegrationDetailPayload';
  integrationDetail?: Maybe<IntegrationDetailType>;
};

export type IntegrationDetailType = {
  __typename?: 'IntegrationDetailType';
  /** Stringified user input corresponding to integration connection form fields */
  authSettings: Scalars['String']['output'];
  connectedAt: Scalars['String']['output'];
  /** user who connected the integration */
  connectedBy?: Maybe<UserProfile>;
  connectedById: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  existingIntegrationGroupIds: Array<Scalars['String']['output']>;
  /**
   * Already selected user groups count according to provider latest groups data
   * @deprecated existingIntegrationGroupIds field provides updated ids hence length of that array can be used to get the updated count
   */
  existingIntegrationGroupsCount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  integrationFields?: Maybe<Array<Scalars['String']['output']>>;
  /** Available user groups for this integration provider */
  integrationGroups?: Maybe<Array<IntegrationUserGroupType>>;
  /** provider for this integration detail */
  integrationProvider?: Maybe<IntegrationProviderType>;
  /** Integration provider id to link to */
  integrationProviderId: Scalars['String']['output'];
  /** Integration provider */
  provider: IntegrationProviderEnum;
  scope: IntegrationScopeEnum;
  settings?: Maybe<IntegrationSettingsType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Integration status */
  status: IntegrationStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type IntegrationDetailTypeIntegrationFieldsArgs = {
  input?: InputMaybe<IntegrationFieldsInput>;
};


export type IntegrationDetailTypeIntegrationGroupsArgs = {
  input?: InputMaybe<IntegrationGroupsInput>;
};

export type IntegrationFieldsInput = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationGroupsInput = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

/** Edge */
export type IntegrationProviderEdge = {
  __typename?: 'IntegrationProviderEdge';
  node: IntegrationProviderType;
};

export enum IntegrationProviderEnum {
  Google = 'GOOGLE',
  Jira = 'JIRA',
  Microsoft = 'MICROSOFT',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  OutlookCalendar = 'OUTLOOK_CALENDAR',
  Salesforce = 'SALESFORCE',
  Slack = 'SLACK',
  Snowflake = 'SNOWFLAKE',
  Workday = 'WORKDAY',
  Zoom = 'ZOOM'
}

export type IntegrationProviderFilters = {
  integrationProviderId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<IntegrationProviderEnum>;
  scopes?: InputMaybe<Array<IntegrationScopeEnum>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationProviderInfoType = {
  __typename?: 'IntegrationProviderInfoType';
  /** @deprecated use components */
  aboutIntegration: Scalars['String']['output'];
  /** @deprecated use components */
  aboutProvider: Scalars['String']['output'];
  /** layout of integration detail section */
  components: Array<DisplayComponentUnion>;
  description: Scalars['String']['output'];
  documentationUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
  /** @deprecated use components */
  worksWith: Array<IntegrationWorksWithInfoType>;
};

export type IntegrationProviderPayload = {
  __typename?: 'IntegrationProviderPayload';
  integrationProvider?: Maybe<IntegrationProviderType>;
};

export enum IntegrationProviderStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  ComingSoon = 'COMING_SOON'
}

export type IntegrationProviderType = {
  __typename?: 'IntegrationProviderType';
  availableLyearnUserFields?: Maybe<Array<AvailableSchemaFieldType>>;
  availableSourceUserFields?: Maybe<Array<Scalars['String']['output']>>;
  /** category of integration */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultFieldMappings?: Maybe<Array<FieldMappingType>>;
  iconUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  info?: Maybe<IntegrationProviderInfoType>;
  integrationConfigs: IntegrationConfigsType;
  /** user who connected the integration */
  integrationDetail?: Maybe<IntegrationDetailType>;
  name: Scalars['String']['output'];
  provider: IntegrationProviderEnum;
  scopes: Array<IntegrationScopeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: IntegrationProviderStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** @deprecated use info.url */
  url: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type IntegrationProviderTypeIntegrationDetailArgs = {
  scope?: InputMaybe<IntegrationScopeEnum>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationProvidersFilters = {
  integrationProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  providers?: InputMaybe<Array<IntegrationProviderEnum>>;
  scopes?: InputMaybe<Array<IntegrationScopeEnum>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationRedirectUrlInput = {
  /** stringified key value pair for form input */
  formInput?: InputMaybe<Scalars['String']['input']>;
  integrationProviderId: Scalars['String']['input'];
  relayState: Scalars['String']['input'];
};

export type IntegrationRedirectUrlPayload = {
  __typename?: 'IntegrationRedirectUrlPayload';
  redirectUrl: Scalars['String']['output'];
};

export enum IntegrationScopeEnum {
  User = 'USER',
  Workspace = 'WORKSPACE'
}

export type IntegrationSettingsInput = {
  googleSettings?: InputMaybe<GoogleIntegrationSettingsInput>;
  jiraSettings?: InputMaybe<JiraIntegrationSettingsInput>;
  microsoftSettings?: InputMaybe<MicrosoftIntegrationSettingsInput>;
  microsoftTeamsSettings?: InputMaybe<MicrosoftTeamsIntegrationSettingsInput>;
  zoomSettings?: InputMaybe<ZoomIntegrationSettingsInput>;
};

export type IntegrationSettingsType = {
  __typename?: 'IntegrationSettingsType';
  googleSettings?: Maybe<GoogleIntegrationSettingsType>;
  jiraSettings?: Maybe<JiraIntegrationSettingsType>;
  microsoftSettings?: Maybe<MicrosoftIntegrationSettingsType>;
  microsoftTeamsSettings?: Maybe<MicrosoftTeamsIntegrationSettingsType>;
  zoomSettings?: Maybe<ZoomIntegrationSettingsType>;
};

export enum IntegrationStatusEnum {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  ReauthorizationRequired = 'REAUTHORIZATION_REQUIRED'
}

export type IntegrationUserGroupType = {
  __typename?: 'IntegrationUserGroupType';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  membersCount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
};

export type IntegrationWorksWithInfoType = {
  __typename?: 'IntegrationWorksWithInfoType';
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

/** The InvitationCode model */
export type InvitationCodeType = {
  __typename?: 'InvitationCodeType';
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  expireAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type InviteExternalUsersForEntitiesInput = {
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  inviteForAllEntities?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InviteUserInput = {
  departmentId?: InputMaybe<Scalars['String']['input']>;
  emailId: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** designation */
  orgRoleId?: InputMaybe<Scalars['String']['input']>;
  reportsToUserId?: InputMaybe<Scalars['String']['input']>;
  /** responsibility */
  responsibilityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  restrictedAccessConfig?: InputMaybe<RestrictedAccessConfigInput>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userType: UserTypeEnum;
  workspaceRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IsDepartmentNameAvailableInput = {
  name: Scalars['String']['input'];
};

export type IsDepartmentNameAvailablePayload = {
  __typename?: 'IsDepartmentNameAvailablePayload';
  isDepartmentNameAvailable: Scalars['Boolean']['output'];
};

/** Filters applicable on userSkillReview */
export type IsPerformanceReviewCompletedInput = {
  reviewCycleId: Scalars['String']['input'];
};

export type JwtDataType = {
  __typename?: 'JWTDataType';
  clientId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  issueId?: Maybe<Scalars['String']['output']>;
  onFailure: OnFailureActions;
  onSuccess: OnSuccessActions;
  secret: Scalars['String']['output'];
};

export type JwtDataTypeEntity = {
  __typename?: 'JWTDataTypeEntity';
  clientId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  issueId?: Maybe<Scalars['String']['output']>;
  onFailure: OnFailureActionsType;
  onSuccess: OnSuccessActionsType;
  secret: Scalars['String']['output'];
};

export type JiraAssigneeInfo = {
  __typename?: 'JiraAssigneeInfo';
  displayName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type JiraIntegrationFieldMappingConfig = {
  __typename?: 'JiraIntegrationFieldMappingConfig';
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  lyearnTagId?: Maybe<Scalars['String']['output']>;
  tagType?: Maybe<TagTypeEnum>;
};

export type JiraIntegrationPriorityConfig = {
  __typename?: 'JiraIntegrationPriorityConfig';
  id: Scalars['String']['output'];
  lyearnPriority?: Maybe<ActionItemPriorityEnum>;
  name: Scalars['String']['output'];
};

export type JiraIntegrationProjectStatusesConfig = {
  __typename?: 'JiraIntegrationProjectStatusesConfig';
  project?: Maybe<JiraProjectType>;
  statuses?: Maybe<Array<JiraIntegrationStatusConfig>>;
};

export type JiraIntegrationProjectStatusesConfigInput = {
  project?: InputMaybe<JiraProjectInput>;
  statuses?: InputMaybe<Array<JiraIntegrationStatusConfigInput>>;
};

export type JiraIntegrationSettingsInput = {
  projectStatusesConfigs?: InputMaybe<Array<JiraIntegrationProjectStatusesConfigInput>>;
};

export type JiraIntegrationSettingsType = {
  __typename?: 'JiraIntegrationSettingsType';
  fieldMappingConfigs?: Maybe<Array<JiraIntegrationFieldMappingConfig>>;
  priorityConfigs?: Maybe<Array<JiraIntegrationPriorityConfig>>;
  projectStatusesConfigs?: Maybe<Array<JiraIntegrationProjectStatusesConfig>>;
  registeredWebhookIds?: Maybe<Array<Scalars['String']['output']>>;
  scheduledJobs?: Maybe<Array<ScheduledJobConfigs>>;
};

export type JiraIntegrationStatusConfig = {
  __typename?: 'JiraIntegrationStatusConfig';
  id: Scalars['String']['output'];
  lyearnStatusId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated use mappedLyearnStatusId to map to lyearn status */
  progress?: Maybe<Scalars['Float']['output']>;
  statusCategory: JiraStatusCategory;
};

export type JiraIntegrationStatusConfigInput = {
  id: Scalars['String']['input'];
  lyearnStatusId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  progress?: InputMaybe<Scalars['Float']['input']>;
  statusCategory: JiraStatusCategoryInput;
};

/** Edge */
export type JiraIssueEdge = {
  __typename?: 'JiraIssueEdge';
  node: JiraIssueType;
};

export type JiraIssueFilters = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  jqlQuery?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
};

export type JiraIssueType = {
  __typename?: 'JiraIssueType';
  assigneeInfo?: Maybe<JiraAssigneeInfo>;
  dueDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActionItemLinked: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
  priorityInfo?: Maybe<JiraPriorityType>;
  project?: Maybe<Scalars['String']['output']>;
  sprintInfos?: Maybe<Array<JiraSprintInfo>>;
  status?: Maybe<Scalars['String']['output']>;
  statusCategory?: Maybe<JiraStatusCategory>;
  statusId?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};


export type JiraIssueTypeIsActionItemLinkedArgs = {
  entityFilter?: InputMaybe<ActionItemEntityFilter>;
  fileFilter?: InputMaybe<FileFilters>;
};

export type JiraPriorityType = {
  __typename?: 'JiraPriorityType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type JiraProjectInput = {
  id: Scalars['String']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type JiraProjectType = {
  __typename?: 'JiraProjectType';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type JiraSprintInfo = {
  __typename?: 'JiraSprintInfo';
  boardId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  goal?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type JiraStatusCategory = {
  __typename?: 'JiraStatusCategory';
  id: Scalars['String']['output'];
  key: JiraStatusCategoryKeyEnum;
  name: Scalars['String']['output'];
};

export type JiraStatusCategoryInput = {
  id: Scalars['String']['input'];
  key: JiraStatusCategoryKeyEnum;
  name: Scalars['String']['input'];
};

export enum JiraStatusCategoryKeyEnum {
  Done = 'DONE',
  Indeterminate = 'INDETERMINATE',
  New = 'NEW',
  Undefined = 'UNDEFINED'
}

export type JobByCreatorSubscriptionInput = {
  /** userId of the job creator */
  createdById: Scalars['String']['input'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<JobSubscriptionOperationEnum>>;
  /** job purposes to subscribe on */
  purpose: Array<JobPurposeEnum>;
};

export type JobByCreatorSubscriptionPayload = {
  __typename?: 'JobByCreatorSubscriptionPayload';
  job: JobType;
  /** operation which happened on the job */
  operation: JobSubscriptionOperationEnum;
};

/** Edge */
export type JobEdge = {
  __typename?: 'JobEdge';
  node: JobType;
};

export enum JobEntityStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProcess = 'IN_PROCESS',
  NotStarted = 'NOT_STARTED',
  Parsing = 'PARSING',
  Validating = 'VALIDATING',
  Waiting = 'WAITING'
}

export type JobFilters = {
  jobScheduleId?: InputMaybe<Scalars['String']['input']>;
};

export type JobInputPropsInput = {
  asyncAssignContentInput?: InputMaybe<AsyncAssignContentInput>;
  asyncAssignEngageEntityInput?: InputMaybe<AsyncAssignEngageEntityInput>;
  asyncAssignJourneyEntityInput?: InputMaybe<AsyncAssignJourneyEntityInput>;
  asyncAssignJourneyStepEntityInput?: InputMaybe<AsyncAssignJourneyStepEntityInput>;
  bulkUnAssignContentPropsInput?: InputMaybe<BulkUnAssignContentPropsInput>;
  chartExportInput?: InputMaybe<ChartExportPropsInput>;
  downloadVideoInput?: InputMaybe<DownloadVideoInput>;
  employeeJourneyReportsExportInput?: InputMaybe<EmployeeJourneyReportsExportInputProps>;
  forceCompleteContentInput?: InputMaybe<ForceCompleteContentInput>;
  groupMemberExportInput?: InputMaybe<GroupMemberExportInputProps>;
  learnerExportInput?: InputMaybe<LearnerExportInputPropsInput>;
  offlineEventAttendanceExportInput?: InputMaybe<OfflineEventAttendanceExportInputPropsInput>;
  offlineEventAttendanceImportInput?: InputMaybe<OfflineEventAttendanceImportInputPropsInput>;
  postContentUpdateProcessingInput?: InputMaybe<PostContentUpdateProcessingInput>;
  questionImportInput?: InputMaybe<QuestionImportInputPropsInput>;
  quizQuestionExportInput?: InputMaybe<QuizQuestionExportInputPropsInput>;
  quizQuestionImportInput?: InputMaybe<QuizQuestionImportInputPropsInput>;
  recalibrateContentProgressInput?: InputMaybe<RecalibrateContentProgressInput>;
  recalibrateQuestionProgressInput?: InputMaybe<RecalibrateQuestionProgressInput>;
  reportExportInput?: InputMaybe<ReportExportPropsInput>;
  reportMediaExportInput?: InputMaybe<ReportMediaExportPropsInput>;
  reviewCycleAutoFlagInput?: InputMaybe<ReviewCycleAutoFlagPropsInput>;
  skillMatrixExportInput?: InputMaybe<SkillMatrixExportInputPropsInput>;
  skillsExportInput?: InputMaybe<SkillsExportInputPropsInput>;
  surveyExportInput?: InputMaybe<SurveyExportPropsInput>;
  tagExportInput?: InputMaybe<TagExportInputProps>;
  taggedEntitiesExportInput?: InputMaybe<TaggedEntitiesExportInputPropsInput>;
  teamsEventAttendanceImportInput?: InputMaybe<TeamsEventAttendanceImportInputPropsInput>;
  teamsRecordingsImportInput?: InputMaybe<TeamsRecordingsImportInputPropsInput>;
  updatePathContentAvailabilityOfGivenUserIdsInput?: InputMaybe<UpdatePathContentAvailabilityOfGivenUserIdsInput>;
  userSurveyResponseExportInput?: InputMaybe<UserSurveyResponseExportPropsInput>;
  zoomEventAttendanceImportInput?: InputMaybe<ZoomEventAttendanceImportInputPropsInput>;
  zoomRecordingsImportInput?: InputMaybe<ZoomRecordingsImportInputPropsInput>;
};

export type JobInputPropsType = {
  __typename?: 'JobInputPropsType';
  asyncAssignJourneyEntityInput?: Maybe<AsyncAssignJourneyEntityInputPropsType>;
  asyncAssignJourneyStepEntityInput?: Maybe<AsyncAssignJourneyStepEntityInputPropsType>;
  bulkUnAssignContentPropsInput?: Maybe<BulkUnAssignContentPropsInputType>;
  chartExportInput?: Maybe<ChartExportPropsType>;
  downloadVideoInput?: Maybe<DownloadVideoType>;
  employeeJourneyReportsExportInput?: Maybe<EmployeeJourneyReportsExportInputPropsType>;
  forceCompleteContentInput?: Maybe<ForceCompleteContentType>;
  groupMemberExportInput?: Maybe<GroupMemberExportInputPropsType>;
  learnerExportInput?: Maybe<LearnerExportInputPropsType>;
  offlineEventAttendanceExportInput?: Maybe<OfflineEventAttendanceExportInputPropsType>;
  offlineEventAttendanceImportInput?: Maybe<OfflineEventAttendanceImportInputPropsType>;
  postContentUpdateProcessingInput?: Maybe<PostContentUpdateProcessingInputType>;
  questionImportInput?: Maybe<QuestionImportInputPropsType>;
  quizQuestionExportInput?: Maybe<QuizQuestionExportInputPropsType>;
  quizQuestionImportInput?: Maybe<QuizQuestionImportInputPropsType>;
  recalibrateContentProgressInput?: Maybe<RecalibrateContentProgressPropsType>;
  reportExportInput?: Maybe<ReportExportPropsType>;
  reportMediaExportInput?: Maybe<ReportMediaExportPropsType>;
  reviewCycleAutoFlagInput?: Maybe<ReviewCycleAutoFlagInputPropsType>;
  skillMatrixExportInput?: Maybe<SkillMatrixExportInputPropsType>;
  skillsExportInput?: Maybe<SkillsExportInputPropsType>;
  surveyExportInput?: Maybe<Scalars['String']['output']>;
  tagExportInput?: Maybe<TagExportInputPropsType>;
  taggedEntitiesExportInput?: Maybe<TaggedEntitiesExportInputPropsType>;
  teamsEventAttendanceImportInput?: Maybe<TeamsEventAttendanceImportInputPropsType>;
  teamsRecordingsImportInput?: Maybe<TeamsRecordingsImportInputPropsType>;
  updateBatchUsersPathContentAvailabilityInput?: Maybe<UpdateBatchUsersPathContentAvailabilityInputPropsType>;
  userSurveyResponseExportInput?: Maybe<UserSurveyResponseExportInputPropsType>;
  zoomEventAttendanceImportInput?: Maybe<ZoomEventAttendanceImportInputPropsType>;
  zoomRecordingsImportInput?: Maybe<ZoomRecordingsImportInputPropsType>;
};

export type JobMetaType = {
  __typename?: 'JobMetaType';
  assignmentImportMetadataType?: Maybe<AssignmentImportMetadataType>;
  assignmentLearnerImportMetadata?: Maybe<AssignmentLearnerImportMetadataType>;
  bulkTagsUpdateMetadata?: Maybe<BulkTagsUpdateMetadataType>;
  bulkUnAssignLearnerMetadata?: Maybe<BulkUnAssignLearnerMetadataType>;
  chartExportMetadata?: Maybe<ChartExportMetadataType>;
  employeeJourneyReportsExportMetadata?: Maybe<EmployeeJourneyReportsExportMetadataType>;
  groupMemberExportMetadata?: Maybe<GroupMemberExportMetadataType>;
  learnerExportMetadata?: Maybe<LearnerExportMetadataType>;
  learnerImportMetadata?: Maybe<LearnerImportMetadataType>;
  lessonMeta?: Maybe<LessonMetaType>;
  offlineEventAttendanceExportMetadataType?: Maybe<OfflineEventAttendanceExportMetadataType>;
  offlineEventAttendanceImportMetadata?: Maybe<OfflineEventAttendanceImportMetadataType>;
  questionImportMetadata?: Maybe<QuestionImportMetadataType>;
  questionsExportMetadata?: Maybe<QuestionsExportMetadataType>;
  quizQuestionImportMetadata?: Maybe<QuizQuestionImportMetadataType>;
  recalibrateContentProgressMetadata?: Maybe<LongLastingProcessMetaDataType>;
  reportExportMetadata?: Maybe<ReportExportMetadataType>;
  reportMediaExportMetadata?: Maybe<ReportMediaExportMetadataType>;
  scormImportMetadata?: Maybe<ScormImportMetadataType>;
  skillMatrixExportMetadata?: Maybe<SkillMatrixExportMetadataType>;
  skillMatrixImportMetadata?: Maybe<SkillMatrixImportMetadataType>;
  skillsExportMetadata?: Maybe<SkillsExportMetadataType>;
  skillsImportMetadata?: Maybe<SkillsImportMetadataType>;
  surveyExportMetadata?: Maybe<SurveyExportMetadataType>;
  tagExportMetadata?: Maybe<TagExportMetadataType>;
  taggedEntitiesExportMetadata?: Maybe<TaggedEntitiesExportMetadataType>;
  teamsEventAttendanceImportMetadata?: Maybe<TeamsEventAttendanceImportMetadataType>;
  updateBatchUsersPathContentAvailabilityMetaData?: Maybe<UpdateBatchUsersPathContentAvailabilityMetaDataType>;
  userGroupMemberImportMetadata?: Maybe<UserGroupMemberImportMetadataType>;
  userSurveyResponseExportMetadata?: Maybe<UserSurveyResponseExportMetadataType>;
  zoomEventAttendanceImportMetadata?: Maybe<ZoomEventAttendanceImportMetadataType>;
};

export enum JobPurposeEnum {
  AssignmentImport = 'ASSIGNMENT_IMPORT',
  AssignmentLearnerImport = 'ASSIGNMENT_LEARNER_IMPORT',
  AsyncAssignContent = 'ASYNC_ASSIGN_CONTENT',
  AsyncAssignEngageEntity = 'ASYNC_ASSIGN_ENGAGE_ENTITY',
  AsyncAssignJourneyEntity = 'ASYNC_ASSIGN_JOURNEY_ENTITY',
  AsyncAssignJourneyStepEntity = 'ASYNC_ASSIGN_JOURNEY_STEP_ENTITY',
  BulkTagsUpdate = 'BULK_TAGS_UPDATE',
  BulkUnAssignLearner = 'BULK_UN_ASSIGN_LEARNER',
  BulkUnAssignLearnerChild = 'BULK_UN_ASSIGN_LEARNER_CHILD',
  BulkUnAssignLearnerPreprocess = 'BULK_UN_ASSIGN_LEARNER_PREPROCESS',
  ChartExport = 'CHART_EXPORT',
  DownloadVideo = 'DOWNLOAD_VIDEO',
  EmployeeJourneyReportsExport = 'EMPLOYEE_JOURNEY_REPORTS_EXPORT',
  ForceCompleteContent = 'FORCE_COMPLETE_CONTENT',
  GroupMemberExport = 'GROUP_MEMBER_EXPORT',
  ImportMsdocAsLesson = 'IMPORT_MSDOC_AS_LESSON',
  LearnerExport = 'LEARNER_EXPORT',
  LearnerImport = 'LEARNER_IMPORT',
  MicrosoftTeamsAttendanceImport = 'MICROSOFT_TEAMS_ATTENDANCE_IMPORT',
  MicrosoftTeamsRecordingImport = 'MICROSOFT_TEAMS_RECORDING_IMPORT',
  MindsetExport = 'MINDSET_EXPORT',
  MindsetImport = 'MINDSET_IMPORT',
  OfflineEventAttendanceExport = 'OFFLINE_EVENT_ATTENDANCE_EXPORT',
  OfflineEventAttendanceImport = 'OFFLINE_EVENT_ATTENDANCE_IMPORT',
  PublishContentPostProcessEvents = 'PUBLISH_CONTENT_POST_PROCESS_EVENTS',
  QuestionImport = 'QUESTION_IMPORT',
  QuizQuestionExport = 'QUIZ_QUESTION_EXPORT',
  QuizQuestionImport = 'QUIZ_QUESTION_IMPORT',
  RecalibrateContentProgress = 'RECALIBRATE_CONTENT_PROGRESS',
  RecalibrateQuestionProgress = 'RECALIBRATE_QUESTION_PROGRESS',
  ReportExport = 'REPORT_EXPORT',
  ReportMediaExport = 'REPORT_MEDIA_EXPORT',
  ReviewCycleAutoFlag = 'REVIEW_CYCLE_AUTO_FLAG',
  ScormImport = 'SCORM_IMPORT',
  SkillExport = 'SKILL_EXPORT',
  SkillImport = 'SKILL_IMPORT',
  SkillMatrixExport = 'SKILL_MATRIX_EXPORT',
  SkillMatrixImport = 'SKILL_MATRIX_IMPORT',
  SurveyExport = 'SURVEY_EXPORT',
  SyncExternalUsers = 'SYNC_EXTERNAL_USERS',
  TaggedEntitiesExport = 'TAGGED_ENTITIES_EXPORT',
  TagExport = 'TAG_EXPORT',
  UpdatePathContentAvailability = 'UPDATE_PATH_CONTENT_AVAILABILITY',
  UserGroupMemberImport = 'USER_GROUP_MEMBER_IMPORT',
  UserSurveyResponseExport = 'USER_SURVEY_RESPONSE_EXPORT',
  ZoomEventAttendanceImport = 'ZOOM_EVENT_ATTENDANCE_IMPORT',
  ZoomRecordingsImport = 'ZOOM_RECORDINGS_IMPORT'
}

export type JobSettings = {
  __typename?: 'JobSettings';
  /** should notify user with current context or not, default is true */
  notifyCurrentUser?: Maybe<Scalars['Boolean']['output']>;
  /** userIds who should be notified on job success or failure */
  notifyToUserIds: Array<Scalars['String']['output']>;
};

export type JobSettingsInput = {
  /** should notify user with current context or not, default is true */
  notifyCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** userIds who should be notified on job success or failure */
  notifyToUserIds: Array<Scalars['String']['input']>;
};

export enum JobStatusEnum {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING'
}

export type JobSubscriptionInput = {
  /** jobScheduleId to subscribe job on */
  jobScheduleId: Scalars['String']['input'];
};

export enum JobSubscriptionOperationEnum {
  JobCreated = 'JOB_CREATED',
  JobUpdated = 'JOB_UPDATED'
}

/** Job schema */
export type JobType = {
  __typename?: 'JobType';
  childJobs?: Maybe<Array<ChildJobMetaType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** errors that occured during job processing */
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** input arguments for job */
  input?: Maybe<JobInputPropsType>;
  /** id of the job */
  jobScheduleId: Scalars['String']['output'];
  /** url and format of the media associated with the job */
  mediaUrls: Array<MediaUrl>;
  meta?: Maybe<JobMetaType>;
  /** url and format of the output associated with the job */
  outputMediaUrls: Array<MediaUrl>;
  purpose?: Maybe<JobPurposeEnum>;
  retryCount?: Maybe<Scalars['Float']['output']>;
  /** input arguments for retry job */
  retryJobInputMeta?: Maybe<Array<JobInputPropsType>>;
  settings?: Maybe<JobSettings>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedAt?: Maybe<Scalars['String']['output']>;
  /** status of the job */
  status: JobEntityStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type JobsFilters = {
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  jobIds?: InputMaybe<Array<Scalars['String']['input']>>;
  purpose?: InputMaybe<Array<JobPurposeEnum>>;
  status?: InputMaybe<Array<JobEntityStatusEnum>>;
  videoUID?: InputMaybe<Scalars['String']['input']>;
};

export type JoinEventInput = {
  eventId: Scalars['String']['input'];
};

export type JourneyBatchConfigsUpdateInput = {
  add?: InputMaybe<Array<BatchConfigInput>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<BatchConfigInput>>;
};

export type JourneyDraftType = {
  __typename?: 'JourneyDraftType';
  iconSettings?: Maybe<IconSettings>;
  name: Scalars['String']['output'];
  participantConfigs: ParticipantConfigType;
  phases?: Maybe<Array<JourneyPhaseType>>;
  reportVisibilitySettings: VisibilitySettingsType;
};

/** Edge */
export type JourneyEdge = {
  __typename?: 'JourneyEdge';
  node: JourneyType;
};

export type JourneyFilters = {
  batchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<JourneyStatusEnum>>;
};

export type JourneyInput = {
  id: Scalars['String']['input'];
  versionStatus?: InputMaybe<JourneyVersionStatusEnum>;
};

export enum JourneyParticipantConfigTypeEnum {
  Batches = 'BATCHES',
  SingleBatch = 'SINGLE_BATCH'
}

export type JourneyPayload = {
  __typename?: 'JourneyPayload';
  journey?: Maybe<JourneyType>;
};

/** Edge */
export type JourneyPerformanceEdge = {
  __typename?: 'JourneyPerformanceEdge';
  node: JourneyPerformanceType;
};

export type JourneyPerformanceFilters = {
  availableFrom?: InputMaybe<Scalars['String']['input']>;
  batchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  consumableByUser?: InputMaybe<Scalars['Boolean']['input']>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEntityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  personaTypes?: InputMaybe<Array<JourneyPerformancePersonaTypeEnum>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<JourneyPerformanceStatusEnum>>;
  stepLockStatus?: InputMaybe<JourneyPerformanceStepLockStatusEnum>;
  traineeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<JourneyPerformanceTypeEnum>>;
  uniqueByBatch?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum JourneyPerformancePersonaTypeEnum {
  Participant = 'PARTICIPANT',
  SpecificEmployee = 'SPECIFIC_EMPLOYEE',
  Tag = 'TAG',
  UsersManager = 'USERS_MANAGER'
}

export type JourneyPerformanceQuestionResponseInput = {
  numberResponse?: InputMaybe<Scalars['Float']['input']>;
  textResponse?: InputMaybe<Scalars['String']['input']>;
  type: JourneyPerformanceQuestionResponseTypeEnum;
};

export type JourneyPerformanceQuestionResponseType = {
  __typename?: 'JourneyPerformanceQuestionResponseType';
  numberResponse?: Maybe<Scalars['Float']['output']>;
  textResponse?: Maybe<Scalars['String']['output']>;
  type: JourneyPerformanceQuestionResponseTypeEnum;
};

export enum JourneyPerformanceQuestionResponseTypeEnum {
  Date = 'DATE',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Text = 'TEXT'
}

export enum JourneyPerformanceStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export enum JourneyPerformanceStepLockStatusEnum {
  All = 'ALL',
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED'
}

export type JourneyPerformanceType = {
  __typename?: 'JourneyPerformanceType';
  availableFrom?: Maybe<Scalars['String']['output']>;
  batchId?: Maybe<Scalars['String']['output']>;
  consumableByUser?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isLocked: Scalars['Boolean']['output'];
  isMyStep?: Maybe<Scalars['Boolean']['output']>;
  journey?: Maybe<JourneyType>;
  journeyStep?: Maybe<JourneyStepType>;
  journeyStepLockedStateDescription: Scalars['String']['output'];
  parentEntityId?: Maybe<Scalars['String']['output']>;
  participantsProfiles?: Maybe<Array<UserType>>;
  personaType?: Maybe<JourneyPerformancePersonaTypeEnum>;
  progress: Scalars['Float']['output'];
  questionResponse?: Maybe<JourneyPerformanceQuestionResponseType>;
  reviewerId?: Maybe<Scalars['String']['output']>;
  status: JourneyPerformanceStatusEnum;
  tagId?: Maybe<Scalars['String']['output']>;
  tagName?: Maybe<Scalars['String']['output']>;
  traineeId?: Maybe<Scalars['String']['output']>;
  type: JourneyPerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userLevelMeta?: Maybe<UserJourneyPerformanceLevelMeta>;
};

export enum JourneyPerformanceTypeEnum {
  Journey = 'JOURNEY',
  JourneyPhase = 'JOURNEY_PHASE',
  JourneyStep = 'JOURNEY_STEP',
  JourneyStepQuestion = 'JOURNEY_STEP_QUESTION',
  JourneyStepSkill = 'JOURNEY_STEP_SKILL'
}

export type JourneyPersonaViewPerformanceType = {
  __typename?: 'JourneyPersonaViewPerformanceType';
  isLocked: Scalars['Boolean']['output'];
  isMyStep: Scalars['Boolean']['output'];
  participantsProfiles?: Maybe<Array<UserType>>;
  progress: Scalars['Float']['output'];
  userIds: Array<Scalars['String']['output']>;
};

export type JourneyPhaseInput = {
  name: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};

export type JourneyPhaseType = {
  __typename?: 'JourneyPhaseType';
  name: Scalars['String']['output'];
  phaseId: Scalars['String']['output'];
  stepAssignmentInfo?: Maybe<JourneyStepAssignmentInfoType>;
};


export type JourneyPhaseTypeStepAssignmentInfoArgs = {
  batchId: Scalars['String']['input'];
  journeyId: Scalars['String']['input'];
};

export type JourneyPhaseUpdateInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<JourneyPhaseInput>>;
};

export enum JourneyStatusEnum {
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  UnpublishedChanges = 'UNPUBLISHED_CHANGES'
}

/** This is the input for action entities type in a journey entity! */
export type JourneyStepActionEntitiesInput = {
  /** Entity Ids */
  entityIds: Array<Scalars['String']['input']>;
  /** Entity Type */
  entityType: JourneyStepActionEntityTypeEnum;
};

export type JourneyStepActionEntitiesType = {
  __typename?: 'JourneyStepActionEntitiesType';
  entities?: Maybe<Array<ContentEntity>>;
  /** Entity Ids */
  entityIds: Array<Scalars['String']['output']>;
  /** Entity Type */
  entityType: JourneyStepActionEntityTypeEnum;
};

export enum JourneyStepActionEntityTypeEnum {
  Activity = 'ACTIVITY',
  Checklist = 'CHECKLIST',
  Content = 'CONTENT',
  Form = 'FORM',
  OneOnOne = 'ONE_ON_ONE',
  Review = 'REVIEW',
  Session = 'SESSION',
  Survey = 'SURVEY'
}

export type JourneyStepActionSettingsInput = {
  assigneeActionType: JourneyStepAssigneeActionTypeEnum;
};

/** Journey Step Action Settings */
export type JourneyStepActionSettingsType = {
  __typename?: 'JourneyStepActionSettingsType';
  /** Entity Type */
  assigneeActionType: JourneyStepAssigneeActionTypeEnum;
};

export enum JourneyStepAssigneeActionTypeEnum {
  Participants = 'PARTICIPANTS',
  Self = 'SELF'
}

export enum JourneyStepAssignmentConfigTypeEnum {
  Participants = 'PARTICIPANTS',
  SpecificEmployee = 'SPECIFIC_EMPLOYEE',
  SpecificRole = 'SPECIFIC_ROLE'
}

export type JourneyStepAssignmentConfigsInput = {
  roleType?: InputMaybe<JourneyStepAssignmentRoleTypeEnum>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: JourneyStepAssignmentConfigTypeEnum;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Journey Step Assignment Configs */
export type JourneyStepAssignmentConfigsType = {
  __typename?: 'JourneyStepAssignmentConfigsType';
  roleType?: Maybe<JourneyStepAssignmentRoleTypeEnum>;
  /** Tag Ids */
  tagIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Entity Type */
  type: JourneyStepAssignmentConfigTypeEnum;
  /** User Ids */
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type JourneyStepAssignmentInfoType = {
  __typename?: 'JourneyStepAssignmentInfoType';
  assignedStepsCount: Scalars['Int']['output'];
  completedStepsCount: Scalars['Int']['output'];
  totalStepsCount: Scalars['Int']['output'];
};

export enum JourneyStepAssignmentRoleTypeEnum {
  Tag = 'TAG',
  UsersManagers = 'USERS_MANAGERS'
}

/** Edge */
export type JourneyStepEdge = {
  __typename?: 'JourneyStepEdge';
  node: JourneyStepType;
};

export type JourneyStepFilters = {
  assignmentType?: InputMaybe<Array<JourneyStepAssignmentConfigTypeEnum>>;
  batchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dripSettingsType?: InputMaybe<DripTypeEnum>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  journeyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastStepToFetch?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onlyMySteps?: InputMaybe<Scalars['Boolean']['input']>;
  orders?: InputMaybe<Array<Scalars['Int']['input']>>;
  phaseIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<JourneyStepStatusTypeEnum>>;
  stepsWithConditionalActionEntities?: InputMaybe<Scalars['Boolean']['input']>;
  versionStatus?: InputMaybe<JourneyStepVersionStatusEnum>;
};

export enum JourneyStepSessionAttendeeAdditionType {
  All = 'ALL',
  Manual = 'MANUAL'
}

export type JourneyStepSettingsInput = {
  actionSettings: JourneyStepActionSettingsInput;
  assignmentConfigs: JourneyStepAssignmentConfigsInput;
  dripSettings: DripSettingsV2Input;
  removeStepIfConditionIsNotMet?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Journey Step Settings */
export type JourneyStepSettingsType = {
  __typename?: 'JourneyStepSettingsType';
  /** journey step drip settings */
  actionSettings: JourneyStepActionSettingsType;
  assignmentConfigs: JourneyStepAssignmentConfigsType;
  /** journey step drip settings */
  dripSettings: DripSettingsV2Type;
  removeStepIfConditionIsNotMet?: Maybe<Scalars['Boolean']['output']>;
};

export enum JourneyStepStatusTypeEnum {
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  UnpublishedChanges = 'UNPUBLISHED_CHANGES'
}

export type JourneyStepTraineeSessionType = {
  __typename?: 'JourneyStepTraineeSessionType';
  attendeeAdditionType: JourneyStepSessionAttendeeAdditionType;
  attendeeIds: Array<Scalars['String']['output']>;
  batchId: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  excludeAttendeeIds?: Maybe<Array<Scalars['String']['output']>>;
  journeyStepId: Scalars['String']['output'];
  participantsProfiles?: Maybe<Array<UserType>>;
  sessionId: Scalars['String']['output'];
  trainerId: Scalars['String']['output'];
};

/** Journey Step schema */
export type JourneyStepType = {
  __typename?: 'JourneyStepType';
  actionEntities: Array<JourneyStepActionEntitiesType>;
  actionEntityContents?: Maybe<Array<ContentEntity>>;
  actionEntityQuestions?: Maybe<Array<QuestionType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isOptional: Scalars['Boolean']['output'];
  journeyId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  performance?: Maybe<JourneyPerformanceType>;
  personaViewPerformance?: Maybe<JourneyPersonaViewPerformanceType>;
  phaseId: Scalars['String']['output'];
  settings: JourneyStepSettingsType;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: JourneyStepStatusTypeEnum;
  tagName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


/** Journey Step schema */
export type JourneyStepTypePerformanceArgs = {
  batchId: Scalars['String']['input'];
};


/** Journey Step schema */
export type JourneyStepTypePersonaViewPerformanceArgs = {
  batchId: Scalars['String']['input'];
};

export enum JourneyStepVersionStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type JourneyType = {
  __typename?: 'JourneyType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  draft?: Maybe<JourneyDraftType>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nextStep?: Maybe<JourneyPerformanceType>;
  participantConfigs: ParticipantConfigType;
  participantsProfiles?: Maybe<Array<UserProfile>>;
  phases: Array<JourneyPhaseType>;
  reportVisibilitySettings: VisibilitySettingsType;
  status: JourneyStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userCount: Scalars['Float']['output'];
  userLevelMeta?: Maybe<UserJourneyPerformanceLevelMeta>;
};


export type JourneyTypeNextStepArgs = {
  batchId: Scalars['String']['input'];
};


export type JourneyTypeUserLevelMetaArgs = {
  batchId: Scalars['String']['input'];
};

export enum JourneyVersionStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type JourneysWidgetType = {
  __typename?: 'JourneysWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  journeyPerformances?: Maybe<PaginatedJourneyPerformancesPayload>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum KeyResultMetricEnum {
  Currency = 'CURRENCY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Time = 'TIME',
  Toggle = 'TOGGLE'
}

/** KeyResult outcome */
export type KeyResultOutcome = {
  __typename?: 'KeyResultOutcome';
  /** Current value mentioned */
  actualValue?: Maybe<Scalars['Float']['output']>;
  /** Defines format */
  format?: Maybe<NumberFormatEnum>;
  /** Metric type */
  metric: KeyResultMetricEnum;
  /** Starting value mentioned */
  startValue?: Maybe<Scalars['Float']['output']>;
  /** target value mentioned */
  targetValue?: Maybe<Scalars['Float']['output']>;
};

export type KeyResultOutcomeInput = {
  /** Defines format */
  format?: InputMaybe<NumberFormatEnum>;
  /** Metric type */
  metric: KeyResultMetricEnum;
  /** Starting value mentioned */
  startValue?: InputMaybe<Scalars['Float']['input']>;
  /** target value mentioned */
  targetValue?: InputMaybe<Scalars['Float']['input']>;
};

/** Key Result Type */
export type KeyResultType = {
  __typename?: 'KeyResultType';
  /** all visibility settings of the okr */
  allVisibilitySettings: Array<VisibilitySettingsType>;
  attachments?: Maybe<Array<EntityMediaType>>;
  /** Checkin Timestamp */
  checkedInAt?: Maybe<Scalars['String']['output']>;
  /** Total number of checkins */
  checkinCount?: Maybe<Scalars['Float']['output']>;
  /** All checkins of the okr */
  checkins?: Maybe<Array<OkrCheckinType>>;
  /** will fetch direct child OKRs of the given OKR */
  childOKRs?: Maybe<Array<OkrUnion>>;
  /** Order of okr in the parent okr child list */
  childOrder: Scalars['Float']['output'];
  childOrderForParent: Scalars['Float']['output'];
  childOrders?: Maybe<Array<ParentChildOrder>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String']['output'];
  /**
   * use details field with text and media
   * @deprecated use details field with text and media
   */
  description?: Maybe<Scalars['String']['output']>;
  discussionChannel?: Maybe<ChannelType>;
  discussionChannelId?: Maybe<Scalars['String']['output']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String']['output'];
  dueDateConfig: DueDateConfig;
  /** Returns true if okr has any child okrs */
  hasChildren: Scalars['Boolean']['output'];
  /** path till rootNode - starting from /parent.../rootNode */
  hierarchyPath?: Maybe<Scalars['String']['output']>;
  /** Hierarchy paths of okr */
  hierarchyPaths?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** OKR Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Details for linking externally */
  integrationConfig?: Maybe<OkrIntegrationConfig>;
  /** Indicates if okr is deleted */
  isDeleted: Scalars['Boolean']['output'];
  isProgressAutoRolledUp?: Maybe<Scalars['Boolean']['output']>;
  /** Name or Title of Objective or key result */
  name: Scalars['String']['output'];
  noteBlockId?: Maybe<Scalars['String']['output']>;
  /** Cycle of the OKR */
  okrCycle?: Maybe<OkrCycle>;
  /** owners of the okr */
  okrOwners?: Maybe<Array<UserType>>;
  /** returns count of all the direct + indirect child okrs of given okr */
  okrTreeChildrenCount: Scalars['Float']['output'];
  /** store the result data for KR */
  outcome: KeyResultOutcome;
  /** owners of OKR */
  ownerIds: Array<Scalars['String']['output']>;
  /** owners of the okr */
  owners?: Maybe<Array<UserProfile>>;
  /** Id of the parent */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Id of the parents */
  parentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** parent OKR of the okr */
  parentOKR?: Maybe<OkrUnion>;
  /** parent OKR of the okr */
  parentOKRs?: Maybe<Array<OkrUnion>>;
  /** Progress done */
  progress: Scalars['Float']['output'];
  /** Details for progress measurement */
  progressMeasurementConfig?: Maybe<OkrProgressMeasurementConfig>;
  /** All the things which are related to okr */
  relatedToEntities?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status for okr, ontrack behind etc */
  status: OkrStatusEnum;
  summary?: Maybe<OkrSummaryType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  totalChildOKRCount: Scalars['Float']['output'];
  /** Type for okr, Objective or key result */
  type: OkrTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on okr (objective or key result) */
  userPermissions?: Maybe<Array<UserOkrPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  /** store who can view the objective */
  visibilitySettings: VisibilitySettingsType;
};


/** Key Result Type */
export type KeyResultTypeChildOkRsArgs = {
  filters?: InputMaybe<GetChildOkRsFilters>;
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};


/** Key Result Type */
export type KeyResultTypeChildOrderForParentArgs = {
  parentId: Scalars['String']['input'];
};


/** Key Result Type */
export type KeyResultTypeOkrTreeChildrenCountArgs = {
  types?: InputMaybe<Array<OkrTypeEnum>>;
};


/** Key Result Type */
export type KeyResultTypeParentOkRsArgs = {
  fetchAll?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Key Result Type */
export type KeyResultTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type KeyResultsSetInput = {
  /** input to update key-result */
  set: Array<UpdateKeyResultInput>;
};

export type LanguageConfigType = {
  __typename?: 'LanguageConfigType';
  languageTag?: Maybe<SingleSelectTagType>;
  languageTagId?: Maybe<Scalars['String']['output']>;
};

/** Extended ScoreDetailsType to include reviewCycle title */
export type LatestScoreDetailsPayload = {
  __typename?: 'LatestScoreDetailsPayload';
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** user who submitted the assignment */
  reviewCycleId?: Maybe<Scalars['String']['output']>;
  reviewCycleName?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

/** LatestSkillGroupReviewType Schema */
export type LatestSkillGroupReviewType = {
  __typename?: 'LatestSkillGroupReviewType';
  expectedScore: Scalars['Float']['output'];
  /** source responsibilityId of the skillGroup */
  orgRoleId?: Maybe<Scalars['String']['output']>;
  reviews: Array<LatestSkillReviewType>;
  score: Scalars['Float']['output'];
  /** user who submitted the assignment */
  skillGroupId: Scalars['String']['output'];
  /** source skillMatrixId of the skillGroup */
  skillMatrixId?: Maybe<Scalars['String']['output']>;
};

/** payload for latestSkillReviewDetails field resolver */
export type LatestSkillReviewDetailsPayload = {
  __typename?: 'LatestSkillReviewDetailsPayload';
  expectedScore: Scalars['Float']['output'];
  score: Scalars['Float']['output'];
  scoreDetails: Array<LatestScoreDetailsPayload>;
  skillId: Scalars['String']['output'];
};

/** LatestSkillReviewType Schema */
export type LatestSkillReviewType = {
  __typename?: 'LatestSkillReviewType';
  expectedScore: Scalars['Float']['output'];
  /** source responsibilityId of skill/mindset/microSkill */
  orgRoleId?: Maybe<Scalars['String']['output']>;
  /** parent skill ids for a microskill */
  parentSkillIds?: Maybe<Array<Scalars['String']['output']>>;
  score: Scalars['Float']['output'];
  scoreDetails: Array<ScoreDetailsType>;
  /** user who submitted the assignment */
  skillId: Scalars['String']['output'];
  /** source skillMatrixId of the skillGroup */
  skillMatrixId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

/** LatestUserReviewType Schema */
export type LatestUserReviewType = {
  __typename?: 'LatestUserReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** latest skillReviewDetails for given skill ids [for skill detail page] */
  latestSkillReviewDetails?: Maybe<Array<LatestSkillReviewDetailsPayload>>;
  /** latest microskills scores for user */
  microSkills?: Maybe<Array<LatestSkillReviewType>>;
  mindsets: Array<LatestSkillReviewType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroups: Array<LatestSkillGroupReviewType>;
  /** deprecated: moved under each LatestSkillGroupReviewType and LatestSkillReviewType to support multiple responsibilities */
  skillMatrixId?: Maybe<Scalars['String']['output']>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


/** LatestUserReviewType Schema */
export type LatestUserReviewTypeLatestSkillReviewDetailsArgs = {
  filters: LatestUserSkillReviewInput;
};

export type LatestUserSkillReviewInput = {
  skillIds: Array<Scalars['String']['input']>;
};

export type LeafPageComponentPreviewType = {
  __typename?: 'LeafPageComponentPreviewType';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pageComponentType: PageComponentTypeEnum;
  props?: Maybe<Scalars['String']['output']>;
  stringifiedWidget?: Maybe<Scalars['String']['output']>;
  widget?: Maybe<Scalars['JSON']['output']>;
  widgetPreview?: Maybe<WidgetPreviewUnion>;
};

export type LeafPageComponentType = {
  __typename?: 'LeafPageComponentType';
  calculateSecondaryOrder?: Maybe<Scalars['Boolean']['output']>;
  /** description */
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String']['output'];
  primaryOrder?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  secondaryOrder: Scalars['Float']['output'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** type of the page component */
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']['output']>;
};

export type LearnerActivityMetricsInput = {
  userId: Scalars['String']['input'];
};

export enum LearnerExportFieldEnum {
  Department = 'DEPARTMENT',
  Email = 'EMAIL',
  FullName = 'FULL_NAME',
  LearnerId = 'LEARNER_ID',
  OrgRole = 'ORG_ROLE',
  ReportsTo = 'REPORTS_TO',
  Status = 'STATUS',
  UniqueId = 'UNIQUE_ID'
}

export type LearnerExportInputPropsInput = {
  filters: UsersFilters;
  requiredFields: LearnerExportRequiredFieldsInput;
};

export type LearnerExportInputPropsType = {
  __typename?: 'LearnerExportInputPropsType';
  filters: UsersFiltersType;
  requiredFields: LearnerExportRequiredFieldsType;
};

export type LearnerExportMetadataType = {
  __typename?: 'LearnerExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type LearnerExportRequiredFieldsInput = {
  fields: Array<LearnerExportFieldEnum>;
  tagIds: Array<Scalars['String']['input']>;
};

export type LearnerExportRequiredFieldsType = {
  __typename?: 'LearnerExportRequiredFieldsType';
  fields: Array<LearnerExportFieldEnum>;
  tagIds: Array<Scalars['String']['output']>;
};

export type LearnerImportMetadataType = {
  __typename?: 'LearnerImportMetadataType';
  createdUsersCount: Scalars['Float']['output'];
  deactivatedUsersCount?: Maybe<Scalars['Float']['output']>;
  updatedUsersCount: Scalars['Float']['output'];
};

export enum LearningTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  SelfLearning = 'SELF_LEARNING'
}

export type LessonMetaType = {
  __typename?: 'LessonMetaType';
  /** url of the html */
  htmlUrl: Scalars['String']['output'];
  /** page blockId of newly created page block */
  pageBlockId: Scalars['String']['output'];
  /** ref blockId of newly created page block */
  refBlockId: Scalars['String']['output'];
};

export type LibraryConfigType = {
  __typename?: 'LibraryConfigType';
  additionalFilters?: Maybe<Array<GlobalSearchAndLibraryFilterTypeEnum>>;
  allowedContentTypes?: Maybe<Array<SearchableContentTypeEnum>>;
  featuredTagIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type LinkPropsInput = {
  /** label for the link */
  label: Scalars['String']['input'];
  /** external url */
  url: Scalars['String']['input'];
};

export type LinkType = {
  __typename?: 'LinkType';
  /** label for the link */
  label: Scalars['String']['output'];
  /** external url */
  url: Scalars['String']['output'];
};

export type ListItemType = {
  __typename?: 'ListItemType';
  description?: Maybe<Scalars['String']['output']>;
  /** Defines a list of actions to be performed for different userInteractions performed on the widget */
  eventActions?: Maybe<Array<WidgetInteractionEventActionType>>;
  heading: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  isChecked: Scalars['Boolean']['output'];
  redirectPath?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ListItemsWidgetPropsInput = {
  listItemTypesToUpdate: Array<Scalars['String']['input']>;
  widgetSubType?: InputMaybe<ListItemsWidgetSubTypeEnum>;
};

export enum ListItemsWidgetSubTypeEnum {
  PrimaryOnboardingItems = 'PRIMARY_ONBOARDING_ITEMS',
  SecondaryOnboardingItems = 'SECONDARY_ONBOARDING_ITEMS'
}

/** For Powering Data In Any Type Of List Items */
export type ListItemsWidgetType = {
  __typename?: 'ListItemsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  listItems: Array<ListItemType>;
  progress: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  subType?: Maybe<ListItemsWidgetSubTypeEnum>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ListSectionDisplayComponentType = {
  __typename?: 'ListSectionDisplayComponentType';
  /** heading for the section */
  heading?: Maybe<Scalars['String']['output']>;
  /** image urls */
  items: Array<ListSectionItemType>;
  type: DisplayComponentTypeEnum;
};

export type ListSectionItemType = {
  __typename?: 'ListSectionItemType';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentType = {
  __typename?: 'LiveEventContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  event?: Maybe<EventUnion>;
  eventRecurringCategory?: Maybe<RecurringTypeEnum>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEventId?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String']['output'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type LiveEventProps = {
  /** Livestream provider. Can be provided while using webinar subType. */
  provider?: InputMaybe<LivestreamProviderEnum>;
  recordingSettings: RecordingSettingsInput;
  subType?: InputMaybe<EventSubTypeEnum>;
};

export type LiveEventType = {
  __typename?: 'LiveEventType';
  /** actual ending time */
  actualEndTime?: Maybe<Scalars['String']['output']>;
  /** actual start time */
  actualStartTime?: Maybe<Scalars['String']['output']>;
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']['output']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String']['output'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discussionChannel: ChannelType;
  discussionChannelId: Scalars['String']['output'];
  documentRootBlockId: Scalars['String']['output'];
  duration?: Maybe<Scalars['Float']['output']>;
  /** user provided end time in UTC */
  endTime: Scalars['String']['output'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  firstRegisteredSlotId?: Maybe<Scalars['String']['output']>;
  flexiblePermissionGroupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructorIds: Array<Scalars['String']['output']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isRevokedAfterCompletion: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean']['output'];
  isUserRegistered: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  latestUpcomingEventId?: Maybe<Scalars['String']['output']>;
  latestUpcomingEventStartTime?: Maybe<Scalars['String']['output']>;
  /** liveStream info */
  livestreamInfo?: Maybe<LivestreamInfo>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEvent?: Maybe<EventUnion>;
  /** stores the id of parent recurring session wrapper */
  parentEventId?: Maybe<Scalars['String']['output']>;
  postWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  recordingSettings: RecordingSettings;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']['output']>>;
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** depicts the subtype for session */
  recurringCategory?: Maybe<RecurringTypeEnum>;
  recurringEventSlotCount?: Maybe<Scalars['Float']['output']>;
  registeredEventsCount?: Maybe<Scalars['Float']['output']>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']['output']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float']['output'];
  remainingPreWorkContents: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String']['output'];
  status: EventStatusEnum;
  subType: EventSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String']['output'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float']['output'];
  type: EventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type LiveEventTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type LiveEventTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type LiveEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type LiveEventTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type LivestreamInfo = {
  __typename?: 'LivestreamInfo';
  broadcastId: Scalars['String']['output'];
  livestreamId: Scalars['String']['output'];
  livestreamUrl: Scalars['String']['output'];
  provider: LivestreamProviderEnum;
  rtmpsIngestionAddress: Scalars['String']['output'];
};

export enum LivestreamProviderEnum {
  Ant = 'ANT',
  Youtube = 'YOUTUBE'
}

/** Login Configurations for Workspace Integration */
export type LoginConfig = {
  __typename?: 'LoginConfig';
  IDPEntityId?: Maybe<Scalars['String']['output']>;
  IDPInitiated?: Maybe<Scalars['Boolean']['output']>;
  IDPName?: Maybe<Scalars['String']['output']>;
  IdpTokenVerificationConfig?: Maybe<IdpTokenVerificationConfig>;
  SPEntityId?: Maybe<Scalars['String']['output']>;
  SPInitiated?: Maybe<Scalars['Boolean']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  failureRedirectionURL?: Maybe<Scalars['String']['output']>;
  issueId?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  onFailure?: Maybe<LoginFailureConfig>;
  onSuccess?: Maybe<LoginSuccessConfig>;
  secret?: Maybe<Scalars['String']['output']>;
  successRedirectionURL?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Configurations when Login fails */
export type LoginFailureConfig = {
  __typename?: 'LoginFailureConfig';
  redirect?: Maybe<Scalars['Boolean']['output']>;
};

/** Configurations when Login succeeds */
export type LoginSuccessConfig = {
  __typename?: 'LoginSuccessConfig';
  createUser?: Maybe<Scalars['Boolean']['output']>;
  redirect?: Maybe<Scalars['Boolean']['output']>;
  sendInvitationMail?: Maybe<Scalars['Boolean']['output']>;
  syncTagValue?: Maybe<Scalars['Boolean']['output']>;
  updateUser?: Maybe<Scalars['Boolean']['output']>;
};

export type LongLastingProcessMetaDataType = {
  __typename?: 'LongLastingProcessMetaDataType';
  parentProcessName: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
};

export type LookUpActionItemValuesPayload = {
  __typename?: 'LookUpActionItemValuesPayload';
  statusIds: Array<Scalars['String']['output']>;
  statuses?: Maybe<Array<StatusType>>;
};

export type LowSeatCountRuleInput = {
  delaySeconds: Scalars['Float']['input'];
  lowSeatCountThreshold: Scalars['Float']['input'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type LowSeatCountRuleType = {
  __typename?: 'LowSeatCountRuleType';
  delaySeconds: Scalars['Float']['output'];
  lowSeatCountThreshold: Scalars['Float']['output'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

/** This is used for match the following */
export type MtfComponent = {
  __typename?: 'MTFComponent';
  distractedRightElements?: Maybe<Array<MtfElement>>;
  leftElements: Array<MtfElement>;
  rightElements: Array<MtfElement>;
};

/** This is used for match the following */
export type MtfComponentInput = {
  leftElements: Array<MtfElementInput>;
  pairings: Array<MtfPairingInput>;
  rightElements: Array<MtfElementInput>;
};

/** Match the following element, used for single entry in match the following arrays. */
export type MtfElement = {
  __typename?: 'MTFElement';
  attachments?: Maybe<Array<EntityMediaType>>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Element for match the following, can be right or left side of match */
export type MtfElementInput = {
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

/** Pair provides mapping for match the following type questions */
export type MtfPairing = {
  __typename?: 'MTFPairing';
  leftElementId: Scalars['String']['output'];
  rightElementIds: Array<Scalars['String']['output']>;
};

/** Answer for Match the following */
export type MtfPairingInput = {
  leftElementIndex: Scalars['Float']['input'];
  rightElementIndices: Array<Scalars['Float']['input']>;
};

/** Used when response type is match the following */
export type MtfResponse = {
  __typename?: 'MTFResponse';
  leftElementId: Scalars['String']['output'];
  rightElementIds: Array<Scalars['String']['output']>;
};

/** Used when response type is match the following */
export type MtfResponseInput = {
  leftElementId: Scalars['String']['input'];
  rightElementIds: Array<Scalars['String']['input']>;
};

/** This is the input for submission of Exercise! */
export type MakeExerciseSubmissionInput = {
  contentType?: InputMaybe<ResourceTypeEnum>;
  entityId: Scalars['String']['input'];
  isActualSubmit: Scalars['Boolean']['input'];
  lessonPageId?: InputMaybe<Scalars['String']['input']>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  questionResponses?: InputMaybe<Array<ExerciseQuestionResponseInput>>;
  submissionId?: InputMaybe<Scalars['String']['input']>;
};

/** Make Exercise Submission Response */
export type MakeExerciseSubmissionPayload = {
  __typename?: 'MakeExerciseSubmissionPayload';
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<ExercisePerformanceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ManageUserSurveyQuestionsInput = {
  questionConfigs?: InputMaybe<Array<SurveyQuestionConfigInput>>;
  surveyId: Scalars['String']['input'];
};

export type ManageUserSurveyQuestionsPayload = {
  __typename?: 'ManageUserSurveyQuestionsPayload';
  userSurveyQuestions?: Maybe<Array<Maybe<UserSurveyQuestionType>>>;
};

/** Manual metric column config */
export type ManualMetricColumnConfig = {
  __typename?: 'ManualMetricColumnConfig';
  columnId: Scalars['String']['output'];
  fieldType: ManualMetricColumnFieldTypeEnum;
  isOptional: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  type: ManualMetricColumnTypeEnum;
};

export type ManualMetricColumnConfigInput = {
  columnId?: InputMaybe<Scalars['String']['input']>;
  fieldType: ManualMetricColumnFieldTypeEnum;
  isOptional: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Float']['input'];
  type: ManualMetricColumnTypeEnum;
};

export enum ManualMetricColumnFieldTypeEnum {
  Date = 'DATE',
  Number = 'NUMBER',
  Text = 'TEXT'
}

export enum ManualMetricColumnTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT'
}

export type ManualMetricConfigInput = {
  columnConfigs: Array<ManualMetricColumnConfigInput>;
};

export type ManualMetricConfigType = {
  __typename?: 'ManualMetricConfigType';
  columnConfigs: Array<ManualMetricColumnConfig>;
};

export type MarkEventAttendanceInput = {
  eventId: Scalars['String']['input'];
  userAttendances: Array<OfflineEventUserAttendanceInput>;
};

export type MarkEventAttendancePayload = {
  __typename?: 'MarkEventAttendancePayload';
  event?: Maybe<EventUnion>;
  /** EventId of the mark event attendance input */
  eventId?: Maybe<Scalars['String']['output']>;
  performances?: Maybe<Array<ContentPerformanceEntity>>;
  success: Scalars['Boolean']['output'];
};

/** input for marking submission as feature */
export type MarkSubmissionAsFeatureInput = {
  entityId: Scalars['String']['input'];
  isFeatured: Scalars['Boolean']['input'];
  submissionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** payload for marking submission as feature */
export type MarkSubmissionAsFeaturePayload = {
  __typename?: 'MarkSubmissionAsFeaturePayload';
  /** Error code */
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<ContentPerformanceEntity>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MarketplaceContentCategoriesFilters = {
  marketplaceContentCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Edge */
export type MarketplaceContentCategoryEdge = {
  __typename?: 'MarketplaceContentCategoryEdge';
  node: MarketplaceContentCategoryType;
};

export type MarketplaceContentCategoryType = {
  __typename?: 'MarketplaceContentCategoryType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Edge */
export type MarketplaceContentEdge = {
  __typename?: 'MarketplaceContentEdge';
  node: MarketplaceContentType;
};

export type MarketplaceContentType = {
  __typename?: 'MarketplaceContentType';
  /** categories in which marketplace content lies */
  categories: Array<MarketplaceContentCategoryType>;
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  contentType: MarketplaceContentTypeEnum;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  /** indicates if this content is already imported to the current workspace */
  isAddedToCurrentWorkspace: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  objective?: Maybe<Scalars['String']['output']>;
  /** provider for marketplace content */
  provider?: Maybe<CommonProviderType>;
  providerId: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum MarketplaceContentTypeEnum {
  Article = 'ARTICLE',
  Assessment = 'ASSESSMENT',
  Book = 'BOOK',
  Course = 'COURSE',
  Podcast = 'PODCAST',
  ResearchPaper = 'RESEARCH_PAPER',
  Video = 'VIDEO'
}

export type MarketplaceContentsFilters = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypes?: InputMaybe<Array<MarketplaceContentTypeEnum>>;
  marketplaceContentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  providerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type MarketplaceContentsWidgetType = {
  __typename?: 'MarketplaceContentsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** marketplace contents */
  marketplaceContents: PaginatedMarketplaceContentsPayload;
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** static content ids */
  staticIds?: Maybe<Array<Scalars['String']['output']>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type MarketplaceContentsWidgetTypeMarketplaceContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MarketplaceContentsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};

export enum MeasureAggregatorApplicableOnEnum {
  Self = 'SELF',
  Team = 'TEAM'
}

export enum MeasureAggregatorEnum {
  Avg = 'AVG',
  AvgBucket = 'AVG_BUCKET',
  Count = 'COUNT',
  CountDistinct = 'COUNT_DISTINCT',
  CumulativeSum = 'CUMULATIVE_SUM',
  Max = 'MAX',
  Min = 'MIN',
  None = 'NONE',
  Script = 'SCRIPT',
  Sum = 'SUM',
  SumBucket = 'SUM_BUCKET',
  Top = 'TOP'
}

export type MeasureConfigType = {
  __typename?: 'MeasureConfigType';
  dependentFilters?: Maybe<FilterGroupType>;
  measure: Scalars['String']['output'];
  topHitsConfigs?: Maybe<TopHitsConfigs>;
};

export type MeasureConfigTypeInput = {
  dependentFilters?: InputMaybe<FilterGroupInput>;
  measure: Scalars['String']['input'];
  percentiles?: InputMaybe<Array<Scalars['Float']['input']>>;
  topHitsConfigs?: InputMaybe<TopHitsConfigsInput>;
};

export type MeasureType = {
  __typename?: 'MeasureType';
  aggregateUpto?: Maybe<Scalars['String']['output']>;
  aggregator: MeasureAggregatorEnum;
  applicableOn?: Maybe<MeasureAggregatorApplicableOnEnum>;
  field: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type MeasureTypeInput = {
  aggregateUpto?: InputMaybe<Scalars['String']['input']>;
  aggregator: MeasureAggregatorEnum;
  applicableOn?: InputMaybe<MeasureAggregatorApplicableOnEnum>;
  field: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Media Input */
export type Media = {
  /** Media URL */
  resolutions: Array<ResolutionInputType>;
  /** Media type. type can be an image or video. */
  type: MediaTypeEnum;
  /** Media URL */
  url: Scalars['String']['input'];
};

export enum MediaAccessClassEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum MediaExportFormatEnum {
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum MediaKindEnum {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  File = 'FILE',
  Folder = 'FOLDER',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebLink = 'WEB_LINK',
  YoutubeLink = 'YOUTUBE_LINK'
}

/** Media Location */
export type MediaLocation = {
  __typename?: 'MediaLocation';
  bucket?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  type: MediaLocationTypeEnum;
};

export type MediaLocationInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  destination?: InputMaybe<Scalars['String']['input']>;
  type: MediaLocationTypeEnum;
};

export enum MediaLocationTypeEnum {
  AwsS3 = 'AWS_S3',
  External = 'EXTERNAL',
  GcpCloudStorage = 'GCP_CLOUD_STORAGE'
}

export enum MediaTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

/** Media url type */
export type MediaUrl = {
  __typename?: 'MediaUrl';
  fileName?: Maybe<Scalars['String']['output']>;
  /** media type of uploaded entity */
  format: Scalars['String']['output'];
  mediaId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type MediaUrlInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** media type of uploaded entity */
  format: Scalars['String']['input'];
  mediaId?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type MediasSetInput = {
  /** Medias not in this list would be deleted and new medias in this list will be added */
  set: Array<CreateMediaInput>;
};

/** Edge */
export type MeetingEdge = {
  __typename?: 'MeetingEdge';
  node: MeetingType;
};

export type MeetingFilters = {
  meetingId: Scalars['String']['input'];
};

/** Edge */
export type MeetingInstanceEdge = {
  __typename?: 'MeetingInstanceEdge';
  node: MeetingInstanceType;
};

export type MeetingInstanceFilters = {
  meetingInstanceId: Scalars['String']['input'];
};

export enum MeetingInstanceOccurrenceTypeEnum {
  Oneoff = 'ONEOFF',
  ViaSchedule = 'VIA_SCHEDULE'
}

export type MeetingInstancePayload = {
  __typename?: 'MeetingInstancePayload';
  meetingInstance?: Maybe<MeetingInstanceType>;
};

export type MeetingInstancePrivateNote = {
  __typename?: 'MeetingInstancePrivateNote';
  note?: Maybe<InstructionType>;
  userId: Scalars['String']['output'];
};

export enum MeetingInstanceStatusEnum {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
  Late = 'LATE',
  Missed = 'MISSED',
  Scheduled = 'SCHEDULED'
}

export type MeetingInstanceSubscriptionInput = {
  /** ids of meetings instances */
  meetingInstanceIds: Array<Scalars['String']['input']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<MeetingInstanceSubscriptionOperationEnum>>;
};

export enum MeetingInstanceSubscriptionOperationEnum {
  AgendaItemCreated = 'AGENDA_ITEM_CREATED',
  AgendaItemDeleted = 'AGENDA_ITEM_DELETED',
  AgendaItemDuplicated = 'AGENDA_ITEM_DUPLICATED',
  MeetingInstanceUpdated = 'MEETING_INSTANCE_UPDATED'
}

export type MeetingInstanceSubscriptionPayload = {
  __typename?: 'MeetingInstanceSubscriptionPayload';
  agendaItem?: Maybe<AgendaItemType>;
  meetingInstance?: Maybe<MeetingInstanceType>;
  operation: MeetingInstanceSubscriptionOperationEnum;
};

export type MeetingInstanceSummaryInput = {
  agendaItems?: InputMaybe<Array<GenerateSummaryAgendaItemsInput>>;
};

export type MeetingInstanceSummaryResultType = {
  __typename?: 'MeetingInstanceSummaryResultType';
  text: Scalars['String']['output'];
};

export enum MeetingInstanceSummaryStatusEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Ongoing = 'ONGOING'
}

export type MeetingInstanceSummaryType = {
  __typename?: 'MeetingInstanceSummaryType';
  createdAt: Scalars['String']['output'];
  generativeTaskId: Scalars['String']['output'];
  status: MeetingInstanceSummaryStatusEnum;
  text?: Maybe<Scalars['String']['output']>;
};

export type MeetingInstanceType = {
  __typename?: 'MeetingInstanceType';
  /** field resolvers */
  actionItemsCount: Scalars['Float']['output'];
  actualEndTime?: Maybe<Scalars['String']['output']>;
  /** timestamp when meetingInstance was actually marked IN_PROGRESS */
  actualStartTime?: Maybe<Scalars['String']['output']>;
  agendaItemIds?: Maybe<Array<Scalars['String']['output']>>;
  agendaItems: Array<AgendaItemType>;
  agendaItemsCount: Scalars['Float']['output'];
  bannerStatus?: Maybe<BannerStatusEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultGroupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  meetingId: Scalars['String']['output'];
  occurrenceType: MeetingInstanceOccurrenceTypeEnum;
  organizerId: Scalars['String']['output'];
  participantIds: Array<Scalars['String']['output']>;
  participantsProfile?: Maybe<Array<UserProfile>>;
  privateNote?: Maybe<MeetingInstancePrivateNote>;
  /** deprecated: required for meetingInstances of 1on1 created before agenda feature */
  rootBlockId?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime: Scalars['String']['output'];
  status: PublicMeetingInstanceStatusEnum;
  summary?: Maybe<MeetingInstanceSummaryType>;
  summaryRegenerationRequired: Scalars['Boolean']['output'];
  type: MeetingInstanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum MeetingInstanceTypeEnum {
  OneOnOne = 'ONE_ON_ONE',
  Team = 'TEAM'
}

export type MeetingInstancesFilters = {
  meetingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  meetingInstanceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  occurrenceType?: InputMaybe<Array<MeetingInstanceOccurrenceTypeEnum>>;
  participantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<PublicMeetingInstanceStatusEnum>>;
  type?: InputMaybe<Array<MeetingInstanceTypeEnum>>;
};

export type MeetingInstancesWidgetType = {
  __typename?: 'MeetingInstancesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** upcoming meetings instances */
  meetingInstances: PaginatedMeetingInstancesPayload;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type MeetingInstancesWidgetTypeMeetingInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type MeetingPayload = {
  __typename?: 'MeetingPayload';
  meeting?: Maybe<MeetingType>;
};

/** Additional information related to the meeting report */
export type MeetingReport = {
  __typename?: 'MeetingReport';
  teamInsightId?: Maybe<Scalars['String']['output']>;
};

export type MeetingSubscriptionInput = {
  /** ids of meetings */
  meetingIds: Array<Scalars['String']['input']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<MeetingSubscriptionOperationEnum>>;
};

export enum MeetingSubscriptionOperationEnum {
  TemplateUpdated = 'TEMPLATE_UPDATED'
}

export type MeetingSubscriptionPayload = {
  __typename?: 'MeetingSubscriptionPayload';
  meeting: MeetingType;
  operation: MeetingSubscriptionOperationEnum;
};

export type MeetingType = {
  __typename?: 'MeetingType';
  attachments?: Maybe<Array<EntityMediaType>>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** All okrIds to be excluded */
  excludedOkrIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  includeChildObjectives?: Maybe<Scalars['Boolean']['output']>;
  insightsReport?: Maybe<ReportType>;
  lastOccurrenceDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextInstanceAgendaItemIds?: Maybe<Array<Scalars['String']['output']>>;
  nextOccurrenceDate: Scalars['String']['output'];
  noteBlockId?: Maybe<Scalars['String']['output']>;
  okrIds?: Maybe<Array<Scalars['String']['output']>>;
  /** okrIds without children included */
  okrIdsWithoutChildren?: Maybe<Array<Scalars['String']['output']>>;
  okrProgress: Scalars['Float']['output'];
  oneOnOneReportee?: Maybe<Scalars['String']['output']>;
  organizerId: Scalars['String']['output'];
  participantIds: Array<Scalars['String']['output']>;
  participants?: Maybe<Array<UserType>>;
  participantsCount?: Maybe<Scalars['Float']['output']>;
  participantsProfile: Array<UserProfile>;
  /** All the things which are related to meetings */
  relatedToEntities?: Maybe<Array<Scalars['String']['output']>>;
  report?: Maybe<MeetingReport>;
  sampleParticipantsProfile: Array<UserProfile>;
  schedule: ScheduleInfo;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: PublicMeetingStatusEnum;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  templateId?: Maybe<Scalars['String']['output']>;
  type: MeetingTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
};

export enum MeetingTypeEnum {
  OneOnOne = 'ONE_ON_ONE',
  Team = 'TEAM'
}

export type MeetingsFilters = {
  agendaItemTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  childObjective?: InputMaybe<IncludeChildObjectivesEnum>;
  meetingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  nextOccurrenceDate?: InputMaybe<DateFilter>;
  okrIds?: InputMaybe<Array<Scalars['String']['input']>>;
  relatedToActionPlans?: InputMaybe<RelatedActionPlanEntityInput>;
  status?: InputMaybe<Array<PublicMeetingStatusEnum>>;
  type?: InputMaybe<Array<MeetingTypeEnum>>;
};

export type MeetingsWidgetType = {
  __typename?: 'MeetingsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** upcoming meetings */
  meetings: PaginatedMeetingsPayload;
  returnOne: Scalars['Boolean']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type MeetingsWidgetTypeMeetingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

/** It will resolve user or group for mentioned entity */
export type MentionEntity = UserCollectionGroupType | UserType;

export enum MentionEntityTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Group = 'GROUP',
  User = 'USER'
}

export type MentionFilters = {
  /** search Text to search */
  searchText: Scalars['String']['input'];
  types: Array<EntityTypeEnum>;
};

export type MentionInputType = {
  /** EntityId whoever/whichever is mentioned  */
  entityId: Scalars['String']['input'];
  /** EntityType like user, group etc */
  entityType: MentionEntityTypeEnum;
  /** Handles preview visibility on the post */
  previewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MentionSearchPayload = {
  __typename?: 'MentionSearchPayload';
  contents?: Maybe<ContentConnection>;
  events?: Maybe<PaginatedEventsPayload>;
  users?: Maybe<UserConnection>;
};


export type MentionSearchPayloadContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type MentionSearchPayloadEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<EventsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type MentionSearchPayloadUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UsersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};

/** Mentions on a post */
export type MentionType = {
  __typename?: 'MentionType';
  channelId?: Maybe<Scalars['String']['output']>;
  contentMention?: Maybe<ContentEntity>;
  entityId: Scalars['String']['output'];
  entityMention?: Maybe<MentionEntity>;
  entityType: MentionEntityTypeEnum;
  eventMention?: Maybe<EventUnion>;
  /** Handles preview visibility on the post */
  previewEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type MentionsSetInput = {
  /** Mentions like @user (Pass mentions:[] incase of no mentions */
  set: Array<MentionInputType>;
};

export type MergeFileInput = {
  destinationFileId: Scalars['String']['input'];
  fileIdToMerge: Scalars['String']['input'];
};

export type MergeFilePayload = {
  __typename?: 'MergeFilePayload';
  destinationFile?: Maybe<FileSchema>;
  oldParent?: Maybe<FileSchema>;
};

/** Edge */
export type MetricEdge = {
  __typename?: 'MetricEdge';
  node: MetricSchema;
};

export type MetricPayload = {
  __typename?: 'MetricPayload';
  metric?: Maybe<MetricSchema>;
};

export type MetricSchema = {
  __typename?: 'MetricSchema';
  /** returns the count of activity metrics attached to it */
  activityMetricCount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<EntityMediaType>>;
  automaticType?: Maybe<AutomaticMetricTypeEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Salesforce -> dataObject */
  dataObject?: Maybe<Scalars['String']['output']>;
  defaultTargetFrequency?: Maybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: Maybe<Scalars['Float']['output']>;
  defaultTargetValueUnit?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedById?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Underlying chart widgets */
  entityCount?: Maybe<Scalars['Float']['output']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  integration?: Maybe<IntegrationProviderType>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  /** returns the latest target value of a habit for the provided date */
  latestTargetValue?: Maybe<Scalars['Float']['output']>;
  /** count of activity metrics under learner responsibilities attached to the metric */
  learnerActivityMetricCount?: Maybe<Scalars['Float']['output']>;
  manualMetricConfig?: Maybe<ManualMetricConfigType>;
  /** measurementUnit, salesforce -> displayAs */
  measurementUnit: KeyResultMetricEnum;
  /** Salesforce -> filters */
  metricType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  queryConfig?: Maybe<MetricSyncQueryConfig>;
  /** Time reminders on days based on repeatSchedule */
  reminders?: Maybe<Array<Scalars['String']['output']>>;
  /** Schedule for habit type metrics */
  repeatSchedule?: Maybe<ScheduleInfo>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Current status of metric */
  status: MetricStatusEnum;
  syncConfig: MetricSyncConfig;
  /** Type of metric */
  type: MetricTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Required to track user habits */
  userId?: Maybe<Scalars['String']['output']>;
  /** returns the progress of a user on habit for the provided date */
  userProgress?: Maybe<Scalars['Float']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type MetricSchemaLatestTargetValueArgs = {
  filters: DateFilter;
};


export type MetricSchemaLearnerActivityMetricCountArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MetricSchemaUserProgressArgs = {
  filters: DateFilter;
};

export type MetricSnapshot = {
  __typename?: 'MetricSnapshot';
  currentTime: Scalars['String']['output'];
};

export enum MetricStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

/** Metric sync config */
export type MetricSyncConfig = {
  __typename?: 'MetricSyncConfig';
  integrationId?: Maybe<Scalars['String']['output']>;
  type: MetricSyncTypeEnum;
};

export type MetricSyncConfigInputType = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  type: MetricSyncTypeEnum;
};

/** Metric sync query config */
export type MetricSyncQueryConfig = {
  __typename?: 'MetricSyncQueryConfig';
  /** Metric grouped upon, mostly ownerId/userId */
  dimensions: Array<Scalars['String']['output']>;
  /** All the conditions on which the metric will be synced */
  filterGroup: FilterGroupType;
  /** Aggregation on the field */
  measures: Array<MeasureType>;
  /** Table/Collection from which metric will be synced */
  source: Scalars['String']['output'];
};

export enum MetricSyncTypeEnum {
  Automatic = 'AUTOMATIC',
  Integration = 'INTEGRATION',
  Manual = 'MANUAL'
}

export enum MetricTypeEnum {
  Activity = 'ACTIVITY',
  Habit = 'HABIT'
}

export type MetricsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  integrationProviderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  measureField?: InputMaybe<Scalars['String']['input']>;
  measurementUnits?: InputMaybe<Array<KeyResultMetricEnum>>;
  metricIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  notInIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Metrics whose repeat schedule (falls/is valid) on this date */
  scheduledOn?: InputMaybe<DateFilter>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<MetricStatusEnum>>;
  type?: InputMaybe<MetricTypeEnum>;
  updationMethods?: InputMaybe<Array<MetricSyncTypeEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Product Metrics */
export type MetricsType = {
  __typename?: 'MetricsType';
  conversion?: Maybe<Scalars['Float']['output']>;
  earnings?: Maybe<Scalars['Float']['output']>;
  items?: Maybe<Scalars['Float']['output']>;
  learners?: Maybe<Scalars['Float']['output']>;
};

/** Input for combined updates for MicroSkills */
export type MicroSkillUpdateInput = {
  /** Input if operationType is CREATE */
  createInput?: InputMaybe<CreateSkillInput>;
  /** Input if operationType is DELETE */
  deleteInput?: InputMaybe<DeleteSkillInput>;
  operationType: SkillOperationEnum;
  /** Input if operationType is UPDATE */
  updateInput?: InputMaybe<UpdateSkillInput>;
};

export type MicrosoftIntegrationSettingsInput = {
  archiveTerminatedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  departmentIdsWithCustomLogic?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldMappings: Array<FieldMappingInput>;
  lastSyncedAt?: InputMaybe<Scalars['String']['input']>;
  syncJobId?: InputMaybe<Scalars['String']['input']>;
  syncOrgHierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  syncSchedule?: InputMaybe<ScheduleInfoInput>;
  userGroupIds: Array<Scalars['String']['input']>;
  usersImportType: UsersImportTypeEnum;
};

export type MicrosoftIntegrationSettingsType = {
  __typename?: 'MicrosoftIntegrationSettingsType';
  archiveNonGroupMembers?: Maybe<Scalars['Boolean']['output']>;
  archiveTerminatedUsers?: Maybe<Scalars['Boolean']['output']>;
  /** Department Ids for which we don't want to update the trackId */
  departmentIdsWithCustomLogic?: Maybe<Array<Scalars['String']['output']>>;
  fieldMappings: Array<FieldMappingType>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  scheduledJob?: Maybe<ScheduledJobConfigs>;
  syncJobId?: Maybe<Scalars['String']['output']>;
  syncOrgHierarchy?: Maybe<Scalars['Boolean']['output']>;
  syncSchedule?: Maybe<ScheduleInfo>;
  /** sync users job status */
  syncStatus?: Maybe<JobEntityStatusEnum>;
  userGroupIds: Array<Scalars['String']['output']>;
  usersImportType: UsersImportTypeEnum;
};

export type MicrosoftTeamsIntegrationConnectionInfoInput = {
  accountId: Scalars['String']['input'];
  userEmailId: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MicrosoftTeamsIntegrationConnectionInfoType = {
  __typename?: 'MicrosoftTeamsIntegrationConnectionInfoType';
  accountId: Scalars['String']['output'];
  userEmailId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type MicrosoftTeamsIntegrationSettingsInput = {
  connectionInfo: MicrosoftTeamsIntegrationConnectionInfoInput;
};

export type MicrosoftTeamsIntegrationSettingsType = {
  __typename?: 'MicrosoftTeamsIntegrationSettingsType';
  connectionInfo: MicrosoftTeamsIntegrationConnectionInfoType;
};

export type MissingParticipantsDataCheckCount = {
  __typename?: 'MissingParticipantsDataCheckCount';
  /** Field used by FE for local caching purpose */
  id: Scalars['String']['output'];
  missingParticipantsCount: Scalars['Float']['output'];
  name?: Maybe<DataCheckDefaultAttributeEnum>;
  tag?: Maybe<TagUnion>;
  tagId?: Maybe<Scalars['String']['output']>;
  type: DataCheckAttributeTypeEnum;
};

/** Edge */
export type MissingParticipantsDataCheckCountEdge = {
  __typename?: 'MissingParticipantsDataCheckCountEdge';
  node: MissingParticipantsDataCheckCount;
};

export type MissingParticipantsDataCheckCountsFilters = {
  applicableToEveryone?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType: DataCheckAttributeTypeEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ModifyEntitiesInGroupInput = {
  entityIds: Array<Scalars['String']['input']>;
  groupId: Scalars['String']['input'];
};

export type ModifyEntitiesInGroupPayload = {
  __typename?: 'ModifyEntitiesInGroupPayload';
  dynamicallyPresentEntityIds: Array<Scalars['String']['output']>;
};

export type ModifyTaskCreationConfigsInput = {
  command: OperationEnum;
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<TaskCreationEntityTypeEnum>;
  inputs: TaskCreationConfigInputProps;
  okrId?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['String']['input'];
};

export type ModifyTaskCreationConfigsPayload = {
  __typename?: 'ModifyTaskCreationConfigsPayload';
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<TaskCreationEntityTypeEnum>;
  file?: Maybe<FileSchema>;
  fileId?: Maybe<Scalars['String']['output']>;
  okr?: Maybe<OkrUnion>;
};

export enum ModifyUsersInEventCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type ModifyUsersInEventInput = {
  /** When assignment is made from assignments section, this will be sent from some service */
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  assignmentType?: InputMaybe<AssignmentTypeEnum>;
  command: ModifyUsersInEventCommandEnum;
  createAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  eventId: Scalars['String']['input'];
  selfEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type ModifyUsersInGroupInput = {
  groupId: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type ModifyUsersInGroupPayload = {
  __typename?: 'ModifyUsersInGroupPayload';
  dynamicallyPresentUserIds: Array<Scalars['String']['output']>;
  dynamicallyPresentUsers?: Maybe<Array<UserType>>;
};

export enum MonthOfYearEnum {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER'
}

export type MoveFileInput = {
  fileIdToMove: Scalars['String']['input'];
  newParentId?: InputMaybe<Scalars['String']['input']>;
  /** order in parent */
  orderInNewParent?: InputMaybe<Scalars['Float']['input']>;
};

export type MoveFilePayload = {
  __typename?: 'MoveFilePayload';
  newParent?: Maybe<FileSchema>;
  oldParent?: Maybe<FileSchema>;
};

export type MoveMeetingInstanceAgendaItemsInput = {
  agendaItemIds: Array<Scalars['String']['input']>;
  sourceInstanceId: Scalars['String']['input'];
  targetInstanceId?: InputMaybe<Scalars['String']['input']>;
};

/** input for changing okr cycle */
export type MoveOkrInput = {
  /** whether to move whole okr tree to new cycle or not */
  moveOKRSubTrees?: InputMaybe<Scalars['Boolean']['input']>;
  /** id of cycle to which new okr will be moved */
  moveToCycleId: Scalars['String']['input'];
  okrId: Scalars['String']['input'];
  /** new parent id of okr being moved. must belong to new cycle */
  updatedParentId?: InputMaybe<Scalars['String']['input']>;
  /** new parent ids of okr being moved. must belong to new cycle */
  updatedParentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MoveOkrPayload = {
  __typename?: 'MoveOKRPayload';
  /** moved okr after transition */
  movedOKR?: Maybe<OkrUnion>;
  /** old parent of okr being moved */
  oldParentOKR?: Maybe<OkrUnion>;
  /** old parents of okr being moved */
  oldParentOKRs?: Maybe<Array<OkrUnion>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MultiBannerPageComponentPreviewType = {
  __typename?: 'MultiBannerPageComponentPreviewType';
  components: Array<LeafPageComponentPreviewType>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pageComponentType: PageComponentTypeEnum;
  props?: Maybe<Scalars['String']['output']>;
  stringifiedWidget?: Maybe<Scalars['String']['output']>;
  widget?: Maybe<Scalars['JSON']['output']>;
};

export type MultiBannerPageComponentType = {
  __typename?: 'MultiBannerPageComponentType';
  calculateSecondaryOrder?: Maybe<Scalars['Boolean']['output']>;
  /** components of banner */
  components: Array<LeafPageComponentType>;
  /** description */
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String']['output'];
  primaryOrder?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  secondaryOrder: Scalars['Float']['output'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']['output']>;
};

export type MultiSelectFacetType = {
  __typename?: 'MultiSelectFacetType';
  applyOn: FacetApplyOnType;
  defaultValues?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isOptionLocalisationNeeded: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  options: Array<SelectFacetOption>;
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']['output']>;
  type: FacetTypeEnum;
};

export type MultiSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']['input']>;
  options: Array<SelectTagOptionInput>;
};

export type MultiSelectTagType = {
  __typename?: 'MultiSelectTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** If tag can have dynamic option values */
  ignoreOptions?: Maybe<Scalars['Boolean']['output']>;
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** number type tag specific fields */
  options: Array<SelectTagOptionType>;
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']['output']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** This denotes, whether we need to create a new dynamic group, when a new option is added inside single/multi select */
  syncGroup?: Maybe<Scalars['Boolean']['output']>;
  /** field resolvers */
  taggedContentCount: Scalars['Float']['output'];
  taggedEventCount: Scalars['Float']['output'];
  taggedOKRCount: Scalars['Float']['output'];
  taggedTaskCount: Scalars['Float']['output'];
  taggedUserCount: Scalars['Float']['output'];
  type: TagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type MultiSelectTagTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type MultiSelectTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addContentsToGroup?: Maybe<ModifyEntitiesInGroupPayload>;
  addExerciseSubmissionFeedback: AddExerciseSubmissionFeedbackPayload;
  addReaction?: Maybe<UpdateReactionPayload>;
  addRemoveQuizQuestions?: Maybe<MutationResponse>;
  addUserPushNotificationTokens: MutationResponse;
  addUserSearchActivity: AddUserSearchActivityPayload;
  addUsersInEvent?: Maybe<EventPayload>;
  addUsersToGroup?: Maybe<ModifyUsersInGroupPayload>;
  addUsersToStaffRoles: MutationResponse;
  archiveGroup?: Maybe<GroupUnion>;
  archiveJourney: JourneyPayload;
  archiveTag: TagUnion;
  archiveUserSurvey?: Maybe<ArchiveUserSurveyPayload>;
  assignContent: AssignContentPayload;
  attachActivityMetrics: AttachActivityMetricsPayload;
  bulkMarkEventAttendance: MarkEventAttendancePayload;
  cancelGenerativeTask?: Maybe<MutationResponse>;
  cancelJob: MutationResponse;
  changeDueDate: MutationResponse;
  checkinKeyResult?: Maybe<OkrPayload>;
  checkinObjective?: Maybe<OkrPayload>;
  closeUserSurvey?: Maybe<CloseUserSurveyPayload>;
  completeReviewCycleForManagerReportees: ReviewCyclePayload;
  convertMedia?: Maybe<ConvertMediaPayload>;
  createActionItem?: Maybe<ActionItemPayload>;
  createActionItems: ActionItemsPayload;
  createActionPlan: CreateActionPlanPayload;
  createAgendaItem?: Maybe<AgendaItemType>;
  createAgendaItemTemplate?: Maybe<AgendaItemTemplateType>;
  createAnnouncement: CreateAnnouncementPayload;
  createArticle: CreateArticlePayload;
  createBookmark: CreateBookmarkPayload;
  createChart?: Maybe<CreateChartPayload>;
  createCheckin: CreateCheckinPayload;
  createDatabaseView?: Maybe<CreateDatabaseViewPayload>;
  createDepartment: CreateDepartmentPayload;
  createEmailDigest?: Maybe<EmailDigestPayload>;
  createEvent?: Maybe<EventPayload>;
  createExercise: CreateExercisePayload;
  createExternalContent: CreateExternalContentPayload;
  createFeedback: FeedbackPayload;
  createFile?: Maybe<FilePayload>;
  createGenerativeTask?: Maybe<GenerativeTaskPayload>;
  createGroup: GroupUnion;
  createImportJob?: Maybe<JobType>;
  createJourney: JourneyPayload;
  createJourneyStep: CreateJourneyStepPayload;
  createJourneyStepTraineeSession: JourneyStepTraineeSessionType;
  createMeeting?: Maybe<MeetingType>;
  createMeetingInstance: MeetingInstancePayload;
  createMetric?: Maybe<MetricPayload>;
  createOKRCycle: CreateOkrCyclePayload;
  createObjective: CreateObjectivePayload;
  createOrgRole: CreateOrgRolePayload;
  createOutlookCalendarEvent: CreateOutlookCalendarEventPayload;
  createOutlookCalendarEventFromMeeting: CreateOutlookCalendarEventFromMeetingPayload;
  createPath: CreatePathPayload;
  createPeerFeedback?: Maybe<PeerFeedbackPayload>;
  createPost?: Maybe<CreatePostPayload>;
  createPraise: PraisePayload;
  createQuestions?: Maybe<CreateQuestionsPayload>;
  createQuiz: CreateQuizPayload;
  createReport?: Maybe<CreateReportPayload>;
  createResource: CreateResourcePayload;
  createReviewCycle: ReviewCyclePayload;
  createScorecard: CreateScorecardPayload;
  createScorm: ScormPayload;
  createSelfLearningContent: CreateExternalContentPayload;
  createSharedView: CreateSharedViewPayload;
  createSkill: SkillPayload;
  createSkillMatrix?: Maybe<SkillMatrixType>;
  createSquadChannel?: Maybe<CreateChannelPayload>;
  createStaffRole: RoleType;
  createSurvey: CreateSurveyPayload;
  createTag: TagUnion;
  createTask: CreateTaskPayload;
  createTheme: CreateThemePayload;
  createTrack: CreateTrackPayload;
  createUserCollectionGroup: UserCollectionGroupPayload;
  createUserPublicAPICredentials: CreateUserPublicApiCredentialsPayload;
  createUserSurvey?: Maybe<CreateUserSurveyPayload>;
  deleteActionItem?: Maybe<MutationResponse>;
  deleteActionPlan: DeleteActionPlanPayload;
  deleteAgendaItem: MutationResponse;
  deleteAgendaItemTemplate: MutationResponse;
  deleteBookmark: DeleteBookmarkPayload;
  deleteDepartment: MutationResponse;
  deleteEmailDigest?: Maybe<MutationResponse>;
  deleteEventPerformance: MutationResponse;
  deleteFile?: Maybe<MutationResponse>;
  deleteJourney: MutationResponse;
  deleteJourneyStep: MutationResponse;
  deleteMeeting: MutationResponse;
  deleteMeetingInstances: MutationResponse;
  deleteMetric?: Maybe<MutationResponse>;
  deleteOKR: DeleteOkrPayload;
  deleteOKRCycle: MutationResponse;
  deleteOrgRole: MutationResponse;
  deletePeerFeedback: MutationResponse;
  deletePost: MutationResponse;
  deletePraise?: Maybe<MutationResponse>;
  deleteReport?: Maybe<MutationResponse>;
  deleteResource: MutationResponse;
  deleteReviewCycle: DeleteReviewCyclePayload;
  deleteSharedView: DeleteSharedViewPayload;
  deleteSkill: DeleteSkillPayload;
  deleteSkillMatrix: DeleteSkillMatrixPayload;
  deleteSkillReview: MutationResponse;
  deleteStaffRole: MutationResponse;
  deleteTracks: MutationResponse;
  deleteUserActivityMetrics: MutationResponse;
  deleteUserSearchActivity: MutationResponse;
  deleteWidgets: MutationResponse;
  discardUnpublishedChanges: DiscardUnpublishedChangesPayload;
  disconnectIntegrationDetail: IntegrationDetailPayload;
  duplicateActionPlan: DuplicateActionPlanPayload;
  duplicateAgendaItem?: Maybe<AgendaItemType>;
  duplicateAgendaItemTemplate?: Maybe<AgendaItemTemplateType>;
  duplicateContent: DuplicateContentPayload;
  duplicateEvent?: Maybe<EventPayload>;
  duplicateGroup: GroupUnion;
  duplicateOKR?: Maybe<DuplicateOkrPayload>;
  duplicateReport?: Maybe<DuplicateReportPayload>;
  duplicateSkillMatrix?: Maybe<SkillMatrixType>;
  duplicateSurvey: DuplicateSurveyPayload;
  duplicateUserSurvey?: Maybe<DuplicateUserSurveyPayload>;
  editPost?: Maybe<EditPostPayload>;
  evaluateExerciseSubmission: EvaluateExerciseSubmissionPayload;
  eventSharingSelfEnrollCourse: MutationResponse;
  forceCompleteContentProgress: ForceCompleteContentProgressPayload;
  generateLearnerLink: GenerateLearnerLinkPayload;
  importContentFromMarketplace: ImportContentFromMarketplacePayload;
  importSkilTemplates: MutationResponse;
  importSkillMatrixTemplate?: Maybe<SkillMatrixType>;
  inviteExternalUsersForEntities: MutationResponse;
  inviteUser: UserType;
  joinEvent: MutationResponse;
  makeExerciseSubmission: MakeExerciseSubmissionPayload;
  manageUserSurveyQuestions?: Maybe<ManageUserSurveyQuestionsPayload>;
  markEventAttendance: MarkEventAttendancePayload;
  markSubmissionAsFeature: MarkSubmissionAsFeaturePayload;
  mergeFile?: Maybe<MergeFilePayload>;
  modifyTaskCreationConfigs?: Maybe<ModifyTaskCreationConfigsPayload>;
  modifyUsersInEvent?: Maybe<EventPayload>;
  moveFile?: Maybe<MoveFilePayload>;
  moveMeetingInstanceAgendaItems: MutationResponse;
  moveOKR?: Maybe<MoveOkrPayload>;
  nudgeReviewers: MutationResponse;
  publishArticle: PublishArticlePayload;
  publishAssignment: PublishAssignmentPayload;
  publishCourse: PublishCoursePayload;
  publishJourney: JourneyPayload;
  publishReviewCycle: ReviewCyclePayload;
  publishUserSurvey?: Maybe<PublishUserSurveyPayload>;
  readNotifications: MutationResponse;
  recalibrateContentProgress: MutationResponse;
  regenerateMeetingInstanceSummary: RegenerateMeetingInstanceSummaryPayload;
  registerEvent?: Maybe<EventPayload>;
  removeContentTag: ContentEntity;
  removeContentsFromGroup?: Maybe<ModifyEntitiesInGroupPayload>;
  removeDatabaseData?: Maybe<MutationResponse>;
  removeEntityAccess: EntityAccessPayload;
  removeEventTag: EventPayload;
  removeMarketplaceImportedContent: RemoveMarketplaceImportedContentPayload;
  removeOKRTag?: Maybe<OkrPayload>;
  removeQuestions?: Maybe<RemoveQuestionsPayload>;
  removeReaction?: Maybe<UpdateReactionPayload>;
  removeUserNomination?: Maybe<UserNominationPayload>;
  removeUserTag: UserType;
  removeUsersFromGroup?: Maybe<ModifyUsersInGroupPayload>;
  removeUsersFromStaff: MutationResponse;
  removeUsersFromStaffRoles: MutationResponse;
  requestConnection: MutationResponse;
  requestPeerFeedback: MutationResponse;
  rescheduleMeetingInstance: MeetingInstancePayload;
  resetContentProgress: ResetContentProgressPayload;
  resubmitExerciseSubmission: ResubmitExerciseSubmissionPayload;
  retakeQuiz: RetakeQuizPayload;
  reviewConnection: MutationResponse;
  revokeUserPublicAPICredentials: MutationResponse;
  saveWorkspaceAgendaItems?: Maybe<AgendaItemsPayload>;
  sendReviewCycleNudgeNotification: MutationResponse;
  sendUserSurveyEndReminder?: Maybe<SendUserSurveyEndReminderPayload>;
  setEntityAccess: EntityAccessPayload;
  setUserLanguage: MutationResponse;
  setupAcademy: SetupAcademyPayload;
  shareItems: MutationResponse;
  shareReview: ShareReviewPayload;
  startQuiz: StartQuizPayload;
  startQuizQuestionTimer: StartQuizQuestionTimerPayload;
  stopItemsSharing: MutationResponse;
  submitJourneyStepForm: SubmitJourneyStepFormPayload;
  submitQuiz: SubmitQuizPayload;
  submitQuizQuestion: SubmitQuizQuestionPayload;
  submitReviewCycleResponse: SubmitReviewCycleResponsePayload;
  submitReviews: MutationResponse;
  submitUserSurveyResponse?: Maybe<SubmitUserSurveyResponsePayload>;
  syncExternalPerformance: SyncExternalPerformancePayload;
  syncProgress: SyncProgressPayload;
  syncTasks?: Maybe<SyncTasksPayload>;
  syncUsers: SyncUsersPayload;
  unAssignContent: UnAssignContentPayload;
  undoMoveMeetingInstanceAgendaItems: MutationResponse;
  unregisterEvent?: Maybe<EventPayload>;
  updateActionItem?: Maybe<ActionItemPayload>;
  updateActionPlan: UpdateActionPlanPayload;
  updateAgendaItem?: Maybe<AgendaItemType>;
  updateAgendaItemTemplate?: Maybe<AgendaItemTemplateType>;
  updateAnnouncement: UpdateAnnouncementPayload;
  updateArticle: UpdateArticlePayload;
  updateAssignment: UpdateAssignmentPayload;
  updateCertificateTemplate: CertificateTemplatepayload;
  updateChart?: Maybe<UpdateChartPayload>;
  updateChildOKRsOrder?: Maybe<UpdateChildOkRsOrderPayload>;
  updateContentStatus: UpdateContentStatusPayload;
  updateDatabaseMetadata?: Maybe<UpdateDatabaseMetadataPayload>;
  updateDatabaseView?: Maybe<UpdateDatabaseViewPayload>;
  updateDepartment: UpdateDepartmentPayload;
  updateEmailDigest?: Maybe<EmailDigestPayload>;
  updateEntitiesTasksRelation?: Maybe<MutationResponse>;
  updateEntityAttachments?: Maybe<AttachmentEntityUnion>;
  updateEntityTags: UpdateEntityTagsPayload;
  updateEvent?: Maybe<EventPayload>;
  updateEventStatus?: Maybe<EventPayload>;
  updateExercise: UpdateExercisePayload;
  updateExternalContent: UpdateExternalContentPayload;
  updateExternalContentPerformance: UpdateExternalContentPerformancePayload;
  updateFeedback: FeedbackPayload;
  updateFile?: Maybe<FilePayload>;
  updateGroup: GroupUnion;
  updateJiraPrioritiesMapping: UpdateJiraPrioritiesMappingPayload;
  updateJiraStatusesMapping: UpdateJiraStatusesMappingPayload;
  updateJourney: JourneyPayload;
  updateJourneyQuestionPerformances: MutationResponse;
  updateJourneyStep: UpdateJourneyStepPayload;
  updateJourneyStepTraineeSession: JourneyStepTraineeSessionType;
  updateLastResolvedNotificationTimestamp: UpdateLastResolvedNotificationTimestampPayload;
  updateMeeting?: Maybe<MeetingType>;
  updateMeetingInstanceAgendaItemsOrder: MeetingInstancePayload;
  updateMeetingInstanceStatus: MeetingInstancePayload;
  updateMetric?: Maybe<MetricPayload>;
  updateNotificationTemplate: UpdateNotificationTemplatePayload;
  updateOKRCycle: UpdateOkrCyclePayload;
  updateOKRDetails?: Maybe<OkrPayload>;
  updateObjective: UpdateObjectivePayload;
  updateOrgRole: UpdateOrgRolePayload;
  updatePath: UpdatePathPayload;
  updatePeerFeedback?: Maybe<PeerFeedbackPayload>;
  updatePerformanceStatus: MutationResponse;
  updatePraise?: Maybe<PraisePayload>;
  updateQuestions?: Maybe<UpdateQuestionsPayload>;
  updateQuiz: UpdateQuizPayload;
  updateQuizQuestions?: Maybe<MutationResponse>;
  updateReport?: Maybe<UpdateReportPayload>;
  updateResource: UpdateResourcePayload;
  updateReviewCycle: ReviewCyclePayload;
  updateReviewCycleStatus: ReviewCyclePayload;
  updateScorecard: UpdateScorecardPayload;
  updateScorm: ScormPayload;
  updateSelfLearningContent: UpdateExternalContentPayload;
  updateSharedItemsDuplicationStatus: MutationResponse;
  updateSharedView: UpdateSharedViewPayload;
  updateSkill: SkillPayload;
  updateSkillMatrix?: Maybe<SkillMatrixType>;
  updateStaffRole: RoleType;
  /** Mutation to update status of event */
  updateStatusOfClassroomEvent?: Maybe<UpdateStatusOfClassroomEventResponse>;
  updateSurvey: UpdateSurveyPayload;
  updateSurveyPerformance: MutationResponse;
  updateTag: TagUnion;
  updateTask: UpdateTaskPayload;
  updateTrack: UpdateTrackPayload;
  updateUser: UserType;
  updateUserCollectionGroup: UserCollectionGroupPayload;
  updateUserInvite: MutationResponse;
  updateUserNomination?: Maybe<UserNominationPayload>;
  updateUserNominationRequest?: Maybe<UserNominationPayload>;
  updateUserSurvey?: Maybe<UpdateUserSurveyPayload>;
  updateUsers: MutationResponse;
  updateUsersStaffRoles: MutationResponse;
  updateWorkspace: WorkspaceType;
  upsertCommunity: CommunityType;
  upsertCourse: UpsertCoursePayload;
  upsertDatabaseData?: Maybe<UpsertDatabaseDataPayload>;
  upsertDatabaseFields?: Maybe<UpsertDatabaseFieldsPayload>;
  upsertFlagSurveyResponse?: Maybe<UpsertFlagSurveyResponsePayload>;
  upsertIntegrationDetail: IntegrationDetailPayload;
  upsertOKR?: Maybe<OkrPayload>;
  upsertPage: UpsertPagePayload;
  upsertScorecardReview: UpsertScorecardReviewPayload;
  upsertStatuses: MutationResponse;
  upsertUserActivityMetrics: MutationResponse;
  upsertUserAnnouncement: UpsertUserAnnouncementPayload;
  upsertUserView?: Maybe<UserViewPayload>;
  upsertWidgets: UpsertWidgetsPayload;
  viewChannel?: Maybe<ViewChannelPayload>;
};


export type MutationAddContentsToGroupArgs = {
  data: ModifyEntitiesInGroupInput;
};


export type MutationAddExerciseSubmissionFeedbackArgs = {
  data: AddExerciseSubmissionFeedbackInput;
};


export type MutationAddReactionArgs = {
  data: AddReactionInput;
};


export type MutationAddRemoveQuizQuestionsArgs = {
  data: AddRemoveQuizQuestionsInput;
};


export type MutationAddUserPushNotificationTokensArgs = {
  input: AddUserPushNotificationTokenInput;
};


export type MutationAddUserSearchActivityArgs = {
  input: AddUserSearchActivityInput;
};


export type MutationAddUsersInEventArgs = {
  data: AddUsersInEventInput;
};


export type MutationAddUsersToGroupArgs = {
  data: ModifyUsersInGroupInput;
};


export type MutationAddUsersToStaffRolesArgs = {
  input: AddUsersToStaffRolesInput;
};


export type MutationArchiveGroupArgs = {
  data: ArchiveGroupInput;
};


export type MutationArchiveJourneyArgs = {
  input: ArchiveJourneyInput;
};


export type MutationArchiveTagArgs = {
  data: ArchiveTagInput;
};


export type MutationArchiveUserSurveyArgs = {
  input?: InputMaybe<ArchiveUserSurveyInput>;
};


export type MutationAssignContentArgs = {
  input: AssignContentInput;
};


export type MutationAttachActivityMetricsArgs = {
  data: AttachActivityMetricsInput;
};


export type MutationBulkMarkEventAttendanceArgs = {
  input: MarkEventAttendanceInput;
};


export type MutationCancelGenerativeTaskArgs = {
  data: CancelGenerativeTaskInput;
};


export type MutationCancelJobArgs = {
  data: CancelJobInput;
};


export type MutationChangeDueDateArgs = {
  data: ChangeDueDateInput;
};


export type MutationCheckinKeyResultArgs = {
  input: CheckinKeyResultInput;
};


export type MutationCheckinObjectiveArgs = {
  input: CheckinObjectiveInput;
};


export type MutationCloseUserSurveyArgs = {
  input?: InputMaybe<CloseUserSurveyInput>;
};


export type MutationCompleteReviewCycleForManagerReporteesArgs = {
  data: CompleteReviewCycleForManagerReporteesInput;
};


export type MutationConvertMediaArgs = {
  input: ConvertMediaInput;
};


export type MutationCreateActionItemArgs = {
  data: CreateActionItemInput;
};


export type MutationCreateActionItemsArgs = {
  data: CreateActionItemsInput;
};


export type MutationCreateActionPlanArgs = {
  input: CreateActionPlanInput;
};


export type MutationCreateAgendaItemArgs = {
  data: CreateAgendaItemInput;
};


export type MutationCreateAgendaItemTemplateArgs = {
  data: CreateAgendaItemTemplateInput;
};


export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput;
};


export type MutationCreateArticleArgs = {
  data: CreateArticleInput;
};


export type MutationCreateBookmarkArgs = {
  input: CreateBookmarkInput;
};


export type MutationCreateChartArgs = {
  data: CreateChartInput;
};


export type MutationCreateCheckinArgs = {
  data: CreateCheckinInput;
};


export type MutationCreateDatabaseViewArgs = {
  input: CreateDatabaseViewInput;
};


export type MutationCreateDepartmentArgs = {
  data: CreateDepartmentInput;
};


export type MutationCreateEmailDigestArgs = {
  data: CreateEmailDigestInput;
};


export type MutationCreateEventArgs = {
  data: CreateEventInput;
};


export type MutationCreateExerciseArgs = {
  data: CreateExerciseInput;
};


export type MutationCreateExternalContentArgs = {
  input: CreateExternalContentInput;
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateFileArgs = {
  data: CreateFileInput;
};


export type MutationCreateGenerativeTaskArgs = {
  data: CreateGenerativeTaskInput;
};


export type MutationCreateGroupArgs = {
  data: CreateGroupInput;
};


export type MutationCreateImportJobArgs = {
  data: CreateImportJobInput;
};


export type MutationCreateJourneyArgs = {
  input: CreateJourneyInput;
};


export type MutationCreateJourneyStepArgs = {
  input: CreateJourneyStepInput;
};


export type MutationCreateJourneyStepTraineeSessionArgs = {
  input: CreateJourneyStepTraineeSessionInput;
};


export type MutationCreateMeetingArgs = {
  data: CreateMeetingInput;
};


export type MutationCreateMeetingInstanceArgs = {
  data: CreateMeetingInstanceInput;
};


export type MutationCreateMetricArgs = {
  data: CreateMetricInput;
};


export type MutationCreateOkrCycleArgs = {
  input: CreateOkrCycleInput;
};


export type MutationCreateObjectiveArgs = {
  input: CreateObjectiveInput;
};


export type MutationCreateOrgRoleArgs = {
  data: CreateOrgRoleInput;
};


export type MutationCreateOutlookCalendarEventArgs = {
  data: CreateOutlookCalendarEventInput;
};


export type MutationCreateOutlookCalendarEventFromMeetingArgs = {
  data: CreateOutlookCalendarEventFromMeetingInput;
};


export type MutationCreatePathArgs = {
  data: CreatePathInput;
};


export type MutationCreatePeerFeedbackArgs = {
  data: CreatePeerFeedbackInput;
};


export type MutationCreatePostArgs = {
  data: CreatePostInput;
};


export type MutationCreatePraiseArgs = {
  data: CreatePraiseInput;
};


export type MutationCreateQuestionsArgs = {
  data: CreateQuestionsInput;
};


export type MutationCreateQuizArgs = {
  input: CreateQuizInput;
};


export type MutationCreateReportArgs = {
  data: CreateReportInput;
};


export type MutationCreateResourceArgs = {
  data: CreateResourceInput;
};


export type MutationCreateReviewCycleArgs = {
  input: CreateReviewCycleInput;
};


export type MutationCreateScorecardArgs = {
  data: CreateScorecardInput;
};


export type MutationCreateScormArgs = {
  data: CreateScormInput;
};


export type MutationCreateSelfLearningContentArgs = {
  input: CreateSelfLearningContentInput;
};


export type MutationCreateSharedViewArgs = {
  input: CreateSharedViewInput;
};


export type MutationCreateSkillArgs = {
  input: CreateSkillInput;
};


export type MutationCreateSkillMatrixArgs = {
  data: CreateSkillMatrixInput;
};


export type MutationCreateSquadChannelArgs = {
  data: CreateSquadChannelInput;
};


export type MutationCreateStaffRoleArgs = {
  input: CreateStaffRoleInput;
};


export type MutationCreateSurveyArgs = {
  data: CreateSurveyInput;
};


export type MutationCreateTagArgs = {
  data: CreateTagInput;
};


export type MutationCreateTaskArgs = {
  data: CreateTaskInput;
};


export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};


export type MutationCreateTrackArgs = {
  data: CreateTrackInput;
};


export type MutationCreateUserCollectionGroupArgs = {
  data: CreateUserCollectionGroupInput;
};


export type MutationCreateUserPublicApiCredentialsArgs = {
  input: CreateUserPublicApiCredentialsInput;
};


export type MutationCreateUserSurveyArgs = {
  input?: InputMaybe<CreateUserSurveyInput>;
};


export type MutationDeleteActionItemArgs = {
  data: DeleteActionItemInput;
};


export type MutationDeleteActionPlanArgs = {
  input: DeleteActionPlanInput;
};


export type MutationDeleteAgendaItemArgs = {
  data: DeleteAgendaItemInput;
};


export type MutationDeleteAgendaItemTemplateArgs = {
  data: DeleteAgendaItemTemplateInput;
};


export type MutationDeleteBookmarkArgs = {
  input: DeleteBookmarkInput;
};


export type MutationDeleteDepartmentArgs = {
  data: DeleteDepartmentInput;
};


export type MutationDeleteEmailDigestArgs = {
  data: DeleteEmailDigestInput;
};


export type MutationDeleteEventPerformanceArgs = {
  input: DeleteEventPerformanceInput;
};


export type MutationDeleteFileArgs = {
  data: DeleteFileInput;
};


export type MutationDeleteJourneyArgs = {
  input: DeleteJourneyInput;
};


export type MutationDeleteJourneyStepArgs = {
  input: DeleteJourneyStepInput;
};


export type MutationDeleteMeetingArgs = {
  data: DeleteMeetingInput;
};


export type MutationDeleteMeetingInstancesArgs = {
  data: DeleteMeetingInstancesInput;
};


export type MutationDeleteMetricArgs = {
  data: DeleteMetricInput;
};


export type MutationDeleteOkrArgs = {
  input: DeleteOkrInput;
};


export type MutationDeleteOkrCycleArgs = {
  input: DeleteOkrCycleInput;
};


export type MutationDeleteOrgRoleArgs = {
  data: DeleteOrgRoleInput;
};


export type MutationDeletePeerFeedbackArgs = {
  data: DeletePeerFeedbackInput;
};


export type MutationDeletePostArgs = {
  data: DeletePostInput;
};


export type MutationDeletePraiseArgs = {
  data: DeletePraiseInput;
};


export type MutationDeleteReportArgs = {
  data: DeleteReportInput;
};


export type MutationDeleteResourceArgs = {
  data: DeleteResourceInput;
};


export type MutationDeleteReviewCycleArgs = {
  input: DeleteReviewCycleInput;
};


export type MutationDeleteSharedViewArgs = {
  input: DeleteSharedViewInput;
};


export type MutationDeleteSkillArgs = {
  input: DeleteSkillInput;
};


export type MutationDeleteSkillMatrixArgs = {
  data: DeleteSkillMatrixInput;
};


export type MutationDeleteSkillReviewArgs = {
  data: DeleteSkillReviewInput;
};


export type MutationDeleteStaffRoleArgs = {
  input: DeleteStaffRoleInput;
};


export type MutationDeleteTracksArgs = {
  data: DeleteTracksInput;
};


export type MutationDeleteUserActivityMetricsArgs = {
  data: DeleteUserActivityMetricsInput;
};


export type MutationDeleteUserSearchActivityArgs = {
  input: DeleteUserSearchActivityInput;
};


export type MutationDeleteWidgetsArgs = {
  input: DeleteWidgetsInput;
};


export type MutationDiscardUnpublishedChangesArgs = {
  data: DiscardUnpublishedChangesInput;
};


export type MutationDisconnectIntegrationDetailArgs = {
  data: DisconnectIntegrationDetailInput;
};


export type MutationDuplicateActionPlanArgs = {
  input: DuplicateActionPlanInput;
};


export type MutationDuplicateAgendaItemArgs = {
  data: DuplicateAgendaItemInput;
};


export type MutationDuplicateAgendaItemTemplateArgs = {
  data: DuplicateAgendaItemTemplateInput;
};


export type MutationDuplicateContentArgs = {
  data: DuplicateContentInput;
};


export type MutationDuplicateEventArgs = {
  data: DuplicateEventInput;
};


export type MutationDuplicateGroupArgs = {
  data: DuplicateGroupInput;
};


export type MutationDuplicateOkrArgs = {
  data: DuplicateOkrInput;
};


export type MutationDuplicateReportArgs = {
  data: DuplicateReportInput;
};


export type MutationDuplicateSkillMatrixArgs = {
  data: DuplicateSkillMatrixInput;
};


export type MutationDuplicateSurveyArgs = {
  data: DuplicateSurveyInput;
};


export type MutationDuplicateUserSurveyArgs = {
  input?: InputMaybe<DuplicateUserSurveyInput>;
};


export type MutationEditPostArgs = {
  input: EditPostInput;
};


export type MutationEvaluateExerciseSubmissionArgs = {
  data: EvaluateExerciseSubmissionInput;
};


export type MutationEventSharingSelfEnrollCourseArgs = {
  input: EventSharingSelfEnrollCourseInput;
};


export type MutationForceCompleteContentProgressArgs = {
  input: ForceCompleteContentProgressInput;
};


export type MutationGenerateLearnerLinkArgs = {
  data: GenerateLearnerLinkInput;
};


export type MutationImportContentFromMarketplaceArgs = {
  input: ImportContentFromMarketplaceInput;
};


export type MutationImportSkilTemplatesArgs = {
  input: ImportSkillTemplatesInput;
};


export type MutationImportSkillMatrixTemplateArgs = {
  data: ImportSkillMatrixTemplateInput;
};


export type MutationInviteExternalUsersForEntitiesArgs = {
  data: InviteExternalUsersForEntitiesInput;
};


export type MutationInviteUserArgs = {
  data: InviteUserInput;
};


export type MutationJoinEventArgs = {
  data: JoinEventInput;
};


export type MutationMakeExerciseSubmissionArgs = {
  data: MakeExerciseSubmissionInput;
};


export type MutationManageUserSurveyQuestionsArgs = {
  input?: InputMaybe<ManageUserSurveyQuestionsInput>;
};


export type MutationMarkEventAttendanceArgs = {
  input: MarkEventAttendanceInput;
};


export type MutationMarkSubmissionAsFeatureArgs = {
  data: MarkSubmissionAsFeatureInput;
};


export type MutationMergeFileArgs = {
  data: MergeFileInput;
};


export type MutationModifyTaskCreationConfigsArgs = {
  data: ModifyTaskCreationConfigsInput;
};


export type MutationModifyUsersInEventArgs = {
  data: ModifyUsersInEventInput;
};


export type MutationMoveFileArgs = {
  data: MoveFileInput;
};


export type MutationMoveMeetingInstanceAgendaItemsArgs = {
  data: MoveMeetingInstanceAgendaItemsInput;
};


export type MutationMoveOkrArgs = {
  data: MoveOkrInput;
};


export type MutationNudgeReviewersArgs = {
  data: NudgeReviewersInput;
};


export type MutationPublishArticleArgs = {
  data: PublishArticleInput;
};


export type MutationPublishAssignmentArgs = {
  data: PublishAssignmentInput;
};


export type MutationPublishCourseArgs = {
  data: PublishCourseInput;
};


export type MutationPublishJourneyArgs = {
  input: PublishJourneyInput;
};


export type MutationPublishReviewCycleArgs = {
  input: PublishReviewCycleInput;
};


export type MutationPublishUserSurveyArgs = {
  input?: InputMaybe<PublishUserSurveyInput>;
};


export type MutationReadNotificationsArgs = {
  input: ReadNotificationsInput;
};


export type MutationRecalibrateContentProgressArgs = {
  input: RecalibrateContentProgressInput;
};


export type MutationRegenerateMeetingInstanceSummaryArgs = {
  data: RegenerateMeetingInstanceSummaryInput;
};


export type MutationRegisterEventArgs = {
  data: RegisterEventInput;
};


export type MutationRemoveContentTagArgs = {
  input: RemoveEntityTagInput;
};


export type MutationRemoveContentsFromGroupArgs = {
  data: ModifyEntitiesInGroupInput;
};


export type MutationRemoveDatabaseDataArgs = {
  input: RemoveDatabaseDataInput;
};


export type MutationRemoveEntityAccessArgs = {
  data: RemoveEntityAccessInput;
};


export type MutationRemoveEventTagArgs = {
  data: RemoveEntityTagInput;
};


export type MutationRemoveMarketplaceImportedContentArgs = {
  input: RemoveMarketplaceImportedContentInput;
};


export type MutationRemoveOkrTagArgs = {
  data: RemoveEntityTagInput;
};


export type MutationRemoveQuestionsArgs = {
  data: RemoveQuestionsInput;
};


export type MutationRemoveReactionArgs = {
  data: RemoveReactionInput;
};


export type MutationRemoveUserNominationArgs = {
  data: UserNominationFilters;
};


export type MutationRemoveUserTagArgs = {
  input: RemoveEntityTagInput;
};


export type MutationRemoveUsersFromGroupArgs = {
  data: ModifyUsersInGroupInput;
};


export type MutationRemoveUsersFromStaffArgs = {
  input: RemoveUsersFromStaffInput;
};


export type MutationRemoveUsersFromStaffRolesArgs = {
  input: RemoveUsersFromStaffRolesInput;
};


export type MutationRequestConnectionArgs = {
  input: RequestConnectionInput;
};


export type MutationRequestPeerFeedbackArgs = {
  data: RequestPeerFeedbackInput;
};


export type MutationRescheduleMeetingInstanceArgs = {
  data: RescheduleMeetingInstanceInput;
};


export type MutationResetContentProgressArgs = {
  input: ResetContentProgressInput;
};


export type MutationResubmitExerciseSubmissionArgs = {
  input: ResubmitExerciseSubmissionInput;
};


export type MutationRetakeQuizArgs = {
  input: RetakeQuizInput;
};


export type MutationReviewConnectionArgs = {
  input: ReviewConnectionInput;
};


export type MutationRevokeUserPublicApiCredentialsArgs = {
  input: RevokeUserPublicApiCredentialsInput;
};


export type MutationSaveWorkspaceAgendaItemsArgs = {
  data: SaveWorkspaceAgendaItemsInput;
};


export type MutationSendReviewCycleNudgeNotificationArgs = {
  data: ReviewCycleNudgeNotificationInput;
};


export type MutationSendUserSurveyEndReminderArgs = {
  input?: InputMaybe<SendUserSurveyEndReminderInput>;
};


export type MutationSetEntityAccessArgs = {
  data: SetEntityAccessInput;
};


export type MutationSetUserLanguageArgs = {
  input: SetUserLanguageInput;
};


export type MutationSetupAcademyArgs = {
  input: SetupAcademyInput;
};


export type MutationShareItemsArgs = {
  input: ShareItemsInput;
};


export type MutationShareReviewArgs = {
  data: ShareReviewInput;
};


export type MutationStartQuizArgs = {
  input: StartQuizInput;
};


export type MutationStartQuizQuestionTimerArgs = {
  input: StartQuizQuestionTimerInput;
};


export type MutationStopItemsSharingArgs = {
  input: StopItemsSharingInput;
};


export type MutationSubmitJourneyStepFormArgs = {
  input: SubmitJourneyStepFormInput;
};


export type MutationSubmitQuizArgs = {
  input: SubmitQuizInput;
};


export type MutationSubmitQuizQuestionArgs = {
  input: SubmitQuizQuestionInput;
};


export type MutationSubmitReviewCycleResponseArgs = {
  data: SubmitReviewCycleResponseInput;
};


export type MutationSubmitReviewsArgs = {
  input: SubmitReviewsInput;
};


export type MutationSubmitUserSurveyResponseArgs = {
  input?: InputMaybe<SubmitUserSurveyResponseInput>;
};


export type MutationSyncExternalPerformanceArgs = {
  input?: InputMaybe<SyncExternalPerformanceInput>;
};


export type MutationSyncProgressArgs = {
  input: Array<SyncProgressInput>;
};


export type MutationSyncTasksArgs = {
  data: SyncTasksInput;
};


export type MutationSyncUsersArgs = {
  data: SyncUsersInput;
};


export type MutationUnAssignContentArgs = {
  input: UnAssignContentInput;
};


export type MutationUndoMoveMeetingInstanceAgendaItemsArgs = {
  data: UndoMoveMeetingInstanceAgendaItemsInput;
};


export type MutationUnregisterEventArgs = {
  data: RegisterEventInput;
};


export type MutationUpdateActionItemArgs = {
  data: UpdateActionItemInput;
};


export type MutationUpdateActionPlanArgs = {
  input: UpdateActionPlanInput;
};


export type MutationUpdateAgendaItemArgs = {
  data: UpdateAgendaItemInput;
};


export type MutationUpdateAgendaItemTemplateArgs = {
  data: UpdateAgendaItemTemplateInput;
};


export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput;
};


export type MutationUpdateArticleArgs = {
  data: UpdateArticleInput;
};


export type MutationUpdateAssignmentArgs = {
  data: UpdateAssignmentInput;
};


export type MutationUpdateCertificateTemplateArgs = {
  data: UpdateCertificateTemplateInput;
};


export type MutationUpdateChartArgs = {
  data: UpdateChartInput;
};


export type MutationUpdateChildOkRsOrderArgs = {
  data: UpdateChildOkRsOrderInput;
};


export type MutationUpdateContentStatusArgs = {
  data: UpdateContentStatusInput;
};


export type MutationUpdateDatabaseMetadataArgs = {
  input: UpdateDatabaseMetadataInput;
};


export type MutationUpdateDatabaseViewArgs = {
  input: UpdateDatabaseViewInput;
};


export type MutationUpdateDepartmentArgs = {
  data: UpdateDepartmentInput;
};


export type MutationUpdateEmailDigestArgs = {
  data: UpdateEmailDigestInput;
};


export type MutationUpdateEntitiesTasksRelationArgs = {
  data: UpdateEntitiesTasksRelationInput;
};


export type MutationUpdateEntityAttachmentsArgs = {
  data: UpdateEntityAttachmentsInput;
};


export type MutationUpdateEntityTagsArgs = {
  input: UpdateEntityTagsInput;
};


export type MutationUpdateEventArgs = {
  data: UpdateEventInput;
};


export type MutationUpdateEventStatusArgs = {
  data: UpdateEventStatusInput;
};


export type MutationUpdateExerciseArgs = {
  data: UpdateExerciseInput;
};


export type MutationUpdateExternalContentArgs = {
  input: UpdateExternalContentInput;
};


export type MutationUpdateExternalContentPerformanceArgs = {
  input: UpdateExternalContentPerformanceInput;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateFileArgs = {
  data: UpdateFileInput;
};


export type MutationUpdateGroupArgs = {
  data: UpdateGroupInput;
};


export type MutationUpdateJiraPrioritiesMappingArgs = {
  data: UpdateJiraPrioritiesMappingInput;
};


export type MutationUpdateJiraStatusesMappingArgs = {
  data: UpdateJiraStatusesMappingInput;
};


export type MutationUpdateJourneyArgs = {
  input: UpdateJourneyInput;
};


export type MutationUpdateJourneyQuestionPerformancesArgs = {
  input: UpdateJourneyQuestionPerformancesInput;
};


export type MutationUpdateJourneyStepArgs = {
  input: UpdateJourneyStepInput;
};


export type MutationUpdateJourneyStepTraineeSessionArgs = {
  input: UpdateJourneyStepTraineeSessionInput;
};


export type MutationUpdateMeetingArgs = {
  data: UpdateMeetingInput;
};


export type MutationUpdateMeetingInstanceAgendaItemsOrderArgs = {
  data: UpdateMeetingInstanceAgendaItemsOrderInput;
};


export type MutationUpdateMeetingInstanceStatusArgs = {
  data: UpdateMeetingInstanceStatusInput;
};


export type MutationUpdateMetricArgs = {
  data: UpdateMetricInput;
};


export type MutationUpdateNotificationTemplateArgs = {
  input: UpdateNotificationTemplateInput;
};


export type MutationUpdateOkrCycleArgs = {
  input: UpdateOkrCycleInput;
};


export type MutationUpdateOkrDetailsArgs = {
  data: UpdateOkrDetailsInput;
};


export type MutationUpdateObjectiveArgs = {
  input: UpdateObjectiveInput;
};


export type MutationUpdateOrgRoleArgs = {
  data: UpdateOrgRoleInput;
};


export type MutationUpdatePathArgs = {
  data: UpdatePathInput;
};


export type MutationUpdatePeerFeedbackArgs = {
  data: UpdatePeerFeedbackInput;
};


export type MutationUpdatePerformanceStatusArgs = {
  data: UpdatePerformanceStatusInput;
};


export type MutationUpdatePraiseArgs = {
  data: UpdatePraiseInput;
};


export type MutationUpdateQuestionsArgs = {
  data: UpdateQuestionsInput;
};


export type MutationUpdateQuizArgs = {
  data: UpdateQuizInput;
};


export type MutationUpdateQuizQuestionsArgs = {
  data: UpdateQuizQuestionsInput;
};


export type MutationUpdateReportArgs = {
  data: UpdateReportInput;
};


export type MutationUpdateResourceArgs = {
  data: UpdateResourceInput;
};


export type MutationUpdateReviewCycleArgs = {
  input: UpdateReviewCycleInput;
};


export type MutationUpdateReviewCycleStatusArgs = {
  input: UpdateReviewCycleStatusInput;
};


export type MutationUpdateScorecardArgs = {
  data: UpdateScorecardInput;
};


export type MutationUpdateScormArgs = {
  data: UpdateScormInput;
};


export type MutationUpdateSelfLearningContentArgs = {
  input: UpdateSelfLearningContentInput;
};


export type MutationUpdateSharedItemsDuplicationStatusArgs = {
  input: UpdateSharedItemsDuplicationStatusInput;
};


export type MutationUpdateSharedViewArgs = {
  input: UpdateSharedViewInput;
};


export type MutationUpdateSkillArgs = {
  input: UpdateSkillInput;
};


export type MutationUpdateSkillMatrixArgs = {
  data: UpdateSkillMatrixInput;
};


export type MutationUpdateStaffRoleArgs = {
  input: UpdateStaffRoleInput;
};


export type MutationUpdateStatusOfClassroomEventArgs = {
  data: UpdateStatusOfClassroomEventInput;
};


export type MutationUpdateSurveyArgs = {
  data: UpdateSurveyInput;
};


export type MutationUpdateSurveyPerformanceArgs = {
  input: UpdateSurveyPerformanceInput;
};


export type MutationUpdateTagArgs = {
  data: UpdateTagInput;
};


export type MutationUpdateTaskArgs = {
  data: UpdateTaskInput;
};


export type MutationUpdateTrackArgs = {
  data: UpdateTrackInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateUserCollectionGroupArgs = {
  data: UpdateUserCollectionGroupInput;
};


export type MutationUpdateUserInviteArgs = {
  data: UpdateUserInviteInput;
};


export type MutationUpdateUserNominationArgs = {
  data: UpdateUserNominationInput;
};


export type MutationUpdateUserNominationRequestArgs = {
  data: UpdateUserNominationRequestInput;
};


export type MutationUpdateUserSurveyArgs = {
  input?: InputMaybe<UpdateUserSurveyInput>;
};


export type MutationUpdateUsersArgs = {
  data: UpdateUsersInput;
};


export type MutationUpdateUsersStaffRolesArgs = {
  data: UpdateUsersStaffRolesInput;
};


export type MutationUpdateWorkspaceArgs = {
  data: UpdateWorkspaceInput;
};


export type MutationUpsertCommunityArgs = {
  input: UpsertCommunityInput;
};


export type MutationUpsertCourseArgs = {
  data: UpsertCourseInput;
};


export type MutationUpsertDatabaseDataArgs = {
  input: UpsertDatabaseDataInput;
};


export type MutationUpsertDatabaseFieldsArgs = {
  input: UpsertDatabaseFieldsInput;
};


export type MutationUpsertFlagSurveyResponseArgs = {
  input?: InputMaybe<UpsertFlagSurveyResponseInput>;
};


export type MutationUpsertIntegrationDetailArgs = {
  data: UpsertIntegrationDetailInput;
};


export type MutationUpsertOkrArgs = {
  data: UpsertOkrInput;
};


export type MutationUpsertPageArgs = {
  data: UpsertPageInput;
};


export type MutationUpsertScorecardReviewArgs = {
  data: UpsertScorecardReviewInput;
};


export type MutationUpsertStatusesArgs = {
  data: UpsertStatusesInput;
};


export type MutationUpsertUserActivityMetricsArgs = {
  data: UpsertUserActivityMetricsInput;
};


export type MutationUpsertUserAnnouncementArgs = {
  input: UpsertUserAnnouncementInput;
};


export type MutationUpsertUserViewArgs = {
  data: UpsertUserViewInput;
};


export type MutationUpsertWidgetsArgs = {
  input: UpsertWidgetsInput;
};


export type MutationViewChannelArgs = {
  data: ViewChannelInput;
};

/** Mutation Response */
export type MutationResponse = {
  __typename?: 'MutationResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MyEventsWidgetPreviewType = {
  __typename?: 'MyEventsWidgetPreviewType';
  /** events */
  events?: Maybe<Array<Scalars['JSON']['output']>>;
  type: WidgetTypeEnum;
};

export type NavConfigProperties = {
  __typename?: 'NavConfigProperties';
  children: Array<NavbarElement>;
};

export type NavbarElement = {
  __typename?: 'NavbarElement';
  children?: Maybe<Array<NavbarMenuChild>>;
  icon?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  props?: Maybe<Scalars['String']['output']>;
  type: NavbarElementTypeEnum;
};

export enum NavbarElementTypeEnum {
  Link = 'LINK',
  Menu = 'MENU'
}

export type NavbarMenuChild = {
  __typename?: 'NavbarMenuChild';
  description: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  props?: Maybe<Scalars['String']['output']>;
  type: NavbarElementTypeEnum;
};

export type NestedDueDateConfig = {
  __typename?: 'NestedDueDateConfig';
  dueDateConfig: DueDateAssignmentConfig;
  itemId: Scalars['String']['output'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
};

export type NestedDueDateConfigInput = {
  dueDateConfig: DueDateAssignmentConfigInput;
  itemId: Scalars['String']['input'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
};

export enum NotificationActionTypeEnum {
  AssignContent = 'ASSIGN_CONTENT',
  AssignSingleContent = 'ASSIGN_SINGLE_CONTENT',
  BatchedContentOverdue = 'BATCHED_CONTENT_OVERDUE',
  BatchedDueDateReminder = 'BATCHED_DUE_DATE_REMINDER',
  BatchedOkrDueDateReminder = 'BATCHED_OKR_DUE_DATE_REMINDER',
  BatchedQuizEvaluationUpdated = 'BATCHED_QUIZ_EVALUATION_UPDATED',
  BulkAssignContent = 'BULK_ASSIGN_CONTENT',
  BulkEntityRevoked = 'BULK_ENTITY_REVOKED',
  BulkEntityShared = 'BULK_ENTITY_SHARED',
  CertificateCreation = 'CERTIFICATE_CREATION',
  ChartExportCompleted = 'CHART_EXPORT_COMPLETED',
  ClassroomPlatformUserInvitation = 'CLASSROOM_PLATFORM_USER_INVITATION',
  ClassroomUserForgetPassword = 'CLASSROOM_USER_FORGET_PASSWORD',
  ClassroomUserInvitation = 'CLASSROOM_USER_INVITATION',
  ClassroomUserVerification = 'CLASSROOM_USER_VERIFICATION',
  CommunityChannelMentioned = 'COMMUNITY_CHANNEL_MENTIONED',
  ConnectionRequested = 'CONNECTION_REQUESTED',
  ContentCompleted = 'CONTENT_COMPLETED',
  ContentExpired = 'CONTENT_EXPIRED',
  ContentMajorVersionUpdate = 'CONTENT_MAJOR_VERSION_UPDATE',
  ContentOverdue = 'CONTENT_OVERDUE',
  ContentReviewDue = 'CONTENT_REVIEW_DUE',
  ContentSubmitted = 'CONTENT_SUBMITTED',
  ContentUserInvitation = 'CONTENT_USER_INVITATION',
  DueDateReminder = 'DUE_DATE_REMINDER',
  EnterprisePlatformUserInvitation = 'ENTERPRISE_PLATFORM_USER_INVITATION',
  EntityRevoked = 'ENTITY_REVOKED',
  EntityShared = 'ENTITY_SHARED',
  EventArchived = 'EVENT_ARCHIVED',
  EventPostWorkAvailable = 'EVENT_POST_WORK_AVAILABLE',
  EventRecordingAvailable = 'EVENT_RECORDING_AVAILABLE',
  EventRegistration = 'EVENT_REGISTRATION',
  EventRescheduled = 'EVENT_RESCHEDULED',
  ExerciseApproved = 'EXERCISE_APPROVED',
  ExerciseApprovedAndFeatured = 'EXERCISE_APPROVED_AND_FEATURED',
  ExerciseFeatured = 'EXERCISE_FEATURED',
  ExerciseRejected = 'EXERCISE_REJECTED',
  ExerciseSubmitted = 'EXERCISE_SUBMITTED',
  ExportJobCompleted = 'EXPORT_JOB_COMPLETED',
  ExportJobFailed = 'EXPORT_JOB_FAILED',
  HabitReminder = 'HABIT_REMINDER',
  LowQuizScore = 'LOW_QUIZ_SCORE',
  MeetingInstanceCreated = 'MEETING_INSTANCE_CREATED',
  NudgeReviewCyClEReviewers = 'NUDGE_REVIEW_CYClE_REVIEWERS',
  OkrsUpdated = 'OKRS_UPDATED',
  OkrCheckin = 'OKR_CHECKIN',
  OkrCycleCheckinReminder = 'OKR_CYCLE_CHECKIN_REMINDER',
  OkrCycleCompletionReminder = 'OKR_CYCLE_COMPLETION_REMINDER',
  OkrCycleUpdated = 'OKR_CYCLE_UPDATED',
  OkrDeleted = 'OKR_DELETED',
  OkrDueDateReminder = 'OKR_DUE_DATE_REMINDER',
  OkrOwnerChanged = 'OKR_OWNER_CHANGED',
  OneOnOneDeactivated = 'ONE_ON_ONE_DEACTIVATED',
  OneOnOneInstanceRescheduled = 'ONE_ON_ONE_INSTANCE_RESCHEDULED',
  OneOnOneMissed = 'ONE_ON_ONE_MISSED',
  OneOnOneReminder = 'ONE_ON_ONE_REMINDER',
  OneOnOneScheduleUpdate = 'ONE_ON_ONE_SCHEDULE_UPDATE',
  OneOnOneSetup = 'ONE_ON_ONE_SETUP',
  OneOnOneStart = 'ONE_ON_ONE_START',
  OneOnOneTemplateSetup = 'ONE_ON_ONE_TEMPLATE_SETUP',
  OneOnOneTemplateUpdated = 'ONE_ON_ONE_TEMPLATE_UPDATED',
  PathUpdated = 'PATH_UPDATED',
  PeerFeedbackGiven = 'PEER_FEEDBACK_GIVEN',
  PeerFeedbackRequested = 'PEER_FEEDBACK_REQUESTED',
  PraiseGiven = 'PRAISE_GIVEN',
  QuizEvaluationUpdated = 'QUIZ_EVALUATION_UPDATED',
  RecurringEventArchived = 'RECURRING_EVENT_ARCHIVED',
  ResetProgress = 'RESET_PROGRESS',
  ReviewCycleNudgePeerApprovers = 'REVIEW_CYCLE_NUDGE_PEER_APPROVERS',
  ReviewCycleNudgePeerReviewees = 'REVIEW_CYCLE_NUDGE_PEER_REVIEWEES',
  ReviewCycleNudgeReviewers = 'REVIEW_CYCLE_NUDGE_REVIEWERS',
  ReviewCycleNudgeSharers = 'REVIEW_CYCLE_NUDGE_SHARERS',
  ReviewCyclePeerSelectionReminder = 'REVIEW_CYCLE_PEER_SELECTION_REMINDER',
  ReviewCyclePeerSelectionStarted = 'REVIEW_CYCLE_PEER_SELECTION_STARTED',
  ReviewCyclePeerSelectionStartedForManagers = 'REVIEW_CYCLE_PEER_SELECTION_STARTED_FOR_MANAGERS',
  ReviewCycleReviewWritingReminder = 'REVIEW_CYCLE_REVIEW_WRITING_REMINDER',
  ReviewCycleReviewWritingStarted = 'REVIEW_CYCLE_REVIEW_WRITING_STARTED',
  ReviewCycleStarted = 'REVIEW_CYCLE_STARTED',
  ReviewCycleUserNominationApproved = 'REVIEW_CYCLE_USER_NOMINATION_APPROVED',
  ReviewCycleUserNominationRequestReceived = 'REVIEW_CYCLE_USER_NOMINATION_REQUEST_RECEIVED',
  ReviewCycleViewResults = 'REVIEW_CYCLE_VIEW_RESULTS',
  ReviewCycleViewResultsForManagers = 'REVIEW_CYCLE_VIEW_RESULTS_FOR_MANAGERS',
  ReviewCyClEEnded = 'REVIEW_CYClE_ENDED',
  SingleEntityRevoked = 'SINGLE_ENTITY_REVOKED',
  SingleEntityShared = 'SINGLE_ENTITY_SHARED',
  SourceConnectionReviewed = 'SOURCE_CONNECTION_REVIEWED',
  StorePlatformUserInvitation = 'STORE_PLATFORM_USER_INVITATION',
  StoreUserForgetPassword = 'STORE_USER_FORGET_PASSWORD',
  StoreUserVerification = 'STORE_USER_VERIFICATION',
  SubmissionFeatured = 'SUBMISSION_FEATURED',
  SubmissionFeedbackAdded = 'SUBMISSION_FEEDBACK_ADDED',
  TeamMeetingSetup = 'TEAM_MEETING_SETUP',
  TrainingAttended = 'TRAINING_ATTENDED',
  TrainingInProgress = 'TRAINING_IN_PROGRESS',
  TrainingNotAttended = 'TRAINING_NOT_ATTENDED',
  TrainingStartReminder = 'TRAINING_START_REMINDER',
  UnAssignContent = 'UN_ASSIGN_CONTENT',
  UserMasquerading = 'USER_MASQUERADING',
  UserMention = 'USER_MENTION',
  UserSurveyAssigned = 'USER_SURVEY_ASSIGNED',
  UserSurveyClosed = 'USER_SURVEY_CLOSED',
  UserSurveyEndReminder = 'USER_SURVEY_END_REMINDER',
  UserSurveyResultsEnabled = 'USER_SURVEY_RESULTS_ENABLED',
  UserSurveyViewShared = 'USER_SURVEY_VIEW_SHARED',
  WorkspaceCreated = 'WORKSPACE_CREATED',
  WorkspaceLearnerInvitation = 'WORKSPACE_LEARNER_INVITATION',
  WorkspaceLowSeatCount = 'WORKSPACE_LOW_SEAT_COUNT',
  WorkspaceStaffInvitation = 'WORKSPACE_STAFF_INVITATION',
  WorkspaceUpcomingAutomaticRenewal = 'WORKSPACE_UPCOMING_AUTOMATIC_RENEWAL',
  WorkspaceUpcomingClosedRenewal = 'WORKSPACE_UPCOMING_CLOSED_RENEWAL',
  WorkspaceUpcomingDeactivation = 'WORKSPACE_UPCOMING_DEACTIVATION',
  WorkspaceUpcomingRenewalOpportunity = 'WORKSPACE_UPCOMING_RENEWAL_OPPORTUNITY'
}

export type NotificationConfigurationInput = {
  /** The type of notification for which the configuration is being set */
  actionType: NotificationActionTypeEnum;
  /** Should notifications be sent to learners for the corresponding actionType */
  notifyLearners?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reminder configurations for the corresponding actionType */
  reminderConfigurations: Array<ReminderConfigurationInput>;
};

export type NotificationConfigurationType = {
  __typename?: 'NotificationConfigurationType';
  /** The type of notification for which the configuration is being set */
  actionType: NotificationActionTypeEnum;
  /** Should notifications be sent to learners for the corresponding actionType */
  notifyLearners: Scalars['Boolean']['output'];
  /** Reminder configurations for the corresponding actionType */
  reminderConfigurations: Array<ReminderConfigurationType>;
};

export enum NotificationGroupTypeEnum {
  Assignments = 'ASSIGNMENTS',
  Content = 'CONTENT',
  Invites = 'INVITES',
  LyearnConnect = 'LYEARN_CONNECT',
  Okr = 'OKR',
  OneOnOne = 'ONE_ON_ONE',
  Others = 'OTHERS',
  PraiseAndFeedback = 'PRAISE_AND_FEEDBACK',
  Sessions = 'SESSIONS',
  SubmissionReviews = 'SUBMISSION_REVIEWS',
  SubAccount = 'SUB_ACCOUNT'
}

export type NotificationTaskRequirementAudit = {
  __typename?: 'NotificationTaskRequirementAudit';
  bell?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['Boolean']['output']>;
  push?: Maybe<Scalars['Boolean']['output']>;
  webhook?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  actionType: NotificationActionTypeEnum;
  areRemindersGloballyConfigurable?: Maybe<Scalars['Boolean']['output']>;
  availableAs: Array<TaskTypeEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  defaultReminderType?: Maybe<ReminderTypeEnum>;
  description?: Maybe<Scalars['String']['output']>;
  group?: Maybe<NotificationGroupTypeEnum>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isConfigurable?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reminderConfigurations?: Maybe<Array<ReminderConfigurationType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  tasksRequired: NotificationTaskRequirementAudit;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Edge */
export type NotificationTemplateEdge = {
  __typename?: 'NotificationTemplateEdge';
  node: NotificationTemplate;
};

/** Get Notification Templates */
export type NotificationTemplatesFilters = {
  actionTypes?: InputMaybe<Array<NotificationActionTypeEnum>>;
  isConfigurable?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Notification Token object. */
export type NotificationTokenInput = {
  /** device Id for identifying the device. */
  deviceId: Scalars['String']['input'];
  /** TokenId, generated by FCM or any external Messaging Service */
  tokenId: Scalars['String']['input'];
  /** type of device, like web, android etc */
  type?: InputMaybe<NotificationTokenType>;
};

export enum NotificationTokenType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type NudgeReviewersInput = {
  reviewCycleId: Scalars['String']['input'];
  revieweeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewerIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum NumberFormatEnum {
  Dollar = 'DOLLAR',
  Euro = 'EURO',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Pound = 'POUND',
  Rupee = 'RUPEE',
  Yen = 'YEN'
}

export type NumberTagPropsInput = {
  numberType: NumberTagTypeEnum;
};

export type NumberTagType = {
  __typename?: 'NumberTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']['output']>;
  /** common tag union fields */
  id: Scalars['String']['output'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** number type tag specific fields */
  numberType: NumberTagTypeEnum;
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']['output']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** This denotes, whether we need to create a new dynamic group, when a new option is added inside single/multi select */
  syncGroup?: Maybe<Scalars['Boolean']['output']>;
  /** field resolvers */
  taggedContentCount: Scalars['Float']['output'];
  taggedEventCount: Scalars['Float']['output'];
  taggedOKRCount: Scalars['Float']['output'];
  taggedTaskCount: Scalars['Float']['output'];
  taggedUserCount: Scalars['Float']['output'];
  type: TagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type NumberTagTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type NumberTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

export enum NumberTagTypeEnum {
  Dollar = 'DOLLAR',
  Euro = 'EURO',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Pound = 'POUND',
  Rupee = 'RUPEE',
  Yen = 'YEN'
}

export enum OkrActions {
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE'
}

/** Input for selecting bulk okrs */
export type OkrBulkSelectConfigInput = {
  excludeOkrIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<GetOkRsFilters>;
  includeOkrIds?: InputMaybe<Array<Scalars['String']['input']>>;
  operation: OkrBulkSelectOperationEnum;
};

export enum OkrBulkSelectOperationEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

/** Checkins for any objective or Key Result */
export type OkrCheckinType = {
  __typename?: 'OKRCheckinType';
  /** Current Value of the key result */
  actualValue?: Maybe<Scalars['Float']['output']>;
  /** Checkin Timestamp */
  checkedInAt: Scalars['String']['output'];
  /**
   * Optional description related to checkin
   * @deprecated use details field with text and media
   */
  description?: Maybe<Scalars['String']['output']>;
  /** Checkin details in text or media */
  details?: Maybe<InstructionType>;
  /** Progress on checkin */
  progress: Scalars['Float']['output'];
  /** Status of Checkin */
  status: OkrStatusEnum;
};

/** Base Schema for cycle */
export type OkrCycle = {
  __typename?: 'OKRCycle';
  /** completion Date */
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  discussionChannel?: Maybe<ChannelType>;
  /** Cycle OKR Discussion ChannelId. */
  discussionChannelId?: Maybe<Scalars['String']['output']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String']['output'];
  /** Due date of the cycle */
  dueDateConfig: DueDateConfig;
  /** display emoji or color */
  iconSetting: IconSettings;
  id: Scalars['String']['output'];
  /** Indicates if cycle is deleted */
  isDeleted: Scalars['Boolean']['output'];
  /** Name of cycle */
  name: Scalars['String']['output'];
  objectivePermissions: Array<CycleOkrPermission>;
  objectivesCount?: Maybe<Scalars['Float']['output']>;
  scheduleConfig?: Maybe<ScheduleConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** start date of the cycle */
  startDate: Scalars['String']['output'];
  /** Status for okr, ontrack behind etc */
  status: OkrCycleStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on okr Cycle */
  userPermissions?: Maybe<Array<UserOkrCyclePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** store who can view the cycle */
  visibilitySettings: VisibilitySettingsType;
};

export enum OkrCycleActions {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE'
}

/** This returns paginated OKR Cycles */
export type OkrCycleConnection = {
  __typename?: 'OKRCycleConnection';
  edges?: Maybe<Array<OkrCycleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge of OKR CYCLE */
export type OkrCycleEdge = {
  __typename?: 'OKRCycleEdge';
  node: OkrCycle;
};

export type OkrCycleFilters = {
  /** filter based on cycle createdAt */
  createdAt?: InputMaybe<DateFilter>;
  /** name of cycle to fetch */
  createdBy?: InputMaybe<Scalars['String']['input']>;
  /** id of cycle to fetch */
  cycleId?: InputMaybe<Scalars['String']['input']>;
  /** Returns Cycles which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  /** filter based on due date */
  dueDate?: InputMaybe<DateFilter>;
  /** name of cycle to fetch */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Search to perform regex search on the cycle */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** filter based on start date  */
  startDate?: InputMaybe<DateFilter>;
  /** Status for okr, ontrack behind etc */
  status?: InputMaybe<OkrCycleStatusEnum>;
};

export enum OkrCycleStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS'
}

export enum OkrCycleSubjects {
  CompanyObjective = 'COMPANY_OBJECTIVE',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  OkrCycle = 'OKR_CYCLE',
  TeamObjective = 'TEAM_OBJECTIVE'
}

export type OkrCyclesFilters = {
  /** filter based on cycle createdAt */
  createdAt?: InputMaybe<DateFilter>;
  /** creators of cycle */
  creators?: InputMaybe<Array<Scalars['String']['input']>>;
  /** id of cycle to fetch */
  cycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns Cycles which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  /** filter based on due date */
  dueDate?: InputMaybe<DateFilter>;
  /** name of cycle to fetch */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search to perform regex search on the cycle */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** filter based on start date  */
  startDate?: InputMaybe<DateFilter>;
  /** Status for okr, ontrack behind etc */
  status?: InputMaybe<Array<OkrCycleStatusEnum>>;
};

/** OKR Edge */
export type OkrEdge = {
  __typename?: 'OKREdge';
  node: OkrUnion;
};

export type OkrGroupCount = {
  __typename?: 'OKRGroupCount';
  count: Scalars['Float']['output'];
  status: OkrStatusEnum;
};

/** Data related to integration of any key Result */
export type OkrIntegrationConfig = {
  __typename?: 'OKRIntegrationConfig';
  /** Provider used for progress tracking */
  providerId?: Maybe<Scalars['String']['output']>;
  /** search field or jql query for jira  */
  searchText?: Maybe<Scalars['String']['output']>;
};

export type OkrIntegrationConfigInput = {
  /** Provider used for progress tracking */
  providerId?: InputMaybe<Scalars['String']['input']>;
  /** search field or jql query for jira  */
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type OkrPayload = {
  __typename?: 'OKRPayload';
  okr?: Maybe<OkrUnion>;
};

export type OkrPermissionInput = {
  /** action to be performed */
  action: OkrActions;
  /** store who can act on subject */
  permission: VisibilitySettingsInput;
  /** subject on which permission action is to be performed */
  subject: OkrPermissionSubjects;
};

export enum OkrPermissionSubjects {
  CompanyObjective = 'COMPANY_OBJECTIVE',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  KeyResult = 'KEY_RESULT',
  TeamObjective = 'TEAM_OBJECTIVE'
}

export enum OkrProgressComputationTypeEnum {
  IssueCompletion = 'ISSUE_COMPLETION',
  IssueCount = 'ISSUE_COUNT',
  StoryPointCompletion = 'STORY_POINT_COMPLETION',
  StoryPointCount = 'STORY_POINT_COUNT'
}

/** Data related to progress of any OKR */
export type OkrProgressMeasurementConfig = {
  __typename?: 'OKRProgressMeasurementConfig';
  /** computation type like issues count, etc */
  computationType?: Maybe<OkrProgressComputationTypeEnum>;
  /** type of progress measurement like manual or jira integ etc */
  progressMeasurementType: OkrProgressMeasurementTypeEnum;
};

export type OkrProgressMeasurementConfigInput = {
  /** computation type like issues count, etc */
  computationType?: InputMaybe<OkrProgressComputationTypeEnum>;
  /** type of progress measurement like manual or jira integ etc */
  progressMeasurementType: OkrProgressMeasurementTypeEnum;
};

export enum OkrProgressMeasurementTypeEnum {
  AutoSync = 'AUTO_SYNC',
  Jira = 'JIRA',
  Manual = 'MANUAL',
  Metric = 'METRIC',
  TaskRollup = 'TASK_ROLLUP'
}

export type OkrSelectionConfig = {
  __typename?: 'OKRSelectionConfig';
  excludedOkrIds?: Maybe<Array<Scalars['String']['output']>>;
  okrIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum OkrStatusEnum {
  AtRisk = 'AT_RISK',
  Behind = 'BEHIND',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  OnTrack = 'ON_TRACK'
}

export enum OkrSubjects {
  Okr = 'OKR'
}

/** OKR Summary */
export type OkrSummaryType = {
  __typename?: 'OKRSummaryType';
  createdAt: Scalars['String']['output'];
  /** what service was used to generate the summary */
  generatedBy: Scalars['String']['output'];
  /** Summary of OKR updates */
  value: Scalars['String']['output'];
};

/** Config for auto creation of tasks in OKRs  */
export type OkrTaskCreationConfig = {
  __typename?: 'OKRTaskCreationConfig';
  actionItemsLinkedCount: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** creator of the taskCreation config */
  createdByUser?: Maybe<UserProfile>;
  /** Type of external entity to be tracked by creating action item */
  externalEntityType: ActionItemExternalEntityTypeEnum;
  externalLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Id of the OKR Cycle in which this is created */
  providerId: Scalars['String']['output'];
  /** Optional description related to checkin */
  searchText?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** config for maintaining the status of sync jobs for this particular task creation config */
  syncConfig?: Maybe<SyncConfig>;
  /** Type of config, manual or auto */
  taskCreationType: TaskCreationConfigTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** creator of the taskCreation config */
  updatedByUser?: Maybe<UserProfile>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type OkrTaskCreationConfigFilter = {
  /** Id of the OKR Cycle in which this is created */
  providerId?: InputMaybe<Scalars['String']['input']>;
  /** Optional description related to checkin */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** Type of config, manual or auto */
  taskCreationType?: InputMaybe<TaskCreationConfigTypeEnum>;
};

export type OkrTemplateStubType = {
  __typename?: 'OKRTemplateStubType';
  childrenKRs?: Maybe<Array<ChildKrStubType>>;
  childrenObjectives?: Maybe<Array<ChildObjectiveStubType>>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type OkrTemplateType = {
  __typename?: 'OKRTemplateType';
  categories?: Maybe<Array<TemplateCategoryType>>;
  categoryIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  okrTemplate: TemplateOkrStub;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateStatusEnum;
  type: TemplateTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type OkrTemplatesResultType = {
  __typename?: 'OKRTemplatesResultType';
  records: Array<OkrTemplateStubType>;
};

export enum OkrTypeEnum {
  KeyResult = 'KEY_RESULT',
  Objective = 'OBJECTIVE'
}

/** It will resolve different OKR types */
export type OkrUnion = KeyResultType | ObjectiveType;

export enum OkrViewTypeEnum {
  Flat = 'FLAT',
  Nested = 'NESTED'
}

export type OkRsWidgetType = {
  __typename?: 'OKRsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  okrGroups: Array<OkrGroupCount>;
  okrs: PaginatedOkRsPayload;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Objective Category Config Type */
export type ObjectiveCategoryConfig = {
  __typename?: 'ObjectiveCategoryConfig';
  /** relatedId stored */
  relatedId?: Maybe<Scalars['String']['output']>;
  /** Type of objective created */
  type: ObjectiveTypeEnum;
};

/** Objective Category Config Type */
export type ObjectiveCategoryConfigInput = {
  /** store the related id such as department ID, managerId etc */
  relatedId?: InputMaybe<Scalars['String']['input']>;
  /** Type of objective created */
  type: ObjectiveTypeEnum;
};

export type ObjectivePermissionsSetInput = {
  /** permissions for objective */
  set: Array<OkrPermissionInput>;
};

/** Objective Type */
export type ObjectiveType = {
  __typename?: 'ObjectiveType';
  /** all visibility settings of the okr */
  allVisibilitySettings: Array<VisibilitySettingsType>;
  attachments?: Maybe<Array<EntityMediaType>>;
  /** Config to store type and id to which it is related */
  categoryConfig: ObjectiveCategoryConfig;
  /** Checkin Timestamp */
  checkedInAt?: Maybe<Scalars['String']['output']>;
  /** Total number of checkins */
  checkinCount?: Maybe<Scalars['Float']['output']>;
  /** All checkins of the okr */
  checkins?: Maybe<Array<OkrCheckinType>>;
  childKeyResultIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** will fetch direct child OKRs of the given OKR */
  childOKRs?: Maybe<Array<OkrUnion>>;
  /** Order of okr in the parent okr child list */
  childOrder: Scalars['Float']['output'];
  childOrderForParent: Scalars['Float']['output'];
  childOrders?: Maybe<Array<ParentChildOrder>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  contributeToProgress?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String']['output'];
  /**
   * use details field with text and media
   * @deprecated use details field with text and media
   */
  description?: Maybe<Scalars['String']['output']>;
  discussionChannel?: Maybe<ChannelType>;
  discussionChannelId?: Maybe<Scalars['String']['output']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String']['output'];
  dueDateConfig: DueDateConfig;
  /** Returns true if okr has any child okrs */
  hasChildren: Scalars['Boolean']['output'];
  /** path till rootNode - starting from /parent.../rootNode */
  hierarchyPath?: Maybe<Scalars['String']['output']>;
  /** Hierarchy paths of okr */
  hierarchyPaths?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** OKR Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Indicates if okr is deleted */
  isDeleted: Scalars['Boolean']['output'];
  isProgressAutoRolledUp?: Maybe<Scalars['Boolean']['output']>;
  /** Name or Title of Objective or key result */
  name: Scalars['String']['output'];
  noteBlockId?: Maybe<Scalars['String']['output']>;
  /** Cycle of the OKR */
  okrCycle?: Maybe<OkrCycle>;
  /** owners of the okr */
  okrOwners?: Maybe<Array<UserType>>;
  /** returns count of all the direct + indirect child okrs of given okr */
  okrTreeChildrenCount: Scalars['Float']['output'];
  /** owners of OKR */
  ownerIds: Array<Scalars['String']['output']>;
  /** owners of the okr */
  owners?: Maybe<Array<UserProfile>>;
  /** Id of the parent */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Id of the parents */
  parentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** parent OKR of the okr */
  parentOKR?: Maybe<OkrUnion>;
  /** parent OKR of the okr */
  parentOKRs?: Maybe<Array<OkrUnion>>;
  /** Progress done */
  progress: Scalars['Float']['output'];
  /** All the things which are related to okr */
  relatedToEntities?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status for okr, ontrack behind etc */
  status: OkrStatusEnum;
  summary?: Maybe<OkrSummaryType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  totalChildOKRCount: Scalars['Float']['output'];
  /** Type for okr, Objective or key result */
  type: OkrTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on okr (objective or key result) */
  userPermissions?: Maybe<Array<UserOkrPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  /** store who can view the objective */
  visibilitySettings: VisibilitySettingsType;
};


/** Objective Type */
export type ObjectiveTypeChildOkRsArgs = {
  filters?: InputMaybe<GetChildOkRsFilters>;
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};


/** Objective Type */
export type ObjectiveTypeChildOrderForParentArgs = {
  parentId: Scalars['String']['input'];
};


/** Objective Type */
export type ObjectiveTypeOkrTreeChildrenCountArgs = {
  types?: InputMaybe<Array<OkrTypeEnum>>;
};


/** Objective Type */
export type ObjectiveTypeParentOkRsArgs = {
  fetchAll?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Objective Type */
export type ObjectiveTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum ObjectiveTypeEnum {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Individual = 'INDIVIDUAL',
  Team = 'TEAM'
}

export type OfflineEventAttendanceExportInputPropsInput = {
  eventId: Scalars['String']['input'];
  filters: EventAttendeesFilters;
  recurringEventExportType?: InputMaybe<RecurringEventExportTypeEnum>;
};

export type OfflineEventAttendanceExportInputPropsType = {
  __typename?: 'OfflineEventAttendanceExportInputPropsType';
  eventId: Scalars['String']['output'];
  filters: EventAttendeesFiltersType;
  recurringEventExportType?: Maybe<RecurringEventExportTypeEnum>;
};

export type OfflineEventAttendanceExportMetadataType = {
  __typename?: 'OfflineEventAttendanceExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type OfflineEventAttendanceImportInputPropsInput = {
  eventId: Scalars['String']['input'];
};

export type OfflineEventAttendanceImportInputPropsType = {
  __typename?: 'OfflineEventAttendanceImportInputPropsType';
  eventId: Scalars['String']['output'];
};

export type OfflineEventAttendanceImportMetadataType = {
  __typename?: 'OfflineEventAttendanceImportMetadataType';
  updatedUsersCount: Scalars['Float']['output'];
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentType = {
  __typename?: 'OfflineEventContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  event?: Maybe<EventUnion>;
  eventRecurringCategory?: Maybe<RecurringTypeEnum>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEventId?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String']['output'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type OfflineEventProps = {
  location: EventLocationInput;
};

export type OfflineEventType = {
  __typename?: 'OfflineEventType';
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']['output']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String']['output'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** user provided end time in UTC */
  endTime: Scalars['String']['output'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  firstRegisteredSlotId?: Maybe<Scalars['String']['output']>;
  flexiblePermissionGroupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructorIds: Array<Scalars['String']['output']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isRevokedAfterCompletion: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean']['output'];
  isUserRegistered: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  latestUpcomingEventId?: Maybe<Scalars['String']['output']>;
  latestUpcomingEventStartTime?: Maybe<Scalars['String']['output']>;
  location: EventLocation;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEvent?: Maybe<EventUnion>;
  /** stores the id of parent recurring session wrapper */
  parentEventId?: Maybe<Scalars['String']['output']>;
  postWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']['output']>>;
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** depicts the subtype for session */
  recurringCategory?: Maybe<RecurringTypeEnum>;
  recurringEventSlotCount?: Maybe<Scalars['Float']['output']>;
  registeredEventsCount?: Maybe<Scalars['Float']['output']>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']['output']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float']['output'];
  remainingPreWorkContents: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String']['output'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String']['output'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float']['output'];
  type: EventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type OfflineEventTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type OfflineEventTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type OfflineEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type OfflineEventTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type OfflineEventUserAttendanceInput = {
  excludeUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isPresent: Scalars['Boolean']['input'];
  unmarkedAttendance?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  usersFilters?: InputMaybe<EventAttendeesFiltersV2>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type OnFailureActions = {
  __typename?: 'OnFailureActions';
  redirect: Scalars['Boolean']['output'];
  redirectionURL: Scalars['String']['output'];
};

export type OnFailureActionsInput = {
  redirect?: InputMaybe<Scalars['Boolean']['input']>;
  redirectionURL?: InputMaybe<Scalars['String']['input']>;
};

export type OnFailureActionsType = {
  __typename?: 'OnFailureActionsType';
  redirect: Scalars['Boolean']['output'];
  redirectionURL: Scalars['String']['output'];
};

export type OnSuccessActions = {
  __typename?: 'OnSuccessActions';
  createUser: Scalars['Boolean']['output'];
  redirect: Scalars['Boolean']['output'];
  redirectionURL: Scalars['String']['output'];
  sendInvitationMail: Scalars['Boolean']['output'];
  syncTagValue?: Maybe<Scalars['Boolean']['output']>;
  updateUser: Scalars['Boolean']['output'];
};

export type OnSuccessActionsInput = {
  createUser?: InputMaybe<Scalars['Boolean']['input']>;
  redirect?: InputMaybe<Scalars['Boolean']['input']>;
  redirectionURL?: InputMaybe<Scalars['String']['input']>;
  sendInvitationMail?: InputMaybe<Scalars['Boolean']['input']>;
  syncTagValue?: InputMaybe<Scalars['Boolean']['input']>;
  updateUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OnSuccessActionsType = {
  __typename?: 'OnSuccessActionsType';
  createUser: Scalars['Boolean']['output'];
  redirect: Scalars['Boolean']['output'];
  redirectionURL: Scalars['String']['output'];
  sendInvitationMail: Scalars['Boolean']['output'];
  syncTagValue?: Maybe<Scalars['Boolean']['output']>;
  updateUser: Scalars['Boolean']['output'];
};

export enum OnboardStatusEnum {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type OnboardingContentType = {
  __typename?: 'OnboardingContentType';
  content: ContentEntity;
  orgRole?: Maybe<OrgRoleType>;
  orgRoleId: Scalars['String']['output'];
};

export type OnboardingContentWidgetPreviewType = {
  __typename?: 'OnboardingContentWidgetPreviewType';
  /** onboarding content */
  orgRole?: Maybe<Scalars['JSON']['output']>;
  type: WidgetTypeEnum;
};

export type OnboardingContentWidgetType = {
  __typename?: 'OnboardingContentWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  onboardingContent?: Maybe<OnboardingContentType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type OnboardingInfoType = {
  __typename?: 'OnboardingInfoType';
  expectedTeamSize?: Maybe<ExpectedTeamSizeEnum>;
  lookingFor?: Maybe<Array<Scalars['String']['output']>>;
};

export enum OperationEnum {
  Create = 'CREATE',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

/** Options are used for single select, multi-select or true-false type questions */
export type Option = {
  __typename?: 'Option';
  attachments?: Maybe<Array<EntityMediaType>>;
  optionId: Scalars['String']['output'];
  optionRelativeScore?: Maybe<Scalars['Float']['output']>;
  optionText: Scalars['String']['output'];
};

/** Options are used for single select, multi-select or true-false type questions */
export type OptionInput = {
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  optionEvaluation: Scalars['Boolean']['input'];
  optionId?: InputMaybe<Scalars['String']['input']>;
  optionRelativeScore?: InputMaybe<Scalars['Float']['input']>;
  optionText: Scalars['String']['input'];
};

/** Response for option type questions */
export type OptionResponse = {
  __typename?: 'OptionResponse';
  optionId: Scalars['String']['output'];
  optionText: Scalars['String']['output'];
};

/** Used when response type is SingleSelect, MultiSelect, TrueFalse */
export type OptionResponseInput = {
  optionId: Scalars['String']['input'];
  optionText: Scalars['String']['input'];
};

export type OptionResponseType = {
  __typename?: 'OptionResponseType';
  optionId: Scalars['String']['output'];
  optionText: Scalars['String']['output'];
};

export type OptionsSetInput = {
  /** Options to be updated */
  set: Array<OptionInput>;
};

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** This returns paginated OrgRole */
export type OrgRoleConnections = {
  __typename?: 'OrgRoleConnections';
  edges?: Maybe<Array<OrgRoleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type OrgRoleEdge = {
  __typename?: 'OrgRoleEdge';
  node: OrgRoleType;
};

export type OrgRoleIdInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

/** Org Role Profile */
export type OrgRoleProfileType = {
  __typename?: 'OrgRoleProfileType';
  id: Scalars['String']['output'];
  sampleUsers: Array<UserProfile>;
  userIds: Array<Scalars['String']['output']>;
  usersCount: Scalars['Float']['output'];
};

export type OrgRoleReportConfig = {
  __typename?: 'OrgRoleReportConfig';
  activityMetricConfig?: Maybe<ActivityMetricConfig>;
};

export type OrgRoleReportInput = {
  activityMetricConfig?: InputMaybe<ActivityMetricInput>;
};

export enum OrgRoleStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

/** OrgRole Schema */
export type OrgRoleType = {
  __typename?: 'OrgRoleType';
  activityCount?: Maybe<Scalars['Float']['output']>;
  activityMetricWeightDistributionConfig?: Maybe<ActivityMetricWeightDistributionConfig>;
  activityMetrics?: Maybe<Array<ActivityMetricType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** OrgRole definition */
  definition?: Maybe<Scalars['String']['output']>;
  department?: Maybe<DepartmentType>;
  /** denotes orgRole belongs to which department */
  departmentId?: Maybe<Scalars['String']['output']>;
  /** OrgRole description */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** unique identifier/abbreviation for an orgRole */
  key?: Maybe<Scalars['String']['output']>;
  learnerProfileActivityMetricReport?: Maybe<ReportType>;
  /** level of orgRole inside a track */
  level?: Maybe<Scalars['Float']['output']>;
  mindsetCount?: Maybe<Scalars['Float']['output']>;
  /** denotes mindsets */
  mindsetIds?: Maybe<Array<Scalars['String']['output']>>;
  mindsets?: Maybe<Array<SkillType>>;
  /** OrgRole Name or Title */
  name: Scalars['String']['output'];
  onboardingPath?: Maybe<PathContentType>;
  /** onboarding path Id which needs to be taken up after joining orgRole */
  onboardingPathId?: Maybe<Scalars['String']['output']>;
  orgRoleProfile?: Maybe<OrgRoleProfileType>;
  reportConfig?: Maybe<OrgRoleReportConfig>;
  responsibilitySampleUsers: Array<UserType>;
  responsibilityUserCount?: Maybe<Scalars['Float']['output']>;
  sampleUsers: Array<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillCount?: Maybe<Scalars['Float']['output']>;
  skillGroups?: Maybe<Array<SkillGroupType>>;
  /** denotes skills */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skillMatrix?: Maybe<SkillMatrixType>;
  /** skill matrix attached to org role and skill master settings */
  skillMatrixConfig?: Maybe<SkillMatrixConfig>;
  skills?: Maybe<Array<SkillType>>;
  status: OrgRoleStatusEnum;
  /** role tag using which this role is created under track */
  tag?: Maybe<EntityTagType>;
  track?: Maybe<TrackType>;
  /** denotes orgRole belongs to which track */
  trackId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<OrgRoleTypeEnum>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userCount?: Maybe<Scalars['Float']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum OrgRoleTypeEnum {
  Designation = 'DESIGNATION',
  Responsibility = 'RESPONSIBILITY'
}

export type OrgRolesFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hasNoParentDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  matchExactName?: InputMaybe<Scalars['Boolean']['input']>;
  mindsetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name of the orgRole */
  name?: InputMaybe<Scalars['String']['input']>;
  /** names of the orgRole */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillMatrixIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<OrgRoleStatusEnum>>;
  trackIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<OrgRoleTypeEnum>>;
  usersFilters?: InputMaybe<UsersFilters>;
};

export enum OutlookEventEntityTypeEnum {
  Event = 'EVENT',
  Meeting = 'MEETING',
  MeetingInstance = 'MEETING_INSTANCE'
}

/** ReviewCycle Schema */
export type OverDueSettingsType = {
  __typename?: 'OverDueSettingsType';
  /** Max allowed over due in days */
  overdueDays?: Maybe<Scalars['Float']['output']>;
  /** Max allowed over due in minutes */
  overdueMinutes?: Maybe<Scalars['Float']['output']>;
};

export type OwnerIdsSetInput = {
  /** owners of OKR */
  set: Array<Scalars['String']['input']>;
};

export type PageComponentFilters = {
  id: Scalars['String']['input'];
  pageComponentStatus: Array<PageComponentStatusEnum>;
  pageType: PageTypeEnum;
};

export type PageComponentInput = {
  bannerProps?: InputMaybe<BannerPropsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  props?: InputMaybe<Scalars['String']['input']>;
  status: PageComponentStatusEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: PageComponentTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  widgetProps?: InputMaybe<WidgetPropsInput>;
};

export enum PageComponentLayoutTypeEnum {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type PageComponentPayload = {
  __typename?: 'PageComponentPayload';
  pageComponent?: Maybe<PageComponentType>;
};

export type PageComponentPreviewInput = {
  bannerProps?: InputMaybe<BannerPropsPreviewInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageComponentType: PageComponentTypeEnum;
  props?: InputMaybe<Scalars['String']['input']>;
  widgetProps?: InputMaybe<WidgetPropsInput>;
  widgetType?: InputMaybe<WidgetTypeEnum>;
};

/** union of all todos */
export type PageComponentPreviewUnion = LeafPageComponentPreviewType | MultiBannerPageComponentPreviewType;

export enum PageComponentStatusEnum {
  Hidden = 'HIDDEN',
  Published = 'PUBLISHED'
}

export type PageComponentType = {
  __typename?: 'PageComponentType';
  /** description */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String']['output'];
  primaryOrder: Scalars['Float']['output'];
  progress?: Maybe<Scalars['Float']['output']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']['output']>;
  secondaryOrder: Scalars['Float']['output'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  /** type of the page component */
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']['output']>;
};

export enum PageComponentTypeEnum {
  Banner = 'BANNER',
  BannerCard = 'BANNER_CARD',
  CardGrid = 'CARD_GRID',
  Carousel = 'CAROUSEL',
  Description = 'DESCRIPTION',
  Heading = 'HEADING',
  IconDescriptionList = 'ICON_DESCRIPTION_LIST',
  IconDescriptionRows = 'ICON_DESCRIPTION_ROWS',
  MarkedDescriptionList = 'MARKED_DESCRIPTION_LIST',
  MarkedLabels = 'MARKED_LABELS',
  MultiBanner = 'MULTI_BANNER',
  OkrListing = 'OKR_LISTING',
  OnboardingProgress = 'ONBOARDING_PROGRESS',
  PaginatedCardGrid = 'PAGINATED_CARD_GRID',
  PerformanceMetrics = 'PERFORMANCE_METRICS',
  SetupCardGrid = 'SETUP_CARD_GRID',
  TaskListing = 'TASK_LISTING',
  WelcomeBanner = 'WELCOME_BANNER'
}

/** union of all page components */
export type PageComponentUnion = LeafPageComponentType | MultiBannerPageComponentType;

/** PagedInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Cursor to fetch the next page */
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
};

export type PageInput = {
  pageComponentStatus?: InputMaybe<Array<PageComponentStatusEnum>>;
  type: PageTypeEnum;
};

export type PagePreviewInput = {
  components: Array<PageComponentPreviewInput>;
};

export type PagePreviewPayload = {
  __typename?: 'PagePreviewPayload';
  components: Array<Maybe<PageComponentPreviewUnion>>;
};

export type PageType = {
  __typename?: 'PageType';
  components: Array<PageComponentType>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: PageTypeEnum;
};

export enum PageTypeEnum {
  AdminGettingStarted = 'ADMIN_GETTING_STARTED',
  ContextualHomepage = 'CONTEXTUAL_HOMEPAGE',
  Discover = 'DISCOVER',
  GettingStarted = 'GETTING_STARTED',
  HomePage = 'HOME_PAGE',
  HomePageConfigurable = 'HOME_PAGE_CONFIGURABLE',
  Library = 'LIBRARY',
  MarketplaceContentCatalog = 'MARKETPLACE_CONTENT_CATALOG'
}

export type PageType__Next = {
  __typename?: 'PageType__Next';
  components: Array<PageComponentUnion>;
  type: PageTypeEnum;
};

export type PaginatedActionItemsPayload = {
  __typename?: 'PaginatedActionItemsPayload';
  edges?: Maybe<Array<ActionItemEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type PaginatedActionPlanPayload = {
  __typename?: 'PaginatedActionPlanPayload';
  edges?: Maybe<Array<ActionPlanEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedActivityMetricsPayload = {
  __typename?: 'PaginatedActivityMetricsPayload';
  edges?: Maybe<Array<ActivityMetricEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedAgendaItemTemplatesPayload = {
  __typename?: 'PaginatedAgendaItemTemplatesPayload';
  edges?: Maybe<Array<AgendaItemTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedAgendaItemsPayload = {
  __typename?: 'PaginatedAgendaItemsPayload';
  edges?: Maybe<Array<AgendaItemEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedAnnouncementsPayload = {
  __typename?: 'PaginatedAnnouncementsPayload';
  edges?: Maybe<Array<AnnouncementEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedBookmarkConnections = {
  __typename?: 'PaginatedBookmarkConnections';
  edges?: Maybe<Array<BookmarkEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** This returns paginated channel participants */
export type PaginatedChannelParticipantsPayload = {
  __typename?: 'PaginatedChannelParticipantsPayload';
  edges?: Maybe<Array<ChannelParticipantEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** This returns paginated providers */
export type PaginatedCommonProvidersPayload = {
  __typename?: 'PaginatedCommonProvidersPayload';
  edges?: Maybe<Array<CommonProviderEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedEmailDigestsPayload = {
  __typename?: 'PaginatedEmailDigestsPayload';
  edges?: Maybe<Array<EmailDigestEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedEventsPayload = {
  __typename?: 'PaginatedEventsPayload';
  edges?: Maybe<Array<EventEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedExternalUserPerformanceInfosPayload = {
  __typename?: 'PaginatedExternalUserPerformanceInfosPayload';
  edges?: Maybe<Array<ExternalUserPerformanceInfoEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedFilesPayload = {
  __typename?: 'PaginatedFilesPayload';
  edges?: Maybe<Array<FileEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedFlagSurveyResponsesPayload = {
  __typename?: 'PaginatedFlagSurveyResponsesPayload';
  edges?: Maybe<Array<FlagSurveyResponseEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedGroupedActionItemEdge = {
  __typename?: 'PaginatedGroupedActionItemEdge';
  node: GroupedActionItemEdge;
};

export type PaginatedGroupedActionItemsPayload = {
  __typename?: 'PaginatedGroupedActionItemsPayload';
  edges: Array<PaginatedGroupedActionItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
  totalGroupCount: Scalars['Float']['output'];
};

export type PaginatedIntegrationProvidersPayload = {
  __typename?: 'PaginatedIntegrationProvidersPayload';
  edges?: Maybe<Array<IntegrationProviderEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedJiraIssuesPayload = {
  __typename?: 'PaginatedJiraIssuesPayload';
  edges?: Maybe<Array<JiraIssueEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedJobsPayload = {
  __typename?: 'PaginatedJobsPayload';
  edges?: Maybe<Array<JobEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedJourneyPerformancesPayload = {
  __typename?: 'PaginatedJourneyPerformancesPayload';
  edges?: Maybe<Array<JourneyPerformanceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedJourneyStepsPayload = {
  __typename?: 'PaginatedJourneyStepsPayload';
  edges?: Maybe<Array<JourneyStepEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedJourneysPayload = {
  __typename?: 'PaginatedJourneysPayload';
  edges?: Maybe<Array<JourneyEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedMarketplaceContentCategoriesPayload = {
  __typename?: 'PaginatedMarketplaceContentCategoriesPayload';
  edges?: Maybe<Array<MarketplaceContentCategoryEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedMarketplaceContentsPayload = {
  __typename?: 'PaginatedMarketplaceContentsPayload';
  edges?: Maybe<Array<MarketplaceContentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedMeetingInstancesPayload = {
  __typename?: 'PaginatedMeetingInstancesPayload';
  edges?: Maybe<Array<MeetingInstanceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedMeetingsPayload = {
  __typename?: 'PaginatedMeetingsPayload';
  edges?: Maybe<Array<MeetingEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedMetricsPayload = {
  __typename?: 'PaginatedMetricsPayload';
  edges?: Maybe<Array<MetricEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedMissingParticipantsDataCheckCountsPayload = {
  __typename?: 'PaginatedMissingParticipantsDataCheckCountsPayload';
  edges?: Maybe<Array<MissingParticipantsDataCheckCountEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** This returns paginated notification templates */
export type PaginatedNotificationTemplatesPayload = {
  __typename?: 'PaginatedNotificationTemplatesPayload';
  edges?: Maybe<Array<NotificationTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedOkRsPayload = {
  __typename?: 'PaginatedOKRsPayload';
  edges?: Maybe<Array<OkrEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedPeerFeedbackTemplatesPayload = {
  __typename?: 'PaginatedPeerFeedbackTemplatesPayload';
  edges?: Maybe<Array<PeerFeedbackTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedPeerFeedbacksPayload = {
  __typename?: 'PaginatedPeerFeedbacksPayload';
  edges?: Maybe<Array<PeerFeedbackEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedPraisesPayload = {
  __typename?: 'PaginatedPraisesPayload';
  edges?: Maybe<Array<PraiseEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedReportsPayload = {
  __typename?: 'PaginatedReportsPayload';
  edges?: Maybe<Array<ReportEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** This returns paginated review cycles */
export type PaginatedReviewCyclesPayload = {
  __typename?: 'PaginatedReviewCyclesPayload';
  edges?: Maybe<Array<ReviewCycleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** This returns paginated SharedConnectionInfo */
export type PaginatedSharedConnectionInfo = {
  __typename?: 'PaginatedSharedConnectionInfo';
  edges?: Maybe<Array<SharedConnectionInfoEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedSharedViewsPayload = {
  __typename?: 'PaginatedSharedViewsPayload';
  edges?: Maybe<Array<SharedViewEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedStatusesPayload = {
  __typename?: 'PaginatedStatusesPayload';
  edges?: Maybe<Array<StatusEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedTagsPayload = {
  __typename?: 'PaginatedTagsPayload';
  edges?: Maybe<Array<TagEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type PaginatedTemplateCategoriesPayload = {
  __typename?: 'PaginatedTemplateCategoriesPayload';
  edges?: Maybe<Array<TemplateCategoryEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedTemplatesPayload = {
  __typename?: 'PaginatedTemplatesPayload';
  edges?: Maybe<Array<TemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedThemePayload = {
  __typename?: 'PaginatedThemePayload';
  edges?: Maybe<Array<ThemeEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedTracksPayload = {
  __typename?: 'PaginatedTracksPayload';
  edges?: Maybe<Array<TrackEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserActivityMetricsPayload = {
  __typename?: 'PaginatedUserActivityMetricsPayload';
  edges?: Maybe<Array<UserActivityMetricEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserAnnouncementsPayload = {
  __typename?: 'PaginatedUserAnnouncementsPayload';
  edges?: Maybe<Array<UserAnnouncementEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserNominationsPayload = {
  __typename?: 'PaginatedUserNominationsPayload';
  edges?: Maybe<Array<UserNominationEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** This returns paginated user notifications */
export type PaginatedUserNotificationsPayload = {
  __typename?: 'PaginatedUserNotificationsPayload';
  edges?: Maybe<Array<UserNotificationPayloadEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserPublicApiCredentialsPayload = {
  __typename?: 'PaginatedUserPublicAPICredentialsPayload';
  edges?: Maybe<Array<UserPublicApiCredentialsEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserSurveyCommentsPayload = {
  __typename?: 'PaginatedUserSurveyCommentsPayload';
  edges?: Maybe<Array<UserSurveyCommentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserSurveyResponsesPayload = {
  __typename?: 'PaginatedUserSurveyResponsesPayload';
  edges?: Maybe<Array<UserSurveyResponseEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUserSurveysPayload = {
  __typename?: 'PaginatedUserSurveysPayload';
  edges?: Maybe<Array<UserSurveyEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export enum PaginationErrorCodeEnum {
  InvalidCursor = 'INVALID_CURSOR'
}

/** Pair provides mapping for match the following type questions */
export type Pair = {
  __typename?: 'Pair';
  left: Scalars['String']['output'];
  right: Scalars['String']['output'];
};

/** Pair provides mapping for match the following type questions */
export type PairInput = {
  left: Scalars['String']['input'];
  right: Scalars['String']['input'];
};

export type ParentChildOrder = {
  __typename?: 'ParentChildOrder';
  childOrder: Scalars['Float']['output'];
  parentId: Scalars['String']['output'];
};

/** Parent related information for the block */
export type ParentDetails = {
  __typename?: 'ParentDetails';
  /** parent Cover */
  cover?: Maybe<EntityMediaType>;
  /** Parent Description, can have course, article etc description */
  description?: Maybe<Scalars['String']['output']>;
  /** parent entity's id  */
  id: Scalars['String']['output'];
  /** Lesson description for the current block */
  lessonDescription?: Maybe<Scalars['String']['output']>;
  /** Lesson Id for the current block */
  lessonId?: Maybe<Scalars['String']['output']>;
  /** Lesson page Id for the current block */
  lessonPageId?: Maybe<Scalars['String']['output']>;
  /** Lesson Title for the current block */
  lessonTitle?: Maybe<Scalars['String']['output']>;
  /** parent name, name of course, article etc. */
  name: Scalars['String']['output'];
  /** parent preview */
  preview?: Maybe<EntityMediaType>;
  /** parent entity's Status */
  status: Scalars['String']['output'];
  /** parent entity's type */
  subType?: Maybe<Scalars['String']['output']>;
  /** parent Tagline */
  tagline?: Maybe<Scalars['String']['output']>;
  /** parent entity's type */
  type: Scalars['String']['output'];
};

export enum ParentEntityTypeEnum {
  Collection = 'COLLECTION',
  Content = 'CONTENT',
  Event = 'EVENT'
}

export type ParentIdSetInput = {
  /** parentId to be updated. If empty, OKR will be made root level */
  set?: InputMaybe<Scalars['String']['input']>;
};

export type ParentIdsSetInput = {
  /** ParentIds for objective or keyResult */
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ParticipantConfigInput = {
  batchConfigs?: InputMaybe<JourneyBatchConfigsUpdateInput>;
  type: JourneyParticipantConfigTypeEnum;
};

export type ParticipantConfigType = {
  __typename?: 'ParticipantConfigType';
  batchConfigs?: Maybe<Array<BatchConfigType>>;
  type: JourneyParticipantConfigTypeEnum;
};

/** Path Type */
export type PathContentType = {
  __typename?: 'PathContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** Default Enrolment Settings */
  defaultEnrolmentSettings: DefaultEnrolmentSettingsType;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  /** Stores the description of the path */
  description?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<DraftPathContentType>;
  dripSettings: DripSettingsType;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** Stores total duration */
  duration: Scalars['Float']['output'];
  /** Feedback Questions for the path */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Path Medias */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  /** Objective of the path content */
  objective?: Maybe<Scalars['String']['output']>;
  /** performance of the current user */
  performance?: Maybe<PathPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  sections?: Maybe<Array<PathSectionType>>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** skill ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Path Type */
export type PathContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Path Type */
export type PathContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Path Type */
export type PathContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Path Type */
export type PathContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Path Type */
export type PathContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Path Type */
export type PathContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Path Type */
export type PathContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Path Type */
export type PathContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Path Type */
export type PathContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

/** User Performance on Learning Path */
export type PathPerformanceType = {
  __typename?: 'PathPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  lockSettings: Array<ContentLockSettings>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** resume learning */
  resume?: Maybe<ContentEntity>;
  /** Percentage score: avg of all child quizzes */
  scorePercentage?: Maybe<Scalars['Float']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** User Metrics for Path Performance */
  userMetrics?: Maybe<PathUserMetrics>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type PathSectionInput = {
  /** Stores which resource is optional or not */
  childResourceConfigs?: InputMaybe<Array<SectionChildResourceConfigsInputType>>;
  /** learning content ids */
  childResourceIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Path Section Type */
export type PathSectionType = {
  __typename?: 'PathSectionType';
  /** Stores which resource is optional or not */
  childResourceConfigs?: Maybe<Array<SectionChildResourceConfigsType>>;
  /** stores the child resource Ids */
  childResourceIds?: Maybe<Array<Scalars['String']['output']>>;
  childResources?: Maybe<Array<ContentEntity>>;
  completedCount?: Maybe<Scalars['Float']['output']>;
  /** Stores the description of the section */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Path Section Medias */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Name of the section */
  name: Scalars['String']['output'];
  /** Stores the order of opening/completion of section */
  order: Scalars['Float']['output'];
};

/** User Metrics for Path Performance */
export type PathUserMetrics = {
  __typename?: 'PathUserMetrics';
  progress?: Maybe<Scalars['Float']['output']>;
  remainingTime?: Maybe<Scalars['Float']['output']>;
};

/** Edge */
export type PeerFeedbackEdge = {
  __typename?: 'PeerFeedbackEdge';
  node: PeerFeedbackSchema;
};

export type PeerFeedbackMindsetRatingInputType = {
  mindsetId: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Float']['input']>;
};

export type PeerFeedbackMindsetRatingType = {
  __typename?: 'PeerFeedbackMindsetRatingType';
  MindSet?: Maybe<SkillType>;
  mindsetId: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
};

export type PeerFeedbackPayload = {
  __typename?: 'PeerFeedbackPayload';
  peerFeedback?: Maybe<PeerFeedbackSchema>;
};

export type PeerFeedbackSchema = {
  __typename?: 'PeerFeedbackSchema';
  actionItemsCount: Scalars['Float']['output'];
  /** media */
  attachments: Array<EntityMediaType>;
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedById?: Maybe<Scalars['String']['output']>;
  feedbackMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  learningContentIds?: Maybe<Array<Scalars['String']['output']>>;
  learningContents: Array<ContentEntity>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: Maybe<Array<MentionType>>;
  mindsetRatings?: Maybe<Array<PeerFeedbackMindsetRatingType>>;
  postsCount: Scalars['Float']['output'];
  receiverUser?: Maybe<UserType>;
  receiverUserId: Scalars['String']['output'];
  requestMessage?: Maybe<Scalars['String']['output']>;
  senderUser?: Maybe<UserType>;
  senderUserId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillRatings?: Maybe<Array<PeerFeedbackSkillRatingType>>;
  status: PeerFeedbackStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  visibleToManager: Scalars['Boolean']['output'];
};

export type PeerFeedbackSkillRatingInputType = {
  rating?: InputMaybe<Scalars['Float']['input']>;
  skillId: Scalars['String']['input'];
};

export type PeerFeedbackSkillRatingType = {
  __typename?: 'PeerFeedbackSkillRatingType';
  rating?: Maybe<Scalars['Float']['output']>;
  skill?: Maybe<SkillType>;
  skillId: Scalars['String']['output'];
};

export enum PeerFeedbackStatusEnum {
  Completed = 'COMPLETED',
  Requested = 'REQUESTED'
}

/** Edge */
export type PeerFeedbackTemplateEdge = {
  __typename?: 'PeerFeedbackTemplateEdge';
  node: PeerFeedbackTemplateSchema;
};

export type PeerFeedbackTemplateOfTitleSchema = {
  __typename?: 'PeerFeedbackTemplateOfTitleSchema';
  text: Scalars['String']['output'];
};

export type PeerFeedbackTemplateSchema = {
  __typename?: 'PeerFeedbackTemplateSchema';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  templates: Array<PeerFeedbackTemplateOfTitleSchema>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type PeerFeedbackTemplatesFilters = {
  peerFeedbackTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PeerFeedbacksFilters = {
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns peerFeedback which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  peerFeedbackIds?: InputMaybe<Array<Scalars['String']['input']>>;
  receiverUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  senderUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<PeerFeedbackStatusEnum>>;
};

export type PeerReviewSettingsInput = {
  /** if false then only manager can nominate peers */
  allowRevieweesToNominate?: InputMaybe<Scalars['Boolean']['input']>;
  mindsetReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
  /** peer nomination max allowed count */
  nominationPreferences?: InputMaybe<ReviewCyclePeerNominationPreferencesInput>;
  questions?: InputMaybe<Array<ReviewCycleQuestionInput>>;
  resultDeliverySettings?: InputMaybe<UpwardReviewResultDeliverySettingsInput>;
  reviewInstruction?: InputMaybe<InstructionInput>;
  skillReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
};

export type PeerReviewSettingsType = {
  __typename?: 'PeerReviewSettingsType';
  /** if false then only manager can nominate peers */
  allowRevieweesToNominate?: Maybe<Scalars['Boolean']['output']>;
  mindsetReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
  nominationPreferences?: Maybe<ReviewCyclePeerNominationPreferences>;
  questions?: Maybe<Array<ReviewCycleQuestionType>>;
  resultDeliverySettings?: Maybe<UpwardReviewResultDeliverySettings>;
  /** instructions for reviewers from admin */
  reviewInstruction?: Maybe<InstructionType>;
  skillReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
};

/** ScorecardReview Schema */
export type PeerReviewType = {
  __typename?: 'PeerReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entityType: ResourceTypeEnum;
  evaluation: ExerciseSubmissionStatusEnum;
  id: Scalars['String']['output'];
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  /** retry info object */
  retryInfo?: Maybe<RetryInfoType>;
  reviewType: Scalars['String']['output'];
  /** reviewer for the PeerReview */
  reviewer?: Maybe<UserType>;
  /** reviewer for the assignment */
  reviewerId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** submitted at */
  submissionDate: Scalars['String']['output'];
  submissionId: Scalars['String']['output'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user for the PeerReview */
  user?: Maybe<UserType>;
  /** user who submitted the assignment */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum PerformanceContentTypeEnum {
  InLessonQuiz = 'IN_LESSON_QUIZ',
  ReviewQuiz = 'REVIEW_QUIZ',
  StaticQuiz = 'STATIC_QUIZ'
}

export enum PerformanceEntityTypeEnum {
  Assignment = 'ASSIGNMENT',
  Block = 'BLOCK',
  Event = 'EVENT',
  Question = 'QUESTION',
  Resource = 'RESOURCE'
}

export enum PerformanceErrorCode {
  AllScorecardQuestionsNotAnswered = 'ALL_SCORECARD_QUESTIONS_NOT_ANSWERED',
  AlreadyReviewed = 'ALREADY_REVIEWED',
  NoPermissionToComment = 'NO_PERMISSION_TO_COMMENT',
  QuizPerformanceNotFound = 'QUIZ_PERFORMANCE_NOT_FOUND',
  QuizQuestionPerformanceNotFound = 'QUIZ_QUESTION_PERFORMANCE_NOT_FOUND',
  SubmissionNotAllowed = 'SUBMISSION_NOT_ALLOWED',
  UnableToUpdatePerformance = 'UNABLE_TO_UPDATE_PERFORMANCE'
}

export enum PerformanceEvaluationStatusEnum {
  Correct = 'CORRECT',
  InCorrect = 'IN_CORRECT'
}

export enum PerformanceGradeEnum {
  AboveAverage = 'ABOVE_AVERAGE',
  Average = 'AVERAGE',
  BelowAverage = 'BELOW_AVERAGE',
  Low = 'LOW',
  Top = 'TOP'
}

/** This returns paginated resources */
export type PerformanceInteractionConnections = {
  __typename?: 'PerformanceInteractionConnections';
  edges?: Maybe<Array<PerformanceInteractionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type PerformanceInteractionEdge = {
  __typename?: 'PerformanceInteractionEdge';
  node: PerformanceInteractionEntity;
};

/** Feedback or ScorecardReview or peer review */
export type PerformanceInteractionEntity = CycleReviewType | EntityCycleReviewType | LatestUserReviewType | PeerReviewType | QuestionReviewType | ScorecardReviewType | SkillAssessmentReviewType | SkillGroupReviewType | SkillPeerFeedbackReviewType | SkillReviewType | UserCycleReviewType;

export enum PerformanceInteractionErrorCodeEnum {
  AlreadyReviewed = 'ALREADY_REVIEWED',
  UpsertScorecardFailed = 'UPSERT_SCORECARD_FAILED'
}

export type PerformanceInteractionFilters = {
  containsFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** ids of performanceInteractions */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** questionIds of performanceInteractions of questionReview type */
  questionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  questionResponseTypes?: InputMaybe<Array<QuestionTypeEnum>>;
  reviewCycleId?: InputMaybe<Scalars['String']['input']>;
  reviewCycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewCycleInstanceId?: InputMaybe<Scalars['String']['input']>;
  reviewType?: InputMaybe<Array<ReviewTypeEnum>>;
  reviewerFilter?: InputMaybe<PerformanceInteractionReviewerFilterInput>;
  reviewerId?: InputMaybe<Scalars['String']['input']>;
  reviewerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  scorecardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter userCycleReview docs having provided shareStauts */
  shareStatus?: InputMaybe<Array<ReviewShareStatusEnum>>;
  sharedAt?: InputMaybe<DateFilter>;
  /** filter for sharers of userCycleReview */
  sharedByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillMatrixIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<CycleReviewStatusEnum>>;
  subType?: InputMaybe<SkillTypeEnum>;
  submissionId?: InputMaybe<Scalars['String']['input']>;
  submissionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<PerformanceInteractionTypeEnum>;
  updatedAt?: InputMaybe<DateFilter>;
  userFilter?: InputMaybe<PerformanceInteractionUserFilterInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PerformanceInteractionReviewerFilterInput = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export enum PerformanceInteractionTypeEnum {
  CycleReview = 'CYCLE_REVIEW',
  EntityCycleReview = 'ENTITY_CYCLE_REVIEW',
  Feedback = 'FEEDBACK',
  LatestUserReview = 'LATEST_USER_REVIEW',
  LatestUserSkillGroupReview = 'LATEST_USER_SKILL_GROUP_REVIEW',
  LatestUserSkillReview = 'LATEST_USER_SKILL_REVIEW',
  PeerReview = 'PEER_REVIEW',
  QuestionReview = 'QUESTION_REVIEW',
  ScorecardReview = 'SCORECARD_REVIEW',
  SkillAssessmentReview = 'SKILL_ASSESSMENT_REVIEW',
  SkillGroupReview = 'SKILL_GROUP_REVIEW',
  SkillPeerFeedbackReview = 'SKILL_PEER_FEEDBACK_REVIEW',
  SkillReview = 'SKILL_REVIEW',
  UserCycleReview = 'USER_CYCLE_REVIEW'
}

export type PerformanceInteractionUserFilterInput = {
  parentId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PerformanceMetricsWidgetPreviewType = {
  __typename?: 'PerformanceMetricsWidgetPreviewType';
  certificatesCount: Scalars['Float']['output'];
  completedCoursesCount: Scalars['Float']['output'];
  inProgressCourses: Scalars['Float']['output'];
  type: WidgetTypeEnum;
};

export type PerformanceMetricsWidgetType = {
  __typename?: 'PerformanceMetricsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  assignedContentsCount: Scalars['Float']['output'];
  certificatesCount: Scalars['Float']['output'];
  completedContentsCount: Scalars['Float']['output'];
  completedCoursesCount: Scalars['Float']['output'];
  /** contents */
  contents: ContentConnection;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  inProgressContentsCount: Scalars['Float']['output'];
  inProgressCourses: Scalars['Float']['output'];
  overdueContentsCount: Scalars['Float']['output'];
  productivityScore: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type PerformanceMetricsWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  facetValueInputs?: InputMaybe<Array<FacetValueInput>>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sorts?: InputMaybe<Array<SortType>>;
};

export type PerformanceParentDetails = {
  __typename?: 'PerformanceParentDetails';
  contentType?: Maybe<ResourceTypeEnum>;
  lessonPageId?: Maybe<Scalars['String']['output']>;
};

export enum PerformanceStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deassigned = 'DEASSIGNED',
  Excused = 'EXCUSED',
  Graded = 'GRADED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE',
  PeerReviewPending = 'PEER_REVIEW_PENDING',
  Rejected = 'REJECTED',
  Returned = 'RETURNED',
  Submitted = 'SUBMITTED'
}

export enum PerformanceStatusFilterEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deassigned = 'DEASSIGNED',
  Excused = 'EXCUSED',
  Graded = 'GRADED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  NotAssigned = 'NOT_ASSIGNED',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE',
  PeerReviewPending = 'PEER_REVIEW_PENDING',
  Rejected = 'REJECTED',
  Returned = 'RETURNED',
  Submitted = 'SUBMITTED'
}

export enum PerformanceTypeEnum {
  Article = 'ARTICLE',
  Block = 'BLOCK',
  Checkin = 'CHECKIN',
  Course = 'COURSE',
  Exercise = 'EXERCISE',
  ExternalContent = 'EXTERNAL_CONTENT',
  ExternalEvent = 'EXTERNAL_EVENT',
  Homework = 'HOMEWORK',
  Lesson = 'LESSON',
  LiveEvent = 'LIVE_EVENT',
  OfflineEvent = 'OFFLINE_EVENT',
  Path = 'PATH',
  Question = 'QUESTION',
  Quiz = 'QUIZ',
  RecurringExternalEvent = 'RECURRING_EXTERNAL_EVENT',
  RecurringLiveEvent = 'RECURRING_LIVE_EVENT',
  RecurringOfflineEvent = 'RECURRING_OFFLINE_EVENT',
  Scorm = 'SCORM',
  Section = 'SECTION',
  SubTask = 'SUB_TASK',
  Survey = 'SURVEY',
  Task = 'TASK',
  TaskSection = 'TASK_SECTION'
}

export type PerformanceUserFilter = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PerformanceUsersFilters = {
  departmentOrgRole?: InputMaybe<DepartmentOrgRoleFilter>;
  /** get static or dynamic users for given groupIds */
  groupFilter?: InputMaybe<EntityGroupFilter>;
  notInIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PerformanceUsersFiltersType = {
  __typename?: 'PerformanceUsersFiltersType';
  searchText: Scalars['String']['output'];
};

export type PerformancesFilters = {
  archivalReason?: InputMaybe<Array<ArchivalReasonEnum>>;
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  attendanceStatus?: InputMaybe<Array<AttendanceStatusEnum>>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  classroomId?: InputMaybe<Scalars['String']['input']>;
  contentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  direct?: InputMaybe<Scalars['Boolean']['input']>;
  dueDateRange?: InputMaybe<Array<Scalars['String']['input']>>;
  enrollmentType?: InputMaybe<EnrollmentTypeEnum>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityName?: InputMaybe<Scalars['String']['input']>;
  entityTypes?: InputMaybe<Array<PerformanceEntityTypeEnum>>;
  isArchived?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isAttended?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  /** isOverDue: true will return all overdue performances. isOverdue: false or isOverdue: undefined will return all performances */
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  isSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  parentEntityExists?: InputMaybe<Scalars['Boolean']['input']>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  registeredOn?: InputMaybe<DateFilter>;
  /** Filter based on results */
  results?: InputMaybe<Array<QuizResultEnum>>;
  reviewInfoExists?: InputMaybe<Scalars['Boolean']['input']>;
  reviewerId?: InputMaybe<Scalars['String']['input']>;
  reviewsAssigned?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Array<PerformanceStatusEnum>>;
  submissionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  submissionReviewInfoFilter?: InputMaybe<SubmissionReviewInfoFilter>;
  types?: InputMaybe<Array<PerformanceTypeEnum>>;
  userFilter?: InputMaybe<PerformanceUserFilter>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userTypes?: InputMaybe<Array<EventUserTypeEnum>>;
};

export type PerformancesFiltersType = {
  __typename?: 'PerformancesFiltersType';
  attendanceStatus?: Maybe<Array<AttendanceStatusEnum>>;
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  isAttended?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Array<PerformanceStatusEnum>>;
  userTypes?: Maybe<Array<EventUserTypeEnum>>;
};

export type PerformancesWidgetType = {
  __typename?: 'PerformancesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** performances */
  performances?: Maybe<Array<ContentPerformanceEntity>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum PermissionEntities {
  Channel = 'CHANNEL',
  Resource = 'RESOURCE'
}

export type PermissionInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Permitted Actions and Subjects on an Entity */
export type PermittedOnType = {
  __typename?: 'PermittedOnType';
  entity: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
};

/** Attachments on a post */
export type PostAttachmentType = {
  __typename?: 'PostAttachmentType';
  /** channelId of the post to which this attachment is attached */
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Actual media of the attachment */
  media: EntityMediaType;
  postAttachmentPublicImage?: Maybe<EntityMediaType>;
  /** post id to which the attachment is attached */
  postId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user id who has attached this attachment */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** workspaceId of the user who attached this attachment */
  userWorkspaceId?: Maybe<Scalars['String']['output']>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** This returns paginated Post */
export type PostConnections = {
  __typename?: 'PostConnections';
  edges?: Maybe<Array<PostEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** PostContentUpdateProcessingInput */
export type PostContentUpdateProcessingInput = {
  entityId: Scalars['String']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
};

export type PostContentUpdateProcessingInputType = {
  __typename?: 'PostContentUpdateProcessingInputType';
  entityId: Scalars['String']['output'];
  eventType?: Maybe<ContentPublishedEventTypeEnum>;
};

/** Post Edge */
export type PostEdge = {
  __typename?: 'PostEdge';
  node: PostType;
};

export enum PostStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

/** Post/Message of a conversation */
export type PostType = {
  __typename?: 'PostType';
  attachmentCount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<PostAttachmentType>>;
  channel?: Maybe<ChannelType>;
  /** ChannelId of the channel to which post is made */
  channelId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  createdByWorkspaceId?: Maybe<Scalars['String']['output']>;
  currentWorkspaceChannelId: Scalars['String']['output'];
  /** Last message edited time */
  editedAt?: Maybe<Scalars['String']['output']>;
  hiddenBy?: Maybe<UserType>;
  /** Id of User who hid the post */
  hiddenById?: Maybe<Scalars['String']['output']>;
  /** workspaceId of user who hid the post */
  hiddenByWorkspaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Flag to hide the post on the channel */
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** Flag to pin the post on the channel */
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: Maybe<Array<MentionType>>;
  /** Message payload of the post */
  message: Scalars['String']['output'];
  /** ParentId of the post like parentId could be a postId on which this post is acting as a reply but rootId is still the channelId */
  parentId?: Maybe<Scalars['String']['output']>;
  pinnedBy?: Maybe<UserType>;
  /** Id of User who pinned the post */
  pinnedById?: Maybe<Scalars['String']['output']>;
  /** workspaceId of user who pinned the post */
  pinnedByWorkspaceId?: Maybe<Scalars['String']['output']>;
  previews?: Maybe<Array<Scalars['JSONObject']['output']>>;
  /** Reactions of various users the post */
  reactions?: Maybe<Array<ReactionType>>;
  replyCount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status?: Maybe<PostStatusEnum>;
  /** @deprecated use currentWorkspaceChannelId resolver */
  targetChannelId: Scalars['String']['output'];
  /** Title for post used for announcement like posts */
  title?: Maybe<Scalars['String']['output']>;
  /** Type of post */
  type: PostTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  updatedByWorkspaceId?: Maybe<Scalars['String']['output']>;
  /**
   * [Deprecated] Urls of the post
   * @deprecated use urlsV2
   */
  urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Urls of the post */
  urlsV2?: Maybe<Array<Maybe<PostUrlType>>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** workspaceId where the post is stored */
  workspaceId?: Maybe<Scalars['String']['output']>;
};


/** Post/Message of a conversation */
export type PostTypeTargetChannelIdArgs = {
  targetChannelMappings?: InputMaybe<Array<TargetChannelMapping>>;
};

export enum PostTypeEnum {
  Post = 'POST',
  Reply = 'REPLY'
}

export type PostUrlInput = {
  /** Handles preview visibility on the post */
  previewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Url of this post */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PostUrlType = {
  __typename?: 'PostUrlType';
  /** Handles preview visibility on the post */
  previewEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Url of this post */
  url?: Maybe<Scalars['String']['output']>;
};

export type PostsFilters = {
  channelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  postedByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showHiddenPosts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of post one needs to fetch */
  types?: InputMaybe<Array<PostTypeEnum>>;
};

/** Edge */
export type PraiseEdge = {
  __typename?: 'PraiseEdge';
  node: PraiseType;
};

export type PraisePayload = {
  __typename?: 'PraisePayload';
  praise?: Maybe<PraiseType>;
};

export type PraiseType = {
  __typename?: 'PraiseType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  deletedById?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mindsetRatings?: Maybe<Array<PeerFeedbackMindsetRatingType>>;
  post?: Maybe<PostType>;
  postId: Scalars['String']['output'];
  /** This resolver returns channel of a praise */
  praiseChannel?: Maybe<ChannelType>;
  receiverUserIds: Array<Scalars['String']['output']>;
  receiverUsers: Array<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillRatings?: Maybe<Array<PeerFeedbackSkillRatingType>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibleToEveryone: Scalars['Boolean']['output'];
};

export type PraisesFilters = {
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  praiseIds?: InputMaybe<Array<Scalars['String']['input']>>;
  receiverUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Product pricing parameters */
export type PricingType = {
  __typename?: 'PricingType';
  /** Represents product coupons */
  coupons?: Maybe<Array<Scalars['String']['output']>>;
  /** Represents product currency */
  currency: Scalars['String']['output'];
  /** Represents discount in percentage OR fixed */
  discount?: Maybe<DiscountType>;
  /** Represents price of the product */
  price?: Maybe<Scalars['Float']['output']>;
  /** Represents validity of the product */
  validity: Scalars['String']['output'];
};

export enum ProductActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE'
}

/** Product Content Types */
export type ProductCollectionContentType = {
  __typename?: 'ProductCollectionContentType';
  /** Duration of content in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  /** Type of product content */
  type: ProductContentTypeEnum;
};

/** It will resolve Corporate course or course */
export type ProductContentEntityUnion = ContentType | CourseContentType | PathContentType | ProductCollectionContentType;

export enum ProductContentTypeEnum {
  Classroom = 'CLASSROOM',
  CorporateCourse = 'CORPORATE_COURSE',
  Course = 'COURSE',
  Event = 'EVENT',
  Path = 'PATH',
  ProductCollection = 'PRODUCT_COLLECTION',
  Resource = 'RESOURCE'
}

export enum ProductStatusEnum {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Listed = 'LISTED',
  Unlisted = 'UNLISTED'
}

export enum ProductSubjectsEnum {
  Performance = 'PERFORMANCE',
  Product = 'PRODUCT',
  Resource = 'RESOURCE'
}

/** Product Type */
export type ProductType = {
  __typename?: 'ProductType';
  /** Represents SEO settings like pageTitle, productDescription, keywords */
  SEOSettings?: Maybe<SeoSettingsType>;
  childProducts?: Maybe<Array<ProductType>>;
  content?: Maybe<ProductContentEntityUnion>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Description of a product */
  description?: Maybe<Scalars['String']['output']>;
  /** Represents generalSettings */
  generalSettings?: Maybe<GeneralSettingsType>;
  id: Scalars['String']['output'];
  /** Represents media in a product */
  media: Array<DeprecatedMediaType>;
  /** Product metrics */
  metrics?: Maybe<MetricsType>;
  /** Name or title of a product */
  name?: Maybe<Scalars['String']['output']>;
  /** Represents pricing parameters like price, currency, discount, validity, coupon */
  pricingParameters?: Maybe<PricingType>;
  /** ids of the product */
  productIds: Array<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Product Status */
  status: ProductStatusEnum;
  /** StoreId of store to which this product belongs to */
  storeId: Scalars['String']['output'];
  /** Product Type */
  type: ProductTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** User permissions on the product */
  userPermissions: Array<UserProductPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum ProductTypeEnum {
  Classroom = 'CLASSROOM',
  Collection = 'COLLECTION',
  Course = 'COURSE',
  Event = 'EVENT'
}

export type ProgressInfoType = {
  __typename?: 'ProgressInfoType';
  attendanceProgress?: Maybe<Scalars['Float']['output']>;
  postWorkProgress?: Maybe<Scalars['Float']['output']>;
  preWorkProgress?: Maybe<Scalars['Float']['output']>;
  recordingProgress?: Maybe<Scalars['Float']['output']>;
};

export enum ProgressSourceEnum {
  Recording = 'RECORDING'
}

export enum ProviderAvailableOnEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Metric = 'METRIC',
  Okr = 'OKR'
}

/** This returns paginated providers */
export type ProviderConnections = {
  __typename?: 'ProviderConnections';
  edges?: Maybe<Array<ProviderEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  node: ProviderType;
};

/** The Provider model */
export type ProviderType = {
  __typename?: 'ProviderType';
  availableOn: ProviderAvailableOnEnum;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  externalEventCreationStrategies: Array<ExternalEventCreationStrategyEnum>;
  formInputs?: Maybe<Array<FormComponentType>>;
  id: Scalars['String']['output'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  integrationProviderId?: Maybe<Scalars['String']['output']>;
  logo: EntityMediaType;
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Type of the external content */
  verificationMethod?: Maybe<VerificationMethodEnum>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ProvidersWidgetType = {
  __typename?: 'ProvidersWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** marketplace contents */
  providers: PaginatedCommonProvidersPayload;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** static content ids */
  staticIds?: Maybe<Array<Scalars['String']['output']>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type ProvidersWidgetTypeProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

/** Filters to view public certificate */
export type PublicCertificateFilters = {
  sharedId: Scalars['String']['input'];
};

export type PublicCertificateType = {
  __typename?: 'PublicCertificateType';
  certificateImage?: Maybe<EntityMediaType>;
  content?: Maybe<PublicContentType>;
  event?: Maybe<PublicEventType>;
  issuedCertificate: CertificateType;
  issuedOn?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserProfile>;
};

export type PublicContentType = {
  __typename?: 'PublicContentType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: ResourceTypeEnum;
};

export type PublicEventType = {
  __typename?: 'PublicEventType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: EventTypeEnum;
};

export enum PublicMeetingInstanceStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Late = 'LATE',
  Missed = 'MISSED',
  Scheduled = 'SCHEDULED'
}

export enum PublicMeetingStatusEnum {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export enum PublicSkillMatrixStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type PublicWorkspaceType = {
  __typename?: 'PublicWorkspaceType';
  icon?: Maybe<EntityMediaType>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PublishArticleInput = {
  articleId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Publish Article Response */
export type PublishArticlePayload = {
  __typename?: 'PublishArticlePayload';
  article?: Maybe<ArticleContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PublishAssignmentInput = {
  assignerId?: InputMaybe<Scalars['String']['input']>;
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  assignmentItemsDueDateConfig?: InputMaybe<Array<AssignmentItemDueDateConfigInput>>;
  collectionIds: Array<Scalars['String']['input']>;
  contentIds: Array<Scalars['String']['input']>;
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  eventSelections?: InputMaybe<Array<EventSelectionInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfigurations?: InputMaybe<Array<NotificationConfigurationInput>>;
  scheduleDateConfig?: InputMaybe<ScheduleDateAssignmentConfigInput>;
  userGroupIds: Array<Scalars['String']['input']>;
  userGroupSyncConfigs?: InputMaybe<UserGroupSyncAssignmentConfigsInput>;
  userIds: Array<Scalars['String']['input']>;
};

export type PublishAssignmentPayload = {
  __typename?: 'PublishAssignmentPayload';
  assignment: AssignmentType;
};

export type PublishContentConfig = {
  note?: InputMaybe<Scalars['String']['input']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

export type PublishContentConfigPropertiesType = {
  __typename?: 'PublishContentConfigPropertiesType';
  defaultVersion?: Maybe<ResourceVersionTypeEnum>;
  recalibrateConfig?: Maybe<RecalibrateConfigType>;
};

export type PublishCourseInput = {
  courseId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Publish Course-Content Response */
export type PublishCoursePayload = {
  __typename?: 'PublishCoursePayload';
  course?: Maybe<CourseContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PublishJourneyInput = {
  id: Scalars['String']['input'];
};

/** PublishReviewCycle input */
export type PublishReviewCycleInput = {
  /** Id of the review cycle */
  id: Scalars['String']['input'];
};

export type PublishUserSurveyInput = {
  surveyId: Scalars['String']['input'];
};

export type PublishUserSurveyPayload = {
  __typename?: 'PublishUserSurveyPayload';
  success: Scalars['Boolean']['output'];
  userSurvey?: Maybe<UserSurveyType>;
};

export type Query = {
  __typename?: 'Query';
  actionItem: ActionItemPayload;
  actionItems?: Maybe<PaginatedActionItemsPayload>;
  actionPlan?: Maybe<ActionPlanType>;
  actionPlans?: Maybe<PaginatedActionPlanPayload>;
  activityMetrics?: Maybe<PaginatedActivityMetricsPayload>;
  agendaItem: AgendaItemPayload;
  agendaItemTemplate: AgendaItemTemplatePayload;
  agendaItemTemplates: PaginatedAgendaItemTemplatesPayload;
  agendaItems: PaginatedAgendaItemsPayload;
  allConfigs: AllConfigsPayload;
  announcement: AnnouncementType;
  announcements: PaginatedAnnouncementsPayload;
  assignedUsers: UserConnection;
  assignment?: Maybe<AssignmentType>;
  assignmentStats: AssignmentStatsPayload;
  assignments: AssignmentConnection;
  auditMetadatas?: Maybe<AuditMetadataMessageInfoConnection>;
  certificateTemplates: CertificateTemplateConnections;
  certificates?: Maybe<CertificateConnections>;
  /** Fetches the channel for an id if it exists */
  channel?: Maybe<ChannelType>;
  /** Fetches the channel participants by given role type */
  channelParticipants?: Maybe<PaginatedChannelParticipantsPayload>;
  /** Fetch PaginatedChannels satisfying the filters in paginated manner */
  channels: ChannelConnection;
  /** Fetches the event for an id if it exists */
  classroomEvent?: Maybe<ClassroomEventType>;
  commonProvider?: Maybe<CommonProviderType>;
  commonProviders: PaginatedCommonProvidersPayload;
  community?: Maybe<CommunityType>;
  content?: Maybe<ContentEntity>;
  contentPerformances: ContentPerformanceConnection;
  contentVersions: ResourceVersionConnections;
  contents: ContentConnection;
  /** Fetches the current workspace of user */
  currentWorkspace: WorkspaceType;
  databaseData: DatabaseDataConnection;
  /** Fetches the database metadata for an id if it exists */
  databaseMetadata?: Maybe<DatabaseMetadataType>;
  /** Fetch Paginated database metadata satisfying the filters in paginated manner */
  databaseMetadatas: DatabaseMetadataConnection;
  /** Fetches the database view for a view id if it exists */
  databaseView?: Maybe<DatabaseViewType>;
  /** Fetches the department for an id if it exists */
  department?: Maybe<DepartmentType>;
  departments: DepartmentConnections;
  distinctContentsCount: Scalars['Float']['output'];
  distinctUsersCount: Scalars['Float']['output'];
  emailDigest?: Maybe<EmailDigestType>;
  emailDigests?: Maybe<PaginatedEmailDigestsPayload>;
  embeddedEntitiesInfo: EmbeddedEntitiesInfoPayload;
  event?: Maybe<EventUnion>;
  eventAttendeesV2: ContentPerformanceConnection;
  events?: Maybe<PaginatedEventsPayload>;
  exerciseSubmissionsForReviewer: ContentPerformanceConnection;
  externalUserPerformanceInfos: PaginatedExternalUserPerformanceInfosPayload;
  featureFlags: FeatureFlagsPayload;
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  file?: Maybe<FileSchema>;
  files?: Maybe<PaginatedFilesPayload>;
  flagSurveyResponses?: Maybe<PaginatedFlagSurveyResponsesPayload>;
  generativeTask: GenerativeTaskPayload;
  getAllSkillCategories: AllSkillCategoriesPayload;
  getJourneyStepTraineeSession?: Maybe<JourneyStepTraineeSessionType>;
  getMissingParticipantsDataCheckCounts: PaginatedMissingParticipantsDataCheckCountsPayload;
  getQuestionsParentContents: ContentConnection;
  getRecommendedContentsForSkillIds: ContentConnection;
  globalSearch: GlobalSearchPayload;
  group?: Maybe<GroupUnion>;
  groups: GroupConnection;
  importJobSampleCsvRows: ImportJobSampleCsvRowsPayload;
  instructorAvailabilities: InstructorProviderAvailabilitiesPayload;
  integrationProvider: IntegrationProviderPayload;
  integrationProviders: PaginatedIntegrationProvidersPayload;
  integrationRedirectUrl: IntegrationRedirectUrlPayload;
  /** Checks if given department name is available */
  isDepartmentNameAvailable: IsDepartmentNameAvailablePayload;
  jiraIssues: PaginatedJiraIssuesPayload;
  job?: Maybe<JobType>;
  jobs: PaginatedJobsPayload;
  journey: JourneyPayload;
  journeyPerformances?: Maybe<PaginatedJourneyPerformancesPayload>;
  journeySteps?: Maybe<PaginatedJourneyStepsPayload>;
  journeys?: Maybe<PaginatedJourneysPayload>;
  learnerActivityMetrics: AllActivityMetricsPayload;
  learnerEvents?: Maybe<PaginatedEventsPayload>;
  lookUpActionItemValues: LookUpActionItemValuesPayload;
  marketplaceContentCategories: PaginatedMarketplaceContentCategoriesPayload;
  marketplaceContents: PaginatedMarketplaceContentsPayload;
  meeting: MeetingPayload;
  meetingInstance: MeetingInstancePayload;
  meetingInstances?: Maybe<PaginatedMeetingInstancesPayload>;
  meetings?: Maybe<PaginatedMeetingsPayload>;
  mentionSearch: MentionSearchPayload;
  metric?: Maybe<MetricSchema>;
  metrics?: Maybe<PaginatedMetricsPayload>;
  notificationTemplates: PaginatedNotificationTemplatesPayload;
  okr?: Maybe<OkrUnion>;
  okrCycle?: Maybe<OkrCycle>;
  okrCycles: OkrCycleConnection;
  okrs?: Maybe<PaginatedOkRsPayload>;
  orgRole?: Maybe<OrgRoleType>;
  orgRoles: OrgRoleConnections;
  page?: Maybe<PageType>;
  pageComponent: PageComponentPayload;
  pagePreview: PagePreviewPayload;
  page__next?: Maybe<PageType__Next>;
  paginatedBookmarks?: Maybe<PaginatedBookmarkConnections>;
  paginatedGroupedActionItems?: Maybe<PaginatedGroupedActionItemsPayload>;
  parentContents?: Maybe<GetParentContentsPayload>;
  peerFeedback?: Maybe<PeerFeedbackSchema>;
  peerFeedbackTemplates?: Maybe<PaginatedPeerFeedbackTemplatesPayload>;
  peerFeedbacks?: Maybe<PaginatedPeerFeedbacksPayload>;
  performanceInteractions: PerformanceInteractionConnections;
  post?: Maybe<PostType>;
  /** Fetch PaginatedPosts satisfying the filters in paginated manner */
  posts: PostConnections;
  praises: PaginatedPraisesPayload;
  providers: ProviderConnections;
  publicCertificate?: Maybe<PublicCertificateType>;
  questions: QuestionConnections;
  relatedConnections: RelatedConnectionsPayload;
  report?: Maybe<ReportType>;
  reports?: Maybe<PaginatedReportsPayload>;
  resource?: Maybe<ResourceType>;
  resources: ResourceConnections;
  reviewCycle: ReviewCyclePayload;
  /** Returns all reviewees to be reviewed by the user in context for a given reviewCycle */
  reviewCycleMyReviewees?: Maybe<ReviewCycleMyRevieweesPayload>;
  reviewCycles: PaginatedReviewCyclesPayload;
  reviewShareStatusDistribution: UserCycleReviewShareStatusDistributionPayload;
  role?: Maybe<RoleType>;
  rolePermissionDescriptions?: Maybe<RolePermissionDescriptionsPayload>;
  roles: RoleConntection;
  scorecard: ScorecardType;
  scorecards: ScorecardConnections;
  selectGroups?: Maybe<SelectGroupsPayload>;
  sharedConnection?: Maybe<SharedConnectionInfo>;
  sharedConnections: SharedConnectionsInfoPayload;
  sharedView: SharedViewPayload;
  sharedViews: PaginatedSharedViewsPayload;
  skill?: Maybe<SkillType>;
  skillMatrices: SkillMatrixConnection;
  skillMatrix?: Maybe<SkillMatrixType>;
  skills: SkillConnections;
  staffRoles?: Maybe<RoleConntection>;
  statuses: PaginatedStatusesPayload;
  /** Fetches the sub workspaces of workspace */
  subWorkspaces?: Maybe<Array<PublicWorkspaceType>>;
  submissionToReview: SubmissionsToReviewPayload;
  surveyResponses: DatabaseDataConnection;
  tag?: Maybe<TagUnion>;
  tagOptions: TagOptionConnection;
  tags?: Maybe<PaginatedTagsPayload>;
  tagsInfo?: Maybe<PaginatedTagsPayload>;
  teamReviewerReviewCycleInfo: TeamReviewerReviewCycleInfoPayload;
  template?: Maybe<TemplateUnion>;
  templateCategories: PaginatedTemplateCategoriesPayload;
  templates: PaginatedTemplatesPayload;
  theme?: Maybe<ThemeType>;
  themes?: Maybe<PaginatedThemePayload>;
  track?: Maybe<TrackType>;
  tracks: PaginatedTracksPayload;
  /** Fetch previews of url */
  urlPreviews: GetUrlPreviewsPayload;
  /** Fetches the channel for an id if it exists */
  user?: Maybe<UserType>;
  userActivityMetrics: PaginatedUserActivityMetricsPayload;
  userAnnouncements: PaginatedUserAnnouncementsPayload;
  userEntityActionTaskViewConfigs?: Maybe<UserViewFieldsPayload>;
  userNominationCountDistributionByStatus: UserNominationCountDistributionPayload;
  userNominations: PaginatedUserNominationsPayload;
  userNotifications: PaginatedUserNotificationsPayload;
  userPublicAPICredentials: PaginatedUserPublicApiCredentialsPayload;
  userSearchActivities?: Maybe<UserSearchActivityConnections>;
  userSurvey?: Maybe<UserSurveyType>;
  userSurveyComments?: Maybe<PaginatedUserSurveyCommentsPayload>;
  userSurveyResponses?: Maybe<PaginatedUserSurveyResponsesPayload>;
  userSurveys?: Maybe<PaginatedUserSurveysPayload>;
  userSurveysToSubmit?: Maybe<PaginatedUserSurveysPayload>;
  users: UserConnection;
  widget?: Maybe<WidgetUnion>;
};


export type QueryActionItemArgs = {
  filters?: InputMaybe<ActionItemFilters>;
};


export type QueryActionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ActionItemsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryActionPlanArgs = {
  filters?: InputMaybe<ActionPlanFilters>;
};


export type QueryActionPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ActionPlanFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryActivityMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ActivityMetricsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryAgendaItemArgs = {
  filters?: InputMaybe<AgendaItemFilters>;
};


export type QueryAgendaItemTemplateArgs = {
  filters?: InputMaybe<AgendaItemTemplateFilters>;
};


export type QueryAgendaItemTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgendaItemTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryAgendaItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AgendaItemsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryAllConfigsArgs = {
  filters?: InputMaybe<ConfigsFilters>;
};


export type QueryAnnouncementArgs = {
  input: AnnouncementInput;
};


export type QueryAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AnnouncementFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryAssignedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AssignedUsersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryAssignmentArgs = {
  filters: GetByIdFilter;
};


export type QueryAssignmentStatsArgs = {
  filters?: InputMaybe<AssignmentStatsInput>;
};


export type QueryAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AssignmentsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryAuditMetadatasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AuditMetadataFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryCertificateTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CertificateTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryCertificatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CertificatesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryChannelArgs = {
  filters: ChannelFilters;
};


export type QueryChannelParticipantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ChannelParticipantsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ChannelsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryClassroomEventArgs = {
  filters: GetByIdFilter;
};


export type QueryCommonProviderArgs = {
  filters?: InputMaybe<GetProviderFilters>;
};


export type QueryCommonProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetProvidersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryCommunityArgs = {
  filters: GetByIdFilter;
};


export type QueryContentArgs = {
  filters: GetResourceByIdFilter;
};


export type QueryContentPerformancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PerformancesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryContentVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ResourceVersionFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryDatabaseDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DatabaseDatasFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryDatabaseMetadataArgs = {
  filters: DatabaseMetadataFilters;
};


export type QueryDatabaseMetadatasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DatabaseMetadatasFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryDatabaseViewArgs = {
  filters: DatabaseViewFilters;
};


export type QueryDepartmentArgs = {
  filters: GetByIdFilter;
};


export type QueryDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DepartmentsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryDistinctContentsCountArgs = {
  contentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryDistinctUsersCountArgs = {
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryEmailDigestArgs = {
  filters: GetByIdFilter;
};


export type QueryEmailDigestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<EmailDigestsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryEmbeddedEntitiesInfoArgs = {
  input: GetEmbeddedEntitiesInfoInput;
};


export type QueryEventArgs = {
  filters: EventFilters;
};


export type QueryEventAttendeesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: EventAttendeesFiltersV2;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<EventsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryExerciseSubmissionsForReviewerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExerciseSubmissionsForReviewerFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryExternalUserPerformanceInfosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ExternalUserPerformanceInfosFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryFeatureFlagsArgs = {
  input: FeatureFlagsInput;
};


export type QueryFeedbackQuestionsArgs = {
  filters: FeedbackQuestionFilter;
};


export type QueryFileArgs = {
  filters: GetByIdFilter;
};


export type QueryFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FilesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryFlagSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FlagSurveyResponsesFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryGenerativeTaskArgs = {
  filters?: InputMaybe<GenerativeTaskFilters>;
};


export type QueryGetAllSkillCategoriesArgs = {
  input: AllSkillCategoriesInput;
};


export type QueryGetJourneyStepTraineeSessionArgs = {
  input: GetJourneyStepTraineeSessionInput;
};


export type QueryGetMissingParticipantsDataCheckCountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: MissingParticipantsDataCheckCountsFilters;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryGetQuestionsParentContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<QuestionsParentContentsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryGetRecommendedContentsForSkillIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: GetRecommendedContentIdsForSkillIdsInput;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryGlobalSearchArgs = {
  filters?: InputMaybe<GlobalSearchFilter>;
};


export type QueryGroupArgs = {
  filters: GetByIdFilter;
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GroupsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryImportJobSampleCsvRowsArgs = {
  data: GetImportJobSampleCsvRowsInput;
};


export type QueryInstructorAvailabilitiesArgs = {
  data: InstructorProviderAvailabilitiesInput;
};


export type QueryIntegrationProviderArgs = {
  filters?: InputMaybe<IntegrationProviderFilters>;
};


export type QueryIntegrationProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<IntegrationProvidersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryIntegrationRedirectUrlArgs = {
  data?: InputMaybe<IntegrationRedirectUrlInput>;
};


export type QueryIsDepartmentNameAvailableArgs = {
  input: IsDepartmentNameAvailableInput;
};


export type QueryJiraIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<JiraIssueFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryJobArgs = {
  filters: JobFilters;
};


export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<JobsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryJourneyArgs = {
  input: JourneyInput;
};


export type QueryJourneyPerformancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<JourneyPerformanceFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryJourneyStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<JourneyStepFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryJourneysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<JourneyFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryLearnerActivityMetricsArgs = {
  input?: InputMaybe<LearnerActivityMetricsInput>;
};


export type QueryLearnerEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<EventsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryLookUpActionItemValuesArgs = {
  filters?: InputMaybe<ActionItemsFilters>;
  lookUpKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMarketplaceContentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MarketplaceContentCategoriesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryMarketplaceContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MarketplaceContentsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryMeetingArgs = {
  filters?: InputMaybe<MeetingFilters>;
};


export type QueryMeetingInstanceArgs = {
  filters?: InputMaybe<MeetingInstanceFilters>;
};


export type QueryMeetingInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MeetingInstancesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryMeetingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MeetingsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryMentionSearchArgs = {
  filters?: InputMaybe<MentionFilters>;
};


export type QueryMetricArgs = {
  filters: GetByIdFilter;
};


export type QueryMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MetricsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryNotificationTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<NotificationTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryOkrArgs = {
  filters: GetOkrFilters;
};


export type QueryOkrCycleArgs = {
  filters: OkrCycleFilters;
};


export type QueryOkrCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OkrCyclesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryOkrsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetOkRsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryOrgRoleArgs = {
  filters: GetByIdFilter;
};


export type QueryOrgRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrgRolesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryPageArgs = {
  data: PageInput;
};


export type QueryPageComponentArgs = {
  filters: PageComponentFilters;
};


export type QueryPagePreviewArgs = {
  data: PagePreviewInput;
};


export type QueryPage__NextArgs = {
  data: PageInput;
};


export type QueryPaginatedBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<BookmarkFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryPaginatedGroupedActionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ActionItemsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  firstEntity?: InputMaybe<Scalars['Float']['input']>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<SortType>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type QueryParentContentsArgs = {
  filters: GetParentContentsInput;
};


export type QueryPeerFeedbackArgs = {
  filters: GetByIdFilter;
};


export type QueryPeerFeedbackTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PeerFeedbackTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryPeerFeedbacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PeerFeedbacksFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryPerformanceInteractionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PerformanceInteractionFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryPostArgs = {
  filters: GetByIdFilter;
};


export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PostsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
  targetChannelMappings?: InputMaybe<Array<TargetChannelMapping>>;
};


export type QueryPraisesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PraisesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetProvidersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryPublicCertificateArgs = {
  filters?: InputMaybe<PublicCertificateFilters>;
};


export type QueryQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<QuestionFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryRelatedConnectionsArgs = {
  filters: RelatedConnectionsFilters;
};


export type QueryReportArgs = {
  filters: GetByIdFilter;
};


export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ReportsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryResourceArgs = {
  filters: GetByIdFilter;
};


export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<GetResourcesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryReviewCycleArgs = {
  filters?: InputMaybe<ReviewCycleFilters>;
};


export type QueryReviewCycleMyRevieweesArgs = {
  filters?: InputMaybe<ReviewCycleMyRevieweesInput>;
};


export type QueryReviewCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ReviewCyclesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryReviewShareStatusDistributionArgs = {
  input?: InputMaybe<UserCycleReviewShareStatusDistributionInput>;
};


export type QueryRoleArgs = {
  filters: GetByIdFilter;
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<RolesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryScorecardArgs = {
  filters: GetByIdFilter;
};


export type QueryScorecardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ScorecardsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QuerySelectGroupsArgs = {
  input: SelectGroupsInput;
};


export type QuerySharedConnectionArgs = {
  filters?: InputMaybe<SharedConnectionFilters>;
};


export type QuerySharedConnectionsArgs = {
  filters?: InputMaybe<SharedConnectionsFilters>;
};


export type QuerySharedViewArgs = {
  input: SharedViewInput;
};


export type QuerySharedViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SharedViewFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QuerySkillArgs = {
  filters: GetByIdFilter;
};


export type QuerySkillMatricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SkillMatricesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QuerySkillMatrixArgs = {
  filters: SkillMatrixFilters;
};


export type QuerySkillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SkillsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryStaffRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<RolesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<StatusesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QuerySubmissionToReviewArgs = {
  input: SubmissionsToReviewInput;
};


export type QuerySurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DatabaseDatasFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
  surveyFilters?: InputMaybe<SurveyResponsesFilters>;
};


export type QueryTagArgs = {
  filters: TagFilters;
};


export type QueryTagOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TagPaginatedOptionsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TagsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryTagsInfoArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TagsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryTeamReviewerReviewCycleInfoArgs = {
  input: TeamReviewerReviewCycleInfoInput;
};


export type QueryTemplateArgs = {
  filters: TemplateFilters;
};


export type QueryTemplateCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TemplateCategoriesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TemplatesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryThemeArgs = {
  filters?: InputMaybe<ThemeFilters>;
};


export type QueryThemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ThemeFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryTrackArgs = {
  filters?: InputMaybe<TrackFilters>;
};


export type QueryTracksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TracksFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryUrlPreviewsArgs = {
  input: GetUrlPreviewsInput;
};


export type QueryUserArgs = {
  filters: UserFilters;
};


export type QueryUserActivityMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserActivityMetricsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryUserAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserAnnouncementFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryUserEntityActionTaskViewConfigsArgs = {
  input: UserEntityActionsInput;
};


export type QueryUserNominationCountDistributionByStatusArgs = {
  input: UserNominationCountDistributionInput;
};


export type QueryUserNominationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserNominationsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserNotificationsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryUserPublicApiCredentialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserPublicApiCredentialsFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryUserSearchActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchActivitiesFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryUserSurveyArgs = {
  filters?: InputMaybe<UserSurveyFilter>;
};


export type QueryUserSurveyCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSurveyCommentsFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryUserSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSurveyResponsesFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryUserSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSurveysFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryUserSurveysToSubmitArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSurveysToSubmitFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SortType>>>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UsersFilters>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type QueryWidgetArgs = {
  filters: WidgetFilters;
};

/** Defines  view query */
export type QueryInput = {
  aggregation?: InputMaybe<AggregationSetInput>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  sort?: InputMaybe<SortSetInput>;
};

/** Defines sorting criteria for a particular field */
export type QuerySortType = {
  __typename?: 'QuerySortType';
  /** Sort Order */
  order: OrderEnum;
  /** Field Id */
  property: Scalars['String']['output'];
};

/** Defines queries that needs to be performed over database */
export type QueryType = {
  __typename?: 'QueryType';
  /** Provides aggregation on given Field Id */
  aggregation?: Maybe<Array<AggregationType>>;
  /** Collection of complex filter queries */
  filterGroup?: Maybe<FilterGroupType>;
  /** Provides sorting on given Field Id */
  sort?: Maybe<Array<QuerySortType>>;
};

export enum QuestionApplicableOnEnum {
  Form = 'FORM',
  PerformanceReview = 'PERFORMANCE_REVIEW',
  Quiz = 'QUIZ',
  UserSurvey = 'USER_SURVEY'
}

/** This is the Question Association schema */
export type QuestionAssociation = {
  __typename?: 'QuestionAssociation';
  entityAssociation: EntityAssociation;
  type: ResourceTypeEnum;
};

/** This is the Question Association schema */
export type QuestionAssociationInput = {
  entityAssociation: EntityAssociationInput;
  type: ResourceTypeEnum;
};

export type QuestionAttachmentsSetInput = {
  /** Attachments to be updated */
  set: Array<CreateMediaInput>;
};

/** This returns paginated Questions */
export type QuestionConnections = {
  __typename?: 'QuestionConnections';
  edges?: Maybe<Array<QuestionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  node: QuestionType;
};

export type QuestionFilters = {
  /** Duplicate created from which question */
  actualQuestionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  applicableOn?: InputMaybe<Array<QuestionApplicableOnEnum>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  difficulty?: InputMaybe<Array<DifficultyEnum>>;
  /** Pass false when all questions are to be fetched */
  excludeAssociatedQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  /** ids of questions to fetch */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  notInIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Type of question text types one needs to fetch */
  questionTextTypes?: InputMaybe<Array<QuestionTextTypeEnum>>;
  /** Type of question one needs to fetch */
  questionTypes?: InputMaybe<Array<QuestionTypeEnum>>;
  quizId?: InputMaybe<Scalars['String']['input']>;
  /** Search to perform regex search on the question text and option text */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** SkillIds with which questions are connected */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Status of question one needs to fetch */
  status?: InputMaybe<Array<QuestionStatusEnum>>;
  /** Sub-Types of question one needs to fetch */
  subTypes?: InputMaybe<Array<QuestionSubTypeEnum>>;
  themeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

export type QuestionFiltersType = {
  __typename?: 'QuestionFiltersType';
  /** Duplicate created from which question */
  actualQuestionIds?: Maybe<Array<Scalars['String']['output']>>;
  createdByIds?: Maybe<Array<Scalars['String']['output']>>;
  difficulty?: Maybe<Array<DifficultyEnum>>;
  /** Pass false when all questions are to be fetched */
  excludeAssociatedQuestions?: Maybe<Scalars['Boolean']['output']>;
  /** ids of questions to fetch */
  ids?: Maybe<Array<Scalars['String']['output']>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  notInIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Type of question text types one needs to fetch */
  questionTextTypes?: Maybe<Array<QuestionTextTypeEnum>>;
  /** Type of question one needs to fetch */
  questionTypes?: Maybe<Array<QuestionTypeEnum>>;
  quizId?: Maybe<Scalars['String']['output']>;
  /** Search to perform regex search on the question text and option text */
  searchText?: Maybe<Scalars['String']['output']>;
  /** SkillIds with which questions are connected */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Status of question one needs to fetch */
  status?: Maybe<Array<QuestionStatusEnum>>;
  /** Sub-Types of question one needs to fetch */
  subTypes?: Maybe<Array<QuestionSubTypeEnum>>;
  versionStatus?: Maybe<ResourceStatusEnum>;
};

export enum QuestionGenerationType {
  Dynamic = 'DYNAMIC',
  Random = 'RANDOM',
  Shuffle = 'SHUFFLE',
  Static = 'STATIC'
}

export type QuestionImportInputPropsInput = {
  /** Letting backend API know, that we need to publish question only */
  publishQuestions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionImportInputPropsType = {
  __typename?: 'QuestionImportInputPropsType';
  /** Letting backend API know, that we need to publish question only */
  publishQuestions?: Maybe<Scalars['Boolean']['output']>;
};

export type QuestionImportMetadataType = {
  __typename?: 'QuestionImportMetadataType';
  createdQuestionsCount: Scalars['Float']['output'];
};

/** This is the input for Question creation */
export type QuestionInput = {
  /** if provided, a duplicate question is created using actualQuestionId */
  actualQuestionId?: InputMaybe<Scalars['String']['input']>;
  /** Entities which can use this question */
  applicableOn?: InputMaybe<Array<QuestionApplicableOnEnum>>;
  associations?: InputMaybe<Array<QuestionAssociationInput>>;
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  correctAnswerFeedback?: InputMaybe<Scalars['String']['input']>;
  customRatingComponent?: InputMaybe<CustomRatingComponentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Question difficulty */
  difficulty?: InputMaybe<DifficultyEnum>;
  ftbComponents?: InputMaybe<Array<FtbComponentInput>>;
  globalDistractors?: InputMaybe<Array<DistractorInput>>;
  imageHotspotComponents?: InputMaybe<Array<ImageHotspotComponentInput>>;
  incorrectAnswerFeedback?: InputMaybe<Scalars['String']['input']>;
  mtfComponent?: InputMaybe<MtfComponentInput>;
  options?: InputMaybe<Array<OptionInput>>;
  order?: InputMaybe<Scalars['Float']['input']>;
  pairs?: InputMaybe<Array<PairInput>>;
  questionText?: InputMaybe<Scalars['String']['input']>;
  questionTextType?: InputMaybe<QuestionTextTypeEnum>;
  questionType?: InputMaybe<QuestionTypeEnum>;
  settings?: InputMaybe<QuestionSettingsInput>;
  /** skillIds associated with the question */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Status in which the current question is */
  status?: InputMaybe<QuestionStatusEnum>;
  /** Sub type of question like multi select, single select */
  subType?: InputMaybe<QuestionSubTypeEnum>;
  subWorkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  /** Ids of themes to which this question should belong */
  themeIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This is the input for Question creation */
export type QuestionPayload = {
  __typename?: 'QuestionPayload';
  /** Id of question from which this question was created */
  actualQuestionId?: Maybe<Scalars['String']['output']>;
  associations?: Maybe<Array<QuestionAssociation>>;
  attachments?: Maybe<Array<EntityMediaType>>;
  correctAnswerFeedback?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  difficulty?: Maybe<DifficultyEnum>;
  ftbComponents?: Maybe<Array<FtbComponent>>;
  globalDistractors?: Maybe<Array<Distractor>>;
  imageHotspotComponents?: Maybe<Array<ImageHotspotComponent>>;
  incorrectAnswerFeedback?: Maybe<Scalars['String']['output']>;
  mtfComponent?: Maybe<Array<MtfComponent>>;
  options?: Maybe<Array<Option>>;
  pairs?: Maybe<Array<Pair>>;
  questionText: Scalars['String']['output'];
  questionTextType: QuestionTextTypeEnum;
  questionType: QuestionTypeEnum;
  settings?: Maybe<QuestionSettings>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  /** status of question */
  status?: Maybe<QuestionStatusEnum>;
  /** Sub type of question like multi select, single select */
  subType?: Maybe<QuestionSubTypeEnum>;
};

/** Store Question level attempts on Quiz */
export type QuestionPerformanceAttempt = {
  __typename?: 'QuestionPerformanceAttempt';
  /** tells evaluation status of the question */
  evaluationStatus?: Maybe<PerformanceEvaluationStatusEnum>;
  isSubmitted: Scalars['Boolean']['output'];
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** For any given parent, track for which attempt the answer is given */
  parentAttemptId: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  /** tells in which state the user performance is */
  status: PerformanceStatusEnum;
  submission?: Maybe<QuestionSubmissionType>;
  timeSpent: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** User Performance on Question */
export type QuestionPerformanceType = {
  __typename?: 'QuestionPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** History of all the selections and score calculations for a user for a question, including the current attempt */
  allAttempts: Array<QuestionPerformanceAttempt>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  /** History of all the selections and score calculations for a user for a question */
  attempts: Array<QuestionPerformanceAttempt>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  /** This will store the type of content to which this question belongs to, example REVIEW_QUIZ, IN_LESSON_QUIZ, STATIC etc */
  contentType: PerformanceContentTypeEnum;
  /** correct answer for fill in the blanks */
  correctFTBAnswers: Array<FtbComponent>;
  /** correct answer for image hotspot */
  correctImageHotspotAnswers: Array<ImageHotspotComponent>;
  /** correct answer for match the following */
  correctMTFPairings: Array<MtfPairing>;
  /** correct options */
  correctOptions: Array<Option>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** tells evaluation status of the question */
  evaluationStatus?: Maybe<PerformanceEvaluationStatusEnum>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Stores the attempt associated with max quiz score */
  maxSubmissionDetails?: Maybe<QuestionPerformanceAttempt>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** For any given parent, track for which attempt the answer is given */
  parentAttemptId: Scalars['String']['output'];
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** Parent Id inside which quiz is taking place. ParentId can be of quiz if user has taken quiz directly */
  parentEntityId: Scalars['String']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  submission?: Maybe<QuestionSubmissionType>;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent: Scalars['Float']['output'];
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};


/** User Performance on Question */
export type QuestionPerformanceTypeCorrectFtbAnswersArgs = {
  filters?: InputMaybe<CorrectOptionsFilters>;
};


/** User Performance on Question */
export type QuestionPerformanceTypeCorrectImageHotspotAnswersArgs = {
  filters?: InputMaybe<CorrectOptionsFilters>;
};


/** User Performance on Question */
export type QuestionPerformanceTypeCorrectMtfPairingsArgs = {
  filters?: InputMaybe<CorrectOptionsFilters>;
};


/** User Performance on Question */
export type QuestionPerformanceTypeCorrectOptionsArgs = {
  filters?: InputMaybe<CorrectOptionsFilters>;
};

export type QuestionResponseInput = {
  post?: InputMaybe<CreatePostInput>;
  questionId: Scalars['String']['input'];
  questionSurveyResponse?: InputMaybe<SurveyResponseInput>;
  recurrenceNumber?: InputMaybe<Scalars['Float']['input']>;
  themeId?: InputMaybe<Scalars['String']['input']>;
  userSurveyQuestionResponseId?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionReviewOptionResponseInput = {
  optionId: Scalars['String']['input'];
  optionText: Scalars['String']['input'];
};

export type QuestionReviewOptionResponseType = {
  __typename?: 'QuestionReviewOptionResponseType';
  optionId: Scalars['String']['output'];
  optionText: Scalars['String']['output'];
};

export type QuestionReviewRatingResponseInput = {
  scale: Scalars['Float']['input'];
  value: Scalars['Float']['input'];
};

export type QuestionReviewRatingResponseType = {
  __typename?: 'QuestionReviewRatingResponseType';
  scale: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type QuestionReviewResponseInput = {
  enpsResponse?: InputMaybe<Scalars['Float']['input']>;
  likertResponse?: InputMaybe<Scalars['Float']['input']>;
  optionResponse?: InputMaybe<Array<QuestionReviewOptionResponseInput>>;
  ratingResponse?: InputMaybe<QuestionReviewRatingResponseInput>;
  responseType: QuestionTypeEnum;
  textResponse?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionReviewResponseType = {
  __typename?: 'QuestionReviewResponseType';
  enpsResponse?: Maybe<Scalars['Float']['output']>;
  likertResponse?: Maybe<Scalars['Float']['output']>;
  optionResponse?: Maybe<Array<QuestionReviewOptionResponseType>>;
  ratingResponse?: Maybe<QuestionReviewRatingResponseType>;
  responseType: QuestionTypeEnum;
  textResponse?: Maybe<Scalars['String']['output']>;
};

/** SkillReview Schema */
export type QuestionReviewType = {
  __typename?: 'QuestionReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** feedback from reviewer for the skillReview */
  feedback?: Maybe<InstructionType>;
  id: Scalars['String']['output'];
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  questionId: Scalars['String']['output'];
  questionReviewResponse: QuestionReviewResponseType;
  reviewCycleId: Scalars['String']['output'];
  /** reviewType */
  reviewType: Scalars['String']['output'];
  /** resolve reviewer for the QuestionReview */
  reviewer?: Maybe<UserType>;
  /** user who reviewed the assignment */
  reviewerId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** resolve user for the QuestionReview */
  user?: Maybe<UserType>;
  /** user who is getting marks */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type QuestionReviewUserResponseInput = {
  feedback?: InputMaybe<InstructionInput>;
  questionId: Scalars['String']['input'];
  questionReviewResponse: QuestionReviewResponseInput;
};

/** Settings available on Question */
export type QuestionSettings = {
  __typename?: 'QuestionSettings';
  enableDescription?: Maybe<Scalars['Boolean']['output']>;
  gradedBy?: Maybe<GradedByEnum>;
  isGradable?: Maybe<Scalars['Boolean']['output']>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
  ratingScale?: Maybe<Scalars['Float']['output']>;
  retakeAllowedCount?: Maybe<Scalars['Float']['output']>;
  shuffleOptions?: Maybe<Scalars['Boolean']['output']>;
  textLength?: Maybe<QuestionTextlengthEnum>;
  /** Time limit in seconds */
  timeLimit?: Maybe<Scalars['Float']['output']>;
};

/** Settings available on Question */
export type QuestionSettingsInput = {
  enableDescription?: InputMaybe<Scalars['Boolean']['input']>;
  gradedBy?: InputMaybe<GradedByEnum>;
  isGradable?: InputMaybe<Scalars['Boolean']['input']>;
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
  ratingScale?: InputMaybe<Scalars['Float']['input']>;
  retakeAllowedCount?: InputMaybe<Scalars['Float']['input']>;
  /** while rendering options, should it be shuffled is controlled by this flag */
  shuffleOptions?: InputMaybe<Scalars['Boolean']['input']>;
  textLength?: InputMaybe<QuestionTextlengthEnum>;
  /** Time limit in seconds */
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type QuestionSkillIdsSetInput = {
  /** Attachments to be updated */
  set: Array<Scalars['String']['input']>;
};

export enum QuestionStatusEnum {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum QuestionSubTypeEnum {
  CustomRating = 'CUSTOM_RATING',
  DragAndDrop = 'DRAG_AND_DROP',
  Dropdown = 'DROPDOWN',
  LikertRating = 'LIKERT_RATING',
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT',
  TypeAnswer = 'TYPE_ANSWER'
}

/** Question submission */
export type QuestionSubmissionType = {
  __typename?: 'QuestionSubmissionType';
  ftbResponse?: Maybe<Array<FtbResponse>>;
  imageHotspotResponse?: Maybe<Array<ImageHotspotResponse>>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  mtfResponse?: Maybe<Array<MtfResponse>>;
  optionResponse?: Maybe<Array<OptionResponse>>;
  responseType: ResourceResponseTypeEnum;
  score?: Maybe<Scalars['Float']['output']>;
  submittedAt: Scalars['String']['output'];
};

export enum QuestionTextTypeEnum {
  Blocks = 'BLOCKS',
  Html = 'HTML',
  SimpleText = 'SIMPLE_TEXT',
  Url = 'URL'
}

export enum QuestionTextlengthEnum {
  Long = 'LONG',
  Short = 'SHORT'
}

export type QuestionThemeIdsSetInput = {
  /** ThemeIds to be updated */
  set: Array<Scalars['String']['input']>;
};

/** This is the Question schema */
export type QuestionType = {
  __typename?: 'QuestionType';
  /** Id of question from which this question was created */
  actualQuestionId?: Maybe<Scalars['String']['output']>;
  /** Entities in which this questio can be used */
  applicableOn?: Maybe<Array<QuestionApplicableOnEnum>>;
  /** Association of the question */
  associations: Array<QuestionAssociation>;
  attachments?: Maybe<Array<EntityMediaType>>;
  correctAnswerFeedback?: Maybe<Scalars['String']['output']>;
  /** correct answer for fill in the blanks */
  correctFTBAnswers: Array<FtbComponent>;
  /** correct answer for image hotspot */
  correctImageHotspotAnswers: Array<ImageHotspotComponent>;
  /** correct answer for match the following */
  correctMTFPairings: Array<MtfPairing>;
  /** correct options for staff members */
  correctOptions: Array<Option>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  customRatingComponent?: Maybe<CustomRatingComponent>;
  description?: Maybe<Scalars['String']['output']>;
  difficulty?: Maybe<DifficultyEnum>;
  /** constituting components for fill in the blanks */
  ftbComponents: Array<FtbComponent>;
  /** All the possible answers using global distractor for various components */
  globalDistractedOptions: Array<Distractor>;
  globalDistractors?: Maybe<Array<Distractor>>;
  id: Scalars['String']['output'];
  /** constituting components for image hotspot */
  imageHotspotComponents: Array<ImageHotspotComponent>;
  incorrectAnswerFeedback?: Maybe<Scalars['String']['output']>;
  /** Tells whether the question can be used in newly created quiz from now */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  mtfComponent?: Maybe<MtfComponent>;
  options?: Maybe<Array<Option>>;
  pairs?: Maybe<Array<Pair>>;
  /** Get published version of question */
  publishedQuestion?: Maybe<QuestionType>;
  questionText?: Maybe<Scalars['String']['output']>;
  questionTextType?: Maybe<QuestionTextTypeEnum>;
  questionType: QuestionTypeEnum;
  settings?: Maybe<QuestionSettings>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Number of skills attached to this question */
  skillCount: Scalars['Float']['output'];
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  status: QuestionStatusEnum;
  /** Sub type of question like multi select, single select */
  subType?: Maybe<QuestionSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** Themes in which this question belongs */
  themeIds?: Maybe<Array<Scalars['String']['output']>>;
  themes?: Maybe<Array<ThemeType>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Number of published quizes in which this question has been used */
  usageCount: Scalars['Float']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


/** This is the Question schema */
export type QuestionTypeMtfComponentArgs = {
  shuffleMTFComponents?: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the Question schema */
export type QuestionTypeOptionsArgs = {
  shuffleOptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum QuestionTypeEnum {
  Audio = 'AUDIO',
  Date = 'DATE',
  Emoji = 'EMOJI',
  Enps = 'ENPS',
  Email = 'Email',
  FillInTheBlanks = 'FILL_IN_THE_BLANKS',
  File = 'File',
  Image = 'IMAGE',
  ImageHotspot = 'IMAGE_HOTSPOT',
  Likert = 'LIKERT',
  MatchTheFollowing = 'MATCH_THE_FOLLOWING',
  MultipleChoiceQuestion = 'MULTIPLE_CHOICE_QUESTION',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  Overall = 'OVERALL',
  Percentage = 'PERCENTAGE',
  PictureMultipleChoiceQuestion = 'PICTURE_MULTIPLE_CHOICE_QUESTION',
  Quiz = 'QUIZ',
  Rating = 'RATING',
  SingleSelect = 'SINGLE_SELECT',
  Slider = 'SLIDER',
  Star = 'STAR',
  Text = 'TEXT',
  TrueOrFalse = 'TRUE_OR_FALSE',
  Video = 'VIDEO'
}

export type QuestionsExportMetadataType = {
  __typename?: 'QuestionsExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Quiz Content */
export type QuestionsGenerationConfig = {
  __typename?: 'QuestionsGenerationConfig';
  /** Randomize config of generation of questions */
  randomizeConfig?: Maybe<Array<RandomQuestionsGenerationConfig>>;
};

export type QuestionsGenerationConfigInput = {
  /** Randomize input config of generation of questions */
  randomizeConfig?: InputMaybe<Array<RandomQuestionsGenerationConfigInput>>;
};

export type QuestionsParentContentsFilters = {
  questionIds: Array<Scalars['String']['input']>;
};

export type QuestionsSetInput = {
  /** ordered set of questions */
  set?: InputMaybe<Array<QuizQuestionInputType>>;
};

export enum QuizCompletionCriteriaEnum {
  Attempt = 'ATTEMPT',
  Passing = 'PASSING'
}

export type QuizConfigType = {
  __typename?: 'QuizConfigType';
  completionCriteria: QuizCompletionCriteriaEnum;
};

/** Quiz Content */
export type QuizContentType = {
  __typename?: 'QuizContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  /** Description of the content */
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration in seconds */
  duration: Scalars['Float']['output'];
  /** EntityId could be lessonId, courseId, pathId on which this quiz is attached */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: QuizEntityTypeEnum;
  /** Feedback Questions for the quiz */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** description of the task */
  instruction?: Maybe<ResourceInstruction>;
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  maxScore: Scalars['Float']['output'];
  /** Name of the resource */
  name: Scalars['String']['output'];
  /** Objective of the content */
  objective?: Maybe<Scalars['String']['output']>;
  /** Passing Percentage */
  passingPercentage?: Maybe<Scalars['Float']['output']>;
  performance?: Maybe<QuizPerformanceType>;
  publishable: CheckContentPublishablePayload;
  questionGenerationConfig?: Maybe<QuestionsGenerationConfig>;
  questionGenerationType?: Maybe<QuestionGenerationType>;
  /** Question of the quiz, will be decided according to input */
  questions: Array<QuestionType>;
  /** Number of Question of the quiz */
  questionsCount: Scalars['Float']['output'];
  /** Returns quizQuestions of quizId, need for finding the order of questions in quiz (admin side) */
  quizQuestions: Array<QuizQuestionSchema>;
  quizQuestionsConfig: QuizQuestionsConfigPayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  settings?: Maybe<QuizSettings>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Skills suitable to the content */
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Quiz Content */
export type QuizContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Quiz Content */
export type QuizContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Quiz Content */
export type QuizContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Quiz Content */
export type QuizContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Quiz Content */
export type QuizContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Quiz Content */
export type QuizContentTypePerformanceArgs = {
  filters?: InputMaybe<PerformancesFilters>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Quiz Content */
export type QuizContentTypeQuestionsArgs = {
  attemptId?: InputMaybe<Scalars['String']['input']>;
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};


/** Quiz Content */
export type QuizContentTypeQuestionsCountArgs = {
  attemptId?: InputMaybe<Scalars['String']['input']>;
};


/** Quiz Content */
export type QuizContentTypeQuizQuestionsConfigArgs = {
  attemptId?: InputMaybe<Scalars['String']['input']>;
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};


/** Quiz Content */
export type QuizContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Quiz Content */
export type QuizContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Quiz Content */
export type QuizContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum QuizEntityTypeEnum {
  Course = 'COURSE',
  ExternalContent = 'EXTERNAL_CONTENT',
  Lesson = 'LESSON'
}

export enum QuizNumberOfQuestionsEnum {
  Fifteen = 'FIFTEEN',
  Five = 'FIVE',
  Ten = 'TEN',
  Twenty = 'TWENTY'
}

/** Store Attempt level information for any quiz x */
export type QuizPerformanceAttempt = {
  __typename?: 'QuizPerformanceAttempt';
  /** attemptId to uniquely determine attempts */
  attemptId: Scalars['String']['output'];
  attemptedAt?: Maybe<Scalars['String']['output']>;
  correctAnswersCount?: Maybe<Scalars['Float']['output']>;
  isSubmitted: Scalars['Boolean']['output'];
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Result calculated after submission for this attempt */
  result?: Maybe<QuizResultEnum>;
  score?: Maybe<Scalars['Float']['output']>;
  /** Percentage score */
  scorePercentage?: Maybe<Scalars['Float']['output']>;
  /** tells in which state the user performance is */
  status: PerformanceStatusEnum;
  timeSpent: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** User Performance on Quiz */
export type QuizPerformanceType = {
  __typename?: 'QuizPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all attemps of the user including the current attempt */
  allAttempts?: Maybe<Array<QuizPerformanceAttempt>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  /** attemptId to uniquely determine attempts */
  attemptId: Scalars['String']['output'];
  /** stores the latest attempt information of the user */
  attempts?: Maybe<Array<QuizPerformanceAttempt>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  correctAnswersCount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** stores the max attempt information of the user */
  maxSubmissionDetails?: Maybe<QuizPerformanceAttempt>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** Parent Id inside which quiz is taking place. ParentId can be of quiz if user has taken quiz directly */
  parentEntityId: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Result calculated after submission */
  result?: Maybe<QuizResultEnum>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  /** Percentage score */
  scorePercentage?: Maybe<Scalars['Float']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent: Scalars['Float']['output'];
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export enum QuizQuestionActionEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type QuizQuestionConfigInput = {
  action: QuizQuestionActionEnum;
  difficulty?: InputMaybe<DifficultyEnum>;
  order?: InputMaybe<Scalars['Float']['input']>;
  /** question of quiz */
  questionId: Scalars['String']['input'];
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum QuizQuestionExportFieldEnum {
  CorrectAnswerExplanation = 'CORRECT_ANSWER_EXPLANATION',
  CorrectOption = 'CORRECT_OPTION',
  Difficulty = 'DIFFICULTY',
  Distractors = 'DISTRACTORS',
  IncorrectAnswerExplanation = 'INCORRECT_ANSWER_EXPLANATION',
  Options = 'OPTIONS',
  Question = 'QUESTION',
  QuestionType = 'QUESTION_TYPE',
  Shuffle = 'SHUFFLE',
  SkillId = 'SKILL_ID',
  SkillName = 'SKILL_NAME'
}

export type QuizQuestionExportInputPropsInput = {
  filters: QuestionFilters;
  requiredFields: QuizQuestionExportRequiredFieldsInput;
};

export type QuizQuestionExportInputPropsType = {
  __typename?: 'QuizQuestionExportInputPropsType';
  filters: QuestionFiltersType;
  requiredFields: QuizQuestionExportRequiredFieldsType;
};

export type QuizQuestionExportRequiredFieldsInput = {
  fields: Array<QuizQuestionExportFieldEnum>;
};

export type QuizQuestionExportRequiredFieldsType = {
  __typename?: 'QuizQuestionExportRequiredFieldsType';
  fields: Array<QuizQuestionExportFieldEnum>;
};

export type QuizQuestionImportInputPropsInput = {
  /** Letting backend API know, that we need to publish question only */
  publishQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  quizId?: InputMaybe<Scalars['String']['input']>;
};

export type QuizQuestionImportInputPropsType = {
  __typename?: 'QuizQuestionImportInputPropsType';
  /** Letting backend API know, that we need to publish question only */
  publishQuestions?: Maybe<Scalars['Boolean']['output']>;
  quizId?: Maybe<Scalars['String']['output']>;
};

export type QuizQuestionImportMetadataType = {
  __typename?: 'QuizQuestionImportMetadataType';
  createdQuestionsCount: Scalars['Float']['output'];
};

export type QuizQuestionInput = {
  /** Difficulty of question which will be created and added to quiz */
  difficulty?: InputMaybe<DifficultyEnum>;
  /** Question Id for any question to be added in quiz */
  questionId?: InputMaybe<Scalars['String']['input']>;
  /** Question which will be created and added to quiz */
  questionInput?: InputMaybe<QuestionInput>;
  /** Quiz settings */
  settings?: InputMaybe<QuestionSettingsInput>;
};

export type QuizQuestionInputType = {
  questionId: Scalars['String']['input'];
  settings?: InputMaybe<QuestionSettingsInput>;
};

export type QuizQuestionOptionStubType = {
  __typename?: 'QuizQuestionOptionStubType';
  value: Scalars['String']['output'];
};

export type QuizQuestionOptionsResultType = {
  __typename?: 'QuizQuestionOptionsResultType';
  records: Array<QuizQuestionOptionStubType>;
};

/** This is the QuizQuestion schema */
export type QuizQuestionSchema = {
  __typename?: 'QuizQuestionSchema';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  difficulty?: Maybe<DifficultyEnum>;
  id: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  /** QuestionId of quiz */
  questionId: Scalars['String']['output'];
  /** Quiz */
  quizId: Scalars['String']['output'];
  settings?: Maybe<QuestionSettings>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type QuizQuestionStubType = {
  __typename?: 'QuizQuestionStubType';
  singleChoiceQuestion?: Maybe<QuizSingleChoiceQuestionStubType>;
};

/** Quiz questions config, optimized batched output */
export type QuizQuestionsConfigPayload = {
  __typename?: 'QuizQuestionsConfigPayload';
  questionCount: Scalars['Float']['output'];
  questions: Array<QuestionType>;
  quizQuestions: Array<QuizQuestionSchema>;
};

export enum QuizResultEnum {
  Fail = 'FAIL',
  Pass = 'PASS',
  ToBeEvaluated = 'TO_BE_EVALUATED'
}

export type QuizResultType = {
  __typename?: 'QuizResultType';
  records: Array<QuizQuestionStubType>;
  title: Scalars['String']['output'];
};

export enum QuizRetakeTypeEnum {
  Everyone = 'EVERYONE',
  FailedLearners = 'FAILED_LEARNERS',
  None = 'NONE'
}

export enum QuizRevealAnswerEnum {
  AfterQuestion = 'AFTER_QUESTION',
  AfterQuiz = 'AFTER_QUIZ',
  Never = 'NEVER'
}

/** Settings available on Question */
export type QuizSettings = {
  __typename?: 'QuizSettings';
  completionCriteria?: Maybe<QuizCompletionCriteriaEnum>;
  retakeAllowedCount?: Maybe<Scalars['Float']['output']>;
  retakeType?: Maybe<QuizRetakeTypeEnum>;
  revealAnswers?: Maybe<QuizRevealAnswerEnum>;
  syncSkillScore?: Maybe<Scalars['Boolean']['output']>;
  /** Time limit in seconds */
  timeLimit?: Maybe<Scalars['Float']['output']>;
  timeLimitApplicableOn?: Maybe<TimeLimitApplicableOnEnum>;
};

/** Settings available on Question */
export type QuizSettingsInput = {
  completionCriteria?: InputMaybe<QuizCompletionCriteriaEnum>;
  retakeAllowedCount?: InputMaybe<Scalars['Float']['input']>;
  retakeType?: InputMaybe<QuizRetakeTypeEnum>;
  revealAnswers?: InputMaybe<QuizRevealAnswerEnum>;
  syncSkillScore?: InputMaybe<Scalars['Boolean']['input']>;
  /** Time limit in seconds */
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  timeLimitApplicableOn?: InputMaybe<TimeLimitApplicableOnEnum>;
};

export type QuizSingleChoiceQuestionStubType = {
  __typename?: 'QuizSingleChoiceQuestionStubType';
  /** 1-based index of the correct answer */
  answer: Scalars['Float']['output'];
  explanation?: Maybe<Scalars['String']['output']>;
  options: Array<Scalars['String']['output']>;
  question: Scalars['String']['output'];
};

export type QuizSkillIdsSetInput = {
  /** SkillIds to be updated */
  set: Array<Scalars['String']['input']>;
};

/** Quiz Content */
export type RandomQuestionsGenerationConfig = {
  __typename?: 'RandomQuestionsGenerationConfig';
  limit?: Maybe<Scalars['Float']['output']>;
  /** randomize question on which property key */
  property: Scalars['String']['output'];
  /** randomize question on which property value */
  propertyValue: Scalars['String']['output'];
};

export type RandomQuestionsGenerationConfigInput = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  /** randomize question on which property key */
  property: Scalars['String']['input'];
  /** randomize question on which property value */
  propertyValue: Scalars['String']['input'];
};

/** Rating label Component Schema */
export type RatingLabelComponent = {
  __typename?: 'RatingLabelComponent';
  labelName: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
};

/** Rating label Component Schema */
export type RatingLabelComponentInput = {
  labelName: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Float']['input']>;
};

/** Reactions on a post */
export type ReactionType = {
  __typename?: 'ReactionType';
  channelId?: Maybe<Scalars['String']['output']>;
  count: Scalars['Float']['output'];
  hasReacted?: Maybe<Scalars['Boolean']['output']>;
  postId?: Maybe<Scalars['String']['output']>;
  reaction: Scalars['String']['output'];
  userIds: Array<Scalars['String']['output']>;
  userWorkspaceInfos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  users?: Maybe<Array<UserType>>;
};

export type ReadNotificationsInput = {
  /** If this flag is passed as true, then ALL unread notifications of the user will be marked as read */
  readAll?: InputMaybe<Scalars['Boolean']['input']>;
  userNotificationId?: InputMaybe<Scalars['String']['input']>;
};

export type RecalibrateConfigInput = {
  /** Update the progress for learners who have completed content */
  recalibrateCompletedPerformances?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the progress for learners who are inprogress */
  recalibrateInProgressPerformances?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecalibrateConfigType = {
  __typename?: 'RecalibrateConfigType';
  /** Update the progress for learners who have completed content */
  recalibrateCompletedPerformances?: Maybe<Scalars['Boolean']['output']>;
  /** Update the progress for learners who are inprogress */
  recalibrateInProgressPerformances?: Maybe<Scalars['Boolean']['output']>;
};

/** RecalibrateContentProgressInput! */
export type RecalibrateContentProgressInput = {
  entityId: Scalars['String']['input'];
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  resourceVersionId?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** RecalibrateContentProgressPropsType! */
export type RecalibrateContentProgressPropsType = {
  __typename?: 'RecalibrateContentProgressPropsType';
  entityId: Scalars['String']['output'];
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

/** RecalibrateQuestionProgressInput */
export type RecalibrateQuestionProgressInput = {
  entityIds: Array<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RecipientInfo = {
  __typename?: 'RecipientInfo';
  emailId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type RecipientInfoInput = {
  emailId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Settings for recording */
export type RecordingSettings = {
  __typename?: 'RecordingSettings';
  /** allow user to jump in between recording */
  allowForwarding?: Maybe<Scalars['Boolean']['output']>;
  /** automatically record the event */
  automaticRecording: Scalars['Boolean']['output'];
  /** share event recording with learners */
  shareRecording: Scalars['Boolean']['output'];
};

/** Settings for recording */
export type RecordingSettingsInput = {
  /** allow user to jump in between recording */
  allowForwarding?: InputMaybe<Scalars['Boolean']['input']>;
  /** automatically record the event */
  automaticRecording: Scalars['Boolean']['input'];
  /** share event recording with learners */
  shareRecording: Scalars['Boolean']['input'];
};

export type RecordingWatchSummary = {
  __typename?: 'RecordingWatchSummary';
  progress: Scalars['Float']['output'];
  recordingId: Scalars['String']['output'];
};

export enum RecurringEventExportTypeEnum {
  AllSlots = 'ALL_SLOTS',
  RecurringWrapper = 'RECURRING_WRAPPER'
}

/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentType = {
  __typename?: 'RecurringExternalEventContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  event?: Maybe<EventUnion>;
  eventRecurringCategory?: Maybe<RecurringTypeEnum>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEventId?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String']['output'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Recurring External event container content type. Refers to event entity */
export type RecurringExternalEventContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type RecurringExternalEventType = {
  __typename?: 'RecurringExternalEventType';
  /** actual ending time */
  actualEndTime?: Maybe<Scalars['String']['output']>;
  /** actual start time */
  actualStartTime?: Maybe<Scalars['String']['output']>;
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']['output']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String']['output'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** to enable waiting room */
  enableWaitingRoom?: Maybe<Scalars['Boolean']['output']>;
  /** user provided end time in UTC */
  endTime: Scalars['String']['output'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  externalEventProviderInfo: ExternalEventProviderInfoType;
  firstRegisteredSlotId?: Maybe<Scalars['String']['output']>;
  flexiblePermissionGroupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructorIds: Array<Scalars['String']['output']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isRevokedAfterCompletion: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean']['output'];
  isUserRegistered: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  latestUpcomingEventId?: Maybe<Scalars['String']['output']>;
  latestUpcomingEventStartTime?: Maybe<Scalars['String']['output']>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEvent?: Maybe<EventUnion>;
  /** stores the id of parent recurring session wrapper */
  parentEventId?: Maybe<Scalars['String']['output']>;
  postWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  recordingSettings: RecordingSettings;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']['output']>>;
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** depicts the subtype for session */
  recurringCategory?: Maybe<RecurringTypeEnum>;
  recurringEventSlotCount?: Maybe<Scalars['Float']['output']>;
  registeredEventsCount?: Maybe<Scalars['Float']['output']>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']['output']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float']['output'];
  remainingPreWorkContents: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String']['output'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String']['output'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float']['output'];
  type: EventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type RecurringExternalEventTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type RecurringExternalEventTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type RecurringExternalEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type RecurringExternalEventTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentType = {
  __typename?: 'RecurringLiveEventContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  event?: Maybe<EventUnion>;
  eventRecurringCategory?: Maybe<RecurringTypeEnum>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEventId?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String']['output'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Recurring Live Event container content type. Refers to event entity */
export type RecurringLiveEventContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type RecurringLiveEventType = {
  __typename?: 'RecurringLiveEventType';
  /** actual ending time */
  actualEndTime?: Maybe<Scalars['String']['output']>;
  /** actual start time */
  actualStartTime?: Maybe<Scalars['String']['output']>;
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']['output']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String']['output'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** user provided end time in UTC */
  endTime: Scalars['String']['output'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  firstRegisteredSlotId?: Maybe<Scalars['String']['output']>;
  flexiblePermissionGroupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructorIds: Array<Scalars['String']['output']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isRevokedAfterCompletion: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean']['output'];
  isUserRegistered: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  latestUpcomingEventId?: Maybe<Scalars['String']['output']>;
  latestUpcomingEventStartTime?: Maybe<Scalars['String']['output']>;
  /** liveStream info */
  livestreamInfo?: Maybe<LivestreamInfo>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEvent?: Maybe<EventUnion>;
  /** stores the id of parent recurring session wrapper */
  parentEventId?: Maybe<Scalars['String']['output']>;
  postWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  recordingSettings: RecordingSettings;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']['output']>>;
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** depicts the subtype for session */
  recurringCategory?: Maybe<RecurringTypeEnum>;
  recurringEventSlotCount?: Maybe<Scalars['Float']['output']>;
  registeredEventsCount?: Maybe<Scalars['Float']['output']>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']['output']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float']['output'];
  remainingPreWorkContents: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String']['output'];
  status: EventStatusEnum;
  subType: EventSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String']['output'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float']['output'];
  type: EventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type RecurringLiveEventTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type RecurringLiveEventTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type RecurringLiveEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type RecurringLiveEventTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentType = {
  __typename?: 'RecurringOfflineEventContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration of content in seconds */
  duration: Scalars['Float']['output'];
  event?: Maybe<EventUnion>;
  eventRecurringCategory?: Maybe<RecurringTypeEnum>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEventId?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String']['output'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Recurring Offline Event container content type. Refers to event entity */
export type RecurringOfflineEventContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type RecurringOfflineEventType = {
  __typename?: 'RecurringOfflineEventType';
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']['output']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']['output']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String']['output'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float']['output'];
  defaultGroupId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  /** user provided end time in UTC */
  endTime: Scalars['String']['output'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  firstRegisteredSlotId?: Maybe<Scalars['String']['output']>;
  flexiblePermissionGroupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instructorIds: Array<Scalars['String']['output']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isRevokedAfterCompletion: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean']['output'];
  isUserRegistered: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  latestUpcomingEventId?: Maybe<Scalars['String']['output']>;
  latestUpcomingEventStartTime?: Maybe<Scalars['String']['output']>;
  location: EventLocation;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String']['output'];
  objective: Scalars['String']['output'];
  parentEvent?: Maybe<EventUnion>;
  /** stores the id of parent recurring session wrapper */
  parentEventId?: Maybe<Scalars['String']['output']>;
  postWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']['output']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']['output']>>;
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** depicts the subtype for session */
  recurringCategory?: Maybe<RecurringTypeEnum>;
  recurringEventSlotCount?: Maybe<Scalars['Float']['output']>;
  registeredEventsCount?: Maybe<Scalars['Float']['output']>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']['output']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float']['output'];
  remainingPreWorkContents: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String']['output'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String']['output'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float']['output'];
  type: EventTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userIds: Array<Scalars['String']['output']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};


export type RecurringOfflineEventTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type RecurringOfflineEventTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type RecurringOfflineEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type RecurringOfflineEventTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum RecurringSessionCompletionRollupTypeEnum {
  Average = 'AVERAGE',
  Highest = 'HIGHEST'
}

export enum RecurringTypeEnum {
  NonRecurring = 'NON_RECURRING',
  Recurring = 'RECURRING',
  RecurringInstance = 'RECURRING_INSTANCE'
}

export type RegenerateMeetingInstanceSummaryInput = {
  meetingInstanceId: Scalars['String']['input'];
};

export type RegenerateMeetingInstanceSummaryPayload = {
  __typename?: 'RegenerateMeetingInstanceSummaryPayload';
  generativeTaskId: Scalars['String']['output'];
};

export type RegisterEventInput = {
  eventId: Scalars['String']['input'];
};

/** Settings for registration */
export type RegistrationSettings = {
  __typename?: 'RegistrationSettings';
  /** time in seconds before startTime, when event registration should be closed */
  closeRegistrationBeforeStart: Scalars['Float']['output'];
  multiSlotRegistration?: Maybe<Scalars['Boolean']['output']>;
  seatLimit?: Maybe<Scalars['Float']['output']>;
};

/** Settings for registration */
export type RegistrationSettingsInput = {
  /** time in seconds before startTime, when event registration should be closed */
  closeRegistrationBeforeStart?: InputMaybe<Scalars['Float']['input']>;
  multiSlotRegistration?: InputMaybe<Scalars['Boolean']['input']>;
  seatLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type RelatedActionPlanEntityInput = {
  subTypes: Array<ActionPlanSubTypeEnum>;
  targetEntityId?: InputMaybe<Scalars['String']['input']>;
  targetEntityType: FocusAreaEnum;
  userId: Scalars['String']['input'];
};

export type RelatedConnectionsFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  workspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RelatedConnectionsPayload = {
  __typename?: 'RelatedConnectionsPayload';
  workspaceDetails: Array<WorkspaceDetails>;
};

export type RelatedEntityInput = {
  entityId: Scalars['String']['input'];
  entityType: RelatedEntityTypeEnum;
};

export enum RelatedEntityTypeEnum {
  ActionPlan = 'ACTION_PLAN',
  AgendaItem = 'AGENDA_ITEM',
  KeyResult = 'KEY_RESULT',
  Meeting = 'MEETING',
  MeetingInstance = 'MEETING_INSTANCE',
  Okr = 'OKR',
  PeerFeedback = 'PEER_FEEDBACK',
  TeamMeeting = 'TEAM_MEETING'
}

/** Metadata for relation fields */
export type RelationMetadataInput = {
  collectionName: EntityCollectionEnum;
  /** type of resource */
  type: PerformanceTypeEnum;
};

export type RelativeDateFilter = {
  operator: RelativeDateFilterOperatorEnum;
  unit: RelativeDateFilterUnitEnum;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum RelativeDateFilterOperatorEnum {
  Last = 'LAST',
  Next = 'NEXT',
  Now = 'NOW',
  Previous = 'PREVIOUS',
  This = 'THIS'
}

export type RelativeDateFilterType = {
  __typename?: 'RelativeDateFilterType';
  operator: RelativeDateFilterOperatorEnum;
  unit: RelativeDateFilterUnitEnum;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum RelativeDateFilterUnitEnum {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** For scheduling reminders for a particular notification type */
export type ReminderConfigurationInput = {
  reminderType: ReminderTypeEnum;
  unit: ReminderConfigurationUnitTypeEnum;
  value: Scalars['Float']['input'];
};

export type ReminderConfigurationType = {
  __typename?: 'ReminderConfigurationType';
  reminderType: ReminderTypeEnum;
  unit: ReminderConfigurationUnitTypeEnum;
  value: Scalars['Float']['output'];
};

export enum ReminderConfigurationUnitTypeEnum {
  Days = 'DAYS',
  Minutes = 'MINUTES',
  Months = 'MONTHS'
}

export enum ReminderTypeEnum {
  After = 'AFTER',
  Before = 'BEFORE'
}

/** Input to remove database document */
export type RemoveDatabaseDataInput = {
  dbId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
};

export type RemoveEntityAccessInput = {
  entityId: Scalars['String']['input'];
  entityType: PermissionEntities;
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RemoveEntityTagInput = {
  entityId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
};

export type RemoveMarketplaceImportedContentInput = {
  marketplaceContentId: Scalars['String']['input'];
};

export type RemoveMarketplaceImportedContentPayload = {
  __typename?: 'RemoveMarketplaceImportedContentPayload';
  marketplaceContent?: Maybe<MarketplaceContentType>;
  marketplaceContentId: Scalars['String']['output'];
  /** @deprecated this is just for backward compatibility */
  success: Scalars['Boolean']['output'];
};

export type RemoveQuestionsInput = {
  /** Filters to select questions to be removed */
  filters: QuestionFilters;
};

export type RemoveQuestionsPayload = {
  __typename?: 'RemoveQuestionsPayload';
  /** Questions removed */
  removedQuestions: Array<QuestionType>;
};

export type RemoveReactionInput = {
  /** ChannelId on which the post exists */
  channelId: Scalars['String']['input'];
  /** Post Id on which the reaction exists */
  postId: Scalars['String']['input'];
  /** Actual reaction */
  reaction: Scalars['String']['input'];
};

export type RemoveSkillsFromSkillGroupInput = {
  /** skillGroupId from which given skillIds should be removed */
  skillGroupId: Scalars['String']['input'];
  /** skillId to remove */
  skillIds: Array<Scalars['String']['input']>;
};

export type RemoveUsersFromStaffInput = {
  userIds: Array<Scalars['String']['input']>;
};

export type RemoveUsersFromStaffRolesInput = {
  roleIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

/** Edge */
export type ReportEdge = {
  __typename?: 'ReportEdge';
  node: ReportType;
};

export type ReportExportMetadataType = {
  __typename?: 'ReportExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  failedPagesMetadata?: Maybe<Array<FailedPageMetadata>>;
  totalCount: Scalars['Float']['output'];
};

export type ReportExportPropsInput = {
  chartIds?: InputMaybe<Array<Scalars['String']['input']>>;
  emailDigestId?: InputMaybe<Scalars['String']['input']>;
  reportFilterGroup?: InputMaybe<FilterGroupInput>;
  reportId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type ReportExportPropsType = {
  __typename?: 'ReportExportPropsType';
  chartIds?: Maybe<Array<Scalars['String']['output']>>;
  emailDigestId?: Maybe<Scalars['String']['output']>;
  reportFilterGroup?: Maybe<FilterGroupType>;
  reportId: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

export type ReportMediaExportMetadataType = {
  __typename?: 'ReportMediaExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type ReportMediaExportPropsInput = {
  baseUrlOverride?: InputMaybe<Scalars['String']['input']>;
  emailDigestId?: InputMaybe<Scalars['String']['input']>;
  exportFormat: MediaExportFormatEnum;
  reportId: Scalars['String']['input'];
  urlQueryParams?: InputMaybe<Scalars['String']['input']>;
};

export type ReportMediaExportPropsType = {
  __typename?: 'ReportMediaExportPropsType';
  baseUrlOverride?: Maybe<Scalars['String']['output']>;
  emailDigestId?: Maybe<Scalars['String']['output']>;
  exportFormat: MediaExportFormatEnum;
  reportId: Scalars['String']['output'];
  urlQueryParams?: Maybe<Scalars['String']['output']>;
};

export enum ReportStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum ReportSubTypeEnum {
  ActivityDetails = 'ACTIVITY_DETAILS',
  EmployeeContentMetrics = 'EMPLOYEE_CONTENT_METRICS',
  EmployeeOverviewMetrics = 'EMPLOYEE_OVERVIEW_METRICS',
  EmployeeSessionMetrics = 'EMPLOYEE_SESSION_METRICS',
  LearnerProfileActivities = 'LEARNER_PROFILE_ACTIVITIES',
  ManagerProfileOverview = 'MANAGER_PROFILE_OVERVIEW',
  MeetingInsights = 'MEETING_INSIGHTS',
  ReviewCycleMindset = 'REVIEW_CYCLE_MINDSET',
  ReviewCycleParticipation = 'REVIEW_CYCLE_PARTICIPATION',
  ReviewCycleSkill = 'REVIEW_CYCLE_SKILL',
  SurveyParticipation = 'SURVEY_PARTICIPATION',
  SurveyResult = 'SURVEY_RESULT',
  SurveySummary = 'SURVEY_SUMMARY',
  TeamProfileActivities = 'TEAM_PROFILE_ACTIVITIES',
  TeamProfileGoals = 'TEAM_PROFILE_GOALS',
  TeamProfileMindsets = 'TEAM_PROFILE_MINDSETS',
  TeamProfileOverview = 'TEAM_PROFILE_OVERVIEW',
  TeamProfileSkills = 'TEAM_PROFILE_SKILLS',
  TeamProfileTeam = 'TEAM_PROFILE_TEAM',
  UserProfileActivities = 'USER_PROFILE_ACTIVITIES',
  UserProfileGoals = 'USER_PROFILE_GOALS',
  UserProfileMindsets = 'USER_PROFILE_MINDSETS',
  UserProfileOverview = 'USER_PROFILE_OVERVIEW',
  UserProfileSkills = 'USER_PROFILE_SKILLS',
  UserProfileTeam = 'USER_PROFILE_TEAM'
}

/** Report schema */
export type ReportType = {
  __typename?: 'ReportType';
  /** Thumbnail image url for the standard report */
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filterGroup?: Maybe<FilterGroupType>;
  iconSettings: IconSettings;
  id: Scalars['String']['output'];
  /** if report is globle report */
  isStandardReport?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  reportStatusRelevancyScore: Scalars['Int']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: ReportStatusEnum;
  subType?: Maybe<ReportSubTypeEnum>;
  tags?: Maybe<Array<EntityTagType>>;
  type: ReportTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  visibilitySettings: VisibilitySettingsType;
  /** Underlying chart widgets */
  widgets?: Maybe<Array<ChartWidgetType>>;
  /** Contains info about chart widgets and their layout */
  widgetsLayout?: Maybe<Array<WidgetLayoutType>>;
};

export enum ReportTypeEnum {
  Custom = 'CUSTOM',
  Standard = 'STANDARD',
  System = 'SYSTEM'
}

export enum ReporteeFilterTypeEnum {
  DirectReportee = 'DIRECT_REPORTEE',
  InDirectReportee = 'IN_DIRECT_REPORTEE'
}

export type ReporteeFilterTypeInput = {
  type: ReporteeFilterTypeEnum;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReportingQueriesResultType = {
  __typename?: 'ReportingQueriesResultType';
  queries?: Maybe<Array<ReportingQueryStubType>>;
  suggestedQueries?: Maybe<Array<ReportingQueryStubType>>;
};

export type ReportingQueryFiltersStubType = {
  __typename?: 'ReportingQueryFiltersStubType';
  operator: Scalars['String']['output'];
  property: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ReportingQueryMeasureStubType = {
  __typename?: 'ReportingQueryMeasureStubType';
  aggregator: Scalars['String']['output'];
  field: Scalars['String']['output'];
};

export type ReportingQueryStubType = {
  __typename?: 'ReportingQueryStubType';
  dimensions?: Maybe<Array<Scalars['String']['output']>>;
  filters?: Maybe<Array<ReportingQueryFiltersStubType>>;
  measures?: Maybe<Array<ReportingQueryMeasureStubType>>;
};

export type ReportingWidgetPayloadInputType = {
  /** Will replace given reviewCycleId in place of the string `reviewCycleId` */
  reviewCycleId?: InputMaybe<Scalars['String']['input']>;
};

export type ReportingWidgetType = {
  __typename?: 'ReportingWidgetType';
  name: Scalars['String']['output'];
  /** Payload for reporting widget query */
  payload?: Maybe<Scalars['String']['output']>;
  /** Payload for reporting widget query */
  payloadToParse?: Maybe<Scalars['String']['output']>;
};


export type ReportingWidgetTypePayloadArgs = {
  input?: InputMaybe<ReportingWidgetPayloadInputType>;
};

export type ReportsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isStandardReport?: InputMaybe<Scalars['Boolean']['input']>;
  reportIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ReportStatusEnum>>;
  subType?: InputMaybe<Array<ReportSubTypeEnum>>;
  type?: InputMaybe<Array<ReportTypeEnum>>;
  widgetIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReportsToInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type RequestConnectionInput = {
  targetWorkspaceUri: Scalars['String']['input'];
};

export type RequestPeerFeedbackInput = {
  mindsetIds: Array<Scalars['String']['input']>;
  requestMessage: Scalars['String']['input'];
  requestedFromUserIds: Array<Scalars['String']['input']>;
  skillIds: Array<Scalars['String']['input']>;
};

export type RescheduleMeetingInstanceInput = {
  meetingInstanceId: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

/** ResetContentProgressInput! */
export type ResetContentProgressInput = {
  entityIds: Array<Scalars['String']['input']>;
  entityType?: InputMaybe<PerformanceEntityTypeEnum>;
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

/** Reset content payload */
export type ResetContentProgressPayload = {
  __typename?: 'ResetContentProgressPayload';
  jobScheduleId: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ResolutionEnum {
  L = 'L',
  S = 'S',
  Thumb = 'THUMB'
}

export type ResolutionInputType = {
  type: ResolutionEnum;
  url: Scalars['String']['input'];
};

/** Multiple Resolution Type */
export type ResolutionType = {
  __typename?: 'ResolutionType';
  type: ResolutionEnum;
  url: Scalars['String']['output'];
};

export enum ResourceActions {
  Archive = 'ARCHIVE',
  Assign = 'ASSIGN',
  Consume = 'CONSUME',
  Create = 'CREATE',
  Edit = 'EDIT',
  Enroll = 'ENROLL',
  Grade = 'GRADE',
  Manage = 'MANAGE',
  Publish = 'PUBLISH',
  Read = 'READ',
  Remove = 'REMOVE',
  Return = 'RETURN',
  Share = 'SHARE'
}

export enum ResourceCategoryEnum {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Folder = 'FOLDER',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebLink = 'WEB_LINK',
  YoutubeLink = 'YOUTUBE_LINK'
}

/** This returns paginated resources */
export type ResourceConnections = {
  __typename?: 'ResourceConnections';
  edges?: Maybe<Array<ResourceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type ResourceEdge = {
  __typename?: 'ResourceEdge';
  node: ResourceType;
};

export enum ResourceErrorCodeEnum {
  DuplicateResourceName = 'DUPLICATE_RESOURCE_NAME',
  ExerciseAllowedResponseTypesEmpty = 'EXERCISE_ALLOWED_RESPONSE_TYPES_EMPTY',
  ExerciseCreationFailed = 'EXERCISE_CREATION_FAILED',
  ExerciseNameEmpty = 'EXERCISE_NAME_EMPTY',
  ExerciseNoImageForHotspots = 'EXERCISE_NO_IMAGE_FOR_HOTSPOTS',
  ExerciseNoQuestions = 'EXERCISE_NO_QUESTIONS',
  ExerciseNoScorecards = 'EXERCISE_NO_SCORECARDS',
  ExerciseUpdateFailed = 'EXERCISE_UPDATE_FAILED',
  ExternalContentCreationFailed = 'EXTERNAL_CONTENT_CREATION_FAILED',
  ExternalContentInvalidDuration = 'EXTERNAL_CONTENT_INVALID_DURATION',
  ExternalContentInvalidProviderId = 'EXTERNAL_CONTENT_INVALID_PROVIDER_ID',
  ExternalContentNameEmpty = 'EXTERNAL_CONTENT_NAME_EMPTY',
  ExternalContentUrlEmpty = 'EXTERNAL_CONTENT_URL_EMPTY',
  HomeworkAssignmentFailed = 'HOMEWORK_ASSIGNMENT_FAILED',
  HomeworkNotFound = 'HOMEWORK_NOT_FOUND',
  InvalidData = 'INVALID_DATA',
  PathCreationFailed = 'PATH_CREATION_FAILED',
  PathCyclicDependency = 'PATH_CYCLIC_DEPENDENCY',
  PathNameEmpty = 'PATH_NAME_EMPTY',
  PathNoSections = 'PATH_NO_SECTIONS',
  PathSectionInactiveContent = 'PATH_SECTION_INACTIVE_CONTENT',
  PathSectionNameEmpty = 'PATH_SECTION_NAME_EMPTY',
  PathSectionNoContent = 'PATH_SECTION_NO_CONTENT',
  PathSectionOverlappingContent = 'PATH_SECTION_OVERLAPPING_CONTENT',
  QuizCreationFailed = 'QUIZ_CREATION_FAILED',
  QuizInvalidDuration = 'QUIZ_INVALID_DURATION',
  QuizInvalidPassingPercentage = 'QUIZ_INVALID_PASSING_PERCENTAGE',
  QuizNameEmpty = 'QUIZ_NAME_EMPTY',
  QuizNoQuestions = 'QUIZ_NO_QUESTIONS',
  QuizUpdationFailed = 'QUIZ_UPDATION_FAILED',
  QuizValidationFailed = 'QUIZ_VALIDATION_FAILED',
  RequiredClassroomConfigNotFound = 'REQUIRED_CLASSROOM_CONFIG_NOT_FOUND',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  SubtaskNameEmpty = 'SUBTASK_NAME_EMPTY',
  TaskCreationFailed = 'TASK_CREATION_FAILED',
  TaskNameEmpty = 'TASK_NAME_EMPTY',
  TaskUpdateFailed = 'TASK_UPDATE_FAILED'
}

/** Resource Grading configs */
export type ResourceGradeSettingsType = {
  __typename?: 'ResourceGradeSettingsType';
  maxScore: Scalars['Float']['output'];
};

/** Resource content description/instruction */
export type ResourceInstruction = {
  __typename?: 'ResourceInstruction';
  /** Media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']['output']>;
};

export type ResourceInstructionInput = {
  /** Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** text */
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum ResourceKindEnum {
  File = 'FILE',
  Folder = 'FOLDER'
}

export enum ResourceResponseTypeEnum {
  Any = 'ANY',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  Enps = 'ENPS',
  File = 'FILE',
  FillInTheBlanks = 'FILL_IN_THE_BLANKS',
  Image = 'IMAGE',
  ImageHotspot = 'IMAGE_HOTSPOT',
  Likert = 'LIKERT',
  MarkAsDone = 'MARK_AS_DONE',
  MatchTheFollowing = 'MATCH_THE_FOLLOWING',
  MultipleChoiceQuestion = 'MULTIPLE_CHOICE_QUESTION',
  MultiSelect = 'MULTI_SELECT',
  Overall = 'OVERALL',
  PictureMultipleChoiceQuestion = 'PICTURE_MULTIPLE_CHOICE_QUESTION',
  Presentation = 'PRESENTATION',
  Quiz = 'QUIZ',
  Rating = 'RATING',
  SingleSelect = 'SINGLE_SELECT',
  Slider = 'SLIDER',
  Star = 'STAR',
  Text = 'TEXT',
  TrueOrFalse = 'TRUE_OR_FALSE',
  Video = 'VIDEO',
  VoiceOver = 'VOICE_OVER',
  Whiteboard = 'WHITEBOARD',
  WriteUp = 'WRITE_UP'
}

export enum ResourceReviewTypeEnum {
  MandatoryReview = 'MANDATORY_REVIEW',
  NoReview = 'NO_REVIEW',
  OptionalReview = 'OPTIONAL_REVIEW'
}

export enum ResourceStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Open = 'OPEN',
  Published = 'PUBLISHED',
  Revoked = 'REVOKED',
  ToGrade = 'TO_GRADE',
  UnpublishedChanges = 'UNPUBLISHED_CHANGES'
}

export enum ResourceSubTypeEnum {
  ExternalContent = 'EXTERNAL_CONTENT',
  InLesson = 'IN_LESSON',
  Review = 'REVIEW',
  Static = 'STATIC'
}

export enum ResourceSubjects {
  Assignment = 'ASSIGNMENT',
  Homework = 'HOMEWORK',
  Performance = 'PERFORMANCE',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  Submission = 'SUBMISSION',
  SubWorkspace = 'SUB_WORKSPACE'
}

/** Resource Type. This can be used in classrooms */
export type ResourceType = {
  __typename?: 'ResourceType';
  availableMediaFormats: Array<AvailableMediaFormatType>;
  /** category of uploaded resource */
  category: ResourceCategoryEnum;
  /** Associate with classroom */
  classroomId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entityMedia: EntityMediaType;
  /** resource extension */
  format?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Associated with convertApi, tells whether the documents is converted into images or not */
  jobId?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<JobStatusEnum>;
  /** Tells whether it is a file or folder */
  kind: ResourceKindEnum;
  /** media type of uploaded resource */
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Count of the nested folder and files inside the folder */
  nestedResourceCount: Scalars['Float']['output'];
  /** Parent folder Id of the resource */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Location under which folders/file will be searched */
  path?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Size of the attachment in kbs */
  size?: Maybe<Scalars['Float']['output']>;
  status: ResourceStatusEnum;
  type?: Maybe<ResourceTypeEnum>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** url of the attachment stored on our end */
  url?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum ResourceTypeEnum {
  Article = 'ARTICLE',
  Checkin = 'CHECKIN',
  Course = 'COURSE',
  Exercise = 'EXERCISE',
  ExternalContent = 'EXTERNAL_CONTENT',
  ExternalEventContent = 'EXTERNAL_EVENT_CONTENT',
  Homework = 'HOMEWORK',
  LiveEventContent = 'LIVE_EVENT_CONTENT',
  OfflineEventContent = 'OFFLINE_EVENT_CONTENT',
  Path = 'PATH',
  Quiz = 'QUIZ',
  RecurringExternalEventContent = 'RECURRING_EXTERNAL_EVENT_CONTENT',
  RecurringLiveEventContent = 'RECURRING_LIVE_EVENT_CONTENT',
  RecurringOfflineEventContent = 'RECURRING_OFFLINE_EVENT_CONTENT',
  Scorm = 'SCORM',
  Survey = 'SURVEY',
  Task = 'TASK'
}

/** This returns paginated ResourceVersionTypes */
export type ResourceVersionConnections = {
  __typename?: 'ResourceVersionConnections';
  edges?: Maybe<Array<ResourceVersionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type ResourceVersionEdge = {
  __typename?: 'ResourceVersionEdge';
  node: ResourceVersionType;
};

export type ResourceVersionFilters = {
  contentId?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Contains information regarding the published version of the content */
export type ResourceVersionType = {
  __typename?: 'ResourceVersionType';
  contentId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  note: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  type: ResourceVersionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber: Scalars['String']['output'];
};

export enum ResourceVersionTypeEnum {
  Major = 'MAJOR',
  Minor = 'MINOR'
}

export enum ResponsibilitySyncedFromEnum {
  Integration = 'INTEGRATION'
}

export type RestrictedAccessConfig = {
  __typename?: 'RestrictedAccessConfig';
  restrictedUserAccess?: Maybe<Array<RestrictedUserAccess>>;
};

export type RestrictedAccessConfigInput = {
  restrictedUserAccess?: InputMaybe<Array<RestrictedUserAccessInput>>;
};

export type RestrictedFilterGroupType = {
  __typename?: 'RestrictedFilterGroupType';
  /** Array of simple filter queries */
  filters: Array<FilterD1Type>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};

export type RestrictedPermittedOn = {
  __typename?: 'RestrictedPermittedOn';
  showRestrictedLearners?: Maybe<Scalars['Boolean']['output']>;
};

export type RestrictedPermittedOnInput = {
  showRestrictedLearners?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RestrictedUserAccess = {
  __typename?: 'RestrictedUserAccess';
  filterGroup?: Maybe<RestrictedFilterGroupType>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type RestrictedUserAccessInput = {
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

/** This is the input for resubmission of Exercise! */
export type ResubmitExerciseSubmissionInput = {
  entityId: Scalars['String']['input'];
  isActualSubmit: Scalars['Boolean']['input'];
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  questionResponses?: InputMaybe<Array<ExerciseQuestionResponseInput>>;
  submissionId: Scalars['String']['input'];
};

/** Make Exercise Submission Response */
export type ResubmitExerciseSubmissionPayload = {
  __typename?: 'ResubmitExerciseSubmissionPayload';
  performance: ExercisePerformanceType;
};

/** This is the input for retaking the quiz */
export type RetakeQuizInput = {
  /** Whether quiz being retaken is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether quiz is consumed via admin using preview feature */
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String']['input'];
  /** quizId for which revaluation is taking place */
  quizId: Scalars['String']['input'];
};

/** Quiz reevaluation response */
export type RetakeQuizPayload = {
  __typename?: 'RetakeQuizPayload';
  quizPerformance?: Maybe<QuizPerformanceType>;
};

/** retry info */
export type RetryInfoType = {
  __typename?: 'RetryInfoType';
  /** feedback */
  feedback?: Maybe<Scalars['String']['output']>;
  /** submitted at */
  reviewedAt: Scalars['String']['output'];
};

export type ReviewConnectionInput = {
  sharedConnectionId: Scalars['String']['input'];
  status: TenantConnectionStatusEnum;
};

export type ReviewCycleAutoFlagInputPropsType = {
  __typename?: 'ReviewCycleAutoFlagInputPropsType';
  reviewCycleId: Scalars['String']['output'];
};

export type ReviewCycleAutoFlagPropsInput = {
  reviewCycleId: Scalars['String']['input'];
};

/** ReviewCycleAverageScoresPayload */
export type ReviewCycleAverageScoresPayload = {
  __typename?: 'ReviewCycleAverageScoresPayload';
  averageMindsetScore?: Maybe<Scalars['Float']['output']>;
  averageSkillScore?: Maybe<Scalars['Float']['output']>;
  /** Average of all skill and mindset scores */
  overallScore?: Maybe<Scalars['Float']['output']>;
};

export type ReviewCycleContextualFilterInput = {
  contextType: UserReviewCycleContextEnum;
  userId: Scalars['String']['input'];
};

/** Edge */
export type ReviewCycleEdge = {
  __typename?: 'ReviewCycleEdge';
  node: ReviewCycleType;
};

export type ReviewCycleEndConfig = {
  __typename?: 'ReviewCycleEndConfig';
  adminEndsForAll?: Maybe<Scalars['Boolean']['output']>;
  /** allow manager to end reviewCycle specifically for their direct reportees */
  managerEndsForDirectReports?: Maybe<Scalars['Boolean']['output']>;
};

export type ReviewCycleEndConfigInput = {
  adminEndsForAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** allow manager to end reviewCycle specifically for their direct reportees */
  managerEndsForDirectReports?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewCycleEndSettingsInput = {
  cycleEndType: ReviewCycleEndTypeEnum;
  /** end the reviewCycle when all reviews are received */
  endCycleOnAllReviewsReceived?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type ReviewCycleEndSettingsType = {
  __typename?: 'ReviewCycleEndSettingsType';
  cycleEndType: ReviewCycleEndTypeEnum;
  /** end the reviewCycle when all reviews are received */
  endCycleOnAllReviewsReceived?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum ReviewCycleEndTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  Manual = 'MANUAL'
}

export type ReviewCycleFilters = {
  reviewCycleId: Scalars['String']['input'];
  /** if FE wants to request Draft version of reviewCycle */
  versionStatus?: InputMaybe<ReviewCycleStatusEnum>;
};

export type ReviewCycleInstanceTodoType = {
  __typename?: 'ReviewCycleInstanceTodoType';
  reviewCycleInstance: ReviewCycleInstanceType;
  type: TodoTypeEnum;
};

/** ReviewCycle Schema */
export type ReviewCycleInstanceType = {
  __typename?: 'ReviewCycleInstanceType';
  /** Advance settings to control certain aspects of review */
  advanceSettings?: Maybe<AdvanceSettingsType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** ReviewCycle description */
  description?: Maybe<Scalars['String']['output']>;
  /** Time taken to complete one review cycle */
  duration?: Maybe<Scalars['Float']['output']>;
  entity?: Maybe<ContentEntity>;
  /** CheckIn Id of the review cycle instance */
  entityId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Overdue settings to indicate allowed extension */
  overdueSettings?: Maybe<OverDueSettingsType>;
  /** Total reviews already done by the reviewer */
  pendingReviewsCount?: Maybe<Scalars['Float']['output']>;
  /** Id of the Review Cycle from which this instance is created */
  reviewCycleId: Scalars['String']['output'];
  /** reviewee to whom this reviewCycleInstance is attached */
  reviewees?: Maybe<Array<RevieweeType>>;
  /** Reviewer metrics */
  reviewerMetrics?: Maybe<ReviewerMetricsType>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes: Array<ReviewTypeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** When was this instance started */
  startDate: Scalars['String']['output'];
  /** Status of this review cycle instance */
  status: Scalars['String']['output'];
  /** ReviewCycle Name or Title */
  title: Scalars['String']['output'];
  /** Total reviews to be done based on review cycle type */
  totalReviewsCount?: Maybe<Scalars['Float']['output']>;
  /** Type of this review cycle instance */
  type: ReviewCycleTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ReviewCycleModuleSettingsInput = {
  mindsetReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
  questions?: InputMaybe<Array<ReviewCycleQuestionInput>>;
  reviewInstruction?: InputMaybe<InstructionInput>;
  skillReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
};

export type ReviewCycleModuleSettingsType = {
  __typename?: 'ReviewCycleModuleSettingsType';
  mindsetReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
  questions?: Maybe<Array<ReviewCycleQuestionType>>;
  /** instructions for reviewers from admin */
  reviewInstruction?: Maybe<InstructionType>;
  skillReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
};

/** Filters applicable on userSkillReview */
export type ReviewCycleMyRevieweesInput = {
  reviewCycleId: Scalars['String']['input'];
};

/** ReviewCycleMyRevieweesPayload */
export type ReviewCycleMyRevieweesPayload = {
  __typename?: 'ReviewCycleMyRevieweesPayload';
  manager?: Maybe<Array<UserType>>;
  others?: Maybe<Array<UserType>>;
  peer?: Maybe<Array<UserType>>;
  revieweeReviewTypes?: Maybe<Array<RevieweeReviewTypes>>;
  self?: Maybe<UserType>;
  upward?: Maybe<Array<UserType>>;
};

export type ReviewCycleNudgeNotificationInput = {
  /** Notification Type to send */
  nudgeNotificationType: ReviewCycleNudgeNotificationTypeEnum;
  /** Id of the review cycle */
  reviewCycleId: Scalars['String']['input'];
  /** If userId is passed, notification will be sent to that user only */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum ReviewCycleNudgeNotificationTypeEnum {
  ReviewCycleNudgePeerApprovers = 'REVIEW_CYCLE_NUDGE_PEER_APPROVERS',
  ReviewCycleNudgePeerReviewees = 'REVIEW_CYCLE_NUDGE_PEER_REVIEWEES',
  ReviewCycleNudgeReviewers = 'REVIEW_CYCLE_NUDGE_REVIEWERS',
  ReviewCycleNudgeSharers = 'REVIEW_CYCLE_NUDGE_SHARERS'
}

/** review cycle payload */
export type ReviewCyclePayload = {
  __typename?: 'ReviewCyclePayload';
  reviewCycle: ReviewCycleType;
};

export type ReviewCyclePeerNominationPreferences = {
  __typename?: 'ReviewCyclePeerNominationPreferences';
  /** allow reviewer to decline nomination request */
  allowNomineesToDeclineRequest?: Maybe<Scalars['Boolean']['output']>;
  /** flag whether to enable nominationsLimit below */
  limitNumberOfNominations?: Maybe<Scalars['Boolean']['output']>;
  /** peer nomination max allowed count */
  nominationsLimit?: Maybe<Scalars['Float']['output']>;
  /** share self review with reviewers */
  shareSelfReviewWithReviewers?: Maybe<Scalars['Boolean']['output']>;
};

export type ReviewCyclePeerNominationPreferencesInput = {
  /** allow reviewer to decline nomination request */
  allowNomineesToDeclineRequest?: InputMaybe<Scalars['Boolean']['input']>;
  /** flag whether to enable nominationsLimit below */
  limitNumberOfNominations?: InputMaybe<Scalars['Boolean']['input']>;
  /** peer nomination max allowed count */
  nominationsLimit?: InputMaybe<Scalars['Float']['input']>;
  /** share self review with reviewers */
  shareSelfReviewWithReviewers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewCycleQuestionInput = {
  commentTitle?: InputMaybe<Scalars['String']['input']>;
  /** ref to questionId to be attached */
  questionId: Scalars['String']['input'];
  settings?: InputMaybe<UserSurveyQuestionSettingsConfigInput>;
};

export type ReviewCycleQuestionType = {
  __typename?: 'ReviewCycleQuestionType';
  commentTitle?: Maybe<Scalars['String']['output']>;
  /** ref to questionId to be attached */
  questionId: Scalars['String']['output'];
  settings?: Maybe<UserSurveyQuestionSettingsConfig>;
};

export enum ReviewCycleRecurringTypeEnum {
  NonRecurring = 'NON_RECURRING',
  Recurring = 'RECURRING',
  RecurringInstance = 'RECURRING_INSTANCE'
}

/** Filters applicable on userSkillReview */
export type ReviewCycleReportsInput = {
  reportSubTypes?: InputMaybe<Array<ReportSubTypeEnum>>;
};

export type ReviewCycleResultScoreMetadata = {
  __typename?: 'ReviewCycleResultScoreMetadata';
  /** avg of all reviews received by user in `downward` review type */
  downward?: Maybe<Scalars['Float']['output']>;
  /** overall = avg(self + upward + downward + peer) */
  overall?: Maybe<Scalars['Float']['output']>;
  /** avg of all reviews received by user in `peer` review type */
  peer?: Maybe<Scalars['Float']['output']>;
  /** avg of all reviews received by user in `self` review type */
  self?: Maybe<Scalars['Float']['output']>;
  /** avg of all reviews received by user in `upward` review type */
  upward?: Maybe<Scalars['Float']['output']>;
};

export type ReviewCycleResultShareInfo = {
  __typename?: 'ReviewCycleResultShareInfo';
  /** instructions for reviewers from admin */
  feedback?: Maybe<InstructionType>;
  /** whether the result is shared yet with learner */
  shareStatus?: Maybe<ReviewShareStatusEnum>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  /** user who shared the review result with the learner */
  sharedBy?: Maybe<Scalars['String']['output']>;
};

/** ReviewCycleRevieweeWithAverageScorePayload */
export type ReviewCycleRevieweeWithAverageScorePayload = {
  __typename?: 'ReviewCycleRevieweeWithAverageScorePayload';
  averageScore?: Maybe<Scalars['Float']['output']>;
  reviewee: UserProfile;
};

export enum ReviewCycleReviewerReviewsStatusEnum {
  Completed = 'COMPLETED',
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type ReviewCycleScheduleConfigInput = {
  peerReviewSchedule?: InputMaybe<ReviewCycleScheduleRangeConfigInput>;
  peerSelectionSchedule?: InputMaybe<ReviewCycleScheduleRangeConfigInput>;
  recurrenceSchedule?: InputMaybe<ScheduleInfoInput>;
  /** Type of recurrence of this review cycle */
  recurringType?: InputMaybe<ReviewCycleRecurringTypeEnum>;
  /** Type of schedule of this review cycle */
  scheduleType: ReviewCycleScheduleTypeEnum;
  /** for tag condition based start date */
  startCycleConditionGroup?: InputMaybe<FilterGroupV2InputType>;
  /** start date of the reviewCycle */
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewCycleScheduleConfigType = {
  __typename?: 'ReviewCycleScheduleConfigType';
  peerReviewSchedule?: Maybe<ReviewCycleScheduleRangeConfigType>;
  peerSelectionSchedule?: Maybe<ReviewCycleScheduleRangeConfigType>;
  /** recurrence schedule in case recurringType=recurring */
  recurrenceSchedule?: Maybe<ScheduleInfo>;
  /** Type of recurrence of this review cycle */
  recurringType?: Maybe<ReviewCycleRecurringTypeEnum>;
  /** Type of schedule of this review cycle */
  scheduleType: ReviewCycleScheduleTypeEnum;
  startCycleConditionGroup?: Maybe<FilterGroupV2Type>;
  /** start date of the reviewCycle */
  startDate?: Maybe<Scalars['String']['output']>;
};

export type ReviewCycleScheduleRangeConfigInput = {
  /** number of days - exact start/end dates to be derived from end of previous state */
  days?: InputMaybe<Scalars['Float']['input']>;
  /** equal to reviewStartDate */
  endDate?: InputMaybe<Scalars['String']['input']>;
  scheduleType: ReviewCycleScheduleRangeTypeEnum;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewCycleScheduleRangeConfigType = {
  __typename?: 'ReviewCycleScheduleRangeConfigType';
  /** number of days - exact start/end dates to be derived from end of previous state */
  days?: Maybe<Scalars['Float']['output']>;
  /** equal to reviewStartDate */
  endDate?: Maybe<Scalars['String']['output']>;
  scheduleType: ReviewCycleScheduleRangeTypeEnum;
  startDate?: Maybe<Scalars['String']['output']>;
};

export enum ReviewCycleScheduleRangeTypeEnum {
  Days = 'DAYS',
  Fixed = 'FIXED'
}

export enum ReviewCycleScheduleTypeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type ReviewCycleSkillSettingsInput = {
  excludedTrackSkillGroups?: InputMaybe<Array<ReviewCycleTrackSkillGroupsInput>>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentOptional?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewCycleSkillSettingsType = {
  __typename?: 'ReviewCycleSkillSettingsType';
  excludedTrackSkillGroups?: Maybe<Array<ReviewCycleTrackSkillGroups>>;
  isCommentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isCommentOptional?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export enum ReviewCycleStatusEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  PeerSelection = 'PEER_SELECTION'
}

export type ReviewCycleTemplateType = {
  __typename?: 'ReviewCycleTemplateType';
  categories?: Maybe<Array<TemplateCategoryType>>;
  categoryIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  reviewCycleTemplate: TemplateReviewCycleStub;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateStatusEnum;
  type: TemplateTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type ReviewCycleTodoType = {
  __typename?: 'ReviewCycleTodoType';
  reviewCycle: ReviewCycleType;
  type: TodoTypeEnum;
};

export type ReviewCycleTrackSkillGroups = {
  __typename?: 'ReviewCycleTrackSkillGroups';
  skillGroupIds: Array<Scalars['String']['output']>;
  trackId: Scalars['String']['output'];
};

export type ReviewCycleTrackSkillGroupsInput = {
  skillGroupIds: Array<Scalars['String']['input']>;
  trackId: Scalars['String']['input'];
};

/** ReviewCycle Schema */
export type ReviewCycleType = {
  __typename?: 'ReviewCycleType';
  /** Advance settings to control certain aspects of review */
  advanceSettings?: Maybe<AdvanceSettingsType>;
  /** Average score of current user XOR average score of team based on reportee filter in the reviewCycle */
  averageScore?: Maybe<Scalars['Float']['output']>;
  /** Average scores of current user XOR average scores of team based on reportee filter in the reviewCycle */
  averageScores?: Maybe<ReviewCycleAverageScoresPayload>;
  /** Child Review Cycles for Recurring Review Cycle */
  childReviewCycles?: Maybe<PaginatedReviewCyclesPayload>;
  /** DEPRECATED: When will the review cycle be closed */
  closesAt?: Maybe<Scalars['String']['output']>;
  /** What percent of the reviewCycle is complete */
  completionPercentage?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** ReviewCycle description */
  description?: Maybe<Scalars['String']['output']>;
  /** Settings related to downward-review */
  downwardReviewSettings?: Maybe<DownwardReviewSettingsType>;
  /** Time taken to complete one review cycle */
  duration?: Maybe<Scalars['Float']['output']>;
  /** When will the review cycle end */
  endDate?: Maybe<Scalars['String']['output']>;
  /** Icon settings */
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  instanceId?: Maybe<Scalars['Float']['output']>;
  isChildReviewCycle: Scalars['Boolean']['output'];
  /** What modules (skill/mindset) are attached to cycleReview for the (current) user in the reviewCycle */
  modulesAttachedToUser?: Maybe<Array<ReviewModuleTypeEnum>>;
  /** DEPRECATED: Types of modules to be reviewed in this review cycle */
  modulesToReview?: Maybe<Array<ReviewModuleTypeEnum>>;
  /** Participants that have been reviewed in reviewCycle by current user XOR Participants in the reviewCycle who are reportees of current user and have been reviewed */
  myReviewees?: Maybe<Array<ReviewCycleRevieweeWithAverageScorePayload>>;
  /** Participants who have reviewed the current user in the reviewCycle */
  myReviewers?: Maybe<Array<UserProfile>>;
  /** cron expression of the review cycle occurrence */
  occurrence?: Maybe<Scalars['String']['output']>;
  /** Overdue settings to indicate allowed extension */
  overdueSettings?: Maybe<OverDueSettingsType>;
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  /** Settings related to peer-review */
  peerReviewSettings?: Maybe<PeerReviewSettingsType>;
  /** Total reviews already done by the reviewer */
  pendingReviewsCount?: Maybe<Scalars['Float']['output']>;
  /** Recurring child cycle name derived from instance id */
  recurringCycleName?: Maybe<Scalars['String']['output']>;
  /** Tags of reviewerTagIds */
  reports?: Maybe<Array<ReportType>>;
  /** Checks if review cycle is completed for manager's reportees */
  reviewCycleCompletedForManagerReportees: Scalars['Boolean']['output'];
  /** How and when to end the review cycle */
  reviewCycleEndSettings?: Maybe<ReviewCycleEndSettingsType>;
  /** Count of participants that have been reviewed in reviewCycle by current user XOR count of participants in the reviewCycle who are reportees of current user and have been reviewed */
  revieweeCount?: Maybe<Scalars['Float']['output']>;
  /** reviewee to whom this reviewCycle is attached */
  reviewees?: Maybe<Array<RevieweeType>>;
  /** What percent of the reviewCycle is complete for current user */
  reviewerCompletionPercentage: Scalars['Float']['output'];
  /** Total Participants who will review the current user in the reviewCycle */
  reviewerCount?: Maybe<Scalars['Float']['output']>;
  /** Reviewer metrics */
  reviewerMetrics?: Maybe<ReviewerMetricsType>;
  /** Combined status of all reviews of current user in the reviewCycle */
  reviewerReviewsStatus?: Maybe<ReviewCycleReviewerReviewsStatusEnum>;
  /** DEPRECATED: TagId of the Tag (userType) that is selected as a reviewer for the reviewees in the review cycle */
  reviewerTagIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Tags of reviewerTagIds */
  reviewerTags?: Maybe<Array<TagUnion>>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes?: Maybe<Array<ReviewTypeEnum>>;
  sameItemsForAllReviewTypes: Scalars['Boolean']['output'];
  /** manual/automatic schedule settings */
  scheduleSettings?: Maybe<ReviewCycleScheduleConfigType>;
  /** Settings related to self-review */
  selfReviewSettings?: Maybe<ReviewCycleModuleSettingsType>;
  /** share status of the result of current reviewCycle for current user */
  shareStatus?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Sibling Review Cycles for Child Recurring Review Cycle */
  siblingReviewCycles?: Maybe<PaginatedReviewCyclesPayload>;
  /** When will the review cycle start */
  startDate?: Maybe<Scalars['String']['output']>;
  /** Status of this review cycle */
  status: ReviewCycleStatusEnum;
  /** ReviewCycle Name or Title */
  title: Scalars['String']['output'];
  /** Total participants the current user has to review in the reviewCycle */
  totalRevieweeCount?: Maybe<Scalars['Float']['output']>;
  /** Total reviews to be done based on review cycle type */
  totalReviewsCount?: Maybe<Scalars['Float']['output']>;
  /** Type of this review cycle */
  type: ReviewCycleTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Settings related to upward-review */
  upwardReviewSettings?: Maybe<UpwardReviewSettingsType>;
  userReviewCycleContext: ReviewCycleUserContextInfo;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Review Cycle status for user */
  userStatus: ReviewCycleStatusEnum;
  userTeamReviewCycleContext: ReviewCycleUserTeamContextInfo;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeAverageScoreArgs = {
  filters?: InputMaybe<UserTeamFilters>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeAverageScoresArgs = {
  filters?: InputMaybe<UserTeamFilters>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeChildReviewCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeModulesAttachedToUserArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeMyRevieweesArgs = {
  filters?: InputMaybe<UserTeamFilters>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeReportsArgs = {
  filters?: InputMaybe<ReviewCycleReportsInput>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeRevieweeCountArgs = {
  filters?: InputMaybe<UserTeamFilters>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeSiblingReviewCyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeUserReviewCycleContextArgs = {
  userId: Scalars['String']['input'];
};


/** ReviewCycle Schema */
export type ReviewCycleTypeUserStatusArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** ReviewCycle Schema */
export type ReviewCycleTypeUserTeamReviewCycleContextArgs = {
  userId: Scalars['String']['input'];
};

export enum ReviewCycleTypeEnum {
  Automated = 'AUTOMATED',
  CheckIn = 'CHECK_IN',
  Organizational = 'ORGANIZATIONAL',
  Project = 'PROJECT',
  Skill = 'SKILL'
}

export enum ReviewCycleUserActionsEnum {
  ApprovePeers = 'APPROVE_PEERS',
  NominatePeers = 'NOMINATE_PEERS',
  ViewResult = 'VIEW_RESULT',
  WriteReview = 'WRITE_REVIEW'
}

export type ReviewCycleUserContextInfo = {
  __typename?: 'ReviewCycleUserContextInfo';
  primaryAction?: Maybe<ReviewCycleUserPrimaryActionEnum>;
  reviewCycleId: Scalars['String']['output'];
  reviewScore?: Maybe<Scalars['Float']['output']>;
  reviewsDoneProgress?: Maybe<Scalars['Float']['output']>;
};

export type ReviewCycleUserFilter = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export enum ReviewCycleUserPrimaryActionEnum {
  ApprovalPending = 'APPROVAL_PENDING',
  AwaitingResults = 'AWAITING_RESULTS',
  NominatePeers = 'NOMINATE_PEERS',
  PeersApproved = 'PEERS_APPROVED',
  ShareResults = 'SHARE_RESULTS',
  ViewRequests = 'VIEW_REQUESTS',
  WriteReviews = 'WRITE_REVIEWS'
}

export type ReviewCycleUserTeamContextInfo = {
  __typename?: 'ReviewCycleUserTeamContextInfo';
  primaryAction?: Maybe<ReviewCycleUserPrimaryActionEnum>;
  reviewCycleId: Scalars['String']['output'];
  reviewScore?: Maybe<Scalars['Float']['output']>;
  reviewsDoneProgress?: Maybe<Scalars['Float']['output']>;
};

export type ReviewCyclesFilters = {
  /** Review cycle closes after */
  closesAfter?: InputMaybe<Scalars['DateTime']['input']>;
  /** Review cycle that closes within a date range */
  closesOn?: InputMaybe<DateFilter>;
  contextualFilter?: InputMaybe<ReviewCycleContextualFilterInput>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isChildReviewCycle?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  parentReviewCycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reporteeFilterType?: InputMaybe<ReporteeFilterTypeInput>;
  reviewedAt?: InputMaybe<DateFilter>;
  revieweeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** reviewee to which reviewCycle instance can be attached */
  reviewees?: InputMaybe<Array<RevieweeInput>>;
  reviewerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Combined Status of all reviews of current user in the review cycle */
  reviewerReviewsStatus?: InputMaybe<Array<ReviewCycleReviewerReviewsStatusEnum>>;
  reviewers?: InputMaybe<Array<ReviewTypeEnum>>;
  /** name of the Skill */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** Status of Review Cycle one needs to fetch */
  status?: InputMaybe<Array<ReviewCycleStatusEnum>>;
  type?: InputMaybe<Array<ReviewCycleTypeEnum>>;
};

export type ReviewCyclesWidgetType = {
  __typename?: 'ReviewCyclesWidgetType';
  actionableReviewCycles: Array<ActionableReviewCycle>;
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  reviewCycles: Array<ReviewCycleType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type ReviewCyclesWidgetTypeReviewCyclesArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
};

export enum ReviewModuleTypeEnum {
  Mindset = 'MINDSET',
  Question = 'QUESTION',
  Skill = 'SKILL',
  SkillGroup = 'SKILL_GROUP'
}

export enum ReviewShareStatusEnum {
  NotShared = 'NOT_SHARED',
  Shared = 'SHARED'
}

export enum ReviewStatusEnum {
  InProgress = 'IN_PROGRESS',
  NotRequired = 'NOT_REQUIRED',
  NotStarted = 'NOT_STARTED',
  PeerReviewPending = 'PEER_REVIEW_PENDING',
  Rejected = 'REJECTED',
  Reviewed = 'REVIEWED',
  Unassigned = 'UNASSIGNED'
}

export enum ReviewTypeEnum {
  Admin = 'ADMIN',
  Assessment = 'ASSESSMENT',
  Children = 'CHILDREN',
  Manager = 'MANAGER',
  Overall = 'OVERALL',
  Peer = 'PEER',
  PeerFeedback = 'PEER_FEEDBACK',
  Self = 'SELF',
  Tag = 'TAG',
  Upward = 'UPWARD',
  User = 'USER'
}

export enum RevieweeEntityTypeEnum {
  AllLearners = 'ALL_LEARNERS',
  Condition = 'CONDITION',
  Department = 'DEPARTMENT',
  Group = 'GROUP',
  OrgRole = 'ORG_ROLE',
  User = 'USER',
  Workspace = 'WORKSPACE'
}

/** Reviwee Object schema  */
export type RevieweeInput = {
  /** Ids of reviewees */
  entityIds: Array<Scalars['String']['input']>;
  /** Type of reviewee added */
  entityType: RevieweeEntityTypeEnum;
};

/** A map of all distinct reviewTypes on which a reviewee is getting reviewed (by a particular reviewer) */
export type RevieweeReviewTypes = {
  __typename?: 'RevieweeReviewTypes';
  reviewTypes: Array<Scalars['String']['output']>;
  reviewee?: Maybe<UserType>;
  revieweeId: Scalars['String']['output'];
};

/** Reviwee Object schema  */
export type RevieweeType = {
  __typename?: 'RevieweeType';
  /** Ids of reviewees */
  entityIds: Array<Scalars['String']['output']>;
  /** Type of reviewee added */
  entityType: RevieweeEntityTypeEnum;
};

export type ReviewerConfig = {
  __typename?: 'ReviewerConfig';
  anonymousReview?: Maybe<Scalars['Boolean']['output']>;
  defaultReviewerId?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  minimumReviewsRequired?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['String']['output']>;
  type: ReviewerTypeEnum;
  userGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type ReviewerConfigFilters = {
  defaultReviewerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldNames?: InputMaybe<Array<Scalars['String']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<ReviewerTypeEnum>>;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReviewerConfigInput = {
  anonymousReview?: InputMaybe<Scalars['Boolean']['input']>;
  defaultReviewerId?: InputMaybe<Scalars['String']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  minimumReviewsRequired?: InputMaybe<Scalars['Float']['input']>;
  tagId?: InputMaybe<Scalars['String']['input']>;
  type: ReviewerTypeEnum;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReviewerExerciseSubmissionScorecardScoreType = {
  __typename?: 'ReviewerExerciseSubmissionScorecardScoreType';
  reviewerId: Scalars['String']['output'];
  scorecardScores: Array<ExerciseSubmissionScorecardScoreType>;
};

/** Data regarding the reviewer and assignment  */
export type ReviewerInfo = {
  __typename?: 'ReviewerInfo';
  assignedAt: Scalars['String']['output'];
  reviewerId: Scalars['String']['output'];
};

/** ReviewerMetricsType */
export type ReviewerMetricsType = {
  __typename?: 'ReviewerMetricsType';
  /** Count of pending reviews to be done by reviewer */
  pendingReviewsCount: Scalars['Float']['output'];
  /** Total reviews to be done by reviewer */
  totalReviewsCount: Scalars['Float']['output'];
};

/** ReviewerReviewResponse Schema */
export type ReviewerResponseType = {
  __typename?: 'ReviewerResponseType';
  /** feedback text */
  feedbackText?: Maybe<Scalars['String']['output']>;
  /** question */
  questionId: Scalars['String']['output'];
  /** response */
  response?: Maybe<Scalars['String']['output']>;
  /** response type */
  responseType?: Maybe<Scalars['String']['output']>;
};

export type ReviewerReviewCycleInfo = {
  __typename?: 'ReviewerReviewCycleInfo';
  reviewer?: Maybe<UserType>;
  reviewerId: Scalars['String']['output'];
  reviewerReviewStatus: CycleReviewStatusEnum;
};

export enum ReviewerTypeEnum {
  Dynamic = 'DYNAMIC',
  Group = 'GROUP',
  Peer = 'PEER',
  Specific = 'SPECIFIC'
}

export type RevokeUserPublicApiCredentialsInput = {
  /** All credentials matching this filter will be deleted */
  filters: UserPublicApiCredentialsFilters;
};

/** This returns paginated Roles */
export type RoleConntection = {
  __typename?: 'RoleConntection';
  edges?: Maybe<Array<RoleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  node: RoleType;
};

export type RoleMetadata = {
  __typename?: 'RoleMetadata';
  configuredPermissions?: Maybe<Array<Scalars['String']['output']>>;
  tagColor?: Maybe<Scalars['String']['output']>;
};

export type RolePermissionDescriptionType = {
  __typename?: 'RolePermissionDescriptionType';
  keys: Array<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type RolePermissionDescriptionsPayload = {
  __typename?: 'RolePermissionDescriptionsPayload';
  rolePermissionDescriptions: Array<RolePermissionDescriptionType>;
};

/** Role Profile */
export type RoleProfileType = {
  __typename?: 'RoleProfileType';
  id: Scalars['String']['output'];
  sampleUsers: Array<UserType>;
  userIds: Array<Scalars['String']['output']>;
  usersCount: Scalars['Float']['output'];
};

export enum RoleStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

/** The Role model */
export type RoleType = {
  __typename?: 'RoleType';
  /** On which entity the role is applicable */
  applicableOn: Array<ApplicableOnEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Custom role for entity */
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  metadata?: Maybe<RoleMetadata>;
  name: Scalars['String']['output'];
  permittedOn: Array<PermittedOnType>;
  restrictedPermittedOn?: Maybe<RestrictedPermittedOn>;
  roleProfile: RoleProfileType;
  sampleUsers: Array<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: Scalars['String']['output'];
  /** Type of role */
  type: RoleTypesEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum RoleTypesEnum {
  Admin = 'ADMIN',
  Assigner = 'ASSIGNER',
  Author = 'AUTHOR',
  CompanyObjective = 'COMPANY_OBJECTIVE',
  Custom = 'CUSTOM',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  EditAccess = 'EDIT_ACCESS',
  EventOwner = 'EVENT_OWNER',
  FullAccess = 'FULL_ACCESS',
  Instructor = 'INSTRUCTOR',
  Learner = 'LEARNER',
  Owner = 'OWNER',
  Participant = 'PARTICIPANT',
  TeamObjective = 'TEAM_OBJECTIVE',
  UserSurveyAdmin = 'USER_SURVEY_ADMIN',
  ViewAccess = 'VIEW_ACCESS'
}

export type RolesFilters = {
  applicableOn?: InputMaybe<Array<ApplicableOnEnum>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  roleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<RoleStatusEnum>>;
  types?: InputMaybe<Array<RoleTypesEnum>>;
};

export enum RootActionItemFilter {
  Both = 'BOTH',
  NonRootActionItems = 'NON_ROOT_ACTION_ITEMS',
  RootActionItems = 'ROOT_ACTION_ITEMS'
}

/** Skill Schema */
export type Rubric = {
  __typename?: 'Rubric';
  /** Meaning while giving that score. */
  description?: Maybe<Scalars['String']['output']>;
  /** Label corresponding to a score. */
  label: Scalars['String']['output'];
  /** value/score for that skill. */
  score: Scalars['Float']['output'];
};

/** Rubric Input */
export type RubricInput = {
  /** Meaning while giving that score. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Label corresponding to the score */
  label: Scalars['String']['input'];
  /** value/score for that skill. */
  score: Scalars['Float']['input'];
};

/** Rubric Stub Generated By AI */
export type RubricStubType = {
  __typename?: 'RubricStubType';
  /** Meaning while giving that score. */
  description?: Maybe<Scalars['String']['output']>;
  /** Label corresponding to a score. */
  label: SkillRubricLabelEnum;
  /** value/score for that skill. */
  score: Scalars['Float']['output'];
};

export type SamlDataType = {
  __typename?: 'SAMLDataType';
  IDPEntityId: Scalars['String']['output'];
  SPEntityId: Scalars['String']['output'];
  SPInitiated: Scalars['Boolean']['output'];
  idpCertificate: Scalars['String']['output'];
  ifId?: Maybe<Scalars['String']['output']>;
  onFailure: OnFailureActions;
  onSuccess: OnSuccessActions;
  samlAttributesToTagMapping?: Maybe<Scalars['String']['output']>;
  samlSSOUrl?: Maybe<Scalars['String']['output']>;
  samlType?: Maybe<SamlTypeEnum>;
  syncAttributes: SyncAttributesType;
};

export type SamlDataTypeEntity = {
  __typename?: 'SAMLDataTypeEntity';
  IDPEntityId: Scalars['String']['output'];
  SPEntityId: Scalars['String']['output'];
  SPInitiated: Scalars['Boolean']['output'];
  idpCertificate: Scalars['String']['output'];
  ifId?: Maybe<Scalars['String']['output']>;
  onFailure: OnFailureActionsType;
  onSuccess: OnSuccessActionsType;
  samlAttributesToTagMapping?: Maybe<Scalars['String']['output']>;
  samlSSOUrl?: Maybe<Scalars['String']['output']>;
  samlType?: Maybe<SamlTypeEnum>;
  syncAttributes: SyncAttributesTypeEntity;
};

export enum SamlTypeEnum {
  HttpPost = 'HTTP_POST',
  HttpRedirect = 'HTTP_REDIRECT'
}

/** SEOSettings Type */
export type SeoSettingsType = {
  __typename?: 'SEOSettingsType';
  /** Represents SEO productDescription */
  productDescription: Scalars['String']['output'];
  /** Represents SEO productTitle */
  productTitle: Scalars['String']['output'];
  /** Represents slug in SEO  */
  slug: Scalars['String']['output'];
};

export type SaveWorkspaceAgendaItemsInput = {
  agendaItems: Array<UpsertAgendaItemInput>;
};

/** OKR Cycle schedule config for various reminders  */
export type ScheduleConfig = {
  __typename?: 'ScheduleConfig';
  checkinReminderSchedule?: Maybe<ScheduleInfo>;
};

export type ScheduleConfigInput = {
  checkinReminderSchedule?: InputMaybe<ScheduleInfoInput>;
};

export type ScheduleDateAssignmentConfig = {
  __typename?: 'ScheduleDateAssignmentConfig';
  operator?: Maybe<RelativeDateFilterOperatorEnum>;
  scheduleDate?: Maybe<Scalars['String']['output']>;
  scheduleDateType: AssignmentScheduleDateEnum;
  unit?: Maybe<RelativeDateFilterUnitEnum>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ScheduleDateAssignmentConfigInput = {
  operator?: InputMaybe<RelativeDateFilterOperatorEnum>;
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  scheduleDateType: AssignmentScheduleDateEnum;
  unit?: InputMaybe<RelativeDateFilterUnitEnum>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type ScheduleInfo = {
  __typename?: 'ScheduleInfo';
  MonthOfYear?: Maybe<MonthOfYearEnum>;
  custom?: Maybe<CustomFrequency>;
  dayOfMonth?: Maybe<Scalars['Float']['output']>;
  dayOfWeek?: Maybe<DayOfWeekEnum>;
  daysOfWeek?: Maybe<Array<DayOfWeekEnum>>;
  endScheduleInfo?: Maybe<EndScheduleInfo>;
  frequency: CronFrequencyEnum;
  monthsOfYear?: Maybe<Array<MonthOfYearEnum>>;
  startDate?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  weekOfMonth?: Maybe<WeekOfMonthEnum>;
  weeksOfMonth?: Maybe<Array<WeekOfMonthEnum>>;
};

export type ScheduleInfoInput = {
  MonthOfYear?: InputMaybe<MonthOfYearEnum>;
  custom?: InputMaybe<CustomFrequencyInput>;
  dayOfMonth?: InputMaybe<Scalars['Float']['input']>;
  dayOfWeek?: InputMaybe<DayOfWeekEnum>;
  daysOfWeek?: InputMaybe<Array<DayOfWeekEnum>>;
  endScheduleInfo?: InputMaybe<EndScheduleInfoInput>;
  frequency: CronFrequencyEnum;
  monthsOfYear?: InputMaybe<Array<MonthOfYearEnum>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  time: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  weekOfMonth?: InputMaybe<WeekOfMonthEnum>;
  weeksOfMonth?: InputMaybe<Array<WeekOfMonthEnum>>;
};

export type ScheduledJobAssignmentConfig = {
  __typename?: 'ScheduledJobAssignmentConfig';
  deactivateAutoSyncUser?: Maybe<Scalars['String']['output']>;
  publishAssignment?: Maybe<Scalars['String']['output']>;
};

/** Scheduled Job Schema */
export type ScheduledJobConfigs = {
  __typename?: 'ScheduledJobConfigs';
  jobId: Scalars['String']['output'];
  purpose: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** ScoreDetailsType Schema */
export type ScoreDetailsType = {
  __typename?: 'ScoreDetailsType';
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** user who submitted the assignment */
  reviewCycleId?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

/** This returns paginated score cards */
export type ScorecardConnections = {
  __typename?: 'ScorecardConnections';
  edges?: Maybe<Array<ScorecardEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type ScorecardEdge = {
  __typename?: 'ScorecardEdge';
  node: ScorecardType;
};

export type ScorecardQuestionInput = {
  maxScore: Scalars['Float']['input'];
  order: Scalars['Float']['input'];
  text: Scalars['String']['input'];
};

/** Question object for Scorecard */
export type ScorecardQuestionType = {
  __typename?: 'ScorecardQuestionType';
  id: Scalars['String']['output'];
  /** maximum score */
  maxScore: Scalars['Float']['output'];
  /** order of the scorecard question */
  order: Scalars['Float']['output'];
  text: Scalars['String']['output'];
};

/** ScorecardReview Schema */
export type ScorecardReviewType = {
  __typename?: 'ScorecardReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entityType: ResourceTypeEnum;
  id: Scalars['String']['output'];
  /** reviewer response */
  responses?: Maybe<Array<ReviewerResponseType>>;
  reviewType: Scalars['String']['output'];
  /** reviewer for the scorecardReview */
  reviewer?: Maybe<UserType>;
  /** reviewer for the assignment */
  reviewerId: Scalars['String']['output'];
  /** overall score of scorecard */
  score?: Maybe<Scalars['Float']['output']>;
  /** scorecard for the scorecardReview */
  scorecard?: Maybe<ScorecardType>;
  /** scorecard */
  scorecardId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  submissionId: Scalars['String']['output'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user for the scorecardReview */
  user?: Maybe<UserType>;
  /** user who submitted the assignment */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Scorecard Type */
export type ScorecardType = {
  __typename?: 'ScorecardType';
  averageScore?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Questions for the score card */
  questions?: Maybe<Array<ScorecardQuestionType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skill?: Maybe<SkillType>;
  /** denotes scorecard belongs to which skill */
  skillId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ScorecardsFilters = {
  /** Filter score cards based on resource ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter scorecards by skills */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter scorecards by title */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ScormContentType = {
  __typename?: 'ScormContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  /** Description of the external content */
  description?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<DraftScormContentType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** Duration in seconds */
  duration: Scalars['Float']['output'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  /** Objective of the scorm content */
  objective?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<ScormPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  scormProperties?: Maybe<ScormProperties>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  /** scorm skills */
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


export type ScormContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


export type ScormContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


export type ScormContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type ScormContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


export type ScormContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


export type ScormContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type ScormContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type ScormContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


export type ScormContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type ScormImportMetadataType = {
  __typename?: 'ScormImportMetadataType';
  masteryScore?: Maybe<Scalars['Float']['output']>;
  resourceHref?: Maybe<Scalars['String']['output']>;
  schemaVersion?: Maybe<Scalars['String']['output']>;
};

export type ScormPackageInfo = {
  __typename?: 'ScormPackageInfo';
  byteSize: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ScormPackageInfoInput = {
  byteSize: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ScormPayload = {
  __typename?: 'ScormPayload';
  scormContent: ScormContentType;
};

/** Course Performance Type */
export type ScormPerformanceType = {
  __typename?: 'ScormPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  meta?: Maybe<Scalars['String']['output']>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type ScormProperties = {
  __typename?: 'ScormProperties';
  /** mastery score */
  masteryScore?: Maybe<Scalars['Float']['output']>;
  packageInfo?: Maybe<ScormPackageInfo>;
  /** href to scorm resource */
  resourceHref: Scalars['String']['output'];
  /** scorm schema version */
  schemaVersion: Scalars['String']['output'];
};

export type ScormPropertiesInput = {
  masteryScore?: InputMaybe<Scalars['Float']['input']>;
  packageInfo?: InputMaybe<ScormPackageInfoInput>;
  resourceHref: Scalars['String']['input'];
  schemaVersion: Scalars['String']['input'];
};

export enum SearchConversionTypeEnum {
  NoResults = 'NO_RESULTS',
  NoResultClick = 'NO_RESULT_CLICK',
  ResultClicked = 'RESULT_CLICKED'
}

export type SearchFacetType = {
  __typename?: 'SearchFacetType';
  applyOn: Array<FacetApplyOnType>;
  defaultValues?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']['output']>;
  type: FacetTypeEnum;
};

export enum SearchWidgetTypeEnum {
  BookmarkContents = 'BOOKMARK_CONTENTS',
  Contents = 'CONTENTS',
  Events = 'EVENTS',
  Lessons = 'LESSONS'
}

export enum SearchableContentTypeEnum {
  Article = 'ARTICLE',
  Block = 'BLOCK',
  Checkin = 'CHECKIN',
  Course = 'COURSE',
  Event = 'EVENT',
  Exercise = 'EXERCISE',
  External = 'EXTERNAL',
  ExternalContent = 'EXTERNAL_CONTENT',
  ExternalEventContent = 'EXTERNAL_EVENT_CONTENT',
  Homework = 'HOMEWORK',
  Live = 'LIVE',
  LiveEventContent = 'LIVE_EVENT_CONTENT',
  Offline = 'OFFLINE',
  OfflineEventContent = 'OFFLINE_EVENT_CONTENT',
  Path = 'PATH',
  Quiz = 'QUIZ',
  RecurringExternal = 'RECURRING_EXTERNAL',
  RecurringExternalEventContent = 'RECURRING_EXTERNAL_EVENT_CONTENT',
  RecurringLive = 'RECURRING_LIVE',
  RecurringLiveEventContent = 'RECURRING_LIVE_EVENT_CONTENT',
  RecurringOffline = 'RECURRING_OFFLINE',
  RecurringOfflineEventContent = 'RECURRING_OFFLINE_EVENT_CONTENT',
  Resource = 'RESOURCE',
  Scorm = 'SCORM',
  Survey = 'SURVEY',
  Task = 'TASK'
}

export type SectionChildResourceConfigsInputType = {
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  isOptional: Scalars['Boolean']['input'];
  resourceId: Scalars['String']['input'];
};

/** SectionChildResourceConfigs */
export type SectionChildResourceConfigsType = {
  __typename?: 'SectionChildResourceConfigsType';
  /** Content level dueDate config */
  dueDateConfig?: Maybe<DueDateAssignmentConfig>;
  /** Content Progress is Optional or not */
  isOptional: Scalars['Boolean']['output'];
  /** ResourceId of the current section */
  resourceId: Scalars['String']['output'];
};

export type SectionInfoInput = {
  /** spaces belonging to section */
  channels?: InputMaybe<Array<CommunityChannelInfoInput>>;
  /** name of section */
  name: Scalars['String']['input'];
  /** id of section block */
  sectionBlockId?: InputMaybe<Scalars['String']['input']>;
};

export type SelectFacetOption = {
  __typename?: 'SelectFacetOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Select field options */
export type SelectFieldOptionsType = {
  __typename?: 'SelectFieldOptionsType';
  /** Color for select option */
  color: Scalars['String']['output'];
  /** Option Id */
  optionId: Scalars['String']['output'];
  /** Value for select option */
  value: Scalars['String']['output'];
};

export type SelectGroupsInput = {
  /** filter groups by selection config */
  groupsSelectionConfig: GroupsSelectionConfigInput;
  /** current selection of group ids */
  selectedGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SelectGroupsPayload = {
  __typename?: 'SelectGroupsPayload';
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type SelectTagOptionInput = {
  externalData?: InputMaybe<Scalars['String']['input']>;
  /** if value is provided, then it will be used to create new option, for empty Id, it will be autoGenerated */
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type SelectTagOptionType = {
  __typename?: 'SelectTagOptionType';
  /** key:value pairs for external data, For ex : status=12:Project=SPR */
  externalData?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SendUserSurveyEndReminderInput = {
  surveyId: Scalars['String']['input'];
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SendUserSurveyEndReminderPayload = {
  __typename?: 'SendUserSurveyEndReminderPayload';
  success: Scalars['Boolean']['output'];
};

export type SetCertificateSettingsInput = {
  set: CertificateSettingsInput;
};

export type SetEntityAccessInput = {
  entityId: Scalars['String']['input'];
  entityType: PermissionEntities;
  permissions: Array<PermissionInput>;
};

export type SetEventWorkConfigInput = {
  set: EventWorkConfigInput;
};

export type SetPageComponentsInput = {
  set: Array<PageComponentInput>;
};

export type SetRegistrationSettingsInput = {
  set: RegistrationSettingsInput;
};

export type SetSamlDataInput = {
  IDPEntityId: Scalars['String']['input'];
  SPInitiated?: InputMaybe<Scalars['Boolean']['input']>;
  idpCertificate: Scalars['String']['input'];
  ifId?: InputMaybe<Scalars['String']['input']>;
  onFailure?: InputMaybe<OnFailureActionsInput>;
  onSuccess?: InputMaybe<OnSuccessActionsInput>;
  samlAttributesToTagMapping?: InputMaybe<Scalars['String']['input']>;
  samlSSOUrl?: InputMaybe<Scalars['String']['input']>;
  samlType?: InputMaybe<SamlTypeEnum>;
  syncAttributes: SyncAttributesInput;
};

export type SetSkillIds = {
  set: Array<Scalars['String']['input']>;
};

export type SetStringInput = {
  set: Scalars['String']['input'];
};

export type SetUserLanguageInput = {
  languageTagId: Scalars['String']['input'];
  optionId: Scalars['String']['input'];
};

export type SetUserPreferencesInput = {
  showMarketplaceContentCatalogBanner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetWorkspaceAdministrationConfigInput = {
  clientOwnerUserId?: InputMaybe<Scalars['String']['input']>;
  excludeLearnerEmailPatterns?: InputMaybe<Array<Scalars['String']['input']>>;
  licenseLimits?: InputMaybe<Array<WorkspaceLicenseLimitInput>>;
  licenseTagId?: InputMaybe<Scalars['String']['input']>;
  maxLearnersCount?: InputMaybe<Scalars['Float']['input']>;
  notificationRules?: InputMaybe<WorkspaceAdministrationRulesInput>;
  renewalDate?: InputMaybe<Scalars['String']['input']>;
  renewalStatus?: InputMaybe<WorkspaceRenewalStatusEnum>;
  renewalType?: InputMaybe<WorkspaceRenewalTypeEnum>;
  serviceContactUserId?: InputMaybe<Scalars['String']['input']>;
};

export type SetWorkspaceStrategyMethodInput = {
  allowUsername?: InputMaybe<Scalars['Boolean']['input']>;
  authCodeValidatorUrl?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  samlConfigs?: InputMaybe<Array<SetSamlDataInput>>;
  scope?: InputMaybe<Scalars['String']['input']>;
  signInLabel?: InputMaybe<Scalars['String']['input']>;
  signUpLabel?: InputMaybe<Scalars['String']['input']>;
  type: SupportedStrategyMethodTypeEnum;
  webStrategyUrl?: InputMaybe<Scalars['String']['input']>;
};

export type SetupAcademyInput = {
  departmentTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Details of users/staff to be invited */
  toInviteUsers: Array<InviteUserInput>;
  upsertIntegrationDetailInput?: InputMaybe<UpsertIntegrationDetailInput>;
};

export type SetupAcademyPayload = {
  __typename?: 'SetupAcademyPayload';
  jobScheduleId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ShareConfig = {
  __typename?: 'ShareConfig';
  allowDuplication?: Maybe<Scalars['Boolean']['output']>;
  sharedAt: Scalars['String']['output'];
  sharedById: Scalars['String']['output'];
  /** content id in source workspace */
  sourceWorkspaceEntityId?: Maybe<Scalars['String']['output']>;
  status: ShareConfigStatusEnum;
  workspaceId: Scalars['String']['output'];
};

export enum ShareConfigStatusEnum {
  Received = 'RECEIVED',
  Revoked = 'REVOKED',
  Sent = 'SENT'
}

export type ShareItemInput = {
  itemId: Scalars['String']['input'];
  type: SharedItemTypeEnum;
};

export type ShareItemsInput = {
  allowDuplication?: InputMaybe<Scalars['Boolean']['input']>;
  items: Array<ShareItemInput>;
  sharedToWorkspaceIds: Array<Scalars['String']['input']>;
};

export type ShareReviewInput = {
  feedback?: InputMaybe<InstructionInput>;
  reviewCycleId: Scalars['String']['input'];
  shareAllReviews?: InputMaybe<Scalars['Boolean']['input']>;
  /** ids of result receiver, empty in case shareAllReviews:true */
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ShareReviewPayload = {
  __typename?: 'ShareReviewPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
  userCycleReviews?: Maybe<Array<UserCycleReviewType>>;
};

export type SharedConnectionFilters = {
  connectionId: Scalars['String']['input'];
};

export type SharedConnectionInfo = {
  __typename?: 'SharedConnectionInfo';
  connectedAt?: Maybe<Scalars['String']['output']>;
  connectedBy?: Maybe<UserType>;
  connectedById?: Maybe<Scalars['String']['output']>;
  connectionId: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  receivedItemsCount?: Maybe<Scalars['Float']['output']>;
  requestedAt: Scalars['String']['output'];
  sentItemsCount?: Maybe<Scalars['Float']['output']>;
  sharedItemsCount?: Maybe<Scalars['Float']['output']>;
  status: TenantConnectionStatusEnum;
  workspaceId: Scalars['String']['output'];
  workspaceLogo: Scalars['String']['output'];
  workspaceName: Scalars['String']['output'];
  workspaceURI: Scalars['String']['output'];
};

/** Shared Connection Info Edge */
export type SharedConnectionInfoEdge = {
  __typename?: 'SharedConnectionInfoEdge';
  node: SharedConnectionInfo;
};

export type SharedConnectionsFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type SharedConnectionsInfoPayload = {
  __typename?: 'SharedConnectionsInfoPayload';
  active?: Maybe<PaginatedSharedConnectionInfo>;
  pending?: Maybe<PaginatedSharedConnectionInfo>;
  sent?: Maybe<PaginatedSharedConnectionInfo>;
};


export type SharedConnectionsInfoPayloadActiveArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type SharedConnectionsInfoPayloadPendingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};


export type SharedConnectionsInfoPayloadSentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type SharedEntityFilters = {
  sharedAt?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<ShareConfigStatusEnum>>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
  workspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SharedItemTypeEnum {
  Collection = 'COLLECTION',
  Content = 'CONTENT',
  Session = 'SESSION'
}

/** Defines group and associated role on given entity */
export type SharedSpaceGroupRoleType = {
  __typename?: 'SharedSpaceGroupRoleType';
  group?: Maybe<UserCollectionGroupType>;
  groupId: Scalars['String']['output'];
  role?: Maybe<RoleType>;
  /** role Id of group on entity */
  roleId?: Maybe<Scalars['String']['output']>;
};

/** Defines user and associated role on given entity */
export type SharedSpaceUserRoleType = {
  __typename?: 'SharedSpaceUserRoleType';
  role?: Maybe<RoleType>;
  /** role Id of user on entity */
  roleId: Scalars['String']['output'];
  user?: Maybe<UserType>;
  userId: Scalars['String']['output'];
};

/** Edge */
export type SharedViewEdge = {
  __typename?: 'SharedViewEdge';
  node: SharedViewType;
};

export type SharedViewFilters = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEntityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<SharedViewTypeEnum>>;
};

export type SharedViewInput = {
  id: Scalars['String']['input'];
};

export type SharedViewPayload = {
  __typename?: 'SharedViewPayload';
  sharedView?: Maybe<SharedViewType>;
};

export type SharedViewType = {
  __typename?: 'SharedViewType';
  allowComments: Scalars['Boolean']['output'];
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentEntityId: Scalars['String']['output'];
  type: SharedViewTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedById?: Maybe<Scalars['String']['output']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export enum SharedViewTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT'
}

export type SingleSelectFacetType = {
  __typename?: 'SingleSelectFacetType';
  applyOn: FacetApplyOnType;
  defaultValues?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isOptionLocalisationNeeded: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  options: Array<SelectFacetOption>;
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']['output']>;
  type: FacetTypeEnum;
};

export type SingleSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']['input']>;
  options: Array<SelectTagOptionInput>;
};

export type SingleSelectTagType = {
  __typename?: 'SingleSelectTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** If tag can have dynamic option values */
  ignoreOptions?: Maybe<Scalars['Boolean']['output']>;
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** number type tag specific fields */
  options: Array<SelectTagOptionType>;
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']['output']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** This denotes, whether we need to create a new dynamic group, when a new option is added inside single/multi select */
  syncGroup?: Maybe<Scalars['Boolean']['output']>;
  /** field resolvers */
  taggedContentCount: Scalars['Float']['output'];
  taggedEventCount: Scalars['Float']['output'];
  taggedOKRCount: Scalars['Float']['output'];
  taggedTaskCount: Scalars['Float']['output'];
  taggedUserCount: Scalars['Float']['output'];
  type: TagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type SingleSelectTagTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type SingleSelectTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

/** Site Setting Parameters */
export type SiteSettingsType = {
  __typename?: 'SiteSettingsType';
  /** Admin user name for that workspace */
  admin?: Maybe<Scalars['String']['output']>;
  /** Application type for the workspace */
  app?: Maybe<Scalars['String']['output']>;
  /** default favicon URL for the site */
  favicon: Scalars['String']['output'];
  /** default logo URL for the site */
  logo: Array<DeprecatedMediaType>;
};

/** SkillReview Schema */
export type SkillAssessmentReviewType = {
  __typename?: 'SkillAssessmentReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** overall score on assessment */
  maxScore: Scalars['Float']['output'];
  /** reviewerType */
  reviewType: Scalars['String']['output'];
  /** overall score on assessment */
  score: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** DEPRECATED */
  skillGroupId?: Maybe<Scalars['String']['output']>;
  skillId: Scalars['String']['output'];
  /** DEPRECATED */
  skillMatrixId?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<SkillTypeEnum>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting marks */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Category of Skill */
export type SkillCategoryType = {
  __typename?: 'SkillCategoryType';
  /** Skill Category Icon */
  icon: Scalars['String']['output'];
  /** Skill Category Name */
  name: Scalars['String']['output'];
};

/** This returns paginated Skill */
export type SkillConnections = {
  __typename?: 'SkillConnections';
  edges?: Maybe<Array<SkillEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type SkillEdge = {
  __typename?: 'SkillEdge';
  node: SkillType;
};

export type SkillGroupInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  skillIds: Array<Scalars['String']['input']>;
};

/** SkillReview Schema */
export type SkillGroupReviewType = {
  __typename?: 'SkillGroupReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  /** review cycle instance */
  reviewCycleId: Scalars['String']['output'];
  /** reviewerType */
  reviewType: Scalars['String']['output'];
  /** user who reviewed the assignment */
  reviewerId: Scalars['String']['output'];
  /** avg score of given skill groups */
  score: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroupId: Scalars['String']['output'];
  skillMatrixId: Scalars['String']['output'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting marks */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Skill Group Schema */
export type SkillGroupType = {
  __typename?: 'SkillGroupType';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  skillIds: Array<Scalars['String']['output']>;
  skills?: Maybe<Array<SkillType>>;
};

export type SkillGroupsInput = {
  set: Array<SkillGroupInput>;
};

export type SkillMasteryConfig = {
  __typename?: 'SkillMasteryConfig';
  expectedScore: Scalars['Float']['output'];
  skillId: Scalars['String']['output'];
};

export type SkillMasteryConfigInput = {
  expectedScore: Scalars['Float']['input'];
  skillId: Scalars['String']['input'];
};

export type SkillMatricesFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skillGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<PublicSkillMatrixStatusEnum>>;
};

export type SkillMatrixConfig = {
  __typename?: 'SkillMatrixConfig';
  skillMasteryConfigs: Array<SkillMasteryConfig>;
  skillMatrixId: Scalars['String']['output'];
};

export type SkillMatrixConfigInput = {
  skillMasteryConfigs: Array<SkillMasteryConfigInput>;
  skillMatrixId: Scalars['String']['input'];
};

export type SkillMatrixConnection = {
  __typename?: 'SkillMatrixConnection';
  edges?: Maybe<Array<SkillMatrixEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

export type SkillMatrixEdge = {
  __typename?: 'SkillMatrixEdge';
  node: SkillMatrixType;
};

export enum SkillMatrixExportFieldEnum {
  Level1 = 'LEVEL1',
  Level2 = 'LEVEL2',
  Level3 = 'LEVEL3',
  Level4 = 'LEVEL4',
  Level5 = 'LEVEL5',
  SkillDescriptioin = 'SKILL_DESCRIPTIOIN',
  SkillGroupName = 'SKILL_GROUP_NAME',
  SkillName = 'SKILL_NAME'
}

export type SkillMatrixExportInputPropsInput = {
  filters: SkillMatrixFilters;
  requiredFields: SkillMatrixExportRequiredFieldsInput;
};

export type SkillMatrixExportInputPropsType = {
  __typename?: 'SkillMatrixExportInputPropsType';
  filters: SkillMatrixFiltersType;
  requiredFields: SkillMatrixExportRequiredFieldsType;
};

export type SkillMatrixExportMetadataType = {
  __typename?: 'SkillMatrixExportMetadataType';
  success: Scalars['Boolean']['output'];
};

export type SkillMatrixExportRequiredFieldsInput = {
  fields: Array<SkillMatrixExportFieldEnum>;
};

export type SkillMatrixExportRequiredFieldsType = {
  __typename?: 'SkillMatrixExportRequiredFieldsType';
  fields: Array<SkillMatrixExportFieldEnum>;
};

export type SkillMatrixFilters = {
  id: Scalars['String']['input'];
};

export type SkillMatrixFiltersType = {
  __typename?: 'SkillMatrixFiltersType';
  id: Scalars['String']['output'];
};

export type SkillMatrixImportMetadataType = {
  __typename?: 'SkillMatrixImportMetadataType';
  createdSkillsCount: Scalars['Float']['output'];
};

/** Skill Matrix Schema */
export type SkillMatrixType = {
  __typename?: 'SkillMatrixType';
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  iconSettings: IconSettings;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroups: Array<SkillGroupType>;
  skillGroupsCount: Scalars['Float']['output'];
  skillsCount: Scalars['Float']['output'];
  status: PublicSkillMatrixStatusEnum;
  systemGenerated: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum SkillOperationEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

/** Skill Payload */
export type SkillPayload = {
  __typename?: 'SkillPayload';
  skill?: Maybe<SkillType>;
};

/** SkillPeerFeedbackReview Schema */
export type SkillPeerFeedbackReviewType = {
  __typename?: 'SkillPeerFeedbackReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** reviewerType */
  reviewType: Scalars['String']['output'];
  /** reviewer */
  reviewer?: Maybe<UserType>;
  /** reviewerId who have given review */
  reviewerId: Scalars['String']['output'];
  /** score on skill */
  score: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroupId?: Maybe<Scalars['String']['output']>;
  skillId: Scalars['String']['output'];
  skillMatrixId?: Maybe<Scalars['String']['output']>;
  subType: SkillTypeEnum;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting review */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type SkillResultType = {
  __typename?: 'SkillResultType';
  records: Array<SkillStubType>;
};

export enum SkillReviewStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS'
}

/** SkillReview Schema */
export type SkillReviewType = {
  __typename?: 'SkillReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** feedback from reviewer for the skillReview */
  feedback?: Maybe<InstructionType>;
  id: Scalars['String']['output'];
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  rationale?: Maybe<Scalars['String']['output']>;
  /** review cycle instance */
  reviewCycleId: Scalars['String']['output'];
  /** reviewerType */
  reviewType: Scalars['String']['output'];
  /** resolved reviewer for the SkillReview */
  reviewer?: Maybe<UserProfile>;
  /** user who reviewed the assignment */
  reviewerId: Scalars['String']['output'];
  /** overall score on skill */
  score: Scalars['Float']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroupId?: Maybe<Scalars['String']['output']>;
  skillId: Scalars['String']['output'];
  skillMatrixId?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<SkillTypeEnum>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting marks */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Skill Rubric Config Type */
export type SkillRubricConfigType = {
  __typename?: 'SkillRubricConfigType';
  /** Label name of rubric */
  label: Scalars['String']['output'];
  /** Value fir label */
  value: Scalars['Float']['output'];
};

export enum SkillRubricLabelEnum {
  Advanced = 'Advanced',
  Competent = 'Competent',
  Expert = 'Expert',
  Intermediate = 'Intermediate',
  Novice = 'Novice'
}

export type SkillRubricsResultType = {
  __typename?: 'SkillRubricsResultType';
  records: Array<RubricStubType>;
};

/** Weightage distribution for score calculation of a Skill from different components */
export type SkillScoreWeightConfigType = {
  __typename?: 'SkillScoreWeightConfigType';
  /** from peerFeedback */
  adhocReviewWeight?: Maybe<Scalars['Float']['output']>;
  /** from SkillAssessmentReviewType */
  assessmentScoreWeight?: Maybe<Scalars['Float']['output']>;
  /** avg of scores of immediate child-skills */
  childScoreWeight?: Maybe<Scalars['Float']['output']>;
  managerReviewWeight?: Maybe<Scalars['Float']['output']>;
  peerReviewWeight?: Maybe<Scalars['Float']['output']>;
  selfReviewWeight?: Maybe<Scalars['Float']['output']>;
  upwardReviewWeight?: Maybe<Scalars['Float']['output']>;
};

export type SkillScoresInput = {
  feedback?: InputMaybe<InstructionInput>;
  score: Scalars['Float']['input'];
  skillId: Scalars['String']['input'];
  skillType?: InputMaybe<SkillTypeEnum>;
};

export type SkillStubType = {
  __typename?: 'SkillStubType';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Skill Schema */
export type SkillType = {
  __typename?: 'SkillType';
  attachedToEntitiesCount?: Maybe<GetEntitiesAttachedToCountPayload>;
  /** Denotes the Category of Skill */
  category?: Maybe<SkillCategoryType>;
  childSkills?: Maybe<Array<SkillType>>;
  /** stores list of children (i.e. microskills) */
  childrenIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Skill description */
  description?: Maybe<Scalars['String']['output']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  isMicroskill: Scalars['Boolean']['output'];
  /** MaxScore Possible to achieve for this skill */
  maxScore?: Maybe<Scalars['Float']['output']>;
  /** Skill Name or Title */
  name: Scalars['String']['output'];
  orgRoleCount: Scalars['Float']['output'];
  /** denotes sub-Skill belongs to which Skill */
  parentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Denotes the rules for scoring this skill, empty array for microskill */
  rubrics: Array<Rubric>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** Denotes whether the skill is system generated or not */
  systemGenerated?: Maybe<Scalars['Boolean']['output']>;
  type: SkillTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  /** True for all user generated skills and true for system generated skills that have been used. False for system generated skills that have not been used */
  visibleInListing?: Maybe<Scalars['Boolean']['output']>;
};

export enum SkillTypeEnum {
  Ability = 'ABILITY',
  Behavior = 'BEHAVIOR'
}

export enum SkillsExportFieldEnum {
  Descriptioin = 'DESCRIPTIOIN',
  Level1 = 'LEVEL1',
  Level2 = 'LEVEL2',
  Level3 = 'LEVEL3',
  Level4 = 'LEVEL4',
  Level5 = 'LEVEL5',
  Name = 'NAME',
  SkillId = 'SKILL_ID'
}

export type SkillsExportInputPropsInput = {
  filters: SkillsFilters;
  requiredFields: SkillsExportRequiredFieldsInput;
};

export type SkillsExportInputPropsType = {
  __typename?: 'SkillsExportInputPropsType';
  filters: SkillsFiltersType;
  requiredFields: SkillsExportRequiredFieldsType;
};

export type SkillsExportMetadataType = {
  __typename?: 'SkillsExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type SkillsExportRequiredFieldsInput = {
  fields: Array<SkillsExportFieldEnum>;
};

export type SkillsExportRequiredFieldsType = {
  __typename?: 'SkillsExportRequiredFieldsType';
  fields: Array<SkillsExportFieldEnum>;
};

export type SkillsFilters = {
  /** Skill Categories */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** visibleInListing filter is overridden if includeMicroSkills is true */
  includeMicroSkills?: InputMaybe<Scalars['Boolean']['input']>;
  isParent?: InputMaybe<Scalars['Boolean']['input']>;
  /** name of the Skill */
  name?: InputMaybe<Scalars['String']['input']>;
  notInIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** name of the Skill */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** Returns skills present in the given skillMatrixIds */
  skillMatrixIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<SkillTypeEnum>>;
  visibleInListing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SkillsFiltersType = {
  __typename?: 'SkillsFiltersType';
  /** Skill Categories */
  categories?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<DateFilterType>;
  createdByIds?: Maybe<Array<Scalars['String']['output']>>;
  generatedBy?: Maybe<GeneratedByEnum>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
  isParent?: Maybe<Scalars['Boolean']['output']>;
  /** name of the Skill */
  name?: Maybe<Scalars['String']['output']>;
  orgRoleIds?: Maybe<Array<Scalars['String']['output']>>;
  parentIds?: Maybe<Array<Scalars['String']['output']>>;
  /** name of the Skill */
  searchText?: Maybe<Scalars['String']['output']>;
  /** Returns skills present in the given skillMatrixIds */
  skillMatrixIds?: Maybe<Array<Scalars['String']['output']>>;
  types?: Maybe<Array<SkillTypeEnum>>;
  /** True for all user generated skills. True for all system generated skills that have been imported. False for system generated skills that have not been imported */
  visibleInListing?: Maybe<Scalars['Boolean']['output']>;
};

export type SkillsImportMetadataType = {
  __typename?: 'SkillsImportMetadataType';
  createdSkillsCount: Scalars['Float']['output'];
};

export type SortFacetType = {
  __typename?: 'SortFacetType';
  defaultValues?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isOptionLocalisationNeeded: Scalars['Boolean']['output'];
  model: FacetModelEnum;
  options: Array<SelectFacetOption>;
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']['output']>;
  type: FacetTypeEnum;
};

/** Defines sort order of a particular field */
export type SortInput = {
  order: OrderEnum;
  property: Scalars['String']['input'];
};

/** Defines array of sort operations */
export type SortSetInput = {
  set: Array<SortInput>;
};

export type SortType = {
  field?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderEnum>;
};

export type SortTypeSchema = {
  __typename?: 'SortTypeSchema';
  /** Sort Field */
  field?: Maybe<Scalars['String']['output']>;
  /** Sort Order */
  order?: Maybe<OrderEnum>;
};

/** This is the input for starting the quiz */
export type StartQuizInput = {
  /** Whether quiz being retaken is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether quiz is consumed via admin using preview feature */
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String']['input'];
  /** quizId to be started */
  quizId: Scalars['String']['input'];
};

/** Quiz start response */
export type StartQuizPayload = {
  __typename?: 'StartQuizPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  questionPerformances?: Maybe<Array<QuestionPerformanceType>>;
  quizPerformance?: Maybe<QuizPerformanceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** This is the input for starting the quiz question timer */
export type StartQuizQuestionTimerInput = {
  /** Whether quiz being retaken is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether quiz is consumed via admin using preview feature */
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String']['input'];
  /** QuestionId for which timer is being started */
  questionId: Scalars['String']['input'];
  /** quizId the question is part of */
  quizId: Scalars['String']['input'];
};

/** Quiz question start response */
export type StartQuizQuestionTimerPayload = {
  __typename?: 'StartQuizQuestionTimerPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  questionPerformance?: Maybe<QuestionPerformanceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum StatusApplicableOnEnum {
  Task = 'TASK'
}

export enum StatusCategoryEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export enum StatusConfigTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT'
}

/** Edge */
export type StatusEdge = {
  __typename?: 'StatusEdge';
  node: StatusType;
};

export type StatusType = {
  __typename?: 'StatusType';
  applicableOn: StatusApplicableOnEnum;
  category: StatusCategoryEnum;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** id of the default status from which current status is created */
  defaultStatusId?: Maybe<Scalars['String']['output']>;
  /** Status on which EntityId to be applicable  */
  entityId?: Maybe<Scalars['String']['output']>;
  /** Status on which EntityId to be applicable  */
  entityType?: Maybe<CustomStatusEntityTypeEnum>;
  icon?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Stores the order of status */
  order?: Maybe<Scalars['Float']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  systemGenerated: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type StatusesFilters = {
  applicableOn?: InputMaybe<Array<StatusApplicableOnEnum>>;
  categories?: InputMaybe<Array<StatusCategoryEnum>>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** exclude entity level custom statuses from result */
  excludeEntityStatuses?: InputMaybe<Scalars['Boolean']['input']>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  /** exact name match */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  statusIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StopItemsSharingInput = {
  items: Array<ShareItemInput>;
  sharedToWorkspaceIds: Array<Scalars['String']['input']>;
};

export type StrategyMethod = {
  __typename?: 'StrategyMethod';
  allowUsername?: Maybe<Scalars['Boolean']['output']>;
  authCodeValidatorUrl?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  jwtConfigs?: Maybe<Array<JwtDataType>>;
  meta?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  samlConfigs?: Maybe<Array<SamlDataType>>;
  scope?: Maybe<Scalars['String']['output']>;
  signInLabel: Scalars['String']['output'];
  signUpLabel?: Maybe<Scalars['String']['output']>;
  type: SupportedStrategyMethodTypeEnum;
  webStrategyUrl: Scalars['String']['output'];
};

export type StrategyMethodType = {
  __typename?: 'StrategyMethodType';
  allowUsername?: Maybe<Scalars['Boolean']['output']>;
  authCodeValidatorUrl?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  jwtConfigs?: Maybe<Array<JwtDataTypeEntity>>;
  meta?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  samlConfigs?: Maybe<Array<SamlDataTypeEntity>>;
  scope?: Maybe<Scalars['String']['output']>;
  signInLabel: Scalars['String']['output'];
  signUpLabel?: Maybe<Scalars['String']['output']>;
  type: SupportedStrategyMethodTypeEnum;
  webStrategyUrl: Scalars['String']['output'];
};

/** Defines view sub filters for a particular field */
export type SubFiltersInput = {
  operator: FilterOperatorEnum;
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Defines filtering over entity */
export type SubFiltersType = {
  __typename?: 'SubFiltersType';
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** Field Id */
  property: Scalars['String']['output'];
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value: Scalars['String']['output'];
};

export type SubTaskInput = {
  instruction?: InputMaybe<TaskInstructionInput>;
  name: Scalars['String']['input'];
};

/** SubTask Performance Type */
export type SubTaskPerformanceType = {
  __typename?: 'SubTaskPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Sub-Task Type */
export type SubTaskType = {
  __typename?: 'SubTaskType';
  id: Scalars['String']['output'];
  /** Stores the instruction of the sub task */
  instruction?: Maybe<TaskInstruction>;
  /** Name of the sub task */
  name: Scalars['String']['output'];
  performance?: Maybe<SubTaskPerformanceType>;
  type: ContentTypeEnum;
};


/** Sub-Task Type */
export type SubTaskTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubmissionData = {
  __typename?: 'SubmissionData';
  exerciseId: Scalars['String']['output'];
  isReviewComplete: Scalars['Boolean']['output'];
  submissionId: Scalars['String']['output'];
  user?: Maybe<UserType>;
  userId: Scalars['String']['output'];
};

/** SubmissionFeedbackStickers Response */
export type SubmissionFeedbackStickers = {
  __typename?: 'SubmissionFeedbackStickers';
  /** Name of stickers */
  name: Scalars['String']['output'];
  /** url of stickers */
  url: Scalars['String']['output'];
};

/** Data regarding the reviewers */
export type SubmissionReviewInfo = {
  __typename?: 'SubmissionReviewInfo';
  defaultReviewerId?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  isEmpty?: Maybe<Scalars['Boolean']['output']>;
  reviewerIds?: Maybe<Array<Scalars['String']['output']>>;
  tagId?: Maybe<Scalars['String']['output']>;
  type: SubmissionReviewInfoTypeEnum;
};

export type SubmissionReviewInfoFilter = {
  defaultReviewerId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  reviewerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tagId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SubmissionReviewInfoTypeEnum>;
};

export enum SubmissionReviewInfoTypeEnum {
  Group = 'GROUP',
  Manager = 'MANAGER',
  Specific = 'SPECIFIC',
  Tag = 'TAG'
}

/** Input regarding whom the review was done. */
export type SubmissionRevieweeInfo = {
  /** the person who was reviewed in the submission */
  revieweeId: Scalars['String']['input'];
  /** Submission Id of the person who was reviewed */
  submissionId: Scalars['String']['input'];
};

/** Submission settings of task! */
export type SubmissionSettingsType = {
  __typename?: 'SubmissionSettingsType';
  allowedResponseTypes: Array<ResourceResponseTypeEnum>;
};

/** User Response to the resource */
export type SubmissionType = {
  __typename?: 'SubmissionType';
  mediaResponses?: Maybe<Array<EntityMediaType>>;
  responseType: ResourceResponseTypeEnum;
  retakeCount?: Maybe<Scalars['Float']['output']>;
  /** If response type is text, HTML string */
  textResponse?: Maybe<Scalars['String']['output']>;
  /** If response type is whiteboard, Stringified JSON which will be managed by frontend */
  whiteboardResponse?: Maybe<Scalars['String']['output']>;
};

export enum SubmissionTypeEnum {
  Media = 'MEDIA',
  Text = 'TEXT'
}

/** input to fetch which submissions are to be reviewed by the peer. */
export type SubmissionsToReviewInput = {
  entityId: Scalars['String']['input'];
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
};

export type SubmissionsToReviewPayload = {
  __typename?: 'SubmissionsToReviewPayload';
  submissions: Array<SubmissionData>;
};

export type SubmissionsToReviewWidgetType = {
  __typename?: 'SubmissionsToReviewWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** submissionsToReview */
  submissionsToReview: ContentPerformanceConnection;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type SubmissionsToReviewWidgetTypeSubmissionsToReviewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type SubmitJourneyStepFormInput = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SubmitJourneyStepFormPayload = {
  __typename?: 'SubmitJourneyStepFormPayload';
  questionPerformances?: Maybe<Array<JourneyPerformanceType>>;
  success: Scalars['Boolean']['output'];
  traineeJourneyStepPerformance?: Maybe<JourneyPerformanceType>;
  trainerJourneyStepPerformance?: Maybe<JourneyPerformanceType>;
};

/** This is the input for submitting the entire quiz */
export type SubmitQuizInput = {
  /** Whether quiz being submitted is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether quiz is consumed via admin using preview feature */
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String']['input'];
  /** quizId for which submission/revaluation taking place */
  quizId: Scalars['String']['input'];
};

/** Quiz Submission Response */
export type SubmitQuizPayload = {
  __typename?: 'SubmitQuizPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<QuizPerformanceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** This is the input for saving or submitting a quiz question response */
export type SubmitQuizQuestionInput = {
  /** Pass true when need to submit response. Pass false when need to save response */
  actualSubmit: Scalars['Boolean']['input'];
  /** Used when Response type is Fill in the blank */
  ftbResponse?: InputMaybe<Array<FtbResponseInput>>;
  /** Used when Response type is image hotspot */
  imageHotspotResponse?: InputMaybe<Array<ImageHotspotResponseInput>>;
  /** Whether quiz question being submitted is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether quiz is consumed via admin using preview feature */
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  /** User when Response type is match the following */
  mtfResponse?: InputMaybe<Array<MtfResponseInput>>;
  /** Used when Response type is SingleSelect, MultiSelect, TrueFalse */
  optionResponse?: InputMaybe<Array<OptionResponseInput>>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String']['input'];
  /** Response QuestionId */
  questionId: Scalars['String']['input'];
  /** QuizId of which response needs to be saved */
  quizId: Scalars['String']['input'];
  responseType: QuestionTypeEnum;
  /** Time spent on question */
  timeSpent: Scalars['Float']['input'];
};

/** Quiz Submission Response */
export type SubmitQuizQuestionPayload = {
  __typename?: 'SubmitQuizQuestionPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<QuestionPerformanceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** deprecated, use submitReviewCycleResponse API instead */
export type SubmitReviewCycleResponseInput = {
  /** DEPRECATED: responsibilities on learner are fetched internally */
  orgRoleId?: InputMaybe<Scalars['String']['input']>;
  questionResponses: Array<QuestionReviewUserResponseInput>;
  reviewCycleId: Scalars['String']['input'];
  reviewTypes: Array<Scalars['String']['input']>;
  skillScores: Array<SkillScoresInput>;
  status?: InputMaybe<SkillReviewStatusEnum>;
  userId: Scalars['String']['input'];
};

export type SubmitReviewCycleResponsePayload = {
  __typename?: 'SubmitReviewCycleResponsePayload';
  /** Error code */
  errorCode?: Maybe<PerformanceInteractionErrorCodeEnum>;
  questionReviews?: Maybe<Array<QuestionReviewType>>;
  skillReviews?: Maybe<Array<SkillReviewType>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Input to submit exercise submission review */
export type SubmitReviewsInput = {
  /** exercise for which submissions were reviewed */
  entityId: Scalars['String']['input'];
  /** course for which submissions were reviewed */
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  /** reviewee info for which person, which submissionId the review was done */
  submissionRevieweeInfos: Array<SubmissionRevieweeInfo>;
};

export type SubmitUserSurveyResponseInput = {
  attemptedQuestionsCount?: InputMaybe<Scalars['Int']['input']>;
  cycleId?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  parentEntityId: Scalars['String']['input'];
  questionResponses?: InputMaybe<Array<QuestionResponseInput>>;
  status?: InputMaybe<UserSurveyResponseStatusEnum>;
  surveyPublicId?: InputMaybe<Scalars['String']['input']>;
  surveyQuestionsCount: Scalars['Int']['input'];
  themesScore?: InputMaybe<Array<ThemeScoreInput>>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userSurveyResponseId?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitUserSurveyResponsePayload = {
  __typename?: 'SubmitUserSurveyResponsePayload';
  upsertedUserSurveyResponses?: Maybe<Array<UserSurveyResponseType>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  actionItems: ActionItemSubscriptionPayload;
  agendaItems: AgendaItemSubscriptionPayload;
  assignments: AssignmentSubscriptionPayload;
  channelPosts: ChannelPostsSubscriptionPayload;
  conferences: ConferenceSubscriptionPayload;
  database: DatabaseDataSubscriptionType;
  events: EventSubscriptionPayload;
  generativeTask: GenerativeTaskSubscriptionPayload;
  job: JobType;
  jobByCreator: JobByCreatorSubscriptionPayload;
  meetingInstances: MeetingInstanceSubscriptionPayload;
  meetings: MeetingSubscriptionPayload;
  syncPerformance: ContentPerformanceEntity;
  userBellNotification: UserBellNotificationsSubscriptionPayload;
};


export type SubscriptionActionItemsArgs = {
  data: ActionItemSubscriptionInput;
};


export type SubscriptionAgendaItemsArgs = {
  data: AgendaItemSubscriptionInput;
};


export type SubscriptionAssignmentsArgs = {
  data: AssignmentSubscriptionInput;
};


export type SubscriptionChannelPostsArgs = {
  data: ChannelPostsSubscriptionInput;
};


export type SubscriptionConferencesArgs = {
  data: ConferenceSubscriptionInput;
};


export type SubscriptionDatabaseArgs = {
  dbId: Scalars['String']['input'];
};


export type SubscriptionEventsArgs = {
  data: EventSubscriptionInput;
};


export type SubscriptionGenerativeTaskArgs = {
  data: GenerativeTaskSubscriptionInput;
};


export type SubscriptionJobArgs = {
  data: JobSubscriptionInput;
};


export type SubscriptionJobByCreatorArgs = {
  data: JobByCreatorSubscriptionInput;
};


export type SubscriptionMeetingInstancesArgs = {
  data: MeetingInstanceSubscriptionInput;
};


export type SubscriptionMeetingsArgs = {
  data: MeetingSubscriptionInput;
};


export type SubscriptionSyncPerformanceArgs = {
  data: UserEntityPerformanceSubscriptionInput;
};


export type SubscriptionUserBellNotificationArgs = {
  data: UserBellNotificationSubscriptionInput;
};

export type SummaryAgendaItemsInput = {
  __typename?: 'SummaryAgendaItemsInput';
  actionItems: Array<AgendaActionItemsInputPropsType>;
  instructionText?: Maybe<Scalars['String']['output']>;
  okrs: Array<AgendaOkRsInputPropsType>;
  title: Scalars['String']['output'];
};

export enum SupportedFlowsEnum {
  AcademySetup = 'ACADEMY_SETUP'
}

export enum SupportedStrategyMethodTypeEnum {
  Credential = 'CREDENTIAL',
  Oauth = 'OAUTH',
  Sso = 'SSO'
}

/** Survey Type */
export type SurveyContentType = {
  __typename?: 'SurveyContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  /** Cover offset */
  coverOffset?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  databaseMetadata?: Maybe<DatabaseMetadataType>;
  databaseViews?: Maybe<Array<DatabaseViewType>>;
  /** Database Metadata Id */
  dbMetadataId: Scalars['String']['output'];
  /** Database View Id */
  dbViewIds: Array<DbViewId>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  /** Description of the survey */
  description?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<DraftSurveyType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** Duration in seconds */
  duration: Scalars['Float']['output'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  /** Parent Content Id */
  parentContentId?: Maybe<Scalars['String']['output']>;
  parentContentProfile?: Maybe<ContentProfile>;
  /** Parent Content Type */
  parentContentType?: Maybe<ResourceTypeEnum>;
  /** Parent Survey Id */
  parentSurveyId: Scalars['String']['output'];
  performance?: Maybe<SurveyPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']['output']>>;
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Survey Type */
export type SurveyContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Survey Type */
export type SurveyContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Survey Type */
export type SurveyContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Survey Type */
export type SurveyContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Survey Type */
export type SurveyContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Survey Type */
export type SurveyContentTypeParentContentProfileArgs = {
  parentContentId?: InputMaybe<Scalars['String']['input']>;
};


/** Survey Type */
export type SurveyContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Survey Type */
export type SurveyContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Survey Type */
export type SurveyContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Survey Type */
export type SurveyContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type SurveyExportMetadataType = {
  __typename?: 'SurveyExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type SurveyExportPropsInput = {
  surveyFilters: SurveyResponsesFilters;
};

/** Survey Performance Type */
export type SurveyPerformanceType = {
  __typename?: 'SurveyPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  databaseData?: Maybe<DatabaseDataType>;
  /** document Id containing responses */
  documentId?: Maybe<Scalars['String']['output']>;
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type SurveyQuestionConfigInput = {
  action: UserSurveyQuestionActionEnum;
  blockType?: InputMaybe<UserSurveyQuestionBlockTypeEnum>;
  commentTitle?: InputMaybe<Scalars['String']['input']>;
  conditionConfigs?: InputMaybe<Array<UserSurveyConditionConfigInput>>;
  conditional?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  pageId?: InputMaybe<Scalars['String']['input']>;
  questionId?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<UserSurveyQuestionSettingsInputConfig>;
};

export type SurveyResponseInput = {
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  enpsResponse?: InputMaybe<Scalars['Float']['input']>;
  likertResponse?: InputMaybe<Scalars['Float']['input']>;
  optionResponse?: InputMaybe<Array<OptionResponseInput>>;
  responseType: ResourceResponseTypeEnum;
  textResponse?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyResponseType = {
  __typename?: 'SurveyResponseType';
  attachments?: Maybe<Array<EntityMediaType>>;
  enpsResponse?: Maybe<Scalars['Float']['output']>;
  likertResponse?: Maybe<Scalars['Float']['output']>;
  optionResponse?: Maybe<Array<OptionResponseType>>;
  responseType: ResourceResponseTypeEnum;
  textResponse?: Maybe<Scalars['String']['output']>;
};

export type SurveyResponsesFilters = {
  dataFilters: DatabaseDatasFilters;
  /** ID of Email Digest */
  emailDigestId?: InputMaybe<Scalars['String']['input']>;
  projections?: InputMaybe<Array<Scalars['String']['input']>>;
  surveyId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type SyncAttributesInput = {
  name: Scalars['Boolean']['input'];
};

export type SyncAttributesType = {
  __typename?: 'SyncAttributesType';
  name: Scalars['Boolean']['output'];
};

export type SyncAttributesTypeEntity = {
  __typename?: 'SyncAttributesTypeEntity';
  name: Scalars['Boolean']['output'];
};

/** sync Config to track any kind of sync tasks in entities  */
export type SyncConfig = {
  __typename?: 'SyncConfig';
  /** Last jobSchedule id which was triggered to sync */
  lastJobScheduleId?: Maybe<Scalars['String']['output']>;
  /** timestamp for the latest sync */
  lastSyncedAt: Scalars['String']['output'];
  /** user who triggered the sync */
  lastSyncedById: Scalars['String']['output'];
  /** current status of sync */
  syncStatus: SyncStatusEnum;
};

export type SyncConfigInput = {
  /** Last jobSchedule id which was triggered to sync */
  lastJobScheduleId?: InputMaybe<Scalars['String']['input']>;
  /** timestamp for the latest sync */
  lastSyncedAt: Scalars['String']['input'];
  /** user who triggered the sync */
  lastSyncedById: Scalars['String']['input'];
  /** current status of sync */
  syncStatus: SyncStatusEnum;
};

/** This is the input for resource properties */
export type SyncContentProgressTargetInput = {
  blockId?: InputMaybe<Scalars['String']['input']>;
  blockType?: InputMaybe<Scalars['String']['input']>;
  contentId: Scalars['String']['input'];
  contentType: ResourceTypeEnum;
  lessonId?: InputMaybe<Scalars['String']['input']>;
  lessonPageId?: InputMaybe<Scalars['String']['input']>;
};

/** input to sync individual users external performance */
export type SyncExternalPerformanceInput = {
  /** Unique Id to identify resource  */
  entityId: Scalars['String']['input'];
  /** userId for whom external performance needs to be synced */
  userId: Scalars['String']['input'];
};

/** response */
export type SyncExternalPerformancePayload = {
  __typename?: 'SyncExternalPerformancePayload';
  success: Scalars['Boolean']['output'];
};

/** This is the input for sync progress on content */
export type SyncProgressInput = {
  attributes?: InputMaybe<AttributesInput>;
  clientTimestamp: Scalars['String']['input'];
  command: CommandEnum;
  /** id of the content viewed by the user */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** journey trainee batch Id to sync progress of participants */
  journeyTraineeBatchId?: InputMaybe<Scalars['String']['input']>;
  /** journey trainee session Id to sync progress of participants */
  journeyTraineeSessionId?: InputMaybe<Scalars['String']['input']>;
  /** parentEntityId from which the content viewed by the user */
  parentEntityId?: InputMaybe<Scalars['String']['input']>;
  /** Additional resume information for contents like course */
  resume?: InputMaybe<Scalars['JSONObject']['input']>;
  target?: InputMaybe<SyncContentProgressTargetInput>;
};

/** Make Exercise Submission Response */
export type SyncProgressPayload = {
  __typename?: 'SyncProgressPayload';
  errorCode?: Maybe<PerformanceErrorCode>;
  performances?: Maybe<Array<CoursePerformanceType>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum SyncStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type SyncTasksInput = {
  /** entity for which we need to sync all the action items */
  entityId: Scalars['String']['input'];
  /** entity type which we need to sync all the action items */
  entityType: TaskCreationEntityTypeEnum;
};

/** Sync Action item payload */
export type SyncTasksPayload = {
  __typename?: 'SyncTasksPayload';
  jobScheduleId: Scalars['String']['output'];
};

export type SyncUsersInput = {
  integrationProviderId: Scalars['String']['input'];
};

export type SyncUsersPayload = {
  __typename?: 'SyncUsersPayload';
  jobScheduleId: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum TagAvailableOnEnum {
  Consumption = 'CONSUMPTION',
  Content = 'CONTENT',
  Event = 'EVENT',
  Okr = 'OKR',
  Task = 'TASK',
  User = 'USER'
}

export enum TagCategoryEnum {
  Field = 'FIELD',
  Label = 'LABEL'
}

export type TagControlConfigInput = {
  controls: Array<TagControlTypeInput>;
};

/** This will contain the controlling entity info and rules for control tags */
export type TagControlConfigType = {
  __typename?: 'TagControlConfigType';
  controls: Array<TagControlType>;
};

export type TagControlEntityConfig = {
  __typename?: 'TagControlEntityConfig';
  type: TagControlSourcePropertyTypeEnum;
  value: Scalars['String']['output'];
};

export type TagControlEntityConfigInput = {
  type: TagControlSourcePropertyTypeEnum;
  value: Scalars['String']['input'];
};

export enum TagControlOperatorEnum {
  In = 'IN'
}

export type TagControlRuleConstraint = {
  __typename?: 'TagControlRuleConstraint';
  operator: TagControlOperatorEnum;
  /** values for filtering or filtered values */
  values: Array<Scalars['String']['output']>;
};

export type TagControlRuleConstraintInput = {
  operator: TagControlOperatorEnum;
  values: Array<Scalars['String']['input']>;
};

/** specific rule for a controlling tag */
export type TagControlRuleType = {
  __typename?: 'TagControlRuleType';
  /** contains the controlling or filtering condition values. */
  source: TagControlRuleConstraint;
  /** contains the values after the controlling tag is applied, subset of actual values. */
  target: TagControlRuleConstraint;
};

export type TagControlRuleTypeInput = {
  source: TagControlRuleConstraintInput;
  target: TagControlRuleConstraintInput;
};

export enum TagControlSourcePropertyTypeEnum {
  Tag = 'TAG'
}

export type TagControlType = {
  __typename?: 'TagControlType';
  /** This will contain the controlling entity info, type will be tag, user etc, and value will be the corresponding id */
  controllingEntity: TagControlEntityConfig;
  /** This will contain the rules for control tags */
  rules: Array<TagControlRuleType>;
};

export type TagControlTypeInput = {
  controllingEntity: TagControlEntityConfigInput;
  rules: Array<TagControlRuleTypeInput>;
};

export type TagDimesionKeyMapping = {
  __typename?: 'TagDimesionKeyMapping';
  availableOn: TagAvailableOnEnum;
  dimensionKey: Scalars['String']['output'];
};

/** Edge */
export type TagEdge = {
  __typename?: 'TagEdge';
  node: TagUnion;
};

export enum TagExportFieldEnum {
  AvailableOn = 'AVAILABLE_ON',
  Description = 'DESCRIPTION',
  IncludeEndDate = 'INCLUDE_END_DATE',
  IncludeEndTime = 'INCLUDE_END_TIME',
  Name = 'NAME',
  NumberType = 'NUMBER_TYPE',
  Options = 'OPTIONS',
  RequiredTag = 'REQUIRED_TAG',
  TagId = 'TAG_ID',
  TextType = 'TEXT_TYPE',
  Type = 'TYPE',
  VisibleToLearners = 'VISIBLE_TO_LEARNERS'
}

export type TagExportInputProps = {
  filters: TagsFilters;
  requiredFields: TagExportRequiredFieldsInput;
};

export type TagExportInputPropsType = {
  __typename?: 'TagExportInputPropsType';
  filters: TagsFiltersType;
  requiredFields: TagExportRequiredFieldsType;
};

export type TagExportMetadataType = {
  __typename?: 'TagExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type TagExportRequiredFieldsInput = {
  fields: Array<TagExportFieldEnum>;
};

export type TagExportRequiredFieldsType = {
  __typename?: 'TagExportRequiredFieldsType';
  fields: Array<TagExportFieldEnum>;
};

export type TagFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['String']['input']>;
};

/** This returns paginated TagOptions */
export type TagOptionConnection = {
  __typename?: 'TagOptionConnection';
  edges?: Maybe<Array<TagOptionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type TagOptionEdge = {
  __typename?: 'TagOptionEdge';
  node: SelectTagOptionType;
};

export type TagOptionsOutputOrderInput = {
  order: Array<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
};

export type TagPaginatedOptionsFilters = {
  parentId?: InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
  valueIds?: InputMaybe<Array<Scalars['String']['input']>>;
  valueText?: InputMaybe<Scalars['String']['input']>;
};

export type TagSettingsInput = {
  availableInLibrary: Scalars['Boolean']['input'];
  controlConfig?: InputMaybe<TagControlConfigInput>;
  disableAttaching?: InputMaybe<Scalars['Boolean']['input']>;
  disableEditing?: InputMaybe<Scalars['Boolean']['input']>;
  disableFiltering?: InputMaybe<Scalars['Boolean']['input']>;
  disableGrouping?: InputMaybe<Scalars['Boolean']['input']>;
  disableResetting?: InputMaybe<Scalars['Boolean']['input']>;
  enableCrossWorkspaceSharing?: InputMaybe<Scalars['Boolean']['input']>;
  iconSettings: IconSettingsInput;
  requiredTag: Scalars['Boolean']['input'];
};

export type TagSettingsType = {
  __typename?: 'TagSettingsType';
  availableInLibrary: Scalars['Boolean']['output'];
  controlConfig?: Maybe<TagControlConfigType>;
  /** If true, this tag will not be attachable from UI, synced tags. external tags will be attached by services internally only. */
  disableAttaching?: Maybe<Scalars['Boolean']['output']>;
  /** If true, options for any select type of tag will be disabled */
  disableEditing?: Maybe<Scalars['Boolean']['output']>;
  /** If true, options for any select type of tag will be disabled */
  disableFiltering?: Maybe<Scalars['Boolean']['output']>;
  /** If true, this tag will not be shown in groupby from UI, synced tags. external tags will be attached by services internally only. */
  disableGrouping?: Maybe<Scalars['Boolean']['output']>;
  /** If true, this tag cannot be set to any empty value or be unassigned etc. */
  disableResetting?: Maybe<Scalars['Boolean']['output']>;
  enableCrossWorkspaceSharing?: Maybe<Scalars['Boolean']['output']>;
  iconSettings: IconSettings;
  requiredTag: Scalars['Boolean']['output'];
};

export enum TagStatusEnum {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED'
}

export enum TagSubTypeEnum {
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT'
}

export enum TagTypeEnum {
  Date = 'DATE',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  SingleSelect = 'SINGLE_SELECT',
  Text = 'TEXT',
  User = 'USER'
}

/** union of all tags */
export type TagUnion = DateTagType | MultiSelectTagType | NumberTagType | SingleSelectTagType | TextTagType | UserTagType;

export type TaggedEntitiesExportInputPropsInput = {
  requiredEntities: Array<TagAvailableOnEnum>;
  tagId: Scalars['String']['input'];
};

export type TaggedEntitiesExportInputPropsType = {
  __typename?: 'TaggedEntitiesExportInputPropsType';
  requiredEntities: Array<TagAvailableOnEnum>;
  tagId: Scalars['String']['output'];
};

export type TaggedEntitiesExportMetadataType = {
  __typename?: 'TaggedEntitiesExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type TagsFilters = {
  availableInLibrary?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  availableOn?: InputMaybe<Array<TagAvailableOnEnum>>;
  category?: InputMaybe<Array<TagCategoryEnum>>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, this tag will not be attachable from UI, synced tags. external tags will be attached by services internally only. */
  disableAttaching?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  disableEditing?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  disableFiltering?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  disableGrouping?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  disableResetting?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  excludeReceivedTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** This field uses regex in name to fetch tags */
  name?: InputMaybe<Scalars['String']['input']>;
  notInTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  requiredTag?: InputMaybe<Scalars['Boolean']['input']>;
  sharedTagFilters?: InputMaybe<SharedEntityFilters>;
  source?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<TagStatusEnum>>;
  subTypes?: InputMaybe<Array<TagSubTypeEnum>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** This field is used to match exact names of the tag */
  tagNames?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<TagTypeEnum>>;
};

export type TagsFiltersType = {
  __typename?: 'TagsFiltersType';
  availableInLibrary?: Maybe<Array<Scalars['Boolean']['output']>>;
  availableOn?: Maybe<Array<TagAvailableOnEnum>>;
  category?: Maybe<Array<TagCategoryEnum>>;
  createdByIds?: Maybe<Array<Scalars['String']['output']>>;
  /** If true, this tag will not be attachable from UI, synced tags. external tags will be attached by services internally only. */
  disableAttaching?: Maybe<Array<Scalars['Boolean']['output']>>;
  disableOptionsEdit?: Maybe<Array<Scalars['Boolean']['output']>>;
  requiredTag?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Array<TagStatusEnum>>;
  tagIds?: Maybe<Array<Scalars['String']['output']>>;
  /** This field is used to match exact names of the tag */
  tagNames?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<Array<TagTypeEnum>>;
};

export type TargetChannelMapping = {
  sourceChannelId: Scalars['String']['input'];
  targetChannelId: Scalars['String']['input'];
};

export enum TaskAvailableOnEnum {
  AgendaItem = 'AGENDA_ITEM',
  KeyResult = 'KEY_RESULT',
  Meeting = 'MEETING',
  MeetingInstance = 'MEETING_INSTANCE',
  Okr = 'OKR',
  PeerFeedback = 'PEER_FEEDBACK'
}

/** All the configs related to task stored */
export type TaskConfigInputSchema = {
  statusConfig: TaskStatusConfigInputSchema;
};

/** All the configs related to task stored */
export type TaskConfigSchema = {
  __typename?: 'TaskConfigSchema';
  statusConfig: TaskStatusConfigSchema;
};

/** Task Type */
export type TaskContentType = {
  __typename?: 'TaskContentType';
  allowMinorVersionPublish: Scalars['Boolean']['output'];
  applicableRoles?: Maybe<Array<RoleType>>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  draft?: Maybe<DraftTaskContentType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean']['output'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean']['output'];
  /** duration in seconds */
  duration: Scalars['Float']['output'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  /** description of the task */
  instruction?: Maybe<TaskInstruction>;
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isReviewer: Scalars['Boolean']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  journeySteps: Array<JourneyStepType>;
  journeyStepsTraineeProgressStatus?: Maybe<PerformanceStatusEnum>;
  lastMajorVersionPublishedAt: Scalars['String']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  /** Name of the resource */
  name: Scalars['String']['output'];
  performance?: Maybe<TaskPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float']['output'];
  sections?: Maybe<Array<TaskSectionType>>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean']['output'];
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};


/** Task Type */
export type TaskContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};


/** Task Type */
export type TaskContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** Task Type */
export type TaskContentTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** Task Type */
export type TaskContentTypeJourneyStepsArgs = {
  journeyId: Scalars['String']['input'];
  phaseId: Scalars['String']['input'];
};


/** Task Type */
export type TaskContentTypeJourneyStepsTraineeProgressStatusArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** Task Type */
export type TaskContentTypePerformanceArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** Task Type */
export type TaskContentTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Task Type */
export type TaskContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};


/** Task Type */
export type TaskContentTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export type TaskCreationConfig = {
  /** Defines how the task is to be created */
  taskCreationType?: InputMaybe<TaskCreationTypeEnum>;
};

export type TaskCreationConfigInputProps = {
  createTaskCreationConfigInputs?: InputMaybe<Array<CreateTaskCreationConfigInput>>;
  removeTaskCreationConfigInputs?: InputMaybe<Array<Scalars['String']['input']>>;
  updateTaskCreationConfigInputs?: InputMaybe<Array<UpdateTaskCreationConfigInput>>;
};

export enum TaskCreationConfigTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export enum TaskCreationEntityTypeEnum {
  File = 'FILE',
  Okr = 'OKR',
  Task = 'TASK'
}

export enum TaskCreationTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/** Task content description */
export type TaskInstruction = {
  __typename?: 'TaskInstruction';
  /** Media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']['output']>;
};

export type TaskInstructionInput = {
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Task Performance Type */
export type TaskPerformanceType = {
  __typename?: 'TaskPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAvailable: Scalars['Boolean']['output'];
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float']['output'];
  recalibrationRequired: Scalars['Boolean']['output'];
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Section Performance Type */
export type TaskSectionPerformanceType = {
  __typename?: 'TaskSectionPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  assignments?: Maybe<Array<AssignmentType>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  /** CourseId for any block performance, quizId for question performance */
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String']['output'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  forceComplete?: Maybe<Scalars['Boolean']['output']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isOfCurrentWorkspace: Scalars['Boolean']['output'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress?: Maybe<Scalars['Float']['output']>;
  /** For tracking the version of the resource on which progress has been made */
  resourceVersionId?: Maybe<Scalars['String']['output']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']['output']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** source workspace contentId required for filtering */
  sourceWorkspaceContentId?: Maybe<Scalars['String']['output']>;
  /** source workspace parent entityId required for filtering */
  sourceWorkspaceParentEntityId?: Maybe<Scalars['String']['output']>;
  startedOn?: Maybe<Scalars['String']['output']>;
  status: PerformanceStatusEnum;
  tags?: Maybe<Array<EntityTagType>>;
  timeSpent?: Maybe<Scalars['Float']['output']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userContentFeedback?: Maybe<FeedbackType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

/** Task Section Type */
export type TaskSectionType = {
  __typename?: 'TaskSectionType';
  completedCount?: Maybe<Scalars['Float']['output']>;
  /** Stores the description of the section */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Name of the section */
  name?: Maybe<Scalars['String']['output']>;
  subTasks?: Maybe<Array<SubTaskType>>;
  type: ContentTypeEnum;
};


/** Task Section Type */
export type TaskSectionTypeCompletedCountArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Input for selecting tasks */
export type TaskSelectionConfigInput = {
  excludeTaskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<ActionItemsFilters>;
  includeTaskIds?: InputMaybe<Array<Scalars['String']['input']>>;
  operation: TaskSelectionOperationEnum;
};

export enum TaskSelectionOperationEnum {
  Add = 'ADD',
  Remove = 'REMOVE'
}

/** Information regarding task status config */
export type TaskStatusConfigInputSchema = {
  statusIds: Array<Scalars['String']['input']>;
  type: StatusConfigTypeEnum;
};

/** Information regarding task status config */
export type TaskStatusConfigSchema = {
  __typename?: 'TaskStatusConfigSchema';
  statusIds: Array<Scalars['String']['output']>;
  type: StatusConfigTypeEnum;
};

export type TaskStubType = {
  __typename?: 'TaskStubType';
  name: Scalars['String']['output'];
};

export enum TaskTypeEnum {
  Bell = 'BELL',
  Email = 'EMAIL',
  Push = 'PUSH',
  Slack = 'SLACK',
  Sms = 'SMS',
  Webhook = 'WEBHOOK'
}

export type TasksFromKrResultType = {
  __typename?: 'TasksFromKRResultType';
  records: Array<TaskStubType>;
};

export type TasksFromPeerFeedbackResultType = {
  __typename?: 'TasksFromPeerFeedbackResultType';
  records: Array<TaskStubType>;
};

/** For toggling email and bell notification on/off for a given actionType */
export type TasksRequiredInput = {
  bell: Scalars['Boolean']['input'];
  email: Scalars['Boolean']['input'];
};

export type TasksWidgetType = {
  __typename?: 'TasksWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  taskGroups: ActionItemCountsByCategoryAndStatusPayload;
  tasks: PaginatedActionItemsPayload;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type TasksWidgetTypeTasksArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type TeamReviewerReviewCycleInfoInput = {
  managerId: Scalars['String']['input'];
  reviewCycleId: Scalars['String']['input'];
  reviewerFilter?: InputMaybe<ReviewCycleUserFilter>;
};

export type TeamReviewerReviewCycleInfoPayload = {
  __typename?: 'TeamReviewerReviewCycleInfoPayload';
  reviewerReviewCycleInfos: Array<ReviewerReviewCycleInfo>;
};

export type TeamsEventAttendanceImportInputPropsInput = {
  eventId: Scalars['String']['input'];
};

export type TeamsEventAttendanceImportInputPropsType = {
  __typename?: 'TeamsEventAttendanceImportInputPropsType';
  eventId: Scalars['String']['output'];
};

export type TeamsEventAttendanceImportMetadataType = {
  __typename?: 'TeamsEventAttendanceImportMetadataType';
  success: Scalars['Boolean']['output'];
};

export type TeamsRecordingsImportInputPropsInput = {
  eventId: Scalars['String']['input'];
};

export type TeamsRecordingsImportInputPropsType = {
  __typename?: 'TeamsRecordingsImportInputPropsType';
  eventId: Scalars['String']['output'];
};

export type TemplateCategoriesFilters = {
  availableOnTemplateType?: InputMaybe<Array<TemplateTypeEnum>>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  templateCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Edge */
export type TemplateCategoryEdge = {
  __typename?: 'TemplateCategoryEdge';
  node: TemplateCategoryType;
};

export enum TemplateCategoryStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type TemplateCategoryType = {
  __typename?: 'TemplateCategoryType';
  availableOnTemplateType: TemplateTypeEnum;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  featured: Scalars['Boolean']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateCategoryStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Edge */
export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  node: TemplateUnion;
};

export type TemplateFilters = {
  templateId: Scalars['String']['input'];
  templateScope?: InputMaybe<TemplateScopeEnum>;
};

export type TemplateIdSetInput = {
  /** used to set template Id in meeting */
  set?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateOkrKeyResultType = {
  __typename?: 'TemplateOKRKeyResultType';
  name: Scalars['String']['output'];
};

export type TemplateOkrStub = {
  __typename?: 'TemplateOKRStub';
  keyResults?: Maybe<Array<TemplateOkrKeyResultType>>;
};

export type TemplateReviewCycleStub = {
  __typename?: 'TemplateReviewCycleStub';
  coverUrl?: Maybe<Scalars['String']['output']>;
  questionIds?: Maybe<Array<Scalars['String']['output']>>;
  questions?: Maybe<Array<QuestionType>>;
  type: ReviewCycleTypeEnum;
};

export enum TemplateScopeEnum {
  Global = 'GLOBAL',
  Workspace = 'WORKSPACE'
}

export enum TemplateStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum TemplateTypeEnum {
  Okr = 'OKR',
  ReviewCycle = 'REVIEW_CYCLE',
  UserSurvey = 'USER_SURVEY'
}

/** union of all templates */
export type TemplateUnion = OkrTemplateType | ReviewCycleTemplateType | UserSurveyTemplateType;

export type TemplateUserSurveyStub = {
  __typename?: 'TemplateUserSurveyStub';
  coverUrl?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  questionIds?: Maybe<Array<Scalars['String']['output']>>;
  questions?: Maybe<Array<QuestionType>>;
  type: UserSurveyTypeEnum;
};

export type TemplatesFilters = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<TemplateStatusEnum>>;
  templateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  templateScope?: InputMaybe<TemplateScopeEnum>;
  types?: InputMaybe<Array<TemplateTypeEnum>>;
  userSurveyTypes?: InputMaybe<Array<UserSurveyTypeEnum>>;
};

export enum TenantConnectionStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

export type TextSectionDisplayComponentType = {
  __typename?: 'TextSectionDisplayComponentType';
  /** heading for the section */
  heading?: Maybe<Scalars['String']['output']>;
  /** rich text */
  text: Scalars['String']['output'];
  type: DisplayComponentTypeEnum;
};

export type TextTagPropsInput = {
  textType: TextTagTypeEnum;
};

export type TextTagType = {
  __typename?: 'TextTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']['output']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** This denotes, whether we need to create a new dynamic group, when a new option is added inside single/multi select */
  syncGroup?: Maybe<Scalars['Boolean']['output']>;
  /** field resolvers */
  taggedContentCount: Scalars['Float']['output'];
  taggedEventCount: Scalars['Float']['output'];
  taggedOKRCount: Scalars['Float']['output'];
  taggedTaskCount: Scalars['Float']['output'];
  taggedUserCount: Scalars['Float']['output'];
  /** number type tag specific fields */
  textType: TextTagTypeEnum;
  type: TagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type TextTagTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type TextTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

export enum TextTagTypeEnum {
  Long = 'LONG',
  Short = 'SHORT'
}

export enum TextToneEnum {
  Appreciative = 'APPRECIATIVE',
  Casual = 'CASUAL',
  Confident = 'CONFIDENT',
  Friendly = 'FRIENDLY',
  Informal = 'INFORMAL',
  Joyful = 'JOYFUL',
  Optimistic = 'OPTIMISTIC',
  Professional = 'PROFESSIONAL'
}

export enum TextTransformationEnum {
  ChangeTone = 'CHANGE_TONE',
  Contract = 'CONTRACT',
  Expand = 'EXPAND',
  Generate = 'GENERATE',
  Rephrase = 'REPHRASE'
}

export enum TextUseCaseEnum {
  ContentDescription = 'CONTENT_DESCRIPTION',
  CourseDescrtiption = 'COURSE_DESCRTIPTION',
  EventDescription = 'EVENT_DESCRIPTION',
  LessonContent = 'LESSON_CONTENT',
  OkrDescription = 'OKR_DESCRIPTION',
  PathDescription = 'PATH_DESCRIPTION',
  PerformanceFeedback = 'PERFORMANCE_FEEDBACK',
  QuizDescription = 'QUIZ_DESCRIPTION',
  QuizQuestion = 'QUIZ_QUESTION',
  SubmissionInstruction = 'SUBMISSION_INSTRUCTION',
  SubmissionReviewerInstruction = 'SUBMISSION_REVIEWER_INSTRUCTION',
  TaskDescription = 'TASK_DESCRIPTION',
  UserFeedback = 'USER_FEEDBACK',
  UserFeedbackRequest = 'USER_FEEDBACK_REQUEST',
  UserPraise = 'USER_PRAISE'
}

/** Edge */
export type ThemeEdge = {
  __typename?: 'ThemeEdge';
  node: ThemeType;
};

export type ThemeFilters = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ThemeStatusEnum>>;
};

export type ThemeQuestionsConfig = {
  __typename?: 'ThemeQuestionsConfig';
  questions: Array<QuestionType>;
  questionsCount: Scalars['Float']['output'];
};

export type ThemeQuestionsConfigFilters = {
  userSurveyIds: Array<Scalars['String']['input']>;
};

export type ThemeScoreInput = {
  score: Scalars['Float']['input'];
  themeId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ThemeScoreType = {
  __typename?: 'ThemeScoreType';
  score: Scalars['Float']['output'];
  themeId: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export enum ThemeStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type ThemeType = {
  __typename?: 'ThemeType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  questionsConfig?: Maybe<ThemeQuestionsConfig>;
  status: ThemeStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
};


export type ThemeTypeQuestionsConfigArgs = {
  filters?: InputMaybe<ThemeQuestionsConfigFilters>;
};

export enum TimeDimensionGranularityEnum {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type TimeDimensionType = {
  __typename?: 'TimeDimensionType';
  dateRange?: Maybe<Array<Scalars['String']['output']>>;
  dimension: Scalars['String']['output'];
  granularity: TimeDimensionGranularityEnum;
};

export type TimeDimensionTypeInput = {
  dateRange?: InputMaybe<Scalars['String']['input']>;
  dimension: Scalars['String']['input'];
  granularity: TimeDimensionGranularityEnum;
};

export enum TimeLimitApplicableOnEnum {
  Question = 'QUESTION',
  Quiz = 'QUIZ'
}

export type TimeZoneConfigType = {
  __typename?: 'TimeZoneConfigType';
  userSessionRecords?: Maybe<Scalars['String']['output']>;
};

export type TimestampRangeInput = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type TimestampRangeType = {
  __typename?: 'TimestampRangeType';
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export enum TodoTypeEnum {
  Content = 'CONTENT',
  ReviewCycle = 'REVIEW_CYCLE'
}

/** union of all todos */
export type TodoUnion = ContentTodoType | ReviewCycleInstanceTodoType | ReviewCycleTodoType;

export type TodosWidgetPreviewType = {
  __typename?: 'TodosWidgetPreviewType';
  /** todos */
  todos?: Maybe<Array<Scalars['JSON']['output']>>;
  type: WidgetTypeEnum;
};

export type TodosWidgetType = {
  __typename?: 'TodosWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  todoContents: Array<TodoUnion>;
  todos: Array<TodoUnion>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type TodosWidgetTypeTodoContentsArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
};


export type TodosWidgetTypeTodosArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
};

export type TopHitsConfigs = {
  __typename?: 'TopHitsConfigs';
  size?: Maybe<Scalars['Float']['output']>;
  sorts?: Maybe<Array<SortTypeSchema>>;
};

export type TopHitsConfigsInput = {
  size?: InputMaybe<Scalars['Float']['input']>;
  sorts?: InputMaybe<Array<SortType>>;
};

/** Edge */
export type TrackEdge = {
  __typename?: 'TrackEdge';
  node: TrackType;
};

export type TrackFilters = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  trackId: Scalars['String']['input'];
};

/** Payload of childTrackTemplate with child orgRole templates per track */
export type TrackTemplatesProfile = {
  __typename?: 'TrackTemplatesProfile';
  /** Configs required to create a department */
  createDepartmentConfig?: Maybe<CreateDepartmentInputPropsType>;
  /** Configs required to create a metric */
  createMetricConfig?: Maybe<CreateMetricConfigsInputPropsType>;
  /** Configs required to create an orgRole */
  createOrgRoleConfig?: Maybe<CreateOrgRoleInputPropsType>;
  /** Configs required to create a skill/mindset */
  createSkillConfig?: Maybe<CreateSkillInputPropsType>;
  /** Configs required to create a skillMatrix */
  createSkillMatrixConfig?: Maybe<CreateSkillMatrixInputPropsType>;
  /** Configs required to create a track */
  createTrackConfig?: Maybe<CreateTrackInputPropsType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  orgRoleTemplates?: Maybe<Array<DefaultTemplateType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Flows for which this template can be used */
  supportedFlows: Array<SupportedFlowsEnum>;
  trackTemplates: Array<TrackTemplatesProfile>;
  /** Entity type of template */
  type: DefaultTemplateEntityEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type TrackType = {
  __typename?: 'TrackType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** id of the dynamic userGroup having all learners of track */
  defaultGroupId?: Maybe<Scalars['String']['output']>;
  department?: Maybe<DepartmentType>;
  /** departmentId in which the track belongs */
  departmentId: Scalars['String']['output'];
  /** description of the track */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isAutomaticTrack: Scalars['Boolean']['output'];
  /** if track is deleted or not */
  isDeleted: Scalars['Boolean']['output'];
  /** metric attached to track */
  metricIds?: Maybe<Array<Scalars['String']['output']>>;
  metrics?: Maybe<Array<MetricSchema>>;
  /** mindsets attached to track */
  mindsetIds?: Maybe<Array<Scalars['String']['output']>>;
  mindsets?: Maybe<Array<SkillType>>;
  /** name of the track */
  name: Scalars['String']['output'];
  orgRoleCount?: Maybe<Scalars['Float']['output']>;
  orgRoles?: Maybe<Array<OrgRoleType>>;
  /** tagId for creating roles for track */
  roleTagId?: Maybe<Scalars['String']['output']>;
  sampleUsers?: Maybe<Array<UserType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillMatrix?: Maybe<SkillMatrixType>;
  /** skillMatrix attached to track */
  skillMatrixId: Scalars['String']['output'];
  /** stores all the conditions related to tags, on basis the users will be added inside track. If not NULL, users are added manually */
  syncConfig?: Maybe<FilterGroupV2Type>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userCount: Scalars['Float']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type TrackTypeSampleUsersArgs = {
  usersFilters?: InputMaybe<UsersFilters>;
};


export type TrackTypeUserCountArgs = {
  usersFilters?: InputMaybe<UsersFilters>;
};

export type TracksFilters = {
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skillMatrixIds?: InputMaybe<Array<Scalars['String']['input']>>;
  trackIds?: InputMaybe<Array<Scalars['String']['input']>>;
  usersFilters?: InputMaybe<UsersFilters>;
};

export type TransformTextInput = {
  text: Scalars['String']['input'];
  tone?: InputMaybe<TextToneEnum>;
  transformation: TextTransformationEnum;
  useCase?: InputMaybe<TextUseCaseEnum>;
};

export type TransformTextInputPropsType = {
  __typename?: 'TransformTextInputPropsType';
  text: Scalars['String']['output'];
  tone?: Maybe<Scalars['String']['output']>;
  transformation: TextTransformationEnum;
  useCase?: Maybe<TextUseCaseEnum>;
};

export type TransformTextResultType = {
  __typename?: 'TransformTextResultType';
  text: Scalars['String']['output'];
};

export enum TypeCastEnum {
  ArrayObjectId = 'ARRAY_OBJECT_ID',
  ArrayString = 'ARRAY_STRING',
  Boolean = 'BOOLEAN',
  Number = 'NUMBER',
  ObjectId = 'OBJECT_ID',
  String = 'STRING'
}

/** URL Preview Metadata */
export type UrlPreviewImageMetadata = {
  __typename?: 'URLPreviewImageMetadata';
  height?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type UrlPreviewImageMetadataInput = {
  height?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

/** URL Preview Metadata */
export type UrlPreviewMetadata = {
  __typename?: 'URLPreviewMetadata';
  description?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  icon?: Maybe<UrlPreviewImageMetadata>;
  image?: Maybe<UrlPreviewImageMetadata>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type UrlPreviewMetadataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  icon?: InputMaybe<UrlPreviewImageMetadataInput>;
  image?: InputMaybe<UrlPreviewImageMetadataInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** UnAssignContentInput! */
export type UnAssignContentInput = {
  assignmentType?: InputMaybe<AssignmentTypeEnum>;
  entityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** unAssignContent content payload */
export type UnAssignContentPayload = {
  __typename?: 'UnAssignContentPayload';
  /** ContentIds of parent content */
  contentIds?: Maybe<Array<Scalars['String']['output']>>;
  contents?: Maybe<Array<ContentEntity>>;
  errorCode?: Maybe<Scalars['String']['output']>;
  jobScheduleId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UnAssignUserConfigInput = {
  /** UserIds to be excluded from the selection of users to be unassigned. This field will be ignored if selectAll is null/false */
  excludedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pass true if all users need to be unassigned. Pass true along with usersFilters if all filtered users need to be deassigned. */
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter that will select users to be unassigned. Only nullable if selectAll is true */
  usersFilters?: InputMaybe<UsersFilters>;
};

export type UnAssignUserConfigType = {
  __typename?: 'UnAssignUserConfigType';
  /** UserIds to be excluded from the selection of users to be unassigned. This field will be ignored if selectAll is null/false */
  excludedUserIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Pass true if all users need to be unassigned. Pass true along with usersFilters if all filtered users need to be deassigned. */
  selectAll?: Maybe<Scalars['Boolean']['output']>;
  /** Filter that will select users to be unassigned. Only nullable if selectAll is true */
  usersFilters?: Maybe<UsersFiltersType>;
};

export type UndoMoveMeetingInstanceAgendaItemsInput = {
  agendaItemId: Scalars['String']['input'];
  targetInstanceId: Scalars['String']['input'];
};

/** A filter to get user based on any uniqueId */
export type UniqueIdFilter = {
  /** Filter user based on users emailId */
  emailId?: InputMaybe<Scalars['String']['input']>;
  /** Filter user based on users federationId */
  federationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter based on any uniqueId of user */
  id?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to get user based on any uniqueId */
export type UniqueIdsFilter = {
  /** Filter user based on users emailId */
  emailIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter user based on users federationId */
  federationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter based on any uniqueId of user */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UnsetWorkspaceStrategyMethodInput = {
  provider: Scalars['String']['input'];
};

export type UpcomingAutomaticRenewalRuleInput = {
  daysBeforeRenewalDate: Scalars['Float']['input'];
  delaySeconds: Scalars['Float']['input'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingAutomaticRenewalRuleType = {
  __typename?: 'UpcomingAutomaticRenewalRuleType';
  daysBeforeRenewalDate: Scalars['Float']['output'];
  delaySeconds: Scalars['Float']['output'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingClosedRenewalRuleInput = {
  daysBeforeRenewalDate: Scalars['Float']['input'];
  delaySeconds: Scalars['Float']['input'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingClosedRenewalRuleType = {
  __typename?: 'UpcomingClosedRenewalRuleType';
  daysBeforeRenewalDate: Scalars['Float']['output'];
  delaySeconds: Scalars['Float']['output'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingDeactivationRuleInput = {
  daysAfterRenewalDate: Scalars['Float']['input'];
  delaySeconds: Scalars['Float']['input'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingDeactivationRuleType = {
  __typename?: 'UpcomingDeactivationRuleType';
  daysAfterRenewalDate: Scalars['Float']['output'];
  delaySeconds: Scalars['Float']['output'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingEventsWidgetPreviewType = {
  __typename?: 'UpcomingEventsWidgetPreviewType';
  /** events */
  events?: Maybe<Array<Scalars['JSON']['output']>>;
  type: WidgetTypeEnum;
};

export type UpcomingRenewalOpportunityRuleInput = {
  daysBeforeRenewalDate: Scalars['Float']['input'];
  delaySeconds: Scalars['Float']['input'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingRenewalOpportunityRuleType = {
  __typename?: 'UpcomingRenewalOpportunityRuleType';
  daysBeforeRenewalDate: Scalars['Float']['output'];
  delaySeconds: Scalars['Float']['output'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpdateActionItemInput = {
  actionItemId: Scalars['String']['input'];
  assignedToId?: InputMaybe<Scalars['String']['input']>;
  /** if set is undefined, will unset the assigned to id field */
  assignedToIdSetInput?: InputMaybe<AssignedToIdSetInput>;
  category?: InputMaybe<StatusCategoryEnum>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  /** if set is undefined, will unset the duedate field */
  dueDateSetInput?: InputMaybe<DueDateSetInput>;
  /** External Link of the actionItem */
  externalLink?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  /** instructions for the learner */
  instruction?: InputMaybe<InstructionInput>;
  /** Details for external entity */
  integrationConfig?: InputMaybe<ActionItemIntegrationConfigInput>;
  /** Details for any additional data related to action item  */
  metadata?: InputMaybe<ActionItemMetadataInput>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<ActionItemPriorityEnum>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<EntityTagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updateAtExternalSource?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateActionPlanInput = {
  actionables?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  focusAreas?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<ActionPlanOwnerInput>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<ActionPlanStatusEnum>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateActionPlanPayload = {
  __typename?: 'UpdateActionPlanPayload';
  actionPlan: ActionPlanType;
};

export type UpdateActivityMetricSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  pull?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateAgendaItemInput = {
  activityMetricIds?: InputMaybe<UpdateActivityMetricSelectionConfig>;
  agendaItemId: Scalars['String']['input'];
  creationCadenceConfig?: InputMaybe<UpdateCadenceConfigInput>;
  entity?: InputMaybe<Scalars['String']['input']>;
  excludedOkrIds?: InputMaybe<UpdateOkrSelectionConfig>;
  /** Agenda Instructions in text or media */
  instruction?: InputMaybe<InstructionInput>;
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
  mindsetIds?: InputMaybe<UpdateMindsetSelectionConfig>;
  okrBulkSelectConfig?: InputMaybe<OkrBulkSelectConfigInput>;
  okrIds?: InputMaybe<UpdateOkrSelectionConfig>;
  skillGroupSelectionConfig?: InputMaybe<UpdateSkillGroupSelectionConfig>;
  skillIds?: InputMaybe<UpdateSkillSelectionConfig>;
  subType?: InputMaybe<ActionItemSubTypeEnum>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAgendaItemTemplateInput = {
  agendaItemIds?: InputMaybe<Array<Scalars['String']['input']>>;
  agendaItemTemplateId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnnouncementInput = {
  availableFrom?: InputMaybe<Scalars['String']['input']>;
  availableTill?: InputMaybe<AnnouncementAvailableTillInput>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  medias?: InputMaybe<Array<InputMaybe<CreateMediaInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  shouldAllowReactions?: InputMaybe<Scalars['Boolean']['input']>;
  showOnlyToNewEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AnnouncementStatusesEnum>;
  subWorkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateAnnouncementPayload = {
  __typename?: 'UpdateAnnouncementPayload';
  announcement: AnnouncementType;
};

export type UpdateArticleInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** author of the article */
  author?: InputMaybe<ArticleAuthorInputType>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of article-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** duration of article */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** external and internal instructors of article */
  instructors?: InputMaybe<Array<CourseInstructorInputType>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** self duration of article */
  selfDuration?: InputMaybe<Scalars['Float']['input']>;
  /** Ids of skill related to the article */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Update Article Response */
export type UpdateArticlePayload = {
  __typename?: 'UpdateArticlePayload';
  article?: Maybe<ArticleContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateAssignmentInput = {
  assignerId?: InputMaybe<Scalars['String']['input']>;
  assignmentId: Scalars['String']['input'];
  assignmentItemsDueDateConfig?: InputMaybe<Array<AssignmentItemDueDateConfigInput>>;
  collectionIds: Array<Scalars['String']['input']>;
  contentIds: Array<Scalars['String']['input']>;
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  eventSelections?: InputMaybe<Array<EventSelectionInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfigurations?: InputMaybe<Array<NotificationConfigurationInput>>;
  scheduleDateConfig?: InputMaybe<ScheduleDateAssignmentConfigInput>;
  userGroupIds: Array<Scalars['String']['input']>;
  userGroupSyncConfigs?: InputMaybe<UserGroupSyncAssignmentConfigsInput>;
  userIds: Array<Scalars['String']['input']>;
};

export type UpdateAssignmentPayload = {
  __typename?: 'UpdateAssignmentPayload';
  assignment: AssignmentType;
};

export type UpdateBatchUsersPathContentAvailabilityInputPropsType = {
  __typename?: 'UpdateBatchUsersPathContentAvailabilityInputPropsType';
  pathId: Scalars['String']['output'];
  userIds: Array<Scalars['String']['output']>;
};

export type UpdateBatchUsersPathContentAvailabilityMetaDataType = {
  __typename?: 'UpdateBatchUsersPathContentAvailabilityMetaDataType';
  success: Scalars['Boolean']['output'];
};

export type UpdateCadenceConfigInput = {
  set: CadenceConfigInput;
};

export type UpdateCertificateTemplateInput = {
  certificateTemplateId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CertificateTemplateStatusEnum>;
};

export type UpdateChartInput = {
  chartId: Scalars['String']['input'];
  chartType?: InputMaybe<ChartTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dimensionConfigs?: InputMaybe<Array<DimensionConfigTypeInput>>;
  dimensions?: InputMaybe<Array<Scalars['String']['input']>>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  measureConfigs?: InputMaybe<Array<MeasureConfigTypeInput>>;
  measures?: InputMaybe<Array<MeasureTypeInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<Array<SortType>>;
  versionNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateChartPayload = {
  __typename?: 'UpdateChartPayload';
  chart: ChartWidgetType;
};

export type UpdateChildOkRsOrderInput = {
  /** ParentId of all okrs, for which we are trying to update the order */
  parentOKRId?: InputMaybe<Scalars['String']['input']>;
  updatedChildOKROrders: Array<UpdatedChildOkrOrder>;
};

export type UpdateChildOkRsOrderPayload = {
  __typename?: 'UpdateChildOKRsOrderPayload';
  parentOKR?: Maybe<OkrUnion>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** childrenIds to update */
export type UpdateChildrenIdsInput = {
  operation: Array<ArrayOperationCommandEnum>;
  position?: InputMaybe<Scalars['Float']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateContentStatusInput = {
  id: Scalars['String']['input'];
  status: ResourceStatusEnum;
};

export type UpdateContentStatusPayload = {
  __typename?: 'UpdateContentStatusPayload';
  content?: Maybe<ContentEntity>;
  entityId: Scalars['String']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Updates database metadata */
export type UpdateDatabaseMetadataInput = {
  dbDefaultViewId?: InputMaybe<Scalars['String']['input']>;
  dbId: Scalars['String']['input'];
  dbMetadataId?: InputMaybe<Scalars['String']['input']>;
  dbName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

/** Response for updating database metadata */
export type UpdateDatabaseMetadataPayload = {
  __typename?: 'UpdateDatabaseMetadataPayload';
  /** Updated Database */
  dbMetadata?: Maybe<DatabaseMetadataType>;
  errorCode?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Defines upsert input for database view */
export type UpdateDatabaseViewInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<QueryInput>;
  viewId: Scalars['String']['input'];
  viewType?: InputMaybe<ViewTypeEnum>;
};

/** Response to upsert database view operation */
export type UpdateDatabaseViewPayload = {
  __typename?: 'UpdateDatabaseViewPayload';
  errorCode?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  /** Returns upserted View */
  view?: Maybe<DatabaseViewType>;
};

export type UpdateDateTagPropsInput = {
  includeEndDate: Scalars['Boolean']['input'];
  includeTime: Scalars['Boolean']['input'];
};

/** Updates metadata fields */
export type UpdateDbMetadataFields = {
  allowMultipleSelection?: InputMaybe<Scalars['Boolean']['input']>;
  collectionId?: InputMaybe<Scalars['String']['input']>;
  collectionType?: InputMaybe<DatabaseRelationCollectionTypeEnum>;
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  fieldId: Scalars['String']['input'];
  includeEndDate?: InputMaybe<Scalars['Boolean']['input']>;
  includeTime?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  numberFormat?: InputMaybe<DatabaseNumberFormatEnum>;
  options?: InputMaybe<Array<DbMetadataFieldOptions>>;
  ratingType?: InputMaybe<DatabaseRatingTypeEnum>;
  type: DatabaseFieldTypeEnum;
  validation?: InputMaybe<ValidationInput>;
};

/** input for updating a department */
export type UpdateDepartmentInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  /** Updated Department */
  department: DepartmentType;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateEmailDigestInput = {
  emailDigestId: Scalars['String']['input'];
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<EmailDigestResourceTypeEnum>;
  exportMetadata?: InputMaybe<ExportMetadataInput>;
  fileFormat?: InputMaybe<EmailDigestFileFormatEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  recipients?: InputMaybe<Array<RecipientInfoInput>>;
  reportId?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<ScheduleInfoInput>;
  status?: InputMaybe<EmailDigestStatusEnum>;
};

export type UpdateEntitiesTasksRelationInput = {
  /** array of updated entity tasks relations */
  entityTasksRelations: Array<UpdateEntityTasksRelationInput>;
};

export enum UpdateEntityAttachmentsCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET',
  Update = 'UPDATE'
}

export enum UpdateEntityAttachmentsEntityTypeEnum {
  Agenda = 'AGENDA',
  Meeting = 'MEETING',
  Metric = 'METRIC',
  Okr = 'OKR',
  Task = 'TASK'
}

export type UpdateEntityAttachmentsInput = {
  command: UpdateEntityAttachmentsCommandEnum;
  entityId: Scalars['String']['input'];
  entityType: UpdateEntityAttachmentsEntityTypeEnum;
  toRemoveAttachmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toUpsertAttachments?: InputMaybe<Array<CreateMediaInput>>;
};

export type UpdateEntityIdsInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  pull?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
  setEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateEntityJiraProjectStatuesConfig = {
  projectId: Scalars['String']['input'];
  statuses: Array<UpdateJiraStatusMappingInput>;
};

export enum UpdateEntityTagCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET',
  Update = 'UPDATE'
}

export type UpdateEntityTagOperation = {
  /** operation to performa on entity tags i.e. remove, set, upsert */
  command: UpdateEntityTagCommandEnum;
  /** entity tags which will be set, added or updated in entity */
  entityTags?: InputMaybe<Array<EntityTagInput>>;
  /** tag ids to remove */
  toRemoveTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum UpdateEntityTagsEntityTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Okr = 'OKR',
  Task = 'TASK',
  User = 'USER'
}

export type UpdateEntityTagsInput = {
  /** id of entity for which to update tags */
  entityId: Scalars['String']['input'];
  /** type of entity */
  entityType: UpdateEntityTagsEntityTypeEnum;
  /** operation to perform on entity tags */
  operations: Array<UpdateEntityTagOperation>;
};

/** update entity tags payload */
export type UpdateEntityTagsPayload = {
  __typename?: 'UpdateEntityTagsPayload';
  entityTags?: Maybe<Array<EntityTagType>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateEntityTasksRelationInput = {
  /** id of entity for which to update related tasks */
  entityId: Scalars['String']['input'];
  /** type of entity */
  entityType: TaskAvailableOnEnum;
  /** task selection configs for entity */
  taskSelectionConfig: TaskSelectionConfigInput;
};

export type UpdateEventInput = {
  actualEndTime?: InputMaybe<Scalars['String']['input']>;
  actualStartTime?: InputMaybe<Scalars['String']['input']>;
  apiVersion?: InputMaybe<Scalars['Float']['input']>;
  /** if not provided, existing certificateSettings will be removed */
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionSettings?: InputMaybe<CompletionSettingsInput>;
  /** if not provided, existing cover will be removed */
  cover?: InputMaybe<CreateMediaInput>;
  /** if not provided, existing description will be removed */
  description?: InputMaybe<Scalars['String']['input']>;
  /** duration of event in seconds,used to derive endTime if endTime is not provided */
  duration?: InputMaybe<Scalars['Float']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  eventRecordings?: InputMaybe<UpdateEventRecordingsInput>;
  externalEventProps?: InputMaybe<UpdateExternalEventPropsInput>;
  /** if not provided, will return error */
  instructorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  liveEventProps?: InputMaybe<UpdateLiveEventPropsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  objective?: InputMaybe<Scalars['String']['input']>;
  offlineEventProps?: InputMaybe<UpdateOfflineEventPropsInput>;
  postWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  postwork?: InputMaybe<SetEventWorkConfigInput>;
  preWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  prework?: InputMaybe<SetEventWorkConfigInput>;
  recordings?: InputMaybe<UpdateRecordingsInput>;
  recurrenceSchedule?: InputMaybe<ScheduleInfoInput>;
  /** if not provided, existing registrationSettings will be removed */
  registrationSettings?: InputMaybe<RegistrationSettingsInput>;
  setCertificateSettings?: InputMaybe<SetCertificateSettingsInput>;
  setDescription?: InputMaybe<SetStringInput>;
  setRegistrationSettings?: InputMaybe<SetRegistrationSettingsInput>;
  setSkillIds?: InputMaybe<SetSkillIds>;
  /** if not provided, existing skillIds will be removed */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updationSource?: InputMaybe<EventUpdationSourceEnum>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateEventRecordingsInput = {
  command: UpdateRecordingsCommandTypeEnum;
  eventRecordings: Array<EventRecordingInput>;
};

export type UpdateEventStatusInput = {
  /** reason for event archival */
  archivalReason?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  status: EventStatusEnum;
  /** if not provided, it will considered as current */
  updateType?: InputMaybe<UpdateEventStatusTypeEnum>;
  updationSource?: InputMaybe<EventUpdationSourceEnum>;
};

export enum UpdateEventStatusTypeEnum {
  All = 'ALL',
  Cancelled = 'CANCELLED',
  Current = 'CURRENT',
  Upcoming = 'UPCOMING'
}

export type UpdateExerciseInput = {
  /** Exercise Comments/ and other advance level settings */
  advanceSettings?: InputMaybe<ExerciseAdvanceSettingsInput>;
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionReviewRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Storing media of exercise-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  exerciseQuestions?: InputMaybe<Array<ExerciseQuestionInput>>;
  /** instructions for the learner */
  instruction?: InputMaybe<ExerciseInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  objective?: InputMaybe<Scalars['String']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  resourceId: Scalars['String']['input'];
  reviewType?: InputMaybe<ResourceReviewTypeEnum>;
  reviewerConfig?: InputMaybe<ReviewerConfigInput>;
  /** instructions for the reviewer */
  reviewerInstruction?: InputMaybe<ExerciseInstructionInput>;
  /** scorecards */
  scorecardIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type UpdateExercisePayload = {
  __typename?: 'UpdateExercisePayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  /** Updated Homework */
  exercise?: Maybe<ExerciseType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateExternalContentInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  externalResourceInfo?: InputMaybe<ExternalResourceInfoInput>;
  /** id of the external content to be updated */
  id: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Id of the external content provider */
  providerId?: InputMaybe<Scalars['String']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  /** Self Duration of the external content */
  selfDuration?: InputMaybe<Scalars['Float']['input']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  /** Method to verify completion of the external content */
  verificationMethod?: InputMaybe<VerificationMethodInputType>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Payload for updated external content mutation */
export type UpdateExternalContentPayload = {
  __typename?: 'UpdateExternalContentPayload';
  externalContent?: Maybe<ExternalContentType>;
};

/** Input for updating externalContentPerformance */
export type UpdateExternalContentPerformanceInput = {
  /** external url of the certificate */
  externalCertificateUrl?: InputMaybe<Scalars['String']['input']>;
  /** Id of the external content whose performance to be updated */
  externalContentId: Scalars['String']['input'];
  /** performance done on external platform */
  externalPerformance?: InputMaybe<ExternalPerformanceInput>;
  /** media submission */
  mediaSubmission?: InputMaybe<CreateMediaInput>;
  /** progress for external content */
  progress?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<PerformanceStatusEnum>;
  /** text submission */
  textSubmission?: InputMaybe<Scalars['String']['input']>;
  /** Id of the user whose performance to be updated */
  userId: Scalars['String']['input'];
};

/** Updated external content */
export type UpdateExternalContentPerformancePayload = {
  __typename?: 'UpdateExternalContentPerformancePayload';
  externalContentPerformance?: Maybe<ExternalContentPerformanceType>;
};

export type UpdateExternalEventPropsInput = {
  enableWaitingRoom?: InputMaybe<Scalars['Boolean']['input']>;
  externalEventProviderInfo?: InputMaybe<ExternalEventProviderInfoInput>;
  recordingSettings?: InputMaybe<RecordingSettingsInput>;
};

/** Feedback provided by the user */
export type UpdateFeedbackInput = {
  /** Entity Id */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** Type of Enum */
  entityType?: InputMaybe<EntityTypeEnum>;
  /** FeedbackId for the feedback to be updated */
  feedbackId: Scalars['String']['input'];
  metadata?: InputMaybe<FeedbackMetadataInput>;
  questionScope?: InputMaybe<FeedbackQuestionScopeEnum>;
  /** User feedback response to be updated */
  responses?: InputMaybe<Array<FeedbackResponseInput>>;
};

export type UpdateFileInput = {
  /** files or folder */
  category?: InputMaybe<FileCategoryEnum>;
  children?: InputMaybe<UpdateChildrenIdsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** contains type of file */
  includes?: InputMaybe<Array<FileContentTypeEnum>>;
  jiraTaskProjectStatusesMap?: InputMaybe<Array<UpdateEntityJiraProjectStatuesConfig>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Location under which folders/file will be searched */
  path?: InputMaybe<Scalars['String']['input']>;
  taskConfig?: InputMaybe<TaskConfigInputSchema>;
  taskSyncConfig?: InputMaybe<SyncConfigInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateGroupInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  contentVisibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  groupId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupsOfUserInput = {
  add: Array<Scalars['String']['input']>;
  remove: Array<Scalars['String']['input']>;
};

export type UpdateJiraPrioritiesMappingInput = {
  priorityMapping: Array<UpdateJiraPriorityMappingInput>;
};

export type UpdateJiraPrioritiesMappingPayload = {
  __typename?: 'UpdateJiraPrioritiesMappingPayload';
  priorityConfigs?: Maybe<Array<JiraIntegrationPriorityConfig>>;
};

export type UpdateJiraPriorityMappingInput = {
  jiraPriorityId: Scalars['String']['input'];
  lyearnPriority: ActionItemPriorityEnum;
};

export type UpdateJiraStatusMappingInput = {
  jiraStatusId: Scalars['String']['input'];
  lyearnStatusId: Scalars['String']['input'];
};

export type UpdateJiraStatusesMappingInput = {
  statusMap: Array<UpdateJiraStatusMappingInput>;
};

export type UpdateJiraStatusesMappingPayload = {
  __typename?: 'UpdateJiraStatusesMappingPayload';
  projectStatusesConfigs: Array<JiraIntegrationProjectStatusesConfig>;
};

export type UpdateJourneyInput = {
  iconSettings?: InputMaybe<IconSettingsInput>;
  id: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  participantConfigs?: InputMaybe<ParticipantConfigInput>;
  phases?: InputMaybe<JourneyPhaseUpdateInput>;
  reportVisibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  status?: InputMaybe<JourneyStatusEnum>;
};

export type UpdateJourneyQuestionPerformanceInput = {
  entityId: Scalars['String']['input'];
  questionResponse: JourneyPerformanceQuestionResponseInput;
  reviewerId?: InputMaybe<Scalars['String']['input']>;
  status: JourneyPerformanceStatusEnum;
};

export type UpdateJourneyQuestionPerformancesInput = {
  batchId: Scalars['String']['input'];
  questionPerformances?: InputMaybe<Array<UpdateJourneyQuestionPerformanceInput>>;
  userId: Scalars['String']['input'];
};

export type UpdateJourneyStepInput = {
  actionEntities?: InputMaybe<Array<InputMaybe<JourneyStepActionEntitiesInput>>>;
  createQuestionsInput?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<JourneyStepSettingsInput>;
  status?: InputMaybe<JourneyStepStatusTypeEnum>;
  updateQuestionsInput?: InputMaybe<Scalars['String']['input']>;
  updateTaskInput?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJourneyStepPayload = {
  __typename?: 'UpdateJourneyStepPayload';
  journeyStep: JourneyStepType;
};

export type UpdateJourneyStepTraineeSessionInput = {
  attendeeAdditionType: JourneyStepSessionAttendeeAdditionType;
  attendeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeAttendeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sessionId: Scalars['String']['input'];
};

export type UpdateKeyResultInput = {
  /** Order of okr in parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  /** Details for linking externally */
  integrationConfig?: InputMaybe<OkrIntegrationConfigInput>;
  /** If id is provided, it will update keyResult, else will create a new one */
  keyResultId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** outcome for the KeyResult */
  outcome?: InputMaybe<KeyResultOutcomeInput>;
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  /** if empty, means that no update is to be done */
  parentId?: InputMaybe<ParentIdSetInput>;
  parentIds?: InputMaybe<ParentIdsSetInput>;
  /** Details for progress measurement */
  progressMeasurementConfig?: InputMaybe<OkrProgressMeasurementConfigInput>;
  tags?: InputMaybe<EntityTagsInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

export type UpdateLastResolvedNotificationTimestampPayload = {
  __typename?: 'UpdateLastResolvedNotificationTimestampPayload';
  lastResolvedNotificationTimestamp?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateLiveEventPropsInput = {
  /** should be provided while updating subType to webinar */
  provider?: InputMaybe<LivestreamProviderEnum>;
  recordingSettings?: InputMaybe<RecordingSettingsInput>;
  subType?: InputMaybe<EventSubTypeEnum>;
};

export type UpdateMeetingInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  excludedOkrIds?: InputMaybe<UpdateMeetingOkrIdsInput>;
  includeChildObjectives?: InputMaybe<Scalars['Boolean']['input']>;
  meetingId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  noteBlockId?: InputMaybe<Scalars['String']['input']>;
  okrBulkSelectConfig?: InputMaybe<OkrBulkSelectConfigInput>;
  okrIds?: InputMaybe<UpdateMeetingOkrIdsInput>;
  okrIdsWithoutChildren?: InputMaybe<UpdateMeetingOkrIdsInput>;
  participantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  schedule?: InputMaybe<ScheduleInfoInput>;
  status?: InputMaybe<PublicMeetingStatusEnum>;
  /** if set is undefined, will unset the assigned to id field */
  templateId?: InputMaybe<TemplateIdSetInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

export type UpdateMeetingInstanceAgendaItemsOrderInput = {
  agendaItemIds: Array<Scalars['String']['input']>;
  meetingInstanceId: Scalars['String']['input'];
};

export type UpdateMeetingInstanceStatusInput = {
  meetingInstanceId: Scalars['String']['input'];
  status: PublicMeetingInstanceStatusEnum;
};

export type UpdateMeetingOkrIdsInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  pull?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
  setEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateMetricInput = {
  /** Salesforce -> dataObject */
  dataObject?: InputMaybe<Scalars['String']['input']>;
  defaultTargetFrequency?: InputMaybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: InputMaybe<Scalars['Float']['input']>;
  defaultTargetValueUnit?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  lastSyncedAt?: InputMaybe<Scalars['String']['input']>;
  manualMetricConfig?: InputMaybe<ManualMetricConfigInput>;
  /** Metric type */
  measurementUnit?: InputMaybe<KeyResultMetricEnum>;
  metricId: Scalars['String']['input'];
  /** Salesforce -> filters */
  metricType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reminders?: InputMaybe<UpdateMetricRemindersInput>;
  repeatSchedule?: InputMaybe<ScheduleInfoInput>;
  status?: InputMaybe<MetricStatusEnum>;
  syncConfig?: InputMaybe<MetricSyncConfigInputType>;
};

export type UpdateMetricRemindersInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMindsetSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  pull?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMultiSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']['input']>;
  options: Array<UpdateSelectTagOptionInput>;
};

/** Input for Updating Notification Template For Customizing Notifications */
export type UpdateNotificationTemplateInput = {
  actionType?: InputMaybe<NotificationActionTypeEnum>;
  notificationTemplateId: Scalars['String']['input'];
  reminderConfigurations?: InputMaybe<Array<ReminderConfigurationInput>>;
  tasksRequired?: InputMaybe<TasksRequiredInput>;
};

/** Update Notification Template Response */
export type UpdateNotificationTemplatePayload = {
  __typename?: 'UpdateNotificationTemplatePayload';
  notificationTemplate?: Maybe<NotificationTemplate>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateNumberTagPropsInput = {
  numberType: NumberTagTypeEnum;
};

export type UpdateOkrCycleInput = {
  /** id of cycle */
  cycleId: Scalars['String']['input'];
  /** Due date of the cycle */
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  /** display emoji or color */
  iconSetting?: InputMaybe<IconSettingsInput>;
  /** Name of cycle */
  name?: InputMaybe<Scalars['String']['input']>;
  objectivePermissions?: InputMaybe<ObjectivePermissionsSetInput>;
  scheduleConfig?: InputMaybe<ScheduleConfigInput>;
  /** start date of the cycle */
  startDate?: InputMaybe<Scalars['String']['input']>;
  /** Status for okr, ontrack behind etc */
  status?: InputMaybe<OkrCycleStatusEnum>;
  /** store who can view the cycle */
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateOkrCyclePayload = {
  __typename?: 'UpdateOKRCyclePayload';
  cycle?: Maybe<OkrCycle>;
};

export type UpdateOkrDetailsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  okrId: Scalars['String']['input'];
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

export type UpdateOkrSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  pull?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateObjectiveInput = {
  /** category config input */
  categoryConfig?: InputMaybe<ObjectiveCategoryConfigInput>;
  childObjectives?: InputMaybe<ChildObjectivesSetInput>;
  /** Order of okr in parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']['input']>;
  contributeToProgress?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  keyResults?: InputMaybe<KeyResultsSetInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of objective to be updated */
  objectiveId?: InputMaybe<Scalars['String']['input']>;
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  /** if empty, means that no update is to be done */
  parentId?: InputMaybe<ParentIdSetInput>;
  parentIds?: InputMaybe<ParentIdsSetInput>;
  tags?: InputMaybe<EntityTagsInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
  /** store who can view the objective */
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateObjectivePayload = {
  __typename?: 'UpdateObjectivePayload';
  objective?: Maybe<ObjectiveType>;
};

export type UpdateOfflineEventPropsInput = {
  location?: InputMaybe<EventLocationInput>;
};

export type UpdateOrgRoleActivityMetricsInput = {
  add?: InputMaybe<Array<ActivityMetricsInputType>>;
  edit?: InputMaybe<Array<ActivityMetricsInputType>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<ActivityMetricsInputType>>;
  /** Metrics to be created and added to orgRole with default target and frequency */
  toCreateMetrics?: InputMaybe<Array<CreateMetricInput>>;
};

export type UpdateOrgRoleDepartmentInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

/** input for creating a new orgRole under a department */
export type UpdateOrgRoleInput = {
  activityMetricWeightDistributionConfig?: InputMaybe<ActivityMetricWeightDistributionConfigInput>;
  activityMetrics?: InputMaybe<UpdateOrgRoleActivityMetricsInput>;
  definition?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<UpdateOrgRoleDepartmentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Float']['input']>;
  mindsetIds?: InputMaybe<UpdateOrgRoleMindsetInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgRoleId: Scalars['String']['input'];
  orgRoleReportConfig?: InputMaybe<OrgRoleReportInput>;
  /** input for creating or editing skillGroups inside an orgRole */
  skillGroups?: InputMaybe<UpdateOrgRoleSkillGroupsInput>;
  skillMasteries?: InputMaybe<UpdateOrgRoleSkillMasteriesInput>;
  skillMatrixConfig?: InputMaybe<UpdateSkillMatrixConfigInput>;
  status?: InputMaybe<OrgRoleStatusEnum>;
};

export type UpdateOrgRoleMindsetInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  create?: InputMaybe<Array<CreateSkillInput>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateOrgRolePayload = {
  __typename?: 'UpdateOrgRolePayload';
  createdActivityMetrics?: Maybe<Array<ActivityMetricType>>;
  createdMindsets?: Maybe<Array<SkillType>>;
  createdSkillGroups?: Maybe<Array<SkillGroupType>>;
  createdSkills?: Maybe<Array<SkillType>>;
  /** Updated OrgRole */
  orgRole?: Maybe<OrgRoleType>;
};

export type UpdateOrgRoleSkillGroupsInput = {
  /** input for creating skillGroup from inside an org role */
  toCreateSkillGroups?: InputMaybe<Array<CreateSkillGroupInOrgRoleInput>>;
  /** input for editing skillGroup name from inside an org role */
  toEditSkillGroups?: InputMaybe<Array<EditSkillGroupInOrgRoleInput>>;
};

export type UpdateOrgRoleSkillMasteriesInput = {
  add?: InputMaybe<Array<AddSkillInOrgRoleInput>>;
  create?: InputMaybe<Array<CreateSkillInOrgRoleInput>>;
  edit?: InputMaybe<Array<SkillMasteryConfigInput>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<SkillMasteryConfigInput>>;
};

/** UpdatePathContentAvailabilityOfGivenUserIdsInput! */
export type UpdatePathContentAvailabilityOfGivenUserIdsInput = {
  pathId: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type UpdatePathInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the path content */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  dripSettings?: InputMaybe<DripSettingsInput>;
  id: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Should notifications be sent to learners when the path is being updated */
  notifyLearners?: InputMaybe<Scalars['Boolean']['input']>;
  objective?: InputMaybe<Scalars['String']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  /** sections not provided will be deleted */
  sections: Array<UpdatePathSectionInput>;
  /** skill ids not provided will be deleted */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdatePathPayload = {
  __typename?: 'UpdatePathPayload';
  path?: Maybe<PathContentType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdatePathSectionInput = {
  /** Stores which resource is optional or not */
  childResourceConfigs?: InputMaybe<Array<SectionChildResourceConfigsInputType>>;
  /** learning content ids */
  childResourceIds: Array<Scalars['String']['input']>;
  /** id given means that section already exists */
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdatePeerFeedbackInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<AttachmentsSetInput>;
  feedbackMessage?: InputMaybe<Scalars['String']['input']>;
  learningContentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mentions?: InputMaybe<MentionsSetInput>;
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  peerFeedbackId: Scalars['String']['input'];
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  status?: InputMaybe<PeerFeedbackStatusEnum>;
  visibleToManager?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input for updating the status of performance */
export type UpdatePerformanceStatusInput = {
  /** Entity (like task, subtask) Id whose performance status are to be update */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** Multiple, subtasks needs to be completed at one go */
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status: PerformanceStatusEnum;
  type?: InputMaybe<PerformanceTypeEnum>;
  /** Id of the students whose performance status are to be update */
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePraiseInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<AttachmentsSetInput>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: InputMaybe<MentionsSetInput>;
  message?: InputMaybe<Scalars['String']['input']>;
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  postId: Scalars['String']['input'];
  praiseId: Scalars['String']['input'];
  receiverUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  visibleToEveryone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateQuestionEntity = {
  applicableOn?: InputMaybe<Array<QuestionApplicableOnEnum>>;
  associations?: InputMaybe<AssociationsSetInput>;
  attachments?: InputMaybe<QuestionAttachmentsSetInput>;
  correctAnswerFeedback?: InputMaybe<Scalars['String']['input']>;
  customRatingComponent?: InputMaybe<CustomRatingComponentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficulty?: InputMaybe<DifficultyEnum>;
  ftbComponents?: InputMaybe<FtbComponentsSetInput>;
  globalDistractors?: InputMaybe<DistractorsSetInput>;
  id: Scalars['String']['input'];
  imageHotspotComponents?: InputMaybe<ImageHotspotComponentsSetInput>;
  incorrectAnswerFeedback?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mtfComponent?: InputMaybe<MtfComponentInput>;
  options?: InputMaybe<OptionsSetInput>;
  questionText?: InputMaybe<Scalars['String']['input']>;
  questionTextType?: InputMaybe<QuestionTextTypeEnum>;
  questionType?: InputMaybe<QuestionTypeEnum>;
  settings?: InputMaybe<QuestionSettingsInput>;
  skillIds?: InputMaybe<QuestionSkillIdsSetInput>;
  status?: InputMaybe<QuestionStatusEnum>;
  /** Sub type of question like multi select, single select */
  subType?: InputMaybe<QuestionSubTypeEnum>;
  subWorkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  themeIds?: InputMaybe<QuestionThemeIdsSetInput>;
};

export type UpdateQuestionsInput = {
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  /** Letting backend API know, that we need to publish question only */
  publishQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  updateQuestionEntities?: InputMaybe<Array<UpdateQuestionEntity>>;
};

export type UpdateQuestionsPayload = {
  __typename?: 'UpdateQuestionsPayload';
  /** Failed Questions */
  failedQuestions: Array<QuestionType>;
  success?: Maybe<Scalars['Boolean']['output']>;
  /** Questions created */
  updatedQuestions: Array<QuestionType>;
};

export type UpdateQuizInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the content */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration in seconds */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Entity on which this quiz has to attached */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** Entity type of the entity where this quiz will be attached */
  entityType?: InputMaybe<QuizEntityTypeEnum>;
  /** Quiz id to be updated */
  id: Scalars['String']['input'];
  /** Instructions for the quiz */
  instruction?: InputMaybe<ResourceInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of the quiz */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Objective of the content */
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Passing Percentage of the quiz */
  passingPercentage?: InputMaybe<Scalars['Float']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  questionGenerationConfig?: InputMaybe<QuestionsGenerationConfigInput>;
  questionGenerationType?: InputMaybe<QuestionGenerationType>;
  /** Quiz Question ids along with settings */
  questions?: InputMaybe<QuestionsSetInput>;
  /** quiz level settings */
  settings?: InputMaybe<QuizSettingsInput>;
  /** Ids of skills suitable for the content */
  skillIds?: InputMaybe<QuizSkillIdsSetInput>;
  /** status of quiz */
  status?: InputMaybe<ResourceStatusEnum>;
  /** SubType of resource, here quiz. Ex - Static, inLesson, review etc */
  subType?: InputMaybe<ResourceSubTypeEnum>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
};

export type UpdateQuizPayload = {
  __typename?: 'UpdateQuizPayload';
  /** Updated Quiz */
  quiz?: Maybe<QuizContentType>;
};

export type UpdateQuizQuestionConfigInput = {
  order?: InputMaybe<Scalars['Float']['input']>;
  /** question of quiz */
  questionId: Scalars['String']['input'];
};

export type UpdateQuizQuestionsInput = {
  questionConfigs: Array<UpdateQuizQuestionConfigInput>;
  /** Quiz */
  quizId: Scalars['String']['input'];
};

/** This returns post along with success when a reaction is added or removed */
export type UpdateReactionPayload = {
  __typename?: 'UpdateReactionPayload';
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum UpdateRecordingsCommandTypeEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET'
}

export type UpdateRecordingsInput = {
  command: UpdateRecordingsCommandTypeEnum;
  recordings: Array<Scalars['String']['input']>;
};

export type UpdateReponsibilitiesOfUserInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['String']['input'];
  status?: InputMaybe<ReportStatusEnum>;
  tags?: InputMaybe<EntityTagsInput>;
  versionNumber?: InputMaybe<Scalars['Float']['input']>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  widgetsLayout?: InputMaybe<WidgetLayoutSetInput>;
};

/** Update Report Payload */
export type UpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  report: ReportType;
};

export type UpdateResourceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Parent folder Id of the resource */
  parentId?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['String']['input'];
  status?: InputMaybe<ResourceStatusEnum>;
};

/** Update Resource Response */
export type UpdateResourcePayload = {
  __typename?: 'UpdateResourcePayload';
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  resource?: Maybe<ResourceType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** ReviewCycle Schema */
export type UpdateReviewCycleInput = {
  advanceSettings?: InputMaybe<AdvanceSettingsInput>;
  /** ReviewCycle description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Settings related to downward-review */
  downwardReviewSettings?: InputMaybe<DownwardReviewSettingsInput>;
  /** When will the review cycle end */
  endDate?: InputMaybe<Scalars['String']['input']>;
  /** Icon settings */
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** Id of the review cycle */
  id: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Settings related to peer-review */
  peerReviewSettings?: InputMaybe<PeerReviewSettingsInput>;
  /** Review Cycle End Settings */
  reviewCycleEndSettings?: InputMaybe<ReviewCycleEndSettingsInput>;
  /** reviewee to whom this reviewCycle is attached */
  reviewees?: InputMaybe<Array<RevieweeInput>>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes?: InputMaybe<Array<ReviewTypeEnum>>;
  sameItemsForAllReviewTypes?: InputMaybe<Scalars['Boolean']['input']>;
  /** manual/automatic schedule settings */
  scheduleSettings?: InputMaybe<ReviewCycleScheduleConfigInput>;
  /** Settings related to self-review */
  selfReviewSettings?: InputMaybe<ReviewCycleModuleSettingsInput>;
  /** When will the review cycle start */
  startDate?: InputMaybe<Scalars['String']['input']>;
  /** Review Cycle Status */
  status?: InputMaybe<ReviewCycleStatusEnum>;
  /** ReviewCycle Name or Title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Settings related to upward-review */
  upwardReviewSettings?: InputMaybe<UpwardReviewSettingsInput>;
};

export type UpdateReviewCycleStatusInput = {
  reviewCycleId: Scalars['String']['input'];
  status: ReviewCycleStatusEnum;
};

export type UpdateSamlDataInput = {
  provider: Scalars['String']['input'];
  set: Array<SetSamlDataInput>;
};

export type UpdateScorecardInput = {
  questions?: InputMaybe<Array<ScorecardQuestionInput>>;
  scorecardId: Scalars['String']['input'];
  skillId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScorecardPayload = {
  __typename?: 'UpdateScorecardPayload';
  /** Error code */
  errorCode?: Maybe<Scalars['String']['output']>;
  scorecard?: Maybe<ScorecardType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateScormInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  contentId: Scalars['String']['input'];
  /** Storing media of cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the scorm content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  objective?: InputMaybe<Scalars['String']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  scormProperties?: InputMaybe<ScormPropertiesInput>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateSelectTagOptionInput = {
  externalData?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateSelfLearningContentInput = {
  /** content id */
  contentId: Scalars['String']['input'];
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']['input']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']['input']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateSharedItemsDuplicationStatusInput = {
  allowDuplication: Scalars['Boolean']['input'];
  items: Array<ShareItemInput>;
  sharedToWorkspaceId: Scalars['String']['input'];
};

export type UpdateSharedViewInput = {
  allowComments?: InputMaybe<Scalars['Boolean']['input']>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateSharedViewPayload = {
  __typename?: 'UpdateSharedViewPayload';
  sharedView: SharedViewType;
};

export type UpdateSingleSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']['input']>;
  options: Array<UpdateSelectTagOptionInput>;
};

export type UpdateSkillGroupSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};

/** Input for updating an existing skill */
export type UpdateSkillInput = {
  childrenIds?: InputMaybe<UpdateEntityIdsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  microSkillUpdates?: InputMaybe<Array<MicroSkillUpdateInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentIds?: InputMaybe<UpdateEntityIdsInput>;
  rubrics?: InputMaybe<Array<RubricInput>>;
  skillId: Scalars['String']['input'];
};

export type UpdateSkillMatrixConfigInput = {
  set?: InputMaybe<SkillMatrixConfigInput>;
};

export type UpdateSkillMatrixInput = {
  iconSettings?: InputMaybe<IconSettingsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  skillGroups?: InputMaybe<SkillGroupsInput>;
  skillMatrixId: Scalars['String']['input'];
  status?: InputMaybe<PublicSkillMatrixStatusEnum>;
};

export type UpdateSkillSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  pull?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateStaffRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entityPermissionKeys: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  restrictedPermittedOn?: InputMaybe<RestrictedPermittedOnInput>;
  roleId: Scalars['String']['input'];
  tagColor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStatusOfClassroomEventInput = {
  eventId: Scalars['String']['input'];
  status: ClassroomEventStatusEnum;
};

/** Update status of event response */
export type UpdateStatusOfClassroomEventResponse = {
  __typename?: 'UpdateStatusOfClassroomEventResponse';
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success: Scalars['Boolean']['output'];
};

export type UpdateSubTaskInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  instruction?: InputMaybe<TaskInstructionInput>;
  name: Scalars['String']['input'];
};

export type UpdateSurveyInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Cover image for survey */
  cover?: InputMaybe<CreateMediaInput>;
  /** Cover offset */
  coverOffset?: InputMaybe<Scalars['Float']['input']>;
  dbFields?: InputMaybe<UpsertDatabaseFieldsInput>;
  /** Description of the survey */
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** Survey Id */
  id: Scalars['String']['input'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the survey */
  name?: InputMaybe<Scalars['String']['input']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  selfDuration?: InputMaybe<Scalars['Float']['input']>;
  /** Skill Ids */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Publishing status of survey */
  status?: InputMaybe<ResourceStatusEnum>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Update Survey Payload */
export type UpdateSurveyPayload = {
  __typename?: 'UpdateSurveyPayload';
  survey?: Maybe<SurveyContentType>;
};

/** Input for updating externalContentPerformance */
export type UpdateSurveyPerformanceInput = {
  /** Database data input */
  databaseDataInput?: InputMaybe<UpsertDatabaseDataInput>;
  /** Is survey embedded inside course */
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>;
  /** survey performance status */
  status?: InputMaybe<PerformanceStatusEnum>;
  /** Id of the survey whose performance to be updated */
  surveyId: Scalars['String']['input'];
  /** Id of the user whose performance to be updated */
  userId: Scalars['String']['input'];
};

export type UpdateTagInput = {
  availableOn?: InputMaybe<Array<TagAvailableOnEnum>>;
  category?: InputMaybe<TagCategoryEnum>;
  dateTagProps?: InputMaybe<UpdateDateTagPropsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  multiSelectTagProps?: InputMaybe<UpdateMultiSelectTagPropsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberTagProps?: InputMaybe<UpdateNumberTagPropsInput>;
  settings?: InputMaybe<UpdateTagSettingsInput>;
  singleSelectTagProps?: InputMaybe<UpdateSingleSelectTagPropsInput>;
  source?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<TagSubTypeEnum>;
  subworkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  tagId: Scalars['String']['input'];
  textTagProps?: InputMaybe<UpdateTextTagPropsInput>;
  type?: InputMaybe<TagTypeEnum>;
};

export type UpdateTagSettingsInput = {
  availableInLibrary?: InputMaybe<Scalars['Boolean']['input']>;
  controlConfig?: InputMaybe<TagControlConfigInput>;
  disableAttaching?: InputMaybe<Scalars['Boolean']['input']>;
  disableEditing?: InputMaybe<Scalars['Boolean']['input']>;
  disableFiltering?: InputMaybe<Scalars['Boolean']['input']>;
  disableGrouping?: InputMaybe<Scalars['Boolean']['input']>;
  disableResetting?: InputMaybe<Scalars['Boolean']['input']>;
  enableCrossWorkspaceSharing?: InputMaybe<Scalars['Boolean']['input']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  requiredTag?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateTaskCreationConfigInput = {
  /** Type of config, manual or auto */
  externalEntityType?: InputMaybe<ActionItemExternalEntityTypeEnum>;
  /** provider with which this sync is created */
  providerId?: InputMaybe<Scalars['String']['input']>;
  /** Searchtext, related query for the sync */
  searchText?: InputMaybe<Scalars['String']['input']>;
  /** Id of the config to update */
  taskCreationConfigId: Scalars['String']['input'];
  /** Type of config, manual or auto */
  taskCreationType?: InputMaybe<TaskCreationConfigTypeEnum>;
};

export type UpdateTaskInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of task-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration: Scalars['Float']['input'];
  instruction?: InputMaybe<TaskInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  publishConfig?: InputMaybe<PublishContentConfig>;
  subTasks?: InputMaybe<Array<UpdateSubTaskInput>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<EntityTagsInput>;
  taskId: Scalars['String']['input'];
};

export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
  task?: Maybe<TaskContentType>;
};

export type UpdateTextTagPropsInput = {
  textType: TextTagTypeEnum;
};

export type UpdateTrackInput = {
  /** description of the track */
  description?: InputMaybe<Scalars['String']['input']>;
  /** input for adding, removing or creating metrics inside a track */
  metrics?: InputMaybe<UpdateTrackMetricsInput>;
  /** input for adding, removing or creating mindsets inside a track */
  mindsets?: InputMaybe<UpdateTrackMindsetsInput>;
  /** name of the track */
  name?: InputMaybe<Scalars['String']['input']>;
  /** input for creating or updating roles inside a track */
  orgRoles?: InputMaybe<UpdateTrackOrgRolesInput>;
  /** tagId for creating roles for track */
  roleTagId?: InputMaybe<Scalars['String']['input']>;
  /** input for removing, creating or editing skillGroups inside a track */
  skillGroups?: InputMaybe<UpdateTrackSkillGroupsInput>;
  /** input for adding, removing or creating skills inside a track */
  skills?: InputMaybe<UpdateTrackSkillsInput>;
  /** input to update dynamicUserGroup condition of track */
  syncConfig?: InputMaybe<FilterGroupV2InputType>;
  /** Id of the track */
  trackId: Scalars['String']['input'];
};

export type UpdateTrackMetricsInput = {
  /** ids of activities to add in track */
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ids of activities to remove from track */
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  /** activity metrics to be created and added to track */
  toCreateMetrics?: InputMaybe<Array<CreateMetricInput>>;
};

export type UpdateTrackMindsetsInput = {
  /** ids of mindsets to add in track */
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ids mindsets to remove from track */
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  /** mindsets to be created and added to track */
  toCreateMindsets?: InputMaybe<Array<CreateSkillInput>>;
};

export type UpdateTrackOrgRolesInput = {
  /** new roles to be created under track */
  toCreateOrgRoles?: InputMaybe<Array<CreateOrgRoleInput>>;
  /** input for editing orgRole name from inside a track */
  toUpdateOrgRoles?: InputMaybe<Array<UpdateOrgRoleInput>>;
};

export type UpdateTrackPayload = {
  __typename?: 'UpdateTrackPayload';
  createdMetrics?: Maybe<Array<MetricSchema>>;
  createdMindsets?: Maybe<Array<SkillType>>;
  createdSkillGroups?: Maybe<Array<SkillGroupType>>;
  createdSkills?: Maybe<Array<SkillType>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  track?: Maybe<TrackType>;
};

export type UpdateTrackSkillGroupsInput = {
  /** skillIds to be removed from track along with corresponding skillGroupIds */
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  /** input for creating skillGroup from inside a track */
  toCreateSkillGroups?: InputMaybe<Array<CreateSkillGroupInTrackInput>>;
  /** input for editing skillGroup name from inside a track */
  toEditSkillGroups?: InputMaybe<Array<EditSkillGroupInTrackInput>>;
};

export type UpdateTrackSkillsInput = {
  /** skillIds to be added in track along with corresponding skillGroupIds */
  add?: InputMaybe<Array<AddSkillsToSkillGroupInput>>;
  /** skillIds to be removed from track along with corresponding skillGroupIds */
  remove?: InputMaybe<Array<RemoveSkillsFromSkillGroupInput>>;
  /** input for creating skill from inside a track. The created skill will be attached to track and added to skillBank */
  toCreateSkills?: InputMaybe<Array<CreateSkillInTrackInput>>;
};

export type UpdateUserCollectionGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  groupId: Scalars['String']['input'];
  memberIds?: InputMaybe<GroupMembersIds>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  /** Cover image of the user */
  cover?: InputMaybe<CreateMediaInput>;
  /** to allow setting/unsetting properties.departmentId */
  department?: InputMaybe<DepartmentIdInput>;
  emailId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<UpdateGroupsOfUserInput>;
  /** User hierarchy updated at */
  hierarchyPathUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastActiveAt?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** to allow unsetting properties.orgRoleId */
  orgRole?: InputMaybe<OrgRoleIdInput>;
  orgRoleId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<UpdateUserPreferencesInput>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  reportsTo?: InputMaybe<ReportsToInput>;
  reportsToUserId?: InputMaybe<Scalars['String']['input']>;
  responsibilityIds?: InputMaybe<UpdateReponsibilitiesOfUserInput>;
  restrictedAccessConfig?: InputMaybe<RestrictedAccessConfigInput>;
  /** users social accounts info */
  socials?: InputMaybe<UpdateUserSocialAccountsInput>;
  status?: InputMaybe<UserStatusEnum>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export enum UpdateUserInviteAction {
  Cancel = 'CANCEL',
  Resend = 'RESEND'
}

export type UpdateUserInviteInput = {
  action: UpdateUserInviteAction;
  userId: Scalars['String']['input'];
};

export type UpdateUserNominationInput = {
  isOverride?: InputMaybe<Scalars['Boolean']['input']>;
  nominees?: InputMaybe<UpdateUserNomineesInput>;
  status?: InputMaybe<UserNominationStatusEnum>;
  userNominationId: Scalars['String']['input'];
};

export enum UpdateUserNominationNomineesCommandEnum {
  Remove = 'REMOVE',
  Set = 'SET'
}

export type UpdateUserNominationRequestInput = {
  status: UserNomineeApprovalStatusEnum;
  userNominationId: Scalars['String']['input'];
};

export type UpdateUserNomineesInput = {
  command: UpdateUserNominationNomineesCommandEnum;
  nominees: Array<UserNomineeInput>;
};

export type UpdateUserPreferencesInput = {
  set?: InputMaybe<SetUserPreferencesInput>;
};

export type UpdateUserSocialAccountsInput = {
  set: Array<UserSocialAccountInfoInput>;
};

export type UpdateUserSurveyInput = {
  adminConfig?: InputMaybe<UserSurveyAdminConfigInput>;
  anonymityThreshold?: InputMaybe<Scalars['Float']['input']>;
  automationConfig?: InputMaybe<AutomationConfigInput>;
  cadenceConfig?: InputMaybe<UserSurveyCadenceConfigInput>;
  coverOffset?: InputMaybe<Scalars['Float']['input']>;
  createdByType?: InputMaybe<UserSurveyCreatedByTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  dueDateSchedulerId?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  endMessageConfig?: InputMaybe<UserSurveyEndMessageConfigInput>;
  latestRecurrenceNumber?: InputMaybe<Scalars['Float']['input']>;
  media?: InputMaybe<CreateMediaInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationConfig?: InputMaybe<UserSurveyNotificationConfigInput>;
  participantsConfig?: InputMaybe<UserSurveyAdminConfigInput>;
  publishedAt?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserSurveyStatusEnum>;
  subWorkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  surveyId: Scalars['String']['input'];
  userSurveyCommentConfig?: InputMaybe<UserSurveyCommentConfigInput>;
  userSurveyEndReminderSchedulerId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserSurveyPayload = {
  __typename?: 'UpdateUserSurveyPayload';
  userSurvey?: Maybe<UserSurveyType>;
};

export type UpdateUserType = {
  hierarchyPath?: InputMaybe<Scalars['String']['input']>;
  hierarchyPathUpdatedAt?: InputMaybe<Scalars['String']['input']>;
  lastActiveAt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<UserPropertiesInput>;
  restrictedAccessConfig?: InputMaybe<RestrictedAccessConfigInput>;
  status?: InputMaybe<UserStatusEnum>;
  tagIdsToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsToSetAddRemove?: InputMaybe<EntityTagsInput>;
  userId: Scalars['String']['input'];
};

export type UpdateUsersInput = {
  users: Array<UpdateUserType>;
};

export type UpdateUsersStaffRolesInput = {
  roleIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type UpdateWorkspaceAdministrationConfigInput = {
  set: SetWorkspaceAdministrationConfigInput;
};

export type UpdateWorkspaceAuthConfigInput = {
  authPageUrl?: InputMaybe<Scalars['String']['input']>;
  background?: InputMaybe<AuthBackgroundInput>;
  restrictedSignUp?: InputMaybe<Scalars['Boolean']['input']>;
  signUpAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  strategyMethods?: InputMaybe<UpdateWorkspaceStrategyMethodsInput>;
  strategyMethodsListing?: InputMaybe<UpdateWorkspaceStrategyMethodsListingInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceInput = {
  administrationConfig?: InputMaybe<UpdateWorkspaceAdministrationConfigInput>;
  authConfig?: InputMaybe<UpdateWorkspaceAuthConfigInput>;
  samlData?: InputMaybe<UpdateSamlDataInput>;
  siteConfig?: InputMaybe<UpdateWorkspaceSiteConfigInput>;
  subDomain?: InputMaybe<Scalars['String']['input']>;
  whitelistedSignUpDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  workspaceId: Scalars['String']['input'];
};

export type UpdateWorkspaceSiteConfigInput = {
  favicon?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Media>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceStrategyMethodsInput = {
  set: Array<SetWorkspaceStrategyMethodInput>;
  unset: Array<UnsetWorkspaceStrategyMethodInput>;
};

export type UpdateWorkspaceStrategyMethodsListingInput = {
  set: Array<Scalars['String']['input']>;
};

export type UpdatedChildOkrOrder = {
  childOrder: Scalars['Float']['input'];
  okrId: Scalars['String']['input'];
};

export type UpsertAgendaItemInput = {
  agendaItemId?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<MediasSetInput>;
  creationCadenceConfig?: InputMaybe<UpdateCadenceConfigInput>;
  entity: AgendaItemEntityInput;
  /** Agenda Instructions in text or media */
  instruction?: InputMaybe<InstructionInput>;
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
  subType: ActionItemSubTypeEnum;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertCommunityInput = {
  /** id of community to update */
  communityId: Scalars['String']['input'];
  /** sections of community */
  sections: Array<SectionInfoInput>;
};

export type UpsertCourseInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  courseId?: InputMaybe<Scalars['String']['input']>;
  /** Storing media of course-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  dripSettings?: InputMaybe<DripSettingsInput>;
  /** duration of content */
  duration?: InputMaybe<Scalars['Float']['input']>;
  /** external and internal instructors of course */
  instructors?: InputMaybe<Array<CourseInstructorInputType>>;
  lastMajorVersionPublishedAt?: InputMaybe<Scalars['String']['input']>;
  /** level of course */
  level?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** outline of course containing sections with lessions */
  outline?: InputMaybe<CourseOutlineInput>;
  /** Ids of prerequisites related to the content */
  prerequisiteIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Storing media of course-preview */
  preview?: InputMaybe<CreateMediaInput>;
  /** self duration of content */
  selfDuration?: InputMaybe<Scalars['Float']['input']>;
  /** Ids of skill related to the course */
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Tagline for course */
  tagline?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Upsert Course-Content Response */
export type UpsertCoursePayload = {
  __typename?: 'UpsertCoursePayload';
  course?: Maybe<CourseContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Input for updating/creating a document inside a database */
export type UpsertDatabaseDataInput = {
  dbId: Scalars['String']['input'];
  documentId?: InputMaybe<Scalars['String']['input']>;
  fields: Array<DocumentFieldsInput>;
};

export type UpsertDatabaseDataPayload = {
  __typename?: 'UpsertDatabaseDataPayload';
  /** Document Id */
  documentId?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Input for updating/creating document fields inside a database */
export type UpsertDatabaseFieldsInput = {
  upsertDatabaseMetadataFields?: InputMaybe<UpsertDatabaseMetadataFieldsInput>;
  upsertDatabaseViewFields?: InputMaybe<UpsertDatabaseViewFieldsInput>;
};

export type UpsertDatabaseFieldsPayload = {
  __typename?: 'UpsertDatabaseFieldsPayload';
  /** Updated Database */
  dbMetadata?: Maybe<DatabaseMetadataType>;
  errorCode?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  /** Returns upserted View */
  view?: Maybe<DatabaseViewType>;
};

/** Defines fields to be added in database metadata */
export type UpsertDatabaseMetadataFieldsInput = {
  dbId?: InputMaybe<Scalars['String']['input']>;
  dbMetadataId?: InputMaybe<Scalars['String']['input']>;
  fields: Array<UpdateDbMetadataFields>;
};

/**  Defines fields to be added in database view */
export type UpsertDatabaseViewFieldsInput = {
  fields: Array<DatabaseViewFormatFields>;
  viewId: Scalars['String']['input'];
};

export type UpsertFlagSurveyResponseInput = {
  cycleId?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  filterGroup?: InputMaybe<FilterGroupInput>;
  flagAtLevel: FlagAtLevelEnum;
  flagOn: FlagOnEntityEnum;
  flagSurveyResponseId?: InputMaybe<Scalars['String']['input']>;
  parentEntities?: InputMaybe<Array<Scalars['String']['input']>>;
  performanceReviewMetadata?: InputMaybe<Array<FlaggedPerformanceReviewMetadataInput>>;
  sanitizedFilter?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FlagSurveyResponseStatusEnum>;
  surveyId: Scalars['String']['input'];
  surveyResponseMetadata?: InputMaybe<Array<FlaggedSurveyResponseMetadataInput>>;
};

export type UpsertFlagSurveyResponsePayload = {
  __typename?: 'UpsertFlagSurveyResponsePayload';
  flagSurveyResponse?: Maybe<FlagSurveyResponseType>;
};

export type UpsertIntegrationDetailInput = {
  authSettings?: InputMaybe<Scalars['String']['input']>;
  connectedAt?: InputMaybe<Scalars['String']['input']>;
  connectedById?: InputMaybe<Scalars['String']['input']>;
  integrationProviderId: Scalars['String']['input'];
  scope?: InputMaybe<IntegrationScopeEnum>;
  settings?: InputMaybe<IntegrationSettingsInput>;
  status?: InputMaybe<IntegrationStatusEnum>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum UpsertOkrCommandEnum {
  Create = 'CREATE',
  Update = 'UPDATE'
}

export type UpsertOkrConfigInputProps = {
  createKeyResultInput?: InputMaybe<CreateKeyResultInput>;
  updateKeyResultInput?: InputMaybe<UpdateKeyResultInput>;
};

export type UpsertOkrInput = {
  command: UpsertOkrCommandEnum;
  inputs: UpsertOkrConfigInputProps;
};

export type UpsertPageInput = {
  components: SetPageComponentsInput;
  type: PageTypeEnum;
};

export type UpsertPagePayload = {
  __typename?: 'UpsertPagePayload';
  page: PageType__Next;
};

export type UpsertScorecardReviewInput = {
  entityId: Scalars['String']['input'];
  entityType: Scalars['String']['input'];
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
  response?: InputMaybe<Scalars['Float']['input']>;
  scorecardId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UpsertScorecardReviewPayload = {
  __typename?: 'UpsertScorecardReviewPayload';
  currentReviewingState?: Maybe<CurrentReviewingState>;
  /** Error code */
  errorCode?: Maybe<PerformanceInteractionErrorCodeEnum>;
  scorecardReview?: Maybe<ScorecardReviewType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpsertStatusInput = {
  applicableOn?: InputMaybe<StatusApplicableOnEnum>;
  category?: InputMaybe<StatusCategoryEnum>;
  /** id of the default status from which current status is created */
  defaultStatusId?: InputMaybe<Scalars['String']['input']>;
  /** Status on which EntityId to be applicable  */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** Status on which EntityId to be applicable  */
  entityType?: InputMaybe<CustomStatusEntityTypeEnum>;
  icon?: InputMaybe<IconSettingsSetInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Stores the order of status */
  order?: InputMaybe<Scalars['Float']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  statusId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertStatusesInput = {
  statuses: Array<UpsertStatusInput>;
};

export type UpsertUserActivityMetricInput = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  actualValue: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  metricId: Scalars['String']['input'];
  metricMeta?: InputMaybe<Array<UserActivityMetricParentDocMetaInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  parentDocumentConfig?: InputMaybe<UserActivityMetricParentDocInputConfig>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  targetValue: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  updatedById?: InputMaybe<Scalars['String']['input']>;
  userActivityMetricId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpsertUserActivityMetricsInput = {
  upsertUserActivityMetrics: Array<UpsertUserActivityMetricInput>;
};

export type UpsertUserAnnouncementInput = {
  announcementId: Scalars['String']['input'];
  completedAt?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  snoozeBy?: InputMaybe<UserAnnouncementSnoozeUntilEnum>;
  startedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertUserAnnouncementPayload = {
  __typename?: 'UpsertUserAnnouncementPayload';
  userAnnouncement: UserAnnouncementType;
};

export type UpsertUserViewInput = {
  applyToAll: Scalars['Boolean']['input'];
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<UserViewEntityTypeEnum>;
  fields: Array<UserViewFieldsInputSchema>;
  userEntityActionsInput?: InputMaybe<UserEntityActionsInput>;
  userViewId?: InputMaybe<Scalars['String']['input']>;
  viewType: UserViewTypeEnum;
};

export type UpsertWidgetsInput = {
  createOrUpdateWidgetsInput: Array<CreateOrUpdateWidgetInput>;
};

export type UpsertWidgetsPayload = {
  __typename?: 'UpsertWidgetsPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
  widgetIds: Array<Scalars['String']['output']>;
};

export type UpwardReviewResultDeliverySettings = {
  __typename?: 'UpwardReviewResultDeliverySettings';
  /** share results to reviewee Anonymously */
  shareAnonymously?: Maybe<Scalars['Boolean']['output']>;
  /** share results to reviewee's manager anonymously */
  shareWithManagerAnonymously?: Maybe<Scalars['Boolean']['output']>;
};

export type UpwardReviewResultDeliverySettingsInput = {
  /** share results to reviewee Anonymously */
  shareAnonymously?: InputMaybe<Scalars['Boolean']['input']>;
  /** share results to reviewee's manager anonymously */
  shareWithManagerAnonymously?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpwardReviewSettingsInput = {
  mindsetReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
  questions?: InputMaybe<Array<ReviewCycleQuestionInput>>;
  resultDeliverySettings?: InputMaybe<UpwardReviewResultDeliverySettingsInput>;
  reviewInstruction?: InputMaybe<InstructionInput>;
  skillReviewSettings?: InputMaybe<ReviewCycleSkillSettingsInput>;
};

export type UpwardReviewSettingsType = {
  __typename?: 'UpwardReviewSettingsType';
  mindsetReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
  questions?: Maybe<Array<ReviewCycleQuestionType>>;
  resultDeliverySettings?: Maybe<UpwardReviewResultDeliverySettings>;
  /** instructions for reviewers from admin */
  reviewInstruction?: Maybe<InstructionType>;
  skillReviewSettings?: Maybe<ReviewCycleSkillSettingsType>;
};

export type UrlsSetInput = {
  /** Urls to be set */
  set: Array<Scalars['String']['input']>;
};

export type UrlsV2SetInput = {
  /** Urls to be set */
  set: Array<PostUrlInput>;
};

export enum UserActionEnum {
  ForceComplete = 'FORCE_COMPLETE',
  ResetProgress = 'RESET_PROGRESS'
}

/** Base Performance Type */
export type UserActionMetaType = {
  __typename?: 'UserActionMetaType';
  action: UserActionEnum;
  meta?: Maybe<Scalars['JSON']['output']>;
  timestamp: Scalars['String']['output'];
  /** Id of the user who performed the action */
  userId: Scalars['String']['output'];
};

/** Edge */
export type UserActivityMetricEdge = {
  __typename?: 'UserActivityMetricEdge';
  node: UserActivityMetricType;
};

export type UserActivityMetricParentDocConfig = {
  __typename?: 'UserActivityMetricParentDocConfig';
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<AutomaticMetricTypeEnum>;
  meta?: Maybe<Array<UserActivityMetricParentDocMetaType>>;
};

export type UserActivityMetricParentDocInputConfig = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<AutomaticMetricTypeEnum>;
  meta?: InputMaybe<Array<UserActivityMetricParentDocMetaInput>>;
};

export type UserActivityMetricParentDocMetaInput = {
  key: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type UserActivityMetricParentDocMetaType = {
  __typename?: 'UserActivityMetricParentDocMetaType';
  key: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type UserActivityMetricType = {
  __typename?: 'UserActivityMetricType';
  activityId?: Maybe<Scalars['String']['output']>;
  actualValue: Scalars['Float']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  endTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metricId: Scalars['String']['output'];
  metricMeta?: Maybe<Array<UserActivityMetricParentDocMetaType>>;
  /** Log progress notes for Habit type metrics */
  notes?: Maybe<Scalars['String']['output']>;
  parentDocumentConfig?: Maybe<UserActivityMetricParentDocConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime?: Maybe<Scalars['String']['output']>;
  targetValue: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type UserActivityMetricsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  endTime?: InputMaybe<DateFilter>;
  endTimeExists?: InputMaybe<Scalars['Boolean']['input']>;
  metricIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTime?: InputMaybe<DateFilter>;
  userActivityMetricIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** User Affiliations */
export type UserAffiliations = {
  __typename?: 'UserAffiliations';
  customerId?: Maybe<Scalars['String']['output']>;
  type: AffiliationsTypeEnum;
  userId?: Maybe<Scalars['String']['output']>;
};

/** Edge */
export type UserAnnouncementEdge = {
  __typename?: 'UserAnnouncementEdge';
  node: UserAnnouncementType;
};

export type UserAnnouncementFilters = {
  announcementId?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userSearchText?: InputMaybe<Scalars['String']['input']>;
};

export enum UserAnnouncementSnoozeUntilEnum {
  Now = 'NOW',
  ThirtyMinutes = 'THIRTY_MINUTES'
}

export type UserAnnouncementType = {
  __typename?: 'UserAnnouncementType';
  announcement?: Maybe<AnnouncementType>;
  announcementId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  progress?: Maybe<Scalars['Int']['output']>;
  snoozeUntil?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedById?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
  userId: Scalars['String']['output'];
};

/** Aggregated points of a user in classroom */
export type UserBehaviorStatsType = {
  __typename?: 'UserBehaviorStatsType';
  /** Behavior Id of the behavior for which points been awarded in a classroom  */
  behaviorId: Scalars['String']['output'];
  /** Total number of times points has been awarded for the behavior */
  count: Scalars['Float']['output'];
};

/** Latest time at which a user opened the notification bell icon */
export type UserBellNotification = {
  __typename?: 'UserBellNotification';
  lastResolvedNotificationTimestamp?: Maybe<Scalars['String']['output']>;
};

export type UserBellNotificationSubscriptionInput = {
  /** operations to subscribe on */
  operations: Array<UserSubscriptionOperationEnum>;
  userTimezone?: InputMaybe<Scalars['String']['input']>;
};

export type UserBellNotificationsSubscriptionPayload = {
  __typename?: 'UserBellNotificationsSubscriptionPayload';
  eventTriggeredAt: Scalars['String']['output'];
  lastResolvedTimeStamp?: Maybe<Scalars['String']['output']>;
  operation: UserSubscriptionOperationEnum;
  type: UserBellNotificationsSubscriptionPayloadTypeEnum;
  unreadNotificationsCount: Scalars['Float']['output'];
  unresolvedNotificationsCount: Scalars['Float']['output'];
  userNotification?: Maybe<BaseUserNotification>;
};

export enum UserBellNotificationsSubscriptionPayloadTypeEnum {
  AllNotificationsRead = 'ALL_NOTIFICATIONS_READ',
  BellNotificationAdded = 'BELL_NOTIFICATION_ADDED',
  BellNotificationUpdated = 'BELL_NOTIFICATION_UPDATED',
  LastResolvedUpdated = 'LAST_RESOLVED_UPDATED'
}

/** User Permission */
export type UserChannelPermissionType = {
  __typename?: 'UserChannelPermissionType';
  /** Action type */
  action: ChannelActionsEnum;
  /** Subject type */
  subject: ChannelSubjectsEnum;
};

/** User Permission */
export type UserClassroomEventPermissionType = {
  __typename?: 'UserClassroomEventPermissionType';
  /** Action type */
  action: ClassroomEventActionsEnum;
  /** Subject type */
  subject: ClassroomEventSubjectsEnum;
};

/** User Permission */
export type UserClassroomPermissionType = {
  __typename?: 'UserClassroomPermissionType';
  /** Action type */
  action: ClassroomActionsEnum;
  /** Subject type */
  subject: ClassroomSubjectsEnum;
};

export type UserCollectionGroupPayload = {
  __typename?: 'UserCollectionGroupPayload';
  group: UserCollectionGroupType;
};

export type UserCollectionGroupPropsInput = {
  classroomId?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<UserCollectionGroupTypeEnum>;
};

export type UserCollectionGroupType = {
  __typename?: 'UserCollectionGroupType';
  /** This is tagId:tagValueId, denotes the combination for which the group is automatically created when new tag value is added */
  autoGroupTagValueId?: Maybe<Scalars['String']['output']>;
  classroomId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  filterGroup?: Maybe<FilterGroupV2Type>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isAutoSyncGroup: Scalars['Boolean']['output'];
  metricSnapshot?: Maybe<MetricSnapshot>;
  name: Scalars['String']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  reSyncedAt?: Maybe<Scalars['String']['output']>;
  sampleUsers: Array<UserType>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: GroupStatusEnum;
  subType: UserCollectionGroupTypeEnum;
  type: GroupTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount: Scalars['Float']['output'];
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type UserCollectionGroupTypeIsAutoSyncArgs = {
  userId: Scalars['String']['input'];
};

export enum UserCollectionGroupTypeEnum {
  Admin = 'ADMIN',
  Author = 'AUTHOR',
  Custom = 'CUSTOM',
  Learner = 'LEARNER',
  LyearnUser = 'LYEARN_USER',
  Staff = 'STAFF'
}

/** This returns paginated users */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<UserEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Filters applicable on user cycle review */
export type UserCycleReviewFilters = {
  reviewCycleId: Scalars['String']['input'];
};

/** UserCycleReviewPayload */
export type UserCycleReviewPayload = {
  __typename?: 'UserCycleReviewPayload';
  exists: Scalars['Boolean']['output'];
};

/** Cycle Reviews */
export type UserCycleReviewShareStatusDistribution = {
  __typename?: 'UserCycleReviewShareStatusDistribution';
  count: Scalars['Float']['output'];
  fieldValue: Scalars['String']['output'];
};

export type UserCycleReviewShareStatusDistributionInput = {
  reviewCycleId: Scalars['String']['input'];
};

export type UserCycleReviewShareStatusDistributionPayload = {
  __typename?: 'UserCycleReviewShareStatusDistributionPayload';
  counts: Array<UserCycleReviewShareStatusDistribution>;
};

/** UserCycleReviewType Schema */
export type UserCycleReviewType = {
  __typename?: 'UserCycleReviewType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parentReviewCycleId?: Maybe<Scalars['String']['output']>;
  /** overall scores for user in a cycle */
  resultScoreMetadata?: Maybe<ReviewCycleResultScoreMetadata>;
  /** overall scores for user in a cycle */
  resultShareInfo?: Maybe<ReviewCycleResultShareInfo>;
  /** review cycle instance */
  reviewCycleId: Scalars['String']['output'];
  /** reviewee */
  reviewee?: Maybe<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** sharedBy user profile */
  sharer?: Maybe<UserType>;
  /** status of the reviewCycle for a user */
  status?: Maybe<ReviewCycleStatusEnum>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** user who is getting reviewed */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** Edge */
export type UserEdge = {
  __typename?: 'UserEdge';
  node: UserType;
};

export type UserEntityActionsInput = {
  targetEntityId?: InputMaybe<Scalars['String']['input']>;
  targetEntityType: FocusAreaEnum;
};

export type UserEntityPerformanceSubscriptionInput = {
  /** entityIds of performance */
  entityIds: Array<Scalars['String']['input']>;
  /** userId of performance */
  userId: Scalars['String']['input'];
};

/** User Event Permission */
export type UserEventPermissionType = {
  __typename?: 'UserEventPermissionType';
  /** Action type */
  action: EventActions;
  /** Subject type */
  subject: EventSubjects;
};

/** Filters applicable on user */
export type UserFilters = {
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter user based on its email id */
  emailId?: InputMaybe<Scalars['String']['input']>;
  /** Filter user based on its federation id */
  federationId?: InputMaybe<Scalars['String']['input']>;
  /** Filter user based on any of the users unique id */
  uniqueId?: InputMaybe<UniqueIdFilter>;
  /** Filter user based on its id */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserGroupMemberImportMetadataType = {
  __typename?: 'UserGroupMemberImportMetadataType';
  createdGroupsCount: Scalars['Float']['output'];
  updatedGroupsCount: Scalars['Float']['output'];
};

export type UserGroupSyncAssignmentConfigs = {
  __typename?: 'UserGroupSyncAssignmentConfigs';
  autoAssignGroupUsers: Scalars['Boolean']['output'];
  deactivateFrom?: Maybe<Scalars['String']['output']>;
};

export type UserGroupSyncAssignmentConfigsInput = {
  autoAssignGroupUsers: Scalars['Boolean']['input'];
  deactivateFrom?: InputMaybe<Scalars['String']['input']>;
};

/** The User model */
export type UserInfoType = {
  __typename?: 'UserInfoType';
  alias?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  emailId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use name field */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated Use name field */
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type UserJourneyPerformanceLevelMeta = {
  __typename?: 'UserJourneyPerformanceLevelMeta';
  completedLevels: Scalars['Int']['output'];
  totalLevels: Scalars['Int']['output'];
};

/** Metadata for user fields */
export type UserMetadataInput = {
  emailId?: InputMaybe<Scalars['String']['input']>;
  federationId: Scalars['String']['input'];
  /** Firstname (space) Lastname of the user */
  name: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserNominationCountDistribution = {
  __typename?: 'UserNominationCountDistribution';
  count: Scalars['Float']['output'];
  fieldValue: Scalars['String']['output'];
};

export type UserNominationCountDistributionInput = {
  filters: UserNominationsFilters;
};

export type UserNominationCountDistributionPayload = {
  __typename?: 'UserNominationCountDistributionPayload';
  counts: Array<UserNominationCountDistribution>;
};

/** Edge */
export type UserNominationEdge = {
  __typename?: 'UserNominationEdge';
  node: UserNominationType;
};

export type UserNominationFilters = {
  userNominationId: Scalars['String']['input'];
};

export type UserNominationPayload = {
  __typename?: 'UserNominationPayload';
  userNomination?: Maybe<UserNominationType>;
};

export enum UserNominationStatusEnum {
  Approved = 'APPROVED',
  InReview = 'IN_REVIEW',
  NotStarted = 'NOT_STARTED'
}

export type UserNominationType = {
  __typename?: 'UserNominationType';
  /** Used to show check in sidebar when all nominations are approved */
  allApprovalsDone: Scalars['Boolean']['output'];
  /** Date of latest approval */
  approvedAt?: Maybe<Scalars['String']['output']>;
  approverUser?: Maybe<UserType>;
  approverUserId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  newNomineesAdded: Scalars['Boolean']['output'];
  nominees: Array<UserNomineeType>;
  nomineesCount: Scalars['Float']['output'];
  reviewCycle?: Maybe<ReviewCycleType>;
  reviewCycleId: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNominationStatusEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** Reviewee User */
  user?: Maybe<UserType>;
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type UserNominationsFilters = {
  approverUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  nomineeUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reviewCycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<UserNominationStatusEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userNominationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userSearchText?: InputMaybe<Scalars['String']['input']>;
};

export enum UserNomineeApprovalStatusEnum {
  Approved = 'APPROVED',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED'
}

export type UserNomineeInput = {
  approvalStatus: UserNomineeApprovalStatusEnum;
  userId: Scalars['String']['input'];
};

export type UserNomineeType = {
  __typename?: 'UserNomineeType';
  approvalStatus: UserNomineeApprovalStatusEnum;
  isNewlyAdded: Scalars['Boolean']['output'];
  user?: Maybe<UserType>;
  userId: Scalars['String']['output'];
};

/** UserNotificationsPayloadEdge */
export type UserNotificationPayloadEdge = {
  __typename?: 'UserNotificationPayloadEdge';
  node: BaseUserNotification;
};

export enum UserNotificationStatusEnum {
  Read = 'READ',
  Unread = 'UNREAD'
}

export type UserNotificationsFilters = {
  types?: InputMaybe<Array<NotificationActionTypeEnum>>;
  userTimezone?: InputMaybe<Scalars['String']['input']>;
};

/** User OKR Cycle Permission */
export type UserOkrCyclePermissionType = {
  __typename?: 'UserOKRCyclePermissionType';
  /** Action type */
  action: OkrCycleActions;
  /** Subject type */
  subject: OkrCycleSubjects;
};

/** User Objective KeyResult Permission */
export type UserOkrPermissionType = {
  __typename?: 'UserOKRPermissionType';
  /** OKR Action type */
  action: OkrActions;
  /** OKR Subject type */
  subject: OkrSubjects;
};

export type UserOrFilter = {
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  groupIds: Array<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type UserPreferencesType = {
  __typename?: 'UserPreferencesType';
  showMarketplaceContentCatalogBanner?: Maybe<Scalars['Boolean']['output']>;
};

/** User Product Permission */
export type UserProductPermissionType = {
  __typename?: 'UserProductPermissionType';
  /** Action type */
  action: ProductActionsEnum;
  /** Subject type */
  subject: ProductSubjectsEnum;
};

/** User Properties */
export type UserProfile = {
  __typename?: 'UserProfile';
  alias?: Maybe<Scalars['String']['output']>;
  /** email id of the user */
  emailId?: Maybe<Scalars['String']['output']>;
  /** federation id of the user */
  federationId?: Maybe<Scalars['String']['output']>;
  /**
   * first name of the user
   * @deprecated Use name field
   */
  firstName?: Maybe<Scalars['String']['output']>;
  /** id of the user */
  id: Scalars['String']['output'];
  /** is user learner: true or false */
  isLearner: Scalars['Boolean']['output'];
  isPerformanceReviewCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** is user staff: true or false */
  isStaff: Scalars['Boolean']['output'];
  /**
   * last name of the user
   * @deprecated Use name field
   */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Full name of the user */
  name?: Maybe<Scalars['String']['output']>;
  /** url of the profile picture of the user */
  profilePicture?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['String']['output'];
};


/** User Properties */
export type UserProfileIsPerformanceReviewCompletedArgs = {
  filters?: InputMaybe<IsPerformanceReviewCompletedInput>;
};

/** User Properties */
export type UserPropertiesInput = {
  departmentId?: InputMaybe<Scalars['String']['input']>;
  orgRoleId?: InputMaybe<Scalars['String']['input']>;
  responsibilityConfigs?: InputMaybe<Array<UserResponsibilityConfigsTypeInput>>;
  syncedFromIntegrationProviderId?: InputMaybe<Scalars['String']['input']>;
  trackId?: InputMaybe<Scalars['String']['input']>;
};

/** User Properties */
export type UserPropertiesType = {
  __typename?: 'UserPropertiesType';
  /** DEPRECATED: use responsibilityConfigs -> departmentId instead */
  departmentId?: Maybe<Scalars['String']['output']>;
  /** Old: orgRoleId; New: storing designationId of orgRoles collection */
  orgRoleId?: Maybe<Scalars['String']['output']>;
  responsibilityConfigs?: Maybe<Array<UserResponsibilityConfigsType>>;
  /** Integration provider id from where the user was synced in Lyearn */
  syncedFromIntegrationProviderId?: Maybe<Scalars['String']['output']>;
  syncedFromProvider?: Maybe<IntegrationProviderType>;
  /** DEPRECATED: trackId of orgRole, use responsibilityConfigs -> trackId instead */
  trackId?: Maybe<Scalars['String']['output']>;
};

/** Edge */
export type UserPublicApiCredentialsEdge = {
  __typename?: 'UserPublicAPICredentialsEdge';
  node: UserPublicApiCredentialsType;
};

export type UserPublicApiCredentialsFilters = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  secret?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPublicAPICredentialsId?: InputMaybe<Scalars['String']['input']>;
};

export type UserPublicApiCredentialsType = {
  __typename?: 'UserPublicAPICredentialsType';
  /** ClientId against which this secret can be mapped */
  clientId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  /** Description for the generated secret */
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Unique identifyer through which a user can identify a generated secret */
  name: Scalars['String']['output'];
  /** Encrypted version of secret through which user can generate public api access token */
  secret: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** UserId for which the secret is valid */
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

/** User Resource Permission */
export type UserResourcePermissionType = {
  __typename?: 'UserResourcePermissionType';
  /** Action type */
  action: ResourceActions;
  /** Subject type */
  subject: ResourceSubjects;
};

/** User Responsibility Configs */
export type UserResponsibilityConfigsType = {
  __typename?: 'UserResponsibilityConfigsType';
  departmentId?: Maybe<Scalars['String']['output']>;
  responsibilityId: Scalars['String']['output'];
  syncedFrom?: Maybe<ResponsibilitySyncedFromEnum>;
  /** trackId of responsibility */
  trackId?: Maybe<Scalars['String']['output']>;
};

/** User ResponsibilityConfigs */
export type UserResponsibilityConfigsTypeInput = {
  departmentId?: InputMaybe<Scalars['String']['input']>;
  responsibilityId: Scalars['String']['input'];
  syncedFrom?: InputMaybe<ResponsibilitySyncedFromEnum>;
  /** trackId of responsibility */
  trackId?: InputMaybe<Scalars['String']['input']>;
};

export enum UserReviewCycleContextEnum {
  Self = 'SELF',
  Team = 'TEAM'
}

export type UserRolePayload = {
  __typename?: 'UserRolePayload';
  roleId: Scalars['String']['output'];
  user: UserInfoType;
};

/** Filters for userSearchActivities of the context user */
export type UserSearchActivitiesFilters = {
  /** searchText to give relevant results */
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type UserSearchActivityConnections = {
  __typename?: 'UserSearchActivityConnections';
  edges?: Maybe<Array<UserSearchActivityEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Float']['output'];
};

/** Edge */
export type UserSearchActivityEdge = {
  __typename?: 'UserSearchActivityEdge';
  node: UserSearchActivityType;
};

/** The User model */
export type UserSearchActivityType = {
  __typename?: 'UserSearchActivityType';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** search text input of the user */
  searchText: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export enum UserSignupTypeEnum {
  Apple = 'APPLE',
  EmailPassword = 'EMAIL_PASSWORD',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Linkedin = 'LINKEDIN',
  PublicApi = 'PUBLIC_API',
  Saml = 'SAML',
  Sso = 'SSO'
}

/** Filters applicable on userSkillReview */
export type UserSkillsReviewFilters = {
  reviewCycleId: Scalars['String']['input'];
  reviewerId: Scalars['String']['input'];
};

/** UserSkillsReviewPayload */
export type UserSkillsReviewPayload = {
  __typename?: 'UserSkillsReviewPayload';
  completed: Scalars['Boolean']['output'];
};

export type UserSocialAccountInfo = {
  __typename?: 'UserSocialAccountInfo';
  link: Scalars['String']['output'];
  type: UserSocialAccountTypeEnum;
};

export type UserSocialAccountInfoInput = {
  link: Scalars['String']['input'];
  type: UserSocialAccountTypeEnum;
};

export enum UserSocialAccountTypeEnum {
  Github = 'GITHUB',
  Linkedin = 'LINKEDIN',
  Skype = 'SKYPE',
  Slack = 'SLACK'
}

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  Invited = 'INVITED'
}

export enum UserSubscriptionOperationEnum {
  UserArchived = 'USER_ARCHIVED',
  UserBellNotification = 'USER_BELL_NOTIFICATION',
  UserPasswordUpdateLogout = 'USER_PASSWORD_UPDATE_LOGOUT'
}

export enum UserSurveyActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE',
  View = 'VIEW'
}

export type UserSurveyAdminConfig = {
  __typename?: 'UserSurveyAdminConfig';
  assignToEveryone?: Maybe<Scalars['Boolean']['output']>;
  public?: Maybe<Scalars['Boolean']['output']>;
  userGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserSurveyAdminConfigInput = {
  assignToEveryone?: InputMaybe<Scalars['Boolean']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  userGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserSurveyCadenceConfigInput = {
  custom?: InputMaybe<CustomFrequencyEnum>;
  endDateConfig?: InputMaybe<UserSurveyCadenceEndDateConfigInput>;
  frequency: CronFrequencyEnum;
  startDate?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UserSurveyCadenceConfigType = {
  __typename?: 'UserSurveyCadenceConfigType';
  custom?: Maybe<CustomFrequencyEnum>;
  endDateConfig?: Maybe<UserSurveyCadenceEndDateConfig>;
  frequency: CronFrequencyEnum;
  startDate?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type UserSurveyCadenceEndDateConfig = {
  __typename?: 'UserSurveyCadenceEndDateConfig';
  cycleValue?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  endDateType: UserSurveyEndDateTypeEnum;
};

export type UserSurveyCadenceEndDateConfigInput = {
  cycleValue?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  endDateType: UserSurveyEndDateTypeEnum;
};

export type UserSurveyCommentConfig = {
  __typename?: 'UserSurveyCommentConfig';
  allowManagerReply: Scalars['Boolean']['output'];
  viewIndirectComments: Scalars['Boolean']['output'];
};

export type UserSurveyCommentConfigInput = {
  allowManagerReply?: InputMaybe<Scalars['Boolean']['input']>;
  viewIndirectComments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSurveyCommentEdge = {
  __typename?: 'UserSurveyCommentEdge';
  node: PostType;
};

export type UserSurveyCommentsFilter = {
  questionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  themeId?: InputMaybe<Scalars['String']['input']>;
  userSurveyId: Scalars['String']['input'];
};

export type UserSurveyConditionActionConfig = {
  __typename?: 'UserSurveyConditionActionConfig';
  actionType: UserSurveyQuestionConditionActionTypeEnum;
  properties?: Maybe<Array<ConditionActionPropertyType>>;
};

export type UserSurveyConditionActionConfigInput = {
  actionType: UserSurveyQuestionConditionActionTypeEnum;
  properties?: InputMaybe<Array<ConditionActionPropertyInput>>;
};

export type UserSurveyConditionConfig = {
  __typename?: 'UserSurveyConditionConfig';
  actions: Array<UserSurveyConditionActionConfig>;
  conditions: Array<FilterD2Type>;
  operator: FilterOperatorEnum;
};

export type UserSurveyConditionConfigInput = {
  actions: Array<UserSurveyConditionActionConfigInput>;
  conditions: Array<FilterD2Input>;
  operator: FilterOperatorEnum;
};

export enum UserSurveyCreatedByTypeEnum {
  User = 'USER',
  Workspace = 'WORKSPACE'
}

export type UserSurveyCycleDateRangeConfig = {
  __typename?: 'UserSurveyCycleDateRangeConfig';
  cycleNumber: Scalars['Float']['output'];
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
};

/** Edge */
export type UserSurveyEdge = {
  __typename?: 'UserSurveyEdge';
  node: UserSurveyType;
};

export enum UserSurveyEndDateTypeEnum {
  AfterFewCycle = 'AFTER_FEW_CYCLE',
  SpecificDate = 'SPECIFIC_DATE'
}

export type UserSurveyEndMessageConfig = {
  __typename?: 'UserSurveyEndMessageConfig';
  message?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type UserSurveyEndMessageConfigInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UserSurveyFilter = {
  id?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
};

export type UserSurveyNotificationConfig = {
  __typename?: 'UserSurveyNotificationConfig';
  description: UserSurveyNotificationDescriptionType;
  sendEmail: Scalars['Boolean']['output'];
  subject: Scalars['String']['output'];
};

export type UserSurveyNotificationConfigInput = {
  description?: InputMaybe<UserSurveyNotificationDescriptionTypeInput>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UserSurveyNotificationDescriptionType = {
  __typename?: 'UserSurveyNotificationDescriptionType';
  text: Scalars['String']['output'];
};

export type UserSurveyNotificationDescriptionTypeInput = {
  text: Scalars['String']['input'];
};

export type UserSurveyPageConfig = {
  __typename?: 'UserSurveyPageConfig';
  title?: Maybe<Scalars['String']['output']>;
};

export enum UserSurveyQueryIntentEnum {
  List = 'LIST',
  Read = 'READ'
}

export enum UserSurveyQuestionActionEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export enum UserSurveyQuestionBlockTypeEnum {
  Condition = 'CONDITION',
  Question = 'QUESTION'
}

export enum UserSurveyQuestionConditionActionTypeEnum {
  JumpTo = 'JUMP_TO',
  RequireAnswer = 'REQUIRE_ANSWER',
  RequireComment = 'REQUIRE_COMMENT',
  ShowQuestion = 'SHOW_QUESTION'
}

export type UserSurveyQuestionConfig = {
  __typename?: 'UserSurveyQuestionConfig';
  questionCount: Scalars['Float']['output'];
  surveyQuestions: Array<UserSurveyQuestionType>;
};

export type UserSurveyQuestionSettingsConfig = {
  __typename?: 'UserSurveyQuestionSettingsConfig';
  allowAttachmentsInResponse?: Maybe<Scalars['Boolean']['output']>;
  allowComments: Scalars['Boolean']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isOptional: Scalars['Boolean']['output'];
};

export type UserSurveyQuestionSettingsConfigInput = {
  allowAttachmentsInResponse?: InputMaybe<Scalars['Boolean']['input']>;
  allowComments: Scalars['Boolean']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isOptional: Scalars['Boolean']['input'];
};

export type UserSurveyQuestionSettingsInputConfig = {
  allowAttachmentsInResponse?: InputMaybe<Scalars['Boolean']['input']>;
  allowComments: Scalars['Boolean']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isOptional: Scalars['Boolean']['input'];
};

export type UserSurveyQuestionType = {
  __typename?: 'UserSurveyQuestionType';
  blockType?: Maybe<UserSurveyQuestionBlockTypeEnum>;
  commentTitle?: Maybe<Scalars['String']['output']>;
  conditionConfigs?: Maybe<Array<UserSurveyConditionConfig>>;
  conditional?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  pageId?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<UserSurveyQuestionSettingsConfig>;
  surveyId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedById?: Maybe<Scalars['String']['output']>;
};

export type UserSurveyResponseEdge = {
  __typename?: 'UserSurveyResponseEdge';
  node: UserSurveyResponseType;
};

export enum UserSurveyResponseExportFieldEnum {
  AttemptId = 'ATTEMPT_ID',
  Department = 'DEPARTMENT',
  Email = 'EMAIL',
  FullName = 'FULL_NAME',
  LearnerId = 'LEARNER_ID',
  Question = 'QUESTION',
  QuestionResponse = 'QUESTION_RESPONSE',
  QuestionType = 'QUESTION_TYPE',
  RecurrenceNumber = 'RECURRENCE_NUMBER',
  RemarkAttachments = 'REMARK_ATTACHMENTS',
  ResponseAttachments = 'RESPONSE_ATTACHMENTS',
  ResponseComments = 'RESPONSE_COMMENTS',
  SubmittedOn = 'SUBMITTED_ON',
  UniqueId = 'UNIQUE_ID'
}

export type UserSurveyResponseExportInputPropsType = {
  __typename?: 'UserSurveyResponseExportInputPropsType';
  filters: UserSurveyResponsesFiltersType;
  requiredFields: UserSurveyResponseExportRequiredFieldsType;
};

export type UserSurveyResponseExportMetadataType = {
  __typename?: 'UserSurveyResponseExportMetadataType';
  exportedCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type UserSurveyResponseExportPropsInput = {
  filters: UserSurveyResponsesFilter;
  requiredFields: UserSurveyResponseExportRequiredFieldsInput;
};

export type UserSurveyResponseExportRequiredFieldsInput = {
  fields: Array<UserSurveyResponseExportFieldEnum>;
};

export type UserSurveyResponseExportRequiredFieldsType = {
  __typename?: 'UserSurveyResponseExportRequiredFieldsType';
  fields: Array<UserSurveyResponseExportFieldEnum>;
};

export enum UserSurveyResponseStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type UserSurveyResponseType = {
  __typename?: 'UserSurveyResponseType';
  attemptId?: Maybe<Scalars['String']['output']>;
  attemptedQuestionsCount?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  cycleId?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parentEntityId: Scalars['String']['output'];
  post?: Maybe<PostType>;
  postId?: Maybe<Scalars['String']['output']>;
  recurrenceNumber?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<UserSurveyResponseStatusEnum>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  survey?: Maybe<UserSurveyType>;
  surveyQuestionsCount?: Maybe<Scalars['Int']['output']>;
  surveyResponse?: Maybe<SurveyResponseType>;
  themeId?: Maybe<Scalars['String']['output']>;
  themesScore?: Maybe<Array<ThemeScoreType>>;
  type?: Maybe<UserSurveyResponseTypeEnum>;
  updatedAt: Scalars['String']['output'];
  updatedById?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum UserSurveyResponseTypeEnum {
  Question = 'QUESTION',
  Survey = 'SURVEY'
}

/** Filters of user survey responses entity */
export type UserSurveyResponsesFilter = {
  attemptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cycleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  parentEntityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  recurrenceNumbers?: InputMaybe<Array<Scalars['Float']['input']>>;
  status?: InputMaybe<Array<UserSurveyResponseStatusEnum>>;
  surveyCreatedById?: InputMaybe<Array<Scalars['String']['input']>>;
  surveyTypes?: InputMaybe<Array<UserSurveyTypeEnum>>;
  themeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<UserSurveyResponseTypeEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Filters of user survey responses entity */
export type UserSurveyResponsesFiltersType = {
  __typename?: 'UserSurveyResponsesFiltersType';
  attemptIds?: Maybe<Array<Scalars['String']['output']>>;
  cycleIds?: Maybe<Array<Scalars['String']['output']>>;
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
  parentEntityIds?: Maybe<Array<Scalars['String']['output']>>;
  questionResponseType?: Maybe<Array<ResourceResponseTypeEnum>>;
  recurrenceNumbers?: Maybe<Array<Scalars['Float']['output']>>;
  surveyCreatedById?: Maybe<Array<Scalars['String']['output']>>;
  themeIds?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<Array<UserSurveyResponseTypeEnum>>;
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserSurveySchedulerConfig = {
  __typename?: 'UserSurveySchedulerConfig';
  dueDateSchedulerId?: Maybe<Scalars['String']['output']>;
  userSurveyEndReminderSchedulerId?: Maybe<Scalars['String']['output']>;
};

export enum UserSurveyStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Processing = 'PROCESSING'
}

export enum UserSurveySubjectsEnum {
  UserSurvey = 'USER_SURVEY'
}

export type UserSurveyTemplateType = {
  __typename?: 'UserSurveyTemplateType';
  categories?: Maybe<Array<TemplateCategoryType>>;
  categoryIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateStatusEnum;
  type: TemplateTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  userSurveyTemplate: TemplateUserSurveyStub;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspaceId?: Maybe<Scalars['String']['output']>;
};

export type UserSurveyType = {
  __typename?: 'UserSurveyType';
  adminConfig?: Maybe<UserSurveyAdminConfig>;
  anonymityThreshold?: Maybe<Scalars['Float']['output']>;
  automationConfig?: Maybe<AutomationConfigType>;
  cadenceConfig?: Maybe<UserSurveyCadenceConfigType>;
  channelId?: Maybe<Scalars['String']['output']>;
  coverOffset?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: UserType;
  createdById: Scalars['String']['output'];
  createdByType: UserSurveyCreatedByTypeEnum;
  customSharedViews: Array<SharedViewType>;
  cyclesDateRange?: Maybe<Array<UserSurveyCycleDateRangeConfig>>;
  defaultSharedViews: Array<SharedViewType>;
  description?: Maybe<Scalars['String']['output']>;
  dueDateConfig?: Maybe<DueDateConfig>;
  duration?: Maybe<Scalars['Float']['output']>;
  endMessageConfig?: Maybe<UserSurveyEndMessageConfig>;
  id: Scalars['String']['output'];
  latestRecurrenceNumber?: Maybe<Scalars['Float']['output']>;
  media?: Maybe<EntityMediaType>;
  name: Scalars['String']['output'];
  notificationConfig?: Maybe<UserSurveyNotificationConfig>;
  pages?: Maybe<Array<UserSurveyPageConfig>>;
  participantsConfig?: Maybe<UserSurveyAdminConfig>;
  publicCoverImage?: Maybe<EntityMediaType>;
  publicId?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  questionConfig: UserSurveyQuestionConfig;
  schedulerConfig?: Maybe<UserSurveySchedulerConfig>;
  status?: Maybe<UserSurveyStatusEnum>;
  subWorkspaceIds?: Maybe<Array<Scalars['String']['output']>>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  systemGenerated: Scalars['Boolean']['output'];
  themes: Array<ThemeType>;
  type: UserSurveyTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy: UserType;
  updatedById?: Maybe<Scalars['String']['output']>;
  userPermissions: Array<UserUserSurveyPermissionType>;
  userSurveyCommentConfig?: Maybe<UserSurveyCommentConfig>;
};

export enum UserSurveyTypeEnum {
  Engagement = 'ENGAGEMENT',
  Exit = 'EXIT',
  Feedback = 'FEEDBACK',
  Onboarding = 'ONBOARDING',
  Pulse = 'PULSE'
}

export type UserSurveysFilter = {
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  intent?: InputMaybe<UserSurveyQueryIntentEnum>;
  isAutomated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  publicIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<UserSurveyStatusEnum>>;
  surveyAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  systemGenerated?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Array<UserSurveyTypeEnum>>;
};

export type UserSurveysToSubmitFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<UserSurveyTypeEnum>>;
};

export type UserSurveysWidgetType = {
  __typename?: 'UserSurveysWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  deletedAt: Scalars['String']['output'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  groupFilter?: Maybe<EntityGroupFilterType>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  userSurveys?: Maybe<Array<Maybe<UserSurveyType>>>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type UserTagType = {
  __typename?: 'UserTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']['output']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']['output']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']['output']>>;
  /** This denotes, whether we need to create a new dynamic group, when a new option is added inside single/multi select */
  syncGroup?: Maybe<Scalars['Boolean']['output']>;
  /** field resolvers */
  taggedContentCount: Scalars['Float']['output'];
  taggedEventCount: Scalars['Float']['output'];
  taggedOKRCount: Scalars['Float']['output'];
  taggedTaskCount: Scalars['Float']['output'];
  taggedUserCount: Scalars['Float']['output'];
  type: TagTypeEnum;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};


export type UserTagTypeSharedAtArgs = {
  workspaceId: Scalars['String']['input'];
};


export type UserTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String']['input'];
};

export type UserTeamFilters = {
  reporteeFilterType?: InputMaybe<ReporteeFilterTypeInput>;
};

/** The User model */
export type UserType = {
  __typename?: 'UserType';
  affiliations?: Maybe<Array<UserAffiliations>>;
  alias?: Maybe<Scalars['String']['output']>;
  canBeNominated: Scalars['Boolean']['output'];
  /** Storing media of user cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  currentSubWorkspace?: Maybe<Scalars['String']['output']>;
  cycleReview?: Maybe<UserCycleReviewPayload>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  department?: Maybe<DepartmentType>;
  emailId?: Maybe<Scalars['String']['output']>;
  federationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the field name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** User hierarchy */
  hierarchyPath?: Maybe<Scalars['String']['output']>;
  /** User hierarchy updated at */
  hierarchyPathUpdatedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isAutoSync: Scalars['Boolean']['output'];
  isInGroup: Scalars['Boolean']['output'];
  isInStaffRole: Scalars['Boolean']['output'];
  isLearner: Scalars['Boolean']['output'];
  isManager: Scalars['Boolean']['output'];
  isPerformanceReviewCompleted?: Maybe<Scalars['Boolean']['output']>;
  isPresentInGroup: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  journeyPerformance?: Maybe<JourneyPerformanceType>;
  lastActiveAt?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the field name */
  lastName?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<UserType>;
  managerId?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgRole?: Maybe<OrgRoleType>;
  password?: Maybe<Scalars['String']['output']>;
  performance?: Maybe<ContentPerformanceEntity>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** user preferences */
  preferences?: Maybe<UserPreferencesType>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** profile picture media */
  profilePictureMedia?: Maybe<EntityMediaType>;
  properties?: Maybe<UserPropertiesType>;
  reportees?: Maybe<Array<UserType>>;
  responsibilities?: Maybe<Array<OrgRoleType>>;
  restrictedAccessConfig?: Maybe<RestrictedAccessConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  signupType: UserSignupTypeEnum;
  skillReview?: Maybe<UserSkillsReviewPayload>;
  /** user social accounts info */
  socials?: Maybe<Array<UserSocialAccountInfo>>;
  staffRoleIds?: Maybe<Array<Scalars['String']['output']>>;
  staffRoles?: Maybe<Array<RoleType>>;
  status: UserStatusEnum;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  timezone?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['String']['output'];
  unreadNotificationsCount: Scalars['Float']['output'];
  unresolvedNotificationsCount: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userBellNotification?: Maybe<UserBellNotification>;
  userGroups: Array<UserCollectionGroupType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  workspacePermissions: Array<UserWorkspacePermissionType>;
};


/** The User model */
export type UserTypeCanBeNominatedArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


/** The User model */
export type UserTypeCycleReviewArgs = {
  filters?: InputMaybe<UserCycleReviewFilters>;
};


/** The User model */
export type UserTypeIsAutoSyncArgs = {
  groupId: Scalars['String']['input'];
};


/** The User model */
export type UserTypeIsInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** The User model */
export type UserTypeIsInStaffRoleArgs = {
  roleId: Scalars['String']['input'];
};


/** The User model */
export type UserTypeIsPerformanceReviewCompletedArgs = {
  filters?: InputMaybe<IsPerformanceReviewCompletedInput>;
};


/** The User model */
export type UserTypeIsPresentInGroupArgs = {
  groupId: Scalars['String']['input'];
};


/** The User model */
export type UserTypeJourneyPerformanceArgs = {
  batchId: Scalars['String']['input'];
  journeyStepId: Scalars['String']['input'];
};


/** The User model */
export type UserTypePerformanceArgs = {
  filters?: InputMaybe<ContentFilter>;
};


/** The User model */
export type UserTypeSkillReviewArgs = {
  filters?: InputMaybe<UserSkillsReviewFilters>;
};


/** The User model */
export type UserTypeTagArgs = {
  tagId: Scalars['String']['input'];
};

export enum UserTypeEnum {
  Learner = 'LEARNER',
  Staff = 'STAFF'
}

export type UserUserSurveyPermissionType = {
  __typename?: 'UserUserSurveyPermissionType';
  action: UserSurveyActionsEnum;
  subject: UserSurveySubjectsEnum;
};

export enum UserViewEntityTypeEnum {
  ActionPlan = 'ACTION_PLAN',
  File = 'FILE',
  Meeting = 'MEETING',
  Okr = 'OKR'
}

/** It will resolve different entities with user view is linked */
export type UserViewEntityUnion = FileSchema | KeyResultType | MeetingType | ObjectiveType;

export enum UserViewFieldEntityTypeEnum {
  Tag = 'TAG'
}

export type UserViewFieldSchema = {
  __typename?: 'UserViewFieldSchema';
  /** description of the field */
  description?: Maybe<Scalars['String']['output']>;
  /** Is field editable */
  editable: Scalars['Boolean']['output'];
  entity?: Maybe<TagUnion>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<UserViewFieldEntityTypeEnum>;
  /** IconSettings of the field */
  iconSettings?: Maybe<IconSettings>;
  /** label/shortId of the field */
  label: Scalars['String']['output'];
  /** Name of the field */
  name: Scalars['String']['output'];
  /** Order of the field */
  order: Scalars['Float']['output'];
  /** Field Resolvers */
  provider?: Maybe<IntegrationProviderEnum>;
  tagEntitySettings?: Maybe<TagSettingsType>;
  /** Field visibility */
  visible: Scalars['Boolean']['output'];
  /** Width of the field */
  width: Scalars['Float']['output'];
};

export type UserViewFieldsInputSchema = {
  /** description of the field */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Is field editable */
  editable: Scalars['Boolean']['input'];
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<UserViewFieldEntityTypeEnum>;
  /** IconSettings of the field */
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** label/shortId of the field */
  label: Scalars['String']['input'];
  /** Name of the field */
  name: Scalars['String']['input'];
  /** Order of the field */
  order: Scalars['Float']['input'];
  /** Field visibility */
  visible: Scalars['Boolean']['input'];
  /** Width of the field */
  width: Scalars['Float']['input'];
};

export type UserViewFieldsPayload = {
  __typename?: 'UserViewFieldsPayload';
  fields: Array<UserViewFieldSchema>;
};

export type UserViewPayload = {
  __typename?: 'UserViewPayload';
  userView?: Maybe<UserViewSchema>;
  viewEntity?: Maybe<UserViewEntityUnion>;
};

export type UserViewSchema = {
  __typename?: 'UserViewSchema';
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entityType: UserViewEntityTypeEnum;
  fields: Array<UserViewFieldSchema>;
  id: Scalars['String']['output'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
  viewType: UserViewTypeEnum;
};

export enum UserViewTypeEnum {
  Task = 'TASK'
}

/** User Worskapce Permissions */
export type UserWorkspacePermissionType = {
  __typename?: 'UserWorkspacePermissionType';
  /** Action type */
  action: WorkspaceActions;
  /** Subject type */
  subject: WorkspaceSubjects;
};

export type UsersFilters = {
  OR?: InputMaybe<UserOrFilter>;
  createdAt?: InputMaybe<DateFilter>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  dataCheckDefaultAttributes?: InputMaybe<Array<DataCheckDefaultAttributeEnum>>;
  departmentOrgRole?: InputMaybe<DepartmentOrgRoleFilter>;
  departmentOrgRoles?: InputMaybe<Array<DepartmentOrgRoleFilter>>;
  emailIds?: InputMaybe<Array<Scalars['String']['input']>>;
  federationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldExistsFilters?: InputMaybe<Array<FieldExistsFilter>>;
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  /** get static or dynamic users for given groupIds */
  groupFilter?: InputMaybe<EntityGroupFilter>;
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** user hierarchy under which user will be searched */
  hierarchyPath?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  journeyStepIdForPersonaView?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<DateFilter>;
  /** get users which are not having emailIds */
  notInEmailIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Returns users not belonging to the set of given notInIds */
  notInIds?: InputMaybe<Array<Scalars['String']['input']>>;
  notInTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  notInTrackIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** immediate parent of the user */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** immediate parent of the user */
  parentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  responsibilityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  staffRoleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<UserStatusEnum>>;
  /** get users based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  /** to be used with tagFilters input, current support for AND and OR */
  tagFiltersOperation?: InputMaybe<FilterOperatorEnum>;
  /** tag id of the user */
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  trackIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter to fetch users based on either of the given uniqueIds */
  uniqueIds?: InputMaybe<UniqueIdsFilter>;
  updatedAt?: InputMaybe<DateFilter>;
  userTypes?: InputMaybe<Array<UserTypeEnum>>;
};

export type UsersFiltersType = {
  __typename?: 'UsersFiltersType';
  createdAt?: Maybe<DateFilterType>;
  createdAtTimestampRange?: Maybe<TimestampRangeType>;
  departmentOrgRole?: Maybe<DepartmentOrgRoleFilterType>;
  departmentOrgRoles?: Maybe<Array<DepartmentOrgRoleFilterType>>;
  emailIds?: Maybe<Array<Scalars['String']['output']>>;
  federationIds?: Maybe<Array<Scalars['String']['output']>>;
  /** get static or dynamic users for given groupIds */
  groupFilter?: Maybe<EntityGroupFilterType>;
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** user hierarchy under which user will be searched */
  hierarchyPath?: Maybe<Scalars['String']['output']>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
  /** Returns users not belonging to the set of given notInIds */
  notInIds?: Maybe<Array<Scalars['String']['output']>>;
  orgRoleIds?: Maybe<Array<Scalars['String']['output']>>;
  parentId?: Maybe<Scalars['String']['output']>;
  searchText?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Array<UserStatusEnum>>;
  /** get users based on tags */
  tagFilters?: Maybe<Array<EntityTagType>>;
  /** tag id of the user */
  tagIds?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<DateFilterType>;
  userTypes?: Maybe<Array<UserTypeEnum>>;
  workspaceRoleIds?: Maybe<Array<Scalars['String']['output']>>;
};

export enum UsersImportTypeEnum {
  AllUsers = 'ALL_USERS',
  SpecificGroups = 'SPECIFIC_GROUPS'
}

/** Validation Input */
export type ValidationInput = {
  /** Maximum Length */
  maxLength?: InputMaybe<Scalars['Float']['input']>;
  /** Maximum Selections */
  maxSelections?: InputMaybe<Scalars['Float']['input']>;
  /** Maximum value */
  maxValue?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum Selections */
  minSelections?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum value */
  minValue?: InputMaybe<Scalars['Float']['input']>;
};

/** Validations on Database fields */
export type ValidationType = {
  __typename?: 'ValidationType';
  /** Maximum Length */
  maxLength?: Maybe<Scalars['Float']['output']>;
  /** Maximum Selections */
  maxSelections?: Maybe<Scalars['Float']['output']>;
  /** Maximum value */
  maxValue?: Maybe<Scalars['Float']['output']>;
  /** Minimum Selections */
  minSelections?: Maybe<Scalars['Float']['output']>;
  /** Minimum value */
  minValue?: Maybe<Scalars['Float']['output']>;
};

export enum VerificationMethodEnum {
  AnswerQuestions = 'ANSWER_QUESTIONS',
  AttachCertificate = 'ATTACH_CERTIFICATE',
  AutomaticSync = 'AUTOMATIC_SYNC',
  MakeSubmission = 'MAKE_SUBMISSION',
  NoVerification = 'NO_VERIFICATION'
}

export type VerificationMethodInputType = {
  /** Extra instruction for verification method */
  instruction?: InputMaybe<Scalars['String']['input']>;
  /** Method to verify the completion of the external content */
  methodType: VerificationMethodEnum;
  /** Minimum required score percentage for Answer Questions verification method */
  minScorePercent?: InputMaybe<Scalars['Float']['input']>;
  /** Question Ids for Answer Questions verification method */
  questionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Submission type for Make a Submission verification method */
  submissionType?: InputMaybe<SubmissionTypeEnum>;
};

/** Properties of verification methods */
export type VerificationMethodType = {
  __typename?: 'VerificationMethodType';
  /** Extra instruction for Make a Submission verification method */
  instruction?: Maybe<Scalars['String']['output']>;
  /** Method to verify the completion of the external content */
  methodType: VerificationMethodEnum;
  /** Minimum required score percentage for Answer Questions verification method */
  minScorePercent?: Maybe<Scalars['Float']['output']>;
  /** Question Ids for Answer Questions verification method */
  questionIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Submission type for Make a Submission verification method */
  submissionType?: Maybe<SubmissionTypeEnum>;
};

/** Contains information regarding the version of the content */
export type VersionConfig = {
  __typename?: 'VersionConfig';
  createdAt: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rootBlockId: Scalars['String']['output'];
  status: ResourceStatusEnum;
};

/** Input for updating the timestamp of user's last visit at the channel */
export type ViewChannelInput = {
  /** ChannelId on which lastViewedAt needs to be updated */
  channelId: Scalars['String']['input'];
  /** Timestamp to be updated to. Takes request timestamp as default if not provided */
  lastViewedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ViewChannelPayload = {
  __typename?: 'ViewChannelPayload';
  channelUser?: Maybe<ChannelUserType>;
  errorCode?: Maybe<ViewChannelUserErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ViewChannelUserErrorCodeEnum {
  ChannelUserNotFound = 'CHANNEL_USER_NOT_FOUND',
  ForbiddenError = 'FORBIDDEN_ERROR'
}

export enum ViewTypeEnum {
  DetailView = 'DETAIL_VIEW',
  FormView = 'FORM_VIEW',
  TableView = 'TABLE_VIEW'
}

export enum VisibilitySettingsAccessTypeEnum {
  None = 'NONE',
  Open = 'OPEN',
  Restricted = 'RESTRICTED'
}

/** It will resolve different entities with visibility settings inheritedFrom */
export type VisibilitySettingsInheritedFromEntityUnion = KeyResultType | ObjectiveType;

export type VisibilitySettingsInput = {
  /** visibility setting access type */
  accessType: VisibilitySettingsAccessTypeEnum;
  /** groupIds entity is visible to */
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** parent info in case visibility settings are inherited from parent */
  inheritedFrom?: InputMaybe<VisibilitySettingsParentEntityInput>;
  /** restriction configs for restricted visibility */
  restrictionConfigs?: InputMaybe<Array<VisibilitySettingsRestrictionInput>>;
};

export type VisibilitySettingsParentEntityInput = {
  /** parent entity id */
  entityId: Scalars['String']['input'];
  /** parent entity type */
  entityType: VisibilitySettingsParentEntityTypeEnum;
};

/** parent entity info in case visibility settings are inherited */
export type VisibilitySettingsParentEntityType = {
  __typename?: 'VisibilitySettingsParentEntityType';
  /** parent entity id */
  entityId: Scalars['String']['output'];
  /** parent entity type */
  entityType: VisibilitySettingsParentEntityTypeEnum;
};

export enum VisibilitySettingsParentEntityTypeEnum {
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE'
}

/** restriction config input for restricted visibility settings access type */
export type VisibilitySettingsRestrictionInput = {
  /** id of user which will be used for contexual visibility settings */
  contextualUserId?: InputMaybe<Scalars['String']['input']>;
  /** entity ids(userIds, groupIds, etc...) for which entity is visible */
  entityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** parent entity id */
  type: VisibilitySettingsRestrictionTypeEnum;
};

/** restriction config in case of restricted visibility settings access type */
export type VisibilitySettingsRestrictionType = {
  __typename?: 'VisibilitySettingsRestrictionType';
  /** id of user which will be used for contexual visibility settings */
  contextualUserId?: Maybe<Scalars['String']['output']>;
  /** entity ids(userIds, groupIds, etc...) for which entity is visible */
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  /** parent entity id */
  type: VisibilitySettingsRestrictionTypeEnum;
};

export enum VisibilitySettingsRestrictionTypeEnum {
  Department = 'DEPARTMENT',
  Groups = 'GROUPS',
  Management = 'MANAGEMENT',
  Manager = 'MANAGER',
  Reportees = 'REPORTEES',
  Self = 'SELF',
  Team = 'TEAM',
  Users = 'USERS'
}

/** Visibility settings for entities */
export type VisibilitySettingsType = {
  __typename?: 'VisibilitySettingsType';
  /** visibility setting access type */
  accessType: VisibilitySettingsAccessTypeEnum;
  /** groupIds entity is visible to */
  groupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** parent info in case visibility settings are inherited from parent */
  inheritedFrom?: Maybe<VisibilitySettingsParentEntityType>;
  /** Field Resolvers */
  inheritedFromEntity?: Maybe<VisibilitySettingsInheritedFromEntityUnion>;
  /** restriction configs for restricted visibility */
  restrictionConfigs?: Maybe<Array<VisibilitySettingsRestrictionType>>;
};

export enum WeekOfMonthEnum {
  First = 'FIRST',
  Fourth = 'FOURTH',
  Last = 'LAST',
  Second = 'SECOND',
  Third = 'THIRD'
}

export type WidgetFilters = {
  isStandardWidget?: InputMaybe<Scalars['Boolean']['input']>;
  widgetId?: InputMaybe<Scalars['String']['input']>;
};

export type WidgetInteractionActionType = {
  __typename?: 'WidgetInteractionActionType';
  /** Relevant data to perform given action */
  props?: Maybe<Scalars['String']['output']>;
  /** ENUM describing the action that should be performed for given user-interaction */
  type: WidgetInteractionActionTypeEnum;
};

export enum WidgetInteractionActionTypeEnum {
  Mutation = 'MUTATION',
  Redirect = 'REDIRECT'
}

export type WidgetInteractionEventActionType = {
  __typename?: 'WidgetInteractionEventActionType';
  /** List of actions to be performaned against given event */
  actions: Array<WidgetInteractionActionType>;
  /** ENUM for identifying type of user-interaction performed */
  event: WidgetInteractionEventTypeEnum;
};

export enum WidgetInteractionEventTypeEnum {
  OnClick = 'ON_CLICK'
}

export type WidgetLayoutInput = {
  position: WidgetPositionInput;
  widgetId: Scalars['String']['input'];
};

export type WidgetLayoutSetInput = {
  set: Array<WidgetLayoutInput>;
};

/** Widget Layout */
export type WidgetLayoutType = {
  __typename?: 'WidgetLayoutType';
  position: WidgetPositionType;
  widgetId: Scalars['String']['output'];
};

/** Widget Position Input */
export type WidgetPositionInput = {
  h: Scalars['Float']['input'];
  w: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

/** Widget Position */
export type WidgetPositionType = {
  __typename?: 'WidgetPositionType';
  h: Scalars['Float']['output'];
  w: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

/** union of all widget previews */
export type WidgetPreviewUnion = CompletedLearningsWidgetPreviewType | ContentsWidgetPreviewType | ContinueLearningWidgetPreviewType | ExternalWidgetPreviewType | MyEventsWidgetPreviewType | OnboardingContentWidgetPreviewType | PerformanceMetricsWidgetPreviewType | TodosWidgetPreviewType | UpcomingEventsWidgetPreviewType;

export type WidgetPropsInput = {
  chartWidgetProps?: InputMaybe<ChartWidgetPropsInput>;
  contentsWidgetProps?: InputMaybe<ContentsWidgetPropsInput>;
  externalWidgetProps?: InputMaybe<ExternalWidgetPropsInput>;
  listItemsWidgetProps?: InputMaybe<ListItemsWidgetPropsInput>;
  type: WidgetTypeEnum;
};

export enum WidgetTypeEnum {
  CompletedLearnings = 'COMPLETED_LEARNINGS',
  Contents = 'CONTENTS',
  ContinueLearning = 'CONTINUE_LEARNING',
  CurrentEvents = 'CURRENT_EVENTS',
  CurrentMeetings = 'CURRENT_MEETINGS',
  CurrentMeetingInstances = 'CURRENT_MEETING_INSTANCES',
  EntityCheck = 'ENTITY_CHECK',
  Events = 'EVENTS',
  External = 'EXTERNAL',
  FeatureNudge = 'FEATURE_NUDGE',
  GlobalSearch = 'GLOBAL_SEARCH',
  Habits = 'HABITS',
  Journeys = 'JOURNEYS',
  ListItems = 'LIST_ITEMS',
  MarketplaceContents = 'MARKETPLACE_CONTENTS',
  Meetings = 'MEETINGS',
  MeetingInstances = 'MEETING_INSTANCES',
  MyEvents = 'MY_EVENTS',
  Okrs = 'OKRS',
  OnboardingContent = 'ONBOARDING_CONTENT',
  Performances = 'PERFORMANCES',
  PerformanceMetrics = 'PERFORMANCE_METRICS',
  Providers = 'PROVIDERS',
  Report = 'REPORT',
  ReviewCycles = 'REVIEW_CYCLES',
  SubmissionsToReview = 'SUBMISSIONS_TO_REVIEW',
  Tasks = 'TASKS',
  Todos = 'TODOS',
  UpcomingEvents = 'UPCOMING_EVENTS',
  UserSurveys = 'USER_SURVEYS'
}

/** union of all widgets */
export type WidgetUnion = ChartWidgetType | CompletedLearningsWidgetType | ContentsWidgetType | ContinueLearningWidgetType | CurrentEventsWidgetType | CurrentMeetingInstancesWidgetType | CurrentMeetingsWidgetType | EntityCheckWidgetType | EventsWidgetType | ExternalWidgetType | FeatureNudgeWidgetType | GlobalSearchWidgetType | HabitsWidgetType | JourneysWidgetType | ListItemsWidgetType | MarketplaceContentsWidgetType | MeetingInstancesWidgetType | MeetingsWidgetType | OkRsWidgetType | OnboardingContentWidgetType | PerformanceMetricsWidgetType | PerformancesWidgetType | ProvidersWidgetType | ReviewCyclesWidgetType | SubmissionsToReviewWidgetType | TasksWidgetType | TodosWidgetType | UserSurveysWidgetType;

export enum WorkspaceActions {
  Approve = 'APPROVE',
  Archive = 'ARCHIVE',
  Attach = 'ATTACH',
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Edit = 'EDIT',
  Enroll = 'ENROLL',
  Hide = 'HIDE',
  Invite = 'INVITE',
  List = 'LIST',
  Manage = 'MANAGE',
  Masquerade = 'MASQUERADE',
  Pin = 'PIN',
  Publish = 'PUBLISH',
  Read = 'READ',
  Reject = 'REJECT',
  Remove = 'REMOVE',
  Send = 'SEND',
  ShareContent = 'SHARE_CONTENT'
}

export type WorkspaceAdministrationConfigType = {
  __typename?: 'WorkspaceAdministrationConfigType';
  clientOwner?: Maybe<UserType>;
  clientOwnerUserId?: Maybe<Scalars['String']['output']>;
  excludeLearnerEmailPatterns?: Maybe<Array<Scalars['String']['output']>>;
  licenseLimits?: Maybe<Array<WorkspaceLicenseLimit>>;
  licenseTagId?: Maybe<Scalars['String']['output']>;
  maxLearnersCount?: Maybe<Scalars['Float']['output']>;
  notificationRules?: Maybe<WorkspaceAdministrationRulesType>;
  parentAcademyId?: Maybe<Scalars['String']['output']>;
  renewalDate?: Maybe<Scalars['String']['output']>;
  renewalStatus?: Maybe<WorkspaceRenewalStatusEnum>;
  renewalType?: Maybe<WorkspaceRenewalTypeEnum>;
  serviceContactUser?: Maybe<UserType>;
  serviceContactUserId?: Maybe<Scalars['String']['output']>;
};

export enum WorkspaceAdministrationRuleStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED'
}

export enum WorkspaceAdministrationRuleTypeEnum {
  LowSeatCount = 'LOW_SEAT_COUNT',
  UpcomingAutomaticRenewal = 'UPCOMING_AUTOMATIC_RENEWAL',
  UpcomingClosedRenewal = 'UPCOMING_CLOSED_RENEWAL',
  UpcomingDeactivation = 'UPCOMING_DEACTIVATION',
  UpcomingRenewalOpportunity = 'UPCOMING_RENEWAL_OPPORTUNITY'
}

export type WorkspaceAdministrationRulesInput = {
  lowSeatCount?: InputMaybe<LowSeatCountRuleInput>;
  upcomingAutomaticRenewal?: InputMaybe<UpcomingAutomaticRenewalRuleInput>;
  upcomingClosedRenewal?: InputMaybe<UpcomingClosedRenewalRuleInput>;
  upcomingDeactivation?: InputMaybe<UpcomingDeactivationRuleInput>;
  upcomingRenewalOpportunity?: InputMaybe<UpcomingRenewalOpportunityRuleInput>;
};

export type WorkspaceAdministrationRulesType = {
  __typename?: 'WorkspaceAdministrationRulesType';
  lowSeatCount?: Maybe<LowSeatCountRuleType>;
  upcomingAutomaticRenewal?: Maybe<UpcomingAutomaticRenewalRuleType>;
  upcomingClosedRenewal?: Maybe<UpcomingClosedRenewalRuleType>;
  upcomingDeactivation?: Maybe<UpcomingDeactivationRuleType>;
  upcomingRenewalOpportunity?: Maybe<UpcomingRenewalOpportunityRuleType>;
};

export type WorkspaceConfigType = {
  __typename?: 'WorkspaceConfigType';
  adminApprovedWhiteListedInviteDomains?: Maybe<Array<Scalars['String']['output']>>;
  administration?: Maybe<WorkspaceAdministrationConfigType>;
  authConfig: AuthConfig;
  checkAssignmentContentVisibility?: Maybe<Scalars['Boolean']['output']>;
  checkInviteUserTags?: Maybe<Scalars['Boolean']['output']>;
  filterStaffRoleIds?: Maybe<Array<Scalars['String']['output']>>;
  pages?: Maybe<Array<PageType__Next>>;
  site?: Maybe<Scalars['JSON']['output']>;
  theme?: Maybe<Scalars['JSON']['output']>;
  whitelistedSignUpDomains?: Maybe<Array<Scalars['String']['output']>>;
};

/** User Worskapce Permissions */
export type WorkspaceDetails = {
  __typename?: 'WorkspaceDetails';
  icon?: Maybe<Scalars['String']['output']>;
  uri: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
  workspaceLogo?: Maybe<Scalars['String']['output']>;
  workspaceName: Scalars['String']['output'];
};

/** Workspace Integrations */
export type WorkspaceIntegrationType = {
  __typename?: 'WorkspaceIntegrationType';
  appId?: Maybe<Scalars['String']['output']>;
  appName?: Maybe<Scalars['String']['output']>;
  appUrl?: Maybe<Scalars['String']['output']>;
  loginConfig?: Maybe<LoginConfig>;
};

export type WorkspaceLicenseLimit = {
  __typename?: 'WorkspaceLicenseLimit';
  licenseId: Scalars['String']['output'];
  maxLearnersCount: Scalars['Float']['output'];
};

export type WorkspaceLicenseLimitInput = {
  licenseId: Scalars['String']['input'];
  maxLearnersCount: Scalars['Float']['input'];
};

export enum WorkspaceRenewalStatusEnum {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Renewed = 'RENEWED'
}

export enum WorkspaceRenewalTypeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export enum WorkspaceSubjects {
  Academy = 'ACADEMY',
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  AuditLog = 'AUDIT_LOG',
  Authentication = 'AUTHENTICATION',
  Billing = 'BILLING',
  Category = 'CATEGORY',
  Certificate = 'CERTIFICATE',
  Channel = 'CHANNEL',
  Community = 'COMMUNITY',
  CompanyObjective = 'COMPANY_OBJECTIVE',
  ContentCollection = 'CONTENT_COLLECTION',
  ContentSubworkspace = 'CONTENT_SUBWORKSPACE',
  Department = 'DEPARTMENT',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  EmailDigest = 'EMAIL_DIGEST',
  Event = 'EVENT',
  EventSubworkspace = 'EVENT_SUBWORKSPACE',
  File = 'FILE',
  Group = 'GROUP',
  Homepage = 'HOMEPAGE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  Integration = 'INTEGRATION',
  Journey = 'JOURNEY',
  Learner = 'LEARNER',
  Members = 'MEMBERS',
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
  Post = 'POST',
  Recordings = 'RECORDINGS',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  ReviewCycle = 'REVIEW_CYCLE',
  Role = 'ROLE',
  Scorecard = 'SCORECARD',
  SharedConnection = 'SHARED_CONNECTION',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Space = 'SPACE',
  Staff = 'STAFF',
  Subaccount = 'SUBACCOUNT',
  Tag = 'TAG',
  TagSubworkspace = 'TAG_SUBWORKSPACE',
  TeamObjective = 'TEAM_OBJECTIVE',
  User = 'USER',
  UserCollection = 'USER_COLLECTION',
  UserSurvey = 'USER_SURVEY',
  Workspace = 'WORKSPACE'
}

/** The Workspace Model */
export type WorkspaceType = {
  __typename?: 'WorkspaceType';
  academySetupDepartmentTemplates: Array<DefaultTemplateType>;
  acsUrl: Scalars['String']['output'];
  adminId?: Maybe<Scalars['String']['output']>;
  allowedEntityPermissionsMap?: Maybe<Array<EntityPermissionsMap>>;
  configs?: Maybe<WorkspaceConfigType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String']['output'];
  enabledApps?: Maybe<Array<Scalars['String']['output']>>;
  hostName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  integrations?: Maybe<Array<WorkspaceIntegrationType>>;
  learnersCount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  onboardingInfo?: Maybe<OnboardingInfoType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  siteSettings: SiteSettingsType;
  spEntityId: Scalars['String']['output'];
  /** Resolves roles available in workspace. Use with caution; all non learner roles will be returned */
  staffRoles: Array<RoleType>;
  status?: Maybe<Scalars['String']['output']>;
  subDomain: Scalars['String']['output'];
  supportEmail?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']['output']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']['output']>;
};

export type ZoomEventAttendanceImportInputPropsInput = {
  eventId: Scalars['String']['input'];
};

export type ZoomEventAttendanceImportInputPropsType = {
  __typename?: 'ZoomEventAttendanceImportInputPropsType';
  eventId: Scalars['String']['output'];
};

export type ZoomEventAttendanceImportMetadataType = {
  __typename?: 'ZoomEventAttendanceImportMetadataType';
  success: Scalars['Boolean']['output'];
};

export type ZoomIntegrationConnectionInfoInput = {
  accountId: Scalars['String']['input'];
  userEmailId: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type ZoomIntegrationConnectionInfoType = {
  __typename?: 'ZoomIntegrationConnectionInfoType';
  accountId: Scalars['String']['output'];
  userEmailId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type ZoomIntegrationSettingsInput = {
  connectionInfo: ZoomIntegrationConnectionInfoInput;
};

export type ZoomIntegrationSettingsType = {
  __typename?: 'ZoomIntegrationSettingsType';
  connectionInfo: ZoomIntegrationConnectionInfoType;
};

export type ZoomRecordingsImportInputPropsInput = {
  eventId: Scalars['String']['input'];
};

export type ZoomRecordingsImportInputPropsType = {
  __typename?: 'ZoomRecordingsImportInputPropsType';
  eventId: Scalars['String']['output'];
};
